import React from "react";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import ReserveBlock from "../ReserveBlock/reserve-block";

import "./block-bookings.css";

export default function BlockBookings(props) {
  const block_bookings_header = () => {
    if (props.blockBookings.length === 0) {
      return <div className="block-booking">No Block Bookings</div>;
    } else {
      return <div className="block-booking-header">Block Bookings</div>;
    }
  };

  return (
    <div className="block-bookings">
      <ReserveBlock
        booking_type="Block Booking"
        commonState={props.commonState}
        // floorState = {props.floorState}
        // reserveBlock={(newBlock)=>props.handleReseveBlock(newBlock)}
      />

      <div className="block-bookings-content">
        {block_bookings_header()}
        {props.blockBookings.map((item) => (
          <BlockBooking
            key={item.block_id}
            blockBooking={item}
            cancelBlockClicked={(block) => props.handleCancelBlock(block)}
            releaseSeatsClicked={(block) => props.handleReleaseSeats(block)}
          />
        ))}
      </div>
    </div>
  );
}

const BlockBooking = (props) => {
  const getMonth = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec",
  };

  const get_day = {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
  };

  const getWeekDay = () => {
    const blocked_date = props.blockBooking.blocked_date;
    return get_day[blocked_date.getDay()];
  };

  const printDate = () => {
    const blocked_date = props.blockBooking.blocked_date;
    let blocked_date_date;

    if (blocked_date.getDate() < 10) {
      blocked_date_date = "0" + blocked_date.getDate().toString();
    } else {
      blocked_date_date = blocked_date.getDate().toString();
    }

    const blocked_date_month = getMonth[blocked_date.getMonth()];

    return blocked_date_date + "-" + blocked_date_month;
  };

  const releaseSeats = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui confirm_dialog">
            <div className="confirm_title">Confirmation</div>
            <div className="confirm_msg">
              Are you sure you want to release{" "}
              {props.blockBooking.seats_blocked -
                props.blockBooking.seats_confirmed}{" "}
              seats?
            </div>
            <div className="confirm_buttons">
              <button
                className="confirm_no"
                onClick={() => {
                  onClose();
                }}
              >
                No
              </button>

              <button
                className="confirm_yes"
                onClick={() => {
                  props.releaseSeatsClicked(props.blockBooking);
                  onClose();
                }}
              >
                Yes
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const cancelBlock = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui confirm_dialog">
            <div className="confirm_title">Confirmation</div>
            <div className="confirm_msg">
              Indvidual seat bookings against this block will also be cancelled.
              Are you sure you want to cancel block booking?
            </div>
            <div className="confirm_buttons">
              <button
                className="confirm_no"
                onClick={() => {
                  onClose();
                }}
              >
                No
              </button>

              <button
                className="confirm_yes"
                onClick={() => {
                  props.cancelBlockClicked(props.blockBooking);
                  onClose();
                }}
              >
                Yes
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const dispalyReleaseSeatsButton = () => {
    if (
      props.blockBooking.seats_confirmed > 0 &&
      props.blockBooking.seats_confirmed < props.blockBooking.seats_blocked
    ) {
      return (
        <button
          className="button-release-seats"
          onClick={() => releaseSeats(props.blockBooking)}
        >
          {/* Release {props.blockBooking.seats_blocked - props.blockBooking.seats_confirmed} Seats? */}
          Release Seats
        </button>
      );
    }
  };

  const printFloorNo = () => {
    const floor_details = props.blockBooking.wing
      ? props.blockBooking.wing + ", " + props.blockBooking.floor
      : props.blockBooking.floor;

    return <div style={{ fontSize: "12px" }}>{floor_details}</div>;
  };

  return (
    <div className="block-booking">
      <div className="block-booking-title">{getWeekDay()}</div>
      <div className="block-booking-left">
        <div className="block-date">
          <div style={{ fontSize: "28px" }}>{printDate()}</div>
          <div style={{ fontSize: "12px" }}>{printFloorNo()}</div>
        </div>
        <div className="block-seats">
          <div style={{ fontSize: "24px" }}>
            {props.blockBooking.seats_confirmed}/
            {props.blockBooking.seats_blocked}
          </div>
          <div style={{ fontSize: "12px" }}>Confirmed</div>
        </div>
      </div>
      <div className="block-booking-right">
        <div className="block-buttons">
          {dispalyReleaseSeatsButton()}

          <button
            onClick={() => cancelBlock(props.blockBooking)}
            className="button-cancel-block"
          >
            Cancel Block
          </button>
        </div>
      </div>
    </div>
  );
};
