import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { firebase } from '../../../config/firebase';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import './floor-div.css'

export default function AssignOrgSpace(props) {

    // let selectedDate;
    const formRef = React.useRef();
    const selectedDate = props.commonState.currentDate;
    const [availableSites, setAvailableSites] = useState([])
    const [blockCategory, setblockCategory] = useState([])
    const [current_floor, setcurrent_floor] = React.useState('');
    const [attrib, setAttrib] = useState('')
    const [defSite, setDefSite] = useState(props.commonState.site);
    const maxDate = new Date(props.commonState.currentDate)
    maxDate.setDate(maxDate.getDate() + 7)
    const sites_ref = firebase.firestore().collection('Sites');

    const [seatAttribs, setSeatAttribs] = useState([]);

    const block_bookings_header = () => {
        // console.log(props.blockBookings.length);
        if (props.blockBookings.length === 0) {
            return (
                <div className='block-booking'>
                    No Org Spaces Created
                </div>
            )
        }
        else {
            return (
                <div className="block-booking-header">
                    Org Space Bifurcation
            </div>
            )
        }
    }

    useEffect(() => {
        sites_ref
            .get()
            .then(
            querySnapshot => {
                const tempSites = []
                querySnapshot.forEach(doc => {
                    const site = doc.id;
                    tempSites.push({ value: site, label: site });
                }
                );
                setAvailableSites([...tempSites])
            }
            )

        const floorsNames = Object.keys(props.commonState.floorState).sort();
            
        const floorsNamesDisplay = []
        for (let i in floorsNames){
            floorsNamesDisplay.push({ value: floorsNames[i], label: floorsNames[i] });
        }
        setblockCategory([...floorsNamesDisplay])

        setSeatAttribs([...props.commonState.site_data.organization])
    }, []);

    const handleChangeSite = (event) => {   
        setDefSite(event.target.value);
    };

    const handleChange = (event) => {
        setcurrent_floor(event.target.value);
        props.setfloor(event.target.value)
    };

    const handleAttribChange = (e) => {
        setAttrib(e.target.value);
        // props.setfloor(e.target.value)
    }
    const SelectBlockClicked = () => {

    }

    return (

        <div className='reserve-block-seat'>
            <form ref={formRef}>
                <div className='reserve-block-seat-items'>

                    <div className='reserve-seat-input'>
                        <TextField
                            id="standard-select-current_floor"
                            select
                            value={defSite}
                            label="Select"
                            disabled={!props.commonState.isAppAdmin}
                            style={{ width: '80%', margin: '5px', marginTop: '20px', }}
                            onChange={handleChangeSite}
                            helperText="Please select the site"
                        >
                            {availableSites.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className='reserve-seat-input'>
                        <TextField
                            id="standard-select-current_floor"
                            required
                            select
                            label="Select"
                            style={{ width: '80%', margin: '5px', marginTop: '20px', }}
                            onChange={handleChange}
                            helperText="Floor"
                        >
                            {blockCategory.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>

                    </div>
                   <div className='reserve-seat-input'>
                        <TextField
                            id="standard-select-attribute"
                            required
                            select
                            label="Select"
                            style={{ width: '80%', margin: '5px', marginTop: '20px', }}
                            onChange={handleAttribChange}
                            helperText="Organization"
                        >
                            {seatAttribs.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>

                    </div>
                    <div className='reserve-seat-input'>
                        <Link
                            to={{
                                pathname: '/floorplan',
                                state: {
                                    action: 'assign_org_space',
                                    commonState: props.commonState,
                                    selectedDate: selectedDate,
                                    blockedSeatName: "Test",
                                    wing_data: props.blockBooking,
                                    floor: current_floor,
                                    org: attrib,
                                }
                            }}
                        >
                            <button
                                onClick={() => {
   
                                        SelectBlockClicked()
                                    // }
                                }
                                }
                                className='field-seat-style reserve-seat-button'>
                                Select Seats
                        </button>
                        </Link>
                    </div>
                </div>
            </form>
            <div className='block-bookings-content'>
                {block_bookings_header()}
                {props.blockBookings.map((item, index) =>
                    <BlockBooking
                        key={index}
                        blockBooking={item}
                        commonState={props.commonState}
                        cancelBlockClicked={(block) => props.handleCancelBlock(block)}
                        releaseSeatsClicked={(block) => props.handleReleaseSeats(block)}
                    />)}
            </div>
        </div>
    )
}

const BlockBooking = (props) => {
    const current_floor = Object.keys(props.blockBooking.floor);
    const curr_floor = current_floor[0]

    const printDate = () => {
        let date1 = props.blockBooking.last_updated_on.toDate().toLocaleDateString();
        return date1;
    }

    const editWing = () => {

    }


    const cancelBlock = () => {
        props.cancelBlockClicked(props.blockBooking)
    }

    return (
        <div className='block-booking'>
            <div className='block-booking-left'>

                <div className='block-date'>
                    <div style={{ fontSize: '16px' }}>
                        {props.blockBooking.org}
                    </div>

                </div>
                <div className='block-seats'>
                    <div style={{ fontSize: '24px' }}>
                        {props.blockBooking.no_of_seats}
                    </div>
                    <div style={{ fontSize: '12px' }}>
                        Seats
                    </div>
                </div>
                <div className='block-seats'>

                    <div style={{ fontSize: '16px' }}>
                        {props.blockBooking.last_updated_by}
                    </div>
                    <div style={{ fontSize: '12px' }}>
                        Last Updated By
                    </div>
                </div>
                <div className='block-seats'>

                    <div style={{ fontSize: '16px' }}>
                        {printDate()}
                    </div>
                    <div style={{ fontSize: '12px' }}>
                        Last Updated On
                    </div>
                </div>

            </div>
            <div >
                <div className='block-buttons'>
                    <div>
                    <Link
                            to={{
                                pathname: '/floorplan',
                                state: {
                                    action: "assign_org_space",
                                    commonState: props.commonState,
                                    wingName:props.blockBooking.wing,
                                    wing_data: props.blockBooking,
                                    floor: curr_floor,
                                    org : props.blockBooking.org,
                                    // floor : Object.props.blockBooking.floor.key()
                                }
                            }}
                        >
                    <button
                        className='button-release-seats'
                        onClick={() => editWing()}>
                        Edit
                    </button>
                    </Link>
                    </div>
                    <button
                        onClick={() => cancelBlock(props.blockBooking)}
                        className='button-cancel-block'>
                        Delete
                </button>
                </div>
            </div>
        </div>
    )
}