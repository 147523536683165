import React  from 'react';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import AddNewQuesChild from './new-ques-child';


export default function AddNewQues(props) {
  const styles = (theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    const DialogContent = withStyles((theme) => ({
        root: {
            padding: theme.spacing(2),
        },
    }))(MuiDialogContent);

    const [open, setOpen] = React.useState(true);

    const handleCloseNew = () => {
        props.handleNewClose();
        setOpen(false);
    };

    return (
        <div >
            <Dialog onClose={handleCloseNew} aria-labelledby="customized-dialog-title" open={open}>
                <div className="new_question_main">
                <div className="new_question_main">
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseNew}>
                        New Question
                    </DialogTitle>
                   </div>
                    <DialogContent dividers style={{width:'100%'}}>  
                        <AddNewQuesChild handleCloseNew={handleCloseNew} userName={props.userName} site={props.site} mode={props.mode}/>                         
                    </DialogContent>
                </div>
            </Dialog>
        </div>

    );
}

