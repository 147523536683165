import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import { firebase } from '../../../config/firebase';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { confirmAlert } from 'react-confirm-alert';
import warningIcon from './../../../Assets/Images/warning.png';
import './block-seats.css'

export default function ReserveBlockedSeat(props) {
    const history = useHistory();
    // const [noOfSeats, setNoOfSeats] = useState(1);
    const noOfSeats = 1;
    const [availableSites, setAvailableSites] = useState([])
    const blockCategory = [
        { value: 'Social Distance', label: 'Social Distance' },
        { value: 'Special Reservation Block', label: 'Special Reservation Block' },
        { value: 'Block Seats', label: 'Block Seats' }];
    const [org_list, set_org_list] = useState([]);
    // const [currency, setCurrency] = React.useState('');
    const [selected_block, setselected_block] = useState('')
    const [group, setGroup] = React.useState('');
    const maxDate = new Date(props.commonState.currentDate)
    maxDate.setDate(maxDate.getDate() + 7)
    const check_array = [false, false, false, false, false, false, false];
    const [active_days, setactive_days] = useState([false, true, true, true, true, true, false])
    const sites_ref = firebase.firestore().collection('Sites');
    useEffect(() => {
        set_org_list(props.commonState.site_data.organization)
        sites_ref
            .get()
            .then(
            querySnapshot => {
                const tempSites = []
                querySnapshot.forEach(doc => {
                    const site = doc.id;
                    tempSites.push({ value: site, label: site });
                });
                setAvailableSites([...tempSites])
            })
    }, []);

    const handleChange = (event) => {
        // console.log('Change Site');
        // console.log(event.target.value);
        // setCurrency(event.target.value);
    };

    const createBlockedSeats = (e) => {
        // console.log('Select Seats')
        if (selected_block === '') {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui confirm_dialog'>
                            <div className="confirm_title">Validation</div>
                            <div className="confirm_msg">
                                <div className="img">
                                    <img
                                        alt=''
                                        style={{ width: 50, height: 50 }}
                                        src={warningIcon}
                                    />
                                </div>
                                <div className="msg">Select Block Category</div>
                            </div>

                            <div className="confirm_buttons">
                                <button className="confirm_yes" onClick={onClose}>OK</button>
                            </div>
                        </div>
                    );

                }
            })
        }
        else if (JSON.stringify(active_days) === JSON.stringify(check_array)) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui confirm_dialog'>
                            <div className="confirm_title">Validation</div>
                            <div className="confirm_msg">
                                <div className="img">
                                    <img
                                        alt=''
                                        style={{ width: 50, height: 50 }}
                                        src={warningIcon}
                                    />
                                </div>
                                <div className="msg">Select atleast one active day</div>
                            </div>

                            <div className="confirm_buttons">
                                <button className="confirm_yes" onClick={onClose}>OK</button>
                            </div>
                        </div>
                    );

                }
            })
        }
        else {
            history.push('/floorplan', {
                action: 'reserve_blocked_seat',
                commonState: props.commonState,
                active_days: active_days,
                selected_block: selected_block,
                noOfSeats: noOfSeats,
                new_cat: true,
                group: group,
                re_select: false,
            })
        }

    }

    const getOrgList = () => {
        return org_list.map((org_name) => {
          return <MenuItem value={org_name}>{org_name}</MenuItem>;
        });
      };  
      
      const handleGroupChange = (val) => {
        setGroup(val);
      };      

    const handleCheckBoxChange = (e, index) => {
        let temp_arr = [...active_days]
        temp_arr[index] = !active_days[index];
        setactive_days([...temp_arr])
    }

    return (
        <div className='reserve-block-seat'>
            <div className='reserve-block-seat-items'>
                <div className='reserve-seat-input'>
                    <TextField
                        id="standard-select-currency"
                        value={props.commonState.site}
                        disabled
                        select
                        label="Select"
                        style={{ width: '80%', margin: '5px', marginTop: '20px', }}
                        onChange={handleChange}
                        helperText="Site"
                    >
                        {availableSites.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className='reserve-seat-input'>
                    <TextField
                            id="standard-select-currency"
                            select
                            value={group}
                            label="Organization"
                            style={{ width: '80%', margin: '5px' }}
                            onChange={(e) => { handleGroupChange(e.target.value) }}
                            // helperText="Select Block"
                        >
                            {getOrgList()}
                     
                        </TextField>      
                </div>
                <div className='reserve-seat-input'>
                    <TextField
                        id="standard-select-currency"
                        select
                        value={selected_block}
                        label="Select"
                        style={{ width: '80%', margin: '5px', marginTop: '20px', }}
                        onChange={(e) => { setselected_block(e.target.value) }}
                        helperText="Select Block"
                    >
                        {blockCategory.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                </div>
                <div style={{ display: 'flex' }}>
                    <div className='block-seats'>
                        <Checkbox checked={active_days[1]}
                            onChange={(e) => { handleCheckBoxChange(e, 1) }}
                        />
                        <div style={{ fontSize: '12px' }}>
                            Mon
                        </div>
                    </div>
                    <div className='block-seats'>
                        <Checkbox checked={active_days[2]}
                            onChange={(e) => { handleCheckBoxChange(e, 2) }}
                        />
                        <div style={{ fontSize: '12px' }}>
                            Tue
                        </div>
                    </div>
                    <div className='block-seats'>
                        <Checkbox checked={active_days[3]}
                            onChange={(e) => { handleCheckBoxChange(e, 3) }}
                        />
                        <div style={{ fontSize: '12px' }}>
                            Wed
                        </div>
                    </div>
                    <div className='block-seats'>
                        <Checkbox checked={active_days[4]}
                            onChange={(e) => { handleCheckBoxChange(e, 4) }}
                        />
                        <div style={{ fontSize: '12px' }}>
                            Thu
                        </div>
                    </div>
                    <div className='block-seats'>
                        <Checkbox checked={active_days[5]}
                            onChange={(e) => { handleCheckBoxChange(e, 5) }}
                        />
                        <div style={{ fontSize: '12px' }}>
                            Fri
                        </div>
                    </div>
                    <div className='block-seats'>
                        <Checkbox checked={active_days[6]}
                            onChange={(e) => { handleCheckBoxChange(e, 6) }}
                        />
                        <div style={{ fontSize: '12px' }}>
                            Sat
                        </div>
                    </div>
                    <div className='block-seats'>
                        <Checkbox checked={active_days[0]}
                            onChange={(e) => { handleCheckBoxChange(e, 0) }}
                        />
                        <div style={{ fontSize: '12px' }}>
                            Sun
                        </div>
                    </div>
                </div>
                <div className='reserve-seat-input'>
                    <button
                        onClick={(e) => {
                            createBlockedSeats(e)

                        }
                        }
                        className='field-seat-style reserve-seat-button'>
                        Select Seats
                        </button>
                </div>
            </div>
        </div>
    )
}
