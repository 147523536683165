import React from 'react';
import Header from '../AppHeader/header'
import { firebase } from '../../config/firebase';

import './site-down.css'

import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

export default function SiteDown(props) {

    const location = useLocation();
    // let error_details = {code: '', message: ''}
    const error_details = location.state;
    const history = useHistory();
    const sites_ref = firebase.firestore().collection("Sites");

    const update_site = (e) => {
        sites_ref
            .doc(error_details.commonState.site)
            .update(
                {
                    is_site_up: true
                }
            )
            .then(function (docRef) {
                // console.log("Category saved successfully ID: " + docRef.id);
                history.push("/")
            })
            .catch(function (error) {
                console.error("Error while saving site: ", error);
            });
    }



    const check_admin = () => {
        if (error_details.commonState.isSiteAdmin || error_details.commonState.isAppAdmin) {
            return (
                <div>
                    <br /><br />
                    <div className=''>
                        <div className='error_msg_try_again'
                            onClick={(e) => {
                                update_site(e)
                            }}> Click here to take the 'Site Up' </div>
                    </div>
                </div>
            )
        }
    }

    const loadError = () => {
        if (location.state)
            return (
                <div>
                    <div>
                        <Header
                            currentPage="Site Down"
                        ></Header>
                    </div>
                    <div className='error_container'>
                        <div className='error_title'>
                            <div className='error_text'>
                            </div>
                            <div
                                className='error_text error_border'
                            >IMPORTANT!
                            </div>

                            <div className='error_text'>
                            </div>

                        </div>
                        <div className='error_content'>
                            <div className=''>
                                {/* As per the communication sent by RTW dated 29 April 2022, the Pilot RTW at PRIMA BAY has been deferred. All individual seat bookings made through Smart Seat App for CP India premise will be canceled and new bookings are being suspended for the time being. We will be back soon! Take care and stay safe. */}
                                {error_details.error_text}
                            </div>
                            <br /><br />

                        </div>
                        {check_admin()}
                    </div>
                </div>
            )
    }


    return (
        <div>
            {loadError()}
        </div>

    )
}