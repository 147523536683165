import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { firebase } from '../../config/firebase';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import './seat-bookings.css';

import './seat-bookings.css';

// import safetyMaskIcon from './../../Assets/Images/safety-mask.png';
// import gatePassIcon from "./../../Assets/Images/gate-pass.png";
import healthIcon from './../../Assets/Images/health.png';
import healthIconUnSafe from './../../Assets/Images/health-unsafe.png';
// import specialSeatIcon from './../../Assets/Images/special_seat_icon.png'
import specialSeatIcon from './../../Assets/Images/chair1.png';
import qrcodeicon from './../../Assets/Images/qr-code.png';
import scanqrcodeicon from './../../Assets/Images/scan-qr-code.png';
import cancelParkingIcon from './../../Assets/Images/parking-cancel.jpg';
import warningIcon from "./../../Assets/Images/warning.png";
import Booking from './../Booking/booking';
import ReserveBlock from '../ReserveBlock/reserve-block';
import GenerateQRCode from './../GenerateQRCode/generate-qr-code';
import ScanQRCode from './../ScanQRCode/scan-qr-code';
import ReserveParking from './../ReserveParking/reserve-parking';

export default function SeatBookings(props) {
  const [showBooking, setShowBooking] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const [QRCodeScan, setQRCodeScan] = useState(false);
  const [scannedBooking, setScannedBooking] = useState(false);
  const [displayScannedBooking, setDisplayScannedBooking] = useState(false);
  const [isSpecialBookingAllowed, setisSpecialBookingAllowed] = useState(false)
  const [bookingAllowed, setBookingAllowed] = useState(true);
  const [nextBookingDate, setNextBookingDate] = useState();
  const [lastBookingDate, setLastBookingDate] = useState();
  // const [isCubicleBookingAllowed, setisCubicleBookingAllowed] = useState(true)

  // const [direct_booking_flag, set_direct_booking_flag] = useState()
  const bookingFreq = parseInt(props.commonState.site_data.bookingFrequency);

  // let healthChk;
  let bookingPass;

  const history = useHistory();
  const seat_booking_ref = firebase
    .firestore()
    .collection('SeatBookings/' + props.commonState.site + '/SeatBookings');

  const blocked_seats_ref = firebase
    .firestore()
    .collection("BlockedSeats/" + props.commonState.site + "/Blocks");
  const curr_date_timestamp = firebase.firestore.Timestamp.fromDate(
    props.commonState.currentDate
  );

  React.useEffect(() => {    
    const curr_date = new Date(props.commonState.currentDate);
    blocked_seats_ref
    .where("isActive", "==", true)
    .where("group","==", props.commonState.organization)
    .where("BlockName", "==", "Special Reservation Block")
    // .where("active_days", "arrayContains", curr_date.getDay())
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.docs.length > 0) {
        querySnapshot.forEach((doc) => {
          const block = doc.data();
          // if (block.active_days[curr_date.getDay()]) {
          if (block.active_days.includes(true)) {  
            setisSpecialBookingAllowed(true)
          }
          else {
            setisSpecialBookingAllowed(false)
          }
        })
      }
    })


    // seat_booking_ref = firebase
    // .firestore()
    // .collection(
    //   "SeatBookings/" + props.commonState.site + "/SeatBookings"
    // );


    let booking_allowed_date = new Date(props.commonState.currentDate)
    booking_allowed_date.setDate(booking_allowed_date.getDate() - bookingFreq)
    // setNextBookingDate ;


    let booking_allowed_date_timestamp = firebase.firestore.Timestamp.fromDate(
      booking_allowed_date
    );

    seat_booking_ref
    .where("booked_by_email", "==", props.commonState.userEmail)
    .where("blocked_date", ">=", booking_allowed_date_timestamp)
    .orderBy("blocked_date",'desc')
    .onSnapshot(
      //    .then(

      (querySnapshot) => {

        // console.log("querySnapshot.docs.length",querySnapshot.docs.length)
        // const booking = querySnapshot.docs[0].data()



        if(!querySnapshot.empty && bookingFreq > 1) {
          
          const booking = querySnapshot.docs[0].data()
          let booking_date = new Date(booking.blocked_date.toDate())
          setLastBookingDate(booking_date)

          let nxtDate = new Date(booking.blocked_date.toDate())
          nxtDate.setDate(booking_date.getDate() + (bookingFreq))
          setNextBookingDate(nxtDate)

          let nxtBookingDt = new Date(nxtDate)
          let today_date = new Date(props.commonState.currentDate)
          if(today_date.getTime() !== nxtBookingDt.getTime()) {
            setBookingAllowed(false);
          }

        }


        // if(querySnapshot.docs.length > 1) {
        //   setBookingAllowed(false);
        //   querySnapshot.forEach((doc) => {
        //     let booking = {...doc.data()}
        //     let booking_date = new Date(booking.blocked_date.toDate())

        //   })
        // }
      })

    // set_direct_booking_flag(props.direct_booking_flag)
  }, []);

  const toggleBooking = () => {
    setShowBooking(!showBooking);
  };

  const toggleQRCode = () => {
    setShowQRCode(!showQRCode);
  };

  const toggleQRCodeScan = () => {
    setQRCodeScan(!QRCodeScan);
  };

  const handleQRCodeScan = (scanData) => {
    const email = scanData;
    setQRCodeScan(!QRCodeScan);
    seat_booking_ref
      .where('booked_by_email', '==', email)
      .where('blocked_date', '==', curr_date_timestamp)
      .get(1)
      .then((querySnapshot) => {
        // if (querySnapshot.docs.length !== 0) {
        let booking;
        querySnapshot.forEach((doc) => {
          booking = doc.data();

          // console.log('booking', booking)
        });
        setScannedBooking(booking);
        setDisplayScannedBooking(true);
        // }
        // else {

        // }
      });
  };

  const booking_scanned = () => {
    if (displayScannedBooking) {
      return (
        <Booking
          closeBooking={() => setDisplayScannedBooking(false)}
          getPass={scannedBooking}
          healthStatus="Updated"
          commonState={props.commonState}
          gatepassType="scanned"
        />
      );
    }
  };

  const booking = () => {
    if (showBooking) {
      props.seatBookings.map((item) => {
        if (
          props.commonState.currentDate.getTime() ===
          item.blocked_date.getTime()
        ) {
          return (bookingPass = { ...item });
        }
        return true;
      });

      return (
        <Booking
          closeBooking={() => toggleBooking()}
          getPass={bookingPass}
          healthStatus={props.health}
          commonState={props.commonState}
          gatepassType="self"
        />
      );
    }
    return true;
  };

  const generateQRCode = () => {
    if (showQRCode) {
      // console.log("generateQRCode Clicked")
      return (
        <GenerateQRCode
          closeQRCode={() => toggleQRCode()}
          commonState={props.commonState}
        />
      );
    }
  };

  const scanQRCode = () => {
    if (QRCodeScan) {
      // console.log("generateQRCode Clicked")
      return (
        <ScanQRCode
          closeQRCodeScan={() => toggleQRCodeScan()}
          handleQRCodeScan={(scanData) => handleQRCodeScan(scanData)}
          commonState={props.commonState}
        />
      );
    }
  };

  const bookSpecialSeat = () => {
    let special_booking_status = 'Can book';

    props.seatBookings.map((item) => {
      if (
        props.commonState.currentDate.getTime() === item.blocked_date.getTime()
      ) {
        if (item.booking_status === 'Confirmed') {
          return (special_booking_status =
            'You already have a booking for today.');
        } else if (item.seats_blocked > item.seats_confirmed) {
          return (special_booking_status =
            'You have block booking by your Block Admin. Please select seat from the block.');
        } 
      }
      return true;
    });

    const min_date = new Date(props.commonState.site_data.booking_calendar_from.toDate());
    const maxDate = new Date(props.commonState.site_data.booking_calendar_to.toDate());

    if (special_booking_status === 'Can book') {
      if(props.commonState.currentDate < min_date || props.commonState.currentDate > maxDate){
        special_booking_status = "Special Booking is not enabled for today";
      } else if (props.commonState.site_data.need_declaration_profile) {
        // Profile - Health Declaration -- new
        if (props.profile_health_new) {
          special_booking_status = "Please update and complete the Health Declaration in your user Profile";
        }
  
        // Profile - Health Declaration -- all or some negative answers
        else if (props.profile_health_negative) {
          special_booking_status = "Your health status is not safe to visit office as per your health declaration in your user profile. Special Booking is hence restricted.";
        }
      }
    }


    if (special_booking_status === 'Can book') {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui confirm_dialog">
              <div className="confirm_title">Confirm!</div>
              <div className="confirm_msg">
                Are you sure you want to book special seat?
              </div>
              <div className="confirm_buttons">
                <button className="confirm_no" onClick={onClose}>
                  No
                </button>
                <button
                  className="confirm_yes"
                  onClick={() => {
                    onClose();
                    history.push(
                      props.commonState.site_data.need_declaration &&
                        props.health === 'Not Updated'
                        ? '/declaration'
                        : '/floorplan',
                      {
                        action: 'special_booking',
                        commonState: props.commonState,
                      }
                    );
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          );
        },
      });
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui confirm_dialog">
              <div className="confirm_title">Validation!</div>
              <div className="confirm_msg">{special_booking_status}</div>
              <div className="confirm_buttons">
                <button className="confirm_yes" onClick={onClose}>
                  OK
                </button>
              </div>
            </div>
          );
        },
      });
    }
  };

  // const bookFromReservedCat = () => {
  //   history.push(
  //     props.commonState.site_data.need_declaration && props.health === "Not Updated"
  //       ? "/declaration"
  //       : "/floorplan",
  //     {
  //       action: "reserved_category",
  //       commonState: props.commonState,
  //     }
  //   );
  // };

  const updateDeclaration = () => {
    history.push(
      props.commonState.site_data.need_declaration
        ? '/declaration'
        : '/floorplan',
      {
        action: 'update_declaration',
        commonState: props.commonState,
        // booking_obj: props.seatBooking
      }
    );
  };

  const printHealthIcon = () => {
    if (props.commonState.site_data.need_declaration) {
      if (props.health === 'Updated') {
        return (
          <img
            alt="Health Status - Safe"
            className="icon"
            style={{ width: 25, height: 25, cursor: 'pointer' }}
            src={healthIcon}
            data-tip="Health Status - Safe"
          />
        );
      } else {
        const health_status = 'Health Status - ' + props.health;
        return (
          <img
            alt="health status"
            className="icon"
            style={{ width: 25, height: 25, cursor: 'pointer' }}
            src={healthIconUnSafe}
            data-tip={health_status}
            onClick={() => {
              updateDeclaration();
            }}
          />
        );
      }
    }
  };

  const displayQRCode = () => {
      return (
        <img
        alt="QR Code"
        className="icon"
        style={{ width: 25, height: 25, cursor: 'pointer' }}
        src={qrcodeicon}
        data-tip="QR Code"
        onClick={() => {
          toggleQRCode();
        }}
      />
      )
  }

  const scan_qr_code = () => {
    if(props.commonState.isBlockAdmin) {
        return (
          <img
          alt="Scan QR Code"
          className="icon"
          style={{ width: 25, height: 25, cursor: 'pointer' }}
          src={scanqrcodeicon}
          data-tip="Scan QR Code"
          onClick={() => {
            toggleQRCodeScan();
          }}
        />
        )
    }
    else {
      return null;
    }
  }

  const allowSpecialBooking = () => {

    // if (isSpecialBookingAllowed && bookingAllowed) {
    //   return (
    //     <img
    //     alt="Special Booking"
    //     className="icon"
    //     style={{ width: 20, height: 25, cursor: 'pointer' }}
    //     src={specialSeatIcon}
    //     data-tip="Special Booking"
    //     title="Special Booking"
    //     onClick={() => {
    //       bookSpecialSeat();
    //     }}
    //   />
    //   )
    // }
    // else {
    //   return null;
    // }
  }

  const no_bookings = () => {
    if (props.seatBookings.length === 0) {
      return <div className="block-booking">No Seat Bookings</div>;
    }
  };

  const getMonth = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'Jun',
    6: 'Jul',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec',
  };

  const printNextBookingDate = (booking_dt) => {
    const blocked_date = new Date(booking_dt);
    let blocked_date_date;

    if (blocked_date.getDate() < 10) {
      blocked_date_date = '0' + blocked_date.getDate().toString();
    } else {
      blocked_date_date = blocked_date.getDate().toString();
    }

    const blocked_date_month = getMonth[blocked_date.getMonth()];

    return blocked_date_date + '-' + blocked_date_month;
  };

  const NoBookingAllowed = () => {

    if(!bookingAllowed && bookingFreq > 1) {
      return <div className="block-booking booking-not-allowed">
        You can book a seat once in {bookingFreq} days. Your last booking was on {printNextBookingDate(lastBookingDate)}. You can do next booking from {printNextBookingDate(nextBookingDate)}
      </div>;
    }
  }

  const seat_bookings_header = () => {
    return (
      <div className="seat-booking-header">
        {/* <ReactTooltip backgroundColor="#d01837" /> */}
        Seat Bookings
        <div className="headerIcons">

          {allowSpecialBooking()}
          {/* {printHealthIcon()} */}
          {/* {displayQRCode()}          */}
          {/* {scan_qr_code()} */}

        </div>
      </div>
    );
  };

  const select_seat_or_block = () => {
    if (
      props.commonState.direct_booking_flag === 'Site Level-Manual' ||
      props.commonState.direct_booking_flag === 'Reserved Cat-Manual' ||
      // props.commonState.direct_booking_flag === 'Normal Booking'
     ( props.commonState.direct_booking_flag === 'Normal Booking' && isSpecialBookingAllowed && bookingAllowed ) 
    //  ( props.commonState.direct_booking_flag === 'Normal Booking' && props.commonState.calendar_data.include_dates.length > 0 && bookingAllowed )
    ) {
        return (
        <ReserveBlock
          commonState={props.commonState}
          booking_type={props.commonState.direct_booking_flag}
          reserveSeat={(newSeat) => props.handleReseveSeat(newSeat)}
          nextBookingDate={nextBookingDate}
          health={props.health}
          seatBookings={props.seatBookings}
          isSpecialBookingAllowed={isSpecialBookingAllowed}
          profile_health_new={props.profile_health_new}
          profile_health_negative={props.profile_health_negative}
        />
      );
    } else {
      // return <SopBlock />;       // Commented SOP Block on Seat Booking Page Header
    }
  };

  const UpdateMyWS = () => {
    const updateWSAction =
      props.commonState.direct_booking_flag === 'Site Level-Auto'
        ? 'UpdateMyWS-SiteLevel'
        : 'UpdateMyWS-CategoryLevel';
    history.push('/floorplan', {
      commonState: props.commonState,
      action: updateWSAction,
    });
  };

  const generateGatepass = () => {
    const autobooking = {
      block_id: props.commonState.direct_booking_flag,
      booking_type: props.commonState.direct_booking_flag,
      blocked_by: '',
      blocked_by_email: '',
      blocked_date: new Date(props.commonState.currentDate),
      booked_on: new Date(props.commonState.currentDate),
      // booking_type: "Auto Booking",
      booking_status: 'Confirmed',
      booked_by_email: props.commonState.userEmail,
      booked_by: props.commonState.userName,
      site: props.commonState.site,
      booked_ws: props.commonState.my_ws.ws ? props.commonState.my_ws.ws : '',
      floor: props.commonState.my_ws.floor ? props.commonState.my_ws.floor : '',
      wing: props.commonState.my_ws.wing ? props.commonState.my_ws.wing : '',
      category: props.commonState.category,
    };

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui confirm_dialog">
            <div className="confirm_title">Confirm!</div>
            <div className="confirm_msg">
              Are you sure you want to generate today's gatepass?
            </div>
            <div className="confirm_buttons">
              <button className="confirm_no" onClick={onClose}>
                No
              </button>
              <button
                className="confirm_yes"
                onClick={() => {
                  const action = props.commonState.direct_booking_flag;
                  history.push(
                    props.commonState.site_data.need_declaration &&
                      ( props.health === 'Not Updated' || props.health === 'not safe' )
                      ? '/declaration'
                      : '/floorplan',
                    {
                      action: action,
                      commonState: props.commonState,
                      booking_obj: autobooking,
                    }
                  );
                  onClose();
                }}
              >
                Yes
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const gatePassButton = (infoMsg, gatepass_button) => {

    if (gatepass_button === 'Generate Gatepass' && bookingAllowed) {
      return (
        <div className="seat-booking-direct">
          <div>{infoMsg}</div>
          <div>
            <button
              onClick={() => {
                generateGatepass();
              }}
              className="button-todays-gatepass"
            >
              Generate Gatepass
            </button>
          </div>
        </div>
      );
    } else if (gatepass_button === 'Update Work Station') {
      return (
        <div className="seat-booking-direct">
          <div>{infoMsg}</div>
          <div>
            <button
              onClick={() => {
                UpdateMyWS();
              }}
              className="button-todays-gatepass"
            >
              Update Work Station
            </button>
          </div>
        </div>
      );
    } else if (gatepass_button === 'Todays Gatepass') {
      // console.log("insjkfksdfsdfh");
      return (
        <div className="seat-booking-direct">
          <div>{infoMsg}</div>
          <div>
            <button
              onClick={() => {
                toggleBooking();
              }}
              className="button-todays-gatepass"
            >
              Today's Gatepass
            </button>
          </div>
        </div>
      );
    } else if (gatepass_button === 'No Booking Day' && bookingAllowed) {
      return (
        <div className="seat-booking-direct">
          <div>{infoMsg}</div>
          <div>
            <button
              onClick={() => {
                bookSpecialSeat();
              }}
              className="button-todays-gatepass"
            >
              Special Booking
            </button>
          </div>
        </div>
      );
    }
  };

  const getpass_section = () => {
    if (props.seatBookings) {
      let infoMsg;
      if (
        props.commonState.direct_booking_flag === 'Site Level-Auto' ||
        props.commonState.direct_booking_flag === 'Reserved Cat-Auto'
      ) {
        if (
          !props.commonState.my_ws ||
          !props.commonState.my_ws.ws ||
          props.commonState.my_ws === '' ||
          props.commonState.my_ws === {}
        ) {
          infoMsg =
            'Your Work Station is not updated. Please update your work station to activate auto booking.';
          return gatePassButton(infoMsg, 'Update Work Station');
        } else if (props.WS_status_flag.status === 'Update WS') {
          infoMsg =
            'Your assigned Work Station is reserved for ' +
            props.WS_status_flag.reserved_for +
            '. Please update your work station to activate auto booking.';
          return gatePassButton(infoMsg, 'Update Work Station');
        } else {
          let flag = false;
          props.seatBookings.map((item) => {
            if (
              props.commonState.currentDate.getTime() ===
              item.blocked_date.getTime()
            ) {
              flag = true;
            }
            return true;
          });
          if (!flag) {
            const site_cat =
              props.commonState.direct_booking_flag === 'Site Level-Auto'
                ? 'site ' + props.commonState.site
                : 'category ' + props.commonState.category;
            infoMsg =
              'Your ' +
              site_cat +
              " has opted for Auto Booking of seats. Please click on below button to generate today's gatepass.";
            return gatePassButton(infoMsg, 'Generate Gatepass');
          } else {
            infoMsg =
              "Please click on Today's Gatepass to show gatepass at the office gate.";
            return gatePassButton(infoMsg, 'Todays Gatepass');
          }
        }
      } else if (
        props.commonState.direct_booking_flag === 'Reserved Cat-No Booking Day'
      ) {
        infoMsg =
          "Today is not the reserved day of your category. Please use 'Special Booking' in case you need to go office today.";
        return gatePassButton(infoMsg, 'No Booking Day');
      } else {
        if (props.commonState.direct_booking_flag === 'Site Level-Manual') {
          infoMsg =
            "Your site has enabled direct bookings by employee. Please reserve a seat and use Today's Gatepass button to show gatepass at the office gate.";
        } else if (
          props.commonState.direct_booking_flag === 'Reserved Cat-Manual'
        ) {
          infoMsg = '';
            // "Your category has enabled direct bookings by employee. Please reserve a seat and use Today's Gatepass button to show gatepass at the office gate.";
        } else {
          infoMsg =
            "Below are the bookings by your block admin. Click on Today's Gatepass to show gatepass at the office gate.";
        }

        return gatePassButton(infoMsg, 'Todays Gatepass');
      }
    }
  };

  return (
    <div className="seat-bookings">
      {select_seat_or_block()}
      {booking()}
      {booking_scanned()}
      {generateQRCode()}
      {scanQRCode()}

      <div className="seat-bookings-content">
        {seat_bookings_header()}
        {getpass_section()}
        {/* {NoBookingAllowed()} */}
        {no_bookings()}
       
        {props.seatBookings.map((item, ind) => {

      let booking_date = new Date(item.blocked_date)
      let nxtBookingDt = new Date(nextBookingDate)

      let seatSelectionAllowed = false;
      if(booking_date >= nxtBookingDt){
        seatSelectionAllowed = true;
      }
      
      if(!nextBookingDate && booking_date){
        seatSelectionAllowed = true;
      }

          return (
            <SeatBooking
              key={ind}
              health={props.health}
              profile_health_new={props.profile_health_new}
              profile_health_negative={props.profile_health_negative}
              bookingAllowed={bookingAllowed}
              commonState={props.commonState}
              seatSelectionAllowed = {seatSelectionAllowed}
              seatBooking={item}
              // selectSeatClicked={(booking)=>props.handleSelectSeat(booking)}
              cancelBookingClicked={(booking) =>
                props.handleCancelBooking(booking)
              }
            />
          );
        })}
      </div>
    </div>
  );
}

const SeatBooking = (props) => {
  const history = useHistory();

  const [showParking, setShowParking] = useState(false);
  // const [seatSelectionAllowed, setSeatSelectionAllowed] = useState(false);


  // React.useEffect(() => {
  //   // const booking = querySnapshot.docs[0].data()
  //   let booking_date = new Date(props.seatBooking.blocked_date)
  //   let nxtBookingDt = new Date(props.nextBookingDate)

  //   if(booking_date > nxtBookingDt){
  //     setSeatSelectionAllowed(true)
  //   }
  // }, []);

  const toggleParking = () => {
    setShowParking(!showParking);
  };

  const seat_booking_ref = firebase
    .firestore()
    .collection('SeatBookings/' + props.commonState.site + '/SeatBookings');

  const getMonth = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'Jun',
    6: 'Jul',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec',
  };

  const get_day = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
  };

  const getWeekDay = () => {
    const blocked_date = props.seatBooking.blocked_date;
    return get_day[blocked_date.getDay()];
  };

  const printDate = () => {
    const blocked_date = props.seatBooking.blocked_date;
    let blocked_date_date;

    if (blocked_date.getDate() < 10) {
      blocked_date_date = '0' + blocked_date.getDate().toString();
    } else {
      blocked_date_date = blocked_date.getDate().toString();
    }

    const blocked_date_month = getMonth[blocked_date.getMonth()];

    return blocked_date_date + '-' + blocked_date_month;
  };

  const printSeatWS = () => {
    if (props.seatBooking.booking_type === 'Confirmed') {
      let ws = String(props.seatBooking.booked_ws);
      let ws_text = "";
      if (ws.includes('WS'))
      {
        ws_text = 'Work Station'
      }
      else if (ws.includes('CU')) {
        ws_text = 'Cubicle'
      }
      else if (ws.includes('DIR')) {
        ws_text = 'Cabin'
      }


      if (ws) {
        // ws = ws.split('WS')[1];
        ws = ws.replace(/[^\d.-]/g, '') //remove all non-numeric characters
      }

      return (
        <div className="block-seats">
          <div style={{ fontSize: '24px' }}>{ws}</div>
          {/* <div style={{ fontSize: '12px' }}>Work Station</div> */}
          <div style={{ fontSize: '12px' }}>{ws_text}</div>
        </div>
      );
    } else {
      return (
        <div className="block-seats">
          <div style={{ fontSize: '24px' }}>
            {props.seatBooking.seats_blocked -
              props.seatBooking.seats_confirmed}
          </div>
          <div style={{ fontSize: '12px' }}>Available</div>
        </div>
      );
    }
  };

  const confirmDiallog = (msg) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui confirm_dialog">
            <div className="confirm_title">Validation</div>
            <div className="confirm_msg">
              <div className="img">
                <img
                  alt=""
                  style={{ width: 50, height: 50 }}
                  src={warningIcon}
                />
              </div>
              <div className="msg">{msg}</div>
            </div>

            <div className="confirm_buttons">
              <button className="confirm_yes" onClick={onClose}>
                OK
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const cancelBooking = (booking) => {
    props.cancelBookingClicked(booking);
  };

  const selectSeat = (booking) => {
    if (booking.seats_blocked > booking.seats_confirmed) {
      const action = 'seat_booking';
      // let redirectTo = ''
      // if(props.commonState.site_data.need_declaration && props.healthStatus === "Not Updated"){
      //   redirectTo = "/declaration"
      // }
      // else {
      //   redirectTo = "/floorplan"
      // }

      let validationFlag = false;
      if(props.commonState.site_data.need_declaration_profile){
        if(props.profile_health_new){
          validationFlag = true;
          let confirmDialogMsg = "Please update and complete the Health Declaration in your user Profile";
          confirmDiallog(confirmDialogMsg);
          // e.preventDefault();
        }      
        
        // Profile - Health Declaration -- all or some negative answers
        else if(props.profile_health_negative){
          validationFlag = true;
          let confirmDialogMsg = "Your health status is not safe to visit office as per your health declaration in your user profile. Booking is hence restricted.";
          confirmDiallog(confirmDialogMsg);
          // e.preventDefault();
        }      
      }
      if(!validationFlag){
        history.push(
          props.commonState.site_data.need_declaration &&
            (props.health === 'Not Updated' || props.health === 'not safe')
            ? '/declaration'
            : '/floorplan',
          {
            action: action,
            commonState: props.commonState,
            booking_obj: props.seatBooking,
          }
        );    
    }
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui confirm_dialog">
              <div className="confirm_title">Booking Full!</div>
              <div className="confirm_msg">
                All the seats from reserved block are full.
              </div>
              <div className="confirm_buttons">
                <button className="confirm_yes" onClick={onClose}>
                  OK
                </button>
              </div>
            </div>
          );
        },
      });
    }
  };

  const confirm_cancelBooking = (seatBooking) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui confirm_dialog">
            <div className="confirm_title">Confirm</div>
            <div className="confirm_msg">
              Are you sure you want to cancel booking?
            </div>
            <div className="confirm_buttons">
              <button className="confirm_no" onClick={onClose}>
                No
              </button>
              <button
                className="confirm_yes"
                onClick={() => {
                  cancelBooking(seatBooking);
                  onClose();
                }}
              >
                Yes
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const reserve_parking = (booking) => {
    if (showParking) {
      // console.log("reserveParking Clicked")
      return (
        <ReserveParking
          closeParking={() => toggleParking()}
          commonState={props.commonState}
          booking={booking}
        />
      );
    }
  };

  const cancel_parking = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui confirm_dialog">
            <div className="confirm_title">Confirm!</div>
            <div className="confirm_msg">
              Are you sure you want to cancel Parking Reservation?
            </div>
            <div className="confirm_buttons">
              <button className="confirm_no" onClick={onClose}>
                No
              </button>
              <button
                className="confirm_yes"
                onClick={() => {
                  seat_booking_ref
                    .where('block_id', '==', props.seatBooking.block_id)
                    .get()
                    .then(function (querySnapshot) {
                      querySnapshot.forEach(function (document) {
                        document.ref.update({
                          parking: '',
                        });
                      });
                      onClose();
                    });
                }}
              >
                Yes
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const reserve_cancel_booking = () => {
    if (props.commonState.site_data.car_parking_enabled) {
      if (props.seatBooking.parking && props.seatBooking.parking !== '') {
        return (
          <div className="cancel_parking_header">
            <div className="cancel_parking">
              Parking: {props.seatBooking.parking}
            </div>
            <img
              style={{ height: 10, width: 10, cursor: 'pointer' }}
              src={cancelParkingIcon}
              alt="Cancel Parking"
              onClick={() => cancel_parking()}
            />
            {/* {reserve_parking(props.seatBooking)} */}
          </div>
        );
      } else {
        return (
          <div>
            <div className="reserve_parking" onClick={() => toggleParking()}>
              Reserve Parking?
            </div>
            {reserve_parking(props.seatBooking)}
          </div>
        );
      }
    }
  };

  const printButton = () => {
    if (props.seatBooking.booking_type === 'Confirmed') {
      return (
        <div>
          <button
            onClick={() => confirm_cancelBooking(props.seatBooking)}
            className="button-cancel-booking"
          >
            Cancel Booking
          </button>
          {reserve_cancel_booking()}
        </div>
      );
    } else if (props.seatSelectionAllowed) {
      return (
        <button
          onClick={() => selectSeat(props.seatBooking)}
          className="button-select-seat"
        >
          Select Seat

        </button>
      );
    }
  };

  const printFloorNo = () => {
    const floor_details = props.seatBooking.wing
      ? props.seatBooking.wing + ', ' + props.seatBooking.floor
      : props.seatBooking.floor;

    return <div style={{ fontSize: '12px' }}>{floor_details}</div>;
  };

  return (
    <div className="seat-booking">
      <div className="seat-booking-title">{getWeekDay()}</div>
      <div className="seat-booking-left">
        {/* {printHealthStatus()} */}
        <div className="block-date">
          <div style={{ fontSize: '28px' }}>{printDate()}</div>
          {printFloorNo()}
        </div>
        {printSeatWS()}
      </div>
      <div className="block-booking-right">
        <div className="block-buttons">{printButton()}</div>
      </div>
    </div>
  );
};

// const SopBlock = () => {
//   return (
//     <div className="vw_sop_content">
//       <div className="vw_sop_box">
//         <div className="vw_sop_title">
//           <div className="txt_sop_title">
//             Your safety is our utmost Priority
//           </div>
//         </div>
//         <div className="vw_sop_details">
//           <div className="vw_sop_logo">
//             <img
//               alt=""
//               style={{ width: 60, height: 60 }}
//               src={safetyMaskIcon}
//             />
//           </div>
//           <div className="vw_sop_info">
//             <div className="vw_sop_info1">
//               <div className="sop_txt">
//                 Colgate values the safety and well-being of employees
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
