import React, { useState } from 'react';
import { firebase } from '../../../config/firebase';
import CategorySub from './CategorySub';
import Loader from '../../Loader/loader';
import '../../Home/home.css'


export default function CategoryMain(props) {
    const [loading, setLoading] = useState(true);
    const [blockBookings, setBlockBookings] = useState([])
    const [category_names, setcategory_names] = useState([])
    const [reload_counter, setreload_counter] = useState(0)
    let cat_temp_names = []
    const categories_ref = firebase.firestore().collection('Categories/' + props.commonState.site + '/Category');

    React.useEffect(() => {
        setLoading(true)
        categories_ref
            .orderBy('isActive', 'desc')
            .get()
            .then(
            querySnapshot => {
                const vBlockBookings = []
                querySnapshot.forEach(doc => {
                    const booking = doc.data()
                    booking.block_id = doc.id
                    vBlockBookings.push(booking)
                    cat_temp_names.push(booking.category)
                });
                setBlockBookings(vBlockBookings)
                setcategory_names([...cat_temp_names])
                setLoading(false)
            },
            error => {
                console.log(error)
            }
            )
    }, [reload_counter])


    const onReseveBlock = (newBlock) => {
        // console.log(newBlock)
    }

    const onCancelBlock = (block) => {
        categories_ref
            .doc(block.block_id)
            .delete()
            .then(function () {
                // console.log("Document successfully deleted!");
            }).catch(function (error) {
                console.error("Error removing document: ", error);
            });
        setreload_counter(reload_counter + 1)
    }

    const onReleaseSeats = (block) => {
    }

    const reload_data = (e) => {
        setreload_counter(reload_counter + 1)
    }

    const loadHomeScreen = () => {
        return <CategorySub
            commonState={props.commonState}
            blockBookings={blockBookings}
            category_names={category_names}
            reload_data={(e) => reload_data(e)}
            handleReseveBlock={(newBlock) => onReseveBlock(newBlock)}
            handleCancelBlock={(Block) => onCancelBlock(Block)}
            handleReleaseSeats={(Block) => onReleaseSeats(Block)}
        />
    }

    return (
        <div className='main-container'>
            <Loader loading={loading}></Loader>
            <div>
                {loadHomeScreen()}
            </div>
        </div>
    )
}