import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import { firebase } from '../../../config/firebase';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import './block-seats.css'

export default function EditBlkSeats(props) {

    const styles = (theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });
    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    const DialogContent = withStyles((theme) => ({
        root: {
            padding: theme.spacing(2),
        },
    }))(MuiDialogContent);

    const [open, setOpen] = React.useState(true);

    const handleCloseEdit = () => {
        props.handleEditClose();
        setOpen(false);
    };
    const history = useHistory();
    // const [noOfSeats, setNoOfSeats] = useState(1); //replaced with const
    const noOfSeats = 1;
    const [active_days, setactive_days] = useState([...props.blockBooking.active_days])
    const org_active_days = [...props.blockBooking.active_days];
    const [no_days_selected, setno_days_selected] = useState(false);
    const check_array = [false, false, false, false, false, false, false];
    const [active, setactive] = React.useState(props.blockBooking.isActive);
    const org_active = props.blockBooking.isActive;
    // const [cat_name, setcat_name] = useState('')
    const maxDate = new Date(props.commonState.currentDate)
    maxDate.setDate(maxDate.getDate() + 7)
    const blocked_seats_ref = firebase.firestore().collection('BlockedSeats/' + props.commonState.site + '/Blocks');
    const reserve_block = () => {
        if (active === true) {
            return (
                <div className="reserve-inpts">

                    
                    <div className='block-booking-right'>
                        <div className='block-seats'>
                            <Checkbox checked={active_days[1]}
                                onChange={(e) => { handleCheckBoxChange(e, 1) }}
                            />
                            <div style={{ fontSize: '12px' }}>
                                Mon
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox checked={active_days[2]}
                                onChange={(e) => { handleCheckBoxChange(e, 2) }}
                            />
                            <div style={{ fontSize: '12px' }}>
                                Tue
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox checked={active_days[3]}
                                onChange={(e) => { handleCheckBoxChange(e, 3) }}
                            />
                            <div style={{ fontSize: '12px' }}>
                                Wed
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox checked={active_days[4]}
                                onChange={(e) => { handleCheckBoxChange(e, 4) }}
                            />
                            <div style={{ fontSize: '12px' }}>
                                Thu
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox checked={active_days[5]}
                                onChange={(e) => { handleCheckBoxChange(e, 5) }}
                            />
                            <div style={{ fontSize: '12px' }}>
                                Fri
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox checked={active_days[6]}
                                onChange={(e) => { handleCheckBoxChange(e, 6) }}
                            />
                            <div style={{ fontSize: '12px' }}>
                                Sat
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox checked={active_days[0]}
                                onChange={(e) => { handleCheckBoxChange(e, 0) }}
                            />
                            <div style={{ fontSize: '12px' }}>
                                Sun
                    </div>
                        </div>
                        
                    </div>
                </div>
            )
        }
    }
    const handleCheckBoxChange = (e, index) => {
        // console.log(active_days)
        // console.log(e.target.value)
        // console.log(index)
        let temp_arr = [...active_days]
        temp_arr[index] = !active_days[index];
        setactive_days([...temp_arr])

    }

    const handleCatChange = (e) => {
        //Commented for cleanup
        // setcat_name(e.target.value)
    }

    const ChangeCatSeats = (e) => {
        // console.log('Clicked change seats')
        // console.log(props.blockBooking.block_id);
        history.push('/floorplan', {
            action: 'reserve_blocked_seat',
            commonState: props.commonState,
            active_days: active_days,
            selected_block: props.blockBooking.BlockName,
            floor_data: {...props.blockBooking.floor},
            group: props.blockBooking.group,
            new_cat : false,
            re_select: false,
            doc_id : props.blockBooking.block_id,
        })
    }

    const SelectSeatsforCat = () => {
        if (JSON.stringify(active_days) === JSON.stringify(check_array)) {
            setno_days_selected(true);
        } else {
            // console.log('select fresh seats')
            history.push('/floorplan', {
            action: 'reserve_blocked_seat',
            commonState: props.commonState,
            blockedSeatName: "Test",
            floor_data: {},
            doc_id : props.blockBooking.block_id,
            active_days: active_days,
            selected_block: props.blockBooking.BlockName,
            noOfSeats: noOfSeats,
            new_cat: false,
            re_select: false,
        })
    }
    }

    const SelectFreshSeats = () => {
        // console.log('re-selecting seats')
        history.push('/floorplan', {
            action: 'reserve_blocked_seat',
            commonState: props.commonState,
            active_days: active_days,
            selected_block: props.blockBooking.BlockName,
            floor_data: {},
            new_cat : false,
            group: props.blockBooking.group,
            doc_id : props.blockBooking.block_id,
            re_select : true,
        })
    }

    const display_error_msg = () => {
        if (no_days_selected) {
            return (
                <div style={{ fontSize: '12px', fontWeight: 'bold', textAlign: 'center', color:'red' }}>
                    Select atleast one active day!
                </div>
            )
        }

    }

    const SaveNewChanges = () => {
        // console.log(props.commonState.userName)
        blocked_seats_ref
        .doc(props.blockBooking.block_id)
        .set(
            {
                active_days: [false, false, false, false, false, false, false],
                BlockName: props.blockBooking.BlockName,
                floor: {},
                isActive: false,
                no_of_seats: 0,
                last_updated_by: props.commonState.userName,
                last_updated_on: new Date()
            }
        )
        .then(function (docRef) {
            // console.log("Blocked seats saved successfully ID: " + docRef.id);
        })
        .catch(function (error) {
            console.error("Error while saving blocked seats: ", error);
        });
        handleCloseEdit();
    props.reload_data(1);
    }

    const display_button = () => {

        if (org_active === true) {
            if (active === true) {
                
                if ( JSON.stringify(active_days) !== JSON.stringify(org_active_days)) {

                    return(
                    <div className='reserve-input'>
                        <button
                            onClick={(e) => SelectFreshSeats(e)}
                            // style={{width:'30%'}}
                            className='edit-cat-field-style reserve-button'>
                            Re-select Seats
                            </button>
                        
                    </div>)
                }
                else {
                    return (
                        <div className='reserve-input'>
                            <button
                                onClick={(e) => ChangeCatSeats(e)}
                                // style={{width:'30%'}}
                                className='edit-cat-field-style reserve-button'>
                                Change Seats
                            </button>
                            
                        </div>
                    )
                }
            }
            else {
                return (
                    <div className='reserve-input'>
                        <button
                            onClick={(e) => SaveNewChanges(e)}
                            className='edit-cat-field-style reserve-button'>

                            Save Changes
                                 </button>
                    </div>
                )
            }

        }
        else {
            if (active === true) {
                // let temp_arr = [false, true, true, true, true,true, false]
                // setactive_days([...temp_arr])
                return (
                    <div className='reserve-input'>
                        <button
                            onClick={(e) => SelectSeatsforCat(e)}
                            // style={{width:'30%'}}
                            className='edit-cat-field-style reserve-button'>
                            Select Seats & Save
                        </button>
                        {display_error_msg()}
                    </div>)
            }
           
        }
    }

    return (

        <div >
            <Dialog onClose={handleCloseEdit} aria-labelledby="customized-dialog-title" open={open}>
                <div className="new_question_main">
                    <div className="new_question_main">
                        <DialogTitle id="customized-dialog-title" onClose={handleCloseEdit}>
                            Edit Blocked Seats Details
                    </DialogTitle>
                    </div>
                    <DialogContent dividers style={{ width: '100%' }}>
                        <div className='edit-cat-items' >
                            <div >
                                <TextField
                                    id="category_name"
                                    label="Block Category"
                                    disabled
                                    // value={props.blockBooking.BlockName}
                                    value="Special Reservation Block"
                                    onChange={(e) => { handleCatChange(e) }}
                                // helperText="Category Name"
                                >
                                </TextField>

                            </div>
                            <div className='block-seats'>
                            <Switch checked={active} onChange={(e) => setactive(!active)} />
                              
                            <div style={{ fontSize: '12px' }}>
                                Active?
                        </div></div>
                           
                            {reserve_block()}
                            {display_button()}


                        </div>
                    </DialogContent>
                </div>
            </Dialog>


        </div>
    )
}
