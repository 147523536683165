import React, { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { firebase } from '../../../config/firebase';
import TextField from '@material-ui/core/TextField';
import './admin-declaration.css';


export default function AddNewQuesChild(props) {
    // const [nextq, setnextq] = useState(0);
    let queno;
    const declaration_ref = firebase
    .firestore()
    .collection(
        props.mode + "Questions/" + props.site + "/Questions")
   
    
    useEffect(() => {
        // console.log(props.mode)
        declaration_ref
            .get()
            .then(
            querySnapshot => {
                queno = querySnapshot.size + 1;
                // console.log(queno);
                // setnextq(queno);
            }
            )
    }, []);


    const [site_floor, setsite_floor] = React.useState(['', '']);
    const [question, setQuestion] = useState('');
    const [question_no, setQuestion_no] = useState(queno);
    const [answer, setAnswer] = useState('');
    const formRef = React.useRef();


    const addfloor = () => {
        let temp_floor_arr = [...site_floor];
        temp_floor_arr.push('');
        setsite_floor([...temp_floor_arr]);
        // console.log(site_floor)
    }


    const saveSiteData = () => {

        const formValidate = formRef.current.reportValidity();

        if (formValidate === true) {

            const saveNewQues =
                {
                    Options: [...site_floor ],
                    Que_No: question_no,
                    Question: question,
                    SafeAns: answer,
                    SelectedAns: '',
                    last_updated_at: new Date(),
                    last_updated_by: props.userName
                }
            // console.log(saveNewQues);
            // console.log(props)
            declaration_ref
                .add({
                    ...saveNewQues
                })
                .then(function (docRef) {
                    // console.log('Question saved');
                })
                .catch(function (error) {
                    console.error('Error while saving question');
                });
            props.handleCloseNew();
        }

    }


    const deletefloor = (event, index) => {
        let temp_que_arr = [...site_floor];
        temp_que_arr.splice(index, 1);
        setsite_floor([...temp_que_arr]);
    }

    const handleQuesChange = (e) => {
        const temp_ques = e.target.value;
        setQuestion(temp_ques);
    }

    const handleQuesNoChange = (e) => {
        const temp_ques = e.target.value;
        setQuestion_no(temp_ques);
    }


    const handleAnsChange = (e) => {
        const temp_ans = e.target.value;
        setAnswer(temp_ans);
    }

    const handleChangeFloor = (e, index) => {
        const temp_floor_1 = e.target.value;
        let temp_arr = [...site_floor];
        temp_arr[index] = temp_floor_1;
        setsite_floor([...temp_arr]);
    }

    return (
        <div >
            <div className="new_question_main">
                <form ref={formRef} style={{ width: '100%' }}>
                <div className="question-container">
                        <TextField
                            value={question_no}
                            required
                            onChange={(e) => handleQuesNoChange(e)}
                            className="question"
                            style={{margin:'5px'}}
                            label="Question Number" />
                    </div>
                    <div className="question-container">
                        <TextField
                            value={question}
                            required
                            onChange={(e) => handleQuesChange(e)}
                            className="question"
                            // color="#d01837"
                            style={{margin:'5px'}}
                            label="Question" />
                    </div>
                    {site_floor.map((floor, index) => {
                        return (
                            <div key={index} className="question-container">
                                <TextField
                                    value={site_floor[index]}
                                    required
                                    onChange={(e) => handleChangeFloor(e, index)}
                                    className="option"
                                    style={{margin:'5px'}}
                                    label="Option" />
                                {index === 0 ?
                                    (
                                        <div onClick={(e) => { addfloor(e, index) }}>
                                            <Tooltip title="Add option">
                                                <IconButton aria-label="Add floor">
                                                    <AddIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    ) : (
                                        <div onClick={(e) => { deletefloor(e, index) }}>
                                            <Tooltip title="Delete option">
                                                <IconButton aria-label="delete floor">
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    )}
                            </div>
                        )
                    })}
                    <div className="question-container">
                        <TextField
                            value={answer}
                            required
                            onChange={(e) => handleAnsChange(e)}
                            className="answer"
                            style={{margin:'5px'}}
                            label="Safe Answer" />
                    </div>
                    <br />
                    <div className="question-container">
                        <button
                            type="button"
                            onClick={saveSiteData}
                            className='dec-field-style_new dec-save-button_new'>
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

