import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import './../FloorPlan/floor-plan.css'

export default function CGBSFloorNine (props) {

    // const [floorState, setFloorState] = useState(props.floorState)
    const floorState = props.floorState;

    // const [selectedWS, setSelectedWS]= useState()

    useEffect(() => {
                // props.totalSeats(Object.keys(floorState).length)
        let seat_count=0
       Object.keys(floorState).forEach((ws)=>{
            if(document.getElementById(ws)){
                seat_count++;
            }
        })
        props.totalSeats(seat_count)

        let selectedAttibs = [];
        props.seatAttributes.map((attr) => {
          if (attr.class === "attribute-selected") {
            selectedAttibs.push(attr.label);
          }
          return true;
        })

        Object.keys(floorState).forEach((ws)=>{
            if(document.getElementById(ws)){
                const tooltip_text = floorState[ws].tooltip ? floorState[ws].tooltip : floorState[ws].status
                document.getElementById(ws).setAttribute("data-tip", tooltip_text);
                document.getElementById(ws+'_text').setAttribute("data-tip", tooltip_text);

                document.getElementById(ws).classList.remove("attrib-selected");
                if (floorState[ws].attributes) {                    
                    let checkAttribute = selectedAttibs.every(v => floorState[ws].attributes.includes(v))
                    if(checkAttribute && selectedAttibs.length > 0) {
                        document.getElementById(ws).classList.add("attrib-selected");
                    }
                }
            }
      })
      });

    //   const colors= {
    //     floorBackground: 'white'
    // }

    const seatSelected = (e) => {
        
        props.seatSelected(e)
        
    }

return (
    <div>
              <ReactTooltip 
            backgroundColor='#d01837'
            />
    <svg viewBox="0 0 800 1450">
      <g>
         <rect x="-1" y="-1" width="800" height="1450" id="canvas_background" fill="#999999"  strokeWidth="1.5" />
      </g>  

      <g>
                            <rect transform="rotate(-180 401.6954345703125,734.937255859375) " fill="#faebd7" strokeWidth="1.5" x="21.93522" y="61.0514" width="759.52045" height="1347.7717" id="svg_2" />
                            <line fill="none" x1="176.42983" y1="138.49811" x2="176.42983" y2="168.22617" id="svg_47" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line stroke="#999999" fill="none" x1="79.1881" y1="138.11219" x2="79.1881" y2="167.84024" id="svg_48" strokeLinejoin="null" strokeLinecap="null" />
                            <line stroke="#999999" fill="none" x1="27.98787" y1="138.14418" x2="224.19963" y2="138.14418" id="svg_56" strokeLinejoin="null" strokeLinecap="null" />
                            <rect stroke="#f1f1f1" fill="#f1f1f1" strokeWidth="1.5" x="28.97674" y="61.63412" width="194.32045" height="1346.23745" id="svg_91a" />
                            <rect stroke="#cccccc" fill="#cccccc" strokeWidth="1.5" x="28.97674" y="61.63412" width="194.32045" height="75.23745" id="svg_91" />
                            <rect id="WS499" data-tip="2342" className={floorState.WS499.status} onClick={(e)=>{seatSelected(e)}} x="182.56994" y="143.31356" width="35.71396" height="19.92463" />
                            <rect id="WS498" data-tip="" className={floorState.WS498.status} onClick={(e)=>{seatSelected(e)}} x="134.14338" y="143.31497" width="35.71396" height="19.92463" />
                            <rect id="WS497" data-tip="" className={floorState.WS497.status} onClick={(e)=>{seatSelected(e)}} x="85" y="143.33244" width="35.71396" height="19.92463" />
                            <rect id="WS496" data-tip="" className={floorState.WS496.status} onClick={(e)=>{seatSelected(e)}} x="36.60402" y="143.6895" width="35.71396" height="19.92463" />
                            <rect id="WS491" data-tip="" className={floorState.WS491.status} onClick={(e)=>{seatSelected(e)}} x="182.56994" y="220.31356" width="35.71396" height="19.92463" />
                            <rect id="WS490" data-tip="" className={floorState.WS490.status} onClick={(e)=>{seatSelected(e)}} x="134.14338" y="220.31497" width="35.71396" height="19.92463" />
                            <rect id="WS489" data-tip="" className={floorState.WS489.status} onClick={(e)=>{seatSelected(e)}} x="85" y="220.33244" width="35.71396" height="19.92463" />
                            <rect id="WS488" data-tip="" className={floorState.WS488.status} onClick={(e)=>{seatSelected(e)}} x="36.60402" y="220.6895" width="35.71396" height="19.92463" />
                            <line fill="none" x1="176.42983" y1="215.83055" x2="176.42983" y2="245.55861" id="svg_100" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="79.1881" y1="215.82485" x2="79.1881" y2="245.5529" id="svg_101" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line stroke="#999999" fill="none" x1="27.9347" y1="215.47662" x2="224.19963" y2="215.47662" id="svg_108" strokeLinejoin="null" strokeLinecap="null" />
                            <line stroke="#999999" fill="none" x1="27.9347" y1="138.47662" x2="224.19963" y2="138.47662" id="svg_108" strokeLinejoin="null" strokeLinecap="null" />
                            <rect id="WS492" data-tip="" className={floorState.WS492.status} onClick={(e)=>{seatSelected(e)}} x="182.56994" y="190.40375" width="35.71396" height="19.92463" />
                            <rect id="WS493" data-tip="" className={floorState.WS493.status} onClick={(e)=>{seatSelected(e)}} x="134.14338" y="190.40516" width="35.71396" height="19.92463" />
                            <rect id="WS494" data-tip="" className={floorState.WS494.status} onClick={(e)=>{seatSelected(e)}} x="85" y="190.42263" width="35.71396" height="19.92463" />
                            <rect id="WS495" data-tip="" className={floorState.WS495.status} onClick={(e)=>{seatSelected(e)}} x="36.60402" y="190.77969" width="35.71396" height="19.92463" />
                            <line fill="none" x1="176.42983" y1="185.92074" x2="176.42983" y2="215.6488" id="svg_54" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="79.1881" y1="186.29527" x2="79.1881" y2="216.02332" id="svg_55" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="223.63604" y1="214.69136" x2="223.63604" y2="245.18408" id="svg_95" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line stroke="#999999" fill="none" x1="223.63604" y1="186" x2="223.63604" y2="215.1138" id="svg_94" strokeLinejoin="null" strokeLinecap="null" />
                            <rect id="WS476" data-tip="" className={floorState.WS476.status} onClick={(e)=>{seatSelected(e)}} x="182.56994" y="345.06862" width="35.71396" height="19.92463" />
                            <rect id="WS477" data-tip="" className={floorState.WS477.status} onClick={(e)=>{seatSelected(e)}} x="134.14338" y="345.07003" width="35.71396" height="19.92463" />
                            <rect id="WS478" data-tip="" className={floorState.WS478.status} onClick={(e)=>{seatSelected(e)}} x="85" y="345.0875" width="35.71396" height="19.92463" />
                            <rect id="WS479" data-tip="" className={floorState.WS479.status} onClick={(e)=>{seatSelected(e)}} x="36.60402" y="345.44456" width="35.71396" height="19.92463" />
                            <line fill="none" x1="176.42983" y1="340.58561" x2="176.42983" y2="370.31367" id="svg_121" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="79.1881" y1="340.96014" x2="79.1881" y2="370.6882" id="svg_122" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="223.63604" y1="339.28595" x2="223.63604" y2="369.77867" id="svg_124" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="223.63604" y1="369.49832" x2="223.63604" y2="399.99103" id="svg_140" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS475" data-tip="" className={floorState.WS475.status} onClick={(e)=>{seatSelected(e)}} x="182.56994" y="375.12051" width="35.71396" height="19.92463" />
                            <rect id="WS474" data-tip="" className={floorState.WS474.status} onClick={(e)=>{seatSelected(e)}} x="134.14338" y="375.12192" width="35.71396" height="19.92463" />
                            <rect id="WS473" data-tip="" className={floorState.WS473.status} onClick={(e)=>{seatSelected(e)}} x="85" y="375.13939" width="35.71396" height="19.92463" />
                            <rect id="WS472" data-tip="" className={floorState.WS472.status} onClick={(e)=>{seatSelected(e)}} x="36.60402" y="375.49645" width="35.71396" height="19.92463" />
                            <line fill="none" x1="176.42983" y1="370.6375" x2="176.42983" y2="400.36556" id="svg_145" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="79.1881" y1="370.44169" x2="79.1881" y2="400.16975" id="svg_146" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS468" data-tip="" className={floorState.WS468.status} onClick={(e)=>{seatSelected(e)}} x="182.56994" y="422.54314" width="35.71396" height="19.92463" />
                            <rect id="WS469" data-tip="" className={floorState.WS469.status} onClick={(e)=>{seatSelected(e)}} x="134.14338" y="422.54455" width="35.71396" height="19.92463" />
                            <rect id="WS470" data-tip="" className={floorState.WS470.status} onClick={(e)=>{seatSelected(e)}} x="85" y="422.56202" width="35.71396" height="19.92463" />
                            <rect id="WS471" data-tip="" className={floorState.WS471.status} onClick={(e)=>{seatSelected(e)}} x="36.60402" y="422.91908" width="35.71396" height="19.92463" />
                            <line fill="none" x1="176.42983" y1="418.06014" x2="176.42983" y2="447.78819" id="svg_151" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="79.1881" y1="418.43466" x2="79.1881" y2="448.16272" id="svg_152" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line stroke="#999999" fill="none" x1="27.52318" y1="370.28357" x2="224.19963" y2="370.28357" id="svg_153" strokeLinejoin="null" strokeLinecap="null" />
                            <line fill="none" x1="223.63604" y1="416.76048" x2="223.63604" y2="447.25319" id="svg_154" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="223.63604" y1="446.87812" x2="223.63604" y2="477.37084" id="svg_170" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS467" data-tip="" className={floorState.WS467.status} onClick={(e)=>{seatSelected(e)}} x="182.56994" y="452.50032" width="35.71396" height="19.92463" />
                            <rect id="WS466" data-tip="" className={floorState.WS466.status} onClick={(e)=>{seatSelected(e)}} x="134.14338" y="452.50173" width="35.71396" height="19.92463" />
                            <rect id="WS465" data-tip="" className={floorState.WS465.status} onClick={(e)=>{seatSelected(e)}} x="85" y="452.5192" width="35.71396" height="19.92463" />
                            <rect id="WS464" data-tip="" className={floorState.WS464.status} onClick={(e)=>{seatSelected(e)}} x="36.60402" y="452.87625" width="35.71396" height="19.92463" />
                            <line fill="none" x1="176.42983" y1="448.01731" x2="176.42983" y2="477.74536" id="svg_175" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="79.1881" y1="448.20173" x2="79.1881" y2="477.92978" id="svg_176" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS460" data-tip="" className={floorState.WS460.status} onClick={(e)=>{seatSelected(e)}} x="182.56994" y="499.92295" width="35.71396" height="19.92463" />
                            <rect id="WS461" data-tip="" className={floorState.WS461.status} onClick={(e)=>{seatSelected(e)}} x="134.14338" y="499.92436" width="35.71396" height="19.92463" />
                            <rect id="WS462" data-tip="" className={floorState.WS462.status} onClick={(e)=>{seatSelected(e)}} x="85" y="499.94183" width="35.71396" height="19.92463" />
                            <rect id="WS463" data-tip="" className={floorState.WS463.status} onClick={(e)=>{seatSelected(e)}} x="36.60402" y="500.29888" width="35.71396" height="19.92463" />
                            <line fill="none" x1="176.42983" y1="495.43994" x2="176.42983" y2="525.16799" id="svg_181" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="79.1881" y1="495.81447" x2="79.1881" y2="525.54252" id="svg_182" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line stroke="#999999" fill="none" x1="27.11167" y1="447.66338" x2="224.19963" y2="447.66338" id="svg_183" strokeLinejoin="null" strokeLinecap="null" />
                            <line fill="none" x1="223.63604" y1="494.14028" x2="223.63604" y2="524.633" id="svg_184" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="223.63604" y1="523.22849" x2="223.63604" y2="553.72121" id="svg_185" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS459" data-tip="" className={floorState.WS459.status} onClick={(e)=>{seatSelected(e)}} x="182.56994" y="528.85069" width="35.71396" height="19.92463" />
                            <rect id="WS458" data-tip="" className={floorState.WS458.status} onClick={(e)=>{seatSelected(e)}} x="134.14338" y="528.8521" width="35.71396" height="19.92463" />
                            <rect id="WS457" data-tip="" className={floorState.WS457.status} onClick={(e)=>{seatSelected(e)}} x="85" y="528.86957" width="35.71396" height="19.92463" />
                            <rect id="WS456" data-tip="" className={floorState.WS456.status} onClick={(e)=>{seatSelected(e)}} x="36.60402" y="529.22662" width="35.71396" height="19.92463" />
                            <line fill="none" x1="176.42983" y1="524.36768" x2="176.42983" y2="554.09573" id="svg_190" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="79.1881" y1="524.74221" x2="79.1881" y2="554.47026" id="svg_191" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS452" data-tip="" className={floorState.WS452.status} onClick={(e)=>{seatSelected(e)}} x="182.56994" y="576.27332" width="35.71396" height="19.92463" />
                            <rect id="WS453" data-tip="" className={floorState.WS453.status} onClick={(e)=>{seatSelected(e)}} x="134.14338" y="576.27473" width="35.71396" height="19.92463" />
                            <rect id="WS454" data-tip="" className={floorState.WS454.status} onClick={(e)=>{seatSelected(e)}} x="85" y="576.2922" width="35.71396" height="19.92463" />
                            <rect id="WS455" data-tip="" className={floorState.WS455.status} onClick={(e)=>{seatSelected(e)}} x="36.60402" y="576.64925" width="35.71396" height="19.92463" />
                            <line fill="none" x1="176.42983" y1="571.79031" x2="176.42983" y2="601.51836" id="svg_196" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="79.1881" y1="572.16484" x2="79.1881" y2="601.89289" id="svg_197" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line stroke="#999999" fill="none" x1="27.52318" y1="524.01375" x2="224.19963" y2="524.01375" id="svg_198" strokeLinejoin="null" strokeLinecap="null" />
                            <line fill="none" x1="223.63604" y1="570.49065" x2="223.63604" y2="600.98337" id="svg_199" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="223.59821" y1="600.44618" x2="223.59821" y2="630.9389" id="svg_230" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS451" data-tip="" className={floorState.WS451.status} onClick={(e)=>{seatSelected(e)}} x="182.53211" y="606.06838" width="35.71396" height="19.92463"  />
                            <rect id="WS450" data-tip="" className={floorState.WS450.status} onClick={(e)=>{seatSelected(e)}} x="134.10555" y="606.06979" width="35.71396" height="19.92463"  />
                            <rect id="WS449" data-tip="" className={floorState.WS449.status} onClick={(e)=>{seatSelected(e)}} x="84.96217" y="606.08726" width="35.71396" height="19.92463" />
                            <rect id="WS448" data-tip="" className={floorState.WS448.status} onClick={(e)=>{seatSelected(e)}} x="36.56619" y="606.44432" width="35.71396" height="19.92463" />
                            <line fill="none" x1="176.392" y1="601.58537" x2="176.392" y2="631.31343" id="svg_235" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="79.15027" y1="601.19945" x2="79.15027" y2="630.92751" id="svg_236" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line stroke="#999999" fill="none" x1="27.89687" y1="601.23144" x2="224.1618" y2="601.23144" id="svg_243" strokeLinejoin="null" strokeLinecap="null" />
                            <rect id="WS484" data-tip="" className={floorState.WS484.status} onClick={(e)=>{seatSelected(e)}} x="182.56994" y="267.73619" width="35.71396" height="19.92463" />
                            <rect id="WS485" data-tip="" className={floorState.WS485.status} onClick={(e)=>{seatSelected(e)}} x="134.14338" y="267.7376" width="35.71396" height="19.92463" />
                            <rect id="WS486" data-tip="" className={floorState.WS486.status} onClick={(e)=>{seatSelected(e)}} x="85" y="267.75507" width="35.71396" height="19.92463" />
                            <rect id="WS487" data-tip="" className={floorState.WS487.status} onClick={(e)=>{seatSelected(e)}} x="36.60402" y="268.11213" width="35.71396" height="19.92463" />
                            <line fill="none" x1="176.42983" y1="263.25318" x2="176.42983" y2="292.98124" id="svg_106" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="79.1881" y1="263.62771" x2="79.1881" y2="293.35576" id="svg_107" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS483" data-tip="" className={floorState.WS483.status} onClick={(e)=>{seatSelected(e)}} x="182.56994" y="297.64599" width="35.71396" height="19.92463" />
                            <rect id="WS482" data-tip="" className={floorState.WS482.status} onClick={(e)=>{seatSelected(e)}} x="134.14338" y="297.6474" width="35.71396" height="19.92463" />
                            <rect id="WS481" data-tip="" className={floorState.WS481.status} onClick={(e)=>{seatSelected(e)}} x="85" y="297.66487" width="35.71396" height="19.92463" />
                            <rect id="WS480" data-tip="" className={floorState.WS480.status} onClick={(e)=>{seatSelected(e)}} x="36.60402" y="298.02193" width="35.71396" height="19.92463" />
                            <line fill="none" x1="176.42983" y1="293.16298" x2="176.42983" y2="322.89104" id="svg_115" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="79.1881" y1="292.77706" x2="79.1881" y2="322.50511" id="svg_116" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line stroke="#999999" fill="none" x1="27.9347" y1="292.80905" x2="224.19963" y2="292.80905" id="svg_123" strokeLinejoin="null" strokeLinecap="null" />
                            <line fill="none" x1="223.63604" y1="261.95352" x2="223.63604" y2="292.44624" id="svg_109" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="223.63604" y1="292.02379" x2="223.63604" y2="322.51651" id="svg_110" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS444" data-tip="" className={floorState.WS444.status} onClick={(e)=>{seatSelected(e)}} x="182.50153" y="653.49242" width="35.71396" height="19.92463" />
                            <rect id="WS445" data-tip="" className={floorState.WS445.status} onClick={(e)=>{seatSelected(e)}} x="134.10555" y="653.49242" width="35.71396" height="19.92463" />
                            <rect id="WS446" data-tip="" className={floorState.WS446.status} onClick={(e)=>{seatSelected(e)}} x="84.96217" y="653.50989" width="35.71396" height="19.92463" />
                            <rect id="WS447" data-tip="" className={floorState.WS447.status} onClick={(e)=>{seatSelected(e)}} x="36.56619" y="653.86695" width="35.71396" height="19.92463" />
                            <line fill="none" x1="176.392" y1="649.008" x2="176.392" y2="678.73606" id="svg_241" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="79.15027" y1="649.38253" x2="79.15027" y2="679.11059" id="svg_242" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="223.59821" y1="647.70834" x2="223.59821" y2="678.20106" id="svg_244" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect fill="#f1f1f1" strokeWidth="1.5" x="576.77669" y="61.38025" width="196.13306" height="1347.24597" id="svg_446" />
                            <line transform="rotate(-180 576.64208984375,123.82288360595703) " fill="none" x1="576.64211" y1="108.57653" x2="576.64211" y2="139.06925" id="svg_3" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS519" data-tip="" className={floorState.WS519.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 599.8512573242188,123.48471832275389) " x="581.99423" y="113.5224" width="35.71396" height="19.92463" />
                            <rect id="WS520" data-tip="" className={floorState.WS520.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 648.2777709960938,123.48331451416016) " x="630.42082" y="113.521" width="35.71396" height="19.92463" />
                            <rect id="WS521" data-tip="" className={floorState.WS521.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 697.421142578125,123.4658432006836) " x="679.56418" y="113.50353" width="35.71396" height="19.92463" />
                            <rect id="WS522" data-tip="" className={floorState.WS522.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 745.817138671875,123.10877990722656) " x="727.96019" y="113.14646" width="35.71396" height="19.92463" />
                            <line transform="rotate(-180 623.8482666015625,123.06601715087889) " fill="none" x1="623.84826" y1="108.20198" x2="623.84826" y2="137.93004" id="svg_12" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 721.0900268554688,123.45195007324219) " stroke="#999999" fill="none" x1="721.09005" y1="108.58793" x2="721.09005" y2="138.31598" id="svg_13" strokeLinejoin="null" strokeLinecap="null" />
                            <rect id="WS516" data-tip="" className={floorState.WS516.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 648.27783203125,76.0606689453125) " x="630.42082" y="66.09835" width="35.71396" height="19.92463" />
                            <rect id="WS517" data-tip="" className={floorState.WS517.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 697.421142578125,76.04320526123047) " x="679.56418" y="66.08088" width="35.71396" height="19.92463" />
                            <rect id="WS518" data-tip="" className={floorState.WS518.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 745.817138671875,75.68614959716797) " x="727.96019" y="65.72383" width="35.71396" height="19.92463" />
                            <line transform="rotate(-180 623.8482666015625,75.64337921142577) " fill="none" x1="623.84826" y1="60.77935" x2="623.84826" y2="90.50741" id="svg_18" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 721.0900268554688,75.26886749267578) " fill="none" x1="721.09005" y1="60.40484" x2="721.09005" y2="90.13289" id="svg_19" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line stroke="#999999" transform="rotate(-180 697.759521484375,60.95153045654297) " fill="none" x1="623.17564" y1="60.95153" x2="772.34337" y2="60.95153" id="svg_21" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 576.5556030273438,200.7725067138672) " fill="none" x1="576.55561" y1="185.52614" x2="576.55561" y2="216.01886" id="svg_38" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS527" data-tip="" className={floorState.WS527.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 599.7647094726562,200.4343414306641) " x="581.90772" y="190.47201" width="35.71396" height="19.92463" />
                            <rect id="WS528" data-tip="" className={floorState.WS528.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 648.1912841796875,200.43293762207034) " x="630.33431" y="190.47061" width="35.71396" height="19.92463" />
                            <rect id="WS529" data-tip="" className={floorState.WS529.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 697.3346557617188,200.41546630859375) " x="679.47768" y="190.45314" width="35.71396" height="19.92463" />
                            <rect id="WS530" data-tip="" className={floorState.WS530.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 745.7306518554688,200.0583953857422) " x="727.87369" y="190.09608" width="35.71396" height="19.92463" />
                            <line transform="rotate(-180 623.7617797851562,200.0156402587891) " fill="none" x1="623.76175" y1="185.1516" x2="623.76175" y2="214.87966" id="svg_57" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 721.0035400390625,200.4015655517578) " stroke="#999999" fill="none" x1="721.00354" y1="185.53754" x2="721.00354" y2="215.26559" id="svg_58" strokeLinejoin="null" strokeLinecap="null" />
                            <rect id="WS526" data-tip="" className={floorState.WS526.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 599.7647094726562,153.01170349121097) " x="581.90772" y="143.04938" width="35.71396" height="19.92463" />
                            <rect id="WS525" data-tip="" className={floorState.WS525.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 648.1912841796875,153.01029968261722) " x="630.33431" y="143.04797" width="35.71396" height="19.92463" />
                            <rect id="WS524" data-tip="" className={floorState.WS524.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 697.3346557617188,152.99282836914062) " x="679.47768" y="143.0305" width="35.71396" height="19.92463" />
                            <rect id="WS523" data-tip="" className={floorState.WS523.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 745.7306518554688,152.63577270507812) " x="727.87369" y="142.67345" width="35.71396" height="19.92463" />
                            <line transform="rotate(-180 623.7617797851562,152.59300231933597) " fill="none" x1="623.76175" y1="137.72897" x2="623.76175" y2="167.45703" id="svg_63" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 721.0035400390625,152.21849060058594) " fill="none" x1="721.00354" y1="137.35446" x2="721.00354" y2="167.08251" id="svg_64" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 576.5556030273438,152.8208770751953) " stroke="#999999" fill="none" x1="576.55561" y1="138.26398" x2="576.55561" y2="167.37778" id="svg_65" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 674.1244506835938,137.90115356445315) " stroke="#999999" fill="none" x1="575.99197" y1="137.90115" x2="772.2569" y2="137.90115" id="svg_66" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 576.6421508789062,278.23474121093744) " fill="none" x1="576.64211" y1="262.98838" x2="576.64211" y2="293.4811" id="svg_82" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS538" data-tip="" className={floorState.WS538.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 599.851318359375,277.8965759277343) " x="581.99423" y="267.93425" width="35.71396" height="19.92463" />
                            <rect id="WS537" data-tip="" className={floorState.WS537.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 648.27783203125,277.89517211914057) " x="630.42082" y="267.93285" width="35.71396" height="19.92463" />
                            <rect id="WS536" data-tip="" className={floorState.WS536.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 697.4212036132812,277.8777160644531) " x="679.56418" y="267.91538" width="35.71396" height="19.92463" />
                            <rect id="WS535" data-tip="" className={floorState.WS535.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 745.8171997070312,277.5206604003906) " x="727.96019" y="267.55832" width="35.71396" height="19.92463" />
                            <line transform="rotate(-180 623.8483276367188,277.4778747558593) " fill="none" x1="623.84826" y1="262.61384" x2="623.84826" y2="292.3419" id="svg_87" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 721.090087890625,277.86383056640625) " stroke="#999999" fill="none" x1="721.09005" y1="262.99978" x2="721.09005" y2="292.72783" id="svg_88" strokeLinejoin="null" strokeLinecap="null" />
                            <rect id="WS534" data-tip="" className={floorState.WS534.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 599.8512573242188,230.47395324707034) " x="581.99423" y="220.51162" width="35.71396" height="19.92463" />
                            <rect id="WS533" data-tip="" className={floorState.WS533.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 648.27783203125,230.47253417968753) "  x="630.42082" y="220.51021" width="35.71396" height="19.92463" />
                            <rect id="WS532" data-tip="" className={floorState.WS532.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 697.4212036132812,230.45506286621094) " x="679.56418" y="220.49274" width="35.71396" height="19.92463" />
                            <rect id="WS531" data-tip="" className={floorState.WS531.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 745.8171997070312,230.09800720214844) " x="727.96019" y="220.13568" width="35.71396" height="19.92463" />
                            <line transform="rotate(-180 623.8483276367188,230.05525207519534) " fill="none" x1="623.84826" y1="215.19121" x2="623.84826" y2="244.91927" id="svg_125" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 721.090087890625,229.6807098388672) " fill="none" x1="721.09005" y1="214.8167" x2="721.09005" y2="244.54475" id="svg_126" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 576.6421508789062,230.2831268310547) " stroke="#999999" fill="none" x1="576.64211" y1="215.72622" x2="576.64211" y2="244.84002" id="svg_127" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 674.2109985351562,215.36338806152347) " stroke="#999999" fill="none" x1="576.07847" y1="215.36339" x2="772.3434" y2="215.36339" id="svg_128" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 576.6421508789062,354.95046997070307) " fill="none" x1="576.64211" y1="339.70412" x2="576.64211" y2="370.19684" id="svg_129" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS546" data-tip="" className={floorState.WS546.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 599.8511962890625,354.61230468749994) " x="581.99423" y="344.64999" width="35.71396" height="19.92463" />
                            <rect id="WS545" data-tip="" className={floorState.WS545.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 648.27783203125,354.6109008789062) " x="630.42082" y="344.64859" width="35.71396" height="19.92463" />
                            <rect id="WS544" data-tip="" className={floorState.WS544.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 697.421142578125,354.59344482421875) " x="679.56418" y="344.63112" width="35.71396" height="19.92463" />
                            <rect id="WS543" data-tip="" className={floorState.WS543.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 745.8171997070312,354.23638916015625) " x="727.96019" y="344.27405" width="35.71396" height="19.92463" />
                            <line transform="rotate(-180 623.8483276367188,354.19360351562494) " fill="none" x1="623.84826" y1="339.32958" x2="623.84826" y2="369.05764" id="svg_134" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 721.090087890625,354.5795593261719) " stroke="#999999" fill="none" x1="721.09005" y1="339.71552" x2="721.09005" y2="369.44357" id="svg_135" strokeLinejoin="null" strokeLinecap="null" />
                            <rect id="WS539" data-tip="" className={floorState.WS539.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 599.8512573242188,307.18969726562494) " x="581.99423" y="297.22736" width="35.71396" height="19.92463" />
                            <rect id="WS540" data-tip="" className={floorState.WS540.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 648.27783203125,307.1882934570312) " x="630.42082" y="297.22594" width="35.71396" height="19.92463" />
                            <rect id="WS541" data-tip="" className={floorState.WS541.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 697.4212036132812,307.1708068847656) " x="679.56418" y="297.20847" width="35.71396" height="19.92463" />
                            <rect id="WS542" data-tip="" className={floorState.WS542.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 745.8171997070312,306.8137512207031) " x="727.96019" y="296.85142" width="35.71396" height="19.92463" />
                            <line transform="rotate(-180 623.8483276367188,306.77099609374994) " fill="none" x1="623.84826" y1="291.90694" x2="623.84826" y2="321.635" id="svg_155" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 721.090087890625,306.3964538574219) " fill="none" x1="721.09005" y1="291.53244" x2="721.09005" y2="321.26049" id="svg_156" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 576.6421508789062,306.9988708496093) " stroke="#999999" fill="none" x1="576.64211" y1="292.44195" x2="576.64211" y2="321.55575" id="svg_157" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 674.2109985351562,292.0791625976562) " stroke="#999999" fill="none" x1="576.07847" y1="292.07913" x2="772.3434" y2="292.07913" id="svg_158" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 576.64208984375,431.91113281249994) " fill="none" x1="576.64212" y1="416.66476" x2="576.64212" y2="447.15748" id="svg_204" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS554" data-tip="" className={floorState.WS554.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 599.8512573242188,431.5729675292968) " x="581.99423" y="421.61063" width="35.71396" height="19.92463" />
                            <rect id="WS553" data-tip="" className={floorState.WS553.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 648.2777709960938,431.57156372070307) " x="630.42082" y="421.60923" width="35.71396" height="19.92463" />
                            <rect id="WS552" data-tip="" className={floorState.WS552.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 697.421142578125,431.5540771484375) " x="679.56418" y="421.59176" width="35.71396" height="19.92463" />
                            <rect id="WS551" data-tip="" className={floorState.WS551.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 745.817138671875,431.197021484375) " x="727.96019" y="421.23469" width="35.71396" height="19.92463" />
                            <line transform="rotate(-180 623.8482666015625,431.1542663574218) " fill="none" x1="623.84826" y1="416.29022" x2="623.84826" y2="446.01828" id="svg_209" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 721.0900268554688,431.5401916503906) " stroke="#999999" fill="none" x1="721.09005" y1="416.67616" x2="721.09005" y2="446.40421" id="svg_210" strokeLinejoin="null" strokeLinecap="null" />
                            <rect id="WS547" data-tip="" className={floorState.WS547.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 599.8511962890625,384.1503295898437) " x="581.99423" y="374.188" width="35.71396" height="19.92463" />
                            <rect id="WS548" data-tip="" className={floorState.WS548.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 648.27783203125,384.1488952636718) " x="630.42082" y="374.18658" width="35.71396" height="19.92463" />
                            <rect id="WS549" data-tip="" className={floorState.WS549.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 697.421142578125,384.1314392089844) " x="679.56418" y="374.16911" width="35.71396" height="19.92463" />
                            <rect id="WS550" data-tip="" className={floorState.WS550.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 745.817138671875,383.7743835449219) " x="727.96019" y="373.81206" width="35.71396" height="19.92463" />
                            <line transform="rotate(-180 623.8482666015625,383.7316284179687) " fill="none" x1="623.84826" y1="368.86759" x2="623.84826" y2="398.59565" id="svg_215" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 721.0900268554688,383.3570861816406) " fill="none" x1="721.09005" y1="368.49308" x2="721.09005" y2="398.22113" id="svg_216" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 576.64208984375,383.95950317382807) " stroke="#999999" fill="none" x1="576.64212" y1="369.40259" x2="576.64212" y2="398.51639" id="svg_217" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 674.2109375,369.0397644042968) " stroke="#999999" fill="none" x1="576.07847" y1="369.03977" x2="772.3434" y2="369.03977" id="svg_218" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 576.6951904296875,508.67004394531244) " fill="none" x1="576.69518" y1="493.42372" x2="576.69518" y2="523.91644" id="svg_219" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS562" data-tip="" className={floorState.WS562.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 599.9043579101562,508.33190917968744) " x="582.04729" y="498.36959" width="35.71396" height="19.92463" />
                            <rect id="WS561" data-tip="" className={floorState.WS561.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 648.3308715820312,508.3305053710937) " x="630.47388" y="498.36819" width="35.71396" height="19.92463" />
                            <rect id="WS560" data-tip="" className={floorState.WS560.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 697.4742431640625,508.31298828125) " x="679.61725" y="498.35072" width="35.71396" height="19.92463" />
                            <rect id="WS559" data-tip="" className={floorState.WS559.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 745.8702392578125,507.95599365234375) " x="728.01326" y="497.99366" width="35.71396" height="19.92463" />
                            <line transform="rotate(-180 623.9013671875,507.91320800781244) " fill="none" x1="623.90133" y1="493.04918" x2="623.90133" y2="522.77724" id="svg_224" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 721.1431274414062,508.29913330078125) " stroke="#999999" fill="none" x1="721.14312" y1="493.43512" x2="721.14312" y2="523.16317" id="svg_225" strokeLinejoin="null" strokeLinecap="null" />
                            <rect id="WS555" data-tip="" className={floorState.WS555.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 599.904296875,460.9092712402343) " x="582.04729" y="450.94696" width="35.71396" height="19.92463" />
                            <rect id="WS556" data-tip="" className={floorState.WS556.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 648.3308715820312,460.90786743164057) " x="630.47388" y="450.94555" width="35.71396" height="19.92463" />
                            <rect id="WS557" data-tip="" className={floorState.WS557.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 697.4742431640625,460.890380859375) " x="679.61725" y="450.92808" width="35.71396" height="19.92463" />
                            <rect id="WS558" data-tip="" className={floorState.WS558.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 745.8702392578125,460.5333251953125) " x="728.01326" y="450.57102" width="35.71396" height="19.92463" />
                            <line transform="rotate(-180 623.9013671875,460.4905700683593) " fill="none" x1="623.90133" y1="445.62655" x2="623.90133" y2="475.35461" id="svg_245" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 721.1431274414062,460.1160888671875) " fill="none" x1="721.14312" y1="445.25204" x2="721.14312" y2="474.98009" id="svg_246" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 576.6951904296875,460.7184448242187) " stroke="#999999" fill="none" x1="576.69518" y1="446.16156" x2="576.69518" y2="475.27536" id="svg_247" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 674.2640380859375,445.79870605468744) " stroke="#999999" fill="none" x1="576.13154" y1="445.79873" x2="772.39647" y2="445.79873" id="svg_248" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 576.64208984375,585.993896484375) " fill="none" x1="576.64212" y1="570.74758" x2="576.64212" y2="601.2403" id="svg_279" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS570" data-tip="" className={floorState.WS570.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 599.8512573242188,585.65576171875) " x="581.99423" y="575.69344" width="35.71396" height="19.92463" />
                            <rect id="WS569" data-tip="" className={floorState.WS569.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 648.27783203125,585.6544189453125) " x="630.42082" y="575.69205" width="35.71396" height="19.92463" />
                            <rect id="WS568" data-tip="" className={floorState.WS568.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 697.4212036132812,585.6369018554688) " x="679.56418" y="575.67457" width="35.71396" height="19.92463" />
                            <rect id="WS567" data-tip="" className={floorState.WS567.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 745.8171997070312,585.2798461914062) " x="727.96019" y="575.31751" width="35.71396" height="19.92463" />
                            <line transform="rotate(-180 623.8482666015625,585.237060546875) " fill="none" x1="623.84826" y1="570.37303" x2="623.84826" y2="600.10109" id="svg_284" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 721.0900268554688,585.6229858398438) " stroke="#999999" fill="none" x1="721.09005" y1="570.75897" x2="721.09005" y2="600.48702" id="svg_285" strokeLinejoin="null" strokeLinecap="null" />
                            <rect id="WS563" data-tip="" className={floorState.WS563.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 599.8512573242188,538.233154296875) " x="581.99423" y="528.27081" width="35.71396" height="19.92463" />
                            <rect id="WS564" data-tip="" className={floorState.WS564.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 648.2777709960938,538.2317504882812) " x="630.42082" y="528.2694" width="35.71396" height="19.92463" />
                            <rect id="WS565" data-tip="" className={floorState.WS565.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 697.421142578125,538.2142333984375) " x="679.56418" y="528.25193" width="35.71396" height="19.92463" />
                            <rect id="WS566" data-tip="" className={floorState.WS566.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 745.817138671875,537.857177734375) " x="727.96019" y="527.89488" width="35.71396" height="19.92463" />
                            <line transform="rotate(-180 623.8482666015625,537.8143920898438) " fill="none" x1="623.84826" y1="522.9504" x2="623.84826" y2="552.67846" id="svg_290" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 721.0900268554688,537.4398803710938) " fill="none" x1="721.09005" y1="522.57589" x2="721.09005" y2="552.30394" id="svg_291" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 576.64208984375,538.0422973632812) " stroke="#999999" fill="none" x1="576.64212" y1="523.48541" x2="576.64212" y2="552.59921" id="svg_292" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 674.2109375,523.12255859375) " stroke="#999999" fill="none" x1="576.07847" y1="523.12258" x2="772.3434" y2="523.12258" id="svg_293" strokeLinejoin="null" strokeLinecap="null" />
                            {/* <rect id="WS571" data-tip="" className={floorState.WS571.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 648.3832397460938,615.8027954101562) "  x="582.09969" y="605.84195" width="35.71396" height="19.92463" /> */}
                            <rect id="WS572" data-tip="" className={floorState.WS572.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 648.38330078125,615.8028564453125) " x="630.52628" y="605.84054" width="35.71396" height="19.92463" />
                            <rect id="WS573" data-tip="" className={floorState.WS573.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 697.526611328125,615.785400390625) " x="679.66964" y="605.82307" width="35.71396" height="19.92463" />
                            <rect id="WS574" data-tip="" className={floorState.WS574.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 745.9226684570312,615.4283447265625) " x="728.06566" y="605.46601" width="35.71396" height="19.92463" />
                            <line transform="rotate(-180 623.9537353515625,615.0914306640625) " fill="none" x1="623.95372" y1="600.22742" x2="623.95372" y2="629.95548" id="svg_305" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 721.1954956054688,615.0110473632812) " fill="none" x1="721.19551" y1="600.14703" x2="721.19551" y2="629.87508" id="svg_306" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 576.74755859375,615.6134033203125) " stroke="#999999" fill="none" x1="576.74758" y1="601.05655" x2="576.74758" y2="630.17035" id="svg_307" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 674.31640625,600.6937255859375) " stroke="#999999" fill="none" x1="576.18393" y1="600.69372" x2="772.44886" y2="600.69372" id="svg_308" strokeLinejoin="null" strokeLinecap="null" />
                            {/* <rect id="svg_560" height="152.08012" width="731.63368" y="679.17133" x="31.22134" strokeWidth="1.5" fill="#f1f1f1" /> */}
                            <line fill="none" x1="29.97568" y1="678.27001" x2="223.85969" y2="678.27001" id="svg_562" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect fill="#f1f1f1" strokeWidth="1.5" x="274.67111" y="62.53635" width="251.75854" height="177.29421" id="svg_567" />
                            <rect id="WS500" data-tip="" className={floorState.WS500.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(90 291.1442260742188,84.34234619140628) " x="273.28723" y="74.38003" width="35.71396" height="19.92463" />
                            <rect id="WS503" data-tip="" className={floorState.WS503.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(90 291.1442260742188,126.70169067382815) " x="273.28723" y="116.73937" width="35.71396" height="19.92463" />
                            <rect id="WS501" data-tip="" className={floorState.WS501.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(90 317.30453491210943,83.9204025268555) " x="299.44756" y="73.95809" width="35.71396" height="19.92463" />
                            <rect id="WS504" data-tip="" className={floorState.WS504.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(90 291.1441955566407,175.4815826416016) " x="273.28723" y="165.51926" width="35.71396" height="19.92463"  />
                            <rect id="WS505" data-tip="" className={floorState.WS505.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(90 317.30453491210943,175.05964660644534) " x="299.44756" y="165.09732" width="35.71396" height="19.92463"  />
                            <rect id="WS507" data-tip="" className={floorState.WS507.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(90 291.1442260742188,217.25372314453128) " x="273.28723" y="207.2914" width="35.71396" height="19.92463"  />
                            <rect id="WS506" data-tip="" className={floorState.WS506.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(90 317.30453491210943,216.83178710937503) " x="299.44756" y="206.86946" width="35.71396" height="19.92463" />
                            <rect id="WS502" data-tip="" className={floorState.WS502.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(90 316.8825988769532,126.95837402343753) " x="299.02562" y="116.99606" width="35.71396" height="19.92463" />
                            <line stroke="#999999" fill="none" x1="274.14396" y1="151.39962" x2="334.49075" y2="151.39962" id="svg_583" strokeLinejoin="null" strokeLinecap="null" />
                            <line fill="none" x1="176.42983" y1="138.49811" x2="176.42983" y2="168.22617" id="svg_584" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="79.42983" y1="138.49811" x2="79.42983" y2="168.22617" id="svg_5a" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="223.63604" y1="138.49811" x2="223.63604" y2="168.22617" id="svg_584" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line stroke="#999999" fill="none" x1="274.74206" y1="61.20151" x2="274.74206" y2="101.90003" id="svg_585" strokeLinejoin="null" strokeLinecap="null" />
                            <line stroke="#999999" fill="none" x1="274.74206" y1="110.5686" x2="274.74206" y2="151.26712" id="svg_586" strokeLinejoin="null" strokeLinecap="null" />
                            <line stroke="#999999" fill="none" x1="274.14396" y1="61.39962" x2="334.49075" y2="61.39962" id="svg_587" strokeLinejoin="null" strokeLinecap="null" />
                            <line stroke="#999999" fill="none" x1="274.74206" y1="150.5686" x2="274.74206" y2="191.26712" id="svg_588" strokeLinejoin="null" strokeLinecap="null" />
                            <line stroke="#999999" fill="none" x1="274.74206" y1="200.5686" x2="274.74206" y2="241.26712" id="svg_589" strokeLinejoin="null" strokeLinecap="null" />
                            <line stroke="#999999" fill="none" x1="274.14396" y1="241.09668" x2="334.49075" y2="241.09668" id="svg_590" strokeLinejoin="null" strokeLinecap="null" />
                            <rect stroke="#cccccc" fill="#cccccc" strokeWidth="1.5" x="333.81858" y="61.48345" width="132.51102" height="179.17179" id="svg_591" />
                            <rect id="WS509" data-tip="" className={floorState.WS509.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-90 510.3989868164063,218.12629699707028) " x="492.54197" y="208.16399" width="35.71396" height="19.92463" />
                            <rect id="WS508" data-tip="" className={floorState.WS508.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-90 484.23861694335943,218.5482482910156) " x="466.38163" y="208.58593" width="35.71396" height="19.92463" />
                            <line transform="rotate(-180 497.22576904296875,179.39279174804688) " stroke="#999999" fill="none" x1="467.05237" y1="179.39279" x2="527.39916" y2="179.39279" id="svg_600" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 526.801025390625,220.91787719726562) " stroke="#999999" fill="none" x1="526.80105" y1="200.56862" x2="526.80105" y2="241.26714" id="svg_601" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 497.22576904296875,241.06903076171875) " stroke="#999999" fill="none" x1="467.05237" y1="241.06903" x2="527.39916" y2="241.06903" id="svg_603" strokeLinejoin="null" strokeLinecap="null" />
                            <line stroke="#999999" transform="rotate(-180 496.795654296875,61.371978759765625) " fill="none" x1="467.05235" y1="61.37198" x2="526.53893" y2="61.37198" id="svg_606" strokeLinejoin="null" strokeLinecap="null" />
                            <text stroke="999999" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="16" id="svg_609" y="90.91032" x="-62.16441" strokeOpacity="null" strokeWidth="0" fill="#000000">COLLABORATION</text>
                            <text stroke="999999" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="16" id="svg_611" y="112.63136" x="-32.87825" strokeOpacity="null" strokeWidth="0" fill="#000000">ROOM</text>
                            <line stroke="#999999" fill="none" x1="333.3193" y1="61.30839" x2="333.3193" y2="241.23018" id="svg_612" strokeLinejoin="null" strokeLinecap="null" />
                            <line stroke="#999999" fill="none" x1="466.88383" y1="60.9298" x2="466.88383" y2="241.23018" id="svg_613" strokeLinejoin="null" strokeLinecap="null" />
                            <text stroke="999999" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="16" id="svg_614" y="170.94697" x="443.37125" strokeOpacity="null" strokeWidth="0" fill="#000000">TRAINING ROOM - 2</text>
                            <rect stroke="#b2b2b2" fill="#cccccc" strokeWidth="1.5" x="275.55215" y="281.95099" width="250.61865" height="184.02434" id="svg_615" />
                            <rect fill="#fff" strokeWidth="1.5" strokeOpacity="null" x="61.85532" y="871.71721" width="0" height="5.15461" id="svg_616" stroke="#000" />
                            <line fill="none" x1="223.63604" y1="830.03936" x2="223.63604" y2="860.53208" id="svg_617" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS440" data-tip="" className={floorState.WS440.status} onClick={(e)=>{seatSelected(e)}} x="182.56994" y="835.66156" width="35.71396" height="19.92463" />
                            <rect id="WS441" data-tip="" className={floorState.WS441.status} onClick={(e)=>{seatSelected(e)}} x="134.14338" y="835.66297" width="35.71396" height="19.92463" />
                            <rect id="WS442" data-tip="" className={floorState.WS442.status} onClick={(e)=>{seatSelected(e)}} x="85" y="835.68044" width="35.71396" height="19.92463" />
                            <rect id="WS443" data-tip="" className={floorState.WS443.status} onClick={(e)=>{seatSelected(e)}} x="36.60402" y="836.0375" width="35.71396" height="19.92463" />
                            <line fill="none" x1="176.42983" y1="831.17855" x2="176.42983" y2="860.90661" id="svg_622" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line stroke="#999999" fill="none" x1="79.1881" y1="830.79262" x2="79.1881" y2="860.52067" id="svg_623" strokeLinejoin="null" strokeLinecap="null" />
                            <line stroke="#999999" fill="none" x1="27.98787" y1="830.82462" x2="224.19963" y2="830.82462" id="svg_624" strokeLinejoin="null" strokeLinecap="null" />
                            <rect id="WS432" data-tip="" className={floorState.WS432.status} onClick={(e)=>{seatSelected(e)}} x="182.56994" y="912.994" width="35.71396" height="19.92463" />
                            <rect id="WS433" data-tip="" className={floorState.WS433.status} onClick={(e)=>{seatSelected(e)}} x="134.14338" y="912.99541" width="35.71396" height="19.92463" />
                            <rect id="WS434" data-tip="" className={floorState.WS434.status} onClick={(e)=>{seatSelected(e)}} x="85" y="913.01288" width="35.71396" height="19.92463" />
                            <rect id="WS435" data-tip="" className={floorState.WS435.status} onClick={(e)=>{seatSelected(e)}} x="36.60402" y="913.36994" width="35.71396" height="19.92463" />
                            <line fill="none" x1="176.42983" y1="908.51099" x2="176.42983" y2="938.23905" id="svg_629" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="79.1881" y1="908.50529" x2="79.1881" y2="938.23334" id="svg_630" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line stroke="#999999" fill="none" x1="27.9347" y1="908.15706" x2="224.19963" y2="908.15706" id="svg_631" strokeLinejoin="null" strokeLinecap="null" />
                            <rect id="WS439" data-tip="" className={floorState.WS439.status} onClick={(e)=>{seatSelected(e)}} x="182.56994" y="883.08419" width="35.71396" height="19.92463" />
                            <rect id="WS438" data-tip="" className={floorState.WS438.status} onClick={(e)=>{seatSelected(e)}} x="134.14338" y="883.0856" width="35.71396" height="19.92463" />
                            <rect id="WS437" data-tip="" className={floorState.WS437.status} onClick={(e)=>{seatSelected(e)}} x="85" y="883.10307" width="35.71396" height="19.92463" />
                            <rect id="WS436" data-tip="" className={floorState.WS436.status} onClick={(e)=>{seatSelected(e)}} x="36.60402" y="883.46013" width="35.71396" height="19.92463" />
                            <line fill="none" x1="176.42983" y1="878.60118" x2="176.42983" y2="908.32924" id="svg_636" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="79.1881" y1="878.97571" x2="79.1881" y2="908.70376" id="svg_637" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="223.63604" y1="907.3718" x2="223.63604" y2="937.86452" id="svg_638" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line stroke="#999999" fill="none" x1="223.63604" y1="878.68044" x2="223.63604" y2="907.79424" id="svg_639" strokeLinejoin="null" strokeLinecap="null" />
                            <rect id="WS423" data-tip="" className={floorState.WS423.status} onClick={(e)=>{seatSelected(e)}} x="182.56994" y="1037.74906" width="35.71396" height="19.92463" />
                            <rect id="WS422" data-tip="" className={floorState.WS422.status} onClick={(e)=>{seatSelected(e)}} x="134.14338" y="1037.75047" width="35.71396" height="19.92463" />
                            <rect id="WS421" data-tip="" className={floorState.WS421.status} onClick={(e)=>{seatSelected(e)}} x="85" y="1037.76794" width="35.71396" height="19.92463" />
                            <rect id="WS420" data-tip="" className={floorState.WS420.status} onClick={(e)=>{seatSelected(e)}} x="36.60402" y="1038.125" width="35.71396" height="19.92463" />
                            <line fill="none" x1="176.42983" y1="1033.26605" x2="176.42983" y2="1062.99411" id="svg_644" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="79.1881" y1="1033.64058" x2="79.1881" y2="1063.36864" id="svg_645" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="223.63604" y1="1031.96639" x2="223.63604" y2="1062.45911" id="svg_646" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="223.63604" y1="1062.17876" x2="223.63604" y2="1092.67147" id="svg_647" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS416" data-tip="" className={floorState.WS416.status} onClick={(e)=>{seatSelected(e)}} x="182.56994" y="1067.80095" width="35.71396" height="19.92463"  />
                            <rect id="WS417" data-tip="" className={floorState.WS417.status} onClick={(e)=>{seatSelected(e)}} x="134.14338" y="1067.80236" width="35.71396" height="19.92463"  />
                            <rect id="WS418" data-tip="" className={floorState.WS418.status} onClick={(e)=>{seatSelected(e)}} x="85" y="1067.81983" width="35.71396" height="19.92463"  />
                            <rect id="WS419" data-tip="" className={floorState.WS419.status} onClick={(e)=>{seatSelected(e)}} x="36.60402" y="1068.17689" width="35.71396" height="19.92463"  />
                            <line fill="none" x1="176.42983" y1="1063.31794" x2="176.42983" y2="1093.046" id="svg_652" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="79.1881" y1="1063.12213" x2="79.1881" y2="1092.85019" id="svg_653" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS415" data-tip="" className={floorState.WS415.status} onClick={(e)=>{seatSelected(e)}} x="182.56994" y="1115.22358" width="35.71396" height="19.92463" />
                            <rect id="WS414" data-tip="" className={floorState.WS414.status} onClick={(e)=>{seatSelected(e)}} x="134.14338" y="1115.22499" width="35.71396" height="19.92463" />
                            <rect id="WS413" data-tip="" className={floorState.WS413.status} onClick={(e)=>{seatSelected(e)}} x="85" y="1115.24246" width="35.71396" height="19.92463" />
                            <rect id="WS412" data-tip="" className={floorState.WS412.status} onClick={(e)=>{seatSelected(e)}} x="36.60402" y="1115.59952" width="35.71396" height="19.92463" />
                            <line fill="none" x1="176.42983" y1="1110.74058" x2="176.42983" y2="1140.46863" id="svg_658" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="79.1881" y1="1111.1151" x2="79.1881" y2="1140.84316" id="svg_659" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line stroke="#999999" fill="none" x1="27.52318" y1="1062.96401" x2="224.19963" y2="1062.96401" id="svg_660" strokeLinejoin="null" strokeLinecap="null" />
                            <line fill="none" x1="223.63604" y1="1109.44092" x2="223.63604" y2="1139.93363" id="svg_661" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="223.63604" y1="1139.55856" x2="223.63604" y2="1170.05128" id="svg_662" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect x="182.56994" y="1145.18076" width="35.71396" height="19.92463" id="svg_663" />
                            <rect x="134.14338" y="1145.18217" width="35.71396" height="19.92463" id="svg_664" />
                            <rect x="85" y="1145.19964" width="35.71396" height="19.92463" id="svg_665" />
                            <rect x="36.60402" y="1145.55669" width="35.71396" height="19.92463" id="svg_666" />
                            <line fill="none" x1="176.42983" y1="1140.69775" x2="176.42983" y2="1170.4258" id="svg_667" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="79.1881" y1="1140.88216" x2="79.1881" y2="1170.61021" id="svg_668" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect x="182.56994" y="1192.60339" width="35.71396" height="19.92463" id="svg_669" />
                            <rect x="134.14338" y="1192.6048" width="35.71396" height="19.92463" id="svg_670" />
                            <rect x="85" y="1192.62227" width="35.71396" height="19.92463" id="svg_671" />
                            <rect x="36.60402" y="1192.97932" width="35.71396" height="19.92463" id="svg_672" />
                            <line fill="none" x1="176.42983" y1="1188.12038" x2="176.42983" y2="1217.84843" id="svg_673" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="79.1881" y1="1188.49491" x2="79.1881" y2="1218.22296" id="svg_674" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line stroke="#999999" fill="none" x1="27.11167" y1="1140.34382" x2="224.19963" y2="1140.34382" id="svg_675" strokeLinejoin="null" strokeLinecap="null" />
                            <line fill="none" x1="223.63604" y1="1186.82072" x2="223.63604" y2="1217.31344" id="svg_676" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="223.63604" y1="1215.90893" x2="223.63604" y2="1246.40165" id="svg_677" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect x="182.56994" y="1221.53113" width="35.71396" height="19.92463" id="svg_678" />
                            <rect x="134.14338" y="1221.53254" width="35.71396" height="19.92463" id="svg_679" />
                            <rect x="85" y="1221.55001" width="35.71396" height="19.92463" id="svg_680" />
                            <rect x="36.60402" y="1221.90706" width="35.71396" height="19.92463" id="svg_681" />
                            <line fill="none" x1="176.42983" y1="1217.04812" x2="176.42983" y2="1246.77617" id="svg_682" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="79.1881" y1="1217.42265" x2="79.1881" y2="1247.1507" id="svg_683" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line stroke="#999999" fill="none" x1="27.52318" y1="1216.69419" x2="224.19963" y2="1216.69419" id="svg_684" strokeLinejoin="null" strokeLinecap="null" />
                            <rect id="WS431" data-tip="" className={floorState.WS431.status} onClick={(e)=>{seatSelected(e)}} x="182.56994" y="960.41663" width="35.71396" height="19.92463" />
                            <rect id="WS430" data-tip="" className={floorState.WS430.status} onClick={(e)=>{seatSelected(e)}} x="134.14338" y="960.41804" width="35.71396" height="19.92463" />
                            <rect id="WS429" data-tip="" className={floorState.WS429.status} onClick={(e)=>{seatSelected(e)}} x="85" y="960.43551" width="35.71396" height="19.92463" />
                            <rect id="WS428" data-tip="" className={floorState.WS428.status} onClick={(e)=>{seatSelected(e)}} x="36.60402" y="960.79257" width="35.71396" height="19.92463" />
                            <line fill="none" x1="176.42983" y1="955.93362" x2="176.42983" y2="985.66168" id="svg_689" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="79.1881" y1="956.30815" x2="79.1881" y2="986.0362" id="svg_690" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS424" data-tip="" className={floorState.WS424.status} onClick={(e)=>{seatSelected(e)}} x="182.56994" y="990.32643" width="35.71396" height="19.92463" />
                            <rect id="WS425" data-tip="" className={floorState.WS425.status} onClick={(e)=>{seatSelected(e)}} x="134.14338" y="990.32784" width="35.71396" height="19.92463" />
                            <rect id="WS426" data-tip="" className={floorState.WS426.status} onClick={(e)=>{seatSelected(e)}} x="85" y="990.34531" width="35.71396" height="19.92463" />
                            <rect id="WS427" data-tip="" className={floorState.WS427.status} onClick={(e)=>{seatSelected(e)}} x="36.60402" y="990.70237" width="35.71396" height="19.92463" />
                            <line fill="none" x1="176.42983" y1="985.84342" x2="176.42983" y2="1015.57148" id="svg_695" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="79.1881" y1="985.45749" x2="79.1881" y2="1015.18554" id="svg_696" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line stroke="#999999" fill="none" x1="27.9347" y1="985.48949" x2="224.19963" y2="985.48949" id="svg_697" strokeLinejoin="null" strokeLinecap="null" />
                            <line fill="none" x1="223.63604" y1="954.63396" x2="223.63604" y2="985.12668" id="svg_698" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="223.63604" y1="984.70423" x2="223.63604" y2="1015.19695" id="svg_699" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="176.42983" y1="831.17855" x2="176.42983" y2="860.90661" id="svg_700" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS571" data-tip="" className={floorState.WS571.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 600.2359008789062,615.9254150390625) " x="582.37885" y="605.96311" width="35.71396" height="19.92463" />
                            <line transform="rotate(-180 577.504150390625,854.097900390625) " fill="none" x1="577.50419" y1="838.85158" x2="577.50419" y2="869.3443" id="svg_702" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS315" data-tip="" className={floorState.WS315.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 600.7133178710938,853.759765625) " x="582.8563" y="843.79745" width="35.71396" height="19.92463" />
                            <rect id="WS316" data-tip="" className={floorState.WS316.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 649.139892578125,853.7584228515625) " x="631.28289" y="843.79605" width="35.71396" height="19.92463" />
                            <rect id="WS317" data-tip="" className={floorState.WS317.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 698.2832641601562,853.7409057617188) " x="680.42625" y="843.77858" width="35.71396" height="19.92463" />
                            <rect id="WS318" data-tip="" className={floorState.WS318.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 746.6792602539062,853.3838500976562) " x="728.82226" y="843.42151" width="35.71396" height="19.92463" />
                            <line transform="rotate(-180 624.7103271484375,853.341064453125) " fill="none" x1="624.71033" y1="838.47704" x2="624.71033" y2="868.2051" id="svg_707" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 721.9520874023438,853.7269897460938) " stroke="#999999" fill="none" x1="721.95212" y1="838.86298" x2="721.95212" y2="868.59103" id="svg_708" strokeLinejoin="null" strokeLinecap="null" />
                            <rect transform="rotate(-180 649.2453002929688,883.9067993164062) " x="582.96176" y="873.94596" width="35.71396" height="19.92463" id="svg_709" />
                            <rect id="WS321" data-tip="" className={floorState.WS321.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 649.245361328125,883.9068603515625) " x="631.38835" y="873.94454" width="35.71396" height="19.92463" />
                            <rect id="WS320" data-tip="" className={floorState.WS320.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 698.388671875,883.889404296875) " x="680.53171" y="873.92707" width="35.71396" height="19.92463"  />
                            <rect id="WS319" data-tip="" className={floorState.WS319.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 746.7847290039062,883.5323486328125) " x="728.92773" y="873.57002" width="35.71396" height="19.92463" />
                            <line transform="rotate(-180 624.8157958984375,883.1954345703125) " fill="none" x1="624.81579" y1="868.33143" x2="624.81579" y2="898.05949" id="svg_713" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 722.0575561523438,883.1150512695312) " fill="none" x1="722.05758" y1="868.25103" x2="722.05758" y2="897.97908" id="svg_714" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 577.609619140625,883.7174072265625) " stroke="#999999" fill="none" x1="577.60965" y1="869.16055" x2="577.60965" y2="898.27435" id="svg_715" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 675.178466796875,868.7977294921875) " stroke="#999999" fill="none" x1="577.04601" y1="868.79773" x2="773.31093" y2="868.79773" id="svg_716" strokeLinejoin="null" strokeLinecap="null" />
                            <rect id="WS322" data-tip="" className={floorState.WS322.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 601.0979614257812,884.0294189453125) " x="583.24092" y="874.06711" width="35.71396" height="19.92463" />
                            <line transform="rotate(-180 577.5042114257814,929.95166015625) " fill="none" x1="577.50418" y1="914.70529" x2="577.50418" y2="945.19801" id="svg_719" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS327" data-tip="" className={floorState.WS327.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 600.7133789062501,929.613525390625) " x="582.8563" y="919.65116" width="35.71396" height="19.92463"  />
                            <rect id="WS328" data-tip="" className={floorState.WS328.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 649.1398925781251,929.6121215820312) " x="631.28289" y="919.64976" width="35.71396" height="19.92463"  />
                            <rect id="WS329" data-tip="" className={floorState.WS329.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 698.2832641601564,929.5946655273438) " x="680.42625" y="919.63229" width="35.71396" height="19.92463"  />
                            <rect id="WS330" data-tip="" className={floorState.WS330.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 746.6792602539064,929.2376098632812) " x="728.82226" y="919.27522" width="35.71396" height="19.92463" />
                            <line transform="rotate(-180 624.7103881835939,929.19482421875) " fill="none" x1="624.71033" y1="914.33074" x2="624.71033" y2="944.0588" id="svg_724" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 721.9521484375001,929.5807495117188) " stroke="#999999" fill="none" x1="721.95212" y1="914.71669" x2="721.95212" y2="944.44474" id="svg_725" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 577.5041503906251,1006.9122924804688) " fill="none" x1="577.50419" y1="991.66593" x2="577.50419" y2="1022.15865" id="svg_726" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS341" data-tip="" className={floorState.WS341.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 600.7133178710939,1006.5741577148438) " x="582.8563" y="996.6118" width="35.71396" height="19.92463"  />
                            <rect id="WS342" data-tip="" className={floorState.WS342.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 649.1398315429689,1006.57275390625) " x="631.28289" y="996.6104" width="35.71396" height="19.92463"  />
                            <rect id="WS343" data-tip="" className={floorState.WS343.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 698.2832031250001,1006.5552368164062) " x="680.42625" y="996.59293" width="35.71396" height="19.92463"  />
                            <rect id="WS344" data-tip="" className={floorState.WS344.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 746.6791992187501,1006.1981811523438) " x="728.82226" y="996.23586" width="35.71396" height="19.92463"  />
                            <line transform="rotate(-180 624.7103271484376,1006.1554565429688) " fill="none" x1="624.71033" y1="991.29139" x2="624.71033" y2="1021.01945" id="svg_731" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 721.9520874023439,1006.5413818359375) " stroke="#999999" fill="none" x1="721.95212" y1="991.67733" x2="721.95212" y2="1021.40538" id="svg_732" strokeLinejoin="null" strokeLinecap="null" />
                            <rect id="WS334" data-tip="" className={floorState.WS334.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 600.7132568359376,959.1515502929688) " x="582.8563" y="949.18917" width="35.71396" height="19.92463" />
                            <rect id="WS333" data-tip="" className={floorState.WS333.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 649.1398925781251,959.1500854492188) " x="631.28289" y="949.18775" width="35.71396" height="19.92463" />
                            <rect id="WS332" data-tip="" className={floorState.WS332.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 698.2832031250001,959.1326293945312) " x="680.42625" y="949.17028" width="35.71396" height="19.92463" />
                            <rect id="WS331" data-tip="" className={floorState.WS331.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 746.6791992187501,958.7755737304688) " x="728.82226" y="948.81323" width="35.71396" height="19.92463" />
                            <line transform="rotate(-180 624.7103271484376,958.7328491210938) " fill="none" x1="624.71033" y1="943.86876" x2="624.71033" y2="973.59682" id="svg_737" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 721.9520874023439,958.3582763671875) " fill="none" x1="721.95212" y1="943.49425" x2="721.95212" y2="973.2223" id="svg_738" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 577.5041503906251,958.960693359375) " stroke="#999999" fill="none" x1="577.50419" y1="944.40376" x2="577.50419" y2="973.51756" id="svg_739" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 675.0729980468751,944.0409545898438) " stroke="#999999" fill="none" x1="576.94054" y1="944.04094" x2="773.20547" y2="944.04094" id="svg_740" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 577.5572509765626,1083.671142578125) " fill="none" x1="577.55725" y1="1068.42489" x2="577.55725" y2="1098.91761" id="svg_741" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS349" data-tip="" className={floorState.WS349.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 600.7664184570314,1083.3330078125) " x="582.90936" y="1073.37076" width="35.71396" height="19.92463" />
                            <rect id="WS350" data-tip="" className={floorState.WS350.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 649.1929321289064,1083.3316650390625) " x="631.33595" y="1073.36936" width="35.71396" height="19.92463" />
                            <rect id="WS351" data-tip="" className={floorState.WS351.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 698.3363037109376,1083.3140869140625) " x="680.47932" y="1073.35189" width="35.71396" height="19.92463" />
                            <rect id="WS352" data-tip="" className={floorState.WS352.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 746.7323608398439,1082.9571533203125) " x="728.87533" y="1072.99482" width="35.71396" height="19.92463" />
                            <line transform="rotate(-180 624.7634277343751,1082.914306640625) " fill="none" x1="624.76339" y1="1068.05035" x2="624.76339" y2="1097.77841" id="svg_746" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 722.0051879882814,1083.30029296875) " stroke="#999999" fill="none" x1="722.00519" y1="1068.43629" x2="722.00519" y2="1098.16434" id="svg_747" strokeLinejoin="null" strokeLinecap="null" />
                            <rect id="WS348" data-tip="" className={floorState.WS348.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 600.7663574218751,1035.910400390625) " x="582.90936" y="1025.94813" width="35.71396" height="19.92463" />
                            <rect id="WS347" data-tip="" className={floorState.WS347.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 649.1929321289064,1035.9090576171875) " x="631.33595" y="1025.94672" width="35.71396" height="19.92463"  />
                            <rect id="WS346" data-tip="" className={floorState.WS346.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 698.3363037109376,1035.8914794921875) " x="680.47932" y="1025.92925" width="35.71396" height="19.92463" />
                            <rect id="WS345" data-tip="" className={floorState.WS345.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 746.7322998046876,1035.534423828125) " x="728.87533" y="1025.57219" width="35.71396" height="19.92463" />
                            <line transform="rotate(-180 624.7634277343751,1035.49169921875) " fill="none" x1="624.76339" y1="1020.62772" x2="624.76339" y2="1050.35578" id="svg_752" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 722.0051879882814,1035.1171875) " fill="none" x1="722.00519" y1="1020.25321" x2="722.00519" y2="1049.98126" id="svg_753" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 577.5572509765626,1035.7196044921875) " stroke="#999999" fill="none" x1="577.55725" y1="1021.16273" x2="577.55725" y2="1050.27653" id="svg_754" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 675.1260986328126,1020.7998657226562) " stroke="#999999" fill="none" x1="576.99361" y1="1020.7999" x2="773.25854" y2="1020.7999" id="svg_755" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 577.5041503906251,1160.9951171875) " fill="none" x1="577.50419" y1="1145.74875" x2="577.50419" y2="1176.24147" id="svg_756" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS357" data-tip="" className={floorState.WS357.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 600.7133178710939,1160.656982421875) " x="582.8563" y="1150.69461" width="35.71396" height="19.92463" />
                            <rect id="WS358" data-tip="" className={floorState.WS358.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 649.1398925781251,1160.6556396484375) " x="631.28289" y="1150.69322" width="35.71396" height="19.92463" />
                            <rect id="WS359" data-tip="" className={floorState.WS359.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 698.2832641601564,1160.637939453125) " x="680.42625" y="1150.67574" width="35.71396" height="19.92463" />
                            <rect id="WS360" data-tip="" className={floorState.WS360.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 746.6792602539064,1160.281005859375) " x="728.82226" y="1150.31868" width="35.71396" height="19.92463" />
                            <line transform="rotate(-180 624.7103271484376,1160.23828125) " fill="none" x1="624.71033" y1="1145.3742" x2="624.71033" y2="1175.10226" id="svg_761" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 721.9521484375001,1160.6241455078125) " stroke="#999999" fill="none" x1="721.95212" y1="1145.76014" x2="721.95212" y2="1175.48819" id="svg_762" strokeLinejoin="null" strokeLinecap="null" />
                            <rect id="WS356" data-tip="" className={floorState.WS356.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 600.7133178710939,1113.234375) " x="582.8563" y="1103.27198" width="35.71396" height="19.92463" />
                            <rect id="WS355" data-tip="" className={floorState.WS355.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 649.1398315429689,1113.23291015625) " x="631.28289" y="1103.27057" width="35.71396" height="19.92463" />
                            <rect id="WS354" data-tip="" className={floorState.WS354.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 698.2832031250001,1113.2154541015625) " x="680.42625" y="1103.2531" width="35.71396" height="19.92463" />
                            <rect id="WS353" data-tip="" className={floorState.WS353.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 746.6791992187501,1112.8583984375) " x="728.82226" y="1102.89605" width="35.71396" height="19.92463" />
                            <line transform="rotate(-180 624.7103271484376,1112.8155517578125) " fill="none" x1="624.71033" y1="1097.95157" x2="624.71033" y2="1127.67963" id="svg_767" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 721.9520874023439,1112.4410400390625) " fill="none" x1="721.95212" y1="1097.57706" x2="721.95212" y2="1127.30511" id="svg_768" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 577.5041503906251,1113.04345703125) " stroke="#999999" fill="none" x1="577.50419" y1="1098.48658" x2="577.50419" y2="1127.60038" id="svg_769" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 675.0729980468751,1098.123779296875) " stroke="#999999" fill="none" x1="576.94054" y1="1098.12375" x2="773.20547" y2="1098.12375" id="svg_770" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 675.1784667968751,1175.6949462890625) " stroke="#999999" fill="none" x1="577.046" y1="1175.69489" x2="773.31093" y2="1175.69489" id="svg_778" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 689.7681884765626,1180.61279296875) " fill="none" x1="606.22545" y1="1180.61278" x2="773.31087" y2="1180.61278" id="svg_4" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            {/* <line transform="rotate(-180 721.9521484375001,1160.6241455078125) " stroke="#999999" fill="none" x1="721.95212" y1="1145.76014" x2="721.95212" y2="1175.48819" id="svg_6" strokeLinejoin="null" strokeLinecap="null" /> */}
                            <line transform="rotate(-180 606.8734741210939,1195.377197265625) " stroke="#999999" fill="none" x1="606.87342" y1="1180.51325" x2="606.87342" y2="1210.2413" id="svg_9" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 772.6893920898439,1195.4326171875) " stroke="#999999" fill="none" x1="772.68933" y1="1180.56852" x2="772.68933" y2="1210.29657" id="svg_22" strokeLinejoin="null" strokeLinecap="null" />
                            <rect id="WS364" data-tip="" className={floorState.WS364.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 628.7447509765626,1195.5028076171875) " x="610.88807" y="1185.54061" width="35.71396" height="19.92463" />
                            <rect id="WS363" data-tip="" className={floorState.WS363.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 668.9785156250001,1195.5028076171875) " x="651.11676" y="1185.54061" width="35.71396" height="19.92463" />
                            <rect id="WS362" data-tip="" className={floorState.WS362.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 710.5512084960939,1195.5028076171875) " x="692.68642" y="1185.54061" width="35.71396" height="19.92463" />
                            <rect id="WS361" data-tip="" className={floorState.WS361.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 750.9765625000001,1195.5028076171875) " x="733.10668" y="1185.54061" width="35.71396" height="19.92463" />
                            <line transform="rotate(-180 689.8316650390626,1195.377197265625) " stroke="#999999" fill="none" x1="689.82117" y1="1180.51325" x2="689.82117" y2="1210.2413" id="svg_28" strokeLinejoin="null" strokeLinecap="null" />
                            <g id="svg_37" transform="rotate(-180 689.5766601562501,1249.0408935546875) ">
                                <line fill="none" x1="605.45919" y1="1242.10522" x2="772.54461" y2="1242.10522" id="svg_29" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" transform="rotate(-180 689.0019531250001,1242.1009521484375) " />
                                <line stroke="#999999" fill="none" x1="606.10716" y1="1242.00569" x2="606.10716" y2="1271.73374" id="svg_30" strokeLinejoin="null" strokeLinecap="null" transform="rotate(-180 606.1072387695314,1256.8653564453125) " />
                                <line transform="rotate(-180 771.9231567382814,1256.9207763671875) " stroke="#999999" fill="none" x1="771.92306" y1="1242.06096" x2="771.92306" y2="1271.78901" id="svg_31" strokeLinejoin="null" strokeLinecap="null" />
                                <rect id="WS368" data-tip="" className={floorState.WS368.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 627.9785156250001,1256.990966796875) " x="610.1218" y="1247.03305" width="35.71396" height="19.92463" />
                                <rect id="WS367" data-tip="" className={floorState.WS367.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 668.2122802734376,1256.990966796875) " x="650.3505" y="1247.03305" width="35.71396" height="19.92463" />
                                <rect id="WS366" data-tip="" className={floorState.WS366.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 709.7849731445314,1256.990966796875) " x="691.92016" y="1247.03305" width="35.71396" height="19.92463" />
                                <rect id="WS365" data-tip="" className={floorState.WS365.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 750.2103271484376,1256.990966796875) " x="732.34042" y="1247.03305" width="35.71396" height="19.92463" />
                                <line transform="rotate(-180 689.0654296875001,1256.8653564453125) " stroke="#999999" fill="none" x1="689.05491" y1="1242.00569" x2="689.05491" y2="1271.73374" id="svg_36" strokeLinejoin="null" strokeLinecap="null" />
                            </g>
                            <line transform="rotate(-180 690.1513061523439,1256.0894775390625) " fill="none" x1="606.60858" y1="1256.08948" x2="773.694" y2="1256.08948" id="svg_67" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 607.2565917968751,1270.8538818359375) " stroke="#999999" fill="none" x1="607.25655" y1="1255.98995" x2="607.25655" y2="1285.718" id="svg_68" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 773.0725097656251,1270.9093017578125) " stroke="#999999" fill="none" x1="773.07246" y1="1256.04522" x2="773.07246" y2="1285.77327" id="svg_69" strokeLinejoin="null" strokeLinecap="null" />
                            <rect id="WS372" data-tip="" className={floorState.WS372.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 629.1278686523439,1270.9794921875) " x="611.2712" y="1261.01731" width="35.71396" height="19.92463" />
                            <rect id="WS371" data-tip="" className={floorState.WS371.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 669.3616333007814,1270.9794921875) " x="651.49989" y="1261.01731" width="35.71396" height="19.92463" />
                            <rect id="WS370" data-tip="" className={floorState.WS370.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 710.9343261718751,1270.9794921875) " x="693.06955" y="1261.01731" width="35.71396" height="19.92463" />
                            <rect id="WS369" data-tip="" className={floorState.WS369.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 751.3596801757814,1270.9794921875) " x="733.48981" y="1261.01731" width="35.71396" height="19.92463" />
                            <line transform="rotate(-180 690.2147827148439,1270.8538818359375) " stroke="#999999" fill="none" x1="690.2043" y1="1255.98995" x2="690.2043" y2="1285.718" id="svg_74" strokeLinejoin="null" strokeLinecap="null" />
                            <g id="svg_160" transform="rotate(-180 689.9597778320314,1324.517578125) ">
                                <line fill="none" x1="605.84232" y1="1317.58192" x2="772.92774" y2="1317.58192" id="svg_75" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" transform="rotate(-180 689.3850708007814,1317.57763671875) " />
                                <line stroke="#999999" fill="none" x1="606.49029" y1="1317.48239" x2="606.49029" y2="1347.21044" id="svg_76" strokeLinejoin="null" strokeLinecap="null" transform="rotate(-180 606.4903564453126,1332.342041015625) " />
                                <line transform="rotate(-180 772.3062744140626,1332.3974609375) " stroke="#999999" fill="none" x1="772.3062" y1="1317.53766" x2="772.3062" y2="1347.26571" id="svg_77" strokeLinejoin="null" strokeLinecap="null" />
                                <rect id="WS376" data-tip="" className={floorState.WS376.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 628.3616333007814,1332.4676513671875) " x="610.50494" y="1322.50975" width="35.71396" height="19.92463" />
                                <rect id="WS375" data-tip="" className={floorState.WS375.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 668.5953979492189,1332.4676513671875) " x="650.73363" y="1322.50975" width="35.71396" height="19.92463" />
                                <rect id="WS374" data-tip="" className={floorState.WS374.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 710.1680908203126,1332.4676513671875) " x="692.30329" y="1322.50975" width="35.71396" height="19.92463" />
                                <rect id="WS373" data-tip="" className={floorState.WS373.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 750.5934448242189,1332.4676513671875) " x="732.72355" y="1322.50975" width="35.71396" height="19.92463" />
                                <line transform="rotate(-180 689.4485473632814,1332.342041015625) " stroke="#999999" fill="none" x1="689.43804" y1="1317.48239" x2="689.43804" y2="1347.21044" id="svg_159" strokeLinejoin="null" strokeLinecap="null" />
                            </g>
                            <line transform="rotate(-180 690.5344848632814,1331.3746337890625) " fill="none" x1="606.99171" y1="1331.37462" x2="774.07713" y2="1331.37462" id="svg_161" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 607.6397705078126,1346.1390380859375) " stroke="#999999" fill="none" x1="607.63968" y1="1331.27509" x2="607.63968" y2="1361.00314" id="svg_162" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 773.4556884765626,1346.1944580078125) " stroke="#999999" fill="none" x1="773.45559" y1="1331.33036" x2="773.45559" y2="1361.05841" id="svg_163" strokeLinejoin="null" strokeLinecap="null" />
                            <rect id="WS380" data-tip="" className={floorState.WS380.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 629.5110473632814,1346.2646484375) " x="611.65433" y="1336.30245" width="35.71396" height="19.92463" />
                            <rect id="WS379" data-tip="" className={floorState.WS379.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 669.7448120117189,1346.2646484375) " x="651.88302" y="1336.30245" width="35.71396" height="19.92463" />
                            <rect id="WS378" data-tip="" className={floorState.WS378.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 711.3175048828126,1346.2646484375) " x="693.45268" y="1336.30245" width="35.71396" height="19.92463" />
                            <rect id="WS377" data-tip="" className={floorState.WS377.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 751.7428588867189,1346.2646484375) " x="733.87294" y="1336.30245" width="35.71396" height="19.92463" />
                            <line transform="rotate(-180 690.5979614257814,1346.1390380859375) " stroke="#999999" fill="none" x1="690.58743" y1="1331.27509" x2="690.58743" y2="1361.00314" id="svg_168" strokeLinejoin="null" strokeLinecap="null" />
                            <g id="svg_252" transform="rotate(-180 690.3429565429688,1399.802734375) ">
                                <line fill="none" x1="606.22545" y1="1392.86706" x2="773.31087" y2="1392.86706" id="svg_169" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" transform="rotate(-180 689.7682495117188,1392.86279296875) " />
                                <line stroke="#999999" fill="none" x1="606.87342" y1="1392.76753" x2="606.87342" y2="1422.49558" id="svg_200" strokeLinejoin="null" strokeLinecap="null" transform="rotate(-180 606.87353515625,1407.627197265625) " />
                                <line transform="rotate(-180 772.689453125,1407.6826171875) " stroke="#999999" fill="none" x1="772.68933" y1="1392.8228" x2="772.68933" y2="1422.55085" id="svg_201" strokeLinejoin="null" strokeLinecap="null" />
                                <rect id="WS381" data-tip="" className={floorState.WS381.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 628.7448120117188,1407.7528076171875) " x="610.88807" y="1397.79489" width="35.71396" height="19.92463" />
                                <rect id="WS382" data-tip="" className={floorState.WS382.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 668.9785766601562,1407.7528076171875) " x="651.11676" y="1397.79489" width="35.71396" height="19.92463" />
                                <rect id="WS383" data-tip="" className={floorState.WS383.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 710.55126953125,1407.7528076171875) " x="692.68642" y="1397.79489" width="35.71396" height="19.92463" />
                                <rect id="WS384" data-tip="" className={floorState.WS384.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(-180 750.9766235351562,1407.7528076171875) " x="733.10668" y="1397.79489" width="35.71396" height="19.92463" />
                                <line transform="rotate(-180 689.8317260742188,1407.627197265625) " stroke="#999999" fill="none" x1="689.82117" y1="1392.76753" x2="689.82117" y2="1422.49558" id="svg_251" strokeLinejoin="null" strokeLinecap="null" />
                            </g>
                            <rect fill="#cccccc" strokeWidth="1.5" x="431.76518" y="1269.63999" width="94.85642" height="138.63591" id="svg_253" stroke="#cccccc" />
                            <rect fill="#dbd9d9" strokeWidth="1.5" x="18.58644" y="1301.21887" width="157.57695" height="107.05706" id="svg_254" stroke="#dbd9d9" />
                            <rect fill="#cccccc" strokeWidth="1.5" x="177.70061" y="1269.57209" width="96.76069" height="138.70382" id="svg_255" stroke="#cccccc" />
                            <line fill="none" x1="176.42983" y1="1110.74058" x2="176.42983" y2="1140.46863" id="svg_256" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="79.1881" y1="1111.1151" x2="79.1881" y2="1140.84316" id="svg_257" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="223.63604" y1="1109.44092" x2="223.63604" y2="1139.93363" id="svg_258" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="223.63604" y1="1139.55856" x2="223.63604" y2="1170.05128" id="svg_259" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS408" data-tip="" className={floorState.WS408.status} onClick={(e)=>{seatSelected(e)}} x="182.56994" y="1145.18076" width="35.71396" height="19.92463" />
                            <rect id="WS409" data-tip="" className={floorState.WS409.status} onClick={(e)=>{seatSelected(e)}} x="134.14338" y="1145.18217" width="35.71396" height="19.92463" />
                            <rect id="WS410" data-tip="" className={floorState.WS410.status} onClick={(e)=>{seatSelected(e)}} x="85" y="1145.19964" width="35.71396" height="19.92463"  />
                            <rect id="WS411" data-tip="" className={floorState.WS411.status} onClick={(e)=>{seatSelected(e)}} x="36.60402" y="1145.55669" width="35.71396" height="19.92463" />
                            <line fill="none" x1="176.42983" y1="1140.69775" x2="176.42983" y2="1170.4258" id="svg_264" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="79.1881" y1="1140.88216" x2="79.1881" y2="1170.61021" id="svg_265" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS407" data-tip="" className={floorState.WS407.status} onClick={(e)=>{seatSelected(e)}} x="182.56994" y="1192.60339" width="35.71396" height="19.92463"  />
                            <rect id="WS406" data-tip="" className={floorState.WS406.status} onClick={(e)=>{seatSelected(e)}} x="134.14338" y="1192.6048" width="35.71396" height="19.92463" />
                            <rect id="WS405" data-tip="" className={floorState.WS405.status} onClick={(e)=>{seatSelected(e)}} x="85" y="1192.62227" width="35.71396" height="19.92463" />
                            <rect id="WS404" data-tip="" className={floorState.WS404.status} onClick={(e)=>{seatSelected(e)}} x="36.60402" y="1192.97932" width="35.71396" height="19.92463" />
                            <line fill="none" x1="176.42983" y1="1188.12038" x2="176.42983" y2="1217.84843" id="svg_270" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="79.1881" y1="1188.49491" x2="79.1881" y2="1218.22296" id="svg_271" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line stroke="#999999" fill="none" x1="27.11167" y1="1140.34382" x2="224.19963" y2="1140.34382" id="svg_272" strokeLinejoin="null" strokeLinecap="null" />
                            <line fill="none" x1="223.63604" y1="1186.82072" x2="223.63604" y2="1217.31344" id="svg_273" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="223.63604" y1="1215.90893" x2="223.63604" y2="1246.40165" id="svg_274" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS400" data-tip="" className={floorState.WS400.status} onClick={(e)=>{seatSelected(e)}} x="182.56994" y="1221.53113" width="35.71396" height="19.92463" />
                            <rect id="WS401" data-tip="" className={floorState.WS401.status} onClick={(e)=>{seatSelected(e)}} x="134.14338" y="1221.53254" width="35.71396" height="19.92463" />
                            <rect id="WS402" data-tip="" className={floorState.WS402.status} onClick={(e)=>{seatSelected(e)}} x="85" y="1221.55001" width="35.71396" height="19.92463" />
                            <rect id="WS403" data-tip="" className={floorState.WS403.status} onClick={(e)=>{seatSelected(e)}} x="36.60402" y="1221.90706" width="35.71396" height="19.92463" />
                            <line fill="none" x1="176.42983" y1="1217.04812" x2="176.42983" y2="1246.77617" id="svg_294" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="79.1881" y1="1217.42265" x2="79.1881" y2="1247.1507" id="svg_295" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line stroke="#999999" fill="none" x1="27.52318" y1="1216.69419" x2="224.19963" y2="1216.69419" id="svg_296" strokeLinejoin="null" strokeLinecap="null" />
                            <rect fill="#f1f1f1" strokeWidth="1.5" x="275.37392" y="1268.58031" width="155.71987" height="140.41426" id="svg_24" />
                            <rect id="WS388" data-tip="" className={floorState.WS388.status} onClick={(e)=>{seatSelected(e)}} x="383.0908" y="1276.65997" width="35.71396" height="19.92463" />
                            <rect id="WS389" data-tip="" className={floorState.WS389.status} onClick={(e)=>{seatSelected(e)}} x="333.94742" y="1276.67744" width="35.71396" height="19.92463" />
                            <rect id="WS390" data-tip="" className={floorState.WS390.status} onClick={(e)=>{seatSelected(e)}} x="285.55144" y="1277.0345" width="35.71396" height="19.92463" />
                            <line fill="none" x1="425.37725" y1="1272.17556" x2="425.37725" y2="1301.90361" id="svg_336" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="328.13552" y1="1272.55008" x2="328.13552" y2="1302.27814" id="svg_337" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect x="383.0908" y="1306.61715" width="35.71396" height="19.92463" id="svg_338" />
                            <rect x="333.94742" y="1306.63462" width="35.71396" height="19.92463" id="svg_339" />
                            <rect x="285.55144" y="1306.99167" width="35.71396" height="19.92463" id="svg_340" />
                            <line fill="none" x1="425.37725" y1="1302.13273" x2="425.37725" y2="1331.86078" id="svg_341" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="328.13552" y1="1302.31714" x2="328.13552" y2="1332.04519" id="svg_342" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect x="383.0908" y="1354.03978" width="35.71396" height="19.92463" id="svg_343" />
                            <rect x="333.94742" y="1354.05725" width="35.71396" height="19.92463" id="svg_344" />
                            <rect x="285.55144" y="1354.4143" width="35.71396" height="19.92463" id="svg_345" />
                            <line fill="none" x1="425.37725" y1="1349.55536" x2="425.37725" y2="1379.28341" id="svg_346" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="328.13552" y1="1349.92989" x2="328.13552" y2="1379.65794" id="svg_347" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect x="383.0908" y="1382.96752" width="35.71396" height="19.92463" id="svg_349" />
                            <rect x="333.94742" y="1382.98499" width="35.71396" height="19.92463" id="svg_350" />
                            <rect x="285.55144" y="1383.34204" width="35.71396" height="19.92463" id="svg_351" />
                            <line fill="none" x1="425.37725" y1="1378.4831" x2="425.37725" y2="1408.21115" id="svg_352" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="328.13552" y1="1378.85763" x2="328.13552" y2="1408.58568" id="svg_353" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="425.37725" y1="1272.17556" x2="425.37725" y2="1301.90361" id="svg_355" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="328.13552" y1="1272.55008" x2="328.13552" y2="1302.27814" id="svg_356" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS393" data-tip="" className={floorState.WS393.status} onClick={(e)=>{seatSelected(e)}} x="383.0908" y="1306.61715" width="35.71396" height="19.92463" />
                            <rect id="WS392" data-tip="" className={floorState.WS392.status} onClick={(e)=>{seatSelected(e)}} x="333.94742" y="1306.63462" width="35.71396" height="19.92463" />
                            <rect id="WS391" data-tip="" className={floorState.WS391.status} onClick={(e)=>{seatSelected(e)}} x="285.55144" y="1306.99167" width="35.71396" height="19.92463" />
                            <line fill="none" x1="425.37725" y1="1302.13273" x2="425.37725" y2="1331.86078" id="svg_360" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="328.13552" y1="1302.31714" x2="328.13552" y2="1332.04519" id="svg_361" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS394" data-tip="" className={floorState.WS394.status} onClick={(e)=>{seatSelected(e)}} x="383.0908" y="1354.03978" width="35.71396" height="19.92463" />
                            <rect id="WS395" data-tip="" className={floorState.WS395.status} onClick={(e)=>{seatSelected(e)}} x="333.94742" y="1354.05725" width="35.71396" height="19.92463" />
                            <rect id="WS396" data-tip="" className={floorState.WS396.status} onClick={(e)=>{seatSelected(e)}} x="285.55144" y="1354.4143" width="35.71396" height="19.92463" />
                            <line fill="none" x1="425.37725" y1="1349.55536" x2="425.37725" y2="1379.28341" id="svg_365" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="328.13552" y1="1349.92989" x2="328.13552" y2="1379.65794" id="svg_366" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line stroke="#999999" fill="none" x1="280.43063" y1="1301.7788" x2="425.811" y2="1301.7788" id="svg_367" strokeLinejoin="null" strokeLinecap="null" />
                            <rect id="WS399" data-tip="" className={floorState.WS399.status} onClick={(e)=>{seatSelected(e)}} x="383.0908" y="1382.96752" width="35.71396" height="19.92463" />
                            <rect id="WS398" data-tip="" className={floorState.WS398.status} onClick={(e)=>{seatSelected(e)}} x="333.94742" y="1382.98499" width="35.71396" height="19.92463" />
                            <rect id="WS397" data-tip="" className={floorState.WS397.status} onClick={(e)=>{seatSelected(e)}} x="285.55144" y="1383.34204" width="35.71396" height="19.92463" />
                            <line fill="none" x1="425.37725" y1="1378.4831" x2="425.37725" y2="1408.21115" id="svg_371" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="328.13552" y1="1378.85763" x2="328.13552" y2="1408.58568" id="svg_372" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line stroke="#999999" fill="none" x1="280.84214" y1="1378.12917" x2="426.06184" y2="1378.12917" id="svg_373" strokeLinejoin="null" strokeLinecap="null" />
                            <rect fill="#f1f1f1" strokeWidth="1.5" x="527.0975" y="1269.42918" width="50.07745" height="139.56539" id="svg_380" />
                            <g transform="rotate(-90 564.8933105468753,1382.6450195312502) " id="svg_387">
                                <rect id="WS385" data-tip="" className={floorState.WS385.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(180 565.5165405273438,1397.526123046875) " x="547.65953" y="1387.56373" width="35.71396" height="19.92463" />
                                <line transform="rotate(180 541.087158203125,1397.10888671875) " fill="none" x1="541.08721" y1="1382.24484" x2="541.08721" y2="1411.97289" id="svg_382" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                                <rect id="WS386" data-tip="" className={floorState.WS386.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(180 565.5166625976561,1368.5985107421875) " x="547.65977" y="1358.63625" width="35.71396" height="19.92463" />
                                <line transform="rotate(180 541.0871582031249,1368.18115234375) " fill="none" x1="541.08721" y1="1353.31711" x2="541.08721" y2="1383.04516" id="svg_384" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                                <line stroke="#999999" transform="rotate(180 564.8933105468749,1383.399169921875) " fill="none" x1="540.40248" y1="1383.39916" x2="589.38417" y2="1383.39916" id="svg_385" strokeLinejoin="null" strokeLinecap="null" />
                            </g>
                            <rect stroke="#999999" fill="#cccccc" strokeWidth="0" x="229.40627" y="65.40435" width="40.01494" height="32.55499" id="svg_388" />
                            <text stroke="#999999" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_389" y="67.79307" x="208.56745" strokeOpacity="null" strokeWidth="0" fill="#000000">UTILITY</text>
                            <text stroke="#999999" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_390" y="80.76129" x="213.712" strokeOpacity="null" strokeWidth="0" fill="#000000">AREA</text>
                            <rect stroke="#999999" fill="#ffffff" strokeWidth="0" x="576.71809" y="61.10327" width="46.46655" height="28.89991" id="svg_391" />
                            <rect stroke="#999999" fill="#cccccc" strokeWidth="0" x="576.96457" y="60.8199" width="46.31855" height="29.97624" id="svg_392" />
                            <text stroke="#999999" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_393" y="58.77814" x="838.32553" strokeOpacity="null" strokeWidth="0" fill="#000000">UTILITY</text>
                            <text stroke="#999999" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_394" y="72.64784" x="846.04052" strokeOpacity="null" strokeWidth="0" fill="#000000">AREA</text>
                            <line transform="rotate(-180 496.647705078125,120.43312072753906) " stroke="#999999" fill="none" x1="466.47433" y1="120.43312" x2="526.82112" y2="120.43312" id="svg_395" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 526.801025390625,158.7184600830078) " stroke="#999999" fill="none" x1="526.80105" y1="138.37018" x2="526.80105" y2="179.0687" id="svg_396" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 526.801025390625,100.64466094970703) " stroke="#999999" fill="none" x1="526.80105" y1="80.2954" x2="526.80105" y2="120.99392" id="svg_397" strokeLinejoin="null" strokeLinecap="null" />
                            <rect stroke="#999999" fill="#cccccc" strokeWidth="0" x="467.20362" y="61.96797" width="58.91502" height="58.07064" id="svg_398" />
                            <rect stroke="#999999" fill="#cccccc" strokeWidth="0" x="467.41868" y="120.89219" width="58.91502" height="58.07064" id="svg_399" />
                            <text stroke="#999999" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_400" y="83.28878" x="652.29763" strokeOpacity="null" strokeWidth="0" fill="#000000">CU - 05</text>
                            <text stroke="#999999" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_401" y="178.2118" x="652.29763" strokeOpacity="null" strokeWidth="0" fill="#000000">CU - 06</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS492_text" data-tip="" className={`${floorState.WS492.status}_text`} onClick={(e)=>{seatSelected(e)}} y="260.45917" x="130.73914" >492</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS493_text" data-tip="" className={`${floorState.WS493.status}_text`} onClick={(e)=>{seatSelected(e)}} y="260.00583" x="46.46006" >493</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS494_text" data-tip="" className={`${floorState.WS494.status}_text`} onClick={(e)=>{seatSelected(e)}} y="260.00583" x="-42.47247" >494</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS495_text" data-tip="" className={`${floorState.WS495.status}_text`} onClick={(e)=>{seatSelected(e)}} y="259.55248" x="-131.405" >495</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS499_text" data-tip="" className={`${floorState.WS499.status}_text`} onClick={(e)=>{seatSelected(e)}} y="185.65753" x="130.22209" >499</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS498_text" data-tip="" className={`${floorState.WS498.status}_text`} onClick={(e)=>{seatSelected(e)}} y="185.20419" x="45.94301" >498</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS497_text" data-tip="" className={`${floorState.WS497.status}_text`} onClick={(e)=>{seatSelected(e)}} y="185.20419" x="-42.98952" >497</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS496_text" data-tip="" className={`${floorState.WS496.status}_text`} onClick={(e)=>{seatSelected(e)}} y="184.75085" x="-131.92205" >496</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS484_text" data-tip="" className={`${floorState.WS484.status}_text`} onClick={(e)=>{seatSelected(e)}} y="382.16894" x="130.00077" >484</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS485_text" data-tip="" className={`${floorState.WS485.status}_text`} onClick={(e)=>{seatSelected(e)}} y="381.7156" x="45.72169" >485</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS486_text" data-tip="" className={`${floorState.WS486.status}_text`} onClick={(e)=>{seatSelected(e)}} y="381.7156" x="-43.21084" >486</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS487_text" data-tip="" className={`${floorState.WS487.status}_text`} onClick={(e)=>{seatSelected(e)}} y="381.26226" x="-132.14337" >487</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS491_text" data-tip="" className={`${floorState.WS491.status}_text`} onClick={(e)=>{seatSelected(e)}} y="307.36731" x="129.48372" >491</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS490_text" data-tip="" className={`${floorState.WS490.status}_text`} onClick={(e)=>{seatSelected(e)}} y="306.91396" x="45.20464" >490</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS489_text" data-tip="" className={`${floorState.WS489.status}_text`} onClick={(e)=>{seatSelected(e)}} y="306.91396" x="-43.72789" >489</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS488_text" data-tip="" className={`${floorState.WS488.status}_text`} onClick={(e)=>{seatSelected(e)}} y="306.46062" x="-132.66042" >488</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS476_text" data-tip="" className={`${floorState.WS476.status}_text`} onClick={(e)=>{seatSelected(e)}} y="503.87871" x="132.21588" >476</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS477_text" data-tip="" className={`${floorState.WS477.status}_text`} onClick={(e)=>{seatSelected(e)}} y="503.42537" x="47.93679" >477</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS478_text" data-tip="" className={`${floorState.WS478.status}_text`} onClick={(e)=>{seatSelected(e)}} y="503.42537" x="-40.99573" >478</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS479_text" data-tip="" className={`${floorState.WS479.status}_text`} onClick={(e)=>{seatSelected(e)}} y="502.97203" x="-129.92826" >479</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS483_text" data-tip="" className={`${floorState.WS483.status}_text`} onClick={(e)=>{seatSelected(e)}} y="429.07708" x="131.69883" >483</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS482_text" data-tip="" className={`${floorState.WS482.status}_text`} onClick={(e)=>{seatSelected(e)}} y="428.62374" x="47.41974" >482</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS481_text" data-tip="" className={`${floorState.WS481.status}_text`} onClick={(e)=>{seatSelected(e)}} y="428.62374" x="-41.51278" >481</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS480_text" data-tip="" className={`${floorState.WS480.status}_text`} onClick={(e)=>{seatSelected(e)}} y="428.17039" x="-130.44531" >480</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS468_text" data-tip="" className={`${floorState.WS468.status}_text`} onClick={(e)=>{seatSelected(e)}} y="626.23589" x="131.47751" >468</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS469_text" data-tip="" className={`${floorState.WS469.status}_text`} onClick={(e)=>{seatSelected(e)}} y="625.78255" x="47.19843" >469</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS470_text" data-tip="" className={`${floorState.WS470.status}_text`} onClick={(e)=>{seatSelected(e)}} y="625.78255" x="-41.7341" >470</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS471_text" data-tip="" className={`${floorState.WS471.status}_text`} onClick={(e)=>{seatSelected(e)}} y="625.3292" x="-130.66663" >471</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS475_text" data-tip="" className={`${floorState.WS475.status}_text`} onClick={(e)=>{seatSelected(e)}} y="551.43426" x="130.96046" >475</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS474_text" data-tip="" className={`${floorState.WS474.status}_text`} onClick={(e)=>{seatSelected(e)}} y="550.98091" x="46.68138" >474</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS473_text" data-tip="" className={`${floorState.WS473.status}_text`} onClick={(e)=>{seatSelected(e)}} y="550.98091" x="-42.25115" >473</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS472_text" data-tip="" className={`${floorState.WS472.status}_text`} onClick={(e)=>{seatSelected(e)}} y="550.52757" x="-131.18368" >472</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS460_text" data-tip="" className={`${floorState.WS460.status}_text`} onClick={(e)=>{seatSelected(e)}} y="746.65088" x="130.73914" >460</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS461_text" data-tip="" className={`${floorState.WS461.status}_text`} onClick={(e)=>{seatSelected(e)}} y="746.19753" x="46.46006" >461</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS462_text" data-tip="" className={`${floorState.WS462.status}_text`} onClick={(e)=>{seatSelected(e)}} y="746.19753" x="-42.47247" >462</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS463_text" data-tip="" className={`${floorState.WS463.status}_text`} onClick={(e)=>{seatSelected(e)}} y="745.74419" x="-131.405" >463</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS467_text" data-tip="" className={`${floorState.WS467.status}_text`} onClick={(e)=>{seatSelected(e)}} y="671.84924" x="130.22209" >467</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS466_text" data-tip="" className={`${floorState.WS466.status}_text`} onClick={(e)=>{seatSelected(e)}} y="671.3959" x="45.94301" >466</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS465_text" data-tip="" className={`${floorState.WS465.status}_text`} onClick={(e)=>{seatSelected(e)}} y="671.3959" x="-42.98952" >465</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS464_text" data-tip="" className={`${floorState.WS464.status}_text`} onClick={(e)=>{seatSelected(e)}} y="670.94255" x="-131.92205" >464</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS452_text" data-tip="" className={`${floorState.WS452.status}_text`} onClick={(e)=>{seatSelected(e)}} y="867.05448" x="132.21585" >452</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS453_text" data-tip="" className={`${floorState.WS453.status}_text`} onClick={(e)=>{seatSelected(e)}} y="866.60114" x="47.93677" >453</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS454_text" data-tip="" className={`${floorState.WS454.status}_text`} onClick={(e)=>{seatSelected(e)}} y="866.60114" x="-40.99576" >454</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS455_text" data-tip="" className={`${floorState.WS455.status}_text`} onClick={(e)=>{seatSelected(e)}} y="866.1478" x="-129.92829" >455</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS459_text" data-tip="" className={`${floorState.WS459.status}_text`} onClick={(e)=>{seatSelected(e)}} y="792.25285" x="131.6988" >459</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS458_text" data-tip="" className={`${floorState.WS458.status}_text`} onClick={(e)=>{seatSelected(e)}} y="791.7995" x="47.41972" >458</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS457_text" data-tip="" className={`${floorState.WS457.status}_text`} onClick={(e)=>{seatSelected(e)}} y="791.7995" x="-41.51281" >457</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS456_text" data-tip="" className={`${floorState.WS456.status}_text`} onClick={(e)=>{seatSelected(e)}} y="791.34616" x="-130.44534" >456</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS444_text" data-tip="" className={`${floorState.WS444.status}_text`} onClick={(e)=>{seatSelected(e)}} y="989.40934" x="130.73914" >444</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS445_text" data-tip="" className={`${floorState.WS445.status}_text`} onClick={(e)=>{seatSelected(e)}} y="988.956" x="46.46006" >445</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS446_text" data-tip="" className={`${floorState.WS446.status}_text`} onClick={(e)=>{seatSelected(e)}} y="988.956" x="-42.47247" >446</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS447_text" data-tip="" className={`${floorState.WS447.status}_text`} onClick={(e)=>{seatSelected(e)}} y="988.50266" x="-131.405" >447</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS451_text" data-tip="" className={`${floorState.WS451.status}_text`} onClick={(e)=>{seatSelected(e)}} y="914.60771" x="130.22209" >451</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS450_text" data-tip="" className={`${floorState.WS450.status}_text`} onClick={(e)=>{seatSelected(e)}} y="914.15436" x="45.94301" >450</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS449_text" data-tip="" className={`${floorState.WS449.status}_text`} onClick={(e)=>{seatSelected(e)}} y="914.15436" x="-42.98952" >449</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS448_text" data-tip="" className={`${floorState.WS448.status}_text`} onClick={(e)=>{seatSelected(e)}} y="913.70102" x="-131.92205" >448</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS530_text" data-tip="" className={`${floorState.WS530.status}_text`} onClick={(e)=>{seatSelected(e)}} y="261.88929" x="1111.02469" >530</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS529_text" data-tip="" className={`${floorState.WS529.status}_text`} onClick={(e)=>{seatSelected(e)}} y="261.43595" x="1026.7456" >529</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS528_text" data-tip="" className={`${floorState.WS528.status}_text`} onClick={(e)=>{seatSelected(e)}} y="261.43595" x="937.81307" >528</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS527_text" data-tip="" className={`${floorState.WS527.status}_text`} onClick={(e)=>{seatSelected(e)}} y="260.9826" x="848.88055" >527</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS523_text" data-tip="" className={`${floorState.WS523.status}_text`} onClick={(e)=>{seatSelected(e)}} y="186.09804" x="1110.50764" >523</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS524_text" data-tip="" className={`${floorState.WS524.status}_text`} onClick={(e)=>{seatSelected(e)}} y="185.64469" x="1025.09986" >524</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS525_text" data-tip="" className={`${floorState.WS525.status}_text`} onClick={(e)=>{seatSelected(e)}} y="186.63431" x="937.29602" >525</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS526_text" data-tip="" className={`${floorState.WS526.status}_text`} onClick={(e)=>{seatSelected(e)}} y="186.18097" x="848.3635" >526</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS535_text" data-tip="" className={`${floorState.WS535.status}_text`} onClick={(e)=>{seatSelected(e)}} y="382.01953" x="1111.02469" >535</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS536_text" data-tip="" className={`${floorState.WS536.status}_text`} onClick={(e)=>{seatSelected(e)}} y="381.56619" x="1026.7456" >536</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS537_text" data-tip="" className={`${floorState.WS537.status}_text`} onClick={(e)=>{seatSelected(e)}} y="381.56619" x="937.81307" >537</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS538_text" data-tip="" className={`${floorState.WS538.status}_text`} onClick={(e)=>{seatSelected(e)}} y="381.11285" x="848.88055" >538</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS531_text" data-tip="" className={`${floorState.WS531.status}_text`} onClick={(e)=>{seatSelected(e)}} y="307.2179" x="1110.50764" >531</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS532_text" data-tip="" className={`${floorState.WS532.status}_text`} onClick={(e)=>{seatSelected(e)}} y="306.76455" x="1026.22855" >532</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS533_text" data-tip="" className={`${floorState.WS533.status}_text`} onClick={(e)=>{seatSelected(e)}} y="306.76455" x="937.29602" >533</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS534_text" data-tip="" className={`${floorState.WS534.status}_text`} onClick={(e)=>{seatSelected(e)}} y="306.31121" x="848.3635" >534</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS543_text" data-tip="" className={`${floorState.WS543.status}_text`} onClick={(e)=>{seatSelected(e)}} y="502.14977" x="1111.02469" >543</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS544_text" data-tip="" className={`${floorState.WS544.status}_text`} onClick={(e)=>{seatSelected(e)}} y="501.69643" x="1026.7456" >544</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS545_text" data-tip="" className={`${floorState.WS545.status}_text`} onClick={(e)=>{seatSelected(e)}} y="501.69643" x="937.81307" >545</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS546_text" data-tip="" className={`${floorState.WS546.status}_text`} onClick={(e)=>{seatSelected(e)}} y="501.24309" x="848.88055" >546</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS542_text" data-tip="" className={`${floorState.WS542.status}_text`} onClick={(e)=>{seatSelected(e)}} y="427.34814" x="1110.50764" >542</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS541_text" data-tip="" className={`${floorState.WS541.status}_text`} onClick={(e)=>{seatSelected(e)}} y="426.8948" x="1026.22855" >541</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS540_text" data-tip="" className={`${floorState.WS540.status}_text`} onClick={(e)=>{seatSelected(e)}} y="426.8948" x="937.29602" >540</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS539_text" data-tip="" className={`${floorState.WS539.status}_text`} onClick={(e)=>{seatSelected(e)}} y="426.44145" x="848.3635" >539</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS551_text" data-tip="" className={`${floorState.WS551.status}_text`} onClick={(e)=>{seatSelected(e)}} y="625.14025" x="1111.02469" >551</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS552_text" data-tip="" className={`${floorState.WS552.status}_text`} onClick={(e)=>{seatSelected(e)}} y="624.68691" x="1026.7456" >552</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS553_text" data-tip="" className={`${floorState.WS553.status}_text`} onClick={(e)=>{seatSelected(e)}} y="624.68691" x="937.81307" >553</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS554_text" data-tip="" className={`${floorState.WS554.status}_text`} onClick={(e)=>{seatSelected(e)}} y="624.23356" x="848.88055" >554</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS550_text" data-tip="" className={`${floorState.WS550.status}_text`} onClick={(e)=>{seatSelected(e)}} y="550.33862" x="1110.50764" >550</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS549_text" data-tip="" className={`${floorState.WS549.status}_text`} onClick={(e)=>{seatSelected(e)}} y="549.88527" x="1026.22855" >549</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS548_text" data-tip="" className={`${floorState.WS548.status}_text`} onClick={(e)=>{seatSelected(e)}} y="549.88527" x="937.29602" >548</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS547_text" data-tip="" className={`${floorState.WS547.status}_text`} onClick={(e)=>{seatSelected(e)}} y="549.43193" x="848.3635" >547</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS559_text" data-tip="" className={`${floorState.WS559.status}_text`} onClick={(e)=>{seatSelected(e)}} y="743.84036" x="1111.02469" >559</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS560_text" data-tip="" className={`${floorState.WS560.status}_text`} onClick={(e)=>{seatSelected(e)}} y="743.38702" x="1026.7456" >560</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS561_text" data-tip="" className={`${floorState.WS561.status}_text`} onClick={(e)=>{seatSelected(e)}} y="743.38702" x="937.81307" >561</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS562_text" data-tip="" className={`${floorState.WS562.status}_text`} onClick={(e)=>{seatSelected(e)}} y="742.93368" x="848.88055" >562</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS558_text" data-tip="" className={`${floorState.WS558.status}_text`} onClick={(e)=>{seatSelected(e)}} y="669.03873" x="1110.50764" >558</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS557_text" data-tip="" className={`${floorState.WS557.status}_text`} onClick={(e)=>{seatSelected(e)}} y="668.58539" x="1026.22855" >557</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS556_text" data-tip="" className={`${floorState.WS556.status}_text`} onClick={(e)=>{seatSelected(e)}} y="668.58539" x="937.29602" >556</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS555_text" data-tip="" className={`${floorState.WS555.status}_text`} onClick={(e)=>{seatSelected(e)}} y="668.13204" x="848.3635" >555</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS567_text" data-tip="" className={`${floorState.WS567.status}_text`} onClick={(e)=>{seatSelected(e)}} y="866.83084" x="1107.7625" >567</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS568_text" data-tip="" className={`${floorState.WS568.status}_text`} onClick={(e)=>{seatSelected(e)}} y="866.3775" x="1023.48342" >568</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS569_text" data-tip="" className={`${floorState.WS569.status}_text`} onClick={(e)=>{seatSelected(e)}} y="866.3775" x="934.55089" >569</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS570_text" data-tip="" className={`${floorState.WS570.status}_text`} onClick={(e)=>{seatSelected(e)}} y="865.92415" x="845.61836" >570</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS566_text" data-tip="" className={`${floorState.WS566.status}_text`} onClick={(e)=>{seatSelected(e)}} y="792.02921" x="1107.24545" >566</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS565_text" data-tip="" className={`${floorState.WS565.status}_text`} onClick={(e)=>{seatSelected(e)}} y="791.57586" x="1022.96637" >565</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS564_text" data-tip="" className={`${floorState.WS564.status}_text`} onClick={(e)=>{seatSelected(e)}} y="791.57586" x="934.03384" >564</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS563_text" data-tip="" className={`${floorState.WS563.status}_text`} onClick={(e)=>{seatSelected(e)}} y="791.12252" x="845.10131" >563</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS330_text" data-tip="" className={`${floorState.WS330.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1408.84702" x="1111.02469" >330</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS329_text" data-tip="" className={`${floorState.WS329.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1408.39367" x="1026.7456" >329</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS328_text" data-tip="" className={`${floorState.WS328.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1408.39367" x="937.81307" >328</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS327_text" data-tip="" className={`${floorState.WS327.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1407.94033" x="848.88055" >327</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS319_text" data-tip="" className={`${floorState.WS319.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1334.04538" x="1110.50764" >319</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS320_text" data-tip="" className={`${floorState.WS320.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1333.59204" x="1026.22855" >320</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS321_text" data-tip="" className={`${floorState.WS321.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1333.59204" x="937.29602" >321</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS322_text" data-tip="" className={`${floorState.WS322.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1333.13869" x="848.3635" >322</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS344_text" data-tip="" className={`${floorState.WS344.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1530.40737" x="1109.3936" >344</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS343_text" data-tip="" className={`${floorState.WS343.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1529.95403" x="1025.11451" >343</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS342_text" data-tip="" className={`${floorState.WS342.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1529.95403" x="936.18198" >342</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS341_text" data-tip="" className={`${floorState.WS341.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1529.50069" x="847.24945" >341</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS331_text" data-tip="" className={`${floorState.WS331.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1455.60574" x="1108.87655" >331</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS332_text" data-tip="" className={`${floorState.WS332.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1455.1524" x="1024.59746" >332</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS333_text" data-tip="" className={`${floorState.WS333.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1455.1524" x="935.66493" >333</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS334_text" data-tip="" className={`${floorState.WS334.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1454.69905" x="846.73241" >334</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS352_text" data-tip="" className={`${floorState.WS352.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1650.53762" x="1111.02469" >352</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS351_text" data-tip="" className={`${floorState.WS351.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1650.08427" x="1026.7456" >351</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS350_text" data-tip="" className={`${floorState.WS350.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1650.08427" x="937.81307" >350</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS349_text" data-tip="" className={`${floorState.WS349.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1649.63093" x="848.88055" >349</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS345_text" data-tip="" className={`${floorState.WS345.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1575.73598" x="1110.50764" >345</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS346_text" data-tip="" className={`${floorState.WS346.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1575.28264" x="1026.22855" >346</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS347_text" data-tip="" className={`${floorState.WS347.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1575.28264" x="937.29602" >347</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS348_text" data-tip="" className={`${floorState.WS348.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1574.8293" x="848.3635" >348</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS360_text" data-tip="" className={`${floorState.WS360.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1772.09798" x="1107.7625" >360</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS359_text" data-tip="" className={`${floorState.WS359.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1771.64463" x="1023.48342" >359</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS358_text" data-tip="" className={`${floorState.WS358.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1771.64463" x="934.55089" >358</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS357_text" data-tip="" className={`${floorState.WS357.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1771.19129" x="845.61836" >357</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS353_text" data-tip="" className={`${floorState.WS353.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1697.29634" x="1107.24545" >353</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS354_text" data-tip="" className={`${floorState.WS354.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1696.843" x="1022.96637" >354</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS355_text" data-tip="" className={`${floorState.WS355.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1696.843" x="934.03384" >355</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS356_text" data-tip="" className={`${floorState.WS356.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1696.38965" x="845.10131" >356</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS439_text" data-tip="" className={`${floorState.WS439.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1350.21203" x="130.73914" >439</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS438_text" data-tip="" className={`${floorState.WS438.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1349.75869" x="46.46006" >438</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS437_text" data-tip="" className={`${floorState.WS437.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1349.75869" x="-42.47247" >437</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS436_text" data-tip="" className={`${floorState.WS436.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1349.30534" x="-131.405" >436</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS440_text" data-tip="" className={`${floorState.WS440.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1275.4104" x="130.22209" >440</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS441_text" data-tip="" className={`${floorState.WS441.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1274.95705" x="45.94301" >441</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS442_text" data-tip="" className={`${floorState.WS442.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1274.95705" x="-42.98952" >442</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS443_text" data-tip="" className={`${floorState.WS443.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1274.50371" x="-131.92205" >443</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS431_text" data-tip="" className={`${floorState.WS431.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1471.77239" x="130.73914" >431</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS430_text" data-tip="" className={`${floorState.WS430.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1471.31905" x="46.46006" >430</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS429_text" data-tip="" className={`${floorState.WS429.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1471.31905" x="-42.47247" >429</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS428_text" data-tip="" className={`${floorState.WS428.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1470.8657" x="-131.405" >428</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS432_text" data-tip="" className={`${floorState.WS432.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1396.97075" x="130.22209" >432</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS433_text" data-tip="" className={`${floorState.WS433.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1396.51741" x="45.94301" >433</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS434_text" data-tip="" className={`${floorState.WS434.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1396.51741" x="-42.98952" >434</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS435_text" data-tip="" className={`${floorState.WS435.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1396.06407" x="-131.92205" >435</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS423_text" data-tip="" className={`${floorState.WS423.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1594.76287" x="130.73914" >423</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS422_text" data-tip="" className={`${floorState.WS422.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1594.30952" x="46.46006" >422</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS421_text" data-tip="" className={`${floorState.WS421.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1594.30952" x="-42.47247" >421</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS420_text" data-tip="" className={`${floorState.WS420.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1593.85618" x="-131.405" >420</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS424_text" data-tip="" className={`${floorState.WS424.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1519.96123" x="130.22209" >424</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS425_text" data-tip="" className={`${floorState.WS425.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1519.50789" x="45.94301" >425</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS426_text" data-tip="" className={`${floorState.WS426.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1519.50789" x="-42.98952" >426</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS427_text" data-tip="" className={`${floorState.WS427.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1519.05455" x="-131.92205" >427</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS415_text" data-tip="" className={`${floorState.WS415.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1714.89311" x="132.37023" >415</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS414_text" data-tip="" className={`${floorState.WS414.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1714.43976" x="48.09115" >414</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS413_text" data-tip="" className={`${floorState.WS413.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1714.43976" x="-40.84138" >413</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS412_text" data-tip="" className={`${floorState.WS412.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1713.98642" x="-129.77391" >412</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS416_text" data-tip="" className={`${floorState.WS416.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1640.09147" x="131.85318" >416</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS417_text" data-tip="" className={`${floorState.WS417.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1639.63813" x="47.5741" >417</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS418_text" data-tip="" className={`${floorState.WS418.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1639.63813" x="-41.35843" >418</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS419_text" data-tip="" className={`${floorState.WS419.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1639.18479" x="-130.29096" >419</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS407_text" data-tip="" className={`${floorState.WS407.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1837.88358" x="130.73914" >407</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS406_text" data-tip="" className={`${floorState.WS406.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1837.43024" x="46.46006" >406</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS405_text" data-tip="" className={`${floorState.WS405.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1837.43024" x="-42.47247" >405</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS404_text" data-tip="" className={`${floorState.WS404.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1836.9769" x="-131.405" >404</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS408_text" data-tip="" className={`${floorState.WS408.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1763.08195" x="130.22209" >408</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS409_text" data-tip="" className={`${floorState.WS409.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1762.62861" x="45.94301" >409</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS410_text" data-tip="" className={`${floorState.WS410.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1762.62861" x="-42.98952" >410</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS411_text" data-tip="" className={`${floorState.WS411.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1762.17526" x="-131.92205" >411</text>
                            <line opacity="0.45" stroke="#999999" transform="rotate(-180 304.1528625488281,282.5060119628906) " fill="none" x1="274.40957" y1="282.506" x2="333.89616" y2="282.506" id="svg_806" strokeLinejoin="null" strokeLinecap="null" />
                            <line opacity="0.45" stroke="#999999" transform="rotate(-180 496.42999267578125,282.20925903320307) " fill="none" x1="466.09324" y1="282.20927" x2="526.76675" y2="282.20927" id="svg_807" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 497.22576904296875,179.39279174804688) " stroke="#999999" fill="none" x1="467.05237" y1="179.39279" x2="527.39916" y2="179.39279" id="svg_808" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 496.647705078125,120.43312072753906) " stroke="#999999" fill="none" x1="466.47433" y1="120.43312" x2="526.82112" y2="120.43312" id="svg_809" strokeLinejoin="null" strokeLinecap="null" />
                            <line opacity="0.45" transform="rotate(-180 304.51361083984375,344.1960754394531) " stroke="#999999" fill="none" x1="274.34022" y1="344.19608" x2="334.68701" y2="344.19608" id="svg_810" strokeLinejoin="null" strokeLinecap="null" />
                            <line opacity="0.45" transform="rotate(-180 304.51361083984375,405.6556091308594) " stroke="#999999" fill="none" x1="274.34021" y1="405.65562" x2="334.687" y2="405.65562" id="svg_812" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 497.22576904296875,241.06903076171875) " stroke="#999999" fill="none" x1="467.05237" y1="241.06903" x2="527.39916" y2="241.06903" id="svg_814" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 497.22576904296875,179.39279174804688) " stroke="#999999" fill="none" x1="467.05237" y1="179.39279" x2="527.39916" y2="179.39279" id="svg_815" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 497.22576904296875,241.06903076171875) " stroke="#999999" fill="none" x1="467.05237" y1="241.06903" x2="527.39916" y2="241.06903" id="svg_818" strokeLinejoin="null" strokeLinecap="null" />
                            <line opacity="0.45" transform="rotate(-180 497.22576904296875,179.39279174804688) " stroke="#999999" fill="none" x1="467.05237" y1="179.39279" x2="527.39916" y2="179.39279" id="svg_819" strokeLinejoin="null" strokeLinecap="null" />
                            <line transform="rotate(-180 497.22576904296875,241.06903076171875) " stroke="#999999" fill="none" x1="467.05237" y1="241.06903" x2="527.39916" y2="241.06903" id="svg_822" strokeLinejoin="null" strokeLinecap="null" />
                            <line opacity="0.45" transform="rotate(-180 497.22576904296875,179.39279174804688) " stroke="#999999" fill="none" x1="467.05237" y1="179.39279" x2="527.39916" y2="179.39279" id="svg_823" strokeLinejoin="null" strokeLinecap="null" />
                            <line opacity="0.45" transform="rotate(-180 304.5136108398438,466.58905029296875) " stroke="#999999" fill="none" x1="274.34022" y1="466.58904" x2="334.68701" y2="466.58904" id="svg_824" strokeLinejoin="null" strokeLinecap="null" />
                            <line opacity="0.45" transform="rotate(-180 275.09747314453125,302.5928649902344) " stroke="#999999" fill="none" x1="275.09749" y1="282.2436" x2="275.09749" y2="322.94212" id="svg_826" strokeLinejoin="null" strokeLinecap="null" />
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_828" y="434.51371" x="310.96716" strokeOpacity="null" strokeWidth="0" fill="#000000">CU - 22</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_829" y="528.90034" x="310.09195" strokeOpacity="null" strokeWidth="0" fill="#000000">CU - 21</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_830" y="611.09176" x="309.21618" strokeOpacity="null" strokeWidth="0" fill="#000000">SMALL</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_831" y="627.20655" x="302.21453" strokeOpacity="null" strokeWidth="0" fill="#000000">MEETING</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_832" y="644.08871" x="308.34097" strokeOpacity="null" strokeWidth="0" fill="#000000">ROOM</text>
                            <rect stroke="#b2b2b2" fill="#cccccc" x="334.30324" y="282.33432" width="131.71591" height="82.28964" id="svg_835" />
                            <rect stroke="#b2b2b2" fill="#cccccc" x="334.30324" y="364.76677" width="131.71591" height="101.20857" id="svg_836" />
                            <rect stroke="#999999" transform="rotate(0.0622989721596241 496.8876953124527,303.39270019531875) " fill="#cccccc" strokeWidth="0" x="467.04577" y="281.94755" width="59.68381" height="42.89032" id="svg_837" />
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_838" y="415.44359" x="646.53284" strokeOpacity="null" strokeWidth="0" fill="#000000">LOUNGE</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_839" y="430.66943" x="655.02119" strokeOpacity="null" strokeWidth="0" fill="#000000">AREA</text>
                            <line opacity="0.45" transform="rotate(-180 495.891845703125,364.45458984374994) " stroke="#999999" fill="none" x1="465.71845" y1="364.45459" x2="526.06524" y2="364.45459" id="svg_840" strokeLinejoin="null" strokeLinecap="null" />
                            <line opacity="0.45" transform="rotate(-180 496.3228759765625,325.23062133789057) " stroke="#999999" fill="none" x1="466.14947" y1="325.23067" x2="526.49626" y2="325.23067" id="svg_841" strokeLinejoin="null" strokeLinecap="null" />
                            <line stroke="#cccccc" strokeLinecap="null" strokeLinejoin="null" id="svg_842" y2="364.01601" x2="466.01747" y1="325.06997" x1="466.01747" strokeWidth="1.5" fill="none" />
                            <text stroke="#ffffff" transform="rotate(90 495.0020751953125,342.85559082031256) matrix(0.7797585912333851,0,0,0.8897444348318662,-25.8888512349747,-87.26633611028196) " id="svg_843" y="487.40645" x="654.36309" strokeOpacity="null" strokeWidth="0" fill="#000000">EXIT</text>
                            {/* <text stroke="#ffffff" transform="rotate(90 481.0943603515625,343.21008300781256) matrix(0.7797585912333851,0,0,0.8897444348318662,-25.8888512349747,-87.26633611028196) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="5" id="svg_844" y="486.922" x="636.12147"   strokeOpacity="null" strokeWidth="0" fill="#000000">Access</text>
            <text stroke="#ffffff" transform="rotate(90 474.1130676269531,343.21008300781256) matrix(0.7797585912333851,0,0,0.8897444348318662,-25.8888512349747,-87.26633611028196) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="5" id="svg_845" y="486.922" x="626.72427"   strokeOpacity="null" strokeWidth="0" fill="#000000">Control</text> */}
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_846" y="573.11977" x="650.26689" strokeOpacity="null" strokeWidth="0" fill="#000000">MEDIUM</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_847" y="589.23456" x="647.90538" strokeOpacity="null" strokeWidth="0" fill="#000000">MEETING</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_848" y="606.11672" x="654.03183" strokeOpacity="null" strokeWidth="0" fill="#000000">ROOM</text>
                            <text stroke="#ffffff" transform="matrix(0.8350667499228237,0,0,0.8349629405620289,-11.046134124462526,-81.3252733915147) " id="svg_849" y="592.10576" x="477.80814" strokeOpacity="null" strokeWidth="0" fill="#000000">A.H.U</text>
                            <text stroke="#ffffff" transform="matrix(0.8350667499228236,0,0,0.8349629405620289,-20.95987142152063,-173.56613337686628) " id="svg_850" y="594.68691" x="474.19498" strokeOpacity="null" strokeWidth="0" fill="#000000">STAIRCASE</text>
                            <rect stroke="#b2b2b2" fill="#cccccc" strokeWidth="1.5" x="274.67111" y="495.29256" width="251.75854" height="183.75973" id="svg_851" />
                            <line stroke="#999999" opacity="0.45" fill="none" x1="334.01376" y1="495.53798" x2="334.01376" y2="679.47386" id="svg_804" strokeLinejoin="null" strokeLinecap="null" />
                            <line stroke="#999999" opacity="0.45" fill="none" x1="466.88383" y1="495.20493" x2="466.88383" y2="679.88996" id="svg_805" strokeLinejoin="null" strokeLinecap="null" />
                            <rect stroke="#b2b2b2" fill="#cccccc" x="334.30324" y="495.76088" width="132.22352" height="101.68475" id="svg_854" />
                            <rect stroke="#b2b2b2" fill="#cccccc" x="400.7998" y="495.76088" width="65.72696" height="101.68475" id="svg_855" />
                            <line opacity="0.45" transform="rotate(-180 304.5136108398438,523.9281005859375) " stroke="#999999" fill="none" x1="274.34021" y1="523.92812" x2="334.687" y2="523.92812" id="svg_866" strokeLinejoin="null" strokeLinecap="null" />
                            <line opacity="0.45" transform="rotate(-180 304.5136108398438,569.6326293945312) " stroke="#999999" fill="none" x1="274.34022" y1="569.63332" x2="334.68701" y2="569.63332" id="svg_867" strokeLinejoin="null" strokeLinecap="null" />
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_868" y="790.76198" x="309.21618" strokeOpacity="null" strokeWidth="0" fill="#000000">SMALL</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_869" y="804.48116" x="302.21453" strokeOpacity="null" strokeWidth="0" fill="#000000">MEETING</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_870" y="820.56479" x="308.34097" strokeOpacity="null" strokeWidth="0" fill="#000000">ROOM</text>
                            <line opacity="0.45" transform="rotate(-180 304.5136108398438,615.8256225585938) " stroke="#999999" fill="none" x1="274.34022" y1="615.82559" x2="334.68701" y2="615.82559" id="svg_872" strokeLinejoin="null" strokeLinecap="null" />
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_873" y="862.63008" x="308.30543" strokeOpacity="null" strokeWidth="0" fill="#000000">SMALL</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_874" y="878.74487" x="301.30378" strokeOpacity="null" strokeWidth="0" fill="#000000">MEETING</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_875" y="895.62703" x="307.43022" strokeOpacity="null" strokeWidth="0" fill="#000000">ROOM</text>
                            <text stroke="#ffffff" transform="matrix(0.8350667499228236,0,0,0.8349629405620289,-105.96869576997032,19.180967453211878) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="10" id="svg_876" y="592.10576" x="475.98454" strokeOpacity="null" strokeWidth="0" fill="#000000">A.H.U</text>
                            <line opacity="0.45" transform="rotate(-180 304.5136108398438,656.9418334960938) " stroke="#999999" fill="none" x1="274.34022" y1="656.94178" x2="334.68701" y2="656.94178" id="svg_877" strokeLinejoin="null" strokeLinecap="null" />
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_878" y="933.0452" x="306.76827" strokeOpacity="null" strokeWidth="0" fill="#000000">STORE</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_879" y="949.92736" x="307.43022" strokeOpacity="null" strokeWidth="0" fill="#000000">ROOM</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="16" id="svg_880" y="930.51589" x="435.7742" strokeOpacity="null" strokeWidth="0" fill="#000000">ELECTRIC</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="16" id="svg_881" y="955.88357" x="440.67215" strokeOpacity="null" strokeWidth="0" fill="#000000">ROOM</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="8" id="svg_882" y="998.52502" x="285.01779" strokeOpacity="null" strokeWidth="0" fill="#000000">FULL HT STOR.</text>
                            <rect stroke="#b2b2b2" fill="#cccccc" x="378.46508" y="496.23706" width="22.07273" height="41.81851" id="svg_883" />
                            <rect stroke="#b2b2b2" fill="#cccccc" x="422.86234" y="630.5211" width="43.86514" height="48.00891" id="svg_884" />
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_885" y="966.38982" x="552.77341" strokeOpacity="null" strokeWidth="0" fill="#000000">SERVICE</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_886" y="983.27198" x="568.58269" strokeOpacity="null" strokeWidth="0" fill="#000000">LIFT</text>
                            <line opacity="0.45" transform="rotate(-180 422.82678222656256,617.8410034179688) " stroke="#999999" fill="none" x1="422.82679" y1="597.49181" x2="422.82679" y2="638.19033" id="svg_887" strokeLinejoin="null" strokeLinecap="null" />
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="16" id="svg_14" y="840.33641" x="464.99039" strokeOpacity="null" strokeWidth="0" fill="#000000">WASHROOM</text>
                            <text stroke="#ffffff" transform="rotate(90 437.78115844726557,525.0098876953126) matrix(0.7797585912333851,0,0,0.8897444348318662,-25.8888512349747,-87.26633611028196) " id="svg_20" y="692.13586" x="582.29808" strokeOpacity="null" strokeWidth="0" fill="#000000">SHE</text>
                            <text stroke="#ffffff" transform="rotate(90 353.57336425781244,522.7438354492189) matrix(0.7797585912333851,0,0,0.8897444348318662,-25.8888512349747,-87.26633611028196) " id="svg_297" y="689.59264" x="478.31165" strokeOpacity="null" strokeWidth="0" fill="#000000">HE</text>
                            <text stroke="#ffffff" transform="rotate(90 388.9548950195312,515.2088012695314) matrix(0.7797585912333851,0,0,0.8897444348318662,-25.8888512349747,-87.26633611028196) " id="svg_298" y="681.11523" x="521.35719" strokeOpacity="null" strokeWidth="0" fill="#000000">H.T.</text>
                            <line opacity="0.45" transform="rotate(-180 496.09887695312506,514.12255859375) " stroke="#999999" fill="none" x1="465.92548" y1="514.12258" x2="526.27227" y2="514.12258" id="svg_299" strokeLinejoin="null" strokeLinecap="null" />
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="8" id="svg_300" y="738.07245" x="631.67011" strokeOpacity="null" strokeWidth="0" fill="#000000">LAPTOP OSD ST.</text>
                            <line opacity="0.45" transform="rotate(-180 496.09887695312506,597.4696655273438) " stroke="#999999" fill="none" x1="465.92548" y1="597.4697" x2="526.27227" y2="597.4697" id="svg_310" strokeLinejoin="null" strokeLinecap="null" />
                            <line opacity="0.45" transform="rotate(-180 496.09887695312506,553.3447265625) " stroke="#999999" fill="none" x1="465.92548" y1="553.34475" x2="526.27227" y2="553.34475" id="svg_311" strokeLinejoin="null" strokeLinecap="null" />
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_312" y="781.0469" x="653.15754" strokeOpacity="null" strokeWidth="0" fill="#000000">CU - 23</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_313" y="850.46136" x="653.15754" strokeOpacity="null" strokeWidth="0" fill="#000000">CU - 24</text>
                            <line opacity="0.45" transform="rotate(-180 496.09887695312506,615.6926879882812) " stroke="#999999" fill="none" x1="465.92548" y1="615.69266" x2="526.27227" y2="615.69266" id="svg_317" strokeLinejoin="null" strokeLinecap="null" />
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="8" id="svg_318" y="897.48977" x="641.92325" strokeOpacity="null" strokeWidth="0" fill="#000000">D.B. STORAGE</text>
                            <line opacity="0.45" transform="rotate(-180 496.49414062500006,662.0401000976562) " stroke="#999999" fill="none" x1="466.32074" y1="662.04011" x2="526.66753" y2="662.04011" id="svg_319" strokeLinejoin="null" strokeLinecap="null" />
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_320" y="947.06495" x="659.54005" strokeOpacity="null" strokeWidth="0" fill="#000000">CU -</text>
                            <rect stroke="#b2b2b2" fill="#cccccc" x="467.16199" y="661.74632" width="59.24914" height="57.09979" id="svg_321" />
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_322" y="1013.10338" x="641.61439" strokeOpacity="null" strokeWidth="0" fill="#000000">DISPATCH &amp;</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_323" y="1029.21817" x="635.32191" strokeOpacity="null" strokeWidth="0" fill="#000000">TRAVEL DESK</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_324" y="1046.10033" x="654.21337" strokeOpacity="null" strokeWidth="0" fill="#000000">ROOM</text>
                            <rect opacity="0.45" stroke="#999999" transform="rotate(0.0622989721596241 400.050415038959,698.8460083008177) " fill="#e5e5e5" x="333.84736" y="679.2188" width="132.40612" height="39.25437" id="svg_325" />
                            <line opacity="0.5" stroke="#999999" transform="rotate(-180 378.2716064453125,698.368896484375) " fill="none" x1="378.27161" y1="678.01967" x2="378.27161" y2="718.71819" id="svg_326" strokeLinejoin="null" strokeLinecap="null" />
                            <line opacity="0.4" transform="rotate(-180 422.87945556640625,698.368896484375) " stroke="#999999" fill="none" x1="422.87946" y1="678.01967" x2="422.87946" y2="718.71819" id="svg_327" strokeLinejoin="null" strokeLinecap="null" />
                            <text opacity="0.4" stroke="#cccccc" transform="matrix(0.8466856372742129,0,0,0.7718252140865304,11.004879117351749,-102.3688901687063) " id="svg_330" y="1041.0054" x="400.87582" strokeOpacity="null" strokeWidth="0" fill="#000000">L1</text>
                            <text opacity="0.4" stroke="#cccccc" transform="matrix(0.8466856372742129,0,0,0.7718252140865304,11.004879117351749,-102.3688901687063) " id="svg_331" y="1041.0054" x="451.24524" strokeOpacity="null" strokeWidth="0" fill="#000000">L2</text>
                            <text opacity="0.4" stroke="#cccccc" transform="matrix(0.8466856372742129,0,0,0.7718252140865304,11.004879117351749,-102.3688901687063) " id="svg_332" y="1039.73517" x="503.9305" strokeOpacity="null" strokeWidth="0" fill="#000000">L3</text>
                            <rect opacity="0.45" stroke="#999999" transform="rotate(0.0622989721596241 400.050415038959,810.6107177734639) " fill="#e5e5e5" x="333.84736" y="790.98353" width="132.40612" height="39.25437" id="svg_348" />
                            <text opacity="0.4" stroke="#cccccc" transform="matrix(0.8466856372742129,0,0,0.7718252140865304,11.004879117351749,-102.3688901687063) " id="svg_375" y="1185.81115" x="400.87582" strokeOpacity="null" strokeWidth="0" fill="#000000">L6</text>
                            <text opacity="0.4" stroke="#cccccc" transform="matrix(0.8466856372742129,0,0,0.7718252140865304,11.004879117351749,-102.3688901687063) " id="svg_376" y="1185.81115" x="451.24524" strokeOpacity="null" strokeWidth="0" fill="#000000">L5</text>
                            <text opacity="0.4" stroke="#cccccc" transform="matrix(0.8466856372742129,0,0,0.7718252140865304,11.004879117351749,-102.3688901687063) " id="svg_377" y="1184.54092" x="503.9305" strokeOpacity="null" strokeWidth="0" fill="#000000">L4</text>
                            <line opacity="0.5" stroke="#999999" transform="rotate(-180 378.2716064453125,810.7667236328125) " fill="none" x1="378.27161" y1="790.41249" x2="378.27161" y2="831.11101" id="svg_378" strokeLinejoin="null" strokeLinecap="null" />
                            <line opacity="0.5" transform="rotate(-180 422.87945556640625,810.7667236328125) " stroke="#999999" fill="none" x1="422.87946" y1="790.41249" x2="422.87946" y2="831.11101" id="svg_379" strokeLinejoin="null" strokeLinecap="null" />
                            <line stroke="#999999" opacity="0.5" transform="rotate(-180 333.8272705078125,754.3615112304688) " fill="none" x1="333.82728" y1="717.34566" x2="333.82728" y2="791.3774" id="svg_386" strokeLinejoin="null" strokeLinecap="null" />
                            <line stroke="#999999" opacity="0.5" transform="rotate(-180 466.73291015625,754.3615112304688) " fill="none" x1="466.73291" y1="717.34566" x2="466.73291" y2="791.3774" id="svg_483" strokeLinejoin="null" strokeLinecap="null" />
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="16" id="svg_328" y="1116.16054" x="485.8007" strokeOpacity="null" strokeWidth="0" fill="#000000">LIFT</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="16" id="svg_329" y="1141.52822" x="473.18872" strokeOpacity="null" strokeWidth="0" fill="#000000">LOBBBY</text>
                            <rect stroke="#b2b2b2" fill="#cccccc" x="466.68806" y="790.65621" width="59.24914" height="57.09979" id="svg_486" />
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_487" y="1202.86138" x="628.03823" strokeOpacity="null" strokeWidth="0" fill="#000000">SITE</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_488" y="1203.24483" x="661.76722" strokeOpacity="null" strokeWidth="0" fill="#000000">SUPPORT</text>
                            <rect fill="#e5e5e5" x="29.90361" y="678.16015" width="93.45202" height="151.96438" id="svg_490" stroke="#7f7f7f" />
                            <line transform="rotate(-180 76.64224243164062,748.48974609375) " fill="none" x1="29.67672" y1="748.48976" x2="123.60776" y2="748.48976" id="svg_491" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="16" id="svg_492" y="1057.60096" x="-113.9881" strokeOpacity="null" strokeWidth="0" fill="#000000">DOCTOR</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="16" id="svg_493" y="1078.94984" x="-104.99198" strokeOpacity="null" strokeWidth="0" fill="#000000">ROOM</text>
                            <line transform="rotate(-180 77.00719451904295,782.4299926757812) " fill="none" x1="30.04167" y1="782.43001" x2="123.97271" y2="782.43001" id="svg_494" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <text stroke="#ffffff" transform="matrix(0.5224493551303553,0,0,0.7567415604525521,117.51860549806872,-106.86944355450854) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="16" id="svg_497" y="1209.16694" x="-113.9881" strokeOpacity="null" strokeWidth="0" fill="#000000">PANTRY</text>
                            <rect fill="#cccccc" x="30.57412" y="749.05202" width="92.4595" height="33.01316" id="svg_498" stroke="#b2b2b2" />
                            <text stroke="#ffffff" transform="matrix(0.39741605829031934,0,0,0.5100961104744118,115.4075380945323,178.98269237596838) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="16" id="svg_495" y="1145.57291" x="-138.78232" strokeOpacity="null" strokeWidth="0" fill="#000000">STORAGE</text>
                            <text stroke="#ffffff" transform="matrix(0.3738918204383772,0,0,0.5319013078764908,110.4654446737131,155.82736525233764) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="16" id="svg_496" y="1159.99779" x="-118.02675" strokeOpacity="null" strokeWidth="0" fill="#000000">ROOM</text>
                            <rect fill="#cccccc" x="123.92868" y="710.40762" width="59.46669" height="91.85598" id="svg_509" stroke="#999999" />
                            <line transform="rotate(-180 153.83511352539062,757.38623046875) " stroke="#999999" fill="none" x1="123.66172" y1="757.38621" x2="184.00851" y2="757.38621" id="svg_511" strokeLinejoin="null" strokeLinecap="null" />
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_512" y="1086.12245" x="38.86884" strokeOpacity="null" strokeWidth="0" fill="#000000">SMALL</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_513" y="1099.84163" x="31.86719" strokeOpacity="null" strokeWidth="0" fill="#000000">MEETING</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_514" y="1115.92526" x="37.99363" strokeOpacity="null" strokeWidth="0" fill="#000000">ROOM</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_555" y="1157.99055" x="37.95809" strokeOpacity="null" strokeWidth="0" fill="#000000">SMALL</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_556" y="1174.10534" x="30.95644" strokeOpacity="null" strokeWidth="0" fill="#000000">MEETING</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_557" y="1190.9875" x="37.08288" strokeOpacity="null" strokeWidth="0" fill="#000000">ROOM</text>
                            <rect fill="#cccccc" x="201.47879" y="720.95171" width="20.69163" height="63.96514" id="svg_558" stroke="#999999" />
                            <rect fill="#cccccc" x="201.47879" y="729.79515" width="14.17239" height="47.97894" id="svg_559" stroke="#999999" />
                            <text stroke="#ffffff" transform="rotate(90 254.5395202636718,751.2236328125001) matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="16" id="svg_561" y="1128.26312" x="185.43048" strokeOpacity="null" strokeWidth="0" fill="#000000">RECEPTION &amp;</text>
                            <text stroke="#ffffff" transform="rotate(90 242.1297302246093,750.1856689453126) matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="16" id="svg_563" y="1126.62655" x="159.46733" strokeOpacity="null" strokeWidth="0" fill="#000000">WAITING AREA</text>
                            <rect fill="#cccccc" x="284.81022" y="688.92307" width="11.79148" height="10.67821" id="svg_564" stroke="#999999" strokeWidth="0" />
                            <rect fill="#cccccc" x="284.81022" y="704.3989" width="11.79148" height="10.67821" id="svg_566" stroke="#999999" strokeWidth="0" />
                            <rect fill="#cccccc" x="301.07968" y="688.92307" width="11.79148" height="10.67821" id="svg_816" stroke="#999999" strokeWidth="0" />
                            <rect fill="#cccccc" x="301.07968" y="704.3989" width="11.79148" height="10.67821" id="svg_817" stroke="#999999" strokeWidth="0" />
                            <rect fill="#cccccc" x="287.19111" y="792.88892" width="11.79148" height="10.67821" id="svg_820" stroke="#999999" strokeWidth="0" />
                            <rect fill="#cccccc" x="287.19111" y="808.36475" width="11.79148" height="10.67821" id="svg_821" stroke="#999999" strokeWidth="0" />
                            <rect fill="#cccccc" x="303.46058" y="792.88892" width="11.79148" height="10.67821" id="svg_825" stroke="#999999" strokeWidth="0" />
                            <rect fill="#cccccc" x="303.46058" y="808.36475" width="11.79148" height="10.67821" id="svg_827" stroke="#999999" strokeWidth="0" />
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS574_text" data-tip="" className={`${floorState.WS574.status}_text`} onClick={(e)=>{seatSelected(e)}} y="913.64922" x="1108.47447" >574</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS573_text" data-tip="" className={`${floorState.WS573.status}_text`} onClick={(e)=>{seatSelected(e)}} y="913.19588" x="1024.19539" >573</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS572_text" data-tip="" className={`${floorState.WS572.status}_text`} onClick={(e)=>{seatSelected(e)}} y="913.19588" x="935.26286" >572</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS571_text" data-tip="" className={`${floorState.WS571.status}_text`} onClick={(e)=>{seatSelected(e)}} y="912.74253" x="846.33033" >571</text>
                            <rect fill="#cccccc" x="578.0703" y="655.00053" width="192.37808" height="157.67876" id="svg_857" stroke="#b2b2b2" />
                            <line transform="rotate(-180 673.9002685546875,746.8621215820312) " fill="none" x1="577.95138" y1="746.86213" x2="769.84922" y2="746.86213" id="svg_859" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line transform="rotate(-180 650.0274047851562,778.9176025390625) " fill="none" x1="650.02741" y1="746.28673" x2="650.02741" y2="811.54839" id="svg_860" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="697.38579" y1="654.20102" x2="697.38579" y2="746.64988" id="svg_861" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="577.95141" y1="688.84265" x2="698.29356" y2="688.84265" id="svg_862" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_864" y="1002.83878" x="878.39502" strokeOpacity="null" strokeWidth="0" fill="#000000">BMS ROOM</text>
                            <text stroke="#ffffff" transform="matrix(0.5027920453364771,0,0,0.5267736234230851,173.38548255509264,152.38101741537696) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="15" id="svg_871" y="1071.79856" x="895.74437" strokeOpacity="null" strokeWidth="0" fill="#000000">PANTRY</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_888" y="1025.41484" x="1075.90438" strokeOpacity="null" strokeWidth="0" fill="#000000">MEDIUM</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_889" y="1041.52963" x="1073.54287" strokeOpacity="null" strokeWidth="0" fill="#000000">MEETING</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_890" y="1058.41179" x="1079.66932" strokeOpacity="null" strokeWidth="0" fill="#000000">ROOM</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_891" y="1154.20737" x="1033.10931" strokeOpacity="null" strokeWidth="0" fill="#000000">MEDIUM</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_892" y="1170.32216" x="1030.7478" strokeOpacity="null" strokeWidth="0" fill="#000000">MEETING</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_893" y="1187.20432" x="1036.87425" strokeOpacity="null" strokeWidth="0" fill="#000000">ROOM</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_895" y="1159.1669" x="850.31452" strokeOpacity="null" strokeWidth="0" fill="#000000">DIRECTORS</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_896" y="1179.0914" x="866.85058" strokeOpacity="null" strokeWidth="0" fill="#000000">CABIN</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS318_text" data-tip="" className={`${floorState.WS318.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1290.26123" x="1108.94711" >318</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS317_text" data-tip="" className={`${floorState.WS317.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1289.80789" x="1024.66802" >317</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS316_text" data-tip="" className={`${floorState.WS316.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1289.80789" x="935.73549" >316</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS315_text" data-tip="" className={`${floorState.WS315.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1289.35454" x="846.80297" >315</text>
                            <rect stroke="#b2b2b2" fill="#cccccc" strokeWidth="1.5" x="274.67111" y="830.29254" width="191.75853" height="233.90174" id="svg_314" />
                            <line stroke="#999999" opacity="0.45" fill="none" x1="334.01376" y1="825.53798" x2="334.01376" y2="1064.82953" id="svg_315" strokeLinejoin="null" strokeLinecap="null" />
                            <line opacity="0.45" transform="rotate(-180 303.891845703125,900.45458984375) " stroke="#999999" fill="none" x1="273.71845" y1="900.45459" x2="334.06524" y2="900.45459" id="svg_316" strokeLinejoin="null" strokeLinecap="null" />
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_354" y="1290.4689" x="305.78053" strokeOpacity="null" strokeWidth="0" fill="#000000">MEDIUM</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_374" y="1306.58369" x="303.41902" strokeOpacity="null" strokeWidth="0" fill="#000000">MEETING</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_484" y="1323.46585" x="309.54547" strokeOpacity="null" strokeWidth="0" fill="#000000">ROOM</text>
                            <rect stroke="#b2b2b2" fill="#cccccc" x="334.09285" y="830.65618" width="132.24914" height="106.09979" id="svg_485" />
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="16" id="svg_507" y="1318.17075" x="475.49211" strokeOpacity="null" strokeWidth="0" fill="#000000">SERVICE</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="16" id="svg_508" y="1343.53843" x="479.11827" strokeOpacity="null" strokeWidth="0" fill="#000000">ROOM</text>
                            <line opacity="0.45" transform="rotate(-180 304.487060546875,964.1433715820312) " stroke="#999999" fill="none" x1="274.31367" y1="964.14337" x2="334.66046" y2="964.14337" id="svg_510" strokeLinejoin="null" strokeLinecap="null" />
                            <line opacity="0.45" transform="rotate(-180 304.487060546875,1029.9769287109375) " stroke="#999999" fill="none" x1="274.31367" y1="1029.97682" x2="334.66046" y2="1029.97682" id="svg_565" strokeLinejoin="null" strokeLinecap="null" />
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_569" y="1405.3374" x="305.82016" strokeOpacity="null" strokeWidth="0" fill="#000000">CU - 20</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_571" y="1507.21636" x="305.82016" strokeOpacity="null" strokeWidth="0" fill="#000000">CU - 19</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_718" y="1582.6146" x="323.72253" strokeOpacity="null" strokeWidth="0" fill="#000000">DB</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_803" y="1598.72939" x="300.21518" strokeOpacity="null" strokeWidth="0" fill="#000000">STORAGE</text>
                            <rect stroke="#b2b2b2" fill="#cccccc" x="466.31355" y="986.27745" width="59.34092" height="78.11673" id="svg_811" />
                            <rect stroke="#b2b2b2" strokeWidth="0" fill="#e5e5e5" x="467.05156" y="848.34433" width="58.23392" height="137.71784" id="svg_813" />
                            <line opacity="0.45" transform="rotate(-180 495.75341796875,1035.4075927734375) " stroke="#999999" fill="none" x1="465.58004" y1="1035.40763" x2="525.92683" y2="1035.40763" id="svg_833" strokeLinejoin="null" strokeLinecap="null" />
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_858" y="1534.33453" x="653.15058" strokeOpacity="null" strokeWidth="0" fill="#000000">CU - 38</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_863" y="1595.03015" x="642.3031" strokeOpacity="null" strokeWidth="0" fill="#000000">AHU ROOM</text>
                            <rect fill="#fff" strokeWidth="1.5" strokeOpacity="null" x="498.97322" y="845.51634" width="0" height="5.15461" id="svg_865" stroke="#000" />
                            <line fill="none" x1="516.306" y1="852.77484" x2="516.306" y2="882.50289" id="svg_906" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS326" data-tip="" className={floorState.WS326.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(89.94062042236328 511.22161865234375,963.1076049804688) " x="493.36462" y="953.14528" width="35.71396" height="19.92463" />
                            <rect id="WS325" data-tip="" className={floorState.WS325.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(89.64104461669922 482.0396118164062,963.0280151367188) " x="464.18262" y="953.06566" width="35.71396" height="19.92463" />
                            <line stroke="#999999" fill="none" x1="465.99365" y1="986.11325" x2="526.01401" y2="986.11325" id="svg_916" strokeLinejoin="null" strokeLinecap="null" />
                            <line fill="none" x1="525.67877" y1="894.48146" x2="525.67877" y2="924.20951" id="svg_917" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="525.67877" y1="864.95188" x2="525.67877" y2="894.67993" id="svg_918" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line fill="none" x1="525.69465" y1="956.52213" x2="525.69465" y2="986.25018" id="svg_919" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS314" data-tip="" className={floorState.WS314.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(89.94062042236328 511.22171020507807,870.4882202148439) " x="493.36462" y="860.52593" width="35.71396" height="19.92463" />
                            <rect id="WS313" data-tip="" className={floorState.WS313.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(89.64104461669922 482.0396118164062,870.4086303710938) "  x="464.18262" y="860.44631" width="35.71396" height="19.92463" />
                            <rect id="WS323" data-tip="" className={floorState.WS323.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(89.94062042236328 511.22171020507807,915.5064086914064) " x="493.36462" y="905.5441" width="35.71396" height="19.92463" />
                            <rect id="WS324" data-tip="" className={floorState.WS324.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(89.64104461669922 482.03964233398443,915.4268798828124) " strokeWidth="1.5" x="464.18262" y="905.46448" width="35.71396" height="19.92463" />
                            <line stroke="#999999" transform="rotate(-180 495.84381103515625,893.1517944335938) " fill="none" x1="465.97659" y1="893.15182" x2="525.711" y2="893.15182" id="svg_924" strokeLinejoin="null" strokeLinecap="null" />
                            <text transform="rotate(90 482.93911743164057,869.0765380859376) matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS313_text" data-tip="" className={`${floorState.WS313.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1311.9936" x="639.57025" >313</text>
                            <text transform="rotate(90 510.9756469726562,869.8112792968751) matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS314_text" data-tip="" className={`${floorState.WS314.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1313.15458" x="689.88698" >314</text>
                            <rect stroke="#b2b2b2" fill="#cccccc" x="334.30324" y="936.50343" width="132.22352" height="127.56964" id="svg_931" />
                            <rect fill="#cccccc" x="400.7998" y="936.50343" width="65.72696" height="127.88016" id="svg_932" stroke="#b2b2b2" />
                            <rect stroke="#b2b2b2" fill="#cccccc" x="378.46508" y="1022.78206" width="22.07273" height="41.81851" id="svg_933" />
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="16" id="svg_934" y="1455.02681" x="464.99039" strokeOpacity="null" strokeWidth="0" fill="#000000">WASHROOM</text>
                            <text stroke="#ffffff" transform="rotate(90 443.9072875976562,1005.5831298828126) matrix(0.7797585912333851,0,0,0.8897444348318662,-25.8888512349747,-87.26633611028196) " id="svg_935" y="1232.45118" x="590.37279" strokeOpacity="null" strokeWidth="0" fill="#000000">SHE</text>
                            <text stroke="#ffffff" transform="rotate(90 353.39953613281244,1003.3239746093751) matrix(0.7797585912333851,0,0,0.8897444348318662,-25.8888512349747,-87.26633611028196) " id="svg_936" y="1229.90796" x="478.31165" strokeOpacity="null" strokeWidth="0" fill="#000000">HE</text>
                            <text stroke="#ffffff" transform="rotate(90 388.7872009277342,1041.5769042968752) matrix(0.7797585912333851,0,0,0.8897444348318662,-25.8888512349747,-87.26633611028196) " id="svg_937" y="1272.90876" x="521.35719" strokeOpacity="null" strokeWidth="0" fill="#000000">H.T.</text>
                            <rect fill="#cccccc" strokeWidth="1.5" x="274.67111" y="1095.58065" width="251.17864" height="132.89165" id="svg_901" stroke="#b2b2b2" />
                            <line opacity="0.5" transform="rotate(-180 333.8272705078125,1134.8690185546875) " fill="none" x1="333.82728" y1="1096.45826" x2="333.82728" y2="1173.27994" id="svg_902" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line opacity="0.5" transform="rotate(-180 466.73291015624994,1162.0445556640625) " fill="none" x1="466.73291" y1="1095.35902" x2="466.73291" y2="1228.73015" id="svg_903" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line opacity="0.45" transform="rotate(-180 496.3028564453125,1139.801025390625) " stroke="#999999" fill="none" x1="466.12948" y1="1139.801" x2="526.47627" y2="1139.801" id="svg_904" strokeLinejoin="null" strokeLinecap="null" />
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_905" y="1698.5593" x="654.13638" strokeOpacity="null" strokeWidth="0" fill="#000000">CU - 16</text>
                            <line opacity="0.45" transform="rotate(-180 496.30285644531244,1184.8548583984375) " stroke="#999999" fill="none" x1="466.12948" y1="1184.85498" x2="526.47627" y2="1184.85498" id="svg_909" strokeLinejoin="null" strokeLinecap="null" />
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_910" y="1769.43525" x="654.13638" strokeOpacity="null" strokeWidth="0" fill="#000000">CU - 17</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_912" y="1837.71818" x="652.16478" strokeOpacity="null" strokeWidth="0" fill="#000000">CU - 18</text>
                            <text stroke="#ffffff" transform="matrix(0.8350667499228236,0,0,0.8349629405620289,-20.95987142152063,-173.56613337686628) " id="svg_945" y="1650.57751" x="473.50796" strokeOpacity="null" strokeWidth="0" fill="#000000">STAIRCASE</text>
                            <line opacity="0.45" transform="rotate(-180 400.30285644531244,1173.5152587890625) " fill="none" x1="333.55803" y1="1173.51528" x2="467.04769" y2="1173.51528" id="svg_946" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <text stroke="#ffffff" transform="matrix(0.8350667499228236,0,0,0.8349629405620289,-20.95987142152063,-173.56613337686628) " id="svg_947" y="1568.4524" x="488.56235" strokeOpacity="null" strokeWidth="0" fill="#000000">A.H.U.</text>
                            <line opacity="0.45" transform="rotate(-180 304.3028564453125,1123.8009033203125) " stroke="#999999" fill="none" x1="274.12949" y1="1123.801" x2="334.47628" y2="1123.801" id="svg_948" strokeLinejoin="null" strokeLinecap="null" />
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_949" y="1685.97423" x="297.34697" strokeOpacity="null" strokeWidth="0" fill="#000000">STORAGE</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_950" y="1699.45823" x="308.6248" strokeOpacity="null" strokeWidth="0" fill="#000000">AREA</text>
                            <line opacity="0.45" transform="rotate(-180 304.87429809570307,1173.5152587890625) " stroke="#999999" fill="none" x1="274.70092" y1="1173.51528" x2="335.04771" y2="1173.51528" id="svg_951" strokeLinejoin="null" strokeLinecap="null" />
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_952" y="1743.50598" x="295.29646" strokeOpacity="null" strokeWidth="0" fill="#000000">DIrectORS</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_953" y="1756.98999" x="310.67531" strokeOpacity="null" strokeWidth="0" fill="#000000">CABIN</text>
                            <line opacity="0.45" transform="rotate(-180 304.3029174804687,1197.5152587890625) " fill="none" x1="274.70096" y1="1197.51528" x2="333.90489" y2="1197.51528" id="svg_954" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_955" y="1845.08549" x="300.42274" strokeOpacity="null" strokeWidth="0" fill="#000000">LOUNGE</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_956" y="1858.56949" x="309.65006" strokeOpacity="null" strokeWidth="0" fill="#000000">AREA</text>
                            <line opacity="0.5" transform="rotate(-180 333.82727050781244,1213.1512451171875) " fill="none" x1="333.82728" y1="1197.02612" x2="333.82728" y2="1229.27636" id="svg_957" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <text stroke="#ffffff" transform="rotate(90 321.06127929687483,1185.2144775390627) matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_958" y="1808.9523" x="345.61295" strokeOpacity="null" strokeWidth="0" fill="#000000">EXIT</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="15" id="svg_960" y="2024.47704" x="167.33018" strokeOpacity="null" strokeWidth="0" fill="#000000">LARGE</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="15" id="svg_961" y="2041.83052" x="156.49212" strokeOpacity="null" strokeWidth="0" fill="#000000">MEETING</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="15" id="svg_962" y="2059.95137" x="165.44409" strokeOpacity="null" strokeWidth="0" fill="#000000">ROOM</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="15" id="svg_964" y="2020.0696" x="584.55801" strokeOpacity="null" strokeWidth="0" fill="#000000">TELE-PRESENCE</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="15" id="svg_965" y="2041.90653" x="613.2886" strokeOpacity="null" strokeWidth="0" fill="#000000">ROOM</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS400_text" data-tip="" className={`${floorState.WS400.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1883.71514" x="130.73914" >400</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS401_text" data-tip="" className={`${floorState.WS401.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1883.2618" x="46.46006" >401</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS402_text" data-tip="" className={`${floorState.WS402.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1883.2618" x="-42.47247" >402</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS403_text" data-tip="" className={`${floorState.WS403.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1882.80846" x="-131.405" >403</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS364_text" data-tip="" className={`${floorState.WS364.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1825.69368" x="900.71595" >364</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS363_text" data-tip="" className={`${floorState.WS363.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1825.69368" x="974.1794" >363</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS362_text" data-tip="" className={`${floorState.WS362.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1825.69368" x="1047.64286" >362</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS361_text" data-tip="" className={`${floorState.WS361.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1825.69368" x="1119.69355" >361</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS365_text" data-tip="" className={`${floorState.WS365.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1897.53775" x="900.71595" >365</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS366_text" data-tip="" className={`${floorState.WS366.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1897.53775" x="974.1794" >366</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS367_text" data-tip="" className={`${floorState.WS367.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1897.53775" x="1047.64286" >367</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS368_text" data-tip="" className={`${floorState.WS368.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1897.53775" x="1119.69355" >368</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS372_text" data-tip="" className={`${floorState.WS372.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1944.608" x="900.71595" >372</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS371_text" data-tip="" className={`${floorState.WS371.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1944.608" x="974.1794" >371</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS370_text" data-tip="" className={`${floorState.WS370.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1944.608" x="1047.64286" >370</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS369_text" data-tip="" className={`${floorState.WS369.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1944.608" x="1119.69355" >369</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS373_text" data-tip="" className={`${floorState.WS373.status}_text`} onClick={(e)=>{seatSelected(e)}} y="2016.45207" x="900.71595" >373</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS374_text" data-tip="" className={`${floorState.WS374.status}_text`} onClick={(e)=>{seatSelected(e)}} y="2016.45207" x="974.1794" >374</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS375_text" data-tip="" className={`${floorState.WS375.status}_text`} onClick={(e)=>{seatSelected(e)}} y="2016.45207" x="1047.64286" >375</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS376_text" data-tip="" className={`${floorState.WS376.status}_text`} onClick={(e)=>{seatSelected(e)}} y="2016.45207" x="1119.69355" >376</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS380_text" data-tip="" className={`${floorState.WS380.status}_text`} onClick={(e)=>{seatSelected(e)}} y="2062.28363" x="900.71595" >380</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS379_text" data-tip="" className={`${floorState.WS379.status}_text`} onClick={(e)=>{seatSelected(e)}} y="2062.28363" x="974.1794" >379</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS378_text" data-tip="" className={`${floorState.WS378.status}_text`} onClick={(e)=>{seatSelected(e)}} y="2062.28363" x="1047.64286" >378</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS377_text" data-tip="" className={`${floorState.WS377.status}_text`} onClick={(e)=>{seatSelected(e)}} y="2062.28363" x="1119.69355" >377</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS384_text" data-tip="" className={`${floorState.WS384.status}_text`} onClick={(e)=>{seatSelected(e)}} y="2134.1277" x="900.71595" >384</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS383_text" data-tip="" className={`${floorState.WS383.status}_text`} onClick={(e)=>{seatSelected(e)}} y="2134.1277" x="974.1794" >383</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS382_text" data-tip="" className={`${floorState.WS382.status}_text`} onClick={(e)=>{seatSelected(e)}} y="2134.1277" x="1047.64286" >382</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS381_text" data-tip="" className={`${floorState.WS381.status}_text`} onClick={(e)=>{seatSelected(e)}} y="2134.1277" x="1119.69355" >381</text>
                            <text transform="rotate(90 482.20254516601557,913.3504638671876) matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS324_text" data-tip="" className={`${floorState.WS324.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1381.65224" x="638.25337" >324</text>
                            <text transform="rotate(90 510.2443237304687,914.0900268554689) matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS323_text" data-tip="" className={`${floorState.WS323.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1382.81322" x="688.5701" >323</text>
                            <text transform="rotate(90 480.6265258789062,960.5936279296876) matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS325_text" data-tip="" className={`${floorState.WS325.status}_text`} onClick={(e)=>{seatSelected(e)}}  y="1455.97353" x="635.42786" >325</text>
                            <text transform="rotate(90 509.7566833496093,960.5936889648439) matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS326_text" data-tip="" className={`${floorState.WS326.status}_text`} onClick={(e)=>{seatSelected(e)}}  y="1455.97353" x="687.69982" >326</text>
                            <text transform="rotate(90 550.8632202148436,1380.0141601562502) matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS386_text" data-tip="" className={`${floorState.WS386.status}_text`} onClick={(e)=>{seatSelected(e)}} y="2116.19435" x="761.16312" >386</text>
                            <text transform="rotate(90 579.2097778320311,1380.7988281250002) matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS385_text" data-tip="" className={`${floorState.WS385.status}_text`} onClick={(e)=>{seatSelected(e)}} y="2117.43304" x="812.02232" >385</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS388_text" data-tip="" className={`${floorState.WS388.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1968.7312" x="491.47812" >388</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS389_text" data-tip="" className={`${floorState.WS389.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1968.7312" x="402.54559" >389</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS390_text" data-tip="" className={`${floorState.WS390.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1968.27786" x="313.61306" >390</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS393_text" data-tip="" className={`${floorState.WS393.status}_text`} onClick={(e)=>{seatSelected(e)}} y="2014.56275" x="491.47812" >393</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS392_text" data-tip="" className={`${floorState.WS392.status}_text`} onClick={(e)=>{seatSelected(e)}} y="2014.56275" x="402.54559" >392</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS391_text" data-tip="" className={`${floorState.WS391.status}_text`} onClick={(e)=>{seatSelected(e)}} y="2014.10941" x="313.61306" >391</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS394_text" data-tip="" className={`${floorState.WS394.status}_text`} onClick={(e)=>{seatSelected(e)}} y="2090.12264" x="491.47812" >394</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS395_text" data-tip="" className={`${floorState.WS395.status}_text`} onClick={(e)=>{seatSelected(e)}} y="2090.12264" x="402.54559" >395</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS396_text" data-tip="" className={`${floorState.WS396.status}_text`} onClick={(e)=>{seatSelected(e)}} y="2089.6693" x="313.61306" >396</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS399_text" data-tip="" className={`${floorState.WS399.status}_text`} onClick={(e)=>{seatSelected(e)}} y="2135.9542" x="491.47812" >399</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS398_text" data-tip="" className={`${floorState.WS398.status}_text`} onClick={(e)=>{seatSelected(e)}} y="2135.9542" x="402.54559" >398</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS397_text" data-tip="" className={`${floorState.WS397.status}_text`} onClick={(e)=>{seatSelected(e)}} y="2135.50086" x="313.61306" >397</text>
                            <rect fill="#cccccc" x="137.14842" y="1269.27259" width="40.01494" height="31.26467" id="svg_1016" stroke="#cccccc" />
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_1017" y="1959.60934" x="41.88089" strokeOpacity="null" strokeWidth="0" fill="#000000">UTILITY</text>
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="svg_1018" y="1972.57756" x="47.02544" strokeOpacity="null" strokeWidth="0" fill="#000000">AREA</text>
                            <rect fill="#cccccc" x="30.69704" y="1269.27259" width="105.82125" height="31.26467" id="svg_1019" stroke="#cccccc" />
                            <text stroke="#ffffff" transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="15" id="svg_1020" y="1968.65628" x="-74.59686" strokeOpacity="null" strokeWidth="0" fill="#000000">LAB</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS522_text" data-tip="" className={`${floorState.WS522.status}_text`} onClick={(e)=>{seatSelected(e)}}  y="140.40121" x="1110.50764" >522</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS521_text" data-tip="" className={`${floorState.WS521.status}_text`} onClick={(e)=>{seatSelected(e)}} y="139.94786" x="1026.22855" >521</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS520_text" data-tip="" className={`${floorState.WS520.status}_text`} onClick={(e)=>{seatSelected(e)}} y="139.94786" x="937.29602" >520</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS519_text" data-tip="" className={`${floorState.WS519.status}_text`} onClick={(e)=>{seatSelected(e)}} y="139.49452" x="848.3635" >519</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS518_text" data-tip="" className={`${floorState.WS518.status}_text`} onClick={(e)=>{seatSelected(e)}} y="64.28199" x="1110.39223" >518</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS517_text" data-tip="" className={`${floorState.WS517.status}_text`} onClick={(e)=>{seatSelected(e)}} y="63.82864" x="1021.59837" >517</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS516_text" data-tip="" className={`${floorState.WS516.status}_text`} onClick={(e)=>{seatSelected(e)}} y="63.82864" x="932.66584" >516</text>
                            <text transform="rotate(90 291.1113586425782,82.35084533691409) matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS500_text" data-tip="" className={`${floorState.WS500.status}_text`} onClick={(e)=>{seatSelected(e)}} y="74.8026" x="295.12438" >500</text>
                            <text transform="rotate(90 318.94845581054693,83.09079742431643) matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS501_text" data-tip="" className={`${floorState.WS501.status}_text`} onClick={(e)=>{seatSelected(e)}} y="75.96358" x="345.44111" >501</text>
                            <text transform="rotate(90 290.74560546875006,126.63444519042972) matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS503_text" data-tip="" className={`${floorState.WS503.status}_text`} onClick={(e)=>{seatSelected(e)}} y="144.46124" x="294.46232" >503</text>
                            <text transform="rotate(90 318.78735351562506,127.37440490722659) matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS502_text" data-tip="" className={`${floorState.WS502.status}_text`} onClick={(e)=>{seatSelected(e)}} y="145.62222" x="344.77905" >502</text>
                            <text transform="rotate(90 289.79635620117193,173.87791442871097) matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS504_text" data-tip="" className={`${floorState.WS504.status}_text`} onClick={(e)=>{seatSelected(e)}} y="218.78253" x="292.7655" >504</text>
                            <text transform="rotate(90 318.3040161132813,173.87791442871097) matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS505_text" data-tip="" className={`${floorState.WS505.status}_text`} onClick={(e)=>{seatSelected(e)}} y="218.78253" x="343.90877" >505</text>
                            <text transform="rotate(90 290.42773437500006,215.8125152587891) matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS507_text" data-tip="" className={`${floorState.WS507.status}_text`} onClick={(e)=>{seatSelected(e)}} y="284.75252" x="293.89419" >507</text>
                            <text transform="rotate(90 318.3040161132813,215.8125152587891) matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS506_text" data-tip="" className={`${floorState.WS506.status}_text`} onClick={(e)=>{seatSelected(e)}} y="284.75252" x="343.90877" >506</text>
                            <text transform="rotate(90 482.7009582519531,217.74934387207037) matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS508_text" data-tip="" className={`${floorState.WS508.status}_text`} onClick={(e)=>{seatSelected(e)}} y="287.79729" x="638.87163" >508</text>
                            <text transform="rotate(90 511.2042541503906,217.74934387207037) matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS509_text" data-tip="" className={`${floorState.WS509.status}_text`} onClick={(e)=>{seatSelected(e)}} y="287.79729" x="690.0149" >509</text>
                            <line opacity="0.5" transform="rotate(-180 177.54322814941406,1285.2786865234375) " fill="none" x1="177.54328" y1="1269.1572" x2="177.54328" y2="1301.40744" id="svg_1049" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <line opacity="0.5" transform="rotate(-180 136.0132598876953,1285.2823486328125) " fill="none" x1="136.01327" y1="1269.1572" x2="136.01327" y2="1301.40744" id="svg_1050" strokeLinejoin="null" strokeLinecap="null" stroke="#999999" />
                            <rect id="WS576" data-tip="" className={floorState.WS576.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(89.64104461669922 482.54208374023415,814.5483398437501) " x="475.68849" y="804.58599" width="13.70723" height="19.92463"  />
                            <rect id="WS575" data-tip="" className={floorState.WS575.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(89.64104461669922 510.54205322265625,814.5447998046876) " x="503.69199" y="804.58244" width="13.70009" height="19.92463" />
                            <rect id="WS577" data-tip="" className={floorState.WS577.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(89.64104461669922 482.54208374023426,834.5477294921875) " x="475.68849" y="824.58599" width="13.70723" height="19.92463" />
                            <rect id="WS578" data-tip="" className={floorState.WS578.status} onClick={(e)=>{seatSelected(e)}} transform="rotate(89.64104461669922 510.54205322265625,834.5441894531251) " x="503.69199" y="824.58244" width="13.70009" height="19.92463" />
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS576_text" data-tip="" className={`${floorState.WS576.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1225.97971" x="638.16317" >576</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS575_text" data-tip="" className={`${floorState.WS575.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1225.97971" x="688.40076" >575</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS577_text" data-tip="" className={`${floorState.WS577.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1257.4424" x="638.16317" >577</text>
                            <text transform="matrix(0.5573515295982361,0,0,0.6356737379714588,120.83611603733152,37.56066693059137) " id="WS578_text" data-tip="" className={`${floorState.WS578.status}_text`} onClick={(e)=>{seatSelected(e)}} y="1257.4424" x="688.40076" >578</text>

                        </g>



         </svg>
            
     </div>
)  
}