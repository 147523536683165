import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import { firebase } from '../../../config/firebase';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import warningIcon from './../../../Assets/Images/warning.png';
import confirmIcon from './../../../Assets/Images/confirm.png';
import { confirmAlert } from 'react-confirm-alert';
import DatePicker from "react-date-picker";
import calendarIcon from "./../../../Assets/Images/calendar-icon.png";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import './category.css'
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width: "80%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

export default function ReserveCategory(props) {
    const history = useHistory();
    const [noOfSeats] = useState(1);
    const [availableSites, setAvailableSites] = useState([])
    const [active_days, setactive_days] = useState([false, true, true, true, true, true, false])
    const [auto_book, setautobook] = useState(false);
    const [currency, setCurrency] = React.useState(props.commonState.site);
    const [active, setactive] = React.useState(false);
    const check_array = [false, false, false, false, false, false, false];
    const [cat_name, setcat_name] = useState('')
    const [minDate, setMinDate] = useState()
    // const maxDate = new Date(props.commonState.currentDate);
    const [selectedDate, setSelectedDate] = useState();
    const [selectedWeekFreq, setSelectedWeekFreq] = useState(1)
    let startingMonday, startingWeek, weekFrequency;
    const [group, setGroup] = React.useState('');
    const [repeat_week, setRepeatWeek] = React.useState(true)
    const [org_list, set_org_list] = useState([]);
    const classes = useStyles();
    const sites_ref = firebase.firestore().collection('Sites');

    useEffect(() => {
        
        let currDate = new Date(props.commonState.currentDate)
        let addDays;
        currDate.getDay() === 0 ? addDays = 1 : addDays = 8-currDate.getDay();
        let nxtMonday = new Date(currDate)
        nxtMonday.setDate(nxtMonday.getDate() +addDays)

        setMinDate(nxtMonday)
        setSelectedDate(nxtMonday)
        set_org_list(props.commonState.site_data.organization)

        sites_ref
            .get()
            .then(
            querySnapshot => {
                const tempSites = []
                querySnapshot.forEach(doc => {
                    const site = doc.id;
                    tempSites.push({ value: site, label: site });
                });
                setAvailableSites([...tempSites])
               
            })
    }, []);

    const handleChange = (event) => {
        // console.log('Change Site');
        setCurrency(event.target.value);
    };

    const handleCheckBoxChange = (e, index) => {
        let temp_arr = [...active_days]
        temp_arr[index] = !active_days[index];
        setactive_days([...temp_arr])
    }

    const handleGroupChange = (val) => {
        setGroup(val);
      };

      const handleRepeatWeek = (val) => {
        // console.log('repeat_week 1', val)
        if(val) {
            document.getElementById("repeat_weekly_sub").classList.remove('disable_week_config')
          }
          else {
            document.getElementById("repeat_weekly_sub").classList.add('disable_week_config')
          }

        //   repeat_week === true ? setRepeatWeek(false): setRepeatWeek(true)
        setRepeatWeek(val)
        //   console.log('repeat_week 2', val)
      }


      const get_cat_next_week = (cat_starting_week, cat_week_freq) => {
          let cat_week_no = cat_starting_week;
        // do {
        //     cat_week_no +=  cat_week_freq;
        //   }
        //   while (cat_week_no <= startingWeek);

          while(cat_week_no < startingWeek) {
            cat_week_no +=  cat_week_freq;
          }

          return cat_week_no;
      }

    const getConflictCategories = () => {
       
      //get the Monday of the selected week
        let selectedMonday;

        if ( selectedDate.getDay() !== 1 )
        {
            let temp_selectedDate  = new Date(selectedDate);
            if(selectedDate.getDay() === 0){
                temp_selectedDate.setDate(temp_selectedDate.getDate() - 6)
            }
            else {
                temp_selectedDate.setDate(temp_selectedDate.getDate() - (temp_selectedDate.getDay() - 1))
            }
            selectedMonday  = new Date(temp_selectedDate);
        }
        else {
            selectedMonday  = new Date(selectedDate);
        }
        
        // console.log('selectedMonday', selectedMonday.getDate())


        //get the week number of selected week
        //Week number will be calcaulated from 03-Jan-2021
        let selectedWeekNo;
        var firstWeekMonday = new Date("03/01/2021");
       
        let Difference_In_Time = selectedMonday.getTime() - firstWeekMonday.getTime();
        let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        selectedWeekNo = Difference_In_Days / 7;

        startingMonday = selectedMonday;
        startingWeek = parseInt(selectedWeekNo);
        weekFrequency = parseInt(selectedWeekFreq);

        // console.log('Difference_In_Time', Difference_In_Time)

        // console.log('Difference_In_Days', Difference_In_Days)

        // console.log('selectedWeekNo', selectedWeekNo)

        let conflictCategories = [];
        props.blockBookings.map((category) => {
            if(category.isActive && category.week_preference) {
                if(category.week_preference.repeat_week === true) {
                   if(category.week_preference.group === group) {}
                   else if(category.week_preference.weekFrequency !== weekFrequency){
                        // if (conflictCategories === '') {
                        //     conflictCategories = category.category;
                        // }
                        // else {
                        //     conflictCategories = conflictCategories + ', ' + category.category;
                        // }
                        conflictCategories.push(category.category)
                    }
                    else {
                    let cat_starting_week = category.week_preference.startingWeek;
                    let cat_week_freq = category.week_preference.weekFrequency;

                    let cat_next_week = get_cat_next_week(cat_starting_week, cat_week_freq)

                    let max_loop_count = cat_week_freq > weekFrequency ? cat_week_freq : weekFrequency;

                    let cat_next_week_temp = cat_next_week;
                    let startingWeek_temp = startingWeek;
                    let isWeekConflict = false;
                    for(let r=0; r <= max_loop_count; r++) {

                        if(cat_next_week_temp === startingWeek_temp){
                            isWeekConflict = true;
                        }

                        cat_next_week_temp += cat_week_freq;
                        startingWeek_temp += weekFrequency;


                    }
                    if(isWeekConflict){
                        // if (conflictCategories === '') {
                        //     conflictCategories = category.category;
                        // }
                        // else {
                        //     conflictCategories = conflictCategories + ', ' + category.category;
                        // }
                        conflictCategories.push(category.category)
                    }
                   }
                }
            } 
            return true;              
        })

            return conflictCategories;
       
    }

    const getOrgList = () => {
        return org_list.map((org_name) => {
          return <MenuItem value={org_name}>{org_name}</MenuItem>;
        });
      };

    const reserve_block = () => {
        if (active === true) {
            return (
                <div className="activedays">
                    
                    <div style={{ fontSize: '14px', paddingRight: '20px' }}>
                        Active Days
                    </div>
                    <div className='block-seats'>
                        <Checkbox checked={active_days[1]}
                            onChange={(e) => { handleCheckBoxChange(e, 1) }}
                        />
                        <div style={{ fontSize: '12px' }}>
                            Mon
                        </div>
                    </div>
                    <div className='block-seats'>
                        <Checkbox checked={active_days[2]}
                            onChange={(e) => { handleCheckBoxChange(e, 2) }}
                        />
                        <div style={{ fontSize: '12px' }}>
                            Tue
                        </div>
                    </div>
                    <div className='block-seats'>
                        <Checkbox checked={active_days[3]}
                            onChange={(e) => { handleCheckBoxChange(e, 3) }}
                        />
                        <div style={{ fontSize: '12px' }}>
                            Wed
                        </div>
                    </div>
                    <div className='block-seats'>
                        <Checkbox checked={active_days[4]}
                            onChange={(e) => { handleCheckBoxChange(e, 4) }}
                        />
                        <div style={{ fontSize: '12px' }}>
                            Thu
                        </div>
                    </div>
                    <div className='block-seats'>
                        <Checkbox checked={active_days[5]}
                            onChange={(e) => { handleCheckBoxChange(e, 5) }}
                        />
                        <div style={{ fontSize: '12px' }}>
                            Fri
                        </div>
                    </div>
                    <div className='block-seats'>
                        <Checkbox checked={active_days[6]}
                            onChange={(e) => { handleCheckBoxChange(e, 6) }}
                        />
                        <div style={{ fontSize: '12px' }}>
                            Sat
                        </div>
                    </div>
                    <div className='block-seats'>
                        <Checkbox checked={active_days[0]}
                            onChange={(e) => { handleCheckBoxChange(e, 0) }}
                        />
                        <div style={{ fontSize: '12px' }}>
                            Sun
                        </div>
                    </div>

                    <div style={{ fontSize: '14px', marginLeft: '10%', marginRight: '10%' }}>
                        Activate Autobooking
                        <Switch checked={auto_book} onChange={(e) => { setautobook(!auto_book) }} />
                    </div>
                    
                    <div className="repeat_weekly_main" style={{ fontSize: '14px', marginTop: '5%' }}>
                            <span style={{visibility:'hidden'}}>
                                <Checkbox checked={repeat_week}
                                    onChange={(e) => { handleRepeatWeek(e.target.checked) }}
                                />
                            </span>
                        <div id="repeat_weekly_sub" className="repeat_weekly_sub" >
                            
                            <span className='alt_week_text'> Repeat every </span>
                            <span>
                                <TextField
                                id="outlined-number"
                                label=""
                                type="number"
                                onChange={(e) => setSelectedWeekFreq(e.target.value)} 
                                value = {selectedWeekFreq}
                                InputLabelProps={{
                                    shrink: true,
                                    
                                }}
                                inputProps = {{
                                    inputMode: 'numeric',
                                    pattern : '[1-9]*'
                                }}
                                style={{width: '36px', marginLeft: '10px', marginRight: '10px', padding:'0px'}}
                                />
                            </span>
                            <span className='alt_week_text'>week(s) starting from</span>
                            {/* <span className='alt_week_text'>starting from </span> */}
                            <span style={{marginLeft:'10px'}}>
                            <DatePicker
                                onChange={(value) => setSelectedDate(value)}
                                value={selectedDate}
                                // maxDate={maxDate}
                                minDate={minDate}
                                format="dd-MMM-y"
                                calendarIcon={<CalendarIcon />}
                            />
                            </span>
                  

                            {/* <div className='reserve-seat-input'>
                    <TextField
                        id="standard-select-currency"
                        select
                        value={group}
                        label="Select"
                        style={{ width: '80%', margin: '5px', marginTop: '20px', }}
                        onChange={handleGroupChange}
                        helperText="Select Block"
                    >
                        {org_list.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                </div> */}
                        </div>   
                    </div>
                </div>
            )
        }
    }


    const SaveCat = (e) => {
        // console.log('saving')
        const trimmed_cat_name = cat_name.trim();
        let temp_cat = [...props.category_names]
        // console.log(props.commonState)
        if (trimmed_cat_name === '') {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui confirm_dialog'>
                            <div className="confirm_title">Validation</div>
                            <div className="confirm_msg">
                                <div className="img">
                                    <img
                                        alt=''
                                        style={{ width: 50, height: 50 }}
                                        src={warningIcon}
                                    />
                                </div>
                                <div className="msg">Enter Category Name</div>
                            </div>

                            <div className="confirm_buttons">
                                <button className="confirm_yes" onClick={onClose}>OK</button>
                            </div>
                        </div>
                    );

                }
            })
        }
        else if (temp_cat.includes(trimmed_cat_name)) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui confirm_dialog'>
                            <div className="confirm_title">Validation</div>
                            <div className="confirm_msg">
                                <div className="img">
                                    <img
                                        alt=''
                                        style={{ width: 50, height: 50 }}
                                        src={warningIcon}
                                    />
                                </div>
                                <div className="msg">Category '{trimmed_cat_name}' already exists</div>
                            </div>

                            <div className="confirm_buttons">
                                <button className="confirm_yes" onClick={onClose}>OK</button>
                            </div>
                        </div>
                    );

                }
            })
        }
        else {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui confirm_dialog'>
                            <div className="confirm_title">Confirmation</div>
                            <div className="confirm_msg">
                                <div className="img">
                                    <img
                                        alt=''
                                        style={{ width: 50, height: 50 }}
                                        src={confirmIcon}
                                    />
                                </div>
                                <div className="msg">Save Category - {trimmed_cat_name}?</div>
                            </div>

                            <div className="confirm_buttons">
                                <button className="confirm_no" onClick={onClose}>Back</button>
                                <button className="confirm_yes"
                                    onClick={() => {
                                        const categories_ref = firebase.firestore().collection('Categories/' + props.commonState.site + '/Category');

                                        const category_temp = {
                                            active_days: [false, false, false, false, false, false, false],
                                            auto_booking_enabled: false,
                                            category: trimmed_cat_name,
                                            floor: {},
                                            isActive: false,
                                            no_of_seats: 0,
                                            last_updated_by: props.commonState.userName,
                                            last_updated_on: new Date(),
                                            week_preference: { "group" : group }
                                        }

                                        categories_ref
                                            .add({
                                                ...category_temp
                                            })
                                            .then(function (docRef) {
                                                // console.log("Category saved successfully ID: " + docRef.id);
                                            })
                                            .catch(function (error) {
                                                console.error("Error while saving category: ", error);
                                            });
                                        props.reload_data(1);
                                        onClose()
                                    }}>
                                    Confirm
                        </button>
                            </div>
                        </div>
                    );

                }
            })
        }
    }

    const SaveCatReserved = () => {
        const trimmed_cat_name = cat_name.trim();
        let temp_cat = [...props.category_names]
        if (trimmed_cat_name === '') {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui confirm_dialog'>
                            <div className="confirm_title">Validation</div>
                            <div className="confirm_msg">
                                <div className="img">
                                    <img
                                        alt=''
                                        style={{ width: 50, height: 50 }}
                                        src={confirmIcon}
                                    />
                                </div>
                                <div className="msg">Enter Category Name</div>
                            </div>

                            <div className="confirm_buttons">
                                <button className="confirm_yes" onClick={onClose}>OK</button>
                            </div>
                        </div>
                    );

                }
            })
        }
        else if (temp_cat.includes(trimmed_cat_name)) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui confirm_dialog'>
                            <div className="confirm_title">Validation</div>
                            <div className="confirm_msg">
                                <div className="img">
                                    <img
                                        alt=''
                                        style={{ width: 50, height: 50 }}
                                        src={warningIcon}
                                    />
                                </div>
                                <div className="msg">Category '{trimmed_cat_name}' already exists</div>
                            </div>

                            <div className="confirm_buttons">
                                <button className="confirm_yes" onClick={onClose}>OK</button>
                            </div>
                        </div>
                    );

                }
            })
        }
        else if (JSON.stringify(active_days) === JSON.stringify(check_array)) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui confirm_dialog'>
                            <div className="confirm_title">Validation</div>
                            <div className="confirm_msg">
                                <div className="img">
                                    <img
                                        alt=''
                                        style={{ width: 50, height: 50 }}
                                        src={warningIcon}
                                    />
                                </div>
                                <div className="msg">Select atleast one active day</div>
                            </div>

                            <div className="confirm_buttons">
                                <button className="confirm_yes" onClick={onClose}>OK</button>
                            </div>
                        </div>
                    );

                }
            })
        }
 
        else {
            
            
            let validationMsg = '';
            let conflictCategories;
            if (repeat_week === true && group === ''){
                validationMsg = 'Please select a group'
            }
            else{
                conflictCategories = getConflictCategories()
                // if (conflictCategories !== '') {
                //     validationMsg = 'The repeat week configuration is conflicting with categories: ' + conflictCategories;
                // }
            }

            if(validationMsg === ''){

                history.push('/floorplan', {
                    action: 'reserve_category',
                    commonState: props.commonState,
                    blockedSeatName: "Test",
                    active_days: active_days,
                    category_name: cat_name,
                    noOfSeats: noOfSeats,
                    auto_book: auto_book,
                    new_cat: true,
                    re_select: false,
                    week_preference: {
                        repeat_week,
                        group,
                        startingMonday,
                        startingWeek,
                        weekFrequency
                    },
                    conflictCategories
                    //  floorState: props.floorState
            })
            }

            else {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className='custom-ui confirm_dialog'>
                                <div className="confirm_title">Validation</div>
                                <div className="confirm_msg">
                                    <div className="img">
                                        <img
                                            alt=''
                                            style={{ width: 50, height: 50 }}
                                            src={warningIcon}
                                        />
                                    </div>
                                    <div className="msg"> {validationMsg} </div>
                                </div>
    
                                <div className="confirm_buttons">
                                    <button className="confirm_yes" onClick={onClose}>OK</button>
                                </div>
                            </div>
                        );
    
                    }
                })
            }
        }

    }

    const handleCatChange = (e) => {
        setcat_name(e.target.value)
    }

    const display_button = () => {

        if (active === true) {
            return (
                <div className='reserve-input'>
                    <button
                        onClick={(e) => SaveCatReserved(e)}
                        className='field-style reserve-button'>
                        Select Seats
                        </button>

                </div>
            )
        }
        else {
            return (
                <div className='reserve-input'>
                    <button
                        onClick={(e) => SaveCat(e)}
                        className='field-style reserve-button'>

                        Save
                        </button>
                </div>
            )
        }
    }

    return (

        <div >
            <div className='reserve-cat-items'>

                <div className='reserve-input'>
                    <TextField
                        id="standard-select-site"
                        select
                        value={currency}
                        label="Site"
                        style={{ width: '80%' }}
                        disabled={!props.commonState.isAppAdmin}
                        onChange={handleChange}
                    >
                        {availableSites.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>                    
                </div>
                <div className='reserve-input'>
                    <TextField
                            id="standard-select-currency"
                            select
                            value={group}
                            label="Organization"
                            style={{ width: '80%', margin: '5px' }}
                            onChange={(e) => { handleGroupChange(e.target.value) }}
                            // helperText="Select Block"
                        >
                            {getOrgList()}
                     
                        </TextField>      
                </div>
                <div className='reserve-input'>
                    <TextField
                        id="category_name"
                        label="Category Name"
                        value={cat_name}
                        onChange={(e) => { handleCatChange(e) }}
                    // helperText="Category Name"
                    >
                    </TextField>

                </div>
                <div className='reserve-input'>
                    <div style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
                        Reserve Permanent Block?
                        <Switch checked={active} onChange={(e) => setactive(!active)} />
                    </div>
                </div>

                {reserve_block()}
                {display_button()}



            </div>



        </div>
    )
}

const CalendarIcon = () => {
    return <img alt="" style={{ width: 30, height: 30 }} src={calendarIcon} />;
  };
