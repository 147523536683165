import React, { useState } from 'react';
import { firebase } from '../../../config/firebase';
import BlockedSeatBooking from './BlockedSeatBooking';
import BlockedSeatBookingDate from './BlockedSeatBookingDate';
import Loader from '../../Loader/loader';
import '../../Home/home.css';

export default function BlockedSeats(props) {
  const [reload_counter, setreload_counter] = useState(0);
  const [loading, setLoading] = useState(true);
  const [blockBookings, setBlockBookings] = useState([]);
  const [blockBookingsd, setBlockBookingsd] = useState([]);
  const [flag_wing_attribute, set_flag_wing_attribute] = useState('Day');
  const blocked_seats_ref = firebase
    .firestore()
    .collection('BlockedSeats/' + props.commonState.site + '/Blocks');

  const blocked_seats_dates_ref = firebase
    .firestore()
    .collection('BlockedSeatsDates/' + props.commonState.site + '/Blocks');  

  React.useEffect(() => {
    setLoading(true);

      blocked_seats_ref
        .orderBy('isActive', 'desc')
        .get()
        .then(
          (querySnapshot) => {
            const vBlockBookings = [];
            querySnapshot.forEach((doc) => {
              const booking = doc.data();
              booking.block_id = doc.id;
              vBlockBookings.push(booking);
            });
            setBlockBookings(vBlockBookings);
            setLoading(false);
          },
          (error) => {
            console.log(error);
          }
        );
    

      blocked_seats_dates_ref
        .orderBy('active_date_from', 'desc')
        .get()
        .then(
          (querySnapshot) => {
            const vBlockBookings = [];
            querySnapshot.forEach((doc) => {
              const booking = doc.data();
              booking.block_id = doc.id;
              vBlockBookings.push(booking);
            });
            setBlockBookingsd(vBlockBookings);
            setLoading(false);
          },
          (error) => {
            console.log(error);
          }
        );
    

  }, [reload_counter, flag_wing_attribute]);

  const onReseveBlock = (newBlock) => {
    // console.log(newBlock)
  };

  const reload_data = (e) => {
    setreload_counter(reload_counter + 1);
  };

  const onCancelBlock = (block) => {
    blocked_seats_ref
      .doc(block.block_id)
      .delete()
      .then(function () {
        // console.log("Document successfully deleted!");
      })
      .catch(function (error) {
        console.error('Error removing document: ', error);
      });
    setreload_counter(reload_counter + 1);
  };

  const onCancelBlockDate = (block) => {
    blocked_seats_dates_ref
      .doc(block.block_id)
      .delete()
      .then(function () {
        // console.log("Document successfully deleted!");
      })
      .catch(function (error) {
        console.error('Error removing document: ', error);
      });
    setreload_counter(reload_counter + 1);
  };

  const onReleaseSeats = (block) => { };

  const change_flag_wing_attribute = (e) => {
    set_flag_wing_attribute(e.target.value)
  }

  const loadHomeScreen = () => {
    if (flag_wing_attribute === 'Day') {
      return (
        <BlockedSeatBooking
          commonState={props.commonState}
          blockBookings={blockBookings}
          mode={'day-wise'}
          reload_data={(e) => reload_data(e)}
          handleReseveBlock={(newBlock) => onReseveBlock(newBlock)}
          handleCancelBlock={(Block) => onCancelBlock(Block)}
          handleReleaseSeats={(Block) => onReleaseSeats(Block)}
        />
      );
    }
    else if (flag_wing_attribute === 'Date') {
      return (
        <BlockedSeatBookingDate
          commonState={props.commonState}
          blockBookings={blockBookingsd}
          mode={'date-wise'}
          reload_data={(e) => reload_data(e)}
          handleReseveBlock={(newBlock) => onReseveBlock(newBlock)}
          handleCancelBlock={(Block) => onCancelBlockDate(Block)}
          handleReleaseSeats={(Block) => onReleaseSeats(Block)}
        />
      );
    }
  };

  return (
    <div className="main-container">
      <Loader loading={loading}></Loader>
      <div>
        <div className="wing-radio-grp">
          <span className="wing-radio">
            <input
              className="radio_options"
              type="radio"
              name=""
              value='Day'
              checked={flag_wing_attribute === 'Day'}
              onChange={(e) => change_flag_wing_attribute(e)}
            />
            Day-wise
          </span>
          <span className="wing-radio">
            <input
              className="radio_options"
              type="radio"
              name=""
              value='Date'
              checked={flag_wing_attribute === 'Date'}
              onChange={(e) => change_flag_wing_attribute(e)}
            />
            Date-wise
          </span>
        </div>

        {loadHomeScreen()}
      </div>
    </div>
  );
}
