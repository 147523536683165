import React from 'react';
import { Link } from 'react-router-dom';
import Header from './../AppHeader/header'
const NotFound = () => (
    <div>
        <div>
            <Header></Header>
        </div>
        <div className='error_container'>
            <div className='error_title'>
                <div className='error_text'>
                </div>
                <div
                    className='error_text error_border'
                >ERROR!
                </div>
                <div className='error_text'>
                </div>
            </div>
            <div className='error_content'>
                <br />
                <div className='error_code'>
                    <div className='error_msg_title'> Error Code: 404 </div>
                </div>
                <br />
                <div className=''>
                    <div className='error_msg_title'>Error Message: Not Found  </div>
                </div>
                <br /><br />
                <div className=''>
                     <div className='error_msg_try_again' >              
                            <Link to="/">Go Home</Link>
                    </div> 
                </div>
            </div>
        </div>
    </div>
);

export default NotFound;