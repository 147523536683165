import React from 'react';
import ReserveBlockedSeat from './ReserveBlockedSeat';
import Modal from "@material-ui/core/Modal";
import '../../BlockBookings/block-bookings.css'
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import { confirmAlert } from 'react-confirm-alert'; 
import EditBlkSeats from './EditBlockedSeats';
import './block-seats.css'

export default function BlockedSeatBooking(props) {
    const block_bookings_header = () => {
        // console.log(props.blockBookings.length);
        if (props.blockBookings.length === 0) {
            return (
                <div className='block-booking'>
                    No Blocked Seats
                </div>
            )
        }
        else {
            return (
                <div className="block-booking-header">
                    Blocked Seats
            </div>
            )
        }
    }

    return (
        <div className='block-bookings'>
            <ReserveBlockedSeat
                commonState={props.commonState}
                reload_data={(e) => props.reload_data(e)}
                reserveBlock={(newBlock) => props.handleReseveBlock(newBlock)} />

            <div className='block-bookings-content'>
                {block_bookings_header()}
                {props.blockBookings.map((item, index) =>
                    <BlockBooking
                        key={index}
                        blockBooking={item}
                        reload_data={(e) => props.reload_data(e)}
                        commonState={props.commonState}
                        cancelBlockClicked={(block) => props.handleCancelBlock(block)}
                        releaseSeatsClicked={(block) => props.handleReleaseSeats(block)}
                    />)}
            </div>
        </div>
    )
}


const BlockBooking = (props) => {
    const [editBlockedSeat, seteditBlockSeat] = React.useState(false);
   
    const handleEditOpen = () => {
        // console.log('open');
        seteditBlockSeat(true);
      };
      const handleEditClose = () => {
        // console.log('Edit window closed')
        seteditBlockSeat(false);
        // setreloadcounter(reload_counter + 1);
      }

    const editBlockedSeats = () =>{
        handleEditOpen();
    }

    const displayGroup = () => {

        if(props.blockBooking.group ) {
            return (
                <div style={{ fontSize: '10px' }}>
                     {props.blockBooking.group}
                </div>
            )
        } 
    }    

    const printDate = () => {
        let date1 = props.blockBooking.last_updated_on.toDate().toLocaleDateString();
        return date1;
    }
    const EditBlockSeatsModal = (
        // <div></div>
        <EditBlkSeats
                commonState={props.commonState}
                reload_data={(e) => props.reload_data(e)}
                handleEditClose={handleEditClose}
                blockBooking={props.blockBooking}
                reserveBlock={(newBlock) => props.handleReseveBlock(newBlock)} />

      );

    const deleteBlockedSeats = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui confirm_dialog'>
                        <div className="confirm_title">Confirmation</div>
                        <div className="confirm_msg">Are you sure you want to delete - {props.blockBooking.BlockName}?</div>
                        <div className="confirm_buttons">
                            <button className="confirm_no" onClick={
                                () => {
                                    onClose();
                                }
                            }>No</button>

                            <button className="confirm_yes" onClick={
                                () => {                                   
                                    props.cancelBlockClicked(props.blockBooking);
                                    onClose();
                                }
                            }>Yes</button>
                        </div>
                    </div>
                );

            }
        })
    }

    return (
        <div className='blockedseat-booking'>
            <div className='block-booking-left'>
            <div className='block-seats'>
                    <Switch checked={props.blockBooking.isActive} />
                    <div style={{ fontSize: '12px' }}>
                        Active?
                    </div>
                </div>
                <div className='block-date'>
                    <div style={{ fontSize: '16px' }}>
                        {props.blockBooking.BlockName}
                    </div>
                    {displayGroup()}
                </div>
                <div className='block-seats'>
                    <div style={{ fontSize: '24px' }}>
                        {props.blockBooking.no_of_seats}
                    </div>
                    <div style={{ fontSize: '12px' }}>
                        Seats Blocked
                    </div>
                </div>
                <div className='block-booking-right'>
                        <div className='block-seats'>
                            <Checkbox size="small" disabled={!props.blockBooking.isActive} checked={props.blockBooking.active_days[1]} />
                            <div style={{ fontSize: '12px' }}>
                                Mon
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox size="small" disabled={!props.blockBooking.isActive} checked={props.blockBooking.active_days[2]} />
                            <div style={{ fontSize: '12px' }}>
                                Tue
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox size="small" disabled={!props.blockBooking.isActive} checked={props.blockBooking.active_days[3]} />
                            <div style={{ fontSize: '12px' }}>
                                Wed
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox size="small" disabled={!props.blockBooking.isActive} checked={props.blockBooking.active_days[4]} />
                            <div style={{ fontSize: '12px' }}>
                                Thu
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox size="small" disabled={!props.blockBooking.isActive} checked={props.blockBooking.active_days[5]} />
                            <div style={{ fontSize: '12px' }}>
                                Fri
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox size="small" disabled={!props.blockBooking.isActive} checked={props.blockBooking.active_days[6]} />
                            <div style={{ fontSize: '12px' }}>
                                Sat
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox size="small" disabled={!props.blockBooking.isActive} checked={props.blockBooking.active_days[0]} />
                            <div style={{ fontSize: '12px' }}>
                                Sun
                        </div>
                        </div>
                    </div>
               
                <div className='block-seats'>
                    <div style={{ fontSize: '14px' }}>
                        {props.blockBooking.last_updated_by}
                    </div>
                    <div style={{ fontSize: '10px' }}>
                        Last Updated By
                    </div>
                </div>
                <div className='block-seats'>

                    <div style={{ fontSize: '14px' }}>
                        {printDate()}
                    </div>
                    <div style={{ fontSize: '10px' }}>
                        Last Updated On
                    </div>
                </div>
            </div>
            <div >
                <div className='block-buttons'>
                    <button 
                className='button-release-seats'
                onClick= {()=> editBlockedSeats(props.blockBooking)}>
                   Edit 
                </button>

                    <button
                        onClick={() => deleteBlockedSeats(props.blockBooking)}
                        className='button-cancel-block'>
                        Delete 
                </button>
                </div>
            </div>
            <Modal
                open={editBlockedSeat}
                onClose={handleEditClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >

                {EditBlockSeatsModal}
            </Modal>
        </div>
    )
}