import React, { useState, useEffect } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { firebase } from '../../../config/firebase';
import TextField from '@material-ui/core/TextField';
import DeclarationTable from './declarationTable';
import './admin-declaration.css';
import AddIcon from '@material-ui/icons/Add';
// import { confirmAlert } from 'react-confirm-alert';
// import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import DeleteIcon from '@material-ui/icons/Delete';
import AddNewQues from './add-new-question';
import Modal from "@material-ui/core/Modal";

export default function DeclarationMain(props) {
    const [availableSites, setAvailableSites] = useState([])
    const [currency, setCurrency] = React.useState(props.commonState.site);
    const [qmode, setQmode] = React.useState(true);
    const [cmode] = React.useState(false);
    // const [pmode, setPmode] = React.useState([false, false, false]);
    // const [questions, setQuestions] = useState([]);
    const [newQues, setnewQues] = React.useState(false);
    // const [loading, setLoading] = useState(true);
    // const [site_floor, setsite_floor] = React.useState(['', '']);
    // const [question, setQuestion] = useState('');
    // const [question_no, setQuestion_no] = useState('');
    // const [answer, setAnswer] = useState('');
    const [reload_counter, setreloadcounter] = useState(0);
    // const formRef = React.useRef();

    const handleNewClose = () => {
        setnewQues(false);
        setreloadcounter(reload_counter + 1);
    }
    const NewQuestion = () => {
        handleNewOpen();
        
    }
    const handleNewOpen = () => {
        // console.log('open');
        setnewQues(true);
    };

    const options = [
        { value: true, label: "Declaration" },
        { value: false, label: "Profile" },
    ];

    // const addfloor = () => {
    //     let temp_floor_arr = [...site_floor];
    //     temp_floor_arr.push('');
    //     setsite_floor([...temp_floor_arr]);
    //     console.log(site_floor)
    // }

    // const deletefloor = (event, index) => {
    //     let temp_que_arr = [...site_floor];
    //     temp_que_arr.splice(index, 1);
    //     setsite_floor([...temp_que_arr]);
    // }

    // const handleChangeFloor = (e, index) => {
    //     const temp_floor_1 = e.target.value;
    //     let temp_arr = [...site_floor];
    //     temp_arr[index] = temp_floor_1;
    //     setsite_floor([...temp_arr]);
    // }

    const handleChange = (event) => {
        setCurrency(event.target.value);
    };

    const handleChangeQ = (event) => {
        setQmode(event.target.value);
        // console.log(qmode);
        setreloadcounter(reload_counter + 1);
        // setnewQues(true);
    };

    // const handleQuesChange = (e) => {
    //     const temp_ques = e.target.value;
    //     // setQuestion(temp_ques);
    // }

    // const handleQuesNoChange = (e) => {
    //     const temp_ques_no = e.target.value;
    //     // setQuestion_no(temp_ques_no);
    // }

    // const handleAnsChange = (e) => {
    //     const temp_ans = e.target.value;
    //     // setAnswer(temp_ans);
    // }

    const sites_ref = firebase.firestore().collection('Sites');

    useEffect(() => {
        // setLoading(true);
        const pdeclaration_ref = firebase
            .firestore()
            .collection(
                "ProfileQuestions/" + currency + "/Questions"
            );
        const declaration_ref = firebase
            .firestore()
            .collection(
                "DeclarationQuestions/" + currency + "/Questions"
            );

        sites_ref
            .get()
            .then(
                querySnapshot => {
                    const tempSites = []
                    querySnapshot.forEach(doc => {
                        const site = doc.id;
                        tempSites.push({ value: site, label: site });
                    });
                    setAvailableSites([...tempSites])
                })

        if (qmode) {
            declaration_ref
                .orderBy("Que_No", "asc")
                .get()
                .then(
                    (querySnapshot) => {
                        const Questions = [];
                        if (querySnapshot.docs.length !== 0) {
                            querySnapshot.forEach((doc) => {
                                const question = doc.data();
                                question.id = doc.id;
                                Questions.push(question);
                            });
                            // setQuestions(Questions);
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                )
                .catch(function (error) {
                    console.log("Error getting Declaration questions: ", error);
                });
        } else {
            pdeclaration_ref
                .orderBy("Que_No", "asc")
                .get()
                .then(
                    (querySnapshot) => {
                        const Questions = [];
                        if (querySnapshot.docs.length !== 0) {
                            querySnapshot.forEach((doc) => {
                                const question = doc.data();
                                question.id = doc.id;
                                Questions.push(question);
                            });
                            // setQuestions(Questions);
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                )
                .catch(function (error) {
                    console.log("Error getting Declaration questions: ", error);
                });
        }
        // setLoading(false);
    }, [qmode, cmode]);

    const loadtable = () => {
        return (
            <DeclarationTable key={currency, qmode, reload_counter} site_name={currency} commonState={props.commonState} qmode={qmode} />
        )
    }
 


    const AddQuesModal = (
        <AddNewQues  handleNewClose={handleNewClose} userName={props.commonState.userName} site={currency} mode={qmode ? 'Declaration' : 'Profile'} />
    );

    // const addques = (e, item, index, id) => {
    //     <AddNewQues userName={props.commonState.userName} site={props.site_name} />

    //     // confirmAlert({
    //     //     customUI: ({ onClose }) => {
    //     //         return (
    //     //             <div className='custom-ui confirm_dialog'>
    //     //                 <div className="confirm_title">New Question</div>
    //     //                 <form ref={formRef} style={{ width: '100%' }}>
    //     //                 <div className="question-container">
    //     //                         <TextField
    //     //                             value={question_no}
    //     //                             required
    //     //                             onChange={(e) => handleQuesNoChange(e)}
    //     //                             className="question"
    //     //                             style={{ margin: '5px' }}
    //     //                             label="Question Number" />
    //     //                     </div>
    //     //                     <div className="question-container">
    //     //                         <TextField
    //     //                             value={question}
    //     //                             required
    //     //                             onChange={(e) => handleQuesChange(e)}
    //     //                             className="question"
    //     //                             style={{ margin: '5px' }}
    //     //                             label="Question" />
    //     //                     </div>
    //     //                     {site_floor.map((floor, index) => {
    //     //                         return (
    //     //                             <div key={index} className="question-container">
    //     //                                 <TextField
    //     //                                     value={site_floor[index]}
    //     //                                     required
    //     //                                     onChange={(e) => handleChangeFloor(e, index)}
    //     //                                     className="add-option"
    //     //                                     style={{ margin: '5px' }}
    //     //                                     label="Option" />
    //     //                                 {index === 0 ?
    //     //                                     (
    //     //                                         <div onClick={(e) => { addfloor(e, index) }}>
    //     //                                             <Tooltip title="Add option">
    //     //                                                 <IconButton aria-label="Add floor">
    //     //                                                     <AddIcon />
    //     //                                                 </IconButton>
    //     //                                             </Tooltip>
    //     //                                         </div>
    //     //                                     ) : (
    //     //                                         <div onClick={(e) => { deletefloor(e, index) }}>
    //     //                                             <Tooltip title="Delete option">
    //     //                                                 <IconButton aria-label="delete floor">
    //     //                                                     <DeleteIcon />
    //     //                                                 </IconButton>
    //     //                                             </Tooltip>
    //     //                                         </div>
    //     //                                     )}
    //     //                             </div>
    //     //                         )
    //     //                     })}
    //     //                     <div className="question-container">
    //     //                         <TextField
    //     //                             value={answer}
    //     //                             required
    //     //                             onChange={(e) => handleAnsChange(e)}
    //     //                             className="answer"
    //     //                             style={{ margin: '5px' }}
    //     //                             label="Safe Answer" />
    //     //                     </div>
    //     //                     <br />
    //     //                     <div className="confirm_buttons">
    //     //                         <button
    //     //                             // onClick={saveSiteData}
    //     //                             // className='dec-field-style dec-save-button'
    //     //                             className="confirm_yes"
    //     //                         >
    //     //                             Add
    //     //                         </button>
    //     //                     </div>
    //     //                 </form>
    //     //             </div>
    //     //         );

    //     //     }
    //     // })
    // }


    return (
        <div>
            <div
                className="new_user_main"
                style={{ margin: "auto", marginTop: "1%", width: "80%", fontFamily: "ColgateReady" }}
            >
                <div style={{ width: "100%" }}>
                    <div className="user-container root" style={{ fontWeight: "bold", fontFamily: 'ColgateReady' }}>
                        <div style={{ width: '100%' }}>
                            <TextField
                                id="standard-select-site"
                                select
                                value={currency}
                                label="Site"
                                style={{ width: '25%', justifyContent: 'left', margin: '1%', fontWeight: "bold", fontFamily: 'ColgateReady' }}
                                disabled={!props.commonState.isAppAdmin}
                                onChange={handleChange}
                            >
                                {availableSites.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                id="standard-select-site"
                                select
                                value={qmode}
                                label="Mode"
                                style={{ width: '50%', justifyContent: 'left', margin: '1%', fontWeight: "bold", fontFamily: 'ColgateReady' }}
                                onChange={handleChangeQ}
                            >
                                {options.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <button
                                // onClick={(e) => {
                                //     // update_confirmation(e);
                                //     addques(e, 'item', 0, 'item.id')
                                    
                                // }}
                                onClick={NewQuestion}
                                className="dec-field-style_new dec-save-button_new"
                            >
                                <AddIcon />
                            </button>
                        </div>
                    </div>
                    <div>
                        {loadtable()}
                    </div>
                    <Modal
                        open={newQues}
                        onClose={handleNewClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >

                        {AddQuesModal}
                    </Modal>
                </div>
            </div>

        </div>
    );
}
