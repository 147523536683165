import React from 'react';
import DotLoader from "react-spinners/DotLoader";

import './loader.css'

export default function Loader (props) {

const loadLoader = () => {
    if(props.loading){
        return(
            <div className='loader'>
                <DotLoader
                css={{display: 'block', margin: 'auto', }}
                size={50}
                color={"#d01837"}
                loading={true}
                />
            </div>  
        )
    }
}


return(
    <div>
        {loadLoader()}
    </div>
)
}