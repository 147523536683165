import { useOktaAuth } from '@okta/okta-react';
import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { firebase } from "../../config/firebase";

import Header from "../AppHeader/header";
import BlockBookings from "../BlockBookings/block-bookings";
import SeatBookings from "../SeatBookings/seat-bookings";
import Loader from "../Loader/loader";
import sendEmail from "../SendEmail/send-email"

import "./home.css";
  
export default function Home() {
  let currentDate = new Date();
  // const location = useLocation();
  const history = useHistory();

  const [commonState, setCommonState] = React.useState({
    currentDate: new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    ),
    //    blockAdminEmail: 'suhas_patil@colpal.com'
  });
  
  const [blockAdminPage, setBlockAdminPage] = useState();
  const [blockBookings, setBlockBookings] = useState([]);
  const [seatBookings, setSeatBookings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [direct_booking_flag_home, set_direct_booking_flag_home] = useState();
  // const [bookingAllowed, setBookingAllowed] = useState(true);
  const [healthChk, setHealthChk] = useState();
  const [profile_health_new, setprofile_health_new] = useState(false);
  const [profile_health_negative, setprofile_health_negative] = useState(false);
  const [WS_status, set_WS_status] = useState("");

  // const [floorState, setFloorState] = useState();

  // let c_seatBookings = [];
  // let user;

  const curr_date_timestamp = firebase.firestore.Timestamp.fromDate(
    commonState.currentDate
  );

  const user_ref = firebase.firestore().collection("Users");
  // const block_booking_ref = firebase.firestore().collection('BlockBookings');
  // const seat_booking_ref = firebase.firestore().collection('SeatBookings');

  // let block_booking_ref;
  // let seat_booking_ref;
  // let sites_ref;
  // let categories_ref;

  // let site_details = {};
  // let category_details = {};

  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  let user = {};

  useEffect(() => {
    setLoading(true);
    user = {};
    let block_booking_ref;
    let seat_booking_ref;
    let sites_ref;
    let categories_ref;

    let site_details = {};
    let category_details = {};
    let c_seatBookings = [];

    // const loggedInUser = sessionStorage.getItem("user");
    // if (loggedInUser) {
    //   user = JSON.parse(loggedInUser);
    // }

    // if (!user) {
    if (!authState || !authState.isAuthenticated) {
        setUserInfo(null);
        login()
    } 
    else if ( authState.isAuthenticated && !user.userEmail) {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
        user.userName = info.name;
        user.userEmail = info.email;
        user.userImage = '';


      //  *******************************************************GET USER, SITE AND CATEGORY DETAILS ***********************************************************************
      user_ref
        .where("userEmail", "==", user.userEmail)
        .get()
        .then((querySnapshot) => {
          let usr = {};
          if (querySnapshot.docs.length !== 0) {
            querySnapshot.forEach((doc) => {
              // console.log("User Found...");
              // console.log(doc.data());
              usr = { ...doc.data() };

              // setLoading(false)
              // history.push('/', user)
            });
          } else {
            setLoading(false);
            // console.log("User not registered..");
            history.push("/signin")
            // history.push("/register", user);
            return false;
          }
          // console.log("doc")

          sites_ref = firebase.firestore().collection("Sites");
          sites_ref
            .where("Site", "==", usr.site)
            .get()
            .then(
              (querySnapshot) => {
                if (querySnapshot.docs.length !== 0) {
                  let site = {};
                  querySnapshot.forEach((doc) => {
                    site = { ...doc.data() };
                  });
                  site_details = { ...site };

                  if(!site_details.is_site_up && !usr.isSiteAdmin
                    ){
                    history.push("/site-down", 
                   {
                      commonState: usr,
                      error_text : site_details.site_down_text                
                    })
                    return false;
                  }

                  //****This patch of code is to sort the work stations in asc order */
                  const temp_floorState = { ...site_details.floors };
                  const floor_keys = Object.keys(site_details.floors);
                  const new_floor_state = {};
                  floor_keys.forEach((floor) => {
                    const temp_ws_keys = Object.keys(temp_floorState[floor]);
                    temp_ws_keys.sort();

                    const new_temp_ws = {};
                    temp_ws_keys.forEach((ws) => {
                      new_temp_ws[ws] = temp_floorState[floor][ws];
                    });

                    new_floor_state[floor] = new_temp_ws;
                  });
                  //****This patch of code is to sort the work stations in asc order */

                  //Cubicles Data
                    if(site_details.cubicles_data.floors){
                      const temp_cubicle_floorState = { ...site_details.cubicles_data.floors };
                      const cubicle_floor_keys = Object.keys(site_details.cubicles_data.floors)
                      cubicle_floor_keys.forEach((floor) => {
                        const temp_ws_keys = Object.keys(temp_cubicle_floorState[floor]);
                        temp_ws_keys.sort();
    
                        const new_temp_ws = {...new_floor_state[floor]};
                        temp_ws_keys.forEach((ws) => {
                          new_temp_ws[ws] = temp_cubicle_floorState[floor][ws];
                        });  
                      new_floor_state[floor] = new_temp_ws;
                    });
                  }
                  // console.log(new_floor_state)


                  // Get booking frequency of user


                  categories_ref = firebase
                    .firestore()
                    .collection("Categories/" + usr.site + "/Category");
                  categories_ref
                    .where("category", "==", usr.category)
                    .get()
                    .then(
                      (querySnapshot) => {
                        if (querySnapshot.docs.length !== 0) {
                          let cat = {};
                          querySnapshot.forEach((doc) => {
                            cat = { ...doc.data() };
                          });
                          category_details = { ...cat };

                          // Open Block Admin page when:
                          //  1. User is BlockAdmin AND
                          //  2. At Site Level - block_booking_by_admin is True (Block booking by Cat Lead is enabled) AND
                          //  3. At Category Level - Category is in InActive State (Category has decided not to have permanent block)
                          if (
                            usr.isBlockAdmin &&
                            !site_details.direct_booking_enabled &&
                            !category_details.isActive
                          ) {
                            setBlockAdminPage(true);
                          }

                          let booking_flag;
                          if (site_details.direct_booking_enabled) {
                            booking_flag = site_details.auto_booking_enabled
                              ? "Site Level-Auto"
                              : "Site Level-Manual";
                          } else if (category_details.isActive) {
                            if (
                              category_details.active_days[
                                commonState.currentDate.getDay()
                              ]
                            ) {
                              booking_flag = category_details.auto_booking_enabled
                                ? "Reserved Cat-Auto"
                                : "Reserved Cat-Manual";
                            } else {
                              booking_flag = category_details.auto_booking_enabled
                              ? "Reserved Cat-Auto"
                              : "Reserved Cat-Manual";
                              // booking_flag = "Reserved Cat-No Booking Day";
                            }
                          } else {
                            booking_flag = "Normal Booking";
                          }

                          set_direct_booking_flag_home(booking_flag);

                          if (
                            booking_flag === "Site Level-Auto" ||
                            booking_flag === "Reserved Cat-Auto"
                          ) {
                            let ws_flag = false;
                            let reserved_for;
                            categories_ref
                              .where("isActive", "==", true)
                              .get()
                              .then((querySnapshot) => {
                                querySnapshot.forEach((doc) => {
                                  const block = doc.data();
                                  if (block) {
                                    if (
                                      booking_flag === "Reserved Cat-Auto" &&
                                      usr.category === block.category
                                    ) {
                                    } else {
                                      Object.keys(block.floor).forEach(
                                        (flr) => {
                                          if (
                                            block.floor[flr].includes(
                                              usr.my_ws.ws
                                            )
                                          ) {
                                            ws_flag = true;
                                            reserved_for = block.category;
                                          }
                                        }
                                      );
                                    }
                                  }
                                });

                                const blocked_seats_ref = firebase
                                  .firestore()
                                  .collection(
                                    "BlockedSeats/" + usr.site + "/Blocks"
                                  );
                                blocked_seats_ref
                                  .where("isActive", "==", true)
                                  .get()
                                  .then((querySnapshot) => {
                                    querySnapshot.forEach((doc) => {
                                      const block = doc.data();
                                      if (block) {
                                        Object.keys(block.floor).forEach(
                                          (flr) => {
                                            if (
                                              block.floor[flr].includes(
                                                usr.my_ws.ws
                                              )
                                            ) {
                                              ws_flag = true;
                                              reserved_for = block.BlockName;
                                            }
                                          }
                                        );
                                      }
                                    });

                                    if (ws_flag) {
                                      set_WS_status({
                                        status: "Update WS",
                                        reserved_for: reserved_for,
                                      });
                                    }
                                  });
                              });
                          }

                          const curr_date = new Date(commonState.currentDate);
                          const next_date = new Date(commonState.currentDate);
                          // next_date.setDate(curr_date.getDate() + 1)
                          if (curr_date.getDay() === 5) { //Friday
                            next_date.setDate(curr_date.getDate() + 3) //Monday
                          }
                          else if (curr_date.getDay() === 6) {   //Saturday
                            next_date.setDate(curr_date.getDate() + 2) //Monday
                          }
                          else {
                            next_date.setDate(curr_date.getDate() + 1)
                          }                        

                          const blocked_seats_ref = firebase
                                  .firestore()
                                  .collection(
                                    "BlockedSeats/" + usr.site + "/Blocks"
                                  );
                          
                          let include_dates = []
                          
                        
                          // Set up the special reservations - Current Day and Next Day
                          const cal_min_date = new Date(site_details.booking_calendar_from.toDate());
                          const cal_max_date = new Date(site_details.booking_calendar_to.toDate());
                          let curr_next_day_flag = false;
                          if ((commonState.currentDate >= cal_min_date || next_date >= cal_min_date) &&
                            (commonState.currentDate <= cal_max_date || next_date <= cal_max_date)) {
                            blocked_seats_ref
                              .where("isActive", "==", true)
                              .where("group", "==", usr.organization)
                              .where("BlockName", "==", "Special Reservation Block")
                              // .where("active_days", "arrayContains", curr_date.getDay())
                              .get()
                              .then((querySnapshot) => {
                                if (querySnapshot.docs.length > 0) {
                                  querySnapshot.forEach((doc) => {
                                    const block = doc.data();
                                    if (block.active_days[curr_date.getDay()] && curr_date >= cal_min_date && curr_date <= cal_max_date) {
                                      let blk_day = {
                                        start: curr_date,
                                        end: curr_date
                                      }
                                      include_dates.push(blk_day)
                                      curr_next_day_flag = true;
                                    }
                                    if (block.active_days[next_date.getDay()] && next_date >= cal_min_date && next_date <= cal_max_date) {
                                      let blk_day = {
                                        start: next_date,
                                        end: next_date
                                      }
                                      include_dates.push(blk_day)
                                      curr_next_day_flag = true;
                                    }
                                    // if (block.active_days[curr_date.getDay()] && block.active_days[next_date.getDay()]) {
                                    //   let blk_day = {
                                    //     start: curr_date,
                                    //     end: next_date
                                    //   }
                                    //   include_dates.push(blk_day)
                                    // }
                                    // else if (block.active_days[curr_date.getDay()]) {
                                    //   let blk_day = {
                                    //     start: curr_date,
                                    //     end: curr_date
                                    //   }
                                    //   include_dates.push(blk_day)
                                    // }
                                    // else if (block.active_days[next_date.getDay()]) {
                                    //   let blk_day = {
                                    //     start: next_date,
                                    //     end: next_date
                                    //   }
                                    //   include_dates.push(blk_day)
                                    // }
                                  })
                                }
                              })
                          }

                          //Fetch the list of excluded/open days
                          const blocked_seats_dates_ref = firebase
                          .firestore()
                          .collection(
                            "BlockedSeatsDates/" + usr.site + "/Blocks"
                          );

                          let include_dates_only = []
                          let exclude_dates = []
                          let min_date = new Date()
                          blocked_seats_dates_ref
                          .where("isActive", "==", true)
                          .where("group", "==", usr.organization)
                          .where("active_date_to",">=", commonState.currentDate)
                          .get()
                          .then((querySnapshot) => {
                            querySnapshot.forEach((doc) => {
                              const block = doc.data();
                              if (block) {
                                if (block.BlockName === 'Block Seats') {
                                  let start_date =  new Date(block.active_date_from.toDate());
                                  let end_date =  new Date(block.active_date_to.toDate());
                                  let blk_day = {
                                    start : start_date,
                                    end : end_date
                                  }
                                  exclude_dates.push(blk_day)
                                  if (commonState.currentDate >= start_date && commonState.currentDate <= end_date ){
                                    min_date.setDate(end_date.getDate() + 1)
                                  }
                                  const next_to_next_date = next_date;
                                  if (start_date.valueOf() === next_date.valueOf()){
                                    //Next day is a holiday, so enable the next to next day if it's active and within the max day
                                    if (curr_next_day_flag){ //Only when special reservations is enabled i.e current and next day
                                      if (next_date.getDay() === 5) { //Friday
                                        next_to_next_date.setDate(next_date.getDate() + 3) //Monday
                                      }
                                      else if (next_date.getDay() === 6) {   //Saturday
                                        next_to_next_date.setDate(next_date.getDate() + 2) //Monday
                                      }
                                      else {
                                        next_to_next_date.setDate(next_date.getDate() + 1)
                                      }   
                                      if ( next_to_next_date >= cal_min_date && next_to_next_date <= cal_max_date ){
                                        let blk_day = {
                                          start: next_to_next_date,
                                          end: next_to_next_date
                                        }
                                        include_dates.push(blk_day)
                                      }
                                    }                               
                                  }
                                } else if (block.BlockName === 'Special Reservation Seats'){
                                  let blk_day = {
                                    start : new Date(block.active_date_from.toDate()),
                                    end : new Date(block.active_date_to.toDate())
                                  }
                                  
                                  include_dates.push(blk_day)
                                  include_dates_only.push(blk_day)
                                  }
                              }
                            });
                          });

                          const temp_min_date = new Date(site_details.booking_calendar_from.toDate());
                          min_date = min_date > temp_min_date ? min_date : temp_min_date

                          // Add user's Site and Category Details to CommonState
                          setCommonState({
                            ...commonState,
                            // ...user,
                            ...usr,
                            direct_booking_flag: booking_flag,
                            floorState: new_floor_state,
                            site_data: { ...site_details },
                            cat_data: { ...category_details },
                            calendar_data : {
                              exclude_dates : exclude_dates,
                              include_dates : include_dates,
                              include_dates_only : include_dates_only,
                              min_date : min_date
                            }
                          });

                          setLoading(false);
                        } //  category_ref if(querySnapshot.docs.length !== 0){
                        else {
                          const errr = {
                            code: "Category Error",
                            message:
                              "Error fetching user's Category -> " +
                              usr.category,
                          };
                          console.log(
                            "Error fetching user's Category -> " + usr.category
                          );
                          history.push("/error", errr);
                        }

                        // console.log("commonState", commonState);

                        //  *******************************************************GET BLOCK BOOKINGS ***********************************************************************

                        block_booking_ref = firebase
                          .firestore()
                          .collection(
                            "BlockBookings/" + usr.site + "/BlockBookings"
                          );
                        seat_booking_ref = firebase
                          .firestore()
                          .collection(
                            "SeatBookings/" + usr.site + "/SeatBookings"
                          );
                        const block_booking_email = usr.isBlockAdmin
                          ? usr.userEmail
                          : usr.blockAdminEmail;
                        if (direct_booking_flag_home) {
                          if (direct_booking_flag_home === "Normal Booking") {
                            block_booking_ref
                              .where(
                                "blocked_by_email",
                                "==",
                                block_booking_email
                              )
                              .where("blocked_date", ">=", curr_date_timestamp)
                              .orderBy("blocked_date", "asc")
                              // .get()
                              .onSnapshot(
                                // .then(
                                (querySnapshot) => {
                                  const vBlockBookings = [];
                                  querySnapshot.forEach((doc) => {
                                    const booking = doc.data();
                                    booking.block_id = doc.id;
                                    booking.blocked_date = new Date(
                                      booking.blocked_date.toDate()
                                    );
                                    booking.blocked_on = new Date(
                                      booking.blocked_on.toDate()
                                    );
                                    booking.booking_type = "New Booking";
                                    vBlockBookings.push(booking);
                                  });
                                  // console.log("vBlockBookings");
                                  // console.log(vBlockBookings);
                                  c_seatBookings = [...vBlockBookings];
                                  setBlockBookings(vBlockBookings);
                                  // console.log(blockBookings);
                                },
                                (error) => {
                                  console.log(error);
                                }
                              );
                          } // if( direct_booking_flag_home === "Normal Booking") {

                          //  *******************************************************GET SEAT BOOKINGS ***********************************************************************
                          seat_booking_ref
                            .where("booked_by_email", "==", usr.userEmail)
                            .where("blocked_date", ">=", curr_date_timestamp)
                            .orderBy("blocked_date", "asc")
                            .onSnapshot(
                              //    .then(

                              (querySnapshot) => {
                                const confirmedBookings = [];
                                let blockBookingsFiltered = [...c_seatBookings];
                                //  let blockBookingsFiltered = [...blockBookings]

                                // console.log("blockBookingsFiltered111");
                                // console.log(c_seatBookings);
                                querySnapshot.forEach((doc) => {
                                  const confirmedBooking = doc.data();
                                  confirmedBooking.booking_id = doc.id;
                                  confirmedBooking.blocked_date = new Date(
                                    confirmedBooking.blocked_date.toDate()
                                  );
                                  confirmedBooking.booked_on = new Date(
                                    confirmedBooking.booked_on.toDate()
                                  );
                                  confirmedBooking.booking_type = "Confirmed";
                                  //    blockBookingsFiltered = blockBookingsFiltered.filter((booking)=> booking.block_id !== confirmedBooking.block_id || booking.blocked_date.getTime() !== confirmedBooking.blocked_date.getTime())
                                  blockBookingsFiltered = blockBookingsFiltered.filter(
                                    (booking) =>
                                      booking.blocked_date.getTime() !==
                                      confirmedBooking.blocked_date.getTime()
                                  );

                                  confirmedBookings.push(confirmedBooking);
                                });
                                // console.log("blockBookingsFiltered");
                                // console.log(blockBookingsFiltered);
                                const newseatBookings = [
                                  ...confirmedBookings,
                                  ...blockBookingsFiltered,
                                ];
                                setSeatBookings([...newseatBookings]);
                                setLoading(false);
                              },
                              (error) => {
                                console.log(error);
                              }
                            ); // seat_booking_ref onSnapshot
                        } // if( direct_booking_flag_home) {
                      } // category_ref querySnapshot => {
                    ) // category_ref  .then(
                    .catch(function (error) {
                      console.log(
                        "Error fetching user's category -> " +
                          usr.category +
                          " : ",
                        error
                      );
                    });

                    // seat_booking_ref = firebase
                    // .firestore()
                    // .collection(
                    //   "SeatBookings/" + usr.site + "/SeatBookings"
                    // );


                    // let booking_allowed_date = new Date(commonState.currentDate)
                    // booking_allowed_date.setDate(booking_allowed_date.getDate() - parseInt(site_details.advance_booking_period_individual))

                    // let booking_allowed_date_timestamp = firebase.firestore.Timestamp.fromDate(
                    //   booking_allowed_date
                    // );

                    // seat_booking_ref
                    // .where("booked_by_email", "==", user.userEmail)
                    // .where("blocked_date", ">=", booking_allowed_date_timestamp)
                    // .onSnapshot(
                    //   //    .then(

                    //   (querySnapshot) => {

                    //     // console.log("querySnapshot.docs.length",querySnapshot.docs.length)

                    //     if(querySnapshot.docs.length > 1) {
                    //       setBookingAllowed(false);
                    //     }
                    //   })

                  //  ******************************************************* UPDATE DECLARATION STATUS ***********************************************************************

                  let declaration_last_updated;
                  let declaration_last_status;
                  // user_ref
                  //     .where("userEmail", "==", user.userEmail)
                  //     .get()
                  //     .then(
                  //         querySnapshot => {
                  //             let usr = {}
                  //             querySnapshot.forEach(doc => {
                  //                 // console.log("doc")
                  //                 console.log(doc.data())
                  //                 usr = {...doc.data()}
                  //             })

                  declaration_last_updated = usr.declaration_last_updated;
                  declaration_last_status = usr.declaration_last_status;
                  // myws = usr.my_ws

                  if (site_details.need_declaration) {
                    if (usr) {
                      if (declaration_last_updated) {
                        if (
                          commonState.currentDate.getTime() ===
                            declaration_last_updated.seconds * 1000 &&
                          declaration_last_status === "safe"
                        ) {
                          // healthChk = "Updated"
                          setHealthChk("Updated");
                        } else {
                          if (
                            commonState.currentDate.getTime() !==
                            declaration_last_updated.seconds * 1000
                          ) {
                            setHealthChk("Not Updated");
                          } else if (declaration_last_status !== "safe") {
                            setHealthChk("not safe");
                            // healthChk = "Not Safe"
                          } else {
                            setHealthChk("Update Health Declaration");
                            // healthChk = "Not Updated"
                          }
                        }
                      } else {
                        setHealthChk("Not Updated");
                      }
                    } else {
                      setHealthChk("Couldn't Update Status");
                    }
                  } else {
                    setHealthChk("Updated");
                  }

                  //Profile - Health Declaration
                  if (site_details.need_declaration_profile) {
                    if (usr) {
                      const profileq_ref = firebase
                      .firestore()
                      .collection("ProfileQuestions/" + usr.site + "/Questions");

                      let check_new = false;
                      profileq_ref
                      .orderBy("Que_No", "asc")
                      .get()
                      .then(
                        (querySnapshot) => {
                          querySnapshot.forEach((doc) => {
                            var temp_json = [...usr.Health_Declaration ];
                            var temp_q = temp_json.find(q => q.id === doc.id);
                            if (temp_q){
                              if(temp_q.SelectedAns === ""){
                                setprofile_health_new(true)
                                check_new = true;
                              }
                              // else if (temp_q.SelectedAns !== temp_q.SafeAns){
                              //   setprofile_health_negative(true)
                              // }
                            } else {
                              setprofile_health_new(true)
                                check_new = true;
                            }      
                          }); 

                          if (!check_new){
                            querySnapshot.forEach((doc) => {
                              var temp_json = [...usr.Health_Declaration ];
                              var temp_q = temp_json.find(q => q.id === doc.id);
                              if (temp_q){
                                if (temp_q.SelectedAns !== temp_q.SafeAns){
                                  setprofile_health_negative(true)
                                }
                              }         
                            }); 
                          }

              
                        },
                        (error) => {
                          // console.log(error);
                        }
                      )
                      .catch(function (error) {
                       
                      });

                    } else {
                      // setHealthChk("Couldn't Update Status");
                    }
                  } else {
                    // setHealthChk("Updated");
                  }



                } //  sites_ref if(querySnapshot.docs.length !== 0){
                else {
                  const errr = {
                    code: "Site Error",
                    message: "Error fetching user's site -> " + usr.site,
                  };
                  console.log("Error fetching user's site -> " + usr.site);
                  history.push("/error", errr);
                }
              } // sites_ref  querySnapshot => {
            ) // sites_ref  .then(
            .catch(function (error) {
              console.log(
                "Error fetching user's site -> " + usr.site + " : ",
                error
              );
              history.push("/error", error)
            });
        }) //.use_ref
        .catch(function (error) {
          console.log(
            "Error fetching user profile of " + user.userEmail + ": ",
            error
          );
        });

      // if(location.state && (location.state.action === 'Reserved Cat-Auto' || location.state.action === 'Site Level-Auto')){
      //     setLoading(true)
      //     confirmAutoBooking()
      // }
    });
    }

  }, [authState, oktaAuth, direct_booking_flag_home, user.userEmail]); // Update if authState changes

  const login = async () => {
    await oktaAuth.signInWithRedirect();

  };

  const getMonth = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec",
  };

  const get_day = {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
  };

  const getWeekDay = (blocked_date) => {
    // const blocked_date = block.blocked_date;
    return get_day[blocked_date.getDay()];
  };

  const printDate = (blocked_date) => {
    // const blocked_date = block.blocked_date;
    let blocked_date_date;

    if (blocked_date.getDate() < 10) {
      blocked_date_date = "0" + blocked_date.getDate().toString();
    } else {
      blocked_date_date = blocked_date.getDate().toString();
    }

    const blocked_date_month = getMonth[blocked_date.getMonth()];

    return getWeekDay(blocked_date) + ", " + blocked_date_date + "-" + blocked_date_month;
  };


  const onCancelBlock = (block) => {

    const block_booking_ref = firebase
      .firestore()
      .collection("BlockBookings/" + commonState.site + "/BlockBookings");
    const seat_booking_ref = firebase
      .firestore()
      .collection("SeatBookings/" + commonState.site + "/SeatBookings");

      // let teamMembers = '';

    block_booking_ref
      .doc(block.block_id)
      .delete()
      .then(function () {
        seat_booking_ref
          .where("block_id", "==", block.block_id)
          .get()
          .then(
            // Delete all reservations from the block
            
            (querySnapshot) => {
              //    doc.ref.delete();
              //    var batch = firebase.batch();
              let sent_to_list;
              querySnapshot.forEach((doc) => {
                const blk = doc.data();

                doc.ref.delete();

               if (sent_to_list === '')
               sent_to_list = blk.blocked_by_email;
               else {
                sent_to_list = sent_to_list + ',' + blk.blocked_by_email;
               }
              });

              const bookingDetails = {
                date : printDate(block.blocked_date),
                floor: block.floor
              }

              sendEmail("Booking Cancelled by BlockAdmin", sent_to_list, block.blocked_by_email, bookingDetails, commonState)
            },
            (error) => {
              console.log(error);
            }
          )
      })
      .then(function () {
        const blockDetails = {
          date : printDate(block.blocked_date),
          floor: block.floor
        }
        sendEmail("Block Cancelled", block.blocked_by_email, '', blockDetails, commonState)
      })
      .catch(function (error) {
        console.error("Error Cancelling Block: ", error);
      });
  };

  const onReleaseSeats = (block) => {
    const block_booking_ref = firebase
      .firestore()
      .collection("BlockBookings/" + commonState.site + "/BlockBookings");

    // While releasing the seats from the Block, along with updating the total count; also
    // updating the array with consists the list of seats within in a block
    const seat_booking_ref = firebase
      .firestore()
      .collection("SeatBookings/" + commonState.site + "/SeatBookings");

      //Get the list of workstations in the block 
      block_booking_ref
      .doc(block.block_id)
      .get()
      .then(doc => {
          if (!doc.exists) {
              // console.log('No doc found');
          } else {
            const block_seats_ref = doc.data();

          var temp_new_block = {}
          temp_new_block[block_seats_ref.floor] = []

          seat_booking_ref
            .where("block_id", "==", block.block_id)
            .where("floor", "==", block_seats_ref.floor)
            .get()
            .then(function (querySnapshot) {
              if (querySnapshot.docs.length > 0) {
                querySnapshot.forEach((doc) => {
                  const seat_conf = doc.data();
                  temp_new_block[block_seats_ref.floor].push(seat_conf.booked_ws)
                })
                block_booking_ref.doc(block.block_id).update({
                  reserved_block: temp_new_block,
                  seats_blocked: block.seats_confirmed,
                });
              }
            });
        }
      })

    // block_booking_ref.doc(block.block_id).update({
    //   reserved_block: block.reserved_block,
    //   seats_blocked: block.seats_confirmed,
    // });
  };

  const onCancelBooking = (booking) => {
    setLoading(true);

    // block_booking_ref = firebase.firestore().collection('BlockBookings/' + commonState.site + '/BlockBookings');
   const seat_booking_ref = firebase
      .firestore()
      .collection("SeatBookings/" + commonState.site + "/SeatBookings");

    seat_booking_ref
      .doc(booking.booking_id)
      .delete()
      .then(function () {
        if (booking.block_id !== "special_booking" &&
           booking.block_id !== "Reserved Cat-Manual" &&
           booking.block_id !== "Reserved Cat-Auto" &&
           booking.block_id !== "Site Level-Manual" &&
           booking.block_id !== "Site Level-Auto" ) {
          const block_booking_ref_doc = firebase
            .firestore()
            .collection("BlockBookings/" + commonState.site + "/BlockBookings")
            .doc(booking.block_id);

          block_booking_ref_doc
          .update({
            seats_confirmed: firebase.firestore.FieldValue.increment(-1),
          })
          let blockIndex = blockBookings.findIndex((obj => obj.block_id === booking.block_id));
          blockBookings[blockIndex].seats_confirmed = blockBookings[blockIndex].seats_confirmed - 1

          // console.log("Booking Cancelled Successfully!")
        }

        const blockDetails = {
          date : printDate(booking.blocked_date),
          ws: booking.booked_ws,
          floor: booking.floor
        }
        sendEmail("Booking Cancelled", booking.booked_by_email, '', blockDetails, commonState)

        setLoading(false);
      })
      .catch(function (error) {
        console.error("Error Cancelling Booking: ", error);
      });
  };

  const loadHomeScreen = () => {
    if (
      commonState.userEmail &&
      commonState.site_data &&
      commonState.cat_data &&
      commonState.floorState &&
      healthChk
    ) {
      if (blockAdminPage) {
        return (
          <BlockBookings
            commonState={commonState}
            blockBookings={blockBookings}
            // floorState = {floorState}
            // handleReseveBlock={(newBlock)=>onReseveBlock(newBlock)}
            direct_booking_flag={direct_booking_flag_home}
            handleCancelBlock={(Block) => onCancelBlock(Block)}
            handleReleaseSeats={(Block) => onReleaseSeats(Block)}
            health={healthChk}
          />
        );
      } else {
        return (
          <SeatBookings
            health={healthChk}
            profile_health_new={profile_health_new}
            profile_health_negative={profile_health_negative}
            commonState={commonState}
            seatBookings={seatBookings}
            direct_booking_flag={direct_booking_flag_home}
            // bookingAllowed={bookingAllowed}
            WS_status_flag={WS_status}
            handleCancelBooking={(booking) => onCancelBooking(booking)}
            // handleSelectSeat={(booking)=>onSelectSeat(booking)}
          />
        );
      }
    }
  };

  const printHeader = () => {
    if (
      commonState.userEmail &&
      commonState.site_data &&
      commonState.cat_data
    ) {
      return (
        <Header
          currentPage="Home"
          commonState={commonState}
          direct_booking_flag={direct_booking_flag_home}
          blockAdminPage={blockAdminPage}
          toggleAdmin={changeBlockAdminPage}
        />
      );
    }
  };

  const changeBlockAdminPage = () => {
    setBlockAdminPage(!blockAdminPage);
  };

  // const SignIn = () => {
  //     let provider = new firebase.auth.GoogleAuthProvider();
  //     provider.addScope('https://www.googleapis.com/auth/plus.login');
  //     firebase.auth().signInWithRedirect(provider)
  // }

  if (!authState) {
    return (
      <div>Loading...</div>
    );
  }
  const printBody = () => {
    if (authState.isAuthenticated && userInfo) {
      return (
        <div>
          <div>
            {/* <Loader loading={loading}></Loader> */}
            {loadHomeScreen()}
          </div>
        </div>
      )
    }
  } 

  return (
    <div className="main-container">
      <div>      
        <Loader loading={loading}></Loader>  
        {printHeader()}
      </div>
      <div>
        {printBody()}
      </div>
    </div>
  );
};

