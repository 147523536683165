import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import { firebase } from '../../../config/firebase';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { confirmAlert } from 'react-confirm-alert';
import warningIcon from './../../../Assets/Images/warning.png';
import confirmIcon from './../../../Assets/Images/confirm.png';
import './block-seats.css'

export default function ReserveBlockedSeatDate(props) {
    const history = useHistory();
    // const [noOfSeats, setNoOfSeats] = useState(1);
    const noOfSeats = 1;
    const [availableSites, setAvailableSites] = useState([])
    const blockCategory = [
        // { value: 'Social Distance', label: 'Social Distance' },
        { label: 'Special Reservation Seats', value: 'Special Reservation Seats' },
        { label: 'Block Seats', value: 'Block Seats' }];
    const [org_list, set_org_list] = useState([]);
    // const [currency, setCurrency] = React.useState('');
    const [selected_block, setselected_block] = useState('')
    const [group, setGroup] = React.useState('');
    const maxDate = new Date(props.commonState.currentDate)
    maxDate.setDate(maxDate.getDate() + 7)
    const check_array = [false, false, false, false, false, false, false];
    const [active_days, setactive_days] = useState([false, true, true, true, true, true, false])
    const sites_ref = firebase.firestore().collection('Sites');
    const [booking_calendar_from, setBookingCalendarFrom] = React.useState();
    const [booking_calendar_to, setBookingCalendarTo] = React.useState();

    const blocked_seats_dates_ref = firebase
    .firestore()
    .collection("BlockedSeatsDates/" + props.commonState.site + "/Blocks");

    useEffect(() => {
        set_org_list(props.commonState.site_data.organization)
        sites_ref
            .get()
            .then(
            querySnapshot => {
                const tempSites = []
                querySnapshot.forEach(doc => {
                    const site = doc.id;
                    tempSites.push({ value: site, label: site });
                });
                setAvailableSites([...tempSites])
            })
    }, []);

    const handleChange = (event) => {
        // console.log('Change Site');
        // console.log(event.target.value);
        // setCurrency(event.target.value);
    };

    const handleDateChangeFrom = (date) => {
        setBookingCalendarFrom(date.target.value)
    };    

    const handleDateChangeTo = (date) => {
        setBookingCalendarTo(date.target.value)
    };

    const format_dt_to_ts = (cal_date) => {
        let current_date = new Date(cal_date);
        let currentDate = new Date(
            current_date.getFullYear(),
            current_date.getMonth(),
            current_date.getDate()
        );
        const curr_date_timestamp = firebase.firestore.Timestamp.fromDate(currentDate);
        return curr_date_timestamp;
    }

    const createBlockedSeats = (e) => {
        // console.log('Select Seats')
        if (selected_block === '') {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui confirm_dialog'>
                            <div className="confirm_title">Validation</div>
                            <div className="confirm_msg">
                                <div className="img">
                                    <img
                                        alt=''
                                        style={{ width: 50, height: 50 }}
                                        src={warningIcon}
                                    />
                                </div>
                                <div className="msg">Select Block Category</div>
                            </div>

                            <div className="confirm_buttons">
                                <button className="confirm_yes" onClick={onClose}>OK</button>
                            </div>
                        </div>
                    );

                }
            })
        }
        else if (JSON.stringify(active_days) === JSON.stringify(check_array)) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui confirm_dialog'>
                            <div className="confirm_title">Validation</div>
                            <div className="confirm_msg">
                                <div className="img">
                                    <img
                                        alt=''
                                        style={{ width: 50, height: 50 }}
                                        src={warningIcon}
                                    />
                                </div>
                                <div className="msg">Select atleast one active day</div>
                            </div>

                            <div className="confirm_buttons">
                                <button className="confirm_yes" onClick={onClose}>OK</button>
                            </div>
                        </div>
                    );

                }
            })
        }
        else {
            history.push('/floorplan', {
                action: 'reserve_blocked_seat_date',
                commonState: props.commonState,
                active_days: active_days,
                selected_block: selected_block,
                active_date_from: booking_calendar_from,
                active_date_to: booking_calendar_to,
                noOfSeats: noOfSeats,
                new_cat: true,
                group: group,
                re_select: false,
            })
        }

    }

    const createBlockedSeatsDate = (e) => {
        // console.log('Select Seats')
        if (selected_block === '') {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui confirm_dialog'>
                            <div className="confirm_title">Validation</div>
                            <div className="confirm_msg">
                                <div className="img">
                                    <img
                                        alt=''
                                        style={{ width: 50, height: 50 }}
                                        src={warningIcon}
                                    />
                                </div>
                                <div className="msg">Select Block Category</div>
                            </div>

                            <div className="confirm_buttons">
                                <button className="confirm_yes" onClick={onClose}>OK</button>
                            </div>
                        </div>
                    );

                }
            })
        }
        else if (booking_calendar_from === '' || booking_calendar_to === '') {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui confirm_dialog'>
                            <div className="confirm_title">Validation</div>
                            <div className="confirm_msg">
                                <div className="img">
                                    <img
                                        alt=''
                                        style={{ width: 50, height: 50 }}
                                        src={warningIcon}
                                    />
                                </div>
                                <div className="msg">Select the from and to dates</div>
                            </div>

                            <div className="confirm_buttons">
                                <button className="confirm_yes" onClick={onClose}>OK</button>
                            </div>
                        </div>
                    );

                }
            })
        }
        else {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui confirm_dialog'>
                            <div className="confirm_title">Confirmation</div>
                            <div className="confirm_msg">
                                <div className="img">
                                    <img
                                        alt=''
                                        style={{ width: 50, height: 50 }}
                                        src={confirmIcon}
                                    />
                                </div>
                                <div className="msg">Save {selected_block} for {booking_calendar_from} to {booking_calendar_to} ?</div>
                            </div>

                            <div className="confirm_buttons">
                                <button className="confirm_no" onClick={onClose}>Back</button>
                                <button className="confirm_yes"
                                    onClick={() => {
                                        const new_blocked_seat = {
                                            active_date_from: format_dt_to_ts(booking_calendar_from),
                                            active_date_to: format_dt_to_ts(booking_calendar_to),
                                            active_days: [],
                                            BlockName: selected_block,
                                            floor: {},
                                            isActive: true,
                                            last_updated_by: props.commonState.userName,
                                            last_updated_on: new Date(),
                                            group: group,
                                            no_of_seats: '',
                                        };

                                        blocked_seats_dates_ref
                                            .add({
                                                ...new_blocked_seat,
                                            })
                                            .then(function (docRef) {
                                            })
                                            .catch(function (error) {
                                                console.error(
                                                    "Error while reserving a Blocked seats: ",
                                                    error
                                                );
                                            });
                                        onClose()
                                    }}>
                                    Confirm
                        </button>
                            </div>
                        </div>
                    );

                }
            })

            //   console.log(new_blocked_seat)


        }

    }




    const getOrgList = () => {
        return org_list.map((org_name) => {
          return <MenuItem value={org_name}>{org_name}</MenuItem>;
        });
      };  
      
      const handleGroupChange = (val) => {
        setGroup(val);
      };      

    const handleCheckBoxChange = (e, index) => {
        let temp_arr = [...active_days]
        temp_arr[index] = !active_days[index];
        setactive_days([...temp_arr])
    }

    return (
        <div className='reserve-block-seat'>
            <div className='reserve-block-seat-items'>
                <div className='reserve-seat-input'>
                    <TextField
                        id="standard-select-currency"
                        value={props.commonState.site}
                        disabled
                        select
                        label="Select"
                        style={{ width: '80%', margin: '5px', marginTop: '20px', }}
                        onChange={handleChange}
                        helperText="Site"
                    >
                        {availableSites.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className='reserve-seat-input'>
                    <TextField
                            id="standard-select-currency"
                            select
                            value={group}
                            label="Organization"
                            style={{ width: '80%', margin: '5px' }}
                            onChange={(e) => { handleGroupChange(e.target.value) }}
                            // helperText="Select Block"
                        >
                            {getOrgList()}
                     
                        </TextField>      
                </div>
                <div className='reserve-seat-input'>
                    <TextField
                        id="standard-select-currency"
                        select
                        value={selected_block}
                        label="Select"
                        style={{ width: '90%', margin: '5px', marginTop: '20px', }}
                        onChange={(e) => { setselected_block(e.target.value) }}
                        helperText="Select Block"
                    >
                        {blockCategory.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                </div>
                <div style={{ display: 'flex', marginRight: '5px' }}>
                <TextField
                        id="date"
                        value={booking_calendar_from}
                        type="date"
                        onChange={(e) => handleDateChangeFrom(e)}
                        style={{ width: '80%', margin: '5px', marginTop: '35px', }}
                        helperText="From"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField
                        id="date"
                        type="date"
                        value={booking_calendar_to}
                        onChange={(e) => handleDateChangeTo(e)}
                        style={{ width: '80%', margin: '5px', marginTop: '35px', }}
                        helperText="To"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
                <div className='reserve-seat-input'>
                    <button
                        onClick={(e) => {
                            { selected_block === "Block Seats" ? createBlockedSeatsDate(e) : createBlockedSeats(e) }
                        }
                        }
                        className='field-seat-style reserve-seat-button'>
                        {selected_block === "Block Seats" ? "Block Seats" : "Select Seats"}

                    </button>
                </div>
            </div>
        </div>
    )
}
