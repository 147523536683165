import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import warningIcon from "./../../Assets/Images/warning.png";
import infoIcon from "./../../Assets/Images/info.png";

import { firebase } from "../../config/firebase";

import Header from "../AppHeader/header";
import Loader from "../Loader/loader";

import "./declaration.css";

export default function Declaration(props) {
  const location = useLocation();

  const declaration_ref = firebase
    .firestore()
    .collection(
      "DeclarationQuestions/" + location.state.commonState.site + "/Questions"
    );

  const [questions, setQuestions] = useState([]);

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    declaration_ref
      .orderBy("Que_No", "asc")
      .get()
      .then(
        (querySnapshot) => {
          const Questions = [];
          if (querySnapshot.docs.length !== 0) {
            querySnapshot.forEach((doc) => {
              const question = doc.data();
              question.id = doc.id;
              Questions.push(question);
            });
            // console.log(Questions);
            setQuestions(Questions);
            setLoading(false);
          } else {
              if(location.state.action === "update_declaration") {
                history.push("/", location.state);
              } 
              else {
                history.push("/floorplan", location.state);
              }
        }
        },
        (error) => {
          console.log(error);
        }
      )
      .catch(function (error) {
        console.log("Error getting Declaration questions: ", error);
      });
  }, []);

  //   console.log(location)

  const onOptionSelect = (e, queNo) => {
    // console.log(e.target.value)

    const questionIndex = questions.findIndex((que) => que.Que_No === queNo);
    // console.log(questionIndex)
    let newQuestions = [...questions];
    newQuestions[questionIndex] = {
      ...newQuestions[questionIndex],
      SelectedAns: e.target.value,
    };

    setQuestions([...newQuestions]);
    //    console.log(questions)
  };

  const closeDeclaration = () => {
    history.push("/", location.state);
  };

  const submitDeclaration = (e) => {
    //   console.log(questions)
    let validationFlag = false;
    let safeAnsFlag = false;

      questions.forEach((question) => {
        if (!question.SelectedAns) {
          validationFlag = true;
        }

        if (question.SelectedAns !== question.SafeAns) {
          safeAnsFlag = true;
          // e.forceUodate()
        }
      });

    if (validationFlag) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui confirm_dialog">
              <div className="confirm_title">Validation</div>
              <div className="confirm_msg">
                <div className="img">
                  <img
                    alt=""
                    style={{ width: 50, height: 50 }}
                    src={warningIcon}
                  />
                </div>
                <div className="msg">Please answer all the questions</div>
              </div>

              <div className="confirm_buttons">
                <button className="confirm_yes" onClick={onClose}>
                  OK
                </button>
              </div>
            </div>
          );
        },
      });
    } else {
      const user_ref = firebase
        .firestore()
        .collection("Users")
        .doc(location.state.commonState.userEmail);

      if (safeAnsFlag) {
        user_ref.update({
          declaration_last_updated: new Date(
            location.state.commonState.currentDate
          ),
          declaration_last_status: "not safe",
        });
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui confirm_dialog">
                <div className="confirm_title">Redirecting to Home</div>
                <div className="confirm_msg">
                  <div className="img">
                    <img
                      alt=""
                      style={{ width: 50, height: 50 }}
                      src={infoIcon}
                    />
                  </div>
                  <div className="msg">
                    Your health status is not safe. Redirecting to home page.
                  </div>
                </div>

                <div className="confirm_buttons">
                  <button
                    className="confirm_yes"
                    onClick={() => {
                      onClose();
                      history.push("/", location.state);
                    }}
                  >
                    OK
                  </button>
                </div>
              </div>
            );
          },
        });
      } else if (location.state.action === "update_declaration") {
        user_ref.update({
          declaration_last_updated: new Date(
            location.state.commonState.currentDate
          ),
          declaration_last_status: "safe",
        });
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui confirm_dialog">
                <div className="confirm_title">Redirecting to Home</div>
                <div className="confirm_msg">
                  Health declaration updated successfully.
                </div>
                <div className="confirm_buttons">
                  <button
                    className="confirm_yes"
                    onClick={() => {
                      onClose();
                      history.push("/", location.state);
                    }}
                  >
                    OK
                  </button>
                </div>
              </div>
            );
          },
        });
      } else {
        user_ref.update({
          declaration_last_updated: new Date(
            location.state.commonState.currentDate
          ),
          declaration_last_status: "safe",
        });

        history.push("/floorplan", location.state);
      }
    }
  };

  const printDeclarationForm = () => {
    if (location.state) {
      return (
        <div className="declarationForm">
          <div style={{ flex: 1, width: "100%" }}>
            <Header
              currentPage="Declaration"
              commonState={location.state.commonState}
            />
          </div>

          <div className="declaration_container">
            <div className="declaration_title">
              <div className="title_text"></div>
              <div className="title_text title_border">Self Declaration</div>
              <div className="title_text"></div>
            </div>

            {/* <div className='title_border'></div> */}
            

            <div className="decl_questions">
            <div className="declaration_note">Note: To respect privacy, we are not storing answers to these questions in the database.</div>
              {questions.map((question) => (
                <Question
                  key={question.id}
                  question={question}
                  OptionSelect={(e, queNo) => onOptionSelect(e, queNo)}
                />
              ))}
            </div>

           

            <div className="declaration-buttons">
              <div style={{ display: "flex", flex: 1 }}>
                <button
                  onClick={(e) => {
                    closeDeclaration(e);
                  }}
                  className="button-close-declaration"
                >
                  Back
                </button>
                {/* <Link 
                        to='.'
                        className='button-close-declaration'>
                            Back
                    </Link> */}
              </div>

              <div style={{ display: "flex", flex: 1 }}>
                <button
                  onClick={(e) => {
                    submitDeclaration(e);
                  }}
                  className="button-submit-declaration"
                >
                  Submit
                </button>
                {/* <Link 
                        to={{
                            pathname: routePath,
                            state: location.state
                        }}
                       
                        onClick= {(e)=> {submitDeclaration(e)}}
                        className='button-submit-declaration'>
                            Submit
                    </Link> */}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <Loader loading={loading}></Loader>
      {printDeclarationForm()}
    </div>
  );
}

const Question = (props) => {
  return (
    <div className="decl_question">
      {props.question.Que_No}. {props.question.Question}
      <div className="options">
        {props.question.Options.map((option, i) => {
          return (
            <span className="radio_span" key={props.question.Que_No + i}>
              <input
                className="radio_options"
                type="radio"
                name={props.question.Que_No}
                value={option}
                checked={props.question.SelectedAns === option}
                onChange={(e) => props.OptionSelect(e, props.question.Que_No)}
              />
              {option}
            </span>
          );
        })}
      </div>
    </div>
  );
};
