import React, { useState } from "react";
import Sidebar from "react-sidebar";

import "./header.css";
import hamburgerIcon from "./../../Assets/Images/hamburger.jpg";
import home from "./../../Assets/Images/home.png";
import live_status from "./../../Assets/Images/live_status.png";

import { useHistory } from "react-router-dom";

export default function Header(props) {
  const history = useHistory();
  // const mql = window.matchMedia(`(min-width: 800px)`);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const onSetSidebarOpen = (open) => {
    setSidebarOpen(open);
  };

  const navigateTo = (action) => {
    switch (action) {
      case "Home":
        history.push("/");
        setSidebarOpen(false);
        break;
      case "Live Status":
        history.push("/floorplan", {
          action: "live_status",
          commonState: props.commonState,
        });
        setSidebarOpen(false);
        break;
      case "Block Bookings":
        props.toggleAdmin();
        setSidebarOpen(false);
        history.push("/");
        break;
      case "Seat Bookings":
        props.toggleAdmin();
        setSidebarOpen(false);
        history.push("/");
        break;
      case "Update Declaration":
        setSidebarOpen(false);
        history.push("/declaration", {
          action: "update_declaration",
          commonState: props.commonState,
        });
        break;
      case "Update Profile":
        setSidebarOpen(false);
        history.push("/user_profile", {
          action: "user_profile",
          commonState: props.commonState,
          selected_user_email: props.commonState.userEmail,
        });
        break;
      case "Admin Dashboard":
        setSidebarOpen(false);
        history.push("/admin", {
          action: "admin_dashboard",
          commonState: props.commonState,
          tab: "booking",
        });
        break;
       case "Help Documents":
          // history.push('/declaration',
          window.open('https://drive.google.com/drive/folders/12a9Nwxe_hi4U0E457M0OhIoKeucjlApg', '_blank').focus();
          break;
       case "Check PDFs iFrame":
          setSidebarOpen(false);
          history.push("/help", {
            action: "help",
            link_action: "iframe",
            commonState: props.commonState
          });
          break;  
       case "Check PDFs Object":
            setSidebarOpen(false);
            history.push("/help", {
              action: "help",
              link_action: "object",
              commonState: props.commonState
            });
          break;  
       case "User Guide":
            setSidebarOpen(false);
            history.push("/help", {
              action: "help",
              link_action: "document",
              commonState: props.commonState
            });
          break;  
       case "Check PDFs Window":
            setSidebarOpen(false);
            history.push("/help", {
              action: "help",
              link_action: "window",
              commonState: props.commonState
            });
          break;  
      default:
        history.push("/");
        setSidebarOpen(false);
        break;
    }
  };


  const navigation = () => {
    let navigation_items = [];

    navigation_items.push({ name: "Home", icon: home });
    navigation_items.push({ name: "Live Status", icon: live_status });

    if (props.currentPage === "Home") {
      let block_booking_text;
      if (props.blockAdminPage) {
        block_booking_text = "Seat Bookings";
        navigation_items.push({ name: block_booking_text, icon: "" });
      } else if (
        props.commonState.isBlockAdmin &&
        !props.commonState.site_data.direct_booking_enabled &&
        !props.commonState.cat_data.isActive
      ) {
        block_booking_text = "Block Bookings";
        navigation_items.push({ name: block_booking_text, icon: "" });
      }
    }

    if (props.commonState.site_data.need_declaration) {
      navigation_items.push({ name: "Update Declaration", icon: "" });
    }
    navigation_items.push({ name: "Update Profile", icon: "" });

    if (props.commonState.isSiteAdmin && window.screen.width > 768) {
      navigation_items.push({ name: "Admin Dashboard", icon: "" });
    }

    if ( window.screen.width <= 768 ) {
      // navigation_items.push({ name: "Check PDFs iFrame", icon: "" });
      // navigation_items.push({ name: "Check PDFs Object", icon: "" });
      navigation_items.push({ name: "User Guide", icon: "" });
      // navigation_items.push({ name: "Check PDFs Window", icon: "" });
    }

    if ( window.screen.width > 768 ) {
       navigation_items.push({ name: "Help Documents", icon: "" });
    }
    return (
      <div className="sidebar_navigation">
        {navigation_items.map((item) => {
          return (
            <div
              key={Math.random()}
              className="navigate_link"
              onClick={() => navigateTo(item.name)}
            >
              {/* <img
                                className="nav_icon"
                                src={item.icon}
                                alt=""
                            />  */}
              - {item.name}
            </div>
          );
        })}
      </div>
    );
  };

  const printBlockAdmin = () => {
    if (props.commonState.direct_booking_flag === "Normal Booking")
      return (
        <div className="profile_booking_type">
          Block Admin: {props.commonState.blockAdmin}
        </div>
      );
  };

  const displayBookingType = () => {
    if(props.commonState.isAppAdmin) {
      return (
        <div className="profile_booking_type">              
        Booking Type: {props.commonState.direct_booking_flag}
      </div>
      )
    }
  }

  const sideBarContent = () => {
    if (props.commonState) {
      return (
        <div className="sidebar-content">
          <div className="sidebar-profile">
            <div className="profile_pic">
              <img
                className="profile_img"
                src={
                  props.commonState.userPhoto ? props.commonState.userPhoto : ""
                }
                alt="Profile Logo"
                onClick={() => onSetSidebarOpen(true)}
              />
            </div>
            <div className="profile_name">{props.commonState.userName}</div>
            <div className="profile_email">{props.commonState.userEmail}</div>
            <div className="profile_site">
              {props.commonState.category}, {props.commonState.organization}, {props.commonState.site}
            </div>
            {displayBookingType()}
            {printBlockAdmin()}
          </div>
          {navigation()}
        </div>
      );
    }
  };

  return (
    <div className="flex-container App-Header">
      <div className="flex-container app-header-left">
        <Sidebar
          // className="sideBarStyle"
          rootClassName="rootClassName"
          overlayClassName="overlayClassName"
          sidebarClassName="sidebarClassName"
          contentClassName="contentClassName"
          sidebar={sideBarContent()}
          open={sidebarOpen}
          onSetOpen={onSetSidebarOpen}
          styles={{ sidebar: { background: "#ededed" } }}
        >
          {/* <button onClick={() => onSetSidebarOpen(true)}>
                Open sidebar
                </button> */}
          <img
            className="hamburger"
            src={hamburgerIcon}
            alt="Side Bar"
            onClick={() => onSetSidebarOpen(true)}
          />
        </Sidebar>
      </div>
      <div className="app-title">Smart Seat</div>
      <div className="app-header-right">
        <div className="header_profile_pic">
          <img
            className="profile_img_header"
            src={
              props.commonState && props.commonState.userPhoto
                ? props.commonState.userPhoto
                : ""
            }
            alt="Profile Pic"
            onClick={() => onSetSidebarOpen(true)}
          />
        </div>
        <div className="header_profile_pic">
          {props.commonState && props.commonState
            ? props.commonState.userName
            : ""}
        </div>
      </div>
    </div>
  );
}
