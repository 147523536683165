import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { firebase } from "../../config/firebase";

import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
// import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
// import MuiDialogActions from "@material-ui/core/DialogActions";
// import IconButton from "@material-ui/core/IconButton";
// import CloseIcon from "@material-ui/icons/Close";
// import Typography from "@material-ui/core/Typography";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";

import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
// import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
// import Loader from "../Loader/loader";

// import chair_icon from './../../Assets/Images/chair.png';
// import health_icon from './../../Assets/Images/safe-health.png';

import "./register.css";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "80%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

// const styles = (theme) => ({
//   root: {
//     margin: 0,
//     // padding: theme.spacing(2),
//   },
//   closeButton: {
//     position: "absolute",
//     right: theme.spacing(1),
//     top: theme.spacing(1),
//     color: theme.palette.grey[500],
//   },
// });

// const DialogTitle = withStyles(styles)((props) => {
//   const { children, classes, onClose, ...other } = props;
//   return (
//     <MuiDialogTitle disableTypography className={classes.root} {...other}>
//       <Typography variant="h6">{children}</Typography>
//       {onClose ? (
//         <IconButton
//           aria-label="close"
//           className={classes.closeButton}
//           onClick={onClose}
//         >
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </MuiDialogTitle>
//   );
// });

const DialogContent = withStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
  },
}))(MuiDialogContent);

// const DialogActions = withStyles((theme) => ({
//   root: {
//     // margin: 0,
//     // padding: theme.spacing(1),
//   },
// }))(MuiDialogActions);

export default function Register(props) {
  const history = useHistory();
  const location = useLocation();

  const [open, setOpen] = useState(true);

  const classes = useStyles();
  const [site, setSite] = useState("");
  const [blockAdmin, setblockAdmin] = useState("");
  const [category, setCategory] = useState("");
  const [organization, setOrganization] = useState("");

  const [site_list, set_site_list] = useState([]);
  const [org_list, set_org_list] = useState([]);
  const [blockAdmin_list, set_blockAdmin_list] = useState([]);
  const [category_list, set_category_list] = useState([]);
  const [category_list_all, set_category_list_all] = useState([]);  
  const [tnc_text, set_tnc_text] = useState("");

  const [loading, setLoading] = useState(false);

  const users_ref = firebase.firestore().collection("Users");
  const sites_ref = firebase.firestore().collection("Sites");
  // const profile_questions = firebase.firestore().collection("ProfileQuestions");
  const [loadErrorMsg, setLoadErrorMsg] = useState(false);
  const [askHealthQuestPermanent, setAskHealthQuestPermanent] = useState(false);

  useEffect(() => {
    setLoading(true);
    
    sites_ref
      .orderBy("Site", "asc")
      .get()
      .then(
        (querySnapshot) => {
          const sites = [];
          querySnapshot.forEach((doc) => {
            const v_site = doc.data();
            v_site.id = doc.id;
            sites.push(v_site.Site);
          });
          // console.log("Sites", sites);
          set_site_list(sites);
          setLoading(false);
        },
        (error) => {
          console.log(error);
        }
      )
      .catch(function (error) {
        console.log("Error getting Site List: ", error);
      });

    // setLoading(true)
  }, [sites_ref]);

  const handleSiteChange = (event) => {
    const site_selected = event.target.value;
    setSite(site_selected);
    // console.log(loading);
    setLoading(true);
    users_ref
      .where("site", "==", site_selected)
      .where("isBlockAdmin", "==", true)
      .orderBy("blockAdmin", "asc")
      .get()
      .then(
        (querySnapshot) => {
          const BlockAdmins = [{
            blockAdmin : "None",
            blockAdminEmail : "None"
          }];
          querySnapshot.forEach((doc) => {
            const blk_admin = {};
            // blk_admin.id = doc.id
            blk_admin.blockAdmin = doc.data().userName;
            blk_admin.blockAdminEmail = doc.data().userEmail;
            // console.log("blk_admin", blk_admin);
            BlockAdmins.push(blk_admin);
          });
          // console.log("BlockAdmins", BlockAdmins);
          // const unique_BlkAdmins = [...new Set(BlockAdmins.map(admin => admin))]
          // const unique_BlkAdmins = BlockAdmins.filter((admin)=> admin.blockAdminEmail !== BlockAdmins.blockAdminEmail)

          set_blockAdmin_list([...BlockAdmins]);
          // console.log(blockAdmin_list);
          setLoading(false);
        },
        (error) => {
          console.log(error);
        }
      )
      .catch(function (error) {
        console.log("Error getting Site List: ", error);
      });

    const category_ref = firebase
      .firestore()
      .collection("Categories/" + site_selected + "/Category");

    // console.log("site_selected", site_selected);

    category_ref
      .get()
      .then(
        (querySnapshot) => {
          // const Categories = [];
          let cat_all = {}
          querySnapshot.forEach((doc) => {
            // let cat={};
            const cat = doc.data();
            cat.id = doc.id;
            // console.log("cat", cat);
            // Categories.push(cat);
            if(cat.week_preference){
              let tempCatGroup = cat.week_preference.group;
              if (cat_all[tempCatGroup]) {
                let temp_group_arr = [...cat_all[tempCatGroup]]
                temp_group_arr.push(cat)
                cat_all[tempCatGroup] = [...temp_group_arr]
              }
              else {
                  cat_all[tempCatGroup] = [cat]; 
              }
            }
          });

          set_category_list_all({...cat_all})
          // set_category_list([...Categories]);
          // console.log("category_list", Categories);
          setLoading(false);
        },
        (error) => {
          console.log(error);
        }
      )
      .catch(function (error) {
        console.log("Error getting Category List: ", error);
      });

      sites_ref
      .doc(site_selected)
      .get()
      .then(doc => {
          if (!doc.exists) {
              // console.log('No doc found');
          } else {
              let new_floor_state = {};
              new_floor_state = { ...doc.data() }
              if (new_floor_state.tnc && new_floor_state.tnc !== '') {
                  set_tnc_text(new_floor_state.tnc)
              } else {
                  set_tnc_text('')
              }
              if(new_floor_state.need_declaration_profile){
                setAskHealthQuestPermanent(true)
              }
              set_org_list(new_floor_state.organization)
          }
      })

      





  };

  const handleBlockAdminChange = (event) => {
    setblockAdmin(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleOrgChange = (event) => {
    setOrganization(event.target.value);
    set_category_list([...category_list_all[event.target.value]]);
  };
  

  const getSiteList = () => {
    return site_list.map((site_name) => {
      return <MenuItem value={site_name}>{site_name}</MenuItem>;
    });
  };

  const getBlockAdminList = () => {
    return blockAdmin_list.map((blkAdmin) => {
      return (
        <MenuItem value={blkAdmin.blockAdminEmail}>
          {blkAdmin.blockAdmin !== "None" ? blkAdmin.blockAdmin + " (" + blkAdmin.blockAdminEmail + ")" : blkAdmin.blockAdmin}
          {/* {blkAdmin.blockAdmin + " (" + blkAdmin.blockAdminEmail + ")"} */}
        </MenuItem>
      );
      //  return (<MenuItem value={blkAdmin.blockAdminEmail}>{blkAdmin.blockAdmin}</MenuItem>)
    });
  };

  const getCategoryList = () => {
    return category_list.map((cat) => {
      return <MenuItem value={cat.category}>{cat.category}</MenuItem>;
    });
  };

  const getOrgList = () => {
    return org_list.map((org_name) => {
      return <MenuItem value={org_name}>{org_name}</MenuItem>;
    });
  };
  

  const handleClose = () => {
    setOpen(false);
    history.push("/error");
    //  props.closeRegister()
  };

  const register = () => {
    // if(site && blockAdmin && category) {
    if (site && category) {
      setLoadErrorMsg(false);
      // console.log("Reisteringg");

      const blk_admin = blockAdmin_list.filter(
        (blk_adm) => blk_adm.blockAdminEmail === blockAdmin
      );

      const new_user = {
        blockAdmin: blk_admin.length > 0 && blk_admin[0].blockAdmin !== "None" ? blk_admin[0].blockAdmin : "",
        blockAdminEmail: blockAdmin !== "None" ? blockAdmin : "",
        category: category,
        organization: organization,
        cubicleBookingAccess : false,        
        declaration_last_status: "",
        declaration_last_updated: "",
        isAppAdmin: false,
        isSiteAdmin: false,
        isBlockAdmin: false,
        profile_created: new Date(),
        site: site,
        my_ws: {},
        userEmail: location.state.userEmail,
        userName: location.state.userName,
        userPhoto: location.state.userImage,
        Health_Declaration:[]
      };

      var check_questions = false;

      if (askHealthQuestPermanent) {
        // profile_questions
        // .doc(site)
        // .get()
        // .then(doc => {
        //     if (!doc.exists) {
        //       check_questions = false;
        //     } else {
        //       check_questions = true;
        //     }
        // })

        const profileq_ref = firebase
        .firestore()
        .collection("ProfileQuestions/" + site + "/Questions");
  
      // console.log("site_selected", site_selected);
  
      profileq_ref
        .get()
        .then(
          (querySnapshot) => {
            querySnapshot.forEach((doc) => {
              check_questions = true;
            }); 

          },
          (error) => {
            // console.log(error);
          }
        )
        .catch(function (error) {
          // console.log("Error getting Category List: ", error);
        });

      }


      users_ref
        .doc(location.state.userEmail)
        .set({
          ...new_user,
        })
        .then(function (docRef) {
          sessionStorage.setItem("user", JSON.stringify(location.state));


          if (check_questions) {
            history.push("/health_declaration",
            {
              action: 'update_declaration',
              commonState: props.commonState,
              site : site,
              new_user : new_user,
              tnc_text : tnc_text,
            }
            );
          }
          else {
            history.push("/", new_user);
          }

        })
        .catch(function (error) {
          console.error("Error while registering new user: ", error);
          history.push("/error", error);
        });
    } else {
      setLoadErrorMsg(true);
      // console.log("Please fill all the fields..");
    }
  };

  const loadError = () => {
    if (loadErrorMsg) {
      return (
        <div className="register-error">
          Please select both Site and Category.
        </div>
      );
    }
  };

  const loadForm = () => {
    if (location.state) {
      // console.log("Inn Register");
      return (
        <Dialog open={open}>
          <DialogContent className="register_container">
            <div className="register_main">
              <div className="close_icon" onClick={() => handleClose()}>
                <CancelTwoToneIcon />
              </div>
              <div className="register_title">Registration</div>
              <div className="">{location.state.user_email}</div>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="site-select-outlined-label">Site</InputLabel>
                <Select
                  labelId="site-select-outlined-label"
                  id="site-select-outlined"
                  required
                  value={site}
                  onChange={(e) => handleSiteChange(e)}
                  label="Choose Site"
                >
                  {getSiteList()}
                </Select>
              </FormControl>

              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="category-select-outlined-label">
                  Organization
                </InputLabel>
                <Select
                  labelId="category-select-outlined-label"
                  id="category-select-outlined"
                  value={organization}
                  onChange={(e) => {
                    handleOrgChange(e);
                  }}
                  label="Choose Organization"
                >
                  {getOrgList()}
                </Select>
              </FormControl>

              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="category-select-outlined-label">
                  Category
                </InputLabel>
                <Select
                  labelId="category-select-outlined-label"
                  id="category-select-outlined"
                  value={category}
                  onChange={(e) => {
                    handleCategoryChange(e);
                  }}
                  label="Choose Category"
                >
                  {getCategoryList()}
                </Select>
              </FormControl>

              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="block-Admin-select-outlined-label">
                  Cat Lead/Block Admin
                </InputLabel>
                <Select
                  labelId="block-Admin-select-outlined-label"
                  id="block-Admin-select-outlined"
                  value={blockAdmin}
                  onChange={(e) => {
                    handleBlockAdminChange(e);
                  }}
                  label="Choose Block Admin"
                >
                  {getBlockAdminList()}
                </Select>
              </FormControl>

              {loadError()}

              <Button
                variant="contained"
                className="register_button"
                onClick={register}
              >
                Submit
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      );
    } else {
      history.push("/error");
    }
  };

  return <div className="register_container">{loadForm()}</div>;
}
