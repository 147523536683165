import React from 'react';
import ReserveCategory from './ReserveCategory';
import { confirmAlert } from 'react-confirm-alert';
import Switch from '@material-ui/core/Switch';
import Modal from "@material-ui/core/Modal";
import Checkbox from '@material-ui/core/Checkbox';
import EditCategory from './EditCategory';
import '../../BlockBookings/block-bookings.css'

export default function CategorySub(props) {
    const block_bookings_header = () => {
        // console.log(props.blockBookings.length);
        if (props.blockBookings.length === 0) {
            return (
                <div className='block-booking'>
                    No Categories Present
                </div>
            )
        }
        else {
            return (
                <div className="block-booking-header">
                    Categories
            </div>
            )
        }
    }

    return (
        <div className='cat-block-bookings'>
            <ReserveCategory
                commonState={props.commonState}
                blockBookings={props.blockBookings}
                category_names={props.category_names}
                reload_data={(e) => props.reload_data(e)}
                reserveBlock={(newBlock) => props.handleReseveBlock(newBlock)} />

            <div className='block-bookings-content'>
                {block_bookings_header()}

                {props.blockBookings.map((item, index) =>
                    <BlockBooking
                        key={index}
                        blockBooking={item}
                        commonState={props.commonState}
                        blockBookings={props.blockBookings}
                        reload_data={(e) => props.reload_data(e)}
                        reserveBlock={(newBlock) => props.handleReseveBlock(newBlock)}
                        cancelBlockClicked={(block) => props.handleCancelBlock(block)}
                        releaseSeatsClicked={(block) => props.handleReleaseSeats(block)}
                    />)}
            </div>
        </div>
    )
}

const BlockBooking = (props) => {
    const [editCategory, seteditCategory] = React.useState(false);

    const printDate = () => {
        let date1 = props.blockBooking.last_updated_on.toDate().toLocaleDateString();
        return date1;
    }

    const deleteCat = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui confirm_dialog'>
                        <div className="confirm_title">Confirmation</div>
                        <div className="confirm_msg">Are you sure you want to delete the {props.blockBooking.category}?</div>
                        <div className="confirm_buttons">
                            <button className="confirm_no" onClick={
                                () => {
                                    onClose();
                                }
                            }>No</button>

                            <button className="confirm_yes" onClick={
                                () => {
                                    props.cancelBlockClicked(props.blockBooking);
                                    onClose();
                                }
                            }>Yes</button>
                        </div>
                    </div>
                );
            }
        })
    }

    const handleEditOpen = () => {
        // console.log('open');
        seteditCategory(true);
    };

    const handleEditClose = () => {
        // console.log('Edit window closed')
        seteditCategory(false);
    }

    const editCat = () => {
        handleEditOpen();
    }

    const EditCatModal = (
        <EditCategory
            commonState={props.commonState}
            reload_data={(e) => props.reload_data(e)}
            handleEditClose={handleEditClose}
            blockBooking={props.blockBooking}
            blockBookings={props.blockBookings}
            reserveBlock={(newBlock) => props.handleReseveBlock(newBlock)} />
    );

    const display_Active_days = () => {
        if (props.blockBooking.isActive) {
            return (
                <div style={{ display: 'flex' }}>
                    <div className='block-booking-right'>
                        <div className='block-seats'>
                            <Checkbox size="small" disabled={!props.blockBooking.isActive} checked={props.blockBooking.active_days[1]} />
                            <div style={{ fontSize: '12px' }}>
                                Mon
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox size="small" disabled={!props.blockBooking.isActive} checked={props.blockBooking.active_days[2]} />
                            <div style={{ fontSize: '12px' }}>
                                Tue
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox size="small" disabled={!props.blockBooking.isActive} checked={props.blockBooking.active_days[3]} />
                            <div style={{ fontSize: '12px' }}>
                                Wed
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox size="small" disabled={!props.blockBooking.isActive} checked={props.blockBooking.active_days[4]} />
                            <div style={{ fontSize: '12px' }}>
                                Thu
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox size="small" disabled={!props.blockBooking.isActive} checked={props.blockBooking.active_days[5]} />
                            <div style={{ fontSize: '12px' }}>
                                Fri
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox size="small" disabled={!props.blockBooking.isActive} checked={props.blockBooking.active_days[6]} />
                            <div style={{ fontSize: '12px' }}>
                                Sat
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox size="small" disabled={!props.blockBooking.isActive} checked={props.blockBooking.active_days[0]} />
                            <div style={{ fontSize: '12px' }}>
                                Sun
                        </div>
                        </div>
                    </div>
                    <div className='block-seats'>
                        <Switch checked={props.blockBooking.auto_booking_enabled} />
                        <div style={{ fontSize: '12px' }}>
                            Auto Booking
                    </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div style={{ display: 'flex', visibility: 'hidden' }}>
                    <div className='block-booking-right'>
                        <div className='block-seats'>
                            <Checkbox size="small" disabled={!props.blockBooking.isActive} checked={props.blockBooking.active_days[1]} />
                            <div style={{ fontSize: '12px' }}>
                                Mon
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox size="small" disabled={!props.blockBooking.isActive} checked={props.blockBooking.active_days[2]} />
                            <div style={{ fontSize: '12px' }}>
                                Tue
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox size="small" disabled={!props.blockBooking.isActive} checked={props.blockBooking.active_days[3]} />
                            <div style={{ fontSize: '12px' }}>
                                Wed
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox size="small" disabled={!props.blockBooking.isActive} checked={props.blockBooking.active_days[4]} />
                            <div style={{ fontSize: '12px' }}>
                                Thu
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox size="small" disabled={!props.blockBooking.isActive} checked={props.blockBooking.active_days[5]} />
                            <div style={{ fontSize: '12px' }}>
                                Fri
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox size="small" disabled={!props.blockBooking.isActive} checked={props.blockBooking.active_days[6]} />
                            <div style={{ fontSize: '12px' }}>
                                Sat
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox size="small" disabled={!props.blockBooking.isActive} checked={props.blockBooking.active_days[0]} />
                            <div style={{ fontSize: '12px' }}>
                                Sun
                        </div>
                        </div>
                    </div>
                    <div className='block-seats'>
                        <Switch checked={props.blockBooking.auto_booking_enabled} />
                        <div style={{ fontSize: '12px' }}>
                            Auto Booking
                    </div>
                    </div>
                </div>
            )
        }
    }

    const displayGroup = () => {

        if(props.blockBooking.week_preference ) {
            return (
                <div style={{ fontSize: '10px' }}>
                     {props.blockBooking.week_preference.group}
                </div>
            )
        } 
    }

    const display_Repeat_Week = () => {
        // getRepeatWeekDetails();
        let next_repeat_date;
        if(props.blockBooking.isActive && props.blockBooking.week_preference){
            next_repeat_date = props.blockBooking.week_preference.startingMonday.toDate().toLocaleDateString();
        }
        else {
            next_repeat_date = '';
        }
        

        if (props.blockBooking.isActive && props.blockBooking.week_preference.repeat_week === true) {
            return (
                <div className='block-seats'>
                <div style={{ fontSize: '14px' }}>
                    Next Booking
                    {/* {props.blockBooking.last_updated_by} */}
                </div>
                <div style={{ fontSize: '10px' }}>
                   Week of: {next_repeat_date}
                </div>
                <div style={{ fontSize: '9px' }}>
                    (Repeat every {props.blockBooking.week_preference.weekFrequency} weeks)
                   
                </div>
            </div>
            )
        }
        else {
            return (
                <div className='block-seats'></div>
            )
        }
    }

    return (
        <div className='cat-block-booking'>
            <div className='block-booking-left'>
                <div className='block-seats'>
                    <Switch checked={props.blockBooking.isActive} />
                    <div style={{ fontSize: '12px' }}>
                        Reserve Permanent Block?
                    </div>
                </div>
                <div className='block-date'>
                    <div style={{ fontSize: '16px' }}>
                        {props.blockBooking.category}
                    </div>
                    {displayGroup()}
                </div>
                <div className='block-seats'>
                    <div style={{ fontSize: '24px' }}>
                        {props.blockBooking.no_of_seats}
                    </div>
                    <div style={{ fontSize: '12px' }}>
                        Seats Blocked
                    </div>
                </div>
                {display_Active_days()}
                {display_Repeat_Week()}

                <div className='block-seats'>

                    <div style={{ fontSize: '14px' }}>
                        Last Updated
                    </div>
                    <div style={{ fontSize: '10px' }}>
                        On: {printDate()}
                    </div>
                    <div style={{ fontSize: '10px' }}>
                        By: {props.blockBooking.last_updated_by}
                    </div>
                </div>
            </div>
            <div >
                <div className='block-buttons'>
                    <button
                        className='button-release-seats'
                        onClick={() => editCat(props.blockBooking)}>
                        Edit
                </button>

                    <button
                        onClick={() => deleteCat(props.blockBooking)}
                        className='button-cancel-block'>
                        Delete
                </button>
                </div>
            </div>
            <Modal
                open={editCategory}
                onClose={handleEditClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {EditCatModal}
            </Modal>
        </div>
    )
}