import React, { useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import BlockedSeats from '../Admin/BlockedSeats/BlockedSeats';
import FloorDiv from '../Admin/FloorDivision/floor-div';
import CategoryMain from '../Admin/Categories/CategoryMain';
// import DeclarationMain from './Declaration/declarationMain';
import DeclarationMainNew from './Declaration_new/declarationMain';
import BookingMain from '../Admin/Bookings/BookingMain';
import UserTable from '../Admin/Users/users_table';
import Header from '../AppHeader/header';
import SiteNewFloor from '../Admin/Sites/SiteNewFloor';
import './admin-home.css'


export default function AdminHome(props) {
    const page = true;
    const [pageSelected, setPageSelected] = React.useState(props.location.state.tab);
    const [admin_buttons, setadmin_buttons] = useState({
        'booking': 'admin_notselected',
        'category': 'admin_notselected',
        'blockedseats': 'admin_notselected',
        'site': 'admin_notselected',
        'users': 'admin_notselected',
        'floor_div': 'admin_notselected',
        // 'declaration': 'admin_notselected',
        'declaration_new': 'admin_notselected'
    })

    React.useEffect(() => {
        let temp_buttons_state = { ...admin_buttons }
        temp_buttons_state[pageSelected] = 'admin_selected';
        // console.log(temp_buttons_state)
        setadmin_buttons({ ...temp_buttons_state })

    }, [])


    const change_tab = (current_tab) => {
        let temp_buttons_state = { ...admin_buttons }
        temp_buttons_state[pageSelected] = 'admin_notselected';
        setPageSelected(current_tab)
        temp_buttons_state[current_tab] = 'admin_selected';
        setadmin_buttons({ ...temp_buttons_state })
    }

    const admin_navbar = () => {
        // console.log('admin dashboard');
        // console.log(props.location.state.commonState);
        return (
            <div className="block-navbar">
                <div className="admin-title">
                    Admin Dashboard</div>
                <button className={admin_buttons.booking} onClick={(e) => { change_tab('booking'); }}>
                    Bookings</button>
                <button className={admin_buttons.category} onClick={(e) => { change_tab('category'); }}>
                    Categories</button>
                <button className={admin_buttons.blockedseats} onClick={(e) => { change_tab('blockedseats') }}>
                    Blocked Seats</button>
                <button className={admin_buttons.site} onClick={(e) => { change_tab('site') }}>
                    Site</button>
                <button className={admin_buttons.users} onClick={(e) => { change_tab('users') }}>
                    Users</button>
                <button className={admin_buttons.floor_div} onClick={(e) => { change_tab('floor_div') }}>
                    Floors Division</button>
                {/* <button className={admin_buttons.declaration} onClick={(e) => { change_tab('declaration') }}>
                    Declaration</button> */}
                <button className={admin_buttons.declaration_new} onClick={(e) => { change_tab('declaration_new') }}>
                    Declaration</button>
            </div>
        )

    }
    const load_admin_pages = () => {
        switch (pageSelected) {
            case 'floor_div':
                return (<FloorDiv commonState={props.location.state.commonState} />)
            case 'category':
                return (<CategoryMain
                    commonState={props.location.state.commonState}
                />)
            case 'blockedseats':
                return (<BlockedSeats
                    commonState={props.location.state.commonState}
                />)
            // case 'declaration':
            //     return (<DeclarationMain
            //         commonState={props.location.state.commonState}
            //     />)
            case 'declaration_new':
                return (<DeclarationMainNew
                    commonState={props.location.state.commonState}
                />)
            case 'booking':
                return (<BookingMain
                    commonState={props.location.state.commonState}
                />)
            case 'site':
                return (<SiteNewFloor
                    commonState={props.location.state.commonState}
                />)
            case 'users':
                return (<UserTable
                    commonState={props.location.state.commonState}
                />)
            default:
                return (<BookingMain
                    commonState={props.location.state.commonState}
                />)
        }
    }

    return (
        <div className='block-bookings'>
            <div style={{ flex: 1, width: '100%' }}>
                <Header
                    currentPage="Declaration"
                    commonState={props.location.state.commonState}
                    blockAdminPage={page}
                />
            </div>

            <div>

                {admin_navbar()}
                {load_admin_pages()}
            </div>
        </div>
    )
}

