import React from 'react';
import { firebase } from '../../config/firebase'
import { useHistory } from 'react-router-dom';
import Loader from '../Loader/loader'
// import Register from '../Register/register';
// import { getAuth, getRedirectResult, GoogleAuthProvider } from "firebase/auth";


export default function SignIn(props) {

    const history = useHistory();

    let provider = new firebase.auth.GoogleAuthProvider();
    // const auth = getAuth();

    // const [loading, setLoading] = useState(true)
    const loading = true;

    const SignIn = () => {

        // console.log("Signing inn..")

        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
            .then(() => {
                provider.setCustomParameters({
                    hd: "colpal.com"
                });
                // firebase.auth().signInWithPopup(provider)
                firebase.auth().getRedirectResult()
                    .then((result) => {
                        if (!result.user) {
                            // User not logged in, start login.
                            firebase.auth().signInWithRedirect(provider);
                        }
                        // if(result){
                        else {
                            var user = result.user;
                            // var user = result;
                            // console.log("Signedd inn..")
                            // console.log(user)
                            const user_temp = {}
                            user_temp.userName = user.displayName
                            user_temp.userEmail = user.email
                            user_temp.userImage = user.photoURL

                            // sessionStorage.setItem('user', JSON.stringify(user_temp))
                            // console.log(user_temp)
                            history.push('/register', user_temp)
                        }

                    })
            })

    }


    return (
        <div>
            <Loader loading={loading}></Loader>
            {SignIn()}
        </div>

    )

}




