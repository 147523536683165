import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import { firebase } from '../../../config/firebase';
// import { lighten, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
// import Switch from '@material-ui/core/Switch';

import './floor-div.css'

export default function ReserveWing(props) {

    // let selectedDate;
    const formRef = React.useRef();
    const [selectedDate] = useState(props.commonState.currentDate);
    // const [noOfSeats, setNoOfSeats] = useState(1);
    // const [regexp] = useState(/^[0-9\b]+$/);
    const [availableSites, setAvailableSites] = useState([])
    const [blockCategory, setblockCategory] = useState([])
    // const [sitename, setSiteName] = React.useState('');
    // const [active_days, setactive_days] = [false, true, true, true, true, true, false]
    const [current_floor, setcurrent_floor] = React.useState('');
    const [wingName, setWingName] = useState('');
    const [defSite, setDefSite] = useState(props.commonState.site);
    // const minDate = new Date(props.commonState.currentDate)
    const maxDate = new Date(props.commonState.currentDate)
    maxDate.setDate(maxDate.getDate() + 7)
    const sites_ref = firebase.firestore().collection('Sites');
    useEffect(() => {
        sites_ref
            .get()
            .then(
            querySnapshot => {
                const tempSites = []
                querySnapshot.forEach(doc => {
                    const site = doc.id;
                    tempSites.push({ value: site, label: site });
                    // setSiteName(site);
                }
                );
                setAvailableSites([...tempSites])
            }
            )
        // if(props.commonState.site === 'CBS-Warsaw'){
        //     let tempfloor = [{ value: '7th-floor', label: '7th-floor' }]        
        //     setblockCategory(tempfloor);
        // }
        const floorsNames = Object.keys(props.commonState.floorState).sort();
        // console.log(floorsNames);
        const floorsNamesDisplay = []
        for (let i in floorsNames){
            floorsNamesDisplay.push({ value: floorsNames[i], label: floorsNames[i] });
        }
        setblockCategory([...floorsNamesDisplay])
    }, []);

    const handleChangeSite = (event) => {
        // console.log('Change Site');
        // console.log(event.target.value);
        setDefSite(event.target.value);
    };

    // const ReserveBlockClicked = () => {

    //     const newBlock =
    //         {
    //             //    block_id:'ytfty',   
    //             blocked_by: props.commonState.userName,
    //             blocked_by_email: props.commonState.userEmail,
    //             blocked_date: selectedDate,
    //             blocked_on: new Date(),
    //             wing: 'A Wing',
    //             floor: "9th Floor",
    //             seats_blocked: noOfSeats,
    //             seats_confirmed: 0,
    //             site: props.commonState.site
    //         }

    //     setNoOfSeats('')
    //     props.reserveBlock(newBlock)
    // }

    const handleChange = (event) => {
        // console.log('Change Site');
        // console.log(event.target.value);
        setcurrent_floor(event.target.value);
        // props.setfloor_t(event.target.value);
        props.setfloor(event.target.value)
    };
    const SelectBlockClicked = () => {

    }

    const handleWingChange = (e) => {
        // console.log(e.target.value);
        setWingName(e.target.value);
    }

    // const onNumberChange = (event) => {
    //     const seats = event.target.value;
    //     if (seats === '' || regexp.test(seats)) {
    //         setNoOfSeats(seats)
    //         console.log(seats)
    //     }
    //     else {
    //         console.log('Invalid Number')
    //     }
    // }

    return (

        <div className='reserve-block-seat'>
            <form ref={formRef}>
                <div className='reserve-block-seat-items'>

                    <div className='reserve-seat-input'>
                        <TextField
                            id="standard-select-current_floor"
                            select
                            value={defSite}
                            label="Select"
                            disabled={!props.commonState.isAppAdmin}
                            style={{ width: '80%', margin: '5px', marginTop: '20px', }}
                            onChange={handleChangeSite}
                            helperText="Please select the site"
                        >
                            {availableSites.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className='reserve-seat-input'>
                        <TextField
                            id="standard-select-current_floor"
                            required
                            select
                            label="Select"
                            style={{ width: '80%', margin: '5px', marginTop: '20px', }}
                            onChange={handleChange}
                            helperText="Floor"
                        >
                            {blockCategory.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>

                    </div>
                    <div className='reserve-seat-input'>
                    <TextField 
                    id="wing" 
                    label="Wing Name"
                    value={wingName}
                    onChange={(e) => handleWingChange(e)}
                    />
                    </div>
                    <div className='reserve-seat-input'>
                        <Link
                            to={{
                                pathname: '/floorplan',
                                state: {
                                    action: 'floor_division_wings',
                                    commonState: props.commonState,
                                    selectedDate: selectedDate,
                                    blockedSeatName: "Test",
                                    floor: current_floor,
                                    wingName: wingName,
                                }
                            }}
                        >
                            <button
                                onClick={() => {
                                    // const formValidate = formRef.current.reportValidity();
                                    // console.log('formref1');
                                    // console.log(formValidate);
                                    // if (formValidate === true) {
                                        // console.log('test');
                                        SelectBlockClicked()
                                    // }
                                }
                                }
                                className='field-seat-style reserve-seat-button'>
                                Create Wing
                        </button>
                        </Link>
                    </div>
                </div>
            </form>
        </div>
    )
}
