import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { firebase } from '../../../config/firebase';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Loader from '../../Loader/loader'
import './booking.css'
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';
import Divider from '@mui/material/Divider';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { confirmAlert } from 'react-confirm-alert';
import sendEmail from "../../SendEmail/send-email"


function createData(name, email, date, blocked_on, site, floor, wing, booked_seats, confirm_seats) {
    return { name, email, date, blocked_on, site, floor, wing, booked_seats, confirm_seats };
}

function createDataSeat(seat_id, name, email, admin, date, ws, parking_no, cat, status, site, floor, wing, blocked_date) {
    return { seat_id, name, email, admin, date, ws, parking_no, cat, status, site, floor, wing, blocked_date };
}


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Booked by' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email ID' },
    { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
    { id: 'blocked_on', numeric: false, disablePadding: false, label: 'Created on' },
    { id: 'site', numeric: false, disablePadding: false, label: 'Site' },
    { id: 'floor', numeric: false, disablePadding: false, label: 'Floor' },
    { id: 'Wing', numeric: false, disablePadding: false, label: 'Wing' },
    { id: 'booked_seats', numeric: true, disablePadding: false, label: 'Booked seats' },
    { id: 'confirm_seats', numeric: true, disablePadding: false, label: 'Confirmed seats' },
];
const headCellsSeat = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Booked by' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email ID' },
    { id: 'admin', numeric: false, disablePadding: false, label: 'BlockAdmin' },
    { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
    { id: 'ws', numeric: false, disablePadding: false, label: 'Workstation No.' },
    { id: 'parking', numeric: false, disablePadding: false, label: 'Parking No.' },
    { id: 'cat', numeric: false, disablePadding: false, label: 'Category' },
    { id: 'status', numeric: false, disablePadding: false, label: 'Confirmed' },
    { id: 'site', numeric: false, disablePadding: false, label: 'Site' },
    { id: 'floor', numeric: false, disablePadding: false, label: 'Floor' },
    { id: 'Wing', numeric: false, disablePadding: false, label: 'Wing' },
];


function EnhancedTableHead(props) {
    // const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className='site-table-header'>
            <TableRow className='site-table-header'>
                {headCells.map((headCell, index) => (
                    <TableCell
                        // className='site-table-header'
                        key={index}
                        className={classes.table_cell}
                        align={'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableHeadSeat(props) {
    // const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className='site-table-header'>
            <TableRow className='site-table-header'>
                {headCellsSeat.map((headCell, index) => (
                    <TableCell
                        // className='site-table-header'
                        key={index}
                        className={classes.table_cell}
                        align={'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHeadSeat.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    // onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        margin: 'auto',
        marginTop: '1%'
    },
    paper: {
        width: '98%',
        margin: 'auto',
        marginTop: '1%'
    },
    paperseat: {
        width: '98%',
        margin: 'auto',
        marginTop: '1%'
    },
    table: {
        // minWidth: 600,
        width: '100%',
        whiteSpace: 'nowrap',
    },
    table_cell: {
        justifyContent: 'center',
        // textAlign: 'left',
        paddingLeft: '5px',
        paddingRight: '5px',
    },
    first_cell: {

        justifyContent: 'center',
        // textAlign: 'left',
        paddingLeft: '15px',
        paddingRight: '5px',

    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
        float: 'left',
    },
    inputSearch: {
        width: '80%',
    },
}));

export default function BookingMain(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [availableSites, setAvailableSites] = useState([])
    const [availableSeatBooking, setAvailableSeatBooking] = useState([])
    const [selected] = React.useState([]);
    const selectedSite = props.commonState.site;
    const [page, setPage] = React.useState(0);
    const [bookedBy, setbookedBy] = React.useState([]);
    const [all_users, setAll_users] = React.useState([]);
    const [bookedOnFrom, setbookedOnFrom] = React.useState();
    const [bookedOnTo, setbookedOnTo] = React.useState();
    const [selectedbookedBy, setselectedbookedBy] = React.useState('');
    // const [currency, setCurrency] = React.useState('');
    const blockCategory = [{ value: 'Seat Bookings', label: 'Seat Bookings' },
    { value: 'Block Bookings', label: 'Block Bookings' }]
    // const [dense, setDense] = React.useState(true);
    const dense = true;
    const [loading, setLoading] = useState(false)
    const [selectedBookingType, setselectedBookingType] = React.useState('Seat Bookings');
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const handleDateChangeFrom = (date) => {
        // console.log(date)
        let date3 = Date.parse(date.target.value);
        let current_date = new Date(date3);
        let currentDate = new Date(
            current_date.getFullYear(),
            current_date.getMonth(),
            current_date.getDate()
        );
        const curr_date_timestamp = firebase.firestore.Timestamp.fromDate(currentDate);
        setbookedOnFrom(curr_date_timestamp)
    };

    const handleDateChangeTo = (date) => {
        let date3 = Date.parse(date.target.value);
        let current_date = new Date(date3);
        let currentDate = new Date(
            current_date.getFullYear(),
            current_date.getMonth(),
            current_date.getDate()
        );
        const curr_date_timestamp = firebase.firestore.Timestamp.fromDate(currentDate);
        setbookedOnTo(curr_date_timestamp)
    };

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (fileName) => {
        var csvData = [];
        if (selectedBookingType === 'Block Bookings') {
            csvData = availableSites;
        } else {
            csvData = availableSeatBooking;
        }
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    const state_bar = {
        labels: ['January', 'February', 'March',
            'April', 'May'],
        datasets: [
            {
                label: 'Rainfall',
                backgroundColor: 'red',
                borderWidth: 0,
                data: [65, 59, 80, 81, 56]
            },
            {
                label: 'Rainfall',
                backgroundColor: 'blue',
                borderWidth: 0,
                data: [65, 59, 80, 81, 56]
            }
        ]
    }

    const block_bookings_ref = firebase.firestore().collection('BlockBookings/' + selectedSite + '/BlockBookings');
    const seat_booking_ref = firebase.firestore().collection('SeatBookings/' + props.commonState.site + '/SeatBookings');
    const users_ref = firebase.firestore().collection('Users');

    useEffect(() => {
        users_ref
            .where("site", "==", selectedSite)
            .get()
            .then(
                querySnapshot => {
                    const temp_arr = []
                    const temp_arr_users = []
                    querySnapshot.forEach(doc => {
                        // console.log(doc.id)
                        // const site = doc.id;
                        const temp_data = doc.data();
                        if (temp_data.isBlockAdmin === true) {
                            temp_arr.push({ value: temp_data.userName, label: temp_data.userName });
                        }
                        temp_arr_users.push({ value: temp_data.userName, label: temp_data.userName });
                    }
                    );
                    setbookedBy([...temp_arr])
                    setAll_users([...temp_arr_users])
                }
            )
    }, []);

    const fetchResults = () => {

        setLoading(true)

        if (selectedBookingType === 'Block Bookings') {

            const b_booked_by_filter = selectedbookedBy ? block_bookings_ref.where("blocked_by", "==", selectedbookedBy) : block_bookings_ref;
            const b_booked_on_from_filter = bookedOnFrom ? b_booked_by_filter.where("blocked_date", ">=", bookedOnFrom) : b_booked_by_filter;
            const b_booked_on_to_filter = bookedOnTo ? b_booked_on_from_filter.where("blocked_date", "<=", bookedOnTo) : b_booked_on_from_filter;


            b_booked_on_to_filter
                .get()
                .then(
                    querySnapshot => {
                        const tempSites = []
                        querySnapshot.forEach(doc => {
                            // const site = doc.id;
                            const temp_data = doc.data();
                            // console.log(temp_data); 
                            let date1 = temp_data.blocked_date.toDate().toLocaleDateString();
                            let date2 = temp_data.blocked_on.toDate().toLocaleDateString();
                            tempSites.push(createData(temp_data.blocked_by, temp_data.blocked_by_email,
                                date1, date2,
                                temp_data.site, temp_data.floor, temp_data.wing,
                                temp_data.seats_blocked, temp_data.seats_confirmed));
                        });
                        setAvailableSites([...tempSites])
                        setLoading(false)
                    })

        }
        else {
            // console.log(bookedOnTo)
            // console.log(bookedOnFrom)
            // console.log(selectedbookedBy)
            const booked_by_filter = selectedbookedBy ? seat_booking_ref.where("booked_by", "==", selectedbookedBy) : seat_booking_ref;
            const booked_on_from_filter = bookedOnFrom ? booked_by_filter.where("blocked_date", ">=", bookedOnFrom) : booked_by_filter;
            const booked_on_to_filter = bookedOnTo ? booked_on_from_filter.where("blocked_date", "<=", bookedOnTo) : booked_on_from_filter;

            

            booked_on_to_filter
                .get()
                .then(
                    querySnapshot => {
                        const tempSites = []
                        querySnapshot.forEach(doc => {
                            const temp_seat_id = doc.id;
                            const temp_data = doc.data();
                            let date1 = temp_data.blocked_date.toDate().toLocaleDateString();
                            let parking_no = '';
                            if(temp_data.parking){
                                parking_no = temp_data.parking;
                            }
                            console.log(parking_no)
                            tempSites.push(createDataSeat(temp_seat_id, temp_data.booked_by, temp_data.booked_by_email, temp_data.blocked_by, date1,
                                temp_data.booked_ws, parking_no, temp_data.category, temp_data.booking_status, temp_data.site, temp_data.floor, temp_data.wing, temp_data.blocked_date.toDate()));
                        });
                        setAvailableSeatBooking([...tempSites])
                        setLoading(false)
                    })
        }
    };

    const getMonth = {
        0: 'Jan',
        1: 'Feb',
        2: 'Mar',
        3: 'Apr',
        4: 'May',
        5: 'Jun',
        6: 'Jul',
        7: 'Aug',
        8: 'Sep',
        9: 'Oct',
        10: 'Nov',
        11: 'Dec',
    };

    const get_day = {
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
    };

    const printDate = (date) => {
        let blocked_date_date;
        let blocked_date = new Date(date)
        if (blocked_date) {
            if (blocked_date.getDate() < 10) {
                blocked_date_date = "0" + blocked_date.getDate().toString();
            } else {
                blocked_date_date = blocked_date.getDate().toString();
            }

            const blocked_date_month = getMonth[blocked_date.getMonth()];

            return (
                get_day[blocked_date.getDay()] +
                ", " +
                blocked_date_date +
                "-" +
                blocked_date_month
            );
        }
    }

    const deleteResults = () => {

        setLoading(true)

        if (selectedBookingType === 'Block Bookings') {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="custom-ui confirm_dialog">
                            <div className="confirm_title">Validation!</div>
                            <div className="confirm_msg">Block Bookings cannot be deleted.</div>
                            <div className="confirm_buttons">
                                <button className="confirm_yes" onClick={onClose}>
                                    OK
                                </button>
                            </div>
                        </div>
                    );
                },
            });
            setLoading(false)

        }
        else {
            setLoading(false)
            if (availableSeatBooking.length > 0) {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className="custom-ui confirm_dialog">
                                <div className="confirm_title">Confirm!</div>
                                <div className="confirm_msg">
                                    Are you sure you want to delete the selected {availableSeatBooking.length} bookings?
                                </div>
                                <div className="confirm_buttons">
                                    <button className="confirm_no" onClick={onClose}>
                                        No
                                    </button>
                                    <button
                                        className="confirm_yes"
                                        onClick={() => {
                                            onClose();
                                            setLoading(true)
                                            availableSeatBooking.forEach((seat) => {

                                                var bookingDetails = {
                                                    date: printDate(seat.blocked_date),
                                                    floor: seat.floor,
                                                    ws: seat.ws,
                                                }

                                                var temp_state = {
                                                    userEmail: seat.email,
                                                    currentDate: new Date()
                                                }

                                                seat_booking_ref
                                                    .doc(seat.seat_id)
                                                    .delete()
                                                    .then(function () {
                                                        sendEmail('Booking Cancelled by Site Admin', seat.email, "", bookingDetails, temp_state)
                                                        setLoading(false);
                                                    })
                                                    .catch(function (error) {
                                                        console.error("Error Cancelling Booking: ", error);
                                                    });
                                            });
                                            setLoading(true)
                                            fetchResults()
                                            setLoading(false)
                                        }}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        );
                    },
                });
            }
            else {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className="custom-ui confirm_dialog">
                                <div className="confirm_title">Validation!</div>
                                <div className="confirm_msg">No bookings selected for deletion! Please select the booking criteria and click Search</div>
                                <div className="confirm_buttons">
                                    <button className="confirm_yes" onClick={onClose}>
                                        OK
                                    </button>
                                </div>
                            </div>
                        );
                    },
                });
            }
            setLoading(false)
        }
    };



    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const Block_table = () => {
        if (selectedBookingType === 'Block Bookings') {
            return (
                <Paper className={classes.paper}>

                    <TableContainer>
                        <Table

                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                            aria-label="enhanced table"
                        >
                            <colgroup>
                                <col width="15%" />
                                <col width="15%" />
                                <col width="10%" />
                                <col width="10%" />
                                <col width="10%" />
                                <col width="10%" />
                                <col width="10%" />
                                <col width="10%" />
                                <col width="10%" />
                            </colgroup>
                            <EnhancedTableHead
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={availableSites.length}
                            />
                            <TableBody>
                                {stableSort(availableSites, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row.name);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                // onClick={(event) => handleClick(event, row.name)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={index}
                                                selected={isItemSelected}
                                            >
                                                <TableCell className={classes.first_cell} component="th" id={labelId} scope="row" padding="none">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell className={classes.table_cell} padding="none">
                                                    {row.email}
                                                </TableCell>
                                                <TableCell className={classes.table_cell} padding="none">
                                                    {row.date}
                                                </TableCell>
                                                <TableCell className={classes.table_cell} padding="none">
                                                    {row.blocked_on}
                                                </TableCell>
                                                <TableCell className={classes.table_cell} padding="none">
                                                    {row.site}
                                                </TableCell>
                                                <TableCell className={classes.table_cell} padding="none">
                                                    {row.floor}
                                                </TableCell>
                                                <TableCell className={classes.table_cell} padding="none">
                                                    {row.wing}
                                                </TableCell>
                                                <TableCell align="center" padding="none">
                                                    {row.booked_seats}
                                                </TableCell>
                                                <TableCell align="center" padding="none">
                                                    {row.confirm_seats}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        component="div"
                        count={availableSites.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>


            )
        }
        else {
            return (
                <Paper className={classes.paperseat}>

                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                            aria-label="enhanced table"
                        >
                            <colgroup>
                                <col width="09%" />
                                <col width="09%" />
                                <col width="09%" />
                                <col width="09%" />
                                <col width="09%" />
                                <col width="09%" />
                                <col width="09%" />
                                <col width="09%" />
                                <col width="09%" />
                            </colgroup>
                            <EnhancedTableHeadSeat
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={availableSeatBooking.length}
                            />
                            <TableBody>
                                {stableSort(availableSeatBooking, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row.name);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                // onClick={(event) => handleClick(event, row.name)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={index}
                                                selected={isItemSelected}
                                            >
                                                <TableCell className={classes.first_cell} component="th" id={labelId} scope="row" padding="none">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell className={classes.table_cell} padding="none">
                                                    {row.email}
                                                </TableCell>
                                                <TableCell className={classes.table_cell} padding="none">
                                                    {row.admin}
                                                </TableCell>
                                                <TableCell className={classes.table_cell} padding="none">
                                                    {row.date}
                                                </TableCell>
                                                <TableCell align="center" padding="none">
                                                    {row.ws}
                                                </TableCell>
                                                <TableCell align="center" padding="none">
                                                    {row.parking_no}
                                                </TableCell>
                                                <TableCell className={classes.table_cell} padding="none">
                                                    {row.cat}
                                                </TableCell>
                                                <TableCell className={classes.table_cell} padding="none">
                                                    {row.status}
                                                </TableCell>
                                                <TableCell className={classes.table_cell} padding="none">
                                                    {row.site}
                                                </TableCell>
                                                <TableCell className={classes.table_cell} padding="none">
                                                    {row.floor}
                                                </TableCell>
                                                <TableCell className={classes.table_cell} padding="none">
                                                    {row.wing}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 50, 100]}
                        component="div"
                        count={availableSeatBooking.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>


            )
        }

    }

    // const handleClick = (event, name) => {
    //     const selectedIndex = selected.indexOf(name);
    //     let newSelected = [];

    //     if (selectedIndex === -1) {
    //         newSelected = newSelected.concat(selected, name);
    //     } else if (selectedIndex === 0) {
    //         newSelected = newSelected.concat(selected.slice(1));
    //     } else if (selectedIndex === selected.length - 1) {
    //         newSelected = newSelected.concat(selected.slice(0, -1));
    //     } else if (selectedIndex > 0) {
    //         newSelected = newSelected.concat(
    //             selected.slice(0, selectedIndex),
    //             selected.slice(selectedIndex + 1),
    //         );
    //     }

    //     setSelected(newSelected);
    // };

    // const handleChange = (event) => {
    //     console.log('Change Site');
    //     console.log(event.target.value);
    //     setCurrency(event.target.value);
    // };

    const handleChangeBookedBy = (event) => {
        // console.log(event.target.value);
        setselectedbookedBy(event.target.value);
    };

    const handleChangeBookedByName = (event, value) => {
        // console.log(event.target.value);
        // console.log(value);
        setselectedbookedBy(value);
    };

    const handleChangeType = (event) => {
        setselectedBookingType(event.target.value)
        setAvailableSites([])
        setAvailableSeatBooking([])
    }

    const display_refined_filter = () => {
        if (selectedBookingType === 'Block Bookings') {
            return (
                <div className="display-filter-item">
                    <TextField
                        id="standard-select-currency"
                        select
                        label="Booked by"
                        className="display-filter-item"
                        // style={{ width: '80%', margin: '5px', marginTop: '20px', }}
                        onChange={handleChangeBookedBy}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    // helperText="Mode of Booking"
                    >
                        {bookedBy.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
            )
        }
        else {
            return (
                <div className="display-filter-item">
                    <Autocomplete
                        freeSolo
                        id="free-solo-2-demo"
                        disableClearable
                        onInputChange={handleChangeBookedByName}
                        options={all_users.map((option) => option.value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                id="standard-select-currency"
                                label="Booked by"
                                className="display-filter-item"
                                // style={{ width: '80%', margin: '5px', marginTop: '20px', }}
                                // onChange={handleChangeBookedByName}
                                // label="Search input"
                                // margin="normal"
                                // variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{ ...params.InputProps, type: 'search' }}
                            />
                        )}
                    />
                    {/* <TextField
                    id="standard-select-currency"
                    label="Booked by"
                    className="display-filter-item"
                    // style={{ width: '80%', margin: '5px', marginTop: '20px', }}
                    onChange={handleChangeBookedByName}
                >
                </TextField> */}
                </div>
            )

        }
    }

    const loadfilteroptions = () => {
        return (

            <Paper className="booking-items-admin-db">
                {/* <Paper  > */}
                <div className="display-filter-item">
                    <TextField disabled id="site" label="Site" defaultValue={selectedSite} />
                    {/* <TextField
                        id="standard-select-currency"
                        select
                        label="Site"
                        className="display-filter-item"
                        // style={{ width: '80%', margin: '5px', marginTop: '20px', }}
                        onChange={handleChange}
                        // helperText="Mode of Booking"
                    >
                        {blockCategory.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField> */}
                </div>
                <div className="display-filter-item">
                    <TextField
                        id="standard-select-currency"
                        select
                        value={selectedBookingType}
                        label="Booking Type"
                        className="display-filter-item"
                        // defaultValue={'Seat Bookings'}

                        // style={{ width: '80%', margin: '5px', marginTop: '20px', }}
                        onChange={(e) => { handleChangeType(e) }}
                    // helperText="Mode of Booking"
                    >
                        {blockCategory.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                {display_refined_filter()}
                {/* <div className="display-filter-item">
                    <TextField
                        id="standard-select-currency"
                        select
                        label="Booked by"
                        className="display-filter-item"
                        // style={{ width: '80%', margin: '5px', marginTop: '20px', }}
                        onChange={handleChangeBookedBy}
                    // helperText="Mode of Booking"
                    >
                        {bookedBy.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div> */}

                <div className="display-filter-item">

                    <TextField
                        id="date"
                        label="Date (From)"
                        type="date"
                        onChange={(e) => handleDateChangeFrom(e)}
                        // className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
                <div className="display-filter-item">

                    <TextField
                        id="date"
                        label="Date (To)"
                        type="date"
                        onChange={(e) => handleDateChangeTo(e)}
                        // defaultValue={materialDateInput}
                        // className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
                {/* <div className='display-filter-item'>
                    <button
                        onClick={(e) => fetchResults(e)}
                        className='field-style reserve-button'>
                        Search
                    </button>
                </div> */}
                <div className='display-filter-item-icon-admin-db1' onClick={(e) => fetchResults(e)}>
                    <Tooltip title="Search">
                        <IconButton sx={{ p: '5px' }} aria-label="Search">
                            <SearchIcon />
                        </IconButton>
                    </Tooltip>

                </div>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <div className='display-filter-item-icon-admin-db' onClick={(e) => exportToCSV("export-data")}>
                    <Tooltip title="Download Data">
                        <IconButton sx={{ p: '5px' }} aria-label="Export Results">
                            <DownloadIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <div className='display-filter-item-icon-admin-db' onClick={(e) => deleteResults(e)}>
                    <Tooltip title="Delete Seat Bookings">
                        <IconButton sx={{ p: '5px' }} aria-label="Delete Bookings">
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                {/*
                <div className='display-filter-item-icon-admin-db' >
                <Tooltip title="Pie Chart">
                        <IconButton sx={{ p: '5px' }} aria-label="Export Results">
                            <PieChartIcon  /> 
                        </IconButton>
                        </Tooltip>
                </div> */}

            </Paper>
            // </div>

        )
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    return (
        <div className={classes.root}>
            <Loader loading={loading}></Loader>
            {loadfilteroptions()}
            {Block_table()}
            {/* <div className='booking-admin-line'>
            <Bar
                data={state_bar}
                options={{
                    responsive: true,
                    plugins: {
                      title: {
                        display: true,
                        text: 'Bar Chart',
                      },
                    },
                }}
          />
          </div> */}
        </div>
    );
}
