import React, { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
// import AddAttr from '@material-ui/icons/add_comment';
import { firebase } from '../../../config/firebase';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import warningIcon from './../../../Assets/Images/warning.png';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
// import SaveIcon from '@material-ui/icons/Save';
import { confirmAlert } from 'react-confirm-alert';
// import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Loader from '../../Loader/loader';
import './site.css';

export default function SiteNewFloor(props) {
    const [sitename, setSiteName] = React.useState(props.commonState.site);
    const [site_floor, setsite_floor] = React.useState(['']);
    // const [test, setTest] = useState({ '8th-floor': ['WS789', 'WS790', 'WS799'] })
    const [floor_seats_range_start, setfloor_seats_range_start] = React.useState(['']);
    const [floor_seats_range_end, setfloor_seats_range_end] = React.useState([0]);
    // const [floor_cubicle_range_start, setfloor_cubicle_range_start] = React.useState(['']);
    const [floor_cubicle_range_end, setfloor_cubicle_range_end] = React.useState([''])
    const [car_parking_slots, set_car_parking_slots] = React.useState('');
    // const [newSiteFloors, setNewSiteFloors] = useState({ 'floors': {} });
    const newSiteFloors = { 'floors': {} };
    const [edit_flag, setedit_flag] = useState(true)
    const [enable_floor, set_enable_floor] = useState()
    const [booking_days_block, setbooking_days_block] = useState(1);
    const [booking_days_ind, setbooking_days_ind] = useState(1);
    const [decl, set_decl] = useState(false);
    const [pdecl, set_pdecl] = useState(false);
    const [is_site_up, set_is_site_up] = useState(true);
    const [dire, set_dire] = useState(false);
    const [autob, set_autob] = useState(false);
    const [team_size_enable, set_team_size_enable] = useState(false);
    const [carb, set_carb] = useState(false);
    // const [carp, set_carp] = useState(false);
    // const [new_site, set_new_site] = useState('');
    const [onlySites, setonlySites] = useState([])
    // const [delete_site, set_delete_site] = useState('');
    // const [temp_floors, settemp_floors] = useState(['8th-floor', '9th-floor'])
    // const [currency, setCurrency] = React.useState(props.commonState.site);
    const [loading, setLoading] = useState(false);
    const formRef = React.useRef();
    const [availableSites, setAvailableSites] = useState([])
    const [refresh, setRefresh] = useState(false);
    const [bookingFrequency, setBookingFrequency] = useState()
    const [pdf_file, setPdfImage] = useState('');
    // const [user_details, set_user_details] = useState(props.commonState.userName);
    const user_details = props.commonState.userName;
    const sites_ref = firebase.firestore().collection("Sites");
    let temp_site = ''
    let temp_arr_sites = []

    const [organization, setOrganization] = useState();
    const [ws_attributes, set_ws_attributes] = useState();
    const [tnc, set_tnc] = useState("");
    const [site_down_text, set_site_down_text] = useState("");
    const [booking_calendar_from, setBookingCalendarFrom] = React.useState();
    const [booking_calendar_to, setBookingCalendarTo] = React.useState();

    const getMonth = {
        0 : "01",
        1 : "02",
        2 : "03",
        3 : "04",
        4 : "05",
        5 : "06",
        6 : "07",
        7 : "08",
        8 : "09",
        9 : "10",
        10: "11",
        11: "12",
    }

    const format_date = (int_ts) => {
        let ext_dt;
        let temp_d = new Date(int_ts.toDate())
        let temp_month = temp_d.getMonth()
        let temp_day = temp_d.getDate() < 10 ? '0' + temp_d.getDate().toString() : temp_d.getDate().toString()

        ext_dt = temp_d.getFullYear().toString() + '-' + getMonth[temp_month] + '-' + temp_day;
        return ext_dt;
    }

    const format_dt_to_ts = (cal_date) => {
        let current_date = new Date(cal_date);
        let currentDate = new Date(
            current_date.getFullYear(),
            current_date.getMonth(),
            current_date.getDate()
        );
        const curr_date_timestamp = firebase.firestore.Timestamp.fromDate(currentDate);
        return curr_date_timestamp;
    }

    useEffect(() => {
        setLoading(true)
        sites_ref
            .get()
            .then(
                querySnapshot => {
                    const tempSites = []
                    querySnapshot.forEach(doc => {
                        const site = doc.id;
                        tempSites.push({ value: site, label: site });
                        temp_arr_sites.push(site);
                    }
                    );
                    setAvailableSites([...tempSites])
                    setonlySites([...temp_arr_sites])
                    // setLoading(true)
                }
            )
        sites_ref
            .doc(sitename)
            .get()
            .then(doc => {
                if (!doc.exists) {
                    // console.log('No doc found');
                } else {
                    let new_floor_state = {};
                    // console.log(doc.data())
                    new_floor_state = { ...doc.data() }
                    // console.log(new_floor_state)
                    if (new_floor_state.advance_booking_period_blockAdmin) {
                        setbooking_days_block(new_floor_state.advance_booking_period_blockAdmin)
                    } else {
                        setbooking_days_block(1)
                    }
                    if (new_floor_state.advance_booking_period_individual) {
                        setbooking_days_ind(new_floor_state.advance_booking_period_individual)
                    } else {
                        setbooking_days_ind(1)
                    }
                    if (new_floor_state.bookingFrequency) {
                        setBookingFrequency(new_floor_state.bookingFrequency)
                    } else {
                        setBookingFrequency(1)
                    }
                    if (new_floor_state.need_declaration) {
                        set_decl(new_floor_state.need_declaration)
                    } else {
                        set_decl(false)
                    }
                    if (new_floor_state.need_declaration_profile) {
                        set_pdecl(new_floor_state.need_declaration_profile)
                    } else {
                        set_pdecl(false)
                    }
                    if (new_floor_state.is_site_up) {
                        set_is_site_up(new_floor_state.is_site_up)
                    } else {
                        set_is_site_up(false)
                    }
                    if (new_floor_state.direct_booking_enabled) {
                        set_dire(new_floor_state.direct_booking_enabled)
                    } else {
                        set_dire(false)
                    }
                    if (new_floor_state.auto_booking_enabled) {
                        set_autob(new_floor_state.auto_booking_enabled)
                    } else {
                        set_autob(false)
                    }
                    if (new_floor_state.block_booking_team_size) {
                        set_team_size_enable(new_floor_state.block_booking_team_size)
                    } else {
                        set_team_size_enable(false)
                    }                    
                    if (new_floor_state.car_parking_enabled) {
                        set_carb(new_floor_state.car_parking_enabled)
                        if (new_floor_state.parking.length > 0) {
                            let parking_slots = [...new_floor_state.parking]
                            let ws_parking = parking_slots.join("\n")
                            set_car_parking_slots(ws_parking)
                        } else {
                            set_car_parking_slots('')
                        }
                    } else {
                        set_carb(false)
                    }
                    if (new_floor_state.seat_attributes.length > 0) {
                        let temp_seat_attributes = [...new_floor_state.seat_attributes]
                        let seat_attributes_string = temp_seat_attributes.join("\n")
                        set_ws_attributes(seat_attributes_string)
                    } else {
                        set_ws_attributes('')
                    }

                    if (new_floor_state.organization && new_floor_state.organization.length > 0) {
                        let temp_org = [...new_floor_state.organization]
                        let org_string = temp_org.join("\n")
                        setOrganization(org_string)
                    } else {
                        setOrganization('')
                    }

                    if (new_floor_state.tnc && new_floor_state.tnc !== '') {
                        set_tnc(new_floor_state.tnc)
                    } else {
                        set_tnc('')
                    }

                    if (new_floor_state.site_down_text && new_floor_state.site_down_text !== '') {
                        set_site_down_text(new_floor_state.site_down_text)
                    } else {
                        set_site_down_text('')
                    }

                    if (new_floor_state.booking_calendar_from && new_floor_state.booking_calendar_from !== '') {
                        let ext_dt = format_date(new_floor_state.booking_calendar_from)
                        setBookingCalendarFrom(ext_dt)
                    } else {
                        setBookingCalendarFrom('')
                    }  
                    
                    if (new_floor_state.booking_calendar_to && new_floor_state.booking_calendar_to !== '') {
                        let ext_dt_to = format_date(new_floor_state.booking_calendar_to)
                        setBookingCalendarTo(ext_dt_to)
                    } else {
                        setBookingCalendarTo('')
                    }                      


                    let i;
                    setsite_floor([])
                    let temp_arr = [];
                    let temp_ws_arr = [];
                    let temp_floors = Object.keys(new_floor_state.floors)
                    temp_floors.sort()
                    for (i in temp_floors) {
                        if (new_floor_state.floors) {
                            if (new_floor_state.floors[temp_floors[i]]) {

                                temp_arr.push(temp_floors[i])
                                let ws = Object.keys(new_floor_state.floors[temp_floors[i]])
                                ws.sort();
                                let ws_str = ws.join("\n")

                                temp_ws_arr.push(ws_str)

                            }
                        }
                    }
                    if (temp_arr.length === 0) {
                        temp_arr.push('')
                    }
                    if (temp_ws_arr.length === 0) {
                        temp_ws_arr.push('')
                    }
                    setsite_floor([...temp_arr])
                    setfloor_seats_range_start([...temp_ws_arr])

                    temp_arr = [];
                    temp_ws_arr = [];
                    if (new_floor_state.cubicles_data.floors) {
                        temp_floors = Object.keys(new_floor_state.cubicles_data.floors)
                        temp_floors.sort()
                        for (i in temp_floors) {
                            if (new_floor_state.cubicles_data.floors) {
                                if (new_floor_state.cubicles_data.floors[temp_floors[i]]) {

                                    temp_arr.push(temp_floors[i])
                                    let ws = Object.keys(new_floor_state.cubicles_data.floors[temp_floors[i]])
                                    ws.sort();
                                    let ws_str = ws.join("\n")

                                    temp_ws_arr.push(ws_str)

                                }
                            }
                        }
                        if (temp_arr.length === 0) {
                            temp_arr.push('')
                        }
                        if (temp_ws_arr.length === 0) {
                            temp_ws_arr.push('')
                        }

                        setfloor_cubicle_range_end([...temp_ws_arr])

                    }
                    setfloor_cubicle_range_end([...temp_ws_arr])


                    let tmp_obj = {}
                    if (new_floor_state.enable_floor) {
                        tmp_obj = { ...new_floor_state.enable_floor }
                    }
                    set_enable_floor({ ...tmp_obj })

                    setLoading(false)
                }
            })


    }, [sitename, refresh]);

    // console.log(props.commonState.site)

    const storageRef1 = firebase.storage().ref();


    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
            //  marginLeft: '10px',

        },
        root3: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            padding: '0px',
        },
        paper: {
            width: '45%',
            paddingTop: '10px',
            marginLeft: '20px',
            // marginBottom: theme.spacing(2),

        },
        paper2: {
            width: '45%',
            paddingTop: '10px',
            marginLeft: '2px',
            // marginBottom: theme.spacing(2),

        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            padding: 10,
            float: 'left',
        },
        inputSearch: {
            width: '80%',
            marginLeft: '20px',
        },
        title3: {
            // flex: '1 1 80%',
            paddingLeft: '10px',
            paddingTop: '20px',
            fontFamily: "Roboto",
            fontWeight: 600,
            lineHeight: 1.167,
            letterSpacing: '0em',
            fontSize: '20px'
        },

        title4: {
            // flex: '1 1 80%',
            //   paddingLeft: '80px',
            paddingTop: '10px',
            //   fontFamily: "Roboto",
            fontWeight: 'bold',
            lineHeight: 1.167,
            letterSpacing: '0em',
            fontSize: '16px',
            textAlign: 'left'
        },

        title5: {
            // flex: '1 1 80%',
            paddingLeft: '40px',
            fontFamily: "Roboto",
            fontWeight: 400,
            lineHeight: 1.167,
            letterSpacing: '0em',
            fontSize: '14px',
            textAlign: 'left'
        },
        paper1: {
            position: 'absolute',
            width: 400,
            backgroundColor: theme.palette.background.paper,

            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        root1: {
            '& > *': {
                margin: theme.spacing(1),
                width: '25ch',
            },
        },
        rowC:
        {
            display: 'flex',
            flexDirection: 'row'
        }

    }));

    const classes = useStyles();


    const addfloor = () => {
        if (!edit_flag) {
            let temp_floor_arr = [...site_floor];
            let temp_seat_start_arr = [...floor_seats_range_start];
            let temp_seat_start_end = [...floor_seats_range_end];
            temp_floor_arr.push('');
            setsite_floor([...temp_floor_arr]);

            temp_seat_start_arr.push('');
            setfloor_seats_range_start([...temp_seat_start_arr]);

            temp_seat_start_end.push('');
            setfloor_seats_range_end([...temp_seat_start_end]);
        }
    }

    const UploadUserGuide = (e) => {
        if (!edit_flag) {
            storageRef1.child('user_guide.pdf').put(pdf_file).then((snapshot) => {
            });
        }
    }

    const ViewUserGuide = (e) => {

        var starsRef = storageRef1.child('user_guide.pdf');
        starsRef.getDownloadURL()
            .then((url) => {
                console.log(url)
                window.open(url).focus();
            })
            .catch((error) => {
                alert(
                    "Error opening file"
                );
            });

    }

    const handleDateChangeFrom = (date) => {
        setBookingCalendarFrom(date.target.value)
        let current_date = new Date(date.target.value);
        let currentDate = new Date(
            current_date.getFullYear(),
            current_date.getMonth(),
            current_date.getDate()
        );
        const curr_date_timestamp = firebase.firestore.Timestamp.fromDate(currentDate);
        // console.log(curr_date_timestamp)

    };    

    const handleDateChangeTo = (date) => {
        setBookingCalendarTo(date.target.value)
    };    

    const saveNewSiteDataConfirm = (e) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui confirm_dialog'>
                        <div className="confirm_title">Confirmation</div>
                        <div className="confirm_msg">Are you sure you want update the site details?</div>
                        <div className="confirm_buttons">
                            <button className="confirm_no" onClick={
                                () => {
                                    setRefresh(!refresh)
                                    setedit_flag(!edit_flag)
                                    onClose()
                                }
                            }>No</button>

                            <button className="confirm_yes"
                                onClick={
                                    () => {
                                        setLoading(true)
                                        saveSiteData()
                                        onClose()
                                    }
                                }
                            >Yes</button>
                        </div>
                    </div>
                );

            }
        })
    }

    const saveSiteData = () => {

        const formValidate = formRef.current.reportValidity();
        let i;
        let j;
        let newSitejson = { ...newSiteFloors };
        newSitejson['cubicles_data'] = {};
        newSitejson['cubicles_data']['floors'] = {};
        for (i in site_floor) {
            newSitejson['floors'][site_floor[i]] = {};
            // for (j = floor_seats_range_start[i]; j <= floor_seats_range_end[i]; j++) {
            //     newSitejson['floors'][site_floor[i]][`WS${j}`] = { "status": "available", "wing": "", "block": "" }
            // }
            var split_str = floor_seats_range_start[i].split("\n");

            for (j in split_str) {
                if (split_str[j] !== '') {
                    newSitejson['floors'][site_floor[i]][split_str[j]] = { "status": "available", "wing": "", "block": "" }
                }
            }

            // Cubicles

            newSitejson['cubicles_data']['floors'][site_floor[i]] = {};
            if (floor_cubicle_range_end[i]) {
                var split_str_c = floor_cubicle_range_end[i].split("\n");
                for (j in split_str_c) {
                    if (split_str_c[j] !== '') {
                        newSitejson['cubicles_data']['floors'][site_floor[i]][split_str_c[j]] = { "status": "available", "wing": "", "block": "" }
                    }
                }
            }


        }
        var car_parking_split_arr = car_parking_slots.split("\n");
        car_parking_split_arr = car_parking_split_arr.map(i => i.trim());
        car_parking_split_arr = car_parking_split_arr.filter(Boolean)

        if (!carb) {
            car_parking_split_arr = []
        }

        // let temp_arr2 = [...test['8th-floor']];
        // console.log(temp_arr2)
        newSitejson['auto_booking_enabled'] = autob;
        newSitejson['direct_booking_enabled'] = dire;
        newSitejson['block_booking_team_size'] = team_size_enable;
        newSitejson['need_declaration'] = decl;
        newSitejson['need_declaration_profile'] = pdecl;
        newSitejson['is_site_up'] = is_site_up;
        newSitejson['car_parking_enabled'] = carb;
        newSitejson['parking'] = [...car_parking_split_arr];
        newSitejson['advance_booking_period_blockAdmin'] = parseInt(booking_days_block);
        newSitejson['advance_booking_period_individual'] = parseInt(booking_days_ind);
        newSitejson['bookingFrequency'] = parseInt(bookingFrequency);
        newSitejson['Site'] = sitename;
        newSitejson['last_updated_by'] = user_details;
        newSitejson['last_updated_on'] = new Date();
        newSitejson['seat_attributes'] = [...ws_attributes.split("\n")]
        newSitejson['organization'] = [...organization.split("\n")]
        newSitejson['tnc'] = tnc;
        newSitejson['site_down_text'] = site_down_text;
        newSitejson['enable_floor'] = { ...enable_floor };

        newSitejson['booking_calendar_from'] = format_dt_to_ts(booking_calendar_from);
        newSitejson['booking_calendar_to'] = format_dt_to_ts(booking_calendar_to);

        // console.log(newSitejson);
        if (formValidate === true) {
            if (!edit_flag) {
                // console.log(edit_flag)
                sites_ref
                    .doc(sitename)
                    .set({
                        ...newSitejson
                    })
                    .then(function (docRef) {
                        // console.log('Site saved');

                        setRefresh(!refresh)
                    })
                    .catch(function (error) {
                        console.error('Error');
                    });
                setedit_flag(true);
            }

        }
    }


    const deletefloor = (index) => {
        if (!edit_flag) {
            let temp_que_arr = [...site_floor];
            let temp_seat_start_arr = [...floor_seats_range_start];
            let temp_seat_start_end = [...floor_seats_range_end];

            temp_que_arr.splice(index, 1);
            setsite_floor([...temp_que_arr]);

            temp_seat_start_arr.splice(index, 1);
            setfloor_seats_range_start([...temp_seat_start_arr]);

            temp_seat_start_end.splice(index, 1);
            setfloor_seats_range_end([...temp_seat_start_end]);
        }
    }

    const handleChange = (e) => {
        // console.log('change');
        const temp_site_name = e.target.value;
        setSiteName(temp_site_name);
        setRefresh(!refresh)
    }

    const handleChangeFloor = (e, index) => {
        const temp_floor_1 = e.target.value;
        let temp_arr = [...site_floor];
        temp_arr[index] = temp_floor_1;
        setsite_floor([...temp_arr]);
    }

    const handleChangeStart = (e, index) => {
        const temp_floor_1 = e.target.value;
        let temp_arr = [...floor_seats_range_start];
        temp_arr[index] = temp_floor_1;
        setfloor_seats_range_start([...temp_arr]);
    }

    const handleChangeStartC = (e, index) => {
        const temp_floor_1 = e.target.value;
        // console.log(temp_floor_1)
        let temp_arr = [...floor_cubicle_range_end];
        // console.log(temp_arr)
        temp_arr[index] = temp_floor_1;
        setfloor_cubicle_range_end([...temp_arr]);
    }

    const handleChangeCarParking = (e) => {
        const temp_floor_1 = e.target.value;
        set_car_parking_slots(temp_floor_1);
    }

    const handle_WS_Attribute_Click = (e) => {
        set_ws_attributes(e.target.value);
    }

    const handle_Org_Click = (e) => {
        setOrganization(e.target.value);
    }

    const handle_file_upload = (e) => {
        const files = e.target.files;
        console.log(files)
        files.length > 0 && setPdfImage(e.target.files[0])
        console.log(URL.createObjectURL(files[0]))
    }

    const handle_tnc_Click = (e) => {
        set_tnc(e.target.value);
    }

    const handle_set_site_down_text = (e) => {
        set_site_down_text(e.target.value)
    }

    // const handleChangeEnd = (e, index) => {
    //     const temp_floor_1 = e.target.value;
    //     let temp_arr = [...floor_seats_range_end];
    //     temp_arr[index] = temp_floor_1;
    //     setfloor_seats_range_end([...temp_arr]);
    // }

    const cancelChanges = (e) => {
        setRefresh(!refresh)
        setedit_flag(true)
    }

    const display_car_parking = () => {
        if (carb) {
            return (
                <div>
                    <TextField
                        value={car_parking_slots}
                        required
                        disabled={edit_flag}
                        multiline
                        rows={5}
                        onChange={(e) => handleChangeCarParking(e)}
                        style={{ width: '100%', margin: '5px' }}
                        label="Car Parking Slot Numbers" variant="outlined"
                        helperText="Enter each value on new line" />
                </div>

            )
        }
    }

    const display_button = () => {
        if (!edit_flag) {
            return (
                <div>

                    <Button variant="contained" onClick={(e) => { cancelChanges(e) }}                         // className="save_button"
                        className='zfield-style'
                        style={{ backgroundColor: '#b0abab', color: 'white', fontWeight: 'bold', marginRight: '2%' }}
                    >
                        CANCEL
                    </Button>
                    <Button variant="contained" onClick={saveNewSiteDataConfirm}
                        // className="save_button"
                        className='zfield-style site-button'
                        style={{ backgroundColor: '#d01837', color: 'white', fontWeight: 'bold' }}
                    >
                        UPDATE
                    </Button>


                </div>

            )
        }
    }

    const enable_disable_floor = (floor) => {
        let temp_enable_floor = { ...enable_floor }
        temp_enable_floor[floor] = !temp_enable_floor[floor]

        set_enable_floor({ ...temp_enable_floor })
    }

    const check_site_down = () => {
        if (!is_site_up) {
            return (
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', }}>
                        <Typography className={classes.title4} color="inherit" variant="subtitle1" component="div">
                            Text to be displayed when Site is Down
                        </Typography>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', }}>
                        {/* <div> */}
                        <TextField
                            value={site_down_text}
                            disabled={edit_flag}
                            multiline
                            rows={5}
                            onChange={(e) => handle_set_site_down_text(e)}
                            style={{ width: '100%', margin: '5px' }}
                            // label="Terms and conditions" 
                            variant="outlined"
                        />
                        {/* </div> */}
                    </div>
                </div>
            )
        }
    }

    const display_cal_dates = () => {
        return (
            <div style={{ margin: 'auto' }}>
                <br />
                <div style={{ display: 'flex' }}>
                    <TextField
                        id="date"
                        value={booking_calendar_from}
                        label="Enable Booking Calendar (From)"
                        type="date"
                        disabled={edit_flag}
                        onChange={(e) => handleDateChangeFrom(e)}
                        style={{ padding: '8px', width: '35%' }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField
                        id="date"
                        label="Enable Booking Calendar (To)"
                        type="date"
                        value={booking_calendar_to}
                        disabled={edit_flag}
                        onChange={(e) => handleDateChangeTo(e)}
                        style={{ padding: '8px', width: '35%' }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                </div>
                <br />
            </div>
        )
    }


    const display_data = () => {
        if (enable_floor) {
            return (
                <div>
                    <div style={{ margin: 'auto', width: '80%' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', }}>
                            <Typography className={classes.title4} color="inherit" variant="subtitle1" component="div">
                                Site up?
                            </Typography>
                            <Switch checked={is_site_up} disabled={edit_flag} onChange={(e) => set_is_site_up(!is_site_up)} />
                        </div>
                        {check_site_down()}
                        {display_cal_dates()}
                    </div>
                    <br />
                    {site_floor.map((floor, index) => {
                        // console.log("site_floor[index]=>", enable_floor[site_floor[index]])
                        return (
                            <div key={index} style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', flex: 2, justifyContent: 'start', paddingLeft: '5%' }}>
                                    <TextField
                                        value={site_floor[index]}
                                        required
                                        disabled={edit_flag}
                                        onChange={(e) => handleChangeFloor(e, index)}
                                        style={{ width: '70%', margin: '5px' }}
                                        label="Floor No" variant="outlined" />
                                    <Switch checked={enable_floor[site_floor[index]]} disabled={edit_flag} onChange={(e) => { enable_disable_floor(site_floor[index]) }} />
                                </div>
                                <div style={{ display: 'flex', flex: 2, justifyContent: 'center' }}>
                                    <TextField
                                        value={floor_seats_range_start[index]}
                                        required
                                        disabled={edit_flag}
                                        multiline
                                        rows={5}
                                        onChange={(e) => handleChangeStart(e, index)}
                                        style={{ margin: '5px' }}
                                        label="Seat Numbers" variant="outlined"
                                        helperText="Enter each value on new line" />
                                    <TextField
                                        value={floor_cubicle_range_end[index]}
                                        // required
                                        disabled={edit_flag}
                                        multiline
                                        rows={5}
                                        onChange={(e) => handleChangeStartC(e, index)}
                                        style={{ margin: '5px' }}
                                        label="Cubicle Numbers" variant="outlined"
                                        helperText="Enter each value on new line" />
                                </div>
                                <div style={{ display: 'flex', flex: 1, justifyContent: 'start', flexDirection: 'column' }}>
                                    {index === 0 ?
                                        (
                                            <div onClick={(e) => { addfloor(e, index) }}>
                                                <Tooltip title="Add floor">
                                                    <IconButton aria-label="Add floor">
                                                        <AddIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        ) : (
                                            <div onClick={(e) => { deletefloor(e, index) }}>
                                                <Tooltip title="Delete floor">
                                                    <IconButton aria-label="delete floor">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        )}
                                </div>
                            </div>
                        )
                    })}
                    <br />
                    <div style={{ margin: 'auto', width: '80%' }}>
                        <div style={{ display: 'flex' }}>
                            <TextField
                                value={booking_days_block}
                                required
                                disabled={edit_flag}
                                type="number"
                                onChange={(e) => setbooking_days_block(e.target.value)}
                                style={{ padding: '8px' }}
                                InputProps={{
                                    inputProps: {
                                        min: 0
                                    }
                                }}
                                label="Advance Booking Period for Block Admins (Days)" />
                            <div style={{ margin: 'auto', width: '5%' }}></div>
                            <TextField
                                value={booking_days_ind}
                                required
                                disabled={edit_flag}
                                type="number"
                                onChange={(e) => setbooking_days_ind(e.target.value)}
                                style={{ padding: '8px' }}
                                InputProps={{
                                    inputProps: {
                                        min: 0
                                    }
                                }}
                                label="Advance Booking Period for Individual (Days)" />


                            <div style={{ margin: 'auto', width: '5%' }}></div>
                            <TextField
                                value={bookingFrequency}
                                required
                                disabled={edit_flag}
                                type="number"
                                onChange={(e) => setBookingFrequency(e.target.value)}
                                style={{ padding: '8px' }}
                                InputProps={{
                                    inputProps: {
                                        min: 0
                                    }
                                }}
                                InputLabelProps={{ shrink: true }}
                                label="Booking Frequency (Days)" />
                        </div>
                        <br />


                        <div style={{ display: 'flex', flexDirection: 'row', }}>
                            <Typography className={classes.title4} color="inherit" variant="subtitle1" component="div">
                                Enable Health Declaration (Profile)?
                            </Typography>
                            <Switch checked={pdecl} disabled={edit_flag} onChange={(e) => set_pdecl(!pdecl)} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', }}>
                            <Typography className={classes.title4} color="inherit" variant="subtitle1" component="div">
                                Enable Health Declaration (Seat Booking)?
                            </Typography>
                            <Switch checked={decl} disabled={edit_flag} onChange={(e) => set_decl(!decl)} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', }}>
                            <Typography className={classes.title4} color="inherit" variant="subtitle1" component="div">
                                Enable Direct Booking?
                            </Typography>
                            <Switch checked={dire} disabled={edit_flag} onChange={(e) => set_dire(!dire)} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', }}>
                            <Typography className={classes.title4} color="inherit" variant="subtitle1" component="div">
                                Enable Auto booking?
                            </Typography>
                            <Switch checked={autob} disabled={edit_flag} onChange={(e) => set_autob(!autob)} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', }}>
                            <Typography className={classes.title4} color="inherit" variant="subtitle1" component="div">
                                Enable Team Size Restriction for Block Booking?
                            </Typography>
                            <Switch checked={team_size_enable} disabled={edit_flag} onChange={(e) => set_team_size_enable(!team_size_enable)} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', }}>
                            <Typography className={classes.title4} color="inherit" variant="subtitle1" component="div">
                                Enable Car Parking Reservation?
                            </Typography>
                            <Switch checked={carb} disabled={edit_flag} onChange={(e) => set_carb(!carb)} />

                        </div>
                        {display_car_parking()}
                        <div style={{ display: 'flex', flexDirection: 'row', }}>
                            <Typography className={classes.title4} color="inherit" variant="subtitle1" component="div">
                                Workstation Attributes:
                            </Typography>
                            {/* <Switch checked={carb} disabled={edit_flag} onChange={(e) => set_carb(!carb)} /> */}
                            <div>
                                <TextField
                                    value={ws_attributes}
                                    // required
                                    disabled={edit_flag}
                                    multiline
                                    rows={5}
                                    onChange={(e) => handle_WS_Attribute_Click(e)}
                                    style={{ width: '100%', margin: '5px' }}
                                    // label="Workstation Attributes" 
                                    variant="outlined"
                                    helperText="Enter each value on new line" />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', }}>
                            <Typography className={classes.title4} color="inherit" variant="subtitle1" component="div">
                                Organizations:
                            </Typography>
                            {/* <Switch checked={carb} disabled={edit_flag} onChange={(e) => set_carb(!carb)} /> */}
                            <div>
                                <TextField
                                    value={organization}
                                    // required
                                    disabled={edit_flag}
                                    multiline
                                    rows={5}
                                    onChange={(e) => handle_Org_Click(e)}
                                    style={{ width: '100%', margin: '5px' }}
                                    // label="Workstation Attributes" 
                                    variant="outlined"
                                    helperText="Enter each value on new line" />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', }}>
                            <Typography className={classes.title4} color="inherit" variant="subtitle1" component="div">
                                User Guide:
                            </Typography>
                            {/* <Switch checked={carb} disabled={edit_flag} onChange={(e) => set_carb(!carb)} /> */}
                            <div style={{ width: "100%" }}>
                                <TextField type={"file"}
                                    inputProps={{ accept: "application/pdf" }}
                                    disabled={edit_flag}
                                    onChange={(e) => handle_file_upload(e)}
                                />

                                {/* <Button variant="contained"
                                style={{ backgroundColor: '#b0abab', color: 'white', marginRight: "1%"}}
                                disabled={edit_flag} 
                                onClick={(e) => UploadUserGuide(e)}
                            >
                                 Upload
                            </Button> */}
                                <Button variant="contained"
                                    style={{ backgroundColor: '#b0abab', color: 'white' }}
                                    disabled={edit_flag}
                                    onClick={(e) => ViewUserGuide(e)}
                                >
                                    View
                                </Button>
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', }}>
                            <Typography className={classes.title4} color="inherit" variant="subtitle1" component="div">
                                Terms and Conditions for storing Health Declaration (Profile):
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', }}>
                            {/* <div> */}
                            <TextField
                                value={tnc}
                                disabled={edit_flag}
                                multiline
                                rows={5}
                                onChange={(e) => handle_tnc_Click(e)}
                                style={{ width: '100%', margin: '5px' }}
                                // label="Terms and conditions" 
                                variant="outlined"
                            />
                            {/* </div> */}
                        </div>


                    </div>

                </div>
            )
        }
    }

    const handleChangeNewSite = (e) => {
        // console.log(e.target.value)
        // set_new_site(e.target.value)
        temp_site = e.target.value;
        setRefresh(!refresh)
    }

    const saveNewSiteData = (e) => {
        if (temp_site !== '') {
            // console.log('saving')
            let newSitejson = {};
            newSitejson['Site'] = temp_site;
            newSitejson['last_updated_by'] = user_details;
            newSitejson['last_updated_on'] = new Date();
            newSitejson['auto_booking_enabled'] = false;
            newSitejson['direct_booking_enabled'] = false;
            newSitejson['block_booking_team_size'] = true;
            newSitejson['car_parking_enabled'] = false;
            newSitejson['need_declaration'] = false;
            newSitejson['need_declaration_profile'] = false;
            newSitejson['parking'] = [];
            newSitejson['advance_booking_period_blockAdmin'] = 1;
            newSitejson['advance_booking_period_individual'] = 1;
            newSitejson['bookingFrequency'] = 1;
            newSitejson['seat_attributes'] = []
            newSitejson['tnc'] = '';
            newSitejson['booking_calendar_from'] = '';
            newSitejson['booking_calendar_to'] = '';
            newSitejson['floors'] = {};
            newSitejson['cubicles_data'] = {};
            newSitejson['enable_floor'] = { ...enable_floor };
            // console.log(newSitejson)
            sites_ref
                .doc(temp_site)
                .set({
                    ...newSitejson
                })
                .then(function (docRef) {
                    // console.log('Site saved');
                })
                .catch(function (error) {
                    console.error('Error');
                });
            setRefresh(!refresh)
        }
    }

    const display_error_msg = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui confirm_dialog'>
                        <div className="confirm_title">Validation</div>
                        <div className="confirm_msg">
                            <div className="img">
                                <img
                                    alt=''
                                    style={{ width: 50, height: 50 }}
                                    src={warningIcon}
                                />
                            </div>
                            <div className="msg">Site '{temp_site}' already exists</div>
                        </div>

                        <div className="confirm_buttons">
                            <button className="confirm_yes" onClick={onClose}>OK</button>
                        </div>
                    </div>
                );

            }
        })
    }

    const addnewsite = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui confirm_dialog'>
                        <div className="confirm_title">Add new site</div>
                        <div className="confirm_msg">
                            <TextField
                                id="standard-new-site"
                                label="Site Name"
                                // value={new_site}
                                style={{ width: '100%', margin: '5px', marginTop: '20px' }}

                                onChange={(e) => { handleChangeNewSite(e) }}
                            />
                        </div>
                        <div className="confirm_buttons">
                            <button className="confirm_no" onClick={
                                () => {
                                    onClose()
                                }
                            }>Cancel</button>

                            <button className="confirm_yes"
                                onClick={
                                    () => {

                                        if (onlySites.includes(temp_site)) {
                                            // console.log('duplicate site')
                                            onClose()
                                            display_error_msg()

                                        } else {
                                            // console.log('new')
                                            saveNewSiteData()
                                            onClose()
                                        }

                                    }
                                }
                            >Save</button>
                        </div>
                    </div>
                );

            }
        })
    }

    const display_admin_add_button = () => {
        if (props.commonState.isAppAdmin) {
            return (
                <div style={{ margin: '5px', marginTop: '20px' }} onClick={(e) => addnewsite()}>
                    <Tooltip title="Add a site">
                        <div style={{ backgroundColor: '#d77373', margin: '2px', borderRadius: '2px' }}>
                            <IconButton aria-label="add-site">
                                <AddIcon />
                            </IconButton>
                        </div>
                    </Tooltip>
                </div>
            )
        }

    }

    return (
        <div className="new_site_form">
            <Loader loading={loading}></Loader>
            <div>
                <form ref={formRef}>
                    <div>

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <TextField
                                id="standard-select-site"
                                select
                                // disabled={true}
                                value={sitename}
                                label="Site"
                                style={{ width: '85%', margin: '5px', marginTop: '20px' }}
                                disabled={!props.commonState.isAppAdmin}
                                onChange={handleChange}
                            >
                                {availableSites.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <div style={{ margin: '5px', marginTop: '20px' }} onClick={(e) => setedit_flag(!edit_flag)}>
                                <Tooltip title="Edit Site Details">
                                    <div style={{ backgroundColor: '#d77373', margin: '2px', borderRadius: '2px' }}>
                                        <IconButton aria-label="edit-site">
                                            <EditIcon />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            </div>
                            {display_admin_add_button()}
                        </div>
                        <br />
                        {display_data()}

                        <br />
                    </div>
                    {display_button()}
                </form>


                <br />
                <br />
            </div>
        </div>
    );
}

