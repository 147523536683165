import { firebase } from "../../config/firebase";

const sendEmail = (mailType, to, cc, booking_content, commonState) => {

      let title;
      let subject;
      let mailMessage;
      let bookingDetails;
      const sent_by = commonState.userEmail;
      const sent_on = new Date(commonState.currentDate);  

      const emails_ref = firebase
      .firestore()
      .collection(
        "Emails"
      );

      if(mailType === "New Block") {
        title = "New Block Booked"
        subject = "New Block Booked"
        mailMessage = `New block has been booked by your block admin. Please find below booking details.`
        bookingDetails = `
                        <div>Booking Date: ` + booking_content.date + `</div>
                        <div>Floor: ` + booking_content.floor + `</div>
                        `
      }
      else if(mailType === "Booking Cancelled by BlockAdmin") {
        title = "Booking Cancelled"
        subject = "Booking Cancelled"
        mailMessage = `Block Admin has cancelled the block and hence your booking has been cancelled. Below are the cancelled booking details.`
        bookingDetails = `
                        <div>Booking Date: ` + booking_content.date + `</div>
                        <div>Floor: ` + booking_content.floor + `</div>
                        `
      }

      else if(mailType === "Booking Cancelled by Site Admin") {
        title = "Booking Cancelled by Site Admin"
        subject = "Booking Cancelled by Site Admin"
        mailMessage = `Your seat booking has been cancelled by the Site Admin. Below were your booking details.`
        bookingDetails = `
                        <div>Booking Date: ` + booking_content.date + `</div>
                        <div>Workstation: ` + booking_content.ws + `</div>
                        <div>Floor: ` + booking_content.floor + `</div>
                        `
      }

      else if(mailType === "Block Cancelled") {
        title = "Block Cancelled"
        subject = "Block Cancelled"
        mailMessage = `You have successfully cancelled the block booking. Below are the cancelled block details.`
        bookingDetails = `
                        <div>Booking Date: ` + booking_content.date + `</div>
                        <div>Floor: ` + booking_content.floor + `</div>
                        `
      }
      else if(mailType === "Confirm Booking") {
        title = "Booking Confirmed"
        subject = "Booking Confirmed"
        mailMessage = `Your seat booking has been confirmed. Below are your booking details.`
        bookingDetails = `
                        <div>Booking Date: ` + booking_content.date + `</div>
                        <div>Floor: ` + booking_content.floor + `</div>
                        <div>Workstation: ` + booking_content.ws + `</div>
                        <div>Booking Type: Normal Booking </div>
                        `
      }
      else if(mailType === "special_booking") {
        title = "Special Booking Confirmed"
        subject = "Special Booking Confirmed"
        mailMessage = `Your seat booking in special area has been confirmed. Below are your booking details.`
        bookingDetails = `
                        <div>Booking Date: ` + booking_content.date + `</div>
                        <div>Floor: ` + booking_content.floor + `</div>
                        <div>Workstation: ` + booking_content.ws + `</div>
                        <div>Booking Type: Special Booking </div>
                        `
      }
      else if(mailType === "Site Level-Manual") {
        title = "Booking Confirmed"
        subject = "Booking Confirmed"
        mailMessage = `Your seat booking has been confirmed. Below are your booking details.`
        bookingDetails = `
                        <div>Booking Date: ` + booking_content.date + `</div>
                        <div>Floor: ` + booking_content.floor + `</div>
                        <div>Booking Type: Site Level Direct Booking </div>
                        `
      }
      else if(mailType === "Site Level-Auto") {
        title = "Auto Booking Confirmed"
        subject = "Auto Booking Confirmed"
        mailMessage = `Your auto seat booking has been confirmed. Below are your booking details.`
        bookingDetails = `
                        <div>Booking Date: ` + booking_content.date + `</div>
                        <div>Floor: ` + booking_content.floor + `</div>
                        <div>Booking Type: Site Level Auto Booking </div>
                        `
      }
      else if(mailType === "Reserved Cat-Manual") {
        title = "Booking Confirmed"
        subject = "Booking Confirmed"
        mailMessage = `Your seat booking has been confirmed. Below are your booking details.`
        bookingDetails = `
                        <div>Booking Date: ` + booking_content.date + `</div>
                        <div>Workstation: ` + booking_content.ws + `</div>
                        <div>Floor: ` + booking_content.floor + `</div>
                        `
      }
      else if(mailType === "Reserved Cat-Auto") {
        title = "Auto Booking Confirmed"
        subject = "Auto Booking Confirmed"
        mailMessage = `Your auto seat booking has been confirmed. Below are your booking details.`
        bookingDetails = `
                        <div>Booking Date: ` + booking_content.date + `</div>
                        <div>Floor: ` + booking_content.floor + `</div>
                        <div>Booking Type: Category Level Auto Booking </div>
                        `
      }
      else if(mailType === "Booking Cancelled") {
        title = "Booking Cancelled"
        subject = "Booking Cancelled"
        mailMessage = `Your seat booking has been Cancelled. Below are your booking details.`
        bookingDetails = `
                        <div>Booking Date: ` + booking_content.date + `</div>
                        <div>Workstation: ` + booking_content.ws + `</div>
                        <div>Floor: ` + booking_content.floor + `</div>
                        `
      }


      const email_data = {
        booking_type:mailType,
        title: title,
        to: to,
        cc: cc,
        subject: subject,
        mailMessage: mailMessage,
        bookingDetails: bookingDetails,
        sent_by,
        sent_on
      };


      emails_ref
      .add({
        ...email_data,
      })
  
      // fetch(
      //   "https://script.google.com/a/macros/colpal.com/s/AKfycbyUXf9OqdkMrrIi70VTecXUMgMVV5WKtF25K5olqVITtGL3bzwkE1z2/exec?callback?",
      //   {
      //     // crossDomain: true,
      //     // mode: 'no-cors',
      //     method: "POST",
      //     body: JSON.stringify(data),
      //     // headers: { "Content-type": "application/json; charset=UTF-8" },
      //     headers: { "Content-type": "text/plain;charset=utf-8" },
      //   })
      //   .then((result) => {
      //     console.log(result);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });

   
  };

  export default sendEmail;