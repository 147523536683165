import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import { firebase } from '../../../config/firebase';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
// import confirmIcon from './../../../Assets/Images/confirm.png';
// import { confirmAlert } from 'react-confirm-alert';
import MenuItem from '@material-ui/core/MenuItem';
import DatePicker from "react-date-picker";
import calendarIcon from "./../../../Assets/Images/calendar-icon.png";
import Select from '@material-ui/core/Select';
import './category.css'


export default function EditCategory(props) {
    const styles = (theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });
    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    const DialogContent = withStyles((theme) => ({
        root: {
            padding: theme.spacing(2),
        },
    }))(MuiDialogContent);

    const [open, setOpen] = React.useState(true);

    const handleCloseEdit = () => {
        props.handleEditClose();
        setOpen(false);
    };
    const history = useHistory();
    const [no_days_selected, setno_days_selected] = useState(false);
    const [noOfSeats] = useState(1);
    const [active_days, setactive_days] = useState([...props.blockBooking.active_days])
    const org_active_days = [...props.blockBooking.active_days]
    const check_array = [false, false, false, false, false, false, false];
    const [active, setactive] = React.useState(props.blockBooking.isActive);
    const org_active = props.blockBooking.isActive;
    const [auto_book, setautobook] = useState(props.blockBooking.auto_booking_enabled);
    const org_auto_book = props.blockBooking.auto_booking_enabled;
    // const [cat_name, setcat_name] = useState('')
    const categories_ref = firebase.firestore().collection('Categories/CGBS-Mumbai/Category');

    const [minDate, setMinDate] = useState()
    // const maxDate = new Date(props.commonState.currentDate);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedWeekFreq, setSelectedWeekFreq] = useState(1)
    let startingMonday, startingWeek, weekFrequency;
    const [group, setGroup] = React.useState('');
    const [repeat_week, setRepeatWeek] = React.useState(true)
    const [errorMsg, setErrorMsg] = React.useState('');
    const [repeat_week_config_changed, set_repeat_week_config_changed] = useState(false);

    useEffect(() => {
        if(props.blockBooking.isActive){
            setSelectedDate(new Date(props.blockBooking.week_preference.startingMonday.toDate()))
            setSelectedWeekFreq(props.blockBooking.week_preference.weekFrequency)
            setGroup(props.blockBooking.week_preference.group)
        }
        else {
            let currDate = new Date(props.commonState.currentDate)
            let addDays;
            currDate.getDay() === 0 ? addDays = 1 : addDays = 8-currDate.getDay();
            let nxtMonday = new Date(currDate)
            nxtMonday.setDate(nxtMonday.getDate() +addDays)
    
            setMinDate(nxtMonday)
            setSelectedDate(nxtMonday)
            setGroup(props.blockBooking.week_preference.group)
        }

    }, []);

    const handleAutoBookChange = () => {
        // let on_off_text = '';
        // if (auto_book) {
        //     on_off_text = 'ON';
        // }
        // else {
        //     on_off_text = 'OFF';
        // }
         setautobook(!auto_book)
    }


    const handleGroupChange = (event) => {
        setGroup(event.target.value);
        set_repeat_week_config_changed(true);
      };

      const handleRepeatWeek = (val) => {
        // console.log('repeat_week 1', val)
        if(val) {
            document.getElementById("repeat_weekly_sub").classList.remove('disable_week_config')
          }
          else {
            document.getElementById("repeat_weekly_sub").classList.add('disable_week_config')
          }

        //   repeat_week === true ? setRepeatWeek(false): setRepeatWeek(true)
        setRepeatWeek(val)
        //   console.log('repeat_week 2', val)
      }


      const get_cat_next_week = (cat_starting_week, cat_week_freq) => {
          let cat_week_no = cat_starting_week;
        // do {
        //     cat_week_no +=  cat_week_freq;
        //   }
        //   while (cat_week_no <= startingWeek);

          while(cat_week_no < startingWeek) {
            cat_week_no +=  cat_week_freq;
          }

          return cat_week_no;
      }

    const getConflictCategories = () => {
       
      //get the Monday of the selected week
        let selectedMonday;

        if ( selectedDate.getDay() !== 1 )
        {
            let temp_selectedDate  = new Date(selectedDate);
            if(selectedDate.getDay() === 0){
                temp_selectedDate.setDate(temp_selectedDate.getDate() - 6)
            }
            else {
                temp_selectedDate.setDate(temp_selectedDate.getDate() - (temp_selectedDate.getDay() - 1))
            }
            selectedMonday  = new Date(temp_selectedDate);
        }
        else {
            selectedMonday  = new Date(selectedDate);
        }
        
        // console.log('selectedMonday', selectedMonday.getDate())


        //get the week number of selected week
        //Week number will be calcaulated from 03-Jan-2021(First Monday of the year 2021)
        let selectedWeekNo;
        var firstWeekMonday = new Date("03/01/2021");
       
        let Difference_In_Time = selectedMonday.getTime() - firstWeekMonday.getTime();
        let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        selectedWeekNo = Difference_In_Days / 7;

        startingMonday = selectedMonday;
        startingWeek = parseInt(selectedWeekNo);
        weekFrequency = parseInt(selectedWeekFreq);

        // console.log('Difference_In_Time', Difference_In_Time)

        // console.log('Difference_In_Days', Difference_In_Days)

        // console.log('selectedWeekNo', selectedWeekNo)

        let conflictCategories = [];
        props.blockBookings.map((category) => {
            if(category.isActive && category.category !== props.blockBooking.category && category.week_preference) {
                if(category.week_preference.repeat_week === true) {
                   if(category.week_preference.group === group) {}
                   else if(category.week_preference.weekFrequency !== weekFrequency){
                        // if (conflictCategories === '') {
                        //     conflictCategories = category.category;
                        // }
                        // else {
                        //     conflictCategories = conflictCategories + ', ' + category.category;
                        // }
                        conflictCategories.push(category.category)
                    }
                    else {
                        let cat_starting_week = category.week_preference.startingWeek;
                        let cat_week_freq = category.week_preference.weekFrequency;

                        let cat_next_week = get_cat_next_week(cat_starting_week, cat_week_freq)

                        // let max_loop_count = cat_week_freq > weekFrequency ? cat_week_freq : weekFrequency;
                        let max_loop_count = cat_week_freq

                        let cat_next_week_temp = cat_next_week;
                        let startingWeek_temp = startingWeek;
                        let isWeekConflict = false;
                        for(let r=0; r <= max_loop_count; r++) {

                            if(cat_next_week_temp === startingWeek_temp){
                                isWeekConflict = true;
                            }

                            cat_next_week_temp += cat_week_freq;
                            startingWeek_temp += weekFrequency;


                        }
                        if(isWeekConflict){
                            // if (conflictCategories === '') {
                            //     conflictCategories = category.category;
                            // }
                            // else {
                            //     conflictCategories = conflictCategories + ', ' + category.category;
                            // }
                            conflictCategories.push(category.category)
                        }
                   }
                }
            }  
            return true;             
        })

            return conflictCategories;
       
    }

    const reserve_block = () => {
        if (active === true) {
            return (
                <div className="edit-activedays">
                <div>
                    <div style={{ fontSize: '14px', paddingRight: '20px', paddingLeft: '20px' }}>
                        Active Days
                    </div>
                    <div className='block-booking-right'>
                        <div className='block-seats'>
                            <Checkbox checked={active_days[1]}
                                onChange={(e) => { handleCheckBoxChange(e, 1) }}
                            />
                            <div style={{ fontSize: '12px' }}>
                                Mon
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox checked={active_days[2]}
                                onChange={(e) => { handleCheckBoxChange(e, 2) }}
                            />
                            <div style={{ fontSize: '12px' }}>
                                Tue
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox checked={active_days[3]}
                                onChange={(e) => { handleCheckBoxChange(e, 3) }}
                            />
                            <div style={{ fontSize: '12px' }}>
                                Wed
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox checked={active_days[4]}
                                onChange={(e) => { handleCheckBoxChange(e, 4) }}
                            />
                            <div style={{ fontSize: '12px' }}>
                                Thu
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox checked={active_days[5]}
                                onChange={(e) => { handleCheckBoxChange(e, 5) }}
                            />
                            <div style={{ fontSize: '12px' }}>
                                Fri
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox checked={active_days[6]}
                                onChange={(e) => { handleCheckBoxChange(e, 6) }}
                            />
                            <div style={{ fontSize: '12px' }}>
                                Sat
                        </div>
                        </div>
                        <div className='block-seats'>
                            <Checkbox checked={active_days[0]}
                                onChange={(e) => { handleCheckBoxChange(e, 0) }}
                            />
                            <div style={{ fontSize: '12px' }}>
                                Sun
                    </div>
                        </div>
                        <div style={{ fontSize: '14px', marginLeft: '10%' }}>
                            Activate Autobooking
                            <Switch checked={auto_book} onChange={(e) => { handleAutoBookChange(e) }} />
                        </div>
                    </div>
                        <div className="repeat_weekly_main" style={{ fontSize: '14px', marginTop: '5%' }}>
                            <span style={{visibility:'hidden'}}>
                                <Checkbox checked={repeat_week}
                                    onChange={(e) => { handleRepeatWeek(e.target.checked) }}
                                />
                            </span>
                        <div id="repeat_weekly_sub" className="repeat_weekly_sub" >
                            
                            <span className='alt_week_text'> Repeat every </span>
                            <span>
                                <TextField
                                id="outlined-number"
                                label=""
                                type="number"
                                onChange={(e) => {
                                    setSelectedWeekFreq(e.target.value)
                                    set_repeat_week_config_changed(true);
                                }} 
                                value = {selectedWeekFreq}
                                InputLabelProps={{
                                    shrink: true,
                                    
                                }}
                                inputProps = {{
                                    inputMode: 'numeric',
                                    pattern : '[1-9]*'
                                }}
                                style={{width: '36px', marginLeft: '10px', marginRight: '10px', padding:'0px'}}
                                />
                            </span>
                            <span className='alt_week_text'>week(s) starting from</span>
                            {/* <span className='alt_week_text'>starting from </span> */}
                            <span style={{marginLeft:'10px'}}>
                            <DatePicker
                                onChange={(value) => {
                                    setSelectedDate(value)
                                    set_repeat_week_config_changed(true);
                                }}
                                value={selectedDate}
                                // maxDate={maxDate}
                                minDate={minDate}
                                format="dd-MMM-y"
                                calendarIcon={<CalendarIcon />}
                            />
                            </span>
                            {/* <span className='group_week'> Select Group </span>
                            <span>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={group}
                                onChange={handleGroupChange}
                                label="Group"
                                style={{marginLeft: '10px'}}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={'A'}>A</MenuItem>
                                    <MenuItem value={'B'}>B</MenuItem>
                                    <MenuItem value={'C'}>C</MenuItem>
                                    <MenuItem value={'D'}>D</MenuItem>
                                    <MenuItem value={'E'}>E</MenuItem>
                             </Select>       
                            </span> */}
                            </div>   
                        </div>
                    </div>



                </div>
            )
        }
    }
    const handleCheckBoxChange = (e, index) => {
        // console.log(active_days)
        // console.log(e.target.value)
        // console.log(index)
        let temp_arr = [...active_days]
        temp_arr[index] = !active_days[index];
        setactive_days([...temp_arr])

    }
    // const SaveCat = (e) => {
    //     // console.log('saving')
    //     confirmAlert({
    //         customUI: ({ onClose }) => {
    //             return (
    //                 <div className='custom-ui confirm_dialog'>
    //                     <div className="confirm_title">Confirmation</div>
    //                     <div className="confirm_msg">
    //                         <div className="img">
    //                             <img
    //                                 alt=''
    //                                 style={{ width: 50, height: 50 }}
    //                                 src={confirmIcon}
    //                             />
    //                         </div>
    //                         <div className="msg">Save Category - {cat_name}?</div>
    //                     </div>

    //                     <div className="confirm_buttons">
    //                         <button className="confirm_no" onClick={onClose}>Back</button>
    //                         <button className="confirm_yes"
    //                             onClick={() => {
    //                                 const categories_ref = firebase.firestore().collection('Categories/' + 'CGBS-Mumbai' + '/Category');
    //                                 const category_temp = {
    //                                     active_days: [false, false, false, false, false, false, false],
    //                                     auto_booking_enabled: false,
    //                                     category: cat_name,
    //                                     floor: {},
    //                                     isActive: false,
    //                                     no_of_seats: 0,
    //                                     last_updated_by: 'Priyamvadhana Yalla',
    //                                     last_updated_on: new Date()
    //                                 }

    //                                 categories_ref
    //                                     .add({
    //                                         ...category_temp
    //                                     })
    //                                     .then(function (docRef) {
    //                                         // console.log("Category saved successfully ID: " + docRef.id);
    //                                     })
    //                                     .catch(function (error) {
    //                                         console.error("Error while saving category: ", error);
    //                                     });
    //                                 props.reload_data(1);
    //                                 onClose()
    //                             }}>
    //                             Confirm
    //                     </button>
    //                     </div>
    //                 </div>
    //             );

    //         }
    //     })
    // }

    const handleCatChange = (e) => {
        // setcat_name(e.target.value)
    }

    const ChangeCatSeats = (e) => {
        // console.log('Clicked change seats')
        // console.log(props.blockBooking.block_id);
        let validationMsg = '';
        let conflictCategories;
        if (repeat_week === true && group === ''){
            validationMsg = 'Please select a group'
        }
        else{
            conflictCategories = getConflictCategories()
            if (conflictCategories !== '') {
                // validationMsg = 'The repeat week configuration is conflicting with categories - ' + conflictCategories;
            }
        }

        if(validationMsg !== '') {
            setErrorMsg(validationMsg);
        }
        else {

        history.push('/floorplan', {
            action: 'reserve_category',
            commonState: props.commonState,
            active_days: active_days,
            category_name: props.blockBooking.category,
            auto_book: auto_book,
            floor_data: { ...props.blockBooking.floor },
            new_cat: false,
            re_select: false,
            doc_id: props.blockBooking.block_id,
            week_preference: {
                repeat_week,
                group,
                startingMonday,
                startingWeek,
                weekFrequency
            },
            conflictCategories
        })
    }
    }

    const SelectSeatsforCat = () => {
        if (JSON.stringify(active_days) === JSON.stringify(check_array)) {
            setno_days_selected(true);
        } else {

            let validationMsg = '';
            let conflictCategories;
            if (repeat_week === true && group === ''){
                validationMsg = 'Please select a group'
            }
            else{
                conflictCategories = getConflictCategories()
                if (conflictCategories !== '') {
                    // validationMsg = 'The repeat week configuration is conflicting with categories - ' + conflictCategories;
                }
            }
    
            if(validationMsg !== '') {
                setErrorMsg(validationMsg);
            }
            else {

            history.push('/floorplan', {
                action: 'reserve_category',
                commonState: props.commonState,
                blockedSeatName: "Test",
                active_days: active_days,
                category_name: props.blockBooking.category,
                noOfSeats: noOfSeats,
                floor_data : {},
                auto_book: auto_book,
                new_cat: false,
                re_select: false,
                doc_id: props.blockBooking.block_id,
                week_preference: {
                    repeat_week,
                    group,
                    startingMonday,
                    startingWeek,
                    weekFrequency
                },
                conflictCategories
            })
        }}

    }

    const SelectFreshSeats = () => {

        let validationMsg = '';
        let conflictCategories;

        if (repeat_week === true && group === ''){
            validationMsg = 'Please select a group'
        }
        else{
            conflictCategories = getConflictCategories()
            if (conflictCategories !== '') {
                // validationMsg = 'The repeat week configuration is conflicting with categories - ' + conflictCategories;
            }
        }

        if(validationMsg !== '') {
            setErrorMsg(validationMsg);
        }
        else {
        // console.log('re-selecting seats')
        history.push('/floorplan', {
            action: 'reserve_category',
            commonState: props.commonState,
            active_days: active_days,
            category_name: props.blockBooking.category,
            auto_book: auto_book,
            floor_data: {  },
            new_cat: false,
            doc_id: props.blockBooking.block_id,
            re_select: true,
            week_preference: {
                repeat_week,
                group,
                startingMonday,
                startingWeek,
                weekFrequency
            },
            conflictCategories
        })
    }}

    const SaveAutoBooking = () => {

        let validationMsg = '';
        let conflictCategories;
        if (repeat_week === true && group === ''){
            validationMsg = 'Please select a group' 
        }
        else{
            conflictCategories = getConflictCategories()
            if (conflictCategories !== '') {
                // validationMsg = 'The repeat week configuration is conflicting with categories - ' + conflictCategories;
            }
        }

        if(validationMsg !== '') {
            setErrorMsg(validationMsg);
        }
        else {
        categories_ref
            .doc(props.blockBooking.block_id)
            .update(
            {
                auto_booking_enabled: auto_book,
                week_preference: {
                    repeat_week,
                    group,
                    startingMonday,
                    startingWeek,
                    weekFrequency
                }

            }
            )
            .then(function () {
                // console.log("Auto Booking updated!");
            }).catch(function (error) {
                console.error("Error while updating auto booking: ", error);
            });
        handleCloseEdit();
        props.reload_data(1);
      }
    }

    const SaveNewChanges = () => {
        // console.log(props.commonState.userName)
        categories_ref
            .doc(props.blockBooking.block_id)
            .set(
            {
                active_days: [false, false, false, false, false, false, false],
                auto_booking_enabled: false,
                category: props.blockBooking.category,
                floor: {},
                isActive: false,
                no_of_seats: 0,
                last_updated_by: props.commonState.userName,
                last_updated_on: new Date(),
                week_preference: { "group" : group }
            }
            )
            .then(function (docRef) {
                // console.log("Category saved successfully ID: " + docRef.id);
            })
            .catch(function (error) {
                console.error("Error while saving category: ", error);
            });
        handleCloseEdit();
        props.reload_data(1);
    }

    const display_error_msg = () => {
        if (no_days_selected) {
            return (
                <div style={{ fontSize: '12px', fontWeight: 'bold', textAlign: 'center', color:'red' }}>
                    Select atleast one active day!
                </div>
            )
        }

    }

    const display_error = () => {
        if (errorMsg !== '') {
            return (
                <div style={{ fontSize: '12px', fontWeight: 'bold', textAlign: 'center', color:'red' }}>
                    {errorMsg}
                </div>
            )
        }

    }

    const display_button = () => {
        if (org_active === true) {
            if (active === true) {

                if (JSON.stringify(active_days) !== JSON.stringify(org_active_days)) {

                    return (
                        <div className='reserve-input'>
                            <button
                                onClick={(e) => SelectFreshSeats(e)}
                                // style={{width:'30%'}}
                                className='edit-cat-field-style reserve-button'>
                                Re-select Seats
                            </button>
                            <button
                                disabled={active}

                                // style={{width:'30%'}}
                                className='edit-cat-field-style save-new-button'
                            >
                                Save Changes
                            </button>
                        </div>)
                }
                else if (auto_book !== org_auto_book || repeat_week_config_changed) {
                    // console.log('auto changing');
                    return (
                        <div className='reserve-input'>
                            <div>
                            <button
                              
                                onClick={(e) => ChangeCatSeats(e)}
                                // style={{width:'30%'}}
                                className='edit-cat-field-style reserve-button'>
                                Change Seats
                            </button>
                            </div>
                            <div>
                            <button
                                onClick={(e) => SaveAutoBooking(e)}
                                // style={{width:'30%'}}
                                className='edit-cat-field-style save-edit-button'
                            >
                                Save Changes
                            </button>
                            </div>
                        </div>)
                }
                else {
                    return (
                        <div className='reserve-input'>
                            <button
                                onClick={(e) => ChangeCatSeats(e)}
                                // style={{width:'30%'}}
                                className='edit-cat-field-style reserve-button'>
                                Change Seats
                            </button>
                            <button
                                disabled={active}

                                // style={{width:'30%'}}
                                className='edit-cat-field-style save-new-button'
                            >
                                Save Changes
                            </button>
                        </div>
                    )
                }
            }
            else {
                return (
                    <div className='reserve-input'>
                        <button
                            onClick={(e) => SaveNewChanges(e)}
                            className='field-style edit-cat-button'>

                            Save Changes
                                 </button>
                    </div>
                )
            }

        }
        else {
            if (active === true) {
                // let temp_arr = [false, true, true, true, true,true, false]
                // setactive_days([...temp_arr])
                return (
                    <div className='reserve-input'>
                        <button
                            onClick={(e) => SelectSeatsforCat(e)}
                            // style={{width:'30%'}}
                            className='edit-cat-field-style reserve-button'>
                            Select Seats & Save
                        </button>
                        {display_error_msg()}
                    </div>)
            }
            //     <div className='reserve-input'>
            //         <button
            //             onClick={(e) => SaveCat(e)}
            //             className='field-style edit-cat-button'>

            //             Save
            //             </button>
            //     </div>
            // )
        }
    }

    return (

        <div >
            <Dialog onClose={handleCloseEdit} aria-labelledby="customized-dialog-title" open={open}>
                <div className="new_question_main">
                    <div className="new_question_main">
                        <DialogTitle id="customized-dialog-title" onClose={handleCloseEdit}>
                            Edit Category Details
                    </DialogTitle>
                    </div>
                    <DialogContent dividers style={{ width: '100%' }}>
                        <div className='edit-cat-items' >


                            <div className='reserve-input'>
                                <TextField
                                    id="category_name"
                                    label="Category Name"
                                    disabled
                                    value={props.blockBooking.category}
                                    onChange={(e) => { handleCatChange(e) }}
                                // helperText="Category Name"
                                >
                                </TextField>

                            </div>
                            <div className='reserve-input'>
                                <div style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
                                    Reserve Permanent Block?
                                    <Switch checked={active} onChange={(e) => setactive(!active)} />
                                </div>
                            </div>
                            {reserve_block()}
                            {display_button()}
                            {display_error()}

                        </div>
                    </DialogContent>
                </div>
            </Dialog>


        </div>
    )
}
const CalendarIcon = () => {
    return <img alt="" style={{ width: 30, height: 30 }} src={calendarIcon} />;
  };