import React from 'react';

import ReserveWing from './ReserveWing';
import { Link } from 'react-router-dom';
import '../../BlockBookings/block-bookings.css'
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
// import Checkbox from '@material-ui/core/Checkbox';
import './floor-div.css'

export default function FloorDivSub(props) {
    // const[floor_t, setfloor_t] = useState('');
    const block_bookings_header = () => {
        // console.log(props.blockBookings.length);
        if (props.blockBookings.length === 0) {
            return (
                <div className='block-booking'>
                    No Wings created
                </div>
            )
        }
        else {
            return (
                <div className="block-booking-header">
                    Wings
            </div>
            )
        }
    }

    // const setfloor_t = (e) => {
    //     console.log(e);
    // }

    return (
        <div className='block-bookings'>
            <ReserveWing
                commonState={props.commonState}
                setfloor={(floor) => props.setfloor(floor)}
                reserveBlock={(newBlock) => props.handleReseveBlock(newBlock)} />

            <div className='block-bookings-content'>
                {block_bookings_header()}
                {props.blockBookings.map((item, index) =>
                    <BlockBooking
                        key={index}
                        blockBooking={item}
                        commonState={props.commonState}
                        cancelBlockClicked={(block) => props.handleCancelBlock(block)}
                        releaseSeatsClicked={(block) => props.handleReleaseSeats(block)}
                    />)}
            </div>
        </div>
    )
}


const BlockBooking = (props) => {
    // const getMonth = {
    //     0: 'Jan',
    //     1: 'Feb',
    //     2: 'Mar',
    //     3: 'Apr',
    //     4: 'May',
    //     5: 'Jun',
    //     6: 'Jul',
    //     7: 'Aug',
    //     8: 'Sep',
    //     9: 'Oct',
    //     10: 'Nov',
    //     11: 'Dec'
    // }

    // const get_day = {
    //     0: 'Sunday',
    //     1: 'Monday',
    //     2: 'Tuesday',
    //     3: 'Wednesday',
    //     4: 'Thursday',
    //     5: 'Friday',
    //     6: 'Saturday',
    // }

    const current_floor = Object.keys(props.blockBooking.floor);
    const curr_floor = current_floor[0]
    // console.log(curr_floor)

    // const getWeekDay = () => {
    //     const blocked_date = props.blockBooking.blocked_date
    //     return get_day[blocked_date.getDay()]
    // }

    const printDate = () => {
        let date1 = props.blockBooking.last_updated_on.toDate().toLocaleDateString();

        // const blocked_date = props.blockBooking.last_updated_on;
        // let blocked_date_date;

        //  if (blocked_date.getDate() < 10) {
        //      blocked_date_date = '0' + blocked_date.getDate().toString()
        //  }
        //  else {
        //      blocked_date_date = blocked_date.getDate().toString()
        //  }

        //  const blocked_date_month = getMonth[blocked_date.getMonth()]

        //  return blocked_date_date + '-' + blocked_date_month
        return date1;
    }

    const editWing = () => {

    }

    // const releaseSeats = () => {
    //     props.releaseSeatsClicked(props.blockBooking)
    // }

    const cancelBlock = () => {
        props.cancelBlockClicked(props.blockBooking)
    }

    return (
        <div className='block-booking'>
            <div className='block-booking-left'>

                <div className='block-date'>
                    <div style={{ fontSize: '16px' }}>
                        {props.blockBooking.wing}
                    </div>

                </div>
                <div className='block-seats'>
                    <div style={{ fontSize: '24px' }}>
                        {props.blockBooking.no_of_seats}
                    </div>
                    <div style={{ fontSize: '12px' }}>
                        Seats
                    </div>
                </div>
                <div className='block-seats'>

                    <div style={{ fontSize: '16px' }}>
                        {props.blockBooking.last_updated_by}
                    </div>
                    <div style={{ fontSize: '12px' }}>
                        Last Updated By
                    </div>
                </div>
                <div className='block-seats'>

                    <div style={{ fontSize: '16px' }}>
                        {printDate()}
                    </div>
                    <div style={{ fontSize: '12px' }}>
                        Last Updated On
                    </div>
                </div>

            </div>
            <div >
                <div className='block-buttons'>
                    <div>
                    <Link
                            to={{
                                pathname: '/floorplan',
                                state: {
                                    action: 'floor_division_wings',
                                    commonState: props.commonState,
                                    wingName:props.blockBooking.wing,
                                    wing_data: props.blockBooking,
                                    floor: curr_floor,
                                    // floor : Object.props.blockBooking.floor.key()
                                }
                            }}
                        >
                    <button
                        className='button-release-seats'
                        onClick={() => editWing()}>
                        Edit
                    </button>
                    </Link>
                    </div>
                    <button
                        onClick={() => cancelBlock(props.blockBooking)}
                        className='button-cancel-block'>
                        Delete
                </button>
                </div>
            </div>
        </div>
    )
}