import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import './../FloorPlan/floor-plan.css'
import './cgbs-floor-four.css';
import Loader from '../Loader/loader'

export default function CGBSFloorFour(props) {

    // const [floorState, setFloorState] = useState(props.floorState)
    const floorState = props.floorState;

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        let seat_count=0
        Object.keys(floorState).forEach((ws)=>{
            if(document.getElementById(ws)){
                seat_count++;
            }
        })
        props.totalSeats(seat_count)

        let selectedAttibs = [];
        props.seatAttributes.map((attr) => {
          if (attr.class === "attribute-selected") {
            selectedAttibs.push(attr.label);
          }
          return true;
        })

        Object.keys(floorState).forEach((ws)=>{
            if(document.getElementById(ws)){
                const tooltip_text = floorState[ws].tooltip ? floorState[ws].tooltip : floorState[ws].status
                document.getElementById(ws).setAttribute("data-tip", tooltip_text);
                document.getElementById(ws+'_text').setAttribute("data-tip", tooltip_text);
          
                document.getElementById(ws).classList.remove("attrib-selected");
                if (floorState[ws].attributes) {                    
                    let checkAttribute = selectedAttibs.every(v => floorState[ws].attributes.includes(v))
                    if(checkAttribute && selectedAttibs.length > 0) {
                        document.getElementById(ws).classList.add("attrib-selected");
                    }
                }
            }
      })
      
      setLoading(false)
      });


    const seatSelected = (e) => {
        props.seatSelected(e)
    }



    return (
        <div>
            <ReactTooltip
                backgroundColor='#d01837'
            />
            <Loader loading={loading}></Loader>
            {/* <svg height={1450} width={800}> */}

            <svg viewBox="0 30 800 1550">

                <g>
                    <rect x="-1" y="-1" width="800" height="1580" id="canvas_background" fill="#999999" strokeWidth="1.5" />
                </g>
                <g>

                    <path stroke="null" id="svg_2" d="m29.59781,154.4036l734.25092,0l0,1173.97205l-734.25092,0l0,-1173.97205z"  strokeWidth="0" fill="#faebd6" />
                    <path stroke="#999999" id="svg_665" d="m544.75326,166.37343l200.47906,0l0,137.77579l-200.47906,0l0,-137.77579z"  strokeWidth="1.75" fill="#b2b2b2" />
                    <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="12" id="svg_13" y="192.55473" x="603.57801" strokeWidth="0" stroke="#b2b2b2" fill="#666666">BOARD ROOM</text>
                    <rect id="svg_26" height="39.31155" width="105.44444" y="227.29725" x="595.762" strokeWidth="1.75" fill="none" stroke="#999999" />
                    <g id="svg_158">
                        <rect id="svg_27" height="8.93882" width="9.343" y="274.64782" x="611.91108" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_122" height="8.93882" width="9.343" y="274.64782" x="625.4704" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_150" height="8.93882" width="9.343" y="274.64782" x="639.02971" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_151" height="8.93882" width="9.343" y="274.64782" x="653.154" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_156" height="8.93882" width="9.343" y="274.64782" x="667.27828" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_157" height="8.93882" width="9.343" y="275.09826" x="681.78479" strokeWidth="1.75" fill="none" stroke="#999999" />
                    </g>
                    <g id="svg_213">
                        <rect id="svg_164" height="8.93882" width="9.343" y="211.73944" x="611.91108" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_168" height="8.93882" width="9.343" y="211.73944" x="625.4704" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_169" height="8.93882" width="9.343" y="211.73944" x="639.02971" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_171" height="8.93882" width="9.343" y="211.73944" x="653.154" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_203" height="8.93882" width="9.343" y="211.73944" x="667.27828" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_204" height="8.93882" width="9.343" y="211.73944" x="681.40257" strokeWidth="1.75" fill="none" stroke="#999999" />
                    </g>
                    <g id="svg_216">
                        <rect id="svg_214" height="8.93882" width="9.343" y="248.31029" x="578.88271" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_215" height="8.93882" width="9.343" y="233.60148" x="578.88271" strokeWidth="1.75" fill="none" stroke="#999999" />
                    </g>
                    <g id="svg_231">
                        <rect id="svg_223" height="8.93882" width="9.343" y="248.31029" x="708.44779" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_230" height="8.93882" width="9.343" y="233.60148" x="708.44779" strokeWidth="1.75" fill="none" stroke="#999999" />
                    </g>
                    <path stroke="#999999" strokeWidth="1.75" id="CU01" data-tip="" className={floorState.CU01.status} onClick={(e) => { seatSelected(e) }} d="m152.52488,166.38788l65.24324,0l0,123.12936l-65.24324,0l0,-123.12936z"  fill="#b2b2b2" />
                    <path stroke="#999999" strokeWidth="1.75" id="svg_271" d="m152.52488,166.38788l-21.80376,0l0,123.12936l21.80376,0l0,-123.12936z"  fill="#b2b2b2" />
                    <path fill="#666666" stroke="#b2b2b2" strokeWidth="0"  d="m494.87,810.57882" id="svg_160" />
                    <path id="svg_627" d="m544.91054,624.70036l199.5967,0l0,217.94878l-199.5967,0l0,-217.94878z"  strokeWidth="1.75" fill="#b2b2b2" stroke="#999999" />
                    <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="12" id="svg_629" y="705.59478" x="661.85383" strokeWidth="0" stroke="#b2b2b2" fill="#666666">STAIRCASE</text>
                    <path stroke="#999999" strokeWidth="1.75" id="svg_133" d="m510.89394,1165.34545l232.76603,0l0,121.43965l-232.76603,0l0,-121.43965z"  fill="#b2b2b2" />
                    <line strokeWidth="1.75" id="svg_134" y2="1225.6672" x2="688.17607" y1="1225.66721" x1="567.2309" fill="none" stroke="#999999" transform="rotate(90 627.703 1225.67)" />
                    <rect id="svg_142" height="59.67127" width="54.2655" y="1167.81839" x="290.28404" strokeWidth="1.75" fill="#b2b2b2" stroke="#999999" />
                    <rect id="svg_193" height="59.67127" width="54.2655" y="1227.37194" x="290.28404" strokeWidth="1.75" fill="#b2b2b2" stroke="#999999" />
                    <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="11" id="svg_194" y="1191.80384" x="295.4633" strokeWidth="0" stroke="#b2b2b2" fill="#666666">SERVER</text>
                    <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="11" id="svg_196" y="1205.69549" x="301.0702" strokeWidth="0" stroke="#b2b2b2" fill="#666666">ROOM</text>
                    <path fill="#b2b2b2" stroke="#999999" strokeWidth="1.75"  d="m53.70136,1170.35132l182.58228,0l0,59.67127l-182.58228,0l0,-59.67127z" id="svg_192" />
                    <rect stroke="#999999" id="svg_202" height="59.67127" width="182.35232" y="1227.30708" x="53.9313" strokeWidth="1.75" fill="#b2b2b2" />
                    <g id="svg_699">
                        <rect id="svg_694" height="24.12174" width="26.23034" y="1237.82878" x="555.52676" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect stroke="#999999" id="svg_695" height="8.93882" width="9.343" y="1225.63164" x="564.06195" strokeWidth="1.75" fill="none" />
                        <rect stroke="#999999" id="svg_696" height="8.93882" width="9.343" y="1265.77249" x="564.06195" strokeWidth="1.75" fill="none" />
                        <rect stroke="#999999" id="svg_697" height="8.93882" width="9.343" y="1243.9415" x="586.59716" strokeWidth="1.75" fill="none" />
                        <rect stroke="#999999" id="svg_698" height="8.93882" width="9.343" y="1243.9415" x="541.5463" strokeWidth="1.75" fill="none" />
                    </g>
                    <g id="svg_705">
                        <rect id="svg_700" height="24.12174" width="26.23034" y="1237.82878" x="671.90476" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect stroke="#999999" id="svg_701" height="8.93882" width="9.343" y="1225.63164" x="680.43995" strokeWidth="1.75" fill="none" />
                        <rect stroke="#999999" id="svg_702" height="8.93882" width="9.343" y="1265.77249" x="680.43995" strokeWidth="1.75" fill="none" />
                        <rect stroke="#999999" id="svg_703" height="8.93882" width="9.343" y="1243.9415" x="702.97516" strokeWidth="1.75" fill="none" />
                        <rect stroke="#999999" id="svg_704" height="8.93882" width="9.343" y="1243.9415" x="657.9243" strokeWidth="1.75" fill="none" />
                    </g>
                    <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="11" id="svg_170" y="1256.44739" x="296.39648" strokeWidth="0" stroke="#b2b2b2" fill="#666666">PANTRY</text>
                    <g id="svg_707" transform="rotate(180 260.916 1229.95)">
                        <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="11" id="svg_12" y="1240.95788" x="229.54084" strokeWidth="0" stroke="#b2b2b2" fill="#666666" transform="rotate(90 261.312 1237.14)">RESTROOM</text>
                        <path fill="#666666" strokeWidth="0" d="m255.95349,1200.7683l4.63027,-4.85677l-4.63027,-4.8568l2.64643,-2.77561l7.27726,7.63241l-7.27726,7.63478" id="svg_138" stroke="#b2b2b2" transform="rotate(-90 260.916 1195.91)" />
                    </g>
                    <line strokeWidth="1.75" id="svg_719" y2="1258.09335" x2="199.34128" y1="1258.09336" x1="139.79323" fill="none" stroke="#999999" transform="rotate(90 169.567 1258.09)" />
                    <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="11" id="svg_720" y="1250.42387" x="179.94735" strokeWidth="0" stroke="#b2b2b2" fill="#666666">SERVER</text>
                    <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="11" id="svg_721" y="1264.31552" x="185.55425" strokeWidth="0" stroke="#b2b2b2" fill="#666666">ROOM</text>
                    <path id="svg_732" d="m-12.37018,129.98534l125.71479,0l0,722.38873l-125.71479,0l0,-722.38873z"  strokeWidth="0" stroke="null" fill="#999" />
                    <rect stroke="#f1f1f1" id="svg_5" height="126.09647" width="195.9678" y="164.52275" x="347.51981" strokeWidth="0" fill="#e5e5e5" />
                    <rect stroke="#b2b2b2" id="WS123" data-tip="" className={floorState.WS123.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="246.29294" x="508.27469" fill="#ffffff" transform="rotate(90 525.775 256.295)" />
                    <rect stroke="#b2b2b2" id="WS122" data-tip="" className={floorState.WS122.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="190.63809" x="508.17689" fill="#ffffff" transform="rotate(90 525.677 200.637)" />
                    <text transform="rotate(90 526.001 200.281)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS122_text" data-tip="" className={`${floorState.WS122.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="204.46983" x="515.98772" fill="#000000">122</text>
                    <text transform="rotate(90 526.42 255.726)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS123_text" data-tip="" className={`${floorState.WS123.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="259.91425" x="516.40717" fill="#000000">123</text>
                    <line strokeWidth="1.75" id="svg_262" y2="228.41236" x2="544.40903" y1="228.41237" x1="513.55795" fill="none" stroke="#999999" />
                    <rect stroke="#b2b2b2" id="WS116" data-tip="" className={floorState.WS116.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="245.79163" x="385.64171" fill="#ffffff" transform="rotate(90 403.142 255.792)" />
                    <rect stroke="#b2b2b2" id="WS115" data-tip="" className={floorState.WS115.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="246.15991" x="428.57895" fill="#ffffff" transform="rotate(90 446.079 256.162)" />
                    <text transform="rotate(90 403.387 255.388)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS116_text" data-tip="" className={`${floorState.WS116.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="259.57619" x="393.82107" fill="#000000">116</text>
                    <rect stroke="#b2b2b2" id="WS119" data-tip="" className={floorState.WS119.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="189.71494" x="385.54272" fill="#ffffff" transform="rotate(90 403.043 199.714)" />
                    <rect stroke="#b2b2b2" id="WS120" data-tip="" className={floorState.WS120.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="189.41304" x="428.48115" fill="#ffffff" transform="rotate(90 445.981 199.413)" />
                    <text transform="rotate(90 446.305 199.056)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS120_text" data-tip="" className={`${floorState.WS120.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="203.24478" x="436.29198" fill="#000000">120</text>
                    <text transform="rotate(90 402.846 199.764)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS119_text" data-tip="" className={`${floorState.WS119.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="203.95222" x="393.27985" fill="#000000">119</text>
                    <text transform="rotate(90 446.277 255.593)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS115_text" data-tip="" className={`${floorState.WS115.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="259.78122" x="436.71143" fill="#000000">115</text>
                    <line stroke="#999999" strokeWidth="1.75" id="svg_244" y2="228.46571" x2="414.40489" y1="228.46572" x1="385.56724" fill="none" />
                    <line stroke="#999999" strokeWidth="1.75" id="svg_245" y2="228.46571" x2="464.38443" y1="228.46572" x1="435.54677" fill="none" />
                    <line strokeWidth="1.75" id="svg_248" y2="226.99427" x2="523.47673" y1="226.99428" x1="406.17071" fill="none" stroke="#999999" transform="rotate(90 464.824 226.994)" />
                    <path fill="#ffffff" stroke="#b2b2b2"  transform="rotate(90 483.445 256.047)" d="m465.94473,246.04715l35,0l0,20l-35,0l0,-20z" id="WS124" data-tip="" className={floorState.WS124.status} onClick={(e) => { seatSelected(e) }} />
                    <text transform="rotate(90 484.137 255.643)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS124_text" data-tip="" className={`${floorState.WS124.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="259.83172" x="474.12406" fill="#000000">124</text>
                    <rect stroke="#b2b2b2" id="WS121" data-tip="" className={floorState.WS121.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="190.26885" x="466.51685" fill="#ffffff" transform="rotate(90 484.017 200.267)" />
                    <text transform="rotate(90 484.267 200.318)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS121_text" data-tip="" className={`${floorState.WS121.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="204.50613" x="474.25398" fill="#000000">121</text>
                    <line stroke="#999999" strokeWidth="1.75" id="svg_261" y2="228.41236" x2="494.03675" y1="228.41237" x1="465.19909" fill="none" />
                    <rect stroke="#b2b2b2" id="WS117" data-tip="" className={floorState.WS117.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="246.15991" x="348.57895" fill="#ffffff" transform="rotate(90 366.079 256.162)" />
                    <rect stroke="#b2b2b2" id="WS118" data-tip="" className={floorState.WS118.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="189.41304" x="348.48115" fill="#ffffff" transform="rotate(90 365.981 199.413)" />
                    <text transform="rotate(90 365.858 199.056)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS118_text" data-tip="" className={`${floorState.WS118.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="203.24478" x="356.29198" fill="#000000">118</text>
                    <text transform="rotate(90 366.277 255.593)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS117_text" data-tip="" className={`${floorState.WS117.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="259.78122" x="356.71143" fill="#000000">117</text>
                    <line stroke="#999999" strokeWidth="1.75" id="svg_95" y2="228.46571" x2="384.38443" y1="228.46572" x1="355.54677" fill="none" />
                    <line strokeWidth="1.75" id="svg_96" y2="226.99427" x2="443.47673" y1="226.99428" x1="326.17071" fill="none" stroke="#999999" transform="rotate(90 384.824 226.994)" />
                    <rect id="svg_50" height="181.06409" width="104.81807" y="402.31773" x="648.75688" strokeWidth="0" fill="#e5e5e5" stroke="#f1f1f1" />
                    <line transform="rotate(90 695.657 489.076)" id="svg_327" y2="489.07591" x2="777.22655" y1="489.07592" x1="614.08713" fill="none" stroke="#999999" strokeWidth="1.75" />
                    <path fill="#666666" stroke="#b2b2b2" strokeWidth="0"  d="m724.87,537.57882" id="svg_354" />
                    <line strokeWidth="1.75" id="svg_360" y2="441.27259" x2="739.30317" y1="441.2726" x1="653.23816" fill="none" stroke="#999999" />
                    <rect id="svg_205" height="181.36361" width="96.74263" y="401.257" x="543.63435" strokeWidth="0" fill="#e5e5e5" stroke="#f1f1f1" />
                    <line transform="rotate(90 592.75 490.661)" strokeWidth="1.75" id="svg_338" y2="490.6612" x2="674.39995" y1="490.66121" x1="511.09903" fill="none" stroke="#999999" />
                    <rect id="svg_380" height="289.09017" width="99.25381" y="315.34411" x="416.19687" strokeWidth="0" fill="#e5e5e5" stroke="#f1f1f1" />
                    <path fill="#666666" stroke="#b2b2b2" strokeWidth="0"  d="m494.87,529.41496" id="svg_124" />
                    <rect transform="rotate(90 484.279 440.383)" stroke="#b2b2b2" id="WS126" data-tip="" className={floorState.WS126.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="430.38315" x="466.77795" fill="#ffffff" />
                    <text transform="rotate(90 484.967 440.087)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS126_text" data-tip="" className={`${floorState.WS126.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="444.16771" x="474.95731" fill="#000000">126</text>
                    <rect transform="rotate(90 485.378 580.383)" stroke="#b2b2b2" id="WS128" data-tip="" className={floorState.WS128.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="570.38315" x="467.87688" fill="#ffffff" />
                    <text transform="rotate(90 485.702 580.823)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS128_text" data-tip="" className={`${floorState.WS128.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="584.903" x="475.6926" fill="#000000">128</text>
                    <path fill="#e5e5e5" strokeWidth="0"  d="m299.80429,323.33891l94.80676,0l0,581.40012l-94.80676,0l0,-581.40012z" id="svg_405" stroke="#f1f1f1" />
                    <rect id="svg_406" height="288.06321" width="99.1546" y="315.79939" x="175.3655" strokeWidth="0" fill="#e5e5e5" stroke="#f1f1f1" />
                    <line transform="rotate(90 466.805 459.896)" stroke="#999999" strokeWidth="1.75" id="svg_77" y2="459.896" x2="607.60634" y1="459.89601" x1="326.0046" fill="none" />
                    <path fill="#666666" stroke="#b2b2b2" strokeWidth="0"  d="m254.87,529.41496" id="svg_257" />
                    <line transform="rotate(90 225.658 459.338)" stroke="#999999" strokeWidth="1.75" id="svg_226" y2="459.33808" x2="365.05899" y1="459.33809" x1="86.25693" fill="none" />
                    <line transform="rotate(90 347.823 630.039)" stroke="#999999" strokeWidth="1.75" id="svg_89" y2="630.03889" x2="578.02293" y1="630.0389" x1="117.62341" fill="none" />
                    <rect stroke="#f1f1f1" id="svg_455" height="121.74915" width="163.00245" y="1166.25486" x="346.05491" strokeWidth="0" fill="#e5e5e5" />
                    <rect stroke="#f1f1f1" id="svg_456" height="223.91415" width="94.81436" y="919.34782" x="299.66289" strokeWidth="0" fill="#e5e5e5" />
                    <rect stroke="#f1f1f1" id="svg_457" height="71.49014" width="47.39011" y="943.88022" x="47.6832" strokeWidth="0" fill="#e5e5e5" />
                    <rect stroke="#f1f1f1" id="svg_458" height="68.61798" width="96.30337" y="1008.26094" x="47.74706" strokeWidth="0" fill="#e5e5e5" />
                    <rect stroke="#f1f1f1" id="svg_459" height="77.81942" width="51.11349" y="1064.14359" x="642.93669" strokeWidth="0" fill="#e5e5e5" />
                    <rect stroke="#f1f1f1" id="svg_460" height="77.4775" width="112.7927" y="927.80219" x="642.93668" strokeWidth="0" fill="#e5e5e5" />
                    <rect stroke="#f1f1f1" id="svg_461" height="222.70445" width="61.09356" y="920.32655" x="538.04749" strokeWidth="0" fill="#e5e5e5" />
                    <rect stroke="#f1f1f1" id="svg_462" height="223.6978" width="98.90501" y="919.64789" x="417.6841" strokeWidth="0" fill="#e5e5e5" />
                    <rect stroke="#f1f1f1" id="svg_463" height="222.90507" width="99.29706" y="920.28035" x="175.23276" strokeWidth="0" fill="#e5e5e5" />
                    <rect stroke="#f1f1f1" id="svg_464" height="284.78821" width="98.51355" y="620.26457" x="417.63477" strokeWidth="0" fill="#e5e5e5" />
                    <rect stroke="#f1f1f1" id="svg_466" height="285.97346" width="98.51268" y="619.10545" x="175.60537" strokeWidth="0" fill="#e5e5e5" />
                    <line transform="rotate(90 466.806 762.222)" stroke="#999999" strokeWidth="1.75" id="svg_115" y2="762.22239" x2="606.69048" y1="762.2224" x1="326.92161" fill="none" />
                    <line transform="rotate(90 225.945 761.708)" stroke="#999999" strokeWidth="1.75" id="svg_176" y2="761.75472" x2="365.26341" y1="761.66111" x1="86.62748" fill="none" />
                    <path fill="#666666" stroke="#b2b2b2" strokeWidth="0"  d="m254.87,810.57882" id="svg_340" />
                    <line transform="rotate(90 347.823 994.594)" stroke="#999999" strokeWidth="1.75" id="svg_548" y2="994.59381" x2="416.55658" y1="994.59382" x1="279.08853" fill="none" />
                    <path fill="#666666" stroke="#b2b2b2" strokeWidth="0"  d="m494.87,1091.74269" id="svg_585" />
                    <line transform="rotate(90 225.657 1031.22)" stroke="#999999" strokeWidth="1.75" id="svg_593" y2="1031.21714" x2="331.01244" y1="1031.21715" x1="120.30189" fill="none" />
                    <path fill="#666666" stroke="#b2b2b2" strokeWidth="0"  d="m254.87,1091.74269" id="svg_603" />
                    <rect stroke="#b2b2b2" id="WS003" data-tip="" className={floorState.WS003.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="967.99318" x="58.37918" fill="#ffffff" transform="rotate(90 75.8796 977.996)" />
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS003_text" data-tip="" className={`${floorState.WS003.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="981.61449" x="66.51166" fill="#000000" transform="rotate(90 76.5156 977.391)">003</text>
                    <line strokeWidth="1.75" id="svg_710" y2="1043.56143" x2="118.70131" y1="1043.56144" x1="70.53751" fill="none" stroke="#999999" transform="rotate(90 94.6194 1043.56)" />
                    <line strokeWidth="1.75" id="svg_711" y2="1008.57889" x2="82.77856" y1="1008.5789" x1="52.78928" fill="none" stroke="#999999" />
                    <rect stroke="#b2b2b2" id="WS002" data-tip="" className={floorState.WS002.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="1029.65981" x="58.37918" fill="#ffffff" transform="rotate(90 75.8796 1039.66)" />
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS002_text" data-tip="" className={`${floorState.WS002.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1043.28112" x="66.51166" fill="#000000" transform="rotate(90 76.5156 1039.06)">002</text>
                    <rect stroke="#b2b2b2" id="WS001" data-tip="" className={floorState.WS001.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="1029.54706" x="95.74493" fill="#ffffff" transform="rotate(90 113.246 1039.55)" />
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS001_text" data-tip="" className={`${floorState.WS001.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1043.33162" x="103.92429" fill="#000000" transform="rotate(90 113.93 1039.11)">001</text>
                    <line strokeWidth="1.75" id="svg_716" y2="1067.79893" x2="135.85127" y1="1067.79894" x1="53.6803" fill="none" stroke="#999999" />
                    <line strokeWidth="1.75" id="svg_717" y2="1009.50918" x2="113.09786" y1="1009.50919" x1="-4.89275" fill="none" stroke="#999999" transform="rotate(90 54.1026 1009.51)" />
                    <line strokeWidth="1.75" id="svg_718" y2="951.68298" x2="82.77856" y1="951.68299" x1="52.78928" fill="none" stroke="#999999" />
                    <line transform="rotate(90 693.656 966.723)" stroke="#999999" strokeWidth="1.75" id="svg_638" y2="966.72275" x2="726.57741" y1="966.72276" x1="660.7348" fill="none" />
                    <path fill="#666666" stroke="#b2b2b2" strokeWidth="0"  d="m722.87,1092.74269" id="svg_678" />
                    <line transform="rotate(90 466.294 1029.35)" stroke="#999999" strokeWidth="1.75" id="svg_575" y2="1029.35373" x2="571.72537" y1="1029.35374" x1="360.8629" fill="none" />
                    <line transform="rotate(90 589.824 1030.29)" stroke="#999999" strokeWidth="1.75" id="svg_666" y2="1030.29173" x2="695.26353" y1="1030.29174" x1="484.3837" fill="none" />
                    <rect stroke="#b2b2b2" id="WS087" data-tip="" className={floorState.WS087.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="1244.56572" x="345.55648" fill="#ffffff" transform="rotate(90 363.057 1254.57)" />
                    <rect stroke="#b2b2b2" id="WS090" data-tip="" className={floorState.WS090.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="1244.934" x="390.21784" fill="#ffffff" transform="rotate(90 407.718 1254.94)" />
                    <text transform="rotate(90 363.745 1254.27)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS087_text" data-tip="" className={`${floorState.WS087.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1258.35028" x="353.73584" fill="#000000">087</text>
                    <rect stroke="#b2b2b2" id="WS088" data-tip="" className={floorState.WS088.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="1188.48903" x="345.45749" fill="#ffffff" transform="rotate(90 362.958 1198.49)" />
                    <rect stroke="#b2b2b2" id="WS089" data-tip="" className={floorState.WS089.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="1188.18713" x="390.12004" fill="#ffffff" transform="rotate(90 407.62 1198.19)" />
                    <text transform="rotate(90 407.941 1197.94)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS089_text" data-tip="" className={`${floorState.WS089.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1202.01887" x="397.93087" fill="#000000">089</text>
                    <text transform="rotate(90 363.204 1198.65)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS088_text" data-tip="" className={`${floorState.WS088.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1202.72631" x="353.19462" fill="#000000">088</text>
                    <text transform="rotate(90 408.36 1254.47)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS090_text" data-tip="" className={`${floorState.WS090.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1258.55531" x="398.35032" fill="#000000">090</text>
                    <line stroke="#999999" strokeWidth="1.75" id="svg_154" y2="1227.2398" x2="374.31966" y1="1227.23981" x1="345.48201" fill="none" />
                    <line stroke="#999999" strokeWidth="1.75" id="svg_155" y2="1227.2398" x2="426.02332" y1="1227.23981" x1="397.18566" fill="none" />
                    <line strokeWidth="1.75" id="svg_173" y2="1225.76836" x2="485.11562" y1="1225.76837" x1="367.8096" fill="none" stroke="#999999" transform="rotate(90 426.463 1225.77)" />
                    <rect stroke="#b2b2b2" id="WS091" data-tip="" className={floorState.WS091.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="1244.82125" x="427.58359" fill="#ffffff" transform="rotate(90 445.084 1254.82)" />
                    <rect stroke="#b2b2b2" id="WS135" data-tip="" className={floorState.WS135.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="1245.06703" x="475.08593" fill="#ffffff" transform="rotate(90 492.586 1255.07)" />
                    <text transform="rotate(90 445.773 1254.53)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS091_text" data-tip="" className={`${floorState.WS091.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1258.60581" x="435.76295" fill="#000000">091</text>
                    <rect stroke="#b2b2b2" id="WS092" data-tip="" className={floorState.WS092.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="1189.04294" x="428.15574" fill="#ffffff" transform="rotate(90 445.656 1199.04)" />
                    <rect stroke="#b2b2b2" id="WS134" data-tip="" className={floorState.WS134.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="1189.41218" x="474.98813" fill="#ffffff" transform="rotate(90 492.488 1199.41)" />
                    <text transform="rotate(90 492.809 1199.16)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS134_text" data-tip="" className={`${floorState.WS134.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1203.24392" x="482.79896" fill="#000000">134</text>
                    <text transform="rotate(90 445.902 1199.2)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS092_text" data-tip="" className={`${floorState.WS092.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1203.28022" x="435.89287" fill="#000000">092</text>
                    <text transform="rotate(90 493.343 1254.61)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS135_text" data-tip="" className={`${floorState.WS135.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1258.68834" x="483.21841" fill="#000000">135</text>
                    <line stroke="#999999" strokeWidth="1.75" id="svg_184" y2="1227.18645" x2="455.67564" y1="1227.18646" x1="426.83798" fill="none" />
                    <line strokeWidth="1.75" id="svg_185" y2="1227.18645" x2="510.22027" y1="1227.18646" x1="479.36919" fill="none" stroke="#999999" />
                    <rect stroke="#f1f1f1" id="svg_742" height="1174.12706" width="17.5336" y="154.63039" x="745.67788" strokeWidth="0" fill="#e5e5e5" />
                    <path id="svg_743" d="m111.48461,148.50749l651.82928,0l0,17.44089l-651.82928,0l0,-17.44089z"  strokeWidth="0" stroke="#f1f1f1" fill="#e5e5e5" />
                    <rect stroke="#f1f1f1" id="svg_745" height="41.65915" width="731.61126" y="1287.29922" x="29.7104" strokeWidth="0" fill="#e5e5e5" />
                    <rect stroke="#f1f1f1" id="svg_746" height="399.60673" width="23.78375" y="929.46377" x="28.36268" strokeWidth="0" fill="#e5e5e5" />
                    <path transform="rotate(93 79.3125 906.973)" stroke="#666666" fill="#e5e5e5" strokeWidth="0" d="m24.19177,954.34848l0,-94.75056l110.24149,94.75056l-110.24149,0z" id="svg_3" />
                    <path transform="rotate(90 64.9034 897.218)" stroke="#666666" fill="#999" strokeWidth="0" d="m17.19325,945.76926l0,-97.10141l95.42029,97.10141l-95.42029,0z" id="svg_733" />
                    <rect stroke="#f1f1f1" id="svg_744" height="707.15488" width="18.56457" y="148.4786" x="110.92418" strokeWidth="0" fill="#e5e5e5" />
                    <path fill="#e5e5e5" stroke="#f1f1f1" strokeWidth="0"  transform="rotate(-45 86.5495 894.038)" d="m30.75534,894.03827l111.58843,-0.00001" id="svg_18" />
                    <path stroke="#999999" strokeWidth="1.75" id="CU03" data-tip="" className={floorState.CU03.status} onClick={(e) => { seatSelected(e) }} d="m281.18893,166.89095l65.44782,0l0,123.12936l-65.44782,0l0,-123.12936z"  fill="#b2b2b2" />
                    <path stroke="#999999" strokeWidth="1.75" id="CU02" data-tip="" className={floorState.CU02.status} onClick={(e) => { seatSelected(e) }} d="m217.52488,166.87501l64.13057,0l0,122.94869l-64.13057,0l0,-122.94869z"  fill="#b2b2b2" />
                    <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="12" id="CU01_text" data-tip="" className={`${floorState.CU01.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="197.41155" x="166.211" strokeWidth="0" stroke="#b2b2b2" fill="#666666">CU-01</text>
                    <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="12" id="CU02_text" data-tip="" className={`${floorState.CU02.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="197.41155" x="232.211" strokeWidth="0" stroke="#b2b2b2" fill="#666666">CU-02</text>
                    <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="12" id="CU03_text" data-tip="" className={`${floorState.CU03.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="197.41155" x="297.211" strokeWidth="0" stroke="#b2b2b2" fill="#666666">CU-03</text>
                    <g id="svg_106">
                        <rect id="svg_22" height="41.25424" width="21.74576" y="214.67709" x="176.17522" fill="none" stroke="#999999" transform="rotate(90 187.048 235.304)" />
                        <rect id="svg_23" height="8.93882" width="9.343" y="210.91558" x="182.42156" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_25" height="8.93882" width="9.343" y="251.91558" x="175.42156" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_31" height="8.93882" width="9.343" y="251.91558" x="189.42156" strokeWidth="1.75" fill="none" stroke="#999999" />
                    </g>
                    <g id="svg_265">
                        <rect id="svg_33" height="41.25424" width="21.74576" y="213.67709" x="240.17522" fill="none" stroke="#999999" transform="rotate(90 251.048 234.304)" />
                        <rect id="svg_34" height="8.93882" width="9.343" y="209.91558" x="246.42156" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_35" height="8.93882" width="9.343" y="250.91558" x="239.42156" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_36" height="8.93882" width="9.343" y="250.91558" x="253.42156" strokeWidth="1.75" fill="none" stroke="#999999" />
                    </g>
                    <g id="svg_270">
                        <rect id="svg_38" height="41.25424" width="21.74576" y="212.67709" x="304.17522" fill="none" stroke="#999999" transform="rotate(90 315.048 233.304)" />
                        <rect id="svg_39" height="8.93882" width="9.343" y="208.91558" x="310.42156" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_40" height="8.93882" width="9.343" y="249.91558" x="303.42156" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_41" height="8.93882" width="9.343" y="249.91558" x="317.42156" strokeWidth="1.75" fill="none" stroke="#999999" />
                    </g>
                    <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="23" id="svg_44" y="195.69608" x="559.59908" strokeWidth="0" stroke="#b2b2b2" fill="#666666">4A</text>
                    <text transform="rotate(90 120.822 228.633)" fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="11" id="svg_272" y="232.47271" x="71.59629" strokeWidth="0" stroke="#b2b2b2" fill="#666666">EMERGENCY EXIT</text>
                    <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="11" id="svg_47" y="618.06357" x="645.0142" strokeWidth="0" stroke="#b2b2b2" fill="#666666">EMERGENCY EXIT</text>
                    <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="11" id="svg_48" y="1126.05909" x="69.29229" strokeWidth="0" stroke="#b2b2b2" fill="#666666">ENTRY</text>
                    <path stroke="#b2b2b2" fill="#666666" strokeWidth="0" d="m110.87687,1128.00244l4.63027,-4.85677l-4.63027,-4.8568l2.64643,-2.77561l7.27726,7.63241l-7.27726,7.63478" id="svg_49" />
                    <rect transform="rotate(90 483.851 342.603)" stroke="#b2b2b2" id="WS125" data-tip="" className={floorState.WS125.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="332.60484" x="466.3501" fill="#ffffff" />
                    <text transform="rotate(90 484.212 342.762)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS125_text" data-tip="" className={`${floorState.WS125.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="346.84212" x="474.08723" fill="#000000">125</text>
                    <rect transform="rotate(90 448.815 342.749)" stroke="#b2b2b2" id="WS114" data-tip="" className={floorState.WS114.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="332.74903" x="431.3144" fill="#ffffff" />
                    <text transform="rotate(90 448.689 342.5)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS114_text" data-tip="" className={`${floorState.WS114.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="346.58077" x="439.12523" fill="#000000">114</text>
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m424.4122,365.49588l35,0l0,20l-35,0l0,-20z" id="WS113" data-tip="" className={floorState.WS113.status} onClick={(e) => { seatSelected(e) }} />
                    <rect transform="rotate(90 447.913 440.498)" stroke="#b2b2b2" id="WS111" data-tip="" className={floorState.WS111.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="430.4959" x="430.4122" fill="#ffffff" />
                    <text transform="rotate(90 447.667 440.037)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS111_text" data-tip="" className={`${floorState.WS111.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="444.11721" x="438.54468" fill="#000000">111</text>
                    <path transform="rotate(90 447.912 481.162)" id="WS110" data-tip="" className={floorState.WS110.status} onClick={(e) => { seatSelected(e) }} d="m430.41223,471.16254l35,0l0,20l-35,0l0,-20z"  stroke="#b2b2b2" fill="#ffffff" />
                    <text transform="rotate(90 448.108 480.703)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS110_text" data-tip="" className={`${floorState.WS110.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="484.78384" x="438.54468" fill="#000000">110</text>
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m424.4122,397.49588l35,0l0,20l-35,0l0,-20z" id="WS112" data-tip="" className={floorState.WS112.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m424.4122,504.49588l35,0l0,20l-35,0l0,-20z" id="WS109" data-tip="" className={floorState.WS109.status} onClick={(e) => { seatSelected(e) }} />
                    <rect transform="rotate(90 447.814 580.498)" stroke="#b2b2b2" id="WS107" data-tip="" className={floorState.WS107.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="570.4959" x="430.31327" fill="#ffffff" />
                    <text transform="rotate(90 447.72 580.772)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS107_text" data-tip="" className={`${floorState.WS107.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="584.8525" x="437.71046" fill="#000000">107</text>
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m424.4122,537.49588l35,0l0,20l-35,0l0,-20z" id="WS108" data-tip="" className={floorState.WS108.status} onClick={(e) => { seatSelected(e) }} />
                    <rect transform="rotate(90 484.913 481.163)" stroke="#b2b2b2" id="WS127" data-tip="" className={floorState.WS127.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="471.16253" x="467.4122" fill="#ffffff" />
                    <text transform="rotate(90 485.554 480.703)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS127_text" data-tip="" className={`${floorState.WS127.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="484.78384" x="475.54468" fill="#000000">127</text>
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m329.56474,365.49588l35,0l0,20l-35,0l0,-20z" id="WS065" data-tip="" className={floorState.WS065.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m304.4122,397.49588l35,0l0,20l-35,0l0,-20z" id="WS063" data-tip="" className={floorState.WS063.status} onClick={(e) => { seatSelected(e) }} />
                    <rect transform="rotate(90 381.076 368.174)" stroke="#b2b2b2" id="WS066" data-tip="" className={floorState.WS066.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="358.17377" x="363.57531" fill="#ffffff" />
                    <text transform="rotate(90 381.396 367.925)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS066_text" data-tip="" className={`${floorState.WS066.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="372.00551" x="371.38614" fill="#000000">066</text>
                    <rect transform="rotate(90 312.554 368.174)" stroke="#b2b2b2" id="WS064" data-tip="" className={floorState.WS064.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="358.17377" x="295.05349" fill="#ffffff" />
                    <text transform="rotate(90 312.874 367.925)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS064_text" data-tip="" className={`${floorState.WS064.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="372.00551" x="302.86432" fill="#000000">064</text>
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m355.4122,397.49588l35,0l0,20l-35,0l0,-20z" id="WS067" data-tip="" className={floorState.WS067.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m304.4122,504.49588l35,0l0,20l-35,0l0,-20z" id="WS060" data-tip="" className={floorState.WS060.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m304.4122,537.49588l35,0l0,20l-35,0l0,-20z" id="WS059" data-tip="" className={floorState.WS059.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m356.4122,504.49588l35,0l0,20l-35,0l0,-20z" id="WS070" data-tip="" className={floorState.WS070.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m356.4122,537.49588l35,0l0,20l-35,0l0,-20z" id="WS071" data-tip="" className={floorState.WS071.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m305.4122,663.72897l35,0l0,20l-35,0l0,-20z" id="WS056" data-tip="" className={floorState.WS056.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m356.4122,663.72897l35,0l0,20l-35,0l0,-20z" id="WS074" data-tip="" className={floorState.WS074.status} onClick={(e) => { seatSelected(e) }} />
                    <rect transform="rotate(90 366.143 580.128)" stroke="#b2b2b2" id="WS072" data-tip="" className={floorState.WS072.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="570.12762" x="348.64163" fill="#ffffff" />
                    <rect transform="rotate(90 329.08 580.498)" stroke="#b2b2b2" id="WS058" data-tip="" className={floorState.WS058.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="570.4959" x="311.57887" fill="#ffffff" />
                    <rect transform="rotate(90 366.143 641.026)" stroke="#b2b2b2" id="WS073" data-tip="" className={floorState.WS073.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="631.02734" x="348.64163" fill="#ffffff" />
                    <rect transform="rotate(90 330.179 641.396)" stroke="#b2b2b2" id="WS057" data-tip="" className={floorState.WS057.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="631.39562" x="312.6778" fill="#ffffff" />
                    <g id="svg_219">
                        <rect transform="rotate(90 366.143 440.131)" stroke="#b2b2b2" id="WS068" data-tip="" className={floorState.WS068.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="430.13089" x="348.64163" fill="#ffffff" />
                        <rect transform="rotate(90 329.08 440.501)" stroke="#b2b2b2" id="WS062" data-tip="" className={floorState.WS062.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="430.49917" x="311.57887" fill="#ffffff" />
                        <rect transform="rotate(90 366.143 480.796)" stroke="#b2b2b2" id="WS069" data-tip="" className={floorState.WS069.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="470.79752" x="348.64163" fill="#ffffff" />
                        <rect transform="rotate(90 329.08 481.166)" stroke="#b2b2b2" id="WS061" data-tip="" className={floorState.WS061.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="471.1658" x="311.57887" fill="#ffffff" />
                    </g>
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m305.4122,697.64121l35,0l0,20l-35,0l0,-20z" id="WS055" data-tip="" className={floorState.WS055.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m356.4122,697.64121l35,0l0,20l-35,0l0,-20z" id="WS075" data-tip="" className={floorState.WS075.status} onClick={(e) => { seatSelected(e) }} />
                    <rect transform="rotate(90 367.013 740.357)" stroke="#b2b2b2" id="WS076" data-tip="" className={floorState.WS076.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="730.35743" x="349.51117" fill="#ffffff" />
                    <rect transform="rotate(90 329.95 740.727)" stroke="#b2b2b2" id="WS054" data-tip="" className={floorState.WS054.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="730.72571" x="312.44841" fill="#ffffff" />
                    <rect transform="rotate(90 367.013 781.023)" stroke="#b2b2b2" id="WS077" data-tip="" className={floorState.WS077.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="771.02406" x="349.51117" fill="#ffffff" />
                    <rect transform="rotate(90 329.95 781.393)" stroke="#b2b2b2" id="WS053" data-tip="" className={floorState.WS053.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="771.39234" x="312.44841" fill="#ffffff" />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m305.13232,804.59524l35,0l0,20l-35,0l0,-20z" id="WS052" data-tip="" className={floorState.WS052.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m356.4122,804.59524l35,0l0,20l-35,0l0,-20z" id="WS078" data-tip="" className={floorState.WS078.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m305.13231,840.24657l35,0l0,20l-35,0l0,-20z" id="WS051" data-tip="" className={floorState.WS051.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m356.4122,840.24657l35,0l0,20l-35,0l0,-20z" id="WS080" data-tip="" className={floorState.WS080.status} onClick={(e) => { seatSelected(e) }} />
                    <rect transform="rotate(90 380.646 883.07)" stroke="#b2b2b2" id="WS079" data-tip="" className={floorState.WS079.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="873.0706" x="363.14401" fill="#ffffff" />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m424.95244,663.72897l35,0l0,20l-35,0l0,-20z" id="WS105" data-tip="" className={floorState.WS105.status} onClick={(e) => { seatSelected(e) }} />
                    <rect transform="rotate(90 484.409 641.026)" stroke="#b2b2b2" id="WSL1" data-tip="" className={floorState.WSL1.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="631.02734" x="466.90798" fill="#ffffff" />
                    <rect transform="rotate(90 449.257 641.396)" stroke="#b2b2b2" id="WS106" data-tip="" className={floorState.WS106.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="631.39562" x="431.75606" fill="#ffffff" />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m424.95244,697.64121l35,0l0,20l-35,0l0,-20z" id="WS104" data-tip="" className={floorState.WS104.status} onClick={(e) => { seatSelected(e) }} />
                    <rect transform="rotate(90 485.279 740.357)" stroke="#b2b2b2" id="WSL2" data-tip="" className={floorState.WSL2.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="730.35743" x="467.77753" fill="#ffffff" />
                    <rect transform="rotate(90 449.49 740.727)" stroke="#b2b2b2" id="WS103" data-tip="" className={floorState.WS103.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="730.72571" x="431.98866" fill="#ffffff" />
                    <rect transform="rotate(90 485.279 781.023)" stroke="#b2b2b2" id="WSL3" data-tip="" className={floorState.WSL3.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="771.02406" x="467.77753" fill="#ffffff" />
                    <rect transform="rotate(90 449.49 781.393)" stroke="#b2b2b2" id="WS102" data-tip="" className={floorState.WS102.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="771.39234" x="431.98866" fill="#ffffff" />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m424.67256,804.59524l35,0l0,20l-35,0l0,-20z" id="WS101" data-tip="" className={floorState.WS101.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m424.67256,840.24657l35,0l0,20l-35,0l0,-20z" id="WS100" data-tip="" className={floorState.WS100.status} onClick={(e) => { seatSelected(e) }} />
                    <rect transform="rotate(90 448.461 883.07)" stroke="#b2b2b2" id="WS099" data-tip="" className={floorState.WS099.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="873.0706" x="430.96011" fill="#ffffff" />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m183.57311,663.72897l35,0l0,20l-35,0l0,-20z" id="WS015" data-tip="" className={floorState.WS015.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m234.57311,663.72897l35,0l0,20l-35,0l0,-20z" id="WS033" data-tip="" className={floorState.WS033.status} onClick={(e) => { seatSelected(e) }} />
                    <rect transform="rotate(90 244.304 641.026)" stroke="#b2b2b2" id="WS032" data-tip="" className={floorState.WS032.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="631.02734" x="226.80254" fill="#ffffff" />
                    <rect transform="rotate(90 208.34 641.396)" stroke="#b2b2b2" id="WS016" data-tip="" className={floorState.WS016.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="631.39562" x="190.83871" fill="#ffffff" />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m183.57311,697.64121l35,0l0,20l-35,0l0,-20z" id="WS014" data-tip="" className={floorState.WS014.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m234.57311,697.64121l35,0l0,20l-35,0l0,-20z" id="WS034" data-tip="" className={floorState.WS034.status} onClick={(e) => { seatSelected(e) }} />
                    <rect transform="rotate(90 245.174 740.357)" stroke="#b2b2b2" id="WS035" data-tip="" className={floorState.WS035.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="730.35743" x="227.67209" fill="#ffffff" />
                    <rect transform="rotate(90 245.174 781.023)" stroke="#b2b2b2" id="WS036" data-tip="" className={floorState.WS036.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="771.02406" x="227.67209" fill="#ffffff" />
                    <rect transform="rotate(90 208.111 760.206)" stroke="#b2b2b2" id="WS013" data-tip="" className={floorState.WS013.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="750.2059" x="190.60933" fill="#ffffff" />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m183.29323,804.59524l35,0l0,20l-35,0l0,-20z" id="WS012" data-tip="" className={floorState.WS012.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m234.57311,804.59524l35,0l0,20l-35,0l0,-20z" id="WS037" data-tip="" className={floorState.WS037.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m183.29323,840.24657l35,0l0,20l-35,0l0,-20z" id="WS011" data-tip="" className={floorState.WS011.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m234.57311,840.24657l35,0l0,20l-35,0l0,-20z" id="WS038" data-tip="" className={floorState.WS038.status} onClick={(e) => { seatSelected(e) }} />
                    <rect transform="rotate(90 207.082 883.07)" stroke="#b2b2b2" id="WS010" data-tip="" className={floorState.WS010.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="873.0706" x="189.58078" fill="#ffffff" />
                    <rect transform="rotate(90 245.013 883.07)" stroke="#b2b2b2" id="WS039" data-tip="" className={floorState.WS039.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="873.0706" x="227.51181" fill="#ffffff" />
                    <g id="svg_370">
                        <path fill="#ffffff" stroke="#b2b2b2"  d="m183.57311,967.34739l35,0l0,20l-35,0l0,-20z" id="WS008" data-tip="" className={floorState.WS008.status} onClick={(e) => { seatSelected(e) }} />
                        <path fill="#ffffff" stroke="#b2b2b2"  d="m234.57311,967.34739l35,0l0,20l-35,0l0,-20z" id="WS041" data-tip="" className={floorState.WS041.status} onClick={(e) => { seatSelected(e) }} />
                        <rect transform="rotate(90 244.304 944.644)" stroke="#b2b2b2" id="WS040" data-tip="" className={floorState.WS040.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="934.64576" x="226.80254" fill="#ffffff" />
                        <rect transform="rotate(90 207.241 945.014)" stroke="#b2b2b2" id="WS009" data-tip="" className={floorState.WS009.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="935.01404" x="189.73978" fill="#ffffff" />
                        <path fill="#ffffff" stroke="#b2b2b2"  d="m183.57311,1001.25963l35,0l0,20l-35,0l0,-20z" id="WS007" data-tip="" className={floorState.WS007.status} onClick={(e) => { seatSelected(e) }} />
                        <path fill="#ffffff" stroke="#b2b2b2"  d="m234.57311,1001.25963l35,0l0,20l-35,0l0,-20z" id="WS042" data-tip="" className={floorState.WS042.status} onClick={(e) => { seatSelected(e) }} />
                        <rect transform="rotate(90 245.174 1043.98)" stroke="#b2b2b2" id="WS043" data-tip="" className={floorState.WS043.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="1033.97585" x="227.67209" fill="#ffffff" />
                        <rect transform="rotate(90 208.111 1044.35)" stroke="#b2b2b2" id="WS006" data-tip="" className={floorState.WS006.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="1034.34413" x="190.60933" fill="#ffffff" />
                        <rect transform="rotate(90 245.174 1084.64)" stroke="#b2b2b2" id="WS044" data-tip="" className={floorState.WS044.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="1074.64248" x="227.67209" fill="#ffffff" />
                        <rect transform="rotate(90 208.111 1085.01)" stroke="#b2b2b2" id="WS005" data-tip="" className={floorState.WS005.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="1075.01076" x="190.60933" fill="#ffffff" />
                        <path fill="#ffffff" stroke="#b2b2b2"  d="m183.29323,1108.21366l35,0l0,20l-35,0l0,-20z" id="WS004" data-tip="" className={floorState.WS004.status} onClick={(e) => { seatSelected(e) }} />
                        <path fill="#ffffff" stroke="#b2b2b2"  d="m234.57311,1108.21366l35,0l0,20l-35,0l0,-20z" id="WS045" data-tip="" className={floorState.WS045.status} onClick={(e) => { seatSelected(e) }} />
                    </g>
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m424.4822,967.34739l35,0l0,20l-35,0l0,-20z" id="WS097" data-tip="" className={floorState.WS097.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m475.4822,967.34739l35,0l0,20l-35,0l0,-20z" id="WS130" data-tip="" className={floorState.WS130.status} onClick={(e) => { seatSelected(e) }} />
                    <rect transform="rotate(90 485.211 944.644)" stroke="#b2b2b2" id="WS129" data-tip="" className={floorState.WS129.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="934.64576" x="467.71163" fill="#ffffff" />
                    <rect transform="rotate(90 448.148 945.014)" stroke="#b2b2b2" id="WS098" data-tip="" className={floorState.WS098.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="935.01404" x="430.64887" fill="#ffffff" />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m424.4822,1001.25963l35,0l0,20l-35,0l0,-20z" id="WS096" data-tip="" className={floorState.WS096.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m475.4822,1001.25963l35,0l0,20l-35,0l0,-20z" id="WS131" data-tip="" className={floorState.WS131.status} onClick={(e) => { seatSelected(e) }} />
                    <rect transform="rotate(90 486.081 1064.32)" stroke="#b2b2b2" id="WS132" data-tip="" className={floorState.WS132.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="1054.31483" x="468.58117" fill="#ffffff" />
                    <rect transform="rotate(90 449.018 1044.35)" stroke="#b2b2b2" id="WS095" data-tip="" className={floorState.WS095.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="1034.34413" x="431.51841" fill="#ffffff" />
                    <rect transform="rotate(90 449.018 1085.01)" stroke="#b2b2b2" id="WS094" data-tip="" className={floorState.WS094.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="1075.01076" x="431.51841" fill="#ffffff" />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m424.20232,1108.21366l35,0l0,20l-35,0l0,-20z" id="WS093" data-tip="" className={floorState.WS093.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m475.4822,1108.21366l35,0l0,20l-35,0l0,-20z" id="WS133" data-tip="" className={floorState.WS133.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m305.16402,967.34739l35,0l0,20l-35,0l0,-20z" id="WS049" data-tip="" className={floorState.WS049.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m356.16402,967.34739l35,0l0,20l-35,0l0,-20z" id="WS082" data-tip="" className={floorState.WS082.status} onClick={(e) => { seatSelected(e) }} />
                    <rect transform="rotate(90 365.894 944.644)" stroke="#b2b2b2" id="WS081" data-tip="" className={floorState.WS081.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="934.64576" x="348.39345" fill="#ffffff" />
                    <rect transform="rotate(90 328.831 945.014)" stroke="#b2b2b2" id="WS050" data-tip="" className={floorState.WS050.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="935.01404" x="311.33069" fill="#ffffff" />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m305.16402,1001.25963l35,0l0,20l-35,0l0,-20z" id="WS048" data-tip="" className={floorState.WS048.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m356.16402,1001.25963l35,0l0,20l-35,0l0,-20z" id="WS083" data-tip="" className={floorState.WS083.status} onClick={(e) => { seatSelected(e) }} />
                    <rect transform="rotate(90 366.764 1043.98)" stroke="#b2b2b2" id="WS084" data-tip="" className={floorState.WS084.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="1033.97585" x="349.26299" fill="#ffffff" />
                    <rect transform="rotate(90 329.701 1044.35)" stroke="#b2b2b2" id="WS047" data-tip="" className={floorState.WS047.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="1034.34413" x="312.20023" fill="#ffffff" />
                    <rect transform="rotate(90 380.4 1110.07)" stroke="#b2b2b2" id="WS086" data-tip="" className={floorState.WS086.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="1100.06721" x="362.89936" fill="#ffffff" />
                    <rect transform="rotate(90 314.929 1110.44)" stroke="#b2b2b2" id="WS046" data-tip="" className={floorState.WS046.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="1100.43549" x="297.42751" fill="#ffffff" />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m330.02765,1108.21366l35,0l0,20l-35,0l0,-20z" id="WS085" data-tip="" className={floorState.WS085.status} onClick={(e) => { seatSelected(e) }} />
                    <g id="svg_404">
                        <path fill="#ffffff" stroke="#b2b2b2"  d="m546.21784,967.34739l35,0l0,20l-35,0l0,-20z" id="WS140" data-tip="" className={floorState.WS140.status} onClick={(e) => { seatSelected(e) }} />
                        <rect transform="rotate(90 569.882 945.014)" stroke="#b2b2b2" id="WS141" data-tip="" className={floorState.WS141.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="935.01404" x="552.38451" fill="#ffffff" />
                        <path fill="#ffffff" stroke="#b2b2b2"  d="m546.21784,1001.25963l35,0l0,20l-35,0l0,-20z" id="WS139" data-tip="" className={floorState.WS139.status} onClick={(e) => { seatSelected(e) }} />
                        <rect transform="rotate(90 570.752 1044.35)" stroke="#b2b2b2" id="WS138" data-tip="" className={floorState.WS138.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="1034.34413" x="553.25406" fill="#ffffff" />
                        <rect transform="rotate(90 570.752 1085.01)" stroke="#b2b2b2" id="WS137" data-tip="" className={floorState.WS137.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="1075.01076" x="553.25406" fill="#ffffff" />
                        <path fill="#ffffff" stroke="#b2b2b2"  d="m545.93796,1108.21366l35,0l0,20l-35,0l0,-20z" id="WS136" data-tip="" className={floorState.WS136.status} onClick={(e) => { seatSelected(e) }} />
                    </g>
                    <g id="svg_411">
                        <path fill="#ffffff" stroke="#b2b2b2"  d="m651.6496,941.26118l35,0l0,20l-35,0l0,-20z" id="WS146" data-tip="" className={floorState.WS146.status} onClick={(e) => { seatSelected(e) }} />
                        <path fill="#ffffff" stroke="#b2b2b2"  d="m702.6496,941.26118l35,0l0,20l-35,0l0,-20z" id="WS147" data-tip="" className={floorState.WS147.status} onClick={(e) => { seatSelected(e) }} />
                        <path fill="#ffffff" stroke="#b2b2b2"  d="m651.6496,975.17342l35,0l0,20l-35,0l0,-20z" id="WS145" data-tip="" className={floorState.WS145.status} onClick={(e) => { seatSelected(e) }} />
                        <path fill="#ffffff" stroke="#b2b2b2"  d="m702.6496,975.17342l35,0l0,20l-35,0l0,-20z" id="WS144" data-tip="" className={floorState.WS144.status} onClick={(e) => { seatSelected(e) }} />
                    </g>
                    <g id="svg_414">
                        <path fill="#ffffff" stroke="#b2b2b2"  d="m651.64959,1073.86608l35,0l0,20l-35,0l0,-20z" id="WS143" data-tip="" className={floorState.WS143.status} onClick={(e) => { seatSelected(e) }} />
                        <path fill="#ffffff" stroke="#b2b2b2"  d="m651.64959,1107.77832l35,0l0,20l-35,0l0,-20z" id="WS142" data-tip="" className={floorState.WS142.status} onClick={(e) => { seatSelected(e) }} />
                    </g>
                    <g id="svg_415">
                        <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="11" id="svg_149" y="1213.41163" x="525.31356" strokeWidth="0" stroke="#b2b2b2" fill="#666666">MEETING</text>
                        <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="11" id="svg_153" y="1213.35949" x="577.61189" strokeWidth="0" stroke="#b2b2b2" fill="#666666">ROOM</text>
                    </g>
                    <g id="svg_416">
                        <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="11" id="svg_8" y="1213.08227" x="639.75783" strokeWidth="0" stroke="#b2b2b2" fill="#666666">MEETING</text>
                        <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="11" id="svg_147" y="1213.03014" x="692.91822" strokeWidth="0" stroke="#b2b2b2" fill="#666666">ROOM</text>
                    </g>
                    <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="23" id="svg_418" y="1192.40664" x="553.07753" strokeWidth="0" stroke="#b2b2b2" fill="#666666">4C</text>
                    <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="23" id="svg_419" y="1192.40664" x="668.29162" strokeWidth="0" stroke="#b2b2b2" fill="#666666">4D</text>
                    <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="23" id="svg_420" y="1194.58049" x="59.6134" strokeWidth="0" stroke="#b2b2b2" fill="#666666">4B</text>
                    <g id="svg_421">
                        <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="11" id="svg_199" y="1280.67972" x="70.48182" strokeWidth="0" stroke="#b2b2b2" fill="#666666">MEETING</text>
                        <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="11" id="svg_201" y="1280.62758" x="124.50428" strokeWidth="0" stroke="#b2b2b2" fill="#666666">ROOM</text>
                    </g>
                    <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="23" id="svg_422" y="1252.18754" x="59.6134" strokeWidth="0" stroke="#b2b2b2" fill="#666666">4E</text>
                    <g id="svg_425">
                        <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="11" id="svg_423" y="1221.98575" x="97.65495" strokeWidth="0" stroke="#b2b2b2" fill="#666666">MEETING</text>
                        <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="11" id="svg_424" y="1221.93361" x="151.67741" strokeWidth="0" stroke="#b2b2b2" fill="#666666">ROOM</text>
                    </g>
                    <g id="svg_431" stroke="null">
                        <rect id="svg_426" height="16.6429" width="18.36812" y="1241.65559" x="106.78383" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_427" height="6.16738" width="6.54255" y="1233.24012" x="112.7607" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_428" height="6.16738" width="6.54255" y="1260.93548" x="112.7607" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_429" height="6.16738" width="6.54255" y="1245.87309" x="128.54126" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_430" height="6.16738" width="6.54255" y="1245.87309" x="96.99383" strokeWidth="1.75" fill="none" stroke="#999999" />
                    </g>
                    <g id="svg_444">
                        <rect id="svg_432" height="15.57449" width="48.80204" y="1184.59524" x="124.17463" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_433" height="5.77146" width="6.54255" y="1175.63308" x="130.1515" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_434" height="5.77146" width="6.54255" y="1202.63743" x="130.1515" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_435" height="5.77146" width="6.54255" y="1189.62891" x="176.36598" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_436" height="5.77146" width="6.54255" y="1188.54199" x="114.38464" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_438" height="5.77146" width="6.54255" y="1175.63308" x="141.02076" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_439" height="5.77146" width="6.54255" y="1175.63308" x="151.89001" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_440" height="5.77146" width="6.54255" y="1175.63308" x="162.75927" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_441" height="5.77146" width="6.54255" y="1202.80622" x="141.02076" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_442" height="5.77146" width="6.54255" y="1202.80622" x="151.89001" strokeWidth="1.75" fill="none" stroke="#999999" />
                        <rect id="svg_443" height="5.77146" width="6.54255" y="1202.80622" x="162.75927" strokeWidth="1.75" fill="none" stroke="#999999" />
                    </g>
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m234.57311,365.4479l35,0l0,20l-35,0l0,-20z" id="WS025" data-tip="" className={floorState.WS025.status} onClick={(e) => { seatSelected(e) }} />
                    <rect transform="rotate(90 244.304 342.745)" stroke="#b2b2b2" id="WS024" data-tip="" className={floorState.WS024.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="332.74626" x="226.80254" fill="#ffffff" />
                    <rect transform="rotate(90 208.34 343.115)" stroke="#b2b2b2" id="WS023" data-tip="" className={floorState.WS023.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="333.11454" x="190.83871" fill="#ffffff" />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m183.57311,398.26121l35,0l0,20l-35,0l0,-20z" id="WS022" data-tip="" className={floorState.WS022.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m234.57311,398.26121l35,0l0,20l-35,0l0,-20z" id="WS026" data-tip="" className={floorState.WS026.status} onClick={(e) => { seatSelected(e) }} />
                    <rect transform="rotate(90 245.174 440.978)" stroke="#b2b2b2" id="WS027" data-tip="" className={floorState.WS027.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="430.97743" x="227.67209" fill="#ffffff" />
                    <rect transform="rotate(90 208.111 441.348)" stroke="#b2b2b2" id="WS021" data-tip="" className={floorState.WS021.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="431.34571" x="190.60933" fill="#ffffff" />
                    <rect transform="rotate(90 245.174 481.643)" stroke="#b2b2b2" id="WS028" data-tip="" className={floorState.WS028.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="471.64406" x="227.67209" fill="#ffffff" />
                    <rect transform="rotate(90 208.111 482.013)" stroke="#b2b2b2" id="WS020" data-tip="" className={floorState.WS020.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="472.01234" x="190.60933" fill="#ffffff" />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m183.29323,505.21524l35,0l0,20l-35,0l0,-20z" id="WS019" data-tip="" className={floorState.WS019.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m234.57311,505.21524l35,0l0,20l-35,0l0,-20z" id="WS029" data-tip="" className={floorState.WS029.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m183.29323,537.56978l35,0l0,20l-35,0l0,-20z" id="WS018" data-tip="" className={floorState.WS018.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m234.57311,537.56978l35,0l0,20l-35,0l0,-20z" id="WS030" data-tip="" className={floorState.WS030.status} onClick={(e) => { seatSelected(e) }} />
                    <rect transform="rotate(90 207.082 580.393)" stroke="#b2b2b2" id="WS017" data-tip="" className={floorState.WS017.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="570.39381" x="189.58078" fill="#ffffff" />
                    <rect transform="rotate(90 245.013 580.393)" stroke="#b2b2b2" id="WS031" data-tip="" className={floorState.WS031.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="570.39381" x="227.51182" fill="#ffffff" />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m550.78902,415.41904l35,0l0,20l-35,0l0,-20z" id="WS150" data-tip="" className={floorState.WS150.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m550.78902,447.41904l35,0l0,20l-35,0l0,-20z" id="WS149" data-tip="" className={floorState.WS149.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m550.78902,545.62761l35,0l0,20l-35,0l0,-20z" id="WS148" data-tip="" className={floorState.WS148.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m600.24082,415.41904l35,0l0,20l-35,0l0,-20z" id="WS151" data-tip="" className={floorState.WS151.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m600.24082,447.41904l35,0l0,20l-35,0l0,-20z" id="WS152" data-tip="" className={floorState.WS152.status} onClick={(e) => { seatSelected(e) }} />
                    <path fill="#ffffff" stroke="#b2b2b2"  d="m600.24082,545.62761l35,0l0,20l-35,0l0,-20z" id="WS153" data-tip="" className={floorState.WS153.status} onClick={(e) => { seatSelected(e) }} />
                    <g id="svg_485">
                        <path fill="#ffffff" stroke="#b2b2b2"  d="m654.08833,415.41904l35,0l0,20l-35,0l0,-20z" id="WS156" data-tip="" className={floorState.WS156.status} onClick={(e) => { seatSelected(e) }} />
                        <path fill="#ffffff" stroke="#b2b2b2"  d="m654.08833,447.41904l35,0l0,20l-35,0l0,-20z" id="WS155" data-tip="" className={floorState.WS155.status} onClick={(e) => { seatSelected(e) }} />
                        <path fill="#ffffff" stroke="#b2b2b2"  d="m654.08833,545.62761l35,0l0,20l-35,0l0,-20z" id="WS154" data-tip="" className={floorState.WS154.status} onClick={(e) => { seatSelected(e) }} />
                        <path fill="#ffffff" stroke="#b2b2b2"  d="m703.54013,415.41904l35,0l0,20l-35,0l0,-20z" id="WS157" data-tip="" className={floorState.WS157.status} onClick={(e) => { seatSelected(e) }} />
                        <path fill="#ffffff" stroke="#b2b2b2"  d="m703.54013,447.41904l35,0l0,20l-35,0l0,-20z" id="WS158" data-tip="" className={floorState.WS158.status} onClick={(e) => { seatSelected(e) }} />
                        <path fill="#ffffff" stroke="#b2b2b2"  d="m703.54013,545.62761l35,0l0,20l-35,0l0,-20z" id="WS159" data-tip="" className={floorState.WS159.status} onClick={(e) => { seatSelected(e) }} />
                    </g>
                    <line transform="rotate(90 646.41 733.635)" strokeWidth="1.75" id="svg_486" y2="733.51445" x2="754.49907" y1="733.7554" x1="538.3205" fill="none" stroke="#999999" />
                    <line strokeWidth="1.75" id="svg_487" y2="765.86739" x2="645.67552" y1="766.10834" x1="544.97012" fill="none" stroke="#999999" />
                    <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="12" id="svg_488" y="706.31942" x="577.79586" strokeWidth="0" stroke="#b2b2b2" fill="#666666">A.H.U.</text>
                    <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="11" id="svg_489" y="800.52232" x="552.43354" strokeWidth="0" stroke="#b2b2b2" fill="#666666">PANEL</text>
                    <line transform="rotate(90 596.412 804.284)" strokeWidth="1.75" id="svg_490" y2="804.16417" x2="633.84875" y1="804.40512" x1="558.97452" fill="none" stroke="#999999" />
                    <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="11" id="svg_492" y="812.84116" x="552.43354" strokeWidth="0" stroke="#b2b2b2" fill="#666666">ROOM</text>
                    <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="11" id="svg_493" y="800.52232" x="603.88282" strokeWidth="0" stroke="#b2b2b2" fill="#666666">PANEL</text>
                    <text fontWeight="bold" textAnchor="start" fontFamily="ColgateReady" fontSize="11" id="svg_494" y="812.84116" x="603.88282" strokeWidth="0" stroke="#b2b2b2" fill="#666666">ROOM</text>
                    <text transform="rotate(90 244.392 343.497)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS024_text" data-tip="" className={`${floorState.WS024.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="347.57741" x="234.38154" fill="#000000">024</text>
                    <text transform="rotate(90 208.038 342.641)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS023_text" data-tip="" className={`${floorState.WS023.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="346.72186" x="198.02844" fill="#000000">023</text>
                    <text transform="rotate(90 245.127 441.291)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS027_text" data-tip="" className={`${floorState.WS027.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="445.37145" x="235.11684" fill="#000000">027</text>
                    <text transform="rotate(90 208.694 441.03)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS021_text" data-tip="" className={`${floorState.WS021.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="445.1101" x="198.68425" fill="#000000">021</text>
                    <text transform="rotate(90 245.127 481.732)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS028_text" data-tip="" className={`${floorState.WS028.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="485.81259" x="235.11684" fill="#000000">028</text>
                    <text transform="rotate(90 208.694 481.471)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS020_text" data-tip="" className={`${floorState.WS020.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="485.55124" x="198.68425" fill="#000000">020</text>
                    <text transform="rotate(90 365.849 440.087)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS068_text" data-tip="" className={`${floorState.WS068.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="444.16771" x="355.83976" fill="#000000">068</text>
                    <text transform="rotate(90 329.437 440.037)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS062_text" data-tip="" className={`${floorState.WS062.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="444.11721" x="319.42713" fill="#000000">062</text>
                    <text transform="rotate(90 329.437 480.703)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS061_text" data-tip="" className={`${floorState.WS061.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="484.78384" x="319.42713" fill="#000000">061</text>
                    <text transform="rotate(90 366.437 480.703)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS069_text" data-tip="" className={`${floorState.WS069.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="484.78384" x="356.42713" fill="#000000">069</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS025_text" data-tip="" className={`${floorState.WS025.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="379.66898" x="242.06656" fill="#000000">025</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS026_text" data-tip="" className={`${floorState.WS026.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="412.02189" x="242.06656" fill="#000000">026</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS022_text" data-tip="" className={`${floorState.WS022.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="412.02189" x="191.33131" fill="#000000">022</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS067_text" data-tip="" className={`${floorState.WS067.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="412.02189" x="362.6547" fill="#000000">067</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS063_text" data-tip="" className={`${floorState.WS063.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="412.02189" x="311.91945" fill="#000000">063</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS065_text" data-tip="" className={`${floorState.WS065.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="378.93368" x="337.54255" fill="#000000">065</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS113_text" data-tip="" className={`${floorState.WS113.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="379.66898" x="432.50758" fill="#000000">113</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS112_text" data-tip="" className={`${floorState.WS112.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="411.2866" x="432.50758" fill="#000000">112</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS151_text" data-tip="" className={`${floorState.WS151.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="429.74457" x="608.24273" fill="#000000">151</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS150_text" data-tip="" className={`${floorState.WS150.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="429.74457" x="557.50748" fill="#000000">150</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS152_text" data-tip="" className={`${floorState.WS152.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="461.36219" x="608.24273" fill="#000000">152</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS149_text" data-tip="" className={`${floorState.WS149.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="461.36219" x="557.50748" fill="#000000">149</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS157_text" data-tip="" className={`${floorState.WS157.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="429.74457" x="711.91912" fill="#000000">157</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS156_text" data-tip="" className={`${floorState.WS156.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="429.74457" x="661.18387" fill="#000000">156</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS158_text" data-tip="" className={`${floorState.WS158.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="461.36219" x="711.91912" fill="#000000">158</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS155_text" data-tip="" className={`${floorState.WS155.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="461.36219" x="661.18387" fill="#000000">155</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS029_text" data-tip="" className={`${floorState.WS029.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="519.37474" x="241.33126" fill="#000000">029</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS019_text" data-tip="" className={`${floorState.WS019.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="519.37474" x="190.59601" fill="#000000">019</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS030_text" data-tip="" className={`${floorState.WS030.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="550.99236" x="241.33126" fill="#000000">030</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS018_text" data-tip="" className={`${floorState.WS018.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="550.99236" x="190.59601" fill="#000000">018</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS070_text" data-tip="" className={`${floorState.WS070.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="518.63945" x="362.65469" fill="#000000">070</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS060_text" data-tip="" className={`${floorState.WS060.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="518.63945" x="311.91944" fill="#000000">060</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS071_text" data-tip="" className={`${floorState.WS071.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="550.99236" x="362.65469" fill="#000000">071</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS059_text" data-tip="" className={`${floorState.WS059.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="550.99236" x="311.91944" fill="#000000">059</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS109_text" data-tip="" className={`${floorState.WS109.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="518.63945" x="431.77228" fill="#000000">109</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS108_text" data-tip="" className={`${floorState.WS108.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="550.99236" x="431.77228" fill="#000000">108</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS153_text" data-tip="" className={`${floorState.WS153.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="559.89151" x="607.50744" fill="#000000">153</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS148_text" data-tip="" className={`${floorState.WS148.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="559.89151" x="557.50748" fill="#000000">148</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS159_text" data-tip="" className={`${floorState.WS159.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="559.89151" x="711.18382" fill="#000000">159</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS154_text" data-tip="" className={`${floorState.WS154.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="559.89151" x="660.44857" fill="#000000">154</text>
                    <text transform="rotate(90 245.127 580.261)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS031_text" data-tip="" className={`${floorState.WS031.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="584.34192" x="235.11684" fill="#000000">031</text>
                    <text transform="rotate(90 207.224 580)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS017_text" data-tip="" className={`${floorState.WS017.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="584.08057" x="197.21366" fill="#000000">017</text>
                    <text transform="rotate(90 366.338 580.261)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS072_text" data-tip="" className={`${floorState.WS072.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="584.34192" x="356.3281" fill="#000000">072</text>
                    <text transform="rotate(90 329.28 579.861)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS058_text" data-tip="" className={`${floorState.WS058.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="584.08057" x="319.27238" fill="#000000">058</text>
                    <text transform="rotate(90 244.391 640.895)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS032_text" data-tip="" className={`${floorState.WS032.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="644.97582" x="234.38154" fill="#000000">032</text>
                    <text transform="rotate(90 208.037 641.736)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS016_text" data-tip="" className={`${floorState.WS016.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="645.8166" x="198.02702" fill="#000000">016</text>
                    <text transform="rotate(90 245.241 739.67)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS035_text" data-tip="" className={`${floorState.WS035.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="743.75025" x="235.11683" fill="#000000">035</text>
                    <text transform="rotate(90 245.126 780.857)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS036_text" data-tip="" className={`${floorState.WS036.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="784.93765" x="235.11683" fill="#000000">036</text>
                    <text transform="rotate(90 208.694 759.409)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS013_text" data-tip="" className={`${floorState.WS013.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="763.48986" x="198.68425" fill="#000000">013</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS033_text" data-tip="" className={`${floorState.WS033.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="678.04778" x="242.06656" fill="#000000">033</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS034_text" data-tip="" className={`${floorState.WS034.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="711.48764" x="242.06656" fill="#000000">034</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS014_text" data-tip="" className={`${floorState.WS014.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="711.48764" x="191.33131" fill="#000000">014</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS037_text" data-tip="" className={`${floorState.WS037.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="818.84049" x="241.33126" fill="#000000">037</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS012_text" data-tip="" className={`${floorState.WS012.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="818.84049" x="190.59601" fill="#000000">012</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS038_text" data-tip="" className={`${floorState.WS038.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="854.53008" x="241.33126" fill="#000000">038</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS011_text" data-tip="" className={`${floorState.WS011.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="854.53008" x="190.59601" fill="#000000">011</text>
                    <text transform="rotate(90 245.126 883.118)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS039_text" data-tip="" className={`${floorState.WS039.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="887.19826" x="235.11683" fill="#000000">039</text>
                    <text transform="rotate(90 207.223 882.856)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS010_text" data-tip="" className={`${floorState.WS010.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="886.93691" x="197.21366" fill="#000000">010</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS015_text" data-tip="" className={`${floorState.WS015.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="678.04778" x="190.57484" fill="#000000">015</text>
                    <text transform="rotate(90 366.94 640.895)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS073_text" data-tip="" className={`${floorState.WS073.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="644.97582" x="356.93056" fill="#000000">073</text>
                    <text transform="rotate(90 329.738 640.889)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS057_text" data-tip="" className={`${floorState.WS057.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="644.96914" x="319.72858" fill="#000000">057</text>
                    <text transform="rotate(90 367.675 739.67)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS076_text" data-tip="" className={`${floorState.WS076.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="743.75025" x="357.66585" fill="#000000">076</text>
                    <text transform="rotate(90 331.243 739.408)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS054_text" data-tip="" className={`${floorState.WS054.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="743.4889" x="321.23327" fill="#000000">054</text>
                    <text transform="rotate(90 367.675 780.857)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS077_text" data-tip="" className={`${floorState.WS077.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="784.93765" x="357.66585" fill="#000000">077</text>
                    <text transform="rotate(90 331.243 780.596)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS053_text" data-tip="" className={`${floorState.WS053.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="784.6763" x="321.23327" fill="#000000">053</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS074_text" data-tip="" className={`${floorState.WS074.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="678.04778" x="364.61558" fill="#000000">074</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS075_text" data-tip="" className={`${floorState.WS075.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="711.48764" x="364.61558" fill="#000000">075</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS055_text" data-tip="" className={`${floorState.WS055.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="711.48764" x="313.88033" fill="#000000">055</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS078_text" data-tip="" className={`${floorState.WS078.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="818.84049" x="363.88028" fill="#000000">078</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS052_text" data-tip="" className={`${floorState.WS052.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="818.84049" x="313.14503" fill="#000000">052</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS080_text" data-tip="" className={`${floorState.WS080.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="854.53008" x="363.88028" fill="#000000">080</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS051_text" data-tip="" className={`${floorState.WS051.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="854.53008" x="313.14503" fill="#000000">051</text>
                    <text transform="rotate(90 380.421 883.118)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS079_text" data-tip="" className={`${floorState.WS079.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="887.19826" x="370.41095" fill="#000000">079</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS056_text" data-tip="" className={`${floorState.WS056.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="678.04778" x="313.12386" fill="#000000">056</text>
                    <text transform="rotate(90 484.294 640.895)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WSL1_text" data-tip="" className={`${floorState.WSL1.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="644.97582" x="474.28412" fill="#000000">0L1</text>
                    <text transform="rotate(90 449.213 640.041)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS106_text" data-tip="" className={`${floorState.WS106.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="644.12168" x="439.20349" fill="#000000">106</text>
                    <text transform="rotate(90 485.029 739.67)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WSL2_text" data-tip="" className={`${floorState.WSL2.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="743.75025" x="475.01941" fill="#000000">0L2</text>
                    <text transform="rotate(90 449.87 739.408)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS103_text" data-tip="" className={`${floorState.WS103.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="743.4889" x="439.86071" fill="#000000">103</text>
                    <text transform="rotate(90 485.029 780.857)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WSL3_text" data-tip="" className={`${floorState.WSL3.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="784.93765" x="475.01941" fill="#000000">0L3</text>
                    <text transform="rotate(90 449.87 780.596)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS102_text" data-tip="" className={`${floorState.WS102.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="784.6763" x="439.86071" fill="#000000">102</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS104_text" data-tip="" className={`${floorState.WS104.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="711.48764" x="432.50778" fill="#000000">104</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS101_text" data-tip="" className={`${floorState.WS101.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="818.84049" x="431.77248" fill="#000000">101</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS100_text" data-tip="" className={`${floorState.WS100.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="854.18664" x="431.77248" fill="#000000">100</text>
                    <text transform="rotate(90 448.4 881.876)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS099_text" data-tip="" className={`${floorState.WS099.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="885.95652" x="438.39013" fill="#000000">099</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS105_text" data-tip="" className={`${floorState.WS105.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="678.04778" x="431.75131" fill="#000000">105</text>
                    <text transform="rotate(90 244.391 944.956)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS040_text" data-tip="" className={`${floorState.WS040.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="949.03666" x="234.38154" fill="#000000">040</text>
                    <text transform="rotate(90 207.191 944.948)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS009_text" data-tip="" className={`${floorState.WS009.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="949.02859" x="197.18094" fill="#000000">009</text>
                    <text transform="rotate(90 245.126 1042.88)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS043_text" data-tip="" className={`${floorState.WS043.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1046.9636" x="235.11683" fill="#000000">043</text>
                    <text transform="rotate(90 208.694 1042.62)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS006_text" data-tip="" className={`${floorState.WS006.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1046.70225" x="198.68425" fill="#000000">006</text>
                    <text transform="rotate(90 245.126 1084.07)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS044_text" data-tip="" className={`${floorState.WS044.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1088.15099" x="235.11683" fill="#000000">044</text>
                    <text transform="rotate(90 208.809 1083.81)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS005_text" data-tip="" className={`${floorState.WS005.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1087.88964" x="198.68425" fill="#000000">005</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS041_text" data-tip="" className={`${floorState.WS041.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="981.26113" x="242.06656" fill="#000000">041</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS042_text" data-tip="" className={`${floorState.WS042.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1014.70099" x="242.06656" fill="#000000">042</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS007_text" data-tip="" className={`${floorState.WS007.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1014.70099" x="191.33131" fill="#000000">007</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS045_text" data-tip="" className={`${floorState.WS045.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1122.05384" x="241.33126" fill="#000000">045</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS004_text" data-tip="" className={`${floorState.WS004.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1122.05384" x="190.59601" fill="#000000">004</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS008_text" data-tip="" className={`${floorState.WS008.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="981.26113" x="190.57484" fill="#000000">008</text>
                    <text transform="rotate(90 366.173 944.956)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS081_text" data-tip="" className={`${floorState.WS081.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="949.03662" x="356.16371" fill="#000000">081</text>
                    <text transform="rotate(90 328.971 944.102)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS050_text" data-tip="" className={`${floorState.WS050.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="948.18248" x="318.96167" fill="#000000">050</text>
                    <text transform="rotate(90 366.909 1042.88)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS084_text" data-tip="" className={`${floorState.WS084.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1046.9636" x="356.899" fill="#000000">084</text>
                    <text transform="rotate(90 329.629 1042.62)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS047_text" data-tip="" className={`${floorState.WS047.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1046.70225" x="319.61893" fill="#000000">047</text>
                    <text transform="rotate(90 380.77 1109.5)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS086_text" data-tip="" className={`${floorState.WS086.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1113.57572" x="370.76039" fill="#000000">086</text>
                    <text transform="rotate(90 314.634 1109.23)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS046_text" data-tip="" className={`${floorState.WS046.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1113.31437" x="304.62483" fill="#000000">046</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS082_text" data-tip="" className={`${floorState.WS082.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="981.26113" x="363.84873" fill="#000000">082</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS083_text" data-tip="" className={`${floorState.WS083.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1014.70099" x="363.84873" fill="#000000">083</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS048_text" data-tip="" className={`${floorState.WS048.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1014.70099" x="313.11348" fill="#000000">048</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS085_text" data-tip="" className={`${floorState.WS085.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1122.05384" x="338.36096" fill="#000000">085</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS049_text" data-tip="" className={`${floorState.WS049.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="981.26113" x="312.35702" fill="#000000">049</text>
                    <text transform="rotate(90 484.985 944.109)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS129_text" data-tip="" className={`${floorState.WS129.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="948.18917" x="474.97558" fill="#000000">129</text>
                    <text transform="rotate(90 447.783 944.102)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS098_text" data-tip="" className={`${floorState.WS098.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="948.18248" x="437.7736" fill="#000000">098</text>
                    <text transform="rotate(90 485.72 1063.22)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS132_text" data-tip="" className={`${floorState.WS132.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1067.30258" x="475.71088" fill="#000000">132</text>
                    <text transform="rotate(90 449.403 1042.62)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS095_text" data-tip="" className={`${floorState.WS095.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1046.70225" x="439.27829" fill="#000000">095</text>
                    <text transform="rotate(90 449.288 1083.81)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS094_text" data-tip="" className={`${floorState.WS094.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1087.88964" x="439.27829" fill="#000000">094</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS130_text" data-tip="" className={`${floorState.WS130.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="981.26113" x="482.6606" fill="#000000">130</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS131_text" data-tip="" className={`${floorState.WS131.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1014.70099" x="482.6606" fill="#000000">131</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS096_text" data-tip="" className={`${floorState.WS096.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1014.70099" x="431.92535" fill="#000000">096</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS133_text" data-tip="" className={`${floorState.WS133.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1122.05384" x="481.9253" fill="#000000">133</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS093_text" data-tip="" className={`${floorState.WS093.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1122.05384" x="431.19005" fill="#000000">093</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS097_text" data-tip="" className={`${floorState.WS097.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="981.26113" x="431.16889" fill="#000000">097</text>
                    <text transform="rotate(90 569.565 945.092)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS141_text" data-tip="" className={`${floorState.WS141.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="949.17258" x="559.55577" fill="#000000">141</text>
                    <text transform="rotate(90 571.07 1043.61)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS138_text" data-tip="" className={`${floorState.WS138.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1047.69234" x="561.06046" fill="#000000">138</text>
                    <text transform="rotate(90 571.07 1084.8)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS137_text" data-tip="" className={`${floorState.WS137.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1088.87974" x="561.06046" fill="#000000">137</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS139_text" data-tip="" className={`${floorState.WS139.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1015.69109" x="553.70752" fill="#000000">139</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS136_text" data-tip="" className={`${floorState.WS136.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1122.05384" x="552.97222" fill="#000000">136</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS140_text" data-tip="" className={`${floorState.WS140.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="981.26113" x="552.95106" fill="#000000">140</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS147_text" data-tip="" className={`${floorState.WS147.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="955.51855" x="710.38335" fill="#000000">147</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS144_text" data-tip="" className={`${floorState.WS144.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="988.95841" x="710.38335" fill="#000000">144</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS145_text" data-tip="" className={`${floorState.WS145.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="988.95841" x="659.6481" fill="#000000">145</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS146_text" data-tip="" className={`${floorState.WS146.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="955.51855" x="658.89163" fill="#000000">146</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS142_text" data-tip="" className={`${floorState.WS142.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1121.63167" x="659.6481" fill="#000000">142</text>
                    <text stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WS143_text" data-tip="" className={`${floorState.WS143.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="1088.1918" x="658.89163" fill="#000000">143</text>
                    <line strokeWidth="1.75" id="svg_671" y2="571.57505" x2="739.30317" y1="571.57506" x1="653.23816" fill="none" stroke="#999999" />
                    <line strokeWidth="1.75" id="svg_672" y2="571.57505" x2="635.66726" y1="571.57506" x1="549.60225" fill="none" stroke="#999999" />
                    <line strokeWidth="1.75" id="svg_673" y2="441.27259" x2="635.59626" y1="441.2726" x1="549.53125" fill="none" stroke="#999999" />
                    <line strokeWidth="1.75" id="svg_679" y2="391.77544" x2="509.5964" y1="391.77545" x1="423.53139" fill="none" stroke="#999999" />
                    <line strokeWidth="1.75" id="svg_680" y2="530.44195" x2="509.5964" y1="530.44196" x1="423.53139" fill="none" stroke="#999999" />
                    <line strokeWidth="1.75" id="svg_7" y2="690.19917" x2="510.23335" y1="690.19918" x1="424.16834" fill="none" stroke="#999999" />
                    <line strokeWidth="1.75" id="svg_9" y2="832.23831" x2="510.23335" y1="832.23832" x1="424.16834" fill="none" stroke="#999999" />
                    <line strokeWidth="1.75" id="svg_10" y2="993.94535" x2="509.44595" y1="993.94536" x1="423.38094" fill="none" stroke="#999999" />
                    <line strokeWidth="1.75" id="svg_11" y2="1135.59163" x2="510.23335" y1="1135.59164" x1="424.16834" fill="none" stroke="#999999" />
                    <line strokeWidth="1.75" id="svg_14" y2="1135.59163" x2="269.28865" y1="1135.59164" x1="183.22364" fill="none" stroke="#999999" />
                    <line strokeWidth="1.75" id="svg_15" y2="690.98657" x2="391.3358" y1="690.98658" x1="305.27079" fill="none" stroke="#999999" />
                    <line strokeWidth="1.75" id="svg_16" y2="833.02571" x2="391.3358" y1="833.02572" x1="305.27079" fill="none" stroke="#999999" />
                    <line strokeWidth="1.75" id="svg_17" y2="690.98657" x2="269.09801" y1="690.98658" x1="183.033" fill="none" stroke="#999999" />
                    <line strokeWidth="1.75" id="svg_19" y2="833.02571" x2="269.09801" y1="833.02572" x1="183.033" fill="none" stroke="#999999" />
                    <line strokeWidth="1.75" id="svg_32" y2="391.77544" x2="390.57904" y1="391.77545" x1="304.51403" fill="none" stroke="#999999" />
                    <line strokeWidth="1.75" id="svg_37" y2="530.44195" x2="390.57904" y1="530.44196" x1="304.51403" fill="none" stroke="#999999" />
                    <line strokeWidth="1.75" id="svg_42" y2="391.77544" x2="269.10772" y1="391.77545" x1="183.04271" fill="none" stroke="#999999" />
                    <line strokeWidth="1.75" id="svg_46" y2="530.44195" x2="269.10772" y1="530.44196" x1="183.04271" fill="none" stroke="#999999" />
                    <line stroke="#999" strokeWidth="4.5" id="svg_52" y2="846.44532" x2="103.27933" y1="846.44533" x1="10.49163" fill="none" />
                    <line stroke="#999999" strokeWidth="1.75" id="svg_53" y2="993.94535" x2="590.8547" y1="993.94536" x1="545.49407" fill="none" />
                    <line stroke="#999999" strokeWidth="1.75" id="svg_54" y2="1135.43989" x2="590.8547" y1="1135.4399" x1="545.49407" fill="none" />
                    <line strokeWidth="1.75" id="svg_59" y2="993.85766" x2="391.3358" y1="993.85767" x1="305.27079" fill="none" stroke="#999999" />
                    <line strokeWidth="1.75" id="svg_60" y2="993.85766" x2="269.09801" y1="993.85767" x1="183.033" fill="none" stroke="#999999" />
                    <line strokeWidth="1.75" id="svg_61" y2="968.52062" x2="737.4211" y1="968.52063" x1="651.35609" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeWidth="1.75" id="svg_62" y2="1100.77865" x2="692.55366" y1="1100.77866" x1="647.19302" fill="none" />
                    <line stroke="#999999" strokeWidth="1.75" id="svg_63" y2="1135.59163" x2="392.43206" y1="1135.59164" x1="302.12959" fill="none" />
                    <rect transform="rotate(90 485.279 883.567)" stroke="#b2b2b2" id="WSL4" data-tip="" className={floorState.WSL4.status} onClick={(e) => { seatSelected(e) }} height="20" width="35" y="873.56644" x="467.77753" fill="#ffffff" />
                    <text transform="rotate(90 485.029 883.399)" stroke="#b2b2b2" textAnchor="start" fontFamily="ColgateReady" fontSize="12" strokeWidth="0" id="WSL4_text" data-tip="" className={`${floorState.WSL4.status}_text cpil4_css`} onClick={(e) => { seatSelected(e) }} y="887.48003" x="475.01941" fill="#000000">0L4</text>

                </g>

            </svg>
            {/* </UncontrolledReactSVGPanZoom> */}
        </div>
    )
}