import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Dropdown from "react-dropdown";
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { firebase } from "../../config/firebase";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Checkbox from '@material-ui/core/Checkbox';

import "./floor-plan.css";
import "react-dropdown/style.css";

import confirmIcon from "./../../Assets/Images/confirm.png";
import warningIcon from "./../../Assets/Images/warning.png";
import backIcon from "./../../Assets/Images/back_arrow.png";

import CGBSFloorEight from "../CGBSFloorEight/cgbs-floor-eight";
import CGBSFloorNine from "../CGBSFloorNine/cgbs-floor-nine";
import CGBSFloorFour from "../CGBSFloorFour/cgbs-floor-four";


import sendEmail from "../SendEmail/send-email"

import Loader from "../Loader/loader";

export default function FloorPlan(props) {
  const location = useLocation();
  const history = useHistory();

   const [floorState, setFloorState] = useState({...location.state.commonState.floorState});

  // const original_CommonState = {...location.state.commonState};

  const [loadFloor, setLoadFloor] = useState("");
  const [loading, setLoading] = useState(false);

  const [floorOptions, setFloorOptions] = useState();

  const [totalSeats, setTotalSeats] = useState();

  const [no_of_seats, set_no_of_seats] = useState(1);

  const [selectedSeat, setSelectedSeat] = useState("");

  const [selectedWS, setSelectedWS] = useState({});

  const [selectedWing, setSelectedWing] = useState("");

  const [bookingType, setBookingType] = useState();

  const [select_all, set_select_all] = useState();
  
  let selectedDate;
  let curr_date_timestamp;


    //  const [seatAttributes, setSeatAttributes] = useState([
    //   { key: 0, label: 'Monitor', class:'attribute-not-selected' },
    //   { key: 1, label: 'Charging', class:'attribute-not-selected'},
    //   { key: 2, label: 'LAN', class:'attribute-not-selected'},
    //   { key: 3, label: 'Window Seat', class:'attribute-not-selected'},
    //   { key: 4, label: 'Telephone', class:'attribute-not-selected'},
    // ]);


    const [seatAttributes, setSeatAttributes] = useState([])

  if (location.state) {
    curr_date_timestamp = firebase.firestore.Timestamp.fromDate(
      location.state.commonState.currentDate
    );
    selectedDate = firebase.firestore.Timestamp.fromDate(
      new Date(location.state.selectedDate)
    );
  }

  const block_booking_ref = firebase
    .firestore()
    .collection(
      "BlockBookings/" + location.state.commonState.site + "/BlockBookings"
    );
  const seat_booking_ref = firebase
    .firestore()
    .collection(
      "SeatBookings/" + location.state.commonState.site + "/SeatBookings"
    );
  const blocked_seats_ref = firebase
    .firestore()
    .collection("BlockedSeats/" + location.state.commonState.site + "/Blocks");

  const blocked_seats_dates_ref = firebase
    .firestore()
    .collection("BlockedSeatsDates/" + location.state.commonState.site + "/Blocks");    

  const categories_ref = firebase
    .firestore()
    .collection("Categories/" + location.state.commonState.site + "/Category");

  const format_dt_to_ts = (cal_date) => {
      let current_date = new Date(cal_date);
      let currentDate = new Date(
          current_date.getFullYear(),
          current_date.getMonth(),
          current_date.getDate()
      );
      const curr_date_timestamp = firebase.firestore.Timestamp.fromDate(currentDate);
      return curr_date_timestamp;
  }


  useEffect(() => {
    setLoading(true);
    // console.log("location.state.action", location.state.action);

    let liveFloorState = {};
    let all_floors_state = {};

    let sites_ref;

    sites_ref = firebase.firestore().collection("Sites");
    sites_ref
      .where("Site", "==", location.state.commonState.site)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.docs.length !== 0) {
          let site = {};
          querySnapshot.forEach((doc) => {
            site = { ...doc.data() };
          });

          // all_floors_state = {...site.floors}
          all_floors_state = floorState;

          if (!floorOptions) {
            const tmpfloorsNames = Object.keys(site.floors).sort();
            const floorsNames = tmpfloorsNames.filter((floor)=>{
              if(site.enable_floor && site.enable_floor[floor]) {
                return floor;
              }
            })
            setFloorOptions(floorsNames);
            setLoadFloor(floorsNames[0]);
          }
        }

        liveFloorState = { ...all_floors_state[loadFloor] };

        // setFloorState({...all_floors_state})

        if (loadFloor) {

          const temp_seat_Attr = [...location.state.commonState.site_data.seat_attributes]
          let temp_seat_Attr_obj = []
          temp_seat_Attr.map((attr, i) => {
            temp_seat_Attr_obj.push({key: i, label: attr, class: 'attribute-not-selected'})
            return true;
          })

          if (seatAttributes.length === 0) {
            setSeatAttributes([...temp_seat_Attr_obj])
          }

          //******************************************* BLOCK BOOKING ******************************************************
          if (location.state.action === "block_booking") {
            setLoading(true);
            set_no_of_seats(location.state.noOfSeats); // Allow to select multiple seats while "Reserve Block"
            setBookingType("Block Booking");

            // let all_floors_state = {...floorState}
            // let liveFloorState = {...all_floors_state[loadFloor]}

            const book_date = new Date(location.state.selectedDate);

            //*** Step 1: get all Blocked Seats, Special Reservation Seats & Social Distance Seats and mark them accordingly on floor map
            blocked_seats_ref
              .where("isActive", "==", true)
              .get()
              .then(
                (querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    const block = doc.data();
                    if (block && block.active_days[book_date.getDay()]) {
                      if (block.floor[loadFloor]) {
                        if (block.BlockName === "Special Reservation Block") {
                          block.floor[loadFloor].forEach((ws) => {
                            liveFloorState[ws].status = "blocked";
                            liveFloorState[ws].tooltip = block.BlockName;
                          });
                        } else if (block.BlockName === "Social Distance") {
                          block.floor[loadFloor].forEach((ws) => {
                            liveFloorState[ws].status =
                              "blocked_social_distance";
                            liveFloorState[ws].tooltip = block.BlockName;
                          });
                        } else {
                          block.floor[loadFloor].forEach((ws) => {
                            liveFloorState[ws].status = "blocked";
                            liveFloorState[ws].tooltip = block.BlockName;
                          });
                        }
                      }
                    }
                  });
                },
                (error) => {
                  console.log(error);
                }
              );

            //***  Step 2: Get all reserved categories and mark them blocked on the floor map
            categories_ref
              .where("isActive", "==", true)
              .get()
              .then(
                (querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    const block = doc.data();
                    //   console.log("doc.data()", doc.data())
                    if (block && block.active_days[book_date.getDay()]) {
                      if (block.floor[loadFloor]) {
                        block.floor[loadFloor].forEach((ws) => {
                          liveFloorState[ws].status = "blocked";
                          liveFloorState[ws].tooltip = block.category;
                        });
                      }
                    }
                  });
                },
                (error) => {
                  console.log(error);
                }
              );

            //***  Step 3: Get all Block Bookings of selected date and mark them as Booked
            block_booking_ref
              .where("blocked_date", "==", selectedDate)
              //     .get()
              //    .then(
              .onSnapshot(
                (querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    const block = doc.data();
                    if (block && block.reserved_block[loadFloor]) {
                      block.reserved_block[loadFloor].forEach((ws) => {
                        liveFloorState[ws].status = "booked";
                        liveFloorState[ws].tooltip =
                          "Blocked By - " + block.blocked_by;
                      });
                    }
                  });

                  //***  Step 3: Get all SEAT Bookings of selected date and mark them as Booked
                  seat_booking_ref
                    .where("blocked_date", "==", selectedDate)
                    // .get()
                    // .then(
                    .onSnapshot(
                      (querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                          const seat = doc.data();
                          if (
                            seat.booked_ws &&
                            liveFloorState[seat.booked_ws]
                          ) {
                            liveFloorState[seat.booked_ws].status = "booked";
                            liveFloorState[seat.booked_ws].tooltip =
                              seat.booked_by;
                          }
                        });

                        
                        //**Step 4: Block Cubicles in this mode */
                        if (location.state.commonState.site_data.cubicles_data){
                          const temp_cubicle_floorState = { ...location.state.commonState.site_data.cubicles_data.floors };
                          const temp_ws_keys = Object.keys(temp_cubicle_floorState[loadFloor]);
                          temp_ws_keys.forEach((ws) => {
                              liveFloorState[ws].status = "blocked";
                              liveFloorState[ws].tooltip = "Not Available";
                          });  
                        }


                        all_floors_state[loadFloor] = {
                          ...all_floors_state[loadFloor],
                          ...liveFloorState,
                        };
                        // setFloorState({...floorState, ...all_floors_state})
                        setFloorState({ ...all_floors_state });

                        setLoading(false);
                      },
                      (error) => {
                        history.push("error", error);
                      }
                    );

                },

                (error) => {
                  history.push("error", error);
                }
              );

              
            /**Step 5 : Only open the seats reserved for the resp Org and block others */
            const org_blocked_seats_ref = firebase.firestore().collection('OrgDivision/' + location.state.commonState.site + '/' + loadFloor);

            org_blocked_seats_ref
              .where("org", "!=", location.state.commonState.organization)
              .get()
              .then(
                (querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    const org_space = doc.data();
                    org_space.floor[loadFloor].forEach((ws) => {
                      liveFloorState[ws].status = "blocked";
                      liveFloorState[ws].tooltip = org_space.org;
                    });
                  });
                },
                (error) => {
                  console.log(error);
                }
              );



          }

          //******************************************* SEAT BOOKING ******************************************************
          else if (location.state.action === "seat_booking") {
            setLoading(true);
            set_no_of_seats(1); // Allow only one booking for "Select a Seat"
            setBookingType("Seat Booking");

            //*** Step 2: Get all permanent Blocked Seats/Special BLock Seats & Social Distance Seats and mark them on Floor Map
            const book_date = new Date(location.state.booking_obj.blocked_date);

            blocked_seats_ref
              .where("isActive", "==", true)
              .get()
              .then(
                (querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    const block = doc.data();
                    if (block && block.active_days[book_date.getDay()]) {
                      if (block.floor[loadFloor]) {
                        if (block.BlockName === "Special Reservation Block") {
                          block.floor[loadFloor].forEach((ws) => {
                            liveFloorState[ws].status = "blocked";
                            liveFloorState[ws].tooltip = block.BlockName;
                          });
                        } else if (block.BlockName === "Social Distance") {
                          block.floor[loadFloor].forEach((ws) => {
                            liveFloorState[ws].status =
                              "blocked_social_distance";
                            liveFloorState[ws].tooltip = block.BlockName;
                          });
                        } else {
                          block.floor[loadFloor].forEach((ws) => {
                            liveFloorState[ws].status = "blocked";
                            liveFloorState[ws].tooltip = block.BlockName;
                          });
                        }
                      }
                    }
                  });
                },
                (error) => {
                  history.push("error", error);
                }
              );

            //*** Step 3: Get all Reserved Categories and mark them blocked on the floor map
            categories_ref
              .where("isActive", "==", true)
              .get()
              .then(
                (querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    const block = doc.data();
                    //   console.log("doc.data()", doc.data())
                    if (block && block.active_days[book_date.getDay()]) {
                      if (block.floor[loadFloor]) {
                        block.floor[loadFloor].forEach((ws) => {
                          liveFloorState[ws].status = "blocked";
                          liveFloorState[ws].tooltip = block.category;
                        });
                      }
                    }
                  });
                },
                (error) => {
                  console.log(error);
                }
              );

            //*** Step 4: Get Seats booked from the current block. Mark them as booked.
            seat_booking_ref
              .where("block_id", "==", location.state.booking_obj.block_id)
              .onSnapshot(
                // .get()
                // .then(
                (querySnapshot) => {
                  // const tempBookedSeats = []
                  querySnapshot.forEach((doc) => {
                    const booking = doc.data();
                    if (liveFloorState[booking.booked_ws]) {
                      liveFloorState[booking.booked_ws].status = "booked"; 
                      liveFloorState[booking.booked_ws].tooltip = booking.booked_by;
                    }
                    //   tempBookedSeats.push(seat)
                  });

                  //*** Step 1: Block all seats on floor EXCEPT the seats from Booked Block
                  const reservedSeats = location.state.booking_obj
                    .reserved_block[loadFloor]
                    ? location.state.booking_obj.reserved_block[loadFloor]
                    : [];

                  var temp_cu_keys = [];

                  if (location.state.commonState.site_data.cubicles_data && location.state.commonState.cubicleBookingAccess){
                    const temp_cubicle_floorState = { ...location.state.commonState.site_data.cubicles_data.floors };
                    temp_cu_keys = Object.keys(temp_cubicle_floorState[loadFloor]);
                  }

                  //+++++ Allow seat booking only from the reserved block
                  // Object.keys(floorState[loadFloor]).forEach((ws)=>{
                  Object.keys(all_floors_state[loadFloor]).forEach((ws) => {
                    if (
                      reservedSeats.includes(ws) &&
                      liveFloorState[ws].status === "available"
                    ) {
                    } else if (temp_cu_keys.includes(ws) &&
                    liveFloorState[ws].status === "available") {

                    } else if (liveFloorState[ws].status === "available") {
                      liveFloorState[ws].status = "blocked";
                      liveFloorState[ws].tooltip = "Blocked";
                    }
                  });


                        
                  //**Step 5: Block Cubicles in this mode if the user doesn't have the cubicle booking role*/
                  if (location.state.commonState.site_data.cubicles_data && !location.state.commonState.cubicleBookingAccess){
                    const temp_cubicle_floorState = { ...location.state.commonState.site_data.cubicles_data.floors };
                    const temp_ws_keys = Object.keys(temp_cubicle_floorState[loadFloor]);
                    temp_ws_keys.forEach((ws) => {
                        liveFloorState[ws].status = "blocked";
                        liveFloorState[ws].tooltip = "Not Available";
                    });  
                  }
                  

                  all_floors_state[loadFloor] = {
                    ...all_floors_state[loadFloor],
                    ...liveFloorState,
                  };
                  setFloorState({ ...floorState, ...all_floors_state }); //Update state only once
                  setLoading(false);
                },
                (error) => {
                  console.log(error);
                }
              );
          }

          //******************************************* SPECIAL BOOKING ******************************************************
          else if (location.state.action === "special_booking") {
            setLoading(true);
            set_no_of_seats(1); // Allow only one booking for "Select a Seat"
            setBookingType("Special Booking");

            // let all_floors_state = {...floorState}
            // let liveFloorState = {...floorState[loadFloor]}

            // const curr_date = new Date(location.state.commonState.currentDate);
            const curr_date = new Date(location.state.selectedDate);

            //**Step : Block Cubicles in this mode if the user doesn't have the cubicle booking role*/
            if (location.state.commonState.site_data.cubicles_data && !location.state.commonState.cubicleBookingAccess){
              const temp_cubicle_floorState = { ...location.state.commonState.site_data.cubicles_data.floors };
              const temp_ws_keys = Object.keys(temp_cubicle_floorState[loadFloor]);
              temp_ws_keys.forEach((ws) => {
                  // console.log(ws)
                  liveFloorState[ws].status = "blocked";
                  liveFloorState[ws].tooltip = "Not Available";
              });  
            }            

            //*** Step 1: Get all permanent Blocked Seats/Special BLock Seats & Social Distance Seats and mark them on Floor Map
            blocked_seats_ref
              .where("isActive", "==", true)
              .where("group","==", location.state.commonState.organization)
              .get()
              .then(
                (querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    const block = doc.data();
                    if (block && block.active_days[curr_date.getDay()]) {
                      if (block.floor[loadFloor]) {
                        if (block.BlockName === "Special Reservation Block") {
                          block.floor[loadFloor].forEach((ws) => {
                            liveFloorState[ws].status = "Special Block";
                            //    liveFloorState[ws].tooltip = block.BlockName
                          });
                        } else if (block.BlockName === "Social Distance") {
                          block.floor[loadFloor].forEach((ws) => {
                            liveFloorState[ws].status =
                              "blocked_social_distance";
                            liveFloorState[ws].tooltip = block.BlockName;
                          });
                        } else {
                          block.floor[loadFloor].forEach((ws) => {
                            liveFloorState[ws].status = "blocked";
                            liveFloorState[ws].tooltip = block.BlockName;
                          });
                        }
                      }
                    }
                  });

                  var temp_cu_keys = [];

                  if (location.state.commonState.site_data.cubicles_data && location.state.commonState.cubicleBookingAccess){
                    const temp_cubicle_floorState = { ...location.state.commonState.site_data.cubicles_data.floors };
                    temp_cu_keys = Object.keys(temp_cubicle_floorState[loadFloor]);
                  }
                  

                  //*** Step 1.1:   Mark special block as available and rest all seats as 'Blocked'. 'Social Distance Seats will Preserve their status as 'blocked_social_distance'.
                  Object.keys(all_floors_state[loadFloor]).forEach((ws) => {
                    if (liveFloorState[ws].status === "Special Block") {
                      liveFloorState[ws].status = "available";
                    } else if (temp_cu_keys.includes(ws) &&
                      liveFloorState[ws].status === "available") {
                    } else if (liveFloorState[ws].status === "available") {
                      liveFloorState[ws].status = "blocked";
                      liveFloorState[ws].tooltip = "Not Available";
                    }
                  });

                  //*** Step 2: Get Seats booked from the special block. Mark them as booked.
                  seat_booking_ref
                    // .where("blocked_date", "==", curr_date_timestamp)
                    .where("blocked_date", "==", selectedDate)
                    // .where("block_id", "==", "special_booking")
                    .onSnapshot(
                      // .get()
                      // .then(
                      (querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                          // const seat = doc.data().booked_ws;
                          // if (liveFloorState[seat]) {
                          //   liveFloorState[seat].status = "booked";
                          //   liveFloorState[seat].tooltip = seat.booked_by;
                          // }
                          const seat = doc.data();
                          if (
                            seat.booked_ws &&
                            liveFloorState[seat.booked_ws]
                          ) {
                            liveFloorState[seat.booked_ws].status = "booked";
                            liveFloorState[seat.booked_ws].tooltip =
                              seat.booked_by;
                          }                          
                        });
                        all_floors_state[loadFloor] = {
                          ...all_floors_state[loadFloor],
                          ...liveFloorState,
                        };
                        setFloorState({ ...floorState, ...all_floors_state }); //Update state only once
                        setLoading(false);
                      },
                      (error) => {
                        console.log(error);
                      }
                    );
                },
                (error) => {
                  console.log(error);
                }
              );
          }

          //******************************************* SITE LEVEL MANUAL BOOKING ******************************************************
          else if (location.state.action === "Site Level-Manual") {
            setLoading(true);
            set_no_of_seats(1); // Allow only one booking for "Select a Seat"
            setBookingType("Seat Booking");

            // let all_floors_state = {...floorState}
            // let liveFloorState = {...floorState[loadFloor]}

            const book_date = new Date(location.state.selectedDate);

            //**Step : Block Cubicles in this mode if the user doesn't have the cubicle booking role*/
            if (location.state.commonState.site_data.cubicles_data && !location.state.commonState.cubicleBookingAccess){
              const temp_cubicle_floorState = { ...location.state.commonState.site_data.cubicles_data.floors };
              const temp_ws_keys = Object.keys(temp_cubicle_floorState[loadFloor]);
              temp_ws_keys.forEach((ws) => {
                  // console.log(ws)
                  liveFloorState[ws].status = "blocked";
                  liveFloorState[ws].tooltip = "Not Available";
              });  
            }         


            //*** Step 1: Get all permanent Blocked Seats/Special BLock Seats & Social Distance Seats and mark them on Floor Map

            blocked_seats_ref
              .where("isActive", "==", true)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  const block = doc.data();
                  if (
                    block &&
                    (block.active_days[book_date.getDay()] ||
                      location.state.action === "UpdateMyWS-SiteLevel")
                  ) {
                    if (block.floor[loadFloor]) {
                      if (block.BlockName === "Special Reservation Block") {
                        block.floor[loadFloor].forEach((ws) => {
                          liveFloorState[ws].status = "blocked";
                          liveFloorState[ws].tooltip = block.BlockName;
                        });
                      } else if (block.BlockName === "Social Distance") {
                        block.floor[loadFloor].forEach((ws) => {
                          liveFloorState[ws].status = "blocked_social_distance";
                          liveFloorState[ws].tooltip = block.BlockName;
                        });
                      } else {
                        block.floor[loadFloor].forEach((ws) => {
                          liveFloorState[ws].status = "blocked";
                          liveFloorState[ws].tooltip = block.BlockName;
                        });
                      }
                    }
                  }
                });
                //*** Step 2: Get All booked seats from the user's Site. Mark them as booked.
                seat_booking_ref
                  .where("blocked_date", "==", selectedDate)
                  .onSnapshot((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                      const seat = doc.data().booked_ws;
                      if (liveFloorState[seat]) {
                        liveFloorState[seat].status = "booked";
                        liveFloorState[seat].tooltip = seat.booked_by;
                      }
                    });

                    // //***  Step 3: Get all Block Bookings of selected date and mark them as Booked
                    // block_booking_ref
                    //   .where("blocked_date", "==", selectedDate)
                    //   // .get()
                    //   // .then(
                    //   .onSnapshot((querySnapshot) => {
                    //     querySnapshot.forEach((doc) => {
                    //       const block = doc.data();
                    //       if (block && block.reserved_block[loadFloor]) {
                    //         block.reserved_block[loadFloor].forEach((ws) => {
                    //           liveFloorState[ws].status = "booked";
                    //           liveFloorState[ws].tooltip =
                    //             "Blocked By - " + block.blocked_by;
                    //         });
                    //       }
                    //     });

                    all_floors_state[loadFloor] = {
                      ...all_floors_state[loadFloor],
                      ...liveFloorState,
                    };
                    setFloorState({ ...floorState, ...all_floors_state });
                    setLoading(false);
                    //   });
                  });
              });
          }

          //******************************************* RESRVED CATEGORY BOOKING - MANUAL******************************************************
          else if (location.state.action === "Reserved Cat-Manual") {
            setLoading(true);
            set_no_of_seats(1); // Allow only one booking for "Select a Seat"
            setBookingType("Seat Booking");

            // let all_floors_state = {...floorState}
            // let liveFloorState = {...floorState[loadFloor]}
            
            const book_date = new Date(location.state.selectedDate); 

            //***  Step 1: Get Seats of user's Reserved category and mark them Reserved
            categories_ref
              .where("category", "==", location.state.commonState.category)
              .where("isActive", "==", true)
              .get()
              .then(
                (querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    const block = doc.data();
                    //   console.log("doc.data()", doc.data())
                    if (block && block.active_days[book_date.getDay()]) {
                      if (block.floor[loadFloor]) {
                        block.floor[loadFloor].forEach((ws) => {
                          liveFloorState[ws].status = "reserved";
                          // liveFloorState[ws].tooltip = block.category
                        });
                      }
                    }
                  });

                  //*** Step 1.1:   Mark reserved seats as 'available and rest all seats as 'Blocked'.
                  Object.keys(all_floors_state[loadFloor]).forEach((ws) => {
                    if (liveFloorState[ws].status === "reserved")
                      liveFloorState[ws].status = "available";
                    else {
                      liveFloorState[ws].status = "blocked";
                      liveFloorState[ws].tooltip = "Not Available";
                    }
                  });

                  //**Step 1.2 : Block Cubicles in this mode if the user doesn't have the cubicle booking role*/
                  if (location.state.commonState.site_data.cubicles_data && !location.state.commonState.cubicleBookingAccess){
                    const temp_cubicle_floorState = { ...location.state.commonState.site_data.cubicles_data.floors };
                    const temp_ws_keys = Object.keys(temp_cubicle_floorState[loadFloor]);
                    temp_ws_keys.forEach((ws) => {
                        liveFloorState[ws].status = "blocked";
                        liveFloorState[ws].tooltip = "Not Available";
                    });  
                  }         

                  //*** Step 2: Get all permanent Blocked Seats/Special BLock Seats & Social Distance Seats and mark them on Floor Map
                  blocked_seats_ref
                    .where("isActive", "==", true)
                    .get()
                    .then((querySnapshot) => {
                      querySnapshot.forEach((doc) => {
                        const block = doc.data();
                        if (
                          block &&
                          (block.active_days[book_date.getDay()] ||
                            location.state.action === "UpdateMyWS-SiteLevel")
                        ) {
                          if (block.floor[loadFloor]) {
                            if (
                              block.BlockName === "Special Reservation Block"
                            ) {
                              block.floor[loadFloor].forEach((ws) => {
                                liveFloorState[ws].status = "blocked";
                                liveFloorState[ws].tooltip = block.BlockName;
                              });
                            } else if (block.BlockName === "Social Distance") {
                              block.floor[loadFloor].forEach((ws) => {
                                liveFloorState[ws].status =
                                  "blocked_social_distance";
                                liveFloorState[ws].tooltip = block.BlockName;
                              });
                            } else {
                              block.floor[loadFloor].forEach((ws) => {
                                liveFloorState[ws].status = "blocked";
                                liveFloorState[ws].tooltip = block.BlockName;
                              });
                            }
                          }
                        }
                      });

                      //*** Step 3: Get Seats booked from the user's reserved category. Mark them as booked.
                      seat_booking_ref
                        // .where(
                        //   "category",
                        //   "==",
                        //   location.state.commonState.category
                        // )
                        .where("blocked_date", "==", selectedDate) // Change date whn Booking logic is built
                        // .where("block_id", "==", location.state.action)
                        .onSnapshot(
                          // .get()
                          // .then(
                          (querySnapshot) => {
                            //*** Step 3.1:   Mark booked seats as available and reassign the status on snapshot trigger
                            Object.keys(all_floors_state[loadFloor]).forEach((ws) => {
                              if (liveFloorState[ws].status === "booked"){
                                liveFloorState[ws].status = "available";
                                liveFloorState[ws].tooltip = "available";
                              }
                            });

                            querySnapshot.forEach((doc) => {
                              const seat = doc.data();
                              if (liveFloorState[seat.booked_ws]) {
                                liveFloorState[seat.booked_ws].status = "booked";
                                liveFloorState[seat.booked_ws].tooltip = seat.booked_by;
                              }
                            });
                            all_floors_state[loadFloor] = {
                              ...all_floors_state[loadFloor],
                              ...liveFloorState,
                            };
                            setFloorState({
                              ...floorState,
                              ...all_floors_state,
                            }); //Update state only once
                            setLoading(false);
                          },
                          (error) => {
                            console.log(error);
                          }
                        );
                    });
                },
                (error) => {
                  console.log(error);
                }
              );
          }

          //******************************************* UPDATE WORK STATION - SiteLevel ******************************************************
          else if (location.state.action === "UpdateMyWS-SiteLevel") {
            setLoading(true);
            set_no_of_seats(1); // Allow only one booking for "Select a Seat"
            setBookingType("Update WS");

            // let all_floors_state = {...floorState}
            // let liveFloorState = {...floorState[loadFloor]}

            // const book_date = new Date(location.state.selectedDate)

            //**Step 0.1 : Block Cubicles in this mode if the user doesn't have the cubicle booking role*/
            if (location.state.commonState.site_data.cubicles_data && !location.state.commonState.cubicleBookingAccess){
              const temp_cubicle_floorState = { ...location.state.commonState.site_data.cubicles_data.floors };
              const temp_ws_keys = Object.keys(temp_cubicle_floorState[loadFloor]);
              temp_ws_keys.forEach((ws) => {
                  // console.log(ws)
                  liveFloorState[ws].status = "blocked";
                  liveFloorState[ws].tooltip = "Not Available";
              });  
            }                

            //*** Step 1: Get all permanent Blocked Seats/Special BLock Seats & Social Distance Seats and mark them on Floor Map

            blocked_seats_ref
              .where("isActive", "==", true)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  const block = doc.data();
                  if (block) {
                    if (block.floor[loadFloor]) {
                      if (block.BlockName === "Special Reservation Block") {
                        block.floor[loadFloor].forEach((ws) => {
                          liveFloorState[ws].status = "blocked";
                          liveFloorState[ws].tooltip = block.BlockName;
                        });
                      } else if (block.BlockName === "Social Distance") {
                        block.floor[loadFloor].forEach((ws) => {
                          liveFloorState[ws].status = "blocked_social_distance";
                          liveFloorState[ws].tooltip = block.BlockName;
                        });
                      } else {
                        block.floor[loadFloor].forEach((ws) => {
                          liveFloorState[ws].status = "blocked";
                          liveFloorState[ws].tooltip = block.BlockName;
                        });
                      }
                    }
                  }
                });

                const user_ref = firebase.firestore().collection("Users");
                user_ref
                  .where("site", "==", location.state.commonState.site)
                  .get()
                  .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                      const usr = doc.data();
                      if (usr) {
                        if (usr.my_ws && usr.my_ws.ws) {
                          if (liveFloorState[usr.my_ws.ws]) {
                            if(usr.userEmail === location.state.commonState.userEmail) {
                              liveFloorState[usr.my_ws.ws].status = "selected";
                              liveFloorState[usr.my_ws.ws].tooltip = "Your WS";
                            }
                            else {
                              liveFloorState[usr.my_ws.ws].status = "booked";
                              liveFloorState[usr.my_ws.ws].tooltip = usr.userName;
                            }
                          }
                          // //Insert for Update Profile (Side-Bar)
                          // if(liveFloorState[usr.my_ws.ws].tooltip === location.state.commonState.userName){
                          //   liveFloorState[usr.my_ws.ws].status = "selected";
                          //   liveFloorState[usr.my_ws.ws].tooltip = "selected before";
                          // }
                          // //End of changes for Update Profile (Side-Bar)
                        }
                      }
                    });

                    all_floors_state[loadFloor] = {
                      ...all_floors_state[loadFloor],
                      ...liveFloorState,
                    };
                    setFloorState({ ...floorState, ...all_floors_state });
                  });
              });
          }

          //******************************************* UPDATE WORK STATION - CategoryLevel ******************************************************
          else if (location.state.action === "UpdateMyWS-CategoryLevel") {
            setLoading(true);
            set_no_of_seats(1); // Allow only one booking for "Select a Seat"
            setBookingType("Update WS");

            // let all_floors_state = {...floorState}
            // let liveFloorState = {...floorState[loadFloor]}

            const book_date = new Date(location.state.selectedDate); //Change date when booking logic is built

            //**Step 0.9 : Block Cubicles in this mode if the user doesn't have the cubicle booking role*/
            if (location.state.commonState.site_data.cubicles_data && !location.state.commonState.cubicleBookingAccess){
              const temp_cubicle_floorState = { ...location.state.commonState.site_data.cubicles_data.floors };
              const temp_ws_keys = Object.keys(temp_cubicle_floorState[loadFloor]);
              temp_ws_keys.forEach((ws) => {
                  // console.log(ws)
                  liveFloorState[ws].status = "blocked";
                  liveFloorState[ws].tooltip = "Not Available";
              });  
            }    

            //***  Step 1: Get Seats of user's Reserved category and mark them Reserved
            categories_ref
              .where("category", "==", location.state.commonState.category)
              .where("isActive", "==", true)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  const block = doc.data();
                  //   console.log("doc.data()", doc.data())
                  // if(block && block.active_days[book_date.getDay()]) {
                  if (block.floor[loadFloor]) {
                    block.floor[loadFloor].forEach((ws) => {
                      liveFloorState[ws].status = "reserved";
                      // liveFloorState[ws].tooltip = block.category
                    });
                  }
                  // }
                });

                //*** Step 1.1:   Mark reserved seats as 'available and rest all seats as 'Blocked'.
                Object.keys(all_floors_state[loadFloor]).forEach((ws) => {
                  if (liveFloorState[ws].status === "reserved")
                    liveFloorState[ws].status = "available";
                  else {
                    liveFloorState[ws].status = "blocked";
                    liveFloorState[ws].tooltip = "Not Available";
                  }
                });

                //*** Step 2: Get all permanent Blocked Seats/Special BLock Seats & Social Distance Seats and mark them on Floor Map
                blocked_seats_ref
                  .where("isActive", "==", true)
                  .get()
                  .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                      const block = doc.data();
                      if (
                        block &&
                        (block.active_days[book_date.getDay()] ||
                          location.state.action === "UpdateMyWS-SiteLevel")
                      ) {
                        if (block.floor[loadFloor]) {
                          if (block.BlockName === "Special Reservation Block") {
                            block.floor[loadFloor].forEach((ws) => {
                              liveFloorState[ws].status = "blocked";
                              liveFloorState[ws].tooltip = block.BlockName;
                            });
                          } else if (block.BlockName === "Social Distance") {
                            block.floor[loadFloor].forEach((ws) => {
                              liveFloorState[ws].status =
                                "blocked_social_distance";
                              liveFloorState[ws].tooltip = block.BlockName;
                            });
                          } else {
                            block.floor[loadFloor].forEach((ws) => {
                              liveFloorState[ws].status = "blocked";
                              liveFloorState[ws].tooltip = block.BlockName;
                            });
                          }
                        }
                      }
                    });

                  // Step 3: Block seats within same category which are already reserved 
                    const user_ref = firebase.firestore().collection("Users");
                    user_ref
                      .where("site", "==", location.state.commonState.site)
                      .where("category", "==", location.state.commonState.category)
                      .get()
                      .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                          const usr = doc.data();
                          if (usr) {
                            if (usr.my_ws && usr.my_ws.ws) {
                              if (liveFloorState[usr.my_ws.ws]) {
                                if(usr.userEmail === location.state.commonState.userEmail) {
                                  liveFloorState[usr.my_ws.ws].status = "selected";
                                  liveFloorState[usr.my_ws.ws].tooltip = "Your WS";
                                }
                                else {
                                  liveFloorState[usr.my_ws.ws].status = "booked";
                                  liveFloorState[usr.my_ws.ws].tooltip = usr.userName;
                                }
                              }
                              // //Insert for Update Profile (Side-Bar)
                              // if(liveFloorState[usr.my_ws.ws].tooltip === location.state.commonState.userName){
                              //   liveFloorState[usr.my_ws.ws].status = "selected";
                              //   liveFloorState[usr.my_ws.ws].tooltip = "selected before";
                              // }
                              // //End of changes for Update Profile (Side-Bar)
                            }
                          }
                        });

                    all_floors_state[loadFloor] = {
                      ...all_floors_state[loadFloor],
                      ...liveFloorState,
                    };
                    setFloorState({ ...floorState, ...all_floors_state });
                    setLoading(false);
                  });
                  });
              });
          }

          //******************************************* RESERVED CATEGORY AUTO BOOKING ******************************************************
          else if (location.state.action === "Reserved Cat-Auto") {
            setLoading(true);
            confirmAutoBooking();
          }

          //******************************************* SITE LEVEL AUTO BOOKING ******************************************************
          else if (location.state.action === "Site Level-Auto") {
            setLoading(true);
            confirmAutoBooking();
          }

          //************************************************** LIVE STATUS ****************************************************************
          else if (location.state.action === "live_status") {
            setLoading(true);
            set_no_of_seats(0); // Allow to select multiple seats while "Reserve Block"
            setBookingType("Live Status");

            // let all_floors_state = {...floorState}
            // let liveFloorState = {...all_floors_state[loadFloor]}

            const curr_date = new Date(location.state.commonState.currentDate);

                  //*** Step 0:   Mark seats as 'available'
                  Object.keys(all_floors_state[loadFloor]).forEach((ws) => {
                    liveFloorState[ws].status = "available";
                    liveFloorState[ws].tooltip = "Not Available";
                  }
                );

            //*** Step 1: get all Blocked Seats, Special Reservation Seats & Social Distance Seats and mark them accordingly on floor map
            blocked_seats_ref
              .where("isActive", "==", true)
              // .get()
              // .then(
              .onSnapshot(
                (querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    const block = doc.data();
                    if (block && block.active_days[curr_date.getDay()]) {
                      if (block.floor[loadFloor]) {
                        if (block.BlockName === "Special Reservation Block") {
                          block.floor[loadFloor].forEach((ws) => {
                          //   liveFloorState[ws].status = "blocked";  //Unoccupied special reservation seats should also appear as available
                            liveFloorState[ws].tooltip = block.BlockName;
                          });
                        } else if (block.BlockName === "Social Distance") {
                          block.floor[loadFloor].forEach((ws) => {
                            liveFloorState[ws].status =
                              "blocked_social_distance";
                            liveFloorState[ws].tooltip = block.BlockName;
                          });
                        } else {
                          block.floor[loadFloor].forEach((ws) => {
                            liveFloorState[ws].status = "blocked";
                            liveFloorState[ws].tooltip = block.BlockName;
                          });
                        }
                      }
                    }
                  });
                },
                (error) => {
                  console.log(error);
                }
              );

            //***  Step 2: Get all reserved categories and mark them blocked on the floor map
            // categories_ref
            //   .where("isActive", "==", true)
            //   .get()
            //   .then(
            //     (querySnapshot) => {
            //       querySnapshot.forEach((doc) => {
            //         const block = doc.data();
            //         //   console.log("doc.data()", doc.data())
            //         if (block && block.active_days[curr_date.getDay()]) {
            //           if (block.floor[loadFloor]) {
            //             block.floor[loadFloor].forEach((ws) => {
            //               liveFloorState[ws].status = "booked";
            //               liveFloorState[ws].tooltip = block.category;
            //             });
            //           }
            //         }
            //       });
            //     },
            //     (error) => {
            //       console.log(error);
            //     }
            //   );

            //***  Step 3: Get all Block Bookings of selected date and mark them as Booked
            // block_booking_ref
            //   .where("blocked_date", "==", curr_date_timestamp)
            //   //     .get()
            //   //    .then(
            //   .onSnapshot(
            //     (querySnapshot) => {
            //       querySnapshot.forEach((doc) => {
            //         const block = doc.data();
            //         if (block && block.reserved_block[loadFloor]) {
            //           block.reserved_block[loadFloor].forEach((ws) => {
            //             liveFloorState[ws].status = "booked";
            //             liveFloorState[ws].tooltip =
            //               "Blocked By - " + block.blocked_by;
            //           });
            //         }
            //       });

                  //***  Step 3: Get all SEAT Bookings of selected date and mark them as Booked
                  seat_booking_ref
                    .where("blocked_date", "==", curr_date_timestamp)
                    // .get()
                    // .then(
                    .onSnapshot(
                      (querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                          const seat = doc.data();
                          if (
                            seat.booked_ws &&
                            liveFloorState[seat.booked_ws]
                          ) {
                            liveFloorState[seat.booked_ws].status = "booked";
                            liveFloorState[seat.booked_ws].tooltip =
                              seat.booked_by;
                          }
                        });

                        all_floors_state[loadFloor] = {
                          ...all_floors_state[loadFloor],
                          ...liveFloorState,
                        };
                        // setFloorState({...floorState, ...all_floors_state})
                        setFloorState({ ...all_floors_state });

                        setLoading(false);
                      },
                      (error) => {
                        history.push("error", error);
                      }
                    );
                // },

                // (error) => {
                //   history.push("error", error);
                // }
              // );
          }

          //******************************************* FLOOR DIVISION INTO WINGS ******************************************************
          else if (location.state.action === "floor_division_wings") {
            setLoading(true);
            // console.log("Floor Division");
            // set_no_of_seats(location.state.noOfSeats)   // Allow to select multiple seats while "Reserve Block"
            setBookingType(location.state.wingName);
            let edit_flag = false;
            // console.log(location.state.wing_data)
            setFloorOptions([location.state.floor])
            setLoadFloor(location.state.floor)
            // let all_floors_state = {...floorState}
            // let liveFloorState = {...all_floors_state[loadFloor]}

            // const book_date = new Date(location.state.selectedDate)

                  //*** Step 0:   Mark seats as 'available'
                  Object.keys(all_floors_state[loadFloor]).forEach((ws) => {
                    liveFloorState[ws].status = "available";
                    liveFloorState[ws].tooltip = "Not Available";
                  }
                );

            const floor_division_ref = firebase
              .firestore()
              .collection(
                "FloorDivision/" +
                  location.state.commonState.site +
                  "/" +
                  loadFloor
              );

            floor_division_ref.get().then(
              (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  const block = doc.data();

                  // console.log(block);
                  //   console.log("doc.data()", doc.data())
                  if (block) {
                    //editing an wing -> Wing already present and having the same floor
                    if (
                      block.wing === location.state.wingName &&
                      loadFloor ===
                        Object.keys(location.state.wing_data.floor)[0]
                    ) {
                      // console.log("Editing Wing");
                      edit_flag = true;
                      // console.log(
                      //   Object.keys(location.state.wing_data.floor)[0]
                      // );
                      // console.log(location.state.wing_data.floor.keys);
                      block.floor[loadFloor].forEach((ws) => {
                        // console.log(ws);
                        liveFloorState[ws].status = "selected";
                        liveFloorState[ws].tooltip = "selected before";
                      });
                      // console.log(location.state.wing_data.floor);
                      // setSelectedWS({ ...location.state.wing_data.floor })
                    } else if (block.floor[loadFloor]) {
                      block.floor[loadFloor].forEach((ws) => {
                        // console.log(ws);
                        liveFloorState[ws].status = "blocked";
                        liveFloorState[ws].tooltip = block.wing;
                      });
                    }
                  }
                });
                if (edit_flag) {
                  setSelectedWS({ ...location.state.wing_data.floor });
                }
                all_floors_state[loadFloor] = {
                  ...all_floors_state[loadFloor],
                  ...liveFloorState,
                };
                setFloorState({ ...floorState, ...all_floors_state }); //Update state only once
                setLoading(false);
              },
              (error) => {
                console.log(error);
              }
            );
            // all_floors_state[loadFloor] = {...all_floors_state[loadFloor], ...liveFloorState }
            // // setFloorState({...floorState, ...all_floors_state})
            // setFloorState({...all_floors_state})
          }

          else if (location.state.action === "assign_ws_attribs") {
            setLoading(true);
            // console.log("Floor Division");
            // set_no_of_seats(location.state.noOfSeats)   // Allow to select multiple seats while "Reserve Block"
            setBookingType("Assign WS Attribs");
            // let edit_flag = false;
            // console.log(location.state.wing_data)
            setFloorOptions([location.state.floor])
            setLoadFloor(location.state.floor)
  
                  //*** Step 0:   Mark seats as 'available'
                  Object.keys(all_floors_state[loadFloor]).forEach((ws) => {
                    liveFloorState[ws].status = "available";
                    liveFloorState[ws].tooltip = "Not Available";
                  }
                );


            if (seatAttributes.length === 0) {
              setSeatAttributes([{key: 0, label:location.state.ws_attribute , class: 'attribute-selected'}])
            }

            let temp_liveFloorState ={...liveFloorState}
            let temp_selectedWS = [];
            Object.keys(temp_liveFloorState).forEach((ws) => {
              if(temp_liveFloorState[ws]['attributes'] && temp_liveFloorState[ws]['attributes'].length > 0 && temp_liveFloorState[ws]['attributes'].indexOf(location.state.ws_attribute) > -1){
                liveFloorState[ws].status = "selected";
                liveFloorState[ws].tooltip = "selected before";
                temp_selectedWS.push(ws);
              }
            });

            let tempSelectedWSObj = {};
            tempSelectedWSObj[loadFloor] = [...temp_selectedWS]
            setSelectedWS({...tempSelectedWSObj});

            setLoading(false);
            all_floors_state[loadFloor] = {...all_floors_state[loadFloor], ...liveFloorState }
            setFloorState({...floorState, ...all_floors_state})
          }

          // ***************************************** ORG SPACE DIVISION *****************************************************
          else if (location.state.action === "assign_org_space") {
            setLoading(true);
            // console.log("Floor Division");
            // set_no_of_seats(location.state.noOfSeats)   // Allow to select multiple seats while "Reserve Block"
            setBookingType("Assign Org Space");
            // let edit_flag = false;
            // console.log(location.state.wing_data)
            setFloorOptions([location.state.floor])
            setLoadFloor(location.state.floor)
  
                  //*** Step 0:   Mark seats as 'available'
                  Object.keys(all_floors_state[loadFloor]).forEach((ws) => {
                    liveFloorState[ws].status = "available";
                    liveFloorState[ws].tooltip = "Not Available";
                  }
                );
                let edit_flag = false;
                const floor_division_ref = firebase
                .firestore()
                .collection(
                  "OrgDivision/" +
                    location.state.commonState.site +
                    "/" +
                    loadFloor
                );
  
              floor_division_ref.get().then(
                (querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    const block = doc.data();
                    if (block) {
                      //editing an Org -> Org already present and having the same floor
                      if (
                        block.org === location.state.org &&
                        loadFloor ===
                          Object.keys(location.state.wing_data.floor)[0]
                      ) {
                        edit_flag = true;
                        block.floor[loadFloor].forEach((ws) => {
                          liveFloorState[ws].status = "selected";
                          liveFloorState[ws].tooltip = "selected before";
                        });
                      } else if (block.floor[loadFloor]) {
                        block.floor[loadFloor].forEach((ws) => {
                          // console.log(ws);
                          liveFloorState[ws].status = "blocked";
                          liveFloorState[ws].tooltip = block.org;
                        });
                      }
                    }
                  });
                  if (edit_flag) {
                    setSelectedWS({ ...location.state.wing_data.floor });
                  }
                  all_floors_state[loadFloor] = {
                    ...all_floors_state[loadFloor],
                    ...liveFloorState,
                  };
                  setFloorState({ ...floorState, ...all_floors_state }); //Update state only once
                  setLoading(false);
                },
                (error) => {
                  console.log(error);
                }
              );
  

          }


          //******************************************* RESERVE CATEGORY ******************************************************
          else if (location.state.action === "reserve_category") {
            setLoading(true);

            set_no_of_seats(location.state.noOfSeats); // Allow to select multiple seats while "Reserve Block"
            setBookingType(location.state.category_name);
            // console.log(location.state.active_days);
            // const book_date = new Date(location.state.selectedDate);
            let i;
            let active_days_keys = [];
            for (i in location.state.active_days) {
              if (location.state.active_days[i] === true) {
                active_days_keys.push(i);
              }
            }

                  //*** Step 0:   Mark seats as 'available'
                  Object.keys(all_floors_state[loadFloor]).forEach((ws) => {
                      liveFloorState[ws].status = "available";
                      liveFloorState[ws].tooltip = "Not Available";
                    }
                  );


            // console.log(active_days_keys);
            //*** Step 1: get all Blocked Seats, Special Reservation Seats & Social Distance Seats
            // for active_days selected from Categories section in Admin Dashboard
            // For eg Active Days passed is Mon, Tue --> so when a seat is blocked even for any of the active days it
            // will be marked as blocked as we need seats that are available on (here) both Mon and Tues
            // mark them accordingly on floor map
            blocked_seats_ref
              .where("isActive", "==", true)
              .get()
              .then(
                (querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    const block = doc.data();
                    if (block) {
                      let check_common_days = false;
                      for (i in active_days_keys) {
                        if (block.active_days[active_days_keys[i]] === true) {
                          check_common_days = true;
                        }
                      }
                      if (check_common_days) {
                        if (block.floor[loadFloor]) {
                          if (block.BlockName === "Special Reservation Block") {
                            block.floor[loadFloor].forEach((ws) => {
                              liveFloorState[ws].status = "blocked";
                              liveFloorState[ws].tooltip = block.BlockName;
                            });
                          } else if (block.BlockName === "Social Distance") {
                            block.floor[loadFloor].forEach((ws) => {
                              liveFloorState[ws].status =
                                "blocked_social_distance";
                              liveFloorState[ws].tooltip = block.BlockName;
                            });
                          } else {
                            block.floor[loadFloor].forEach((ws) => {
                              liveFloorState[ws].status = "blocked";
                              liveFloorState[ws].tooltip = block.BlockName;
                            });
                          }
                        }
                      }
                    }
                  });
                },

                //***  Step 2: Get all reserved categories and mark them blocked on the floor map for common days
                categories_ref
                  .where("isActive", "==", true)
                  // .where("week_preference.group", "==", location.state.week_preference.group)

                  .get()
                  .then(
                    (querySnapshot) => {
                      querySnapshot.forEach((doc) => {
                        const block = doc.data();
                        //   console.log("doc.data()", doc.data())
                        if (block) {
                          if (block.category === location.state.category_name) {
                            // console.log("editing category");
                            if (!location.state.re_select) {
                              if (block.floor[loadFloor]) {
                                block.floor[loadFloor].forEach((ws) => {
                                  // if(liveFloorState[ws].status === "available"){
                                  liveFloorState[ws].status = "selected";
                                  liveFloorState[ws].tooltip = "selected before";
                                // }
                                });
                              }
                            } else {
                              if (block.floor[loadFloor]) {
                                block.floor[loadFloor].forEach((ws) => {
                                  if(liveFloorState[ws].status === "available"){
                                  liveFloorState[ws].status = "available";
                                  liveFloorState[ws].tooltip = "available";}
                                });
                              }
                            }
                          } 
                          else {  
                              let check_common_days_cat = false;
                              for (i in active_days_keys) {
                                if (block.active_days[active_days_keys[i]] === true) {
                                  check_common_days_cat = true;
                                  // console.log("common day");
                                }
                              }
                              if (check_common_days_cat && location.state.conflictCategories.includes(block.category)) {
                                if (block.floor[loadFloor]) {
                                  block.floor[loadFloor].forEach((ws) => {
                  //+++ Commented by Suhas to allow all seat selections by all categories (for the plan May and June)
                                    // liveFloorState[ws].status = "blocked";
                                    // liveFloorState[ws].tooltip = block.category;
                  //--- Commented by Suhas to allow all seat selections by all categories (for the plan May and June)                  
                                  });
                                }
                              }
                          }
                        }
                      });
                      if (!location.state.new_cat) {
                        if(Object.keys(selectedWS).length === 0){
                          setSelectedWS({ ...location.state.floor_data });
                        }
                      }
    
                      all_floors_state[loadFloor] = {
                        ...all_floors_state[loadFloor],
                        ...liveFloorState,
                      };
                      setFloorState({ ...floorState, ...all_floors_state });
                      setLoading(false);
                    },
                    (error) => {
                      console.log(error);
                    }
                  )
                               
                
                // (error) => {
                //   console.log(error);
                // }
                );
                
          }
          //******************************************* RESERVE BLOCKED SEATS ******************************************************
          else if (location.state.action === "reserve_blocked_seat") {
            setLoading(true);

            set_no_of_seats(location.state.noOfSeats); // Allow to select multiple seats while "Reserve Block"
            setBookingType(location.state.selected_block);
            // console.log(location.state.active_days);
            // const book_date = new Date(location.state.selectedDate);
            let i;
            let active_days_keys = [];
            for (i in location.state.active_days) {
              if (location.state.active_days[i] === true) {
                active_days_keys.push(i);
              }
            }

                  //*** Step 0:   Mark seats as 'available'
                  Object.keys(all_floors_state[loadFloor]).forEach((ws) => {
                    liveFloorState[ws].status = "available";
                    liveFloorState[ws].tooltip = "Not Available";
                  }
                );


            // console.log(active_days_keys);
            //*** Step 1: get all Blocked Seats, Special Reservation Seats & Social Distance Seats
            // for active_days selected from Categories section in Admin Dashboard
            // For eg Active Days passed is Mon, Tue --> so when a seat is blocked even for any of the active days it
            // will be marked as blocked as we need seats that are available on (here) both Mon and Tues
            // mark them accordingly on floor map
            blocked_seats_ref
              .where("isActive", "==", true)
              .get()
              .then(
                (querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    const block = doc.data();
                    if (block) {
                      if (
                        !location.state.new_cat &&
                        doc.id === location.state.doc_id
                      ) {
                        //editing mode (same block)
                        if (!location.state.re_select) {
                          if (block.floor[loadFloor]) {
                            block.floor[loadFloor].forEach((ws) => {
                              // if(liveFloorState[ws].status === "available"){
                                liveFloorState[ws].status = "selected";
                                liveFloorState[ws].tooltip = "selected before";
                              // }
                              
                            });
                          }
                        } else {
                          if (block.floor[loadFloor]) {
                            block.floor[loadFloor].forEach((ws) => {
                               if(liveFloorState[ws].status === "available"){
                              liveFloorState[ws].status = "available";
                              liveFloorState[ws].tooltip = "available";}
                            });
                          }
                        }
                      } 
                      else {
                        let check_common_days = false;
                        for (i in active_days_keys) {
                          if (block.active_days[active_days_keys[i]] === true) {
                            check_common_days = true;
                          }
                        }
                        if (check_common_days) {
                          if (block.floor[loadFloor]) {
                            if (
                              block.BlockName === "Special Reservation Block"
                            ) {
                              block.floor[loadFloor].forEach((ws) => {
                                liveFloorState[ws].status = "blocked";
                                liveFloorState[ws].tooltip = block.BlockName;
                              });
                            } else if (block.BlockName === "Social Distance") {
                              block.floor[loadFloor].forEach((ws) => {
                                liveFloorState[ws].status =
                                  "blocked_social_distance";
                                liveFloorState[ws].tooltip = block.BlockName;
                              });
                            } else {
                              block.floor[loadFloor].forEach((ws) => {
                                liveFloorState[ws].status = "blocked";
                                liveFloorState[ws].tooltip = block.BlockName;
                              });
                            }
                          }
                        }
                      }
                    }
                  });
                },
                (error) => {
                  console.log(error);
                }
              );

            if (!location.state.new_cat) {
              // console.log("add WS");             
                if(Object.keys(selectedWS).length === 0){
                  setSelectedWS({ ...location.state.floor_data });
                }
            }
          
            
            // console.log(selectedWS);
            // console.log(location.state.floor_data);
            // console.log(location.state.re_select);
            //***  Step 2: Get all reserved categories and mark them blocked on the floor map for common days
            categories_ref
              .where("isActive", "==", true)
              .get()
              .then(
                (querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    const block = doc.data();
                    //   console.log("doc.data()", doc.data())
                    if (block) {
                    
                      // console.log(location.state.category_name)
                      let check_common_days_cat = false;
                      for (i in active_days_keys) {
                        if (block.active_days[active_days_keys[i]] === true) {
                          check_common_days_cat = true;
                        }
                      }
                      if (check_common_days_cat) {
                        if (block.floor[loadFloor]) {
                          block.floor[loadFloor].forEach((ws) => {
                            liveFloorState[ws].status = "blocked";
                            liveFloorState[ws].tooltip = block.category;
                          });
                        }
                      }
                    }
                  });

                  all_floors_state[loadFloor] = {
                    ...all_floors_state[loadFloor],
                    ...liveFloorState,
                  };
                  setFloorState({ ...floorState, ...all_floors_state });
                  setLoading(false);
                },
                (error) => {
                  console.log(error);
                }
              );
          }
          //******************************************* SPECIAL BOOKING DATES******************************************************
          else if (location.state.action === "special_booking_date") {
            setLoading(true);
            set_no_of_seats(1); // Allow only one booking for "Select a Seat"
            setBookingType("Special Booking");

            // let all_floors_state = {...floorState}
            // let liveFloorState = {...floorState[loadFloor]}

            const curr_date = new Date(location.state.commonState.currentDate);

            //**Step : Block Cubicles in this mode if the user doesn't have the cubicle booking role*/
            if (location.state.commonState.site_data.cubicles_data && !location.state.commonState.cubicleBookingAccess){
              const temp_cubicle_floorState = { ...location.state.commonState.site_data.cubicles_data.floors };
              const temp_ws_keys = Object.keys(temp_cubicle_floorState[loadFloor]);
              temp_ws_keys.forEach((ws) => {
                  // console.log(ws)
                  liveFloorState[ws].status = "blocked";
                  liveFloorState[ws].tooltip = "Not Available";
              });  
            }            

            //*** Step 1: Get all permanent Blocked Seats/Special BLock Seats & Social Distance Seats and mark them on Floor Map
            blocked_seats_dates_ref
              .where("isActive", "==", true)
              .where("BlockName","==", "Special Reservation Seats")
              .where("group","==", location.state.commonState.organization)
              .where("active_date_to",">=", location.state.commonState.currentDate)
              .get()
              .then(
                (querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    const block = doc.data();
                    if (block) {
                      if (block.floor[loadFloor]) {
                        if (block.BlockName === "Special Reservation Seats") {
                          block.floor[loadFloor].forEach((ws) => {
                            liveFloorState[ws].status = "Special Block";
                               liveFloorState[ws].tooltip = "Available";
                          });
                        } else {
                          block.floor[loadFloor].forEach((ws) => {
                            liveFloorState[ws].status = "blocked";
                            liveFloorState[ws].tooltip = block.BlockName;
                          });
                        }
                      }
                    }
                  });

                  var temp_cu_keys = [];
                  if (location.state.commonState.site_data.cubicles_data && location.state.commonState.cubicleBookingAccess){
                    const temp_cubicle_floorState = { ...location.state.commonState.site_data.cubicles_data.floors };
                    temp_cu_keys = Object.keys(temp_cubicle_floorState[loadFloor]);
                  }
                  

                  //*** Step 1.1:   Mark special block as available and rest all seats as 'Blocked'. 'Social Distance Seats will Preserve their status as 'blocked_social_distance'.
                  Object.keys(all_floors_state[loadFloor]).forEach((ws) => {
                    if (liveFloorState[ws].status === "Special Block") {
                      liveFloorState[ws].status = "available";
                    } else if (temp_cu_keys.includes(ws) &&
                      liveFloorState[ws].status === "available") {
                    } else if (liveFloorState[ws].status === "available") {
                      liveFloorState[ws].status = "blocked";
                      liveFloorState[ws].tooltip = "Not Available";
                    }
                  });

                  //**Step : Block Cubicles in this mode if the user doesn't have the cubicle booking role*/
                  if (location.state.commonState.site_data.cubicles_data && !location.state.commonState.cubicleBookingAccess){
                    const temp_cubicle_floorState = { ...location.state.commonState.site_data.cubicles_data.floors };
                    const temp_ws_keys = Object.keys(temp_cubicle_floorState[loadFloor]);
                    temp_ws_keys.forEach((ws) => {
                        // console.log(ws)
                        liveFloorState[ws].status = "blocked";
                        liveFloorState[ws].tooltip = "Not Available";
                    });  
                  }                            

                  //*** Step 2: Get Seats booked from the special block. Mark them as booked.
                  seat_booking_ref
                    .where("blocked_date", "==", selectedDate)
                    // .where("block_id", "==", "special_booking")
                    .onSnapshot(
                      // .get()
                      // .then(
                      (querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                          // const seat = doc.data().booked_ws;
                          // if (liveFloorState[seat]) {
                          //   liveFloorState[seat].status = "booked";
                          //   liveFloorState[seat].tooltip = seat.booked_by;
                          // }
                          const seat = doc.data();
                          if (
                            seat.booked_ws &&
                            liveFloorState[seat.booked_ws]
                          ) {
                            liveFloorState[seat.booked_ws].status = "booked";
                            liveFloorState[seat.booked_ws].tooltip =
                              seat.booked_by;
                          }                          
                        });
                        all_floors_state[loadFloor] = {
                          ...all_floors_state[loadFloor],
                          ...liveFloorState,
                        };
                        setFloorState({ ...floorState, ...all_floors_state }); //Update state only once
                        setLoading(false);
                      },
                      (error) => {
                        console.log(error);
                      }
                    );
                },
                (error) => {
                  console.log(error);
                }
              );
          }

          //******************************************* RESERVE BLOCKED SEATS WITH DATES ******************************************************
          else if (location.state.action === "reserve_blocked_seat_date") {
            setLoading(true);

            set_no_of_seats(location.state.noOfSeats); // Allow to select multiple seats while "Reserve Block"
            setBookingType(location.state.selected_block);
            // console.log(location.state.active_days);
            // const book_date = new Date(location.state.selectedDate);
            let i;
            let active_days_keys = [];
            for (i in location.state.active_days) {
              if (location.state.active_days[i] === true) {
                active_days_keys.push(i);
              }
            }

            //*** Step 0:   Mark seats as 'available'
            Object.keys(all_floors_state[loadFloor]).forEach((ws) => {
              liveFloorState[ws].status = "available";
              liveFloorState[ws].tooltip = "Not Available";
            }
            );


            // console.log(active_days_keys);
            //*** Step 1: get all Blocked Seats, Special Reservation Seats & Social Distance Seats
            // for active_days selected from Categories section in Admin Dashboard
            // For eg Active Days passed is Mon, Tue --> so when a seat is blocked even for any of the active days it
            // will be marked as blocked as we need seats that are available on (here) both Mon and Tues
            // mark them accordingly on floor map

            blocked_seats_dates_ref
              .where("isActive", "==", true)
              .where("active_date_from","<=", format_dt_to_ts(location.state.active_date_from))
              .where("BlockName","==", "Special Reservation Seats")
              // .where("active_date_to","<=", location.state.active_date_to)
              .get()
              .then(
                (querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    const block = doc.data();
                    if (block) {
                      if (format_dt_to_ts(location.state.active_date_to) <= block.active_date_to) {
                        if (block.floor[loadFloor]) {
                          block.floor[loadFloor].forEach((ws) => {
                            liveFloorState[ws].status = "blocked";
                            liveFloorState[ws].tooltip = block.BlockName;
                          });
                        }
                      }

                    }
                  });
                  all_floors_state[loadFloor] = {
                    ...all_floors_state[loadFloor],
                    ...liveFloorState,
                  };
                  setFloorState({ ...floorState, ...all_floors_state }); //Update state only once
                  setLoading(false);
                },
                (error) => {
                  console.log(error);
                }
              );

            if (!location.state.new_cat) {
              // console.log("add WS");             
                if(Object.keys(selectedWS).length === 0){
                  setSelectedWS({ ...location.state.floor_data });
                }
            }
          
            
          }          
          setLoading(false);
        } // if liveFloorState
      }); //  sites_ref querySnapshot => {
  }, [loadFloor]);
// },
// [location.state.commonState.site, location.state.commonState.site_data.seat_attributes, location.state.commonState.site_data.cubicles_data, location.state.commonState.cubicleBookingAccess, location.state.commonState.currentDate, location.state.commonState.category, location.state.commonState.userEmail, location.state.action, location.state.noOfSeats, location.state.selectedDate, location.state.booking_obj.blocked_date, location.state.booking_obj.block_id, location.state.booking_obj.reserved_block, location.state.wingName, location.state.floor, location.state.wing_data.floor, location.state.ws_attribute, location.state.category_name, location.state.active_days, location.state.new_cat, location.state.re_select, location.state.conflictCategories, location.state.floor_data, location.state.selected_block, location.state.doc_id, loadFloor, floorState, floorOptions, seatAttributes.length, blocked_seats_ref, categories_ref, block_booking_ref, selectedDate, seat_booking_ref, history, curr_date_timestamp, confirmAutoBooking, selectedWS]
// );
  // const onSelectSeat = (seat) => {
  //     setSelectedSeat(seat)
  // }
  const TotalSeats = (seats) => {
    //Display total no. of seats on floor
    setTotalSeats(seats);
  };

  const seatSelected = (e) => {
    if (
      location.state.action === "floor_division_wings" ||
      location.state.action === "reserve_category" ||
      location.state.action === "reserve_blocked_seat" ||
      location.state.action === "reserve_blocked_seat_date" ||
      location.state.action === "assign_ws_attribs" ||
      location.state.action === "assign_org_space"
    ) {
      //Tap functionality for admin dashboard actions
      seatSelectedTap(e);
    } else {
      // console.log(e.target.id);
      let id;
      if(e.target){
        id = e.target.id;
      }
      else {
        id = e;
      }
     
      var chkText = id.substr(id.length - 5);

      if (chkText === "_text") {
        id = id.substring(0, id.length - 5);
        // console.log(id)
      }

      let temp_ws = { ...selectedWS };
      // temp_ws[loadFloor]=[]
      // setSelectedWS({...temp_ws})

      let WSList = [];
      let i = 1;
      let wsID = id;
      let wing = "";
      // let counter = 1;

      // let lastSeat = parseInt(lastSeatNo.substring(2,lastSeatNo.length))

      let dummy_floor_state = { ...floorState };
      let current_floor = { ...dummy_floor_state[loadFloor] };
      let start_selection = false;

      if (current_floor[wsID].status === "selected") {
        Object.keys(current_floor).forEach((floor_ws) => {
          if (current_floor[floor_ws].status === "selected") {
            current_floor[floor_ws].status = "available";
          }
        });
        setSelectedWS({});
      } else {
        Object.keys(current_floor).forEach((floor_ws) => {
          if (current_floor[floor_ws].status === "selected") {
            current_floor[floor_ws].status = "available";
          }

          if (floor_ws === wsID) {
            start_selection = true;
          }

          if (
            start_selection &&
            document.getElementById(floor_ws) &&
            current_floor[floor_ws].status === "available" &&
            i <= no_of_seats
          ) {
            current_floor[floor_ws].status = "selected";

            if (wing === "") {
              wing = current_floor[floor_ws].wing;
              setSelectedWing(wing);
            }

            WSList.push(floor_ws);
            i++;
          }
        });

        temp_ws[loadFloor] = [...WSList];

        setSelectedWS({ ...temp_ws });
      }

      if (WSList[0]) {
        if (no_of_seats === 1) {
          setSelectedSeat(WSList[0]);
        } else {
          setSelectedSeat(WSList[0] + "-" + WSList[WSList.length - 1]);
        }
      } else {
        setSelectedSeat("");
      }

      // console.log(WSList);
      // console.log({ ...selectedWS });

      // setSelectedWS(id)
      // props.seatSelected(id)

      dummy_floor_state[loadFloor] = {
        ...dummy_floor_state[loadFloor],
        ...current_floor,
      };
      setFloorState({ ...floorState, ...dummy_floor_state });
    }
  };

  const seatSelectedTap = (e) => {
    // console.log(e.target.id);
    let id;
    if(e.target){
      id = e.target.id;
    }
    else {
      id = e;
    }

    var chkText = id.substr(id.length - 5);

    if (chkText === "_text") {
      id = id.substring(0, id.length - 5);
    }

    let temp_ws = { ...selectedWS };
    let temp_arr = temp_ws[loadFloor];
    let WSList = [];
    // let i = 0;

    let wsID = id;
    // let wing = "";
    // let counter = 1;
    // console.log({ ...selectedWS });

    let dummy_floor_state = { ...floorState };
    let current_floor = { ...dummy_floor_state[loadFloor] };
    // let start_selection = false;
    if (current_floor[wsID].status === "selected") {
      current_floor[wsID].status = "available";
      if (temp_arr) {
        //After the array is defined, push the elements
        const index = temp_arr.indexOf(wsID);
        if (index > -1) {
          temp_arr.splice(index, 1);
        }
        temp_ws[loadFloor] = [...temp_arr];
        setSelectedWS({ ...temp_ws });
      } else {
        //first time ->undefined object
        setSelectedWS({});
      }
    } else {
      if (current_floor[wsID].status === "available") {
        if (temp_arr) {
          //After the array is defined, push the elements
          temp_arr.push(wsID);
          current_floor[wsID].status = "selected";
          temp_ws[loadFloor] = [...temp_arr];
          setSelectedWS({ ...temp_ws });
        } else {
          //first time ->undefined object
          // console.log("first");
          WSList.push(wsID);
          temp_ws[loadFloor] = [...WSList];
          current_floor[wsID].status = "selected";
          setSelectedWS({ ...temp_ws });
        }
      }
      // let t_counter = no_of_seats_tap + 1;
      // set_no_of_seats_tap(t_counter);
      // setSelectedSeat(`Selected Seats : ${no_of_seats_tap}`);
      // temp_ws[loadFloor] = []

      // temp_ws[loadFloor] = [...WSList]
    }

    // if (WSList[0]) {
    //     if (no_of_seats === 1) {
    //         setSelectedSeat(WSList[0])
    //     }
    //     else {
    //         setSelectedSeat(WSList[0] + '-' + WSList[WSList.length - 1])
    //     }
    // }
    // else {
    //     setSelectedSeat('')
    // }

    // console.log(WSList);
    // console.log({ ...selectedWS });

    // setSelectedWS(id)
    // props.seatSelected(id)

    dummy_floor_state[loadFloor] = {
      ...dummy_floor_state[loadFloor],
      ...current_floor,
    };
    setFloorState({ ...floorState, ...dummy_floor_state });
  };



  const loadSeatAttributes = () => {

    const ListItem = styled('li')(({ theme }) => ({
      margin: theme.spacing(0.5),
    }));

    let objIndex;

    const handleClick = (seatAttr) => () => {

      const tempSeatAttributes = [...seatAttributes];

      objIndex = tempSeatAttributes.findIndex((obj => obj.label === seatAttr.label));
      if (tempSeatAttributes[objIndex].class === "attribute-selected") {
        tempSeatAttributes[objIndex].class = "attribute-not-selected"
      }
      else{
        tempSeatAttributes[objIndex].class = "attribute-selected"
      }
      
      setSeatAttributes([...tempSeatAttributes])

    };

    return (
      <div className="ws_attrib">
        {seatAttributes.length > 0 && (!seatAttributes[0].label === '')  && (
       <Paper
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 0.5,
        m: 0,
      }}
      component="ul"
    >
      {seatAttributes.map((data) => {
        let icon;

        // if (data.label === 'React') {
        //    icon = <TagFacesIcon />;
        // }

        return (
          <ListItem key={data.key}>
            <Chip
              icon={icon}
              label={data.label}
              className={data.class}
              onClick={handleClick(data)}
              // onDelete={data.label === 'React' ? undefined : handleDelete(data)}
            />
          </ListItem>
        );
      })}
    </Paper>
        )}
      </div>
    )
  }

  const handleSelectAll = (e) => {
    set_select_all(e.target.checked)

    let dummy_floor_state = { ...floorState };
    let current_floor = { ...dummy_floor_state[loadFloor] };

    let temp_ws = { ...selectedWS };
    let temp_arr = temp_ws[loadFloor];
    let WSList = [];

    Object.keys(floorState[loadFloor]).forEach((ws) => {
      // seatSelected(floor_ws);
      if(e.target.checked){
        if( current_floor[ws].status === "available") {
        if(temp_arr){
          temp_arr.push(ws);
          current_floor[ws].status = "selected";
          temp_ws[loadFloor] = [...temp_arr];
        }
        else {
          WSList.push(ws);
          current_floor[ws].status = "selected";
          temp_ws[loadFloor] = [...WSList];
        }
      }
      } 
      else {
        temp_ws = [];
        Object.keys(floorState[loadFloor]).forEach((ws) => {
          if( current_floor[ws].status === "selected") {
            current_floor[ws].status = "available";
          }          
        });
      }
    });
    
    setSelectedWS({ ...temp_ws });

    dummy_floor_state[loadFloor] = {
      ...dummy_floor_state[loadFloor],
      ...current_floor,
    };
    setFloorState({ ...floorState, ...dummy_floor_state });
}

  const select_all_button = () => {
    if (
      location.state.action === "floor_division_wings" ||
      location.state.action === "reserve_category" ||
      location.state.action === "reserve_blocked_seat" ||
      location.state.action === "reserve_blocked_seat_date" ||
      location.state.action === "assign_ws_attribs"  ||
      location.state.action === "assign_org_space"
    ) {
        return (
          <div className='select-all-seats'>
          <Checkbox checked={select_all}
              label='Select All'
              onChange={(e) => { handleSelectAll(e) }}
          />
          <span>Select All</span>
      </div>

        )
    }
  }

  const loadFloorPlan = () => {
    if (floorState) {

      // setLoading(false)
      switch (location.state.commonState.site + " " + loadFloor) {
        case "CGBS-Mumbai CP-4th-floor":
          return (
            <CGBSFloorFour
              // seatSelected={onSelectSeat}
              totalSeats={TotalSeats}
              no_of_seats={no_of_seats}
              seatAttributes = {seatAttributes}
              seatSelected={(e) => {
                seatSelected(e);
              }}
              // available_seats={available_seats}
              selectedWS={selectedWS}
              floorState={floorState["CP-4th-floor"]}
            />
          );
        case "CGBS-Mumbai 8th-floor":
          return (
            <CGBSFloorEight
              // seatSelected={onSelectSeat}
              totalSeats={TotalSeats}
              no_of_seats={no_of_seats}
              seatAttributes = {seatAttributes}
              seatSelected={(e) => {
                seatSelected(e);
              }}
              // available_seats={available_seats}
              selectedWS={selectedWS}
              floorState={floorState["8th-floor"]}
            />
          );
        case "CGBS-Mumbai 9th-floor":
          return (
            <CGBSFloorNine
              // seatSelected={onSelectSeat}
              totalSeats={TotalSeats}
              no_of_seats={no_of_seats}
              seatAttributes = {seatAttributes}
              seatSelected={(e) => {
                seatSelected(e);
              }}
              // available_seats={available_seats}
              selectedWS={selectedWS}
              floorState={floorState["9th-floor"]}
            />
          );
        default:
          return null;
      }
    } else {
      return <svg viewBox="0 0 800 1450"></svg>;
    }
  };

  const reserveBlock = () => {
    const seats_selected = selectedWS[loadFloor];
    if (Object.keys(selectedWS).length === 0 || seats_selected === 0 ) {     //Added check on selected workstations too
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui confirm_dialog">
              <div className="confirm_title">Validation</div>
              <div className="confirm_msg">
                <div className="img">
                  <img
                    alt=""
                    style={{ width: 50, height: 50 }}
                    src={warningIcon}
                  />
                </div>
                <div className="msg">
                  Please selects seats on the floor map to proceed.
                </div>
              </div>
              <div className="confirm_buttons">
                <button
                  className="confirm_yes"
                  onClick={() => {
                    onClose();
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          );
        },
      });
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui confirm_dialog">
              <div className="confirm_title">Confirmation</div>
              <div className="confirm_msg">
                <div className="img">
                  <img
                    alt=""
                    style={{ width: 50, height: 50 }}
                    src={confirmIcon}
                  />
                </div>
                <div className="msg">
                {/* Selected Work Stations- {selectedSeat} */} 
                  Selected {seats_selected.length} Work Stations : {selectedSeat}      
                </div>
              </div>

              <div className="confirm_buttons">
                <button className="confirm_no" onClick={onClose}>
                  Back
                </button>
                <button
                  className="confirm_yes"
                  onClick={() => {
                    setLoading(true);
                    const newBlock = {
                      blocked_by: location.state.commonState.userName,
                      blocked_by_email: location.state.commonState.userEmail,
                      blocked_date: location.state.selectedDate,
                      blocked_on: new Date(),
                      wing: selectedWing,
                      floor: loadFloor,
                      // seats_blocked: location.state.noOfSeats,
                      seats_blocked: seats_selected.length,  //Added the actual no of seats selected
                      seats_confirmed: 0,
                      site: location.state.commonState.site,
                      reserved_block: selectedWS,
                    };

                    block_booking_ref
                      .add({
                        ...newBlock,
                      })
                      .then(function (docRef) {
                        buildEmail("New Block", newBlock);

                        // console.log(
                        //   "Block reserved successfully ID: " + docRef.id
                        // );
                        setLoading(false);
                        history.push("/");
                      })
                      .catch(function (error) {
                        console.error("Error while REserving a Block: ", error);
                      });
                    onClose();
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          );
        },
      });
    }
  };


  const buildEmail = (booking_type, booking) => {

    let mail_to = '';
    let mail_cc = '';
    let bookingDetails ={};

    const user_ref = firebase.firestore().collection("Users");

   if(booking_type === "New Block") {
    user_ref
    .where("site", "==", location.state.commonState.site)
    .where("blockAdminEmail", "==", location.state.commonState.userEmail)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const usr = doc.data();
        // console.log('usr', usr)
        if(mail_to === '') {
          mail_to = usr.userEmail
        }
        else {
          mail_to = mail_to + ',' + usr.userEmail
        }
         
      })

      mail_cc = booking.blocked_by_email;

      bookingDetails = {
        date : printDate(),
        floor: booking.floor
      }
      sendEmail(booking_type, mail_to, mail_cc, bookingDetails, location.state.commonState)
      // sendEmail(booking_type, teamMembers, booking.blocked_by_email, bookingDetails)
    })
   }

   else if(booking_type === "Confirm Booking" || 
           booking_type === "special_booking" ||
           booking_type === "Site Level-Manual" ||
           booking_type === "Reserved Cat-Manual" ||
           booking_type === "Site Level-Auto" ||
           booking_type === "Reserved Cat-Auto"
           ) {
             
      mail_to = booking.booked_by_email
      mail_cc = ''
      bookingDetails = {
        date : printDate(),
        ws: booking.booked_ws,
        floor: booking.floor
      }

      sendEmail(booking_type, mail_to, mail_cc, bookingDetails, location.state.commonState)
   }

    
   
  };

  const allot_wing = (e) => {
    if (Object.keys(selectedWS).length === 0) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui confirm_dialog">
              <div className="confirm_title">Validation</div>
              <div className="confirm_msg">
                <div className="img">
                  <img
                    alt=""
                    style={{ width: 50, height: 50 }}
                    src={warningIcon}
                  />
                </div>
                <div className="msg">
                  Please selects seats on the floor map to proceed.
                </div>
              </div>
              <div className="confirm_buttons">
                <button
                  className="confirm_yes"
                  onClick={() => {
                    onClose();
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          );
        },
      });
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui confirm_dialog">
              <div className="confirm_title">Confirmation</div>
              <div className="confirm_msg">
                <div className="img">
                  <img
                    alt=""
                    style={{ width: 50, height: 50 }}
                    src={confirmIcon}
                  />
                </div>
                <div className="msg">
                  Selected {selectedWS[loadFloor].length} Work Stations under{" "}
                  {location.state.wingName}
                </div>
              </div>

              <div className="confirm_buttons">
                <button className="confirm_no" onClick={onClose}>
                  Back
                </button>
                <button
                  className="confirm_yes"
                  onClick={() => {
                    setLoading(true);

                    let sites_ref = firebase.firestore().collection("Sites");
                    // console.log(location.state.commonState.site);
                    sites_ref
                      .doc(location.state.commonState.site)
                      .get()
                      .then((doc) => {
                        if (!doc.exists) {
                          // console.log("No doc found");
                        } else {
                          let new_floor_state = {};
                          // console.log(doc.data());
                          new_floor_state = { ...doc.data() };
                          // console.log(new_floor_state);
                          let i;
                          if (new_floor_state["floors"]) {
                            for (i in selectedWS[loadFloor]) {
                              //"WS789", "WS790"
                              // console.log(selectedWS[loadFloor][i])
                              new_floor_state["floors"][loadFloor][
                                selectedWS[loadFloor][i]
                              ]["wing"] = location.state.wingName;
                            }
                          }
                          // console.log("Edited one");
                          // console.log(new_floor_state);

                          sites_ref
                            .doc(location.state.commonState.site)
                            .update({
                              floors: { ...new_floor_state["floors"] },
                            })
                            .then(function (docRef) {
                              // console.log("floor divisions saved");
                              setLoading(false);
                            })
                            .catch(function (error) {
                              console.error(
                                "Error while saving floor divisions"
                              );
                            });
                        }
                      });

                    const add_wing = {
                      wing: location.state.wingName,
                      floor: {
                        [loadFloor]: [...selectedWS[loadFloor]],
                      },
                      no_of_seats: selectedWS[loadFloor].length,
                      last_updated_on: new Date(),
                      last_updated_by: location.state.commonState.userName,
                    };
                    // console.log(add_wing);
                    const floor_division_ref = firebase
                      .firestore()
                      .collection(
                        "FloorDivision/" +
                          location.state.commonState.site +
                          "/" +
                          loadFloor
                      );
                    floor_division_ref
                      .doc(location.state.wingName)
                      .set({
                        ...add_wing,
                      })
                      .then(function (docRef) {
                        // console.log("floor divisions saved");
                        setLoading(false);
                      })
                      .catch(function (error) {
                        console.error("Error while saving floor divisions");
                      });
                    setLoading(false);
                    history.push("/admin", {
                      commonState: location.state.commonState,
                      tab: 'floor_div'
                    }
                    );
                    onClose();
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          );
        },
      });
    }
  };

  const assign_attribute = (e) => {
    if (Object.keys(selectedWS).length === 0) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui confirm_dialog">
              <div className="confirm_title">Validation</div>
              <div className="confirm_msg">
                <div className="img">
                  <img
                    alt=""
                    style={{ width: 50, height: 50 }}
                    src={warningIcon}
                  />
                </div>
                <div className="msg">
                  Please selects seats on the floor map to proceed.
                </div>
              </div>
              <div className="confirm_buttons">
                <button
                  className="confirm_yes"
                  onClick={() => {
                    onClose();
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          );
        },
      });
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui confirm_dialog">
              <div className="confirm_title">Confirmation</div>
              <div className="confirm_msg">
                <div className="img">
                  <img
                    alt=""
                    style={{ width: 50, height: 50 }}
                    src={confirmIcon}
                  />
                </div>
                <div className="msg">
                  Assigning {location.state.ws_attribute} {" "} to {" "} {selectedWS[loadFloor].length} Work Stations
                </div>
              </div>

              <div className="confirm_buttons">
                <button className="confirm_no" onClick={onClose}>
                  Back
                </button>
                <button
                  className="confirm_yes"
                  onClick={() => {
                    setLoading(true);

                    let sites_ref = firebase.firestore().collection("Sites");
                    // console.log(location.state.commonState.site);
                    sites_ref
                      .doc(location.state.commonState.site)
                      .get()
                      .then((doc) => {
                        if (!doc.exists) {
                          // console.log("No doc found");
                        } else {
                          let new_floor_state = {};
                          // console.log(doc.data());
                          new_floor_state = { ...doc.data() };
                          // console.log(new_floor_state);
                          // let i;
                          if (new_floor_state["floors"] && new_floor_state["floors"][loadFloor]) {

                            let tempLoadFloor = {...new_floor_state["floors"][loadFloor]}

                            Object.keys(tempLoadFloor).forEach ((ws_no) => {
                              if(tempLoadFloor[ws_no].attributes) {
                                if(selectedWS[loadFloor].indexOf(ws_no) > -1) {
                                  //remove if existing attribute with same name
                                  tempLoadFloor[ws_no].attributes = tempLoadFloor[ws_no].attributes.filter(e => e !== location.state.ws_attribute);
                                  //add fresh attribute
                                  tempLoadFloor[ws_no].attributes.push(location.state.ws_attribute)
                                }
                                else {
                                  //remove attribute from an array since it is unassigned in current selection
                                  tempLoadFloor[ws_no].attributes = tempLoadFloor[ws_no].attributes.filter(e => e !== location.state.ws_attribute);
                                }
                              }
                              else {
                                if(selectedWS[loadFloor].indexOf(ws_no) > -1) {
                                  tempLoadFloor[ws_no].attributes = [location.state.ws_attribute]
                                }
                              } 
                            })

                            // selectedWS[loadFloor].forEach ((ws_no) => {
                            //     if(tempLoadFloor[ws_no]){
                            //       if(tempLoadFloor[ws_no].attributes){
                            //         tempLoadFloor[ws_no].attributes.push(location.state.ws_attribute)
                            //       }
                            //       else {
                            //         tempLoadFloor[ws_no].attributes = [location.state.ws_attribute]
                            //       }                                  
                            //     }
                            // })

                            new_floor_state['floors'][loadFloor] = {...tempLoadFloor}

                            // for (i in selectedWS[loadFloor]) {

                            //   new_floor_state["floors"][loadFloor][
                            //     selectedWS[loadFloor][i]
                            //   ]["wing"] = location.state.wingName;
                            // }
                          }
                          // console.log("Edited one");
                          // console.log(new_floor_state);

                          sites_ref
                            .doc(location.state.commonState.site)
                            .update({
                              floors: { ...new_floor_state["floors"] },
                            })
                            .then(function (docRef) {
                              // console.log("floor divisions saved");
                              setLoading(false);
                            })
                            .catch(function (error) {
                              console.error(
                                "Error while saving WS attribute assignment"
                              );
                            });
                        }
                      });

                    // const add_wing = {
                    //   wing: location.state.wingName,
                    //   floor: {
                    //     [loadFloor]: [...selectedWS[loadFloor]],
                    //   },
                    //   no_of_seats: selectedWS[loadFloor].length,
                    //   last_updated_on: new Date(),
                    //   last_updated_by: location.state.commonState.userName,
                    // };
                    // console.log(add_wing);
                    // const floor_division_ref = firebase
                    //   .firestore()
                    //   .collection(
                    //     "FloorDivision/" +
                    //       location.state.commonState.site +
                    //       "/" +
                    //       loadFloor
                    //   );
                    // floor_division_ref
                    //   .doc(location.state.wingName)
                    //   .set({
                    //     ...add_wing,
                    //   })
                      // .then(function (docRef) {
                      //   // console.log("floor divisions saved");
                      //   setLoading(false);
                      // })
                      // .catch(function (error) {
                      //   console.error("Error while saving floor divisions");
                      // });
                    setLoading(false);
                    history.push("/admin", {
                      commonState: location.state.commonState,
                      tab: 'floor_div'
                    }
                    );
                    onClose();
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          );
        },
      });
    }
  };

  const assign_org_space = (e) => {
    if (Object.keys(selectedWS).length === 0) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui confirm_dialog">
              <div className="confirm_title">Validation</div>
              <div className="confirm_msg">
                <div className="img">
                  <img
                    alt=""
                    style={{ width: 50, height: 50 }}
                    src={warningIcon}
                  />
                </div>
                <div className="msg">
                  Please selects seats on the floor map to proceed.
                </div>
              </div>
              <div className="confirm_buttons">
                <button
                  className="confirm_yes"
                  onClick={() => {
                    onClose();
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          );
        },
      });
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui confirm_dialog">
              <div className="confirm_title">Confirmation</div>
              <div className="confirm_msg">
                <div className="img">
                  <img
                    alt=""
                    style={{ width: 50, height: 50 }}
                    src={confirmIcon}
                  />
                </div>
                <div className="msg">
                  Assigning {" "} to {" "} {selectedWS[loadFloor].length} Work Stations to {location.state.org} Organization
                </div>
              </div>

              <div className="confirm_buttons">
                <button className="confirm_no" onClick={onClose}>
                  Back
                </button>
                <button
                  className="confirm_yes"
                  onClick={() => {
                    setLoading(true);

                    const add_wing = {
                      org: location.state.org,
                      floor: {
                        [loadFloor]: [...selectedWS[loadFloor]],
                      },
                      no_of_seats: selectedWS[loadFloor].length,
                      last_updated_on: new Date(),
                      last_updated_by: location.state.commonState.userName,
                    };

                    const floor_division_ref = firebase
                      .firestore()
                      .collection(
                        "OrgDivision/" +
                        location.state.commonState.site +
                        "/" +
                        loadFloor
                      );


                    floor_division_ref
                      .doc(location.state.org)
                      .set({
                        ...add_wing,
                      })
                      .then(function (docRef) {
                        // console.log("floor divisions saved");
                        setLoading(false);
                      })
                      .catch(function (error) {
                        console.error("Error while saving org space");
                      });
                    setLoading(false);

                    setLoading(false);
                    history.push("/admin", {
                      commonState: location.state.commonState,
                      tab: 'floor_div'
                    }
                    );
                    onClose();
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          );
        },
      });
    }
  };


  const reserve_cat = () => {
    let total_seats = 0;
    floorOptions.forEach((e) => {
      if (selectedWS[e]) {
        total_seats = total_seats + selectedWS[e].length;
      }
    });
    
    // console.log(Object.keys(selectedWS).length)
    if (total_seats === 0) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui confirm_dialog">
              <div className="confirm_title">Validation</div>
              <div className="confirm_msg">
                <div className="img">
                  <img
                    alt=""
                    style={{ width: 50, height: 50 }}
                    src={warningIcon}
                  />
                </div>
                <div className="msg">
                  Please selects seats on the floor map to proceed.
                </div>
              </div>
              <div className="confirm_buttons">
                <button
                  className="confirm_yes"
                  onClick={() => {
                    onClose();
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          );
        },
      });
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui confirm_dialog">
              <div className="confirm_title">Confirmation</div>
              <div className="confirm_msg">
                <div className="img">
                  <img
                    alt=""
                    style={{ width: 50, height: 50 }}
                    src={confirmIcon}
                  />
                </div>
                <div className="msg">
                  Selected {total_seats} Work Stations for{" "}
                  {location.state.category_name}
                </div>
              </div>

              <div className="confirm_buttons">
                <button className="confirm_no" onClick={onClose}>
                  Back
                </button>
                <button
                  className="confirm_yes"
                  onClick={() => {
                    setLoading(true);
                    const new_category = {
                      active_days: location.state.active_days,
                      auto_booking_enabled: location.state.auto_book,
                      category: location.state.category_name,
                      floor: { ...selectedWS },
                      isActive: true,
                      last_updated_by: location.state.commonState.userName,
                      last_updated_on: new Date(),
                      no_of_seats: total_seats,
                      week_preference: location.state.week_preference
                    };
                    // console.log(new_category);
                    if (location.state.new_cat) {
                      categories_ref
                        .add({
                          ...new_category,
                        })
                        .then(function (docRef) {
                          // console.log(
                            // "Category reserved successfully ID: " + docRef.id
                          // );
                          setLoading(false);
                          history.push("/admin", {
                            commonState: location.state.commonState,
                            tab: 'category'
                          }
                          );
                        })
                        .catch(function (error) {
                          console.error(
                            "Error while reserving a Category: ",
                            error
                          );
                        });
                    } else {
                      // console.log(location.state.doc_id);
                      categories_ref
                        .doc(location.state.doc_id)
                        .set({
                          ...new_category,
                        })
                        .then(function (docRef) {
                          // console.log("Category reserved successfully ID: ");
                          setLoading(false);
                          history.push("/admin", {
                            commonState: location.state.commonState,
                            tab: 'category'
                          }
                          );
                        })
                        .catch(function (error) {
                          console.error(
                            "Error while reserving a Category: ",
                            error
                          );
                        });
                    }
                    onClose();
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          );
        },
      });
    }
  };

  const reserve_block_seats = () => {
    let total_seats = 0;
    floorOptions.forEach((e) => {
      if (selectedWS[e]) {
        total_seats = total_seats + selectedWS[e].length;
      }
    });

    if (total_seats === 0) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui confirm_dialog">
              <div className="confirm_title">Validation</div>
              <div className="confirm_msg">
                <div className="img">
                  <img
                    alt=""
                    style={{ width: 50, height: 50 }}
                    src={warningIcon}
                  />
                </div>
                <div className="msg">
                  Please selects seats on the floor map to proceed.
                </div>
              </div>
              <div className="confirm_buttons">
                <button
                  className="confirm_yes"
                  onClick={() => {
                    onClose();
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          );
        },
      });
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui confirm_dialog">
              <div className="confirm_title">Confirmation</div>
              <div className="confirm_msg">
                <div className="img">
                  <img
                    alt=""
                    style={{ width: 50, height: 50 }}
                    src={confirmIcon}
                  />
                </div>
                <div className="msg">
                  Selected {total_seats} Work Stations for{" "}
                  {location.state.selected_block}
                </div>
              </div>

              <div className="confirm_buttons">
                <button className="confirm_no" onClick={onClose}>
                  Back
                </button>
                <button
                  className="confirm_yes"
                  onClick={() => {
                    setLoading(true);
                    const new_blocked_seat = {
                      active_days: location.state.active_days,
                      BlockName: location.state.selected_block,
                      floor: { ...selectedWS },
                      isActive: true,
                      last_updated_by: location.state.commonState.userName,
                      last_updated_on: new Date(),
                      group : location.state.group,
                      no_of_seats: total_seats,
                    };
                    // console.log(new_blocked_seat);
                    if (location.state.new_cat) {
                      blocked_seats_ref
                        .add({
                          ...new_blocked_seat,
                        })
                        .then(function (docRef) {
                          // console.log(
                          //   "Blocked seats reserved successfully ID: " +
                          //     docRef.id
                          // );
                          setLoading(false);
                          history.push("/admin", {
                            commonState: location.state.commonState,
                            tab: 'blockedseats'
                          }
                          );
                        })
                        .catch(function (error) {
                          console.error(
                            "Error while reserving a Blocked seats: ",
                            error
                          );
                        });
                    } else {
                      // console.log(location.state.doc_id);
                      blocked_seats_ref
                        .doc(location.state.doc_id)
                        .set({
                          ...new_blocked_seat,
                        })
                        .then(function (docRef) {
                          // console.log("Blocked reserved successfully ID: ");
                          setLoading(false);
                          history.push("/admin", {
                            commonState: location.state.commonState,
                            tab: 'blockedseats'
                          }
                          );
                        })
                        .catch(function (error) {
                          console.error(
                            "Blocked while reserving a Category: ",
                            error
                          );
                        });
                    }
                    onClose();
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          );
        },
      });
    }
  };

  const reserve_block_seats_date = () => {
    let total_seats = 0;
    floorOptions.forEach((e) => {
      if (selectedWS[e]) {
        total_seats = total_seats + selectedWS[e].length;
      }
    });

    if (total_seats === 0) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui confirm_dialog">
              <div className="confirm_title">Validation</div>
              <div className="confirm_msg">
                <div className="img">
                  <img
                    alt=""
                    style={{ width: 50, height: 50 }}
                    src={warningIcon}
                  />
                </div>
                <div className="msg">
                  Please selects seats on the floor map to proceed.
                </div>
              </div>
              <div className="confirm_buttons">
                <button
                  className="confirm_yes"
                  onClick={() => {
                    onClose();
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          );
        },
      });
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui confirm_dialog">
              <div className="confirm_title">Confirmation</div>
              <div className="confirm_msg">
                <div className="img">
                  <img
                    alt=""
                    style={{ width: 50, height: 50 }}
                    src={confirmIcon}
                  />
                </div>
                <div className="msg">
                  Selected {total_seats} Work Stations for{" "}
                  {location.state.selected_block}
                </div>
              </div>

              <div className="confirm_buttons">
                <button className="confirm_no" onClick={onClose}>
                  Back
                </button>
                <button
                  className="confirm_yes"
                  onClick={() => {
                    setLoading(true);
                    const new_blocked_seat = {
                      active_days: [],
                      active_date_from: format_dt_to_ts(location.state.active_date_from),
                      active_date_to: format_dt_to_ts(location.state.active_date_to),                      
                      BlockName: location.state.selected_block,
                      floor: { ...selectedWS },
                      isActive: true,
                      last_updated_by: location.state.commonState.userName,
                      last_updated_on: new Date(),
                      group : location.state.group,
                      no_of_seats: total_seats,
                    };
                      blocked_seats_dates_ref
                        .add({
                          ...new_blocked_seat,
                        })
                        .then(function (docRef) {
                          setLoading(false);
                          history.push("/admin", {
                            commonState: location.state.commonState,
                            tab: 'blockedseats'
                          }
                          );
                        })
                        .catch(function (error) {
                          console.error(
                            "Error while reserving a Blocked seats: ",
                            error
                          );
                        });
                    
                    onClose();
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          );
        },
      });
    }
  };

  const confirmAutoBooking = () => {
    seat_booking_ref.add(location.state.booking_obj).then(function () {

      buildEmail( location.state.action , location.state.booking_obj)

      setLoading(false);
      // console.log("confirmedddddddddddd auto booking");
      setLoading(false);
      history.push("/");
    });
  };

  const confirmBooking = () => {
    if (floorState[loadFloor][selectedSeat].status === "selected") {
      setLoading(true);

      let block_booking_ref_doc = firebase
        .firestore()
        .collection(
          "BlockBookings/" + location.state.commonState.site + "/BlockBookings"
        )
        .doc(location.state.booking_obj.block_id);


      const confirmedBooking = {
        block_id: location.state.booking_obj.block_id,
        booking_type: location.state.commonState.direct_booking_flag,
        booked_by: location.state.commonState.userName,
        booked_by_email: location.state.commonState.userEmail,
        blocked_by: location.state.booking_obj.blocked_by,
        blocked_by_email: location.state.booking_obj.blocked_by_email,
        blocked_date: new Date(location.state.booking_obj.blocked_date),
        booked_on: new Date(location.state.commonState.currentDate),
        booked_ws: selectedSeat,
        booking_status: "Confirmed",
        site: location.state.commonState.site,
        floor: loadFloor,
        wing: selectedWing,
        category: location.state.commonState.category,
      };
      
// CHANGE : Extending the booking key and additional check to Normal Booking Mode as well

      //   const seat_booking_ref = firebase.firestore().collection('SeatBookings');
      // seat_booking_ref.add(confirmedBooking).then(function (docRef) {
      //   block_booking_ref_doc.update({
      //     seats_confirmed: firebase.firestore.FieldValue.increment(1),
      //   });
      //   buildEmail( "Confirm Booking" , confirmedBooking)
      //   // console.log("confirmedddddddddddd");
      //   setLoading(false);
      //   history.push("/");
      // });

      const curDate = location.state.booking_obj.blocked_date;

      const booking_key = `${curDate.getFullYear()}${curDate.getMonth()}${curDate.getDate()}${loadFloor}${selectedSeat}`;

      seat_booking_ref.doc(booking_key)
      .get()
      .then(function (docRef) {
        if (docRef.exists) {
          alert("The Workstation you selected has been booked by someone else. Please select another Workstation");
        }
        else {
          seat_booking_ref
            .where("blocked_date", "==", curDate)
            .where("booked_by_email", "==", location.state.commonState.userEmail)
            .get(1)
            .then(function (querySnapshot) {
              if (querySnapshot.docs.length > 0) {
                querySnapshot.forEach((doc) => {
                  alert(`Error! You have already booked ${doc.data().booked_ws} for this date! Redirecting back to Home Page`);
                  setLoading(false);
                  history.push("/");
                })                  
              }
              else {
                seat_booking_ref.doc(booking_key).set({
                  ...confirmedBooking,
                })
                  .then(function (doc) {
                    if (selectedSeat.includes('WS')){
                      block_booking_ref_doc.update({
                        seats_confirmed: firebase.firestore.FieldValue.increment(1),
                      });  
                   }                  
                    buildEmail( "Confirm Booking" , confirmedBooking)
                    setLoading(false);
                    history.push("/");
                  });
              }
            });

        }
        setLoading(false);
      })



    } else {
      alert(
        "Your selected seat is booked by someone. Please select another seat."
      );
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui confirm_dialog">
              <div className="confirm_title">Validation</div>
              <div className="confirm_msg">
                <div className="img">
                  <img
                    alt=""
                    style={{ width: 50, height: 50 }}
                    src={warningIcon}
                  />
                </div>
                <div className="msg">
                  Your selected seat is booked by someone. Please select another
                  seat.
                </div>
              </div>
              <div className="confirm_buttons">
                <button
                  className="confirm_yes"
                  onClick={() => {
                    onClose();
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          );
        },
      });
      setLoading(false);
    }
  };

  const confirmOtherBookings = () => {
    if (floorState[loadFloor][selectedSeat].status === "selected") {
      setLoading(true);

      if (
        location.state.action === "UpdateMyWS-SiteLevel" ||
        location.state.action === "UpdateMyWS-CategoryLevel"
      ) {
        const user_ref = firebase
          .firestore()
          .collection("Users")
          .doc(location.state.commonState.userEmail);

        user_ref
          .update({
            my_ws: {
              ws: selectedSeat,
              floor: loadFloor,
              wing: selectedWing,
            },
          })
          .then(function (docRef) {
            // console.log("WorkStation Updated-> ", location.state.action);

            setLoading(false);
            history.push("/");
          });
      } else {

        // For special booking, the date is the current date
        // const curDate = new Date(location.state.selectedDate);
        // const curDate =
        // location.state.action === "special_booking"
        //   ? new Date(location.state.commonState.currentDate)
        //   : location.state.selectedDate;
        const curDate = location.state.selectedDate;

        const booking_key = `${curDate.getFullYear()}${curDate.getMonth()}${curDate.getDate()}${loadFloor}${selectedSeat}`;
        // console.log(booking_key);

        // const blk_date =
        //   location.state.action === "special_booking"
        //     ? new Date(location.state.commonState.currentDate)
        //     : location.state.selectedDate;
        const blk_date = location.state.selectedDate;
        let book_type =
          location.state.action === "special_booking"
            ? "special_booking"
            : location.state.commonState.direct_booking_flag;
        const block_id_ch =
            location.state.action === "special_booking_date"
              ? "special_booking"
              : location.state.action;
        
        if (location.state.action === "special_booking_date") {
          book_type = "special_booking";
        }

        const 
        confirmedBooking = {
          // block_id: location.state.action,
          block_id: block_id_ch,
          booking_type: book_type,
          booked_by: location.state.commonState.userName,
          booked_by_email: location.state.commonState.userEmail,
          blocked_by: "",
          blocked_by_email: "",
          blocked_date: blk_date,
          booked_on: new Date(location.state.commonState.currentDate),
          booked_ws: selectedSeat,
          booking_status: "Confirmed",
          site: location.state.commonState.site,
          floor: loadFloor,
          wing: selectedWing,
          category: location.state.commonState.category,
        };


        // seat_booking_ref.add(confirmedBooking).then(function (docRef) {
        
        seat_booking_ref.doc(booking_key)
          .get()
          .then(function (docRef) {
            if (docRef.exists) {
              alert("The Workstation you selected has been booked by someone else. Please select another Workstation");
            }
            else {
              seat_booking_ref
                .where("blocked_date", "==", selectedDate)
                .where("booked_by_email", "==", location.state.commonState.userEmail)
                .get(1)
                .then(function (querySnapshot) {
                  if (querySnapshot.docs.length > 0) {
                    querySnapshot.forEach((doc) => {
                      alert(`Error! You have already booked ${doc.data().booked_ws} for this date! Redirecting back to Home Page`);
                      setLoading(false);
                      history.push("/");
                    })                  
                  }
                  else {
                    seat_booking_ref.doc(booking_key).set({
                      ...confirmedBooking,
                    })
                      .then(function (doc) {
                        buildEmail(book_type, confirmedBooking)
                        setLoading(false);
                        history.push("/");
                      });
                  }
                });

            }
            setLoading(false);
          })

      
      }
    } else {
      alert(
        "Your selected seat is booked by someone. Please select another seat."
      );
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui confirm_dialog">
              <div className="confirm_title">Validation</div>
              <div className="confirm_msg">
                <div className="img">
                  <img
                    alt=""
                    style={{ width: 50, height: 50 }}
                    src={warningIcon}
                  />
                </div>
                <div className="msg">
                  Your selected seat is booked by someone. Please select another
                  seat.
                </div>
              </div>
              <div className="confirm_buttons">
                <button
                  className="confirm_yes"
                  onClick={() => {
                    onClose();
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          );
        },
      });
      setLoading(false);
    }
  };

  const confirm_confirmBooking = () => {
    if (
      selectedSeat === "" ||
      floorState[loadFloor][selectedSeat].status !== "selected"
    ) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui confirm_dialog">
              <div className="confirm_title">Validation</div>
              <div className="confirm_msg">
                <div className="img">
                  <img
                    alt=""
                    style={{ width: 50, height: 50 }}
                    src={warningIcon}
                  />
                </div>
                <div className="msg">
                  {" "}
                  Please select seat on the floor map to proceed.
                </div>
              </div>
              <div className="confirm_buttons">
                <button
                  className="confirm_yes"
                  onClick={() => {
                    onClose();
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          );
        },
      });
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          let seat_text  = "";
          if (selectedSeat.includes('WS')){
            seat_text = 'Work Station'
          } else if (selectedSeat.includes('CU')) {
            seat_text = 'Cubicle'
          } else if (selectedSeat.includes('DIR')) {
            seat_text = 'Cabin';
          }

          const ConfirmBookingButton = "Confirm";
          return (
            <div className="custom-ui confirm_dialog">
              <div className="confirm_title">Confirmation</div>
              <div className="confirm_msg">
                     
                  <div className="img">
                    <img
                      alt=""
                      style={{ width: 50, height: 50 }}
                      src={confirmIcon}
                    />
                  </div>
                  <div className="msg_content">  
                    {/* <div className="msg">Selected Work Station- {selectedSeat}</div> */}
                    <div className="msg">Selected {seat_text} - {selectedSeat}</div>
                    {/* <div className="msg_parking">  
                    <label>                   
                        <input
                          className = "parking_chkbox"
                          name="doesParkingNeeded"
                          type="checkbox"
                          checked={parkingNeeded}
                          onChange={(e)=> handleParkingNeeded(e)} />                     
                           <span className="parking_text">I also need to reserve parking space.</span>
                      </label>
                    </div> */}
                </div>

              </div>

              <div className="confirm_buttons">
                <button className="confirm_no" onClick={onClose}>
                  Back
                </button>
                <button
                  className="confirm_yes"
                  onClick={() => {
                    setLoading(true);

                    location.state.action === "seat_booking"
                      ? confirmBooking()
                      : confirmOtherBookings();
                    onClose();
                  }}
                >
                  {ConfirmBookingButton}
                </button>
              </div>
            </div>
          );
        },
      });
    }
  };


  // const handleParkingNeeded = (e) => {
  //   console.log('parkingNeeded=> ' , parkingNeeded)
  //  console.log('e.target.checked=> ' , e.target.checked)
  //  if(e.target.checked) {

  //    setParkingNeeded(e.target.checked)
    
  //  }
  // }

  const showConfirmButton = () => {
    // if (selectedSeat) {
    if (location.state.action === "block_booking") {
      return (
        <button
          onClick={() => {
            reserveBlock();
          }}
          className="button-confirm-booking"
        >
          BOOK BLOCK
        </button>
      );
    } else if (location.state.action === "floor_division_wings") {
      return (
        <button
          onClick={(e) => {
            allot_wing(e);
          }}
          className="button-confirm-booking"
        >
          ALLOT WING
        </button>
      );
    } else if (location.state.action === "assign_ws_attribs") {
      return (
        <button
          onClick={(e) => {
            assign_attribute(e);
          }}
          className="button-confirm-booking"
        >
          Assign WS Attribute
        </button>
      );    
    } else if (location.state.action === "assign_org_space") {
      return (
        <button
          onClick={(e) => {
            assign_org_space(e);
          }}
          className="button-confirm-booking"
        >
          Assign Org Space
        </button>
      );        
    } else if (location.state.action === "reserve_category") {
      return (
        <button
          onClick={() => {
            reserve_cat();
          }}
          className="button-confirm-booking"
        >
          Reserve Category
        </button>
      );
    } else if (location.state.action === "reserve_blocked_seat") {
      return (
        <button
          onClick={() => {
            reserve_block_seats();
          }}
          className="button-confirm-booking"
        >
          Reserve Block
        </button>
      );
    } else if (location.state.action === "reserve_blocked_seat_date") {
      return (
        <button
          onClick={() => {
            reserve_block_seats_date();
          }}
          className="button-confirm-booking"
        >
          Reserve Block
        </button>
      );  
    } else if (location.state.action === "live_status") {
    } else {
      return (
        <button
          onClick={() => {
            confirm_confirmBooking();
          }}
          className="button-confirm-booking"
        >
          BOOK SEAT
        </button>
      );
    }
  };

  const changeFloor = (value) => {
    // console.log(value.value);
    setLoadFloor(value.value);

    if (
      location.state.action !== "reserve_category" &&
      location.state.action !== "reserve_blocked_seat"
      &&  location.state.action !== "reserve_blocked_seat_date"
    ) {
      // console.log("Clear values");
      setSelectedWS({});
      setSelectedSeat("");

      let temp_floor_state = { ...floorState };

      Object.keys(temp_floor_state).forEach((flr) => {
        Object.keys(temp_floor_state[flr]).forEach((ws) => {
          if (temp_floor_state[flr][ws].status === "selected") {
            temp_floor_state[flr][ws].status = "available";
          }
        });
      });

      setFloorState(temp_floor_state);
    }

    // if(!preserveSelection){
    //     let current_floor = {...floorState}

    //     Object.keys(selectedWS).forEach((flr)=> {
    //         selectedWS[flr].forEach((ws)=>{
    //             current_floor[flr][ws].status='available'
    //         })
    //     })

    //     setFloorState({...current_floor})
    // }
  };

  const getMonth = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec",
  };

  const get_day = {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
  };

  // const getWeekDay = () => {
  //     const blocked_date = selectedDate.toDate()
  //     return get_day[blocked_date.getDay()]
  // }

  const printDate = () => {
    let blocked_date;
    if (location.state.action === "block_booking") {
      blocked_date = new Date(selectedDate.toDate());
    } else if (location.state.action === "seat_booking") {
      blocked_date = new Date(location.state.booking_obj.blocked_date);
    } else if (location.state.action === "special_booking") {
      // blocked_date = new Date(location.state.commonState.currentDate);
      blocked_date = new Date(selectedDate.toDate());
    } else if (location.state.action === "Site Level-Auto" || location.state.action === "Category Level-Auto") {
      blocked_date = new Date(location.state.booking_obj.blocked_date);
    } else if(location.state.action === "live_status") {
      blocked_date = new Date();
    }
    else if(
      location.state.action === "floor_division_wings" ||
      location.state.action === "reserve_category" ||
      location.state.action === "reserve_blocked_seat" ||
      location.state.action === "reserve_blocked_seat_date" ||
      location.state.action === "assign_ws_attribs"  ||
      location.state.action === "assign_org_space"
    ){
      blocked_date = ''
    }
    else {
      blocked_date = new Date(location.state.selectedDate);
    }

    let blocked_date_date;
    if (blocked_date) {
      if (blocked_date.getDate() < 10) {
        blocked_date_date = "0" + blocked_date.getDate().toString();
      } else {
        blocked_date_date = blocked_date.getDate().toString();
      }

      const blocked_date_month = getMonth[blocked_date.getMonth()];

      return (
        get_day[blocked_date.getDay()] +
        ", " +
        blocked_date_date +
        "-" +
        blocked_date_month
      );
    }
  };

  const backToHome = () => {
    // console.log(location.state.commonState)
    let backToTab;

    backToTab = location.state.action === "floor_division_wings" || location.state.action === 'assign_ws_attribs' || location.state.action === 'assign_org_space' ? 'floor_div' :
                location.state.action === "reserve_category"     ? 'category'  :
                location.state.action === "reserve_blocked_seat" ||  location.state.action === "reserve_blocked_seat_date" ? 'blockedseats' : 'Home'

    if (backToTab === 'Home') {
      history.push("/");
    }
    else {
      history.push("/admin", {
        commonState: location.state.commonState,
        // commonState: original_CommonState,
        tab: backToTab
      })
    }
  };

  const printFloor = () => {
    if (floorOptions) {
      if (
        location.state &&
        location.state.action !== "Reserved Cat-Auto" &&
        location.state.action !== "Reserved Cat-Auto"
      ) {
        return (
          <div className="floor">
            <Loader loading={loading}></Loader>

            <div className="floor-header">
              <div className="booking-type">
                <div className="back_arrow" onClick={() => backToHome()}>
                  <img
                    style={{ height: 20, width: 20 }}
                    src={backIcon}
                    alt=""
                  />
                </div>
                <div className="booking_title">{bookingType}</div>
              </div>
              <div className="select-floor">
                <Dropdown
                  options={floorOptions}
                  onChange={(value) => changeFloor(value)}
                  value={loadFloor}
                  className="dropdownRoot"
                  placeholderClassName="setColor"
                  arrowClassName="Dropdown-arrow"
                />
              </div>
              <div className="booking-date">{printDate()}</div>
            </div>

            <div className="floor-seat-attributes">
                  {loadSeatAttributes()}
                  {select_all_button()}
            </div>

            <div className="floor-plan">{loadFloorPlan()}</div>
            <div className="confirm-seat">
              <div className="selected-seats">{selectedSeat}</div>
              <div className="button-confirm-seat">{showConfirmButton()}</div>
              <div className="total-seats">Total Seats: {totalSeats}</div>
            </div>
          </div>
        );
      } else {
        return <div className="floor-plan">Please submit declaration!</div>;
      }
    }
  };

  return <div>{printFloor()}</div>;
}
