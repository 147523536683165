import React from 'react';
import Header from './../AppHeader/header'

import './error-page.css'

import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

export default function ErrorPage (props) {

    const location = useLocation();
    // let error_details = {code: '', message: ''}
    const error_details= location.state;
    const history = useHistory();

    const loadError = () => {
        if(location.state)
            return (
            <div>
                <div>
                    <Header></Header>
                </div>
                <div className='error_container'>
                    <div className='error_title'>
                                    <div className='error_text'>
                                    </div>
                                    <div 
                                        className='error_text error_border'
                                        >ERROR!
                                    </div>
                                    <div className='error_text'>
                                    </div>
                    </div>
                    <div className='error_content'>
                        <br/>
                        <div className='error_code'>
                            <div className='error_msg_title'> Error Code: </div>{error_details.code}
                        </div>
                        <br/>
                        <div className=''>
                            <div className='error_msg_title'>Error Message:  </div>{error_details.message}
                        </div>
                        <br/><br/>
                        <div className=''>
                            <div className='error_msg_try_again'
                            onClick={()=>{
                                history.push('/')
                            }}>Try Again </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


return(
    <div>
        {loadError()}
    </div>

)
}