import React, {useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { firebase } from "../../config/firebase";

import MuiDialogContent from '@material-ui/core/DialogContent';
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
// import { makeStyles } from '@material-ui/core/styles';

import './reserve-parking.css'



const DialogContent = withStyles((theme) => ({
    root: {
      // padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  // const useStyles = makeStyles((theme) => ({
  //   root: {
  //     '& > *': {
  //       margin: theme.spacing(1),
  //     },
  //   },
  // }));

export default function ReserveParking(props) {

  const[parkingNumbers, setParkingNumbers] = useState([])

  const[parkingReserved, setParkingReserved] = useState([])

  const [selectedParking, setSelectedParking] = React.useState('female');

// const booking_date = new Date(props.booking.blocked_date)

const getMonth = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec",
  };

  const get_day = {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
  };

  const getWeekDay = () => {
    const blocked_date = props.booking.blocked_date;
    return get_day[blocked_date.getDay()];
  };

  const printDate = () => {
    const blocked_date = props.booking.blocked_date;
    let blocked_date_date;

    if (blocked_date.getDate() < 10) {
      blocked_date_date = "0" + blocked_date.getDate().toString();
    } else {
      blocked_date_date = blocked_date.getDate().toString();
    }

    const blocked_date_month = getMonth[blocked_date.getMonth()];

    const weekdy = getWeekDay()

    return weekdy + ", " + blocked_date_date + "-" + blocked_date_month;
  };

  const seat_booking_ref = firebase
  .firestore()
  .collection(
    "SeatBookings/" + props.commonState.site + "/SeatBookings"
  );

  React.useEffect(() => {
    seat_booking_ref
    .where("blocked_date", "==", props.booking.blocked_date)
    .where("parking", "!=", "")
    .onSnapshot((querySnapshot) => {
       let parkings = []
      querySnapshot.forEach((doc) => {
        const parking_num = doc.data().parking;
        parkings.push(parking_num)
    //    console.log("seat_booking", seat_booking)
      });
      setParkingNumbers([...props.commonState.site_data.parking].sort())
      setParkingReserved([...parkings])
  })


// }, [props.booking.blocked_date,props.commonState.site_data.parking,seat_booking_ref,parkingReserved]);
}, [props.booking.blocked_date,props.commonState.site_data.parking]);


const handleClose = () => {
    // setOpen(false);
    props.closeParking()
  };


  const handleChange = (event) => {
    setSelectedParking(event.target.value);
  };


  const printParkingNumbers = () => {
     
    return parkingNumbers.map((parking, index) => {
        const isAvailable = parkingReserved.find(park => park === parking)
       if(isAvailable) {
        return <FormControlLabel value={parking} disabled checked control={<Radio />} label={parking} key={index} />           
       } 
       else {
        return <FormControlLabel value={parking} control={<Radio />} label={parking} key={index} />
       }
       
    })
  }

  const handleSubmit = () => {
    // console.log("props.booking.block_id", props.booking.block_id)

    seat_booking_ref
      .where("block_id", "==", props.booking.block_id)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (document) {
          document.ref.update({
            parking: selectedParking
          });
        });
        props.closeParking()
      });
    // props.closeParking()
  }

return (
    <div className='qr-code_container'>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <Dialog onClose={handleClose}  open={true}>
       
        <DialogContent className='qr-code_container'>
            <div className="qr-code_main">   
            <div className="close_icon" onClick={()=>handleClose()}><CancelTwoToneIcon/></div>   
            <div className="qr-code-img-container">
                <div className="qr-code-msg">
                  <div className="parking-title">Select Parking Number</div>
                  <div className="date-txt">{printDate()}</div>                  
                </div>
                <div className="parking_wrapper">
                    <RadioGroup aria-label="gender" name="gender1" value={selectedParking} onChange={handleChange}>

                        {printParkingNumbers()}
                        {/* <FormControlLabel value="female" control={<Radio />} label="Female" />
                        <FormControlLabel value="male" control={<Radio />} label="Male" />
                        <FormControlLabel value="other" control={<Radio />} label="Other" />
                        <FormControlLabel value="disabled" disabled control={<Radio />} label="(Disabled option)" /> */}
                    </RadioGroup>
                </div>
                <div className="parkin_submit_button">
                    <Button 
                        variant="contained"
                        color="secondary"
                        onClick = {() => handleSubmit()}
                        >
                        SUBMIT
                    </Button>
                </div>
    
            </div>                
            </div>
        </DialogContent>
        
      </Dialog>
    </div>
  );
}