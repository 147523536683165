import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TablePagination from '@material-ui/core/TablePagination';
// import TableRow from '@material-ui/core/TableRow';
// import warningIcon from './../../../Assets/Images/warning.png';
import { confirmAlert } from 'react-confirm-alert';
// import AddNewQues from '../Declaration/add-new-question';
// import EditQues from '../Declaration/edit-question';
// import TableSortLabel from '@material-ui/core/TableSortLabel';
// import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/DeleteTwoTone';
// import EditIcon from '@material-ui/icons/EditTwoTone';
// import AddIcon from '@material-ui/icons/Add';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemButton from '@mui/material/ListItemButton';
import { firebase } from '../../../config/firebase';
// import Modal from "@material-ui/core/Modal";
// import TextField from '@material-ui/core/TextField';
import Loader from '../../Loader/loader';
// import OptionsTable from './options_table';
import List from "@material-ui/core/List";
import './admin-declaration.css';
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";


// function createData(no, name) {
//     return { no, name };
// }





const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        //  marginLeft: '10px',

    },
    root3: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        padding: '0px',
    },
    paper: {
        width: '100%',
        // paddingTop: '10px',  
        marginLeft: '20px',
        // marginBottom: theme.spacing(2),

    },
    paper2: {
        width: '100%',
        paddingTop: '10px',
        marginLeft: '2px',
        // marginBottom: theme.spacing(2),

    },
    table: {
        minWidth: 500,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
        float: 'left',
    },
    inputSearch: {
        width: '80%',
        marginLeft: '20px',
    },
    paper1: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,

        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    root1: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    rowC:
    {
        display: 'flex',
        flexDirection: 'row'
    }

}));

// function rand() {
//     return Math.round(Math.random() * 20) - 10;
// }



export default function DeclarationTable(props) {
    const classes = useStyles();
    // const [order, setOrder] = React.useState('asc');
    // const [orderBy, setOrderBy] = React.useState('name');
    // const order = 'asc';
    // const orderBy = 'name';
    // const [availableSites, setAvailableSites] = useState([])
    // const [availableFloors, setAvailableFloors] = useState([])
    // const [availableFloorsjson, setAvailableFloorsjson] = useState({});
    // const [availableSiteFloors, setAvailableSiteFloors] = useState([]);
    // const [editOptions] = useState([]);
    // const [selected, setSelected] = React.useState([]);
    // const [page, setPage] = React.useState(0);
    // const [dense, setDense] = React.useState(true);
    // const [selectedQuesIndex, setSelectedQuesIndex] = React.useState(-1);
    // const [selectedQuesName, setSelectedQuesName] = React.useState('');
    // const [selectedQuesID, setSelectedQuesID] = React.useState('');
    // const [selectedSafeAns] = React.useState('');
    // const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // const [ques_id] = useState({});
    // const [newQues, setnewQues] = React.useState(false);
    // const [editQues, seteditQues] = React.useState(false);
    const [reload_counter, setreloadcounter] = useState(0);
    const [loading, setLoading] = useState(true);
    const selected_site = props.site_name;
    const [qmode] = React.useState(props.qmode);
    const [cmode, setCmode] = React.useState(false);
    const [pmode, setPmode] = React.useState([false, false, false]);
    const [questions, setQuestions] = useState([]);
    // const [pquestions, setpQuestions] = useState([]);
    // const [open] = React.useState(false);
    // const [openh, setOpenh] = React.useState(false);

    const pdeclaration_ref = firebase
        .firestore()
        .collection(
            "ProfileQuestions/CGBS-Mumbai/Questions"
        );
    const declaration_ref = firebase
        .firestore()
        .collection(
            "DeclarationQuestions/CGBS-Mumbai/Questions"
        );

    useEffect(() => {
        setLoading(true);
        if (qmode) {
            declaration_ref
                .orderBy("Que_No", "asc")
                .get()
                .then(
                    (querySnapshot) => {
                        const Questions = [];
                        if (querySnapshot.docs.length !== 0) {
                            querySnapshot.forEach((doc) => {
                                const question = doc.data();
                                question.id = doc.id;
                                Questions.push(question);
                            });
                            setQuestions(Questions);
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                )
                .catch(function (error) {
                    console.log("Error getting Declaration questions: ", error);
                });
        } else {
            pdeclaration_ref
                .orderBy("Que_No", "asc")
                .get()
                .then(
                    (querySnapshot) => {
                        const Questions = [];
                        if (querySnapshot.docs.length !== 0) {
                            querySnapshot.forEach((doc) => {
                                const question = doc.data();
                                question.id = doc.id;
                                Questions.push(question);
                            });
                            setQuestions(Questions);
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                )
                .catch(function (error) {
                    console.log("Error getting Declaration questions: ", error);
                });
        }
        setLoading(false);
    }, [reload_counter, qmode, cmode]);


    // const NewQuestion = () => {
    //     handleNewOpen();
    // }

    // const handleNewOpen = () => {
    //     // console.log('open');
    //     // setnewQues(true);
    // };

    // const handleNewClose = () => {
    //     // setnewQues(false);
    //     setreloadcounter(reload_counter + 1);
    // }

    // const handleEditClose = () => {
    //     // console.log('Edit window closed')
    //     // setSelected([])
    //     // seteditQues(false);
    //     setreloadcounter(reload_counter + 1);
    //     // setSelectedQuesIndex(-1)
    //     setSelectedQuesName('')
    //     // setAvailableSiteFloors([])
    // }

    // const handleChangePage = (event, newPage) => {
    //     // setPage(newPage);
    // };

    const deleteques = (e, item, index, id) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui confirm_dialog'>
                        <div className="confirm_title">Confirmation</div>
                        <div className="confirm_msg">Are you sure you want to delete the question - {item}</div>
                        <div className="confirm_buttons">
                            <button className="confirm_no" onClick={
                                () => {
                                    onClose()
                                }
                            }>No</button>

                            <button className="confirm_yes"
                                onClick={
                                    () => {
                                        const pdeclaration_ref = firebase
                                            .firestore()
                                            .collection(
                                                "ProfileQuestions/" + selected_site + "/Questions"
                                            );

                                        const declaration_ref = firebase
                                            .firestore()
                                            .collection(
                                                "DeclarationQuestions/" + selected_site + "/Questions"
                                            );
                                        if (qmode) {
                                            declaration_ref
                                                .doc(id)
                                                .delete()
                                                .then(function () {
                                                    // console.log("Question successfully deleted!");
                                                    setCmode(!cmode);
                                                    onClose();
                                                }).catch(function (error) {
                                                    console.error("Error removing document: ", error);
                                                });
                                        } else {
                                            pdeclaration_ref
                                                .doc(id)
                                                .delete()
                                                .then(function () {
                                                    // console.log("Question successfully deleted!");
                                                    setCmode(!cmode);
                                                    onClose();
                                                }).catch(function (error) {
                                                    console.error("Error removing document: ", error);
                                                });
                                        }
                                        setCmode(!cmode)
                                        setreloadcounter(reload_counter + 1);
                                        onClose()
                                    }
                                }
                            >Yes</button>
                        </div>
                    </div>
                );

            }
        })
    }



    // const AddQuesModal = (
    //     <AddNewQues handleNewClose={handleNewClose} userName={props.commonState.userName} site={props.site_name} />
    // );

    // const EditQuesModal = (
    //     <EditQues
    //         handleEditClose={handleEditClose}
    //         userName={props.commonState.userName}
    //         site={props.site_name}
    //         question={selectedQuesName}
    //         options={editOptions}
    //         ques_no={selectedQuesIndex + 1}
    //         safe_ans={selectedSafeAns}
    //         doc_id={ques_id}
    //     />
    // );

    const handleClick = (index) => {
        // console.log(pmode)
        // var temp_state = open
        var temp_arr = [...pmode]
        temp_arr[index] = !temp_arr[index]
        setPmode([...temp_arr])
        // console.log(pmode)
        // console.log(event.target.value)
        // console.log(event)
        // console.log(index)
        // setOpen(!open);
        // if (open === false) {
        //     setOpenh(false)
        // }
        // setOpenh(temp_state)
    };

    return (
        <div className={classes.root}>
            <Loader loading={loading}></Loader>
            <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', fontFamily: 'ColgateReady' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                {/* {qmode === true ? display_decl() : display_pdecl() } */}
                {questions.map((item, index) => {
                    return (
                        <div>
                            <ListItemButton onClick={() => {
                                handleClick(index)
                            }} style={{
                                backgroundColor: '#fff',
                                marginTop: '2px', marginBottom: '2px', borderWidth: '1px', borderColor: 'gray', width: '100%'
                            }}>
                                <ListItemIcon>
                                    {item.Que_No}
                                </ListItemIcon>
                                <ListItemText primary={item.Question} />
                                <div onClick={(e) => { deleteques(e, item.Question, index, item.id) }} style={{ marginRight: '2%', color: '#d01837' }}>
                                    <Tooltip title="Delete Question"  >
                                        <div>
                                            <IconButton aria-label="filter list" style={{ marginRight: '2%', color: '#d01837' }} >
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                </div>

                                {pmode[index] ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Paper component="form" sx={{ p: '2px 4px', margin: '1px', backgroundColor: 'whitesmoke' }}>
                                <Collapse in={pmode[index]} timeout="auto" unmountOnExit style={{ padding: '2%', backgroundColor: 'whitesmoke' }}>
                                    <div className="options">
                                        {item.Options.map((option, i) => {
                                            return (
                                                <span className="radio_span" key={index + i}>
                                                    <input
                                                        className="radio_options"
                                                        type="radio"
                                                        name={item}
                                                        value={option}
                                                        defaultChecked={item.SafeAns === option}
                                                    // style={{ backgroundColor: item.SafeAns === option ? 'red': 'white' }}
                                                    // onChange={(e) => props.OptionSelect(e, props.question.Que_No)}
                                                    />
                                                    {option}
                                                </span>
                                            );
                                        })}
                                    </div>

                                </Collapse>
                            </Paper>
                        </div>
                    )
                })}
            </List>
        </div>
    );
}
