import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import MuiDialogContent from '@material-ui/core/DialogContent';
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';

import QRCode from "react-qr-code";

import './generate-qr-code.css'



const DialogContent = withStyles((theme) => ({
    root: {
      // padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

export default function GenerateQRCode(props) {
  const handleClose = () => {
    // setOpen(false);
    props.closeQRCode()
  };

return (
    <div className='qr-code_container'>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <Dialog onClose={handleClose}  open={true}>
       
        <DialogContent className='qr-code_container'>
            <div className="qr-code_main">   
            <div className="close_icon" onClick={()=>handleClose()}><CancelTwoToneIcon/></div>   
            <div className="qr-code-img-container">
                <div className="qr-code-msg">
                  <div className="qr-code-txt">QR Code</div>
                  <div className="qr-code-name">{props.commonState.userName}</div>
                </div>
                <QRCode
                    className="qr-code-img"
                    renderas='svg'
                    // size={350} 
                    // level="L"
                    value={props.commonState.userEmail}>
                </QRCode>
    
            </div>                
            </div>
        </DialogContent>
        
      </Dialog>
    </div>
  );
}