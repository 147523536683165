import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import MuiDialogContent from '@material-ui/core/DialogContent';
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';

import chair_icon from './../../Assets/Images/chair1.png'; 
// import health_icon from './../../Assets/Images/safe-health.png'; 

import './booking.css'



const DialogContent = withStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
  },
}))(MuiDialogContent);



export default function Booking(props) {
  // const [open, setOpen] = React.useState(true);
  
  let gatePassStatus;

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  const handleClose = () => {
    // setOpen(false);
    props.closeBooking()
  };



  const getMonth = {
    0 : 'Jan',
    1 : 'Feb',
    2 : 'Mar',
    3 : 'Apr',
    4 : 'May',
    5 : 'Jun',
    6 : 'Jul',
    7 : 'Aug',
    8 : 'Sep',
    9 : 'Oct',
    10: 'Nov',
    11: 'Dec'
}

const get_day = {
    0 : 'Sunday',
    1 : 'Monday',
    2 : 'Tuesday',
    3 : 'Wednesday',
    4 : 'Thursday',
    5 : 'Friday',
    6 : 'Saturday',
}

  const printDate = () => {
    let blocked_date;
    if(props.gatepassType==="self") {
      blocked_date = props.getPass.blocked_date
    }
    else {
      blocked_date = new Date(props.getPass.blocked_date.toDate())
    }
    
    let blocked_date_date;

     if (blocked_date.getDate() < 10) {
         blocked_date_date = '0' + blocked_date.getDate().toString()
     }
     else {
         blocked_date_date = blocked_date.getDate().toString()
     }

     const blocked_date_month = getMonth[blocked_date.getMonth()]

    //  const blocked_date_year = blocked_date.getFullYear().toString()

     const blocked_day = get_day[blocked_date.getDay()]

    //  return blocked_date_date + '-' + blocked_date_month + '-' + blocked_date_year
    //  return blocked_date_date + '-' + blocked_date_month + ', ' + blocked_day
        return blocked_day + ', ' + blocked_date_date + '-' + blocked_date_month
 }

 const safe_to_work_msg = () => {
  
     if(props.commonState.site_data.need_declaration){
      return (
        <div className='safety_msg_div'>Safe to work from office</div>
      )
     }
   
 }

 const parking_number = () => {
    if(props.commonState.site_data.car_parking_enabled && props.getPass.parking){
      return (
       <div className="parking_number">
         <div className="">Parking: {props.getPass.parking}</div>
         {/* <div style={{flex:1}}></div> */}
       </div>
      )
    }
    else {
      return (
        <div className="parking_number"></div>
      )
    }
 }

 const printGatePass = () => {
   if(props.getPass && props.healthStatus === "Updated" && props.getPass.booking_status === 'Confirmed'){
      return (
        <div className="booking_wrapper">
          <div className="booking_main">
              <div className='booking_img'>
                    <img
                    alt=''
                    className='gatepass_chair_css'
                    src={chair_icon}
                    />
                    {/* {safe_to_work_msg()}
                    <div>Parking: 123</div> */}
                </div>

                <div className='booking_text'>
               
                    {/* <div className='title'>Gate Pass</div> */}
                    <div className='title'>{props.getPass.booked_by}</div>
                    <div className='date'>{printDate()}</div>
                   
                    {/* <div className='gatepass_ws'>{props.getPass.booked_ws}</div> */}

                </div>

                <div className='booking_text'>    
                    <div className='gatepass_ws'>{props.getPass.booked_ws}</div>
                    {/* <div className='date'>{printDate()}</div> */}

                    <div className='floor-name-booking'>
                        <div>{props.getPass.wing? props.getPass.wing + ',': ''} {props.getPass.floor}</div>
                       
                    </div>
                </div>                       
          </div>
          <div className="booking_bottom">
              
              {safe_to_work_msg()}
              
              {parking_number()}

              <div className="ws_number"></div>
          </div>
        </div>
      )
     
    }
    else {
      // console.log("props.getPass.booking_type", props.getPass.booking_type)
          if(!props.getPass){
            gatePassStatus = "You do not have a booking today!"
          }

          else if(props.getPass.booking_type === 'New Booking') {
            gatePassStatus = "Please select seat to confirm today's booking!"
          }

          else if(props.getPass.booking_type === 'Auto Booking') {
            gatePassStatus = "Please confirm today's booking to activate the gatepass!"
          }

      
          else if(props.healthStatus === "Not Updated") {
            gatePassStatus = "Please update your health declaration to activate the gatepass!"
          }

          else if (props.healthStatus === "Not Safe"){
            gatePassStatus = "Your health status seems not safe to come office!"
        }

    }

    return (
      <div className="booking_main">
         {/* <div className='booking_img'>
              <img
              alt=''
              className='gatepass_chair_css'
              src={chair_icon}
              />
          </div> */}

          <div className='booking_text booking_text_error'>
              <div className="title">{gatePassStatus}</div>           
          </div>
    </div>
    )
 }

  return (
    <div className='booking_container'>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <Dialog onClose={handleClose}  open={true}>
       
        <DialogContent className='booking_container'>
            <div className="booking_main">   
            <div className="close_icon" onClick={()=>handleClose()}><CancelTwoToneIcon/></div>      
               {printGatePass()}
            </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}