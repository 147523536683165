// import React, { useState, useEffect, Profiler } from "react";
import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { firebase } from "../../../config/firebase";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "../../Loader/loader";
import "./user-table.css";
// import { makeStyles } from "@material-ui/styles";
import Header from "../../AppHeader/header";
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import warningIcon from "./../../../Assets/Images/warning.png";
import infoIcon from "./../../../Assets/Images/info.png";

export default function EditUserChild(props) {
  const [availableSites, setAvailableSites] = useState([]);
  const [category_list, set_category_list] = useState([]);
  const [category_list_all, set_category_list_all] = useState([]);
  const [org_list, set_org_list] = useState([]);
  const [org_list_all, set_org_list_all] = useState({});
  const [usersSite, setUsersSite] = useState(props.location.state.commonState.site);
  const [usersCat, setUsersCat] = useState("");
  const [usersOrg, setUsersOrg] = useState(props.location.state.commonState.organization);
  const [refresh, setRefresh] = useState(false);
  const [usersBlkAdmin, setUsersBlkAdmin] = useState("");
  // const [usersFloor, setUsersFloor] = useState("");
  // const [usersWs, setUsersWs] = useState("");
  // const [usersWing, setUsersWing] = useState("");
  // const [usersAltEmail, setusersAltEmail] = useState("");
  // const [selected_user_details, setselected_user_details] = useState({});
  const [usersSiteRole, setUsersSiteRole] = useState("");
  const [userCatAutoBookingFlag, setUserCatAutoBookingFlag] = useState(false);
  const [usersBlockRole, setUsersBlockRole] = useState("");
  const [usersCubicleRole, setUsersCubicleRole] = useState("");
  // const [blockAdmin_list, set_blockAdmin_list] = useState([]);
  const [blockAdmin_display, set_blockAdmin_display] = useState([]);
  const [questions, setQuestions] = useState([]);
  // const [tempQuestions, setTempQuestions] = useState([]);
  const [prevAns, setPrevAns] = useState([""]);
  const [tnc, setTnC] = useState(false)
  const [askHealthQuestPermanent, setAskHealthQuestPermanent] = useState(props.location.state.commonState.site_data.need_declaration_profile);


  // const useStyles = makeStyles({
  //   root: {
  //     '&$selected': {
  //       backgroundColor: 'red',
  //     },
  //   },
  //   selected: {},
  // });

  const options = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  // const [loading, setLoading] = useState(true);
  const [blockadEmails, setblockadEmails] = useState({});
  const [loadingg, setLoadingg] = useState(true);
  const sites_ref = firebase.firestore().collection("Sites");
  const users_ref = firebase.firestore().collection("Users");
  const category_ref = firebase
    .firestore()
    .collection("Categories/" + usersSite + "/Category");
  const history = useHistory();

  const declaration_ref = firebase
    .firestore()
    .collection(
      "ProfileQuestions/" + usersSite + "/Questions"
    );


  useEffect(() => {
    setLoadingg(true);
    sites_ref.get().then((querySnapshot) => {
      const tempSites = [];
      let org_all = {}
      querySnapshot.forEach((doc) => {
        const org_list = []
        const site = doc.id;
        tempSites.push({ value: site, label: site });
        const Orgs = doc.data().organization
        
        if (Orgs) {
          Orgs.forEach((orgz) => {
            org_list.push({ value: orgz, label: orgz });
            org_all[site] = org_list;
          });
        } else {
          org_all[site] = '';
        }
        
        if(site === usersSite){
          set_org_list([...org_list])
        }
        
      });
      setAvailableSites([...tempSites]);
      set_org_list_all({...org_all})
      // console.log(org_all)
     
    });

    category_ref
      .get()
      .then(
        (querySnapshot) => {
          let cat_all = {}
          querySnapshot.forEach((doc) => {
            const cat = doc.data();
            cat.id = doc.id;
            // Categories.push({ value: cat.category, label: cat.category });
            if(cat.week_preference){
              let tempCatGroup = cat.week_preference.group;
              if (cat_all[tempCatGroup]) {
                let temp_group_arr = [...cat_all[tempCatGroup]]
                temp_group_arr.push({ value: cat.category, label: cat.category })
                cat_all[tempCatGroup] = [...temp_group_arr]
              }
              else {
                  cat_all[tempCatGroup] = [{ value: cat.category, label: cat.category }]; 
              }
            }

            if (doc.data().category === location.state.commonState.category) {
              if (doc.data().isActive && doc.data().auto_booking_enabled) {
                setUserCatAutoBookingFlag(true);
              }
            }
          });
          set_category_list_all({...cat_all})
          set_category_list([...cat_all[usersOrg]]);
        },
        (error) => {
          console.log(error);
        }
      )
      .catch(function (error) {
        console.log("Error getting Category List: ", error);
      });

    let temp_blk_admin = {};
    users_ref
      .where("site", "==", usersSite)
      .where("isBlockAdmin", "==", true)
      .orderBy("blockAdmin", "asc")
      .get()
      .then(
        (querySnapshot) => {
          const BlockAdmins = [];
          const BlockAdminsLabel = [];
          querySnapshot.forEach((doc) => {
            const blk_admin = {};
            blk_admin.blockAdmin = doc.data().userName;
            blk_admin.blockAdminEmail = doc.data().userEmail;
            temp_blk_admin[blk_admin.blockAdmin] = doc.data().userEmail;
            BlockAdmins.push(blk_admin);
            let temp_label =
              blk_admin.blockAdmin + " (" + blk_admin.blockAdminEmail + ")";
            BlockAdminsLabel.push({
              value: blk_admin.blockAdmin,
              label: temp_label,
            });
          });
          // set_blockAdmin_list([...BlockAdmins]);
          set_blockAdmin_display([...BlockAdminsLabel]);
          setblockadEmails({ ...temp_blk_admin });
        },
        (error) => {
          console.log(error);
        }
      )
      .catch(function (error) {
        console.log("Error getting Site List: ", error);
      });

    const questions_set = []
    var selectedans = {};
    users_ref
      .doc(location.state.selected_user_email)
      .get()
      .then((doc) => {
        if (!doc.exists) {
        } else {
          setLoadingg(true);
          // setselected_user_details({ ...doc.data() });
          setUsersSite(doc.data().site);
          setUsersCat(doc.data().category);
          setUsersBlkAdmin(doc.data().blockAdmin);
          if (doc.data().organization) {
            setUsersOrg(doc.data().organization)
          }
          if (doc.data().isBlockAdmin) {
            setUsersBlockRole("Yes");
          } else {
            setUsersBlockRole("No");
          }
          if (doc.data().isSiteAdmin) {
            setUsersSiteRole("Yes");
          } else {
            setUsersSiteRole("No");
          }
          if (doc.data().cubicleBookingAccess) {
            setUsersCubicleRole("Yes");
          } else {
            setUsersCubicleRole("No");
          }
          if (doc.data().userEmailAlternate) {
            // setusersAltEmail(doc.data().userEmailAlternate);
          }
          if (doc.data().Health_Declaration) {
            var temp_decl_questions_data = [...doc.data().Health_Declaration];
            temp_decl_questions_data.forEach((doc) => {
              selectedans[doc.id] = doc.SelectedAns;
            });
          }
          setLoadingg(false);
        }
      })
      .catch(function (error) {
        console.log("Error getting Site List: ", error);
      });


    
    if(askHealthQuestPermanent) {
      declaration_ref
        .orderBy("Que_No", "asc")
        .get()
        .then(
          (querySnapshot) => {
            const Questions = [];
            var temp_options_change = [];
            querySnapshot.forEach((doc) => {
              setAskHealthQuestPermanent(true)
              var temp_json = { ...doc.data() }
              var temp_options = [];

              doc.data().Options.forEach((menuoption) => {
                temp_options.push({ value: menuoption, label: menuoption })
              })

              temp_json.Question = doc.data().Question
              temp_json.Options = [...temp_options]
              temp_json.id = doc.id;


              questions_set.push(doc.id)

              if (selectedans[doc.id]) {
                const temp_op = selectedans[doc.id];
                temp_json.check_prev_ans = selectedans[doc.id];
                temp_options_change.push(temp_op)
              } else {
                temp_options_change.push("")
                temp_json.check_prev_ans = "";
              }
              Questions.push(temp_json);
            });
            setQuestions(Questions);
            setPrevAns([...temp_options_change])
          },
          (error) => {
            console.log(error);
          }
        )
        .catch(function (error) {
          console.log("Error getting Site List: ", error);
        });
    }
    
    setLoadingg(false);
  }, [refresh]);
  


  const location = useLocation();

  const handleSiteChange = (e) => {
    setLoadingg(true);
    setUsersSite(e.target.value);

    set_org_list(org_list_all[e.target.value])

    let category_ref_t = firebase.firestore().collection("Categories/" + e.target.value + "/Category");

    set_category_list_all({})
    set_category_list([]);

    category_ref_t
    .get()
    .then(
      (querySnapshot) => {
        let cat_all = {}
        querySnapshot.forEach((doc) => {
          const cat = doc.data();
          cat.id = doc.id;
          // Categories.push({ value: cat.category, label: cat.category });
          if(cat.week_preference){
            let tempCatGroup = cat.week_preference.group;
            if (cat_all[tempCatGroup]) {
              let temp_group_arr = [...cat_all[tempCatGroup]]
              temp_group_arr.push({ value: cat.category, label: cat.category })
              cat_all[tempCatGroup] = [...temp_group_arr]
            }
            else {
                cat_all[tempCatGroup] = [{ value: cat.category, label: cat.category }]; 
            }
          }

          if (doc.data().category === location.state.commonState.category) {
            if (doc.data().isActive && doc.data().auto_booking_enabled) {
              setUserCatAutoBookingFlag(true);
            }
          }
        });
        set_category_list_all({...cat_all})
        set_category_list([...cat_all[usersOrg]]);
      },
      (error) => {
        console.log(error);
      }
    )
    .catch(function (error) {
      console.log("Error getting Category List: ", error);
    });


    users_ref
      .where("site", "==", e.target.value)
      .where("isBlockAdmin", "==", true)
      .orderBy("blockAdmin", "asc")
      .get()
      .then(
        (querySnapshot) => {
          const BlockAdmins = [];
          const BlockAdminsLabel = [];
          querySnapshot.forEach((doc) => {
            const blk_admin = {};
            blk_admin.blockAdmin = doc.data().userName;
            blk_admin.blockAdminEmail = doc.data().userEmail;
            BlockAdmins.push(blk_admin);
            let temp_label =
              blk_admin.blockAdmin + " (" + blk_admin.blockAdminEmail + ")";
            BlockAdminsLabel.push({
              value: blk_admin.blockAdmin,
              label: temp_label,
            });
          });
          // set_blockAdmin_list([...BlockAdmins]);
          set_blockAdmin_display([...BlockAdminsLabel]);
        },
        (error) => {
          console.log(error);
        }
      )
      .catch(function (error) {
        console.log("Error getting Site List: ", error);
      });

    setLoadingg(false);
  };

  const handleCatChange = (e) => {
    setUsersCat(e.target.value);
  };

  const handleOrgChange = (e) => {
    setUsersOrg(e.target.value);
    set_category_list([...category_list_all[e.target.value]]);
  };

  const handleBlkAdminChange = (e) => {
    setUsersBlkAdmin(e.target.value);
  };

  const handleWsChange = (e) => {
    // setUsersWs(e.target.value);
  };

  const handleFloorChange = (e) => {
    // setUsersFloor(e.target.value);
  };

  const handleWingChange = (e) => {
    // setUsersWing(e.target.value);
  };

  const handleBlockRoleChange = (e) => {
    setUsersBlockRole(e.target.value);
  };

  const handleCublicleRoleChange = (e) => {
    setUsersCubicleRole(e.target.value);
  };

  const handleSiteRoleChange = (e) => {
    setUsersSiteRole(e.target.value);
  };

  const handleHealthQuesChange = (e, qindex) => {
    var temp_selected_ans = [...prevAns];
    temp_selected_ans[qindex] = e.target.value;
    setPrevAns([...temp_selected_ans])
    var temp_json = []
    temp_json = [...questions]
    temp_json[qindex].check_prev_ans = e.target.value;
    setQuestions(temp_json)
  };


  const update_confirmation = (e) => {


    if (askHealthQuestPermanent) {
      var check_unanswered_ques = false
      questions.forEach((question) => {
        if (!question.check_prev_ans) {
          check_unanswered_ques = true;
        }
      });
      // console.log(check_unanswered_ques)
      if (check_unanswered_ques) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui confirm_dialog">
                <div className="confirm_title">Validation</div>
                <div className="confirm_msg">
                  <div className="img">
                    <img
                      alt=""
                      style={{ width: 50, height: 50 }}
                      src={warningIcon}
                    />
                  </div>
                  <div className="msg">Please answer all the questions</div>
                </div>

                <div className="confirm_buttons">
                  <button className="confirm_yes" onClick={onClose}>
                    OK
                  </button>
                </div>
              </div>
            );
          },
        });


      } else if (!tnc) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui confirm_dialog">
                <div className="confirm_title">Validation</div>
                <div className="confirm_msg">
                  <div className="img">
                    <img
                      alt=""
                      style={{ width: 50, height: 50 }}
                      src={warningIcon}
                    />
                  </div>
                  <div className="msg">Please read and agree to the terms and conditions</div>
                </div>

                <div className="confirm_buttons">
                  <button className="confirm_yes" onClick={onClose}>
                    OK
                  </button>
                </div>
              </div>
            );
          },
        });
      }
      else {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui confirm_dialog">
                <div className="confirm_title">Confirmation</div>
                <div className="confirm_msg">
                  Are you sure you want update the details?
                </div>
                <div className="confirm_buttons">
                  <button
                    className="confirm_no"
                    onClick={() => {
                      setRefresh(!refresh);
                      onClose();
                    }}
                  >
                    No
                  </button>

                  <button
                    className="confirm_yes"
                    onClick={() => {
                      updateUserDetails();
                      onClose();
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            );
          },
        });
      }
    }

    // }
    // else if (!tnc) {

    else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui confirm_dialog">
              <div className="confirm_title">Confirmation</div>
              <div className="confirm_msg">
                Are you sure you want update the details?
              </div>
              <div className="confirm_buttons">
                <button
                  className="confirm_no"
                  onClick={() => {
                    setRefresh(!refresh);
                    onClose();
                  }}
                >
                  No
                </button>

                <button
                  className="confirm_yes"
                  onClick={() => {
                    updateUserDetails();
                    onClose();
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          );
        },
      });
    }
  };

  const updateUserDetails = (e) => {
    let temp_blk_role = usersBlockRole === "Yes" ? true : false;
    let temp_site_role = usersSiteRole === "Yes" ? true : false;
    let temp_cub_role = usersCubicleRole === "Yes" ? true : false;
    let temp_blk_admin_email = "";
    if (usersBlkAdmin && usersBlkAdmin !== "") {
      if (blockadEmails[usersBlkAdmin]) {
        temp_blk_admin_email = blockadEmails[usersBlkAdmin];
      }

    }

    var new_answers = [...questions]

    for (var new_ans in new_answers) {
      new_answers[new_ans].SelectedAns = new_answers[new_ans].check_prev_ans;
      delete new_answers[new_ans]['check_prev_ans'];
      var lv_options = new_answers[new_ans].Options;
      var lv_options_arr = []
      for (var lv_opt in lv_options) {
        lv_options_arr.push(lv_options[lv_opt].label)
      }
      new_answers[new_ans].Options = [...lv_options_arr];
    }

    users_ref
      .doc(location.state.selected_user_email)
      .update({
        site: usersSite,
        category: usersCat,
        organization: usersOrg,
        blockAdmin: usersBlkAdmin,
        blockAdminEmail: temp_blk_admin_email,
        isBlockAdmin: temp_blk_role,
        isSiteAdmin: temp_site_role,
        cubicleBookingAccess: temp_cub_role,
        // userEmailAlternate: usersAltEmail,
        last_updated_on: new Date(),
        last_updated_by: location.state.commonState.userName,
        Health_Declaration: [...new_answers]
      })
      .then(function (docRef) {
        // console.log("User details changed");
        if (location.state.action === "user_profile") {
          history.push("/", location.state);
        } else {
          history.push("/admin", {
            action: "update_declaration",
            commonState: location.state.commonState,
            tab: "users",
          });
        }
      })
      .catch(function (error) {
        console.error("Error");
      });
  };

  const UpdateMyWS = () => {
    const updateWSAction =
      location.state.commonState.direct_booking_flag === "Site Level-Auto"
        ? "UpdateMyWS-SiteLevel"
        : "UpdateMyWS-CategoryLevel";
    history.push("/floorplan", {
      commonState: location.state.commonState,
      action: updateWSAction,
    });
  };

  const display_ws_details = () => {
    if (
      location.state.commonState.direct_booking_flag === "Site Level-Auto" ||
      userCatAutoBookingFlag === true
    ) {
      return (
        <div style={{ display: "flex", width: "80%" }}>
          <TextField
            value={location.state.commonState.my_ws.ws}
            disabled
            onChange={(e) => {
              handleWsChange(e);
            }}
            className="user-sub-field2"
            label="WS"
          />
          <TextField
            value={location.state.commonState.my_ws.floor}
            disabled
            onChange={(e) => {
              handleFloorChange(e);
            }}
            className="user-sub-field2"
            label="Floor"
          />
          <TextField
            value={location.state.commonState.my_ws.wing}
            disabled
            onChange={(e) => {
              handleWingChange(e);
            }}
            className="user-sub-field2"
            label="Wing"
          />
          <button
            // disabled
            onClick={(e) => {
              UpdateMyWS(e);
            }}
            // style={{margin:'2%', fontWeight:'normal', fontSize:'16px', padding:'0px', width:'20%'}}
            className="user-field-style user-change-button"
          >
            Change
          </button>
        </div>
      );
    }
  };

  const display_button = () => {
    return (
      <div className="user-container">
        {/* <button variant="contained"
          className='user-field-style user-save-button'
          style={{ color: 'gray', fontWeight: 'bold', marginRight: '2%', borderWidth: '2px', borderColor: '#dcdfe3', backgroundColor: 'white' }}
        >
          {/* <CloseIcon />  */}
        {/* CANCEL
        </button>  */}
        <button
          onClick={(e) => {
            update_confirmation(e);
          }}
          className="user-field-style user-save-button"
        // style = {{ backgroundColor: '#0e919c' }}
        >
          UPDATE
        </button>
      </div>
    );
  };

  const displayTermsAndConditions = (e) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui confirm_dialog">
            <div className="confirm_title">Terms and Conditions</div>
            <div className="confirm_msg">
              <div className="img">
                <img
                  alt=""
                  style={{ width: 50, height: 50 }}
                  src={infoIcon}
                />
              </div>
              <div className="msg">{location.state.commonState.site_data.tnc}</div>
            </div>

            <div className="confirm_buttons">
              <button className="confirm_yes" onClick={onClose}>
                CLOSE
              </button>
            </div>
          </div>
        );
      },
    });
  }

  const display_roles_section = () => {
    if (
      location.state.commonState.isAppAdmin ||
      location.state.commonState.isSiteAdmin
    ) {
      return (
        <div className="user-container">
          <TextField
            value={usersSiteRole}
            select
            style={{ margin: "5px" }}
            onChange={(e) => {
              handleSiteRoleChange(e);
            }}
            className="user-sub-field"
            label="Is Site Admin?"
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            value={usersBlockRole}
            select
            style={{ margin: "5px" }}
            onChange={(e) => {
              handleBlockRoleChange(e);
            }}
            className="user-sub-field"
            label="Is Block Admin?"
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            value={usersCubicleRole}
            select
            style={{ margin: "5px" }}
            onChange={(e) => {
              handleCublicleRoleChange(e);
            }}
            className="user-sub-field"
            label="Enable Cubicle/Cabin Booking?"
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      );
    }
  };

  const display_health_decl_questions = () => {
    if (askHealthQuestPermanent) {
      return (
        <div
          className="new_user_main"
          style={{ margin: "auto", width: "80%", fontFamily: "ColgateReady", backgroundColor: "White" }}
        >
          <div style={{ width: "100%" }}>
            <div className="profile-flex-container Profile-Header">
              <div className="Profile-title">COVID-19 Vaccination Status</div>
            </div>
            {questions.map((question, qindex) => (
              <div className="user-container">
                <TextField
                  // value={prevAns[qindex]}
                  value={question.check_prev_ans}
                  select
                  name={qindex + 'textinput'}
                  style={{ fontFamily: "ColgateReady" }}
                  onChange={(e) => {
                    handleHealthQuesChange(e, qindex);
                  }}
                  className="user-field"
                  label={question.Question}
                >
                  {question.Options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            ))}

          </div>

          <div className="pdeclaration_note">
            <Checkbox checked={tnc} onChange={(e) => setTnC(!tnc)} />
            I have read and agree to the
            <Link onClick={(e) => {
              displayTermsAndConditions(e);
            }}> Terms and Conditions
            </Link>
          </div>
        </div>
      )
    }
  }


  // const handleAltEmailChange = (e) => {
  //   // setusersAltEmail(e.target.value);
  // };

  const display_email = () => {

    if (
      location.state.commonState.isAppAdmin ||
      location.state.commonState.isSiteAdmin
    ) {
      return (
        <div className="user-container">
          <TextField
            disabled
            value={location.state.selected_user_email}
            className="user-field root"
            label="Email ID"
            style={{ fontFamily: 'ColgateReady' }}
          ></TextField>
        </div>
      )
    }
  }

  return (
    <div>
      <Loader loading={loadingg}></Loader>
      <div style={{ flex: 1, width: "100%", border:"0px" }}>
        <Header
          currentPage="Declaration"
          commonState={location.state.commonState}
        />
      </div>
      <div >
        <div
          className="new_user_main"
          style={{ margin: "auto", width: "80%", fontFamily: "ColgateReady", backgroundColor: "White", marginTop: "5px" }}
        >
          <div style={{ width: "100%" }}>
            <div className="profile-flex-container Profile-Header ">
              <div className="Profile-title">General</div>
            </div>
            {display_email()}
            <div className="user-container">
              <TextField
                value={usersSite}
                select
                className="user-field"
                style={{ fontFamily: 'ColgateReady' }}
                onChange={(e) => {
                  handleSiteChange(e);
                }}
                label="Site"
              >
                {availableSites.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="user-container">
              <TextField
                value={usersOrg}
                select
                className="user-sub-field"
                style={{ fontFamily: 'ColgateReady', margin: "5px" }}
                onChange={(e) => {
                  handleOrgChange(e);
                }}
                label="Organization"
              >
                {org_list.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            {/* </div>
            <div className="user-container"> */}
              <TextField
                value={usersCat}
                select
                style={{ fontFamily: "ColgateReady", margin: "5px" }}
                onChange={(e) => {
                  handleCatChange(e);
                }}
                className="user-sub-field-cat"
                label="Category"
              >
                {category_list.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="user-container">
              <TextField
                value={usersBlkAdmin}
                select
                style={{ fontFamily: "ColgateReady" }}
                className="user-field"
                onChange={(e) => {
                  handleBlkAdminChange(e);
                }}
                label="Cat Lead/Block Admin"
              >
                {blockAdmin_display.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <div className="user-container">{display_ws_details()}</div>
            {display_roles_section()}

            <br></br>

          </div>
        </div>
        <div
          style={{ marginTop: "5px" }}
        ></div>

        {display_health_decl_questions()}
        {display_button()}
      </div>
    </div>
  );
}

