// import React, { useState, useEffect, Profiler } from "react";
import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Loader from "../Loader/loader";
import Header from "../AppHeader/header";
import { firebase } from '../../config/firebase';
import { pdfjs, Document, Page } from "react-pdf";

import "./help-documents.css"

export default function HelpDocuments(props) {

    const [loadingg, setLoadingg] = useState(true);
    const history = useHistory();
    // const { pageNumber, numPages } = useState(1);
    const [numPages, setNumPages] = useState(null);

    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

    const [pdf_url, setPdfUrl] = useState("");
    const storageRef1 = firebase.storage().ref();

    useEffect(() => {
        setLoadingg(true)
        var starsRef = storageRef1.child('user_guide.pdf');
        starsRef.getDownloadURL()
            .then((url) => {
                setPdfUrl(url);
            })
            .catch((error) => {
                alert(
                    "Error opening file"
                );
            });
    }, []);


    const check_action = () => {
        if (pdf_url) {

            if (props.location.state.link_action === 'iframe') {
                return (
                    <div className="help-page"><Loader loading={loadingg}></Loader>
                        <iframe className="help-page-iframe" title="Help Documents"
                            onLoad={(e) => setLoadingg(false)}
                            src={pdf_url + '#view=fitH'} ></iframe></div>
                )

            }
            else if (props.location.state.link_action === 'object') {
                return (
                    <div className="help-page">
                        <object className="help-page-iframe" type="application/pdf"
                            data={pdf_url} >Loading...</object>
                    </div>)
            }
            else if (props.location.state.link_action === 'document') {
                return (
                    <div className="help-page">
                        <Document
                            file={pdf_url}
                            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                        >
                            {Array.from(new Array(numPages), (el, index) => (
                                <Page wrap={false} key={`page_${index + 1}`} pageNumber={index + 1} />
                            ))}
                        </Document>
                    </div>)
            }
        }
        else {
            return (
                <div><Loader loading={loadingg}></Loader></div>
            )
        }
    }


    const location = useLocation();
    return (
        <div>
            <div className="help-doc-header">
                <Header
                    currentPage="Declaration"
                    commonState={location.state.commonState}
                />
            </div>
            {check_action()}
        </div>
    );
}

