import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';


import MuiDialogContent from '@material-ui/core/DialogContent';
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';

// import ReactDOM from "react-dom";
// import QRCode from "react-qr-code";
import QrReader from 'react-qr-reader'

import './scan-qr-code.css'



const DialogContent = withStyles((theme) => ({
    root: {
      // padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

export default function GenerateQRCode(props) {



    // const [displayGatePass, setDisplayGatePass] = useState(false);
    // const [gatepass, setGatePass] = useState();

  const handleClose = () => {
    // setOpen(false);
    props.closeQRCodeScan()
  };

  const handleError = err => {
    console.error(err)
  }

  const handleScan = data => {
      // console.log(data)
    if (data) {
      // console.log("scanned data", data)
      
      props.handleQRCodeScan(data)      
      
    }
  }


return (
    <div className='qrcode_scan_container'>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <Dialog onClose={handleClose}  open={true}>
       
        <DialogContent className='qrcode_scan_container'>
            <div className="qrcode_scan_main">   
            <div className="close_icon" onClick={()=>handleClose()}><CancelTwoToneIcon/></div>   
            
                <div className="qr-scan-container">
                  <div className="qr-scan-title">Scan QR Code</div>
                  <QrReader
                  delay={100}
                  onError={()=> handleError()}
                  onScan={(data) => handleScan(data)}
                  facingMode = "user"
                  // style={{ width: '50%' }}
                  className="QR_reader"
                  />               
              </div>
            </div>
        </DialogContent>
        
      </Dialog>
    </div>
  );
}