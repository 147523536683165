import React from 'react';
import './App.css';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { Container } from 'semantic-ui-react';

import Home from './Components/Home/home'
import Declaration from './Components/Declaration/declaration'
import FloorPlan from './Components/FloorPlan/floor-plan';
import SignIn from './Components/SignIn/sign-in'
import Register from './Components/Register/register'
import ErrorPage from './Components/ErrorPage/error-page'
import SiteDown from './Components/SiteDown/site-down'
import AdminHome from './Components/Admin/admin-home'
import EditUserChild from './Components/Admin/Users/edit-user-child'
import DeclarationProfile from './Components/Declaration/profile_questions'
import HelpDocuments from './Components/HelpDocuments/help-documents'
import NotFound from './Components/NotFound/not_found';
import oktaconfig from './config/okta_sso'

const oktaAuth = new OktaAuth({ ...oktaconfig });

function App() {

  const history = useHistory();

  const restoreOriginalUri = async (oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Container>
        <div className="App">
          <Switch>
            <SecureRoute exact path="/" component={Home} />
            <SecureRoute exact path="/register" component={Register} />
            <Route exact path="/signin" component={SignIn} />
            <Route path="/login/callback" component={LoginCallback} />
            <SecureRoute exact path="/declaration" component={Declaration} />
            <SecureRoute exact path="/health_declaration" component={DeclarationProfile} />
            <SecureRoute exact path="/floorplan" component={FloorPlan} />
            <SecureRoute exact path="/error" component={ErrorPage} />
            <SecureRoute exact path="/admin" component={AdminHome} />
            <SecureRoute exact path="/user_profile" component={EditUserChild} />
            <SecureRoute exact path="/help" component={HelpDocuments} />
            <Route exact path="/error_page" component={NotFound}></Route>
            <SecureRoute exact path="/site-down" component={SiteDown}></SecureRoute>
            <Redirect to="/error_page"></Redirect>
          </Switch>
        </div>
      </Container>
    </Security>


  );
}

export default App;
