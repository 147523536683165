import React , { useState } from 'react';
import { firebase } from '../../../config/firebase';
// import { useLocation } from 'react-router-dom';
import FloorDivSub from './floor-div-sub';
import WSAttributes from './ws-attributes';
import Loader from '../../Loader/loader'
// import { confirmAlert } from 'react-confirm-alert';
import '../../Home/home.css'
import Org_Division from './org-division';


export default function FloorDiv (props) {
    // const location = useLocation();
//    let currentDate = new Date();

//    const [commonState, setCommonState] = React.useState({
//        currentDate : new Date(currentDate.getFullYear(), currentDate.getMonth() , currentDate.getDate()),
//     //    userName: 'Suhas Patil',
//        userEmail: 'suhas_patil@colpal.com',
//        blockAdmin: 'Suhas Patil',
//        blockAdminEmail: 'suhas_patil@colpal.com',
//        CL_TeamSize: 58,
//     //    isBlockAdmin: false     
//    });
   const [loading, setLoading] = useState(false)
// const [blockAdminPage, setBlockAdminPage] = useState()   
const [blockBookings, setBlockBookings] = useState([]) 
// const [seatBookings, setSeatBookings]= useState([]) 
const[currentfloor, setCurrentfloor] = useState('tmp');
const [refresh, setrefresh] = useState(false)
const [flag_wing_attribute, set_flag_wing_attribute] = useState('Wings');
// let c_seatBookings = []

// const blocked_seats_ref = firebase.firestore().collection('BlockedSeats/' + props.commonState.site + '/Blocks');
const floor_division_ref = firebase.firestore().collection('FloorDivision/' + props.commonState.site + '/' + currentfloor);
const org_division_ref = firebase.firestore().collection('OrgDivision/' + props.commonState.site + '/' + currentfloor);

    React.useEffect(() => {
        if (currentfloor !== 'tmp') {
            setLoading(true)
            if (flag_wing_attribute === 'Organizations') {
 
                org_division_ref
                    .get()
                    .then(
                        querySnapshot => {
                            const vBlockBookings = []
                            querySnapshot.forEach(doc => {
                                const booking = doc.data()
                                vBlockBookings.push(booking)
                            });
                            setBlockBookings(vBlockBookings)
                            setLoading(false)
                        },
                        error => {
                            console.log(error)
                        }
                    )
            }
            else {
                floor_division_ref
                    .get()
                    .then(
                        querySnapshot => {
                            const vBlockBookings = []
                            querySnapshot.forEach(doc => {
                                const booking = doc.data()
                                vBlockBookings.push(booking)
                            });
                            //   console.log('vBlockBookings')
                            //   console.log(vBlockBookings)
                            //   c_seatBookings = [...vBlockBookings]
                            setBlockBookings(vBlockBookings)
                            //   console.log(blockBookings)
                            setLoading(false)
                        },
                        error => {
                            console.log(error)
                        }
                    )
            }
        }

    }, [currentfloor, refresh, flag_wing_attribute])


const onReseveBlock = (newBlock) => {
//    console.log(newBlock)
}

const setfloor = (floor) => {
    // console.log(floor)
    setCurrentfloor(floor)
}

const onCancelBlock = (block) => {
    // confirmAlert({
    //     customUI: ({ onClose }) => {
    //         return (
    //             <div className='custom-ui confirm_dialog'>
    //                 <div className="confirm_title">Confirmation</div>
    //                 <div className="confirm_msg">Are you sure you want to delete {block.wing}?</div>
    //                 <div className="confirm_buttons">
    //                     <button className="confirm_no" onClick={
    //                         () => {
    //                             setrefresh(!refresh)
    //                             onClose()
    //                         }
    //                     }>No</button>

    //                     <button className="confirm_yes"
    //                         onClick={
    //                             () => {
    //                                 delete_wing(block);
    //                                 onClose()
    //                             }
    //                         }
    //                     >Yes</button>
    //                 </div>
    //             </div>
    //         );

    //     }
    // })
    // setrefresh(!refresh)
    // console.log(block)
    floor_division_ref
    .doc(block.wing)
    .delete()
    .then(function () {
        // console.log("Document successfully deleted!");
    }).catch(function (error) {
        console.error("Error removing document: ", error);
    });
    setrefresh(!refresh)
   
}

const onCancelBlockOrg = (block) => {
    org_division_ref
    .doc(block.org)
    .delete()
    .then(function () {
        // console.log("Document successfully deleted!");
    }).catch(function (error) {
        console.error("Error removing document: ", error);
    });
    setrefresh(!refresh)
   
}



// const delete_wing = (block) => {
//     // console.log(block)
//     floor_division_ref
//     .doc(block.wing)
//     .delete()
//     .then(function () {
//         // console.log("Document successfully deleted!");
//     }).catch(function (error) {
//         console.error("Error removing document: ", error);
//     });
//     setrefresh(!refresh)
    
    
// }

const onReleaseSeats = (block) => {
}

// const onCancelBooking = (booking) => {
// }

// const onSelectSeat = (booking) => {
//     console.log(booking)
// }


const loadHomeScreen = () => {
   

    if(flag_wing_attribute === 'Wings') {

        return <FloorDivSub
                commonState={props.commonState}
                blockBookings={blockBookings}
                setfloor={(floor) => setfloor(floor)}
                handleReseveBlock={(newBlock)=>onReseveBlock(newBlock)}
                handleCancelBlock={(Block)=>onCancelBlock(Block)}
                handleReleaseSeats={(Block)=>onReleaseSeats(Block)}
                />
    }

    else if (flag_wing_attribute === 'WS Attributes') {
        return <WSAttributes
        commonState={props.commonState}
        blockBookings={blockBookings}
        setfloor={(floor) => setfloor(floor)}
        handleReseveBlock={(newBlock)=>onReseveBlock(newBlock)}
        handleCancelBlock={(Block)=>onCancelBlock(Block)}
        handleReleaseSeats={(Block)=>onReleaseSeats(Block)}
        />
    }

    else if (flag_wing_attribute === 'Organizations') {
        return <Org_Division
        commonState={props.commonState}
        blockBookings={blockBookings}
        setfloor={(floor) => setfloor(floor)}
        handleReseveBlock={(newBlock)=>onReseveBlock(newBlock)}
        handleCancelBlock={(Block)=>onCancelBlockOrg(Block)}
        handleReleaseSeats={(Block)=>onReleaseSeats(Block)}
        />
    }    
    
}

const change_flag_wing_attribute = (e) => {
    // console.log(flag_wing_attribute, e.target.value)
    set_flag_wing_attribute(e.target.value)
}


return(
    <div className='main-container'>
      <Loader loading={loading}></Loader>
         <div>
            <div className="wing-radio-grp"> 
                <span className="wing-radio">
                <input
                    className="radio_options"
                    type="radio"
                    name=""
                    value='Wings'
                    checked={flag_wing_attribute === 'Wings'}
                    onChange={(e) => change_flag_wing_attribute(e)}
                />
                Wings
                </span>  
                <span className="wing-radio">
                <input
                    className="radio_options"
                    type="radio"
                    name=""
                    value='WS Attributes'
                    checked={flag_wing_attribute === 'WS Attributes'}
                    onChange={(e) => change_flag_wing_attribute(e)}
                />
                WS Attributes
                </span> 
                <span className="wing-radio">
                <input
                    className="radio_options"
                    type="radio"
                    name=""
                    value='Organizations'
                    checked={flag_wing_attribute === 'Organizations'}
                    onChange={(e) => change_flag_wing_attribute(e)}
                />
                Organizations
                </span>                 
            </div> 
           {loadHomeScreen()}           
         </div>
        
    </div>
)
}