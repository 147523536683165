
import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import './../FloorPlan/floor-plan.css'
import Loader from '../Loader/loader'

export default function CGBSFloorEight(props) {

    // const [floorState, setFloorState] = useState(props.floorState)
    const floorState = props.floorState

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        let seat_count = 0
        Object.keys(floorState).forEach((ws) => {
            if (document.getElementById(ws)) {
                seat_count++;
            }
        })
        props.totalSeats(seat_count)

        let selectedAttibs = [];
        props.seatAttributes.map((attr) => {
            if (attr.class === "attribute-selected") {
                selectedAttibs.push(attr.label);
            }
            return true;
        })

        Object.keys(floorState).forEach((ws) => {
            if (document.getElementById(ws)) {
                const tooltip_text = floorState[ws].tooltip ? floorState[ws].tooltip : floorState[ws].status
                document.getElementById(ws).setAttribute("data-tip", tooltip_text);
                // console.log(ws + '_text')
                document.getElementById(ws + '_text').setAttribute("data-tip", tooltip_text);

                document.getElementById(ws).classList.remove("attrib-selected");
                if (floorState[ws].attributes) {
                    let checkAttribute = selectedAttibs.every(v => floorState[ws].attributes.includes(v))
                    if (checkAttribute && selectedAttibs.length > 0) {
                        document.getElementById(ws).classList.add("attrib-selected");
                    }
                }
            }
        })

        setLoading(false)
    });

    const seatSelected = (e) => {
        props.seatSelected(e)
    }

    return (
        <div>
            <ReactTooltip
                backgroundColor='#d01837'
            />
            <Loader loading={loading}></Loader>
            {/* <svg height={1450} width={800}> */}

            <svg viewBox="0 -20 800 1600">

                <g>
                    <rect x="-1" y="-20" width="800" height="1650" id="canvas_background" fill="#999999" strokeWidth="1.5" />
                </g>
                <g>
                    <rect height="1369.72103" width="759.52045" y="61.76936" x="19.62967" strokeWidth="1.5" fill="#faebd7" transform="rotate(-180 399.39 746.63)" />
                    <rect height="1345.0234" width="194.32045" y="62.99185" x="29.5196" strokeWidth="1.5" fill="#f4f4f4" />
                    <rect height="74.431" width="194.32045" y="62.44057" x="28.97674" strokeWidth="1.5" fill="#cccccc" stroke="#cccccc" />
                    <rect height="1369.40288" width="196.13306" y="61.47715" x="576.81812" strokeWidth="1.5" fill="#f4f4f4" />
                    <rect fill="#faebd7" strokeWidth="1.5" x="225.88798" y="679.17133" width="348.96704" height="152.08012" />
                    <path d="m258.67109,62.53635l282.75856,0l0,186.88242l-282.75856,0l0,-186.88242z" strokeWidth="1.5" fill="#f4f4f4" />
                    {/* <text fill="#000000" strokeWidth="0" strokeOpacity="null" x="-106.47109" y="136.09301" fontSize="18" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 120.836 38.2934)" stroke="#cccccc" opacity="0.52" fontWeight="bold">COLLABORATION ROOM</text> */}
                    <text fontWeight="bold" opacity="0.5" stroke="#cccccc" transform="matrix(0.557352 0 0 0.635674 45.353 -291.439)"  fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" fontSize="15" id="svg_803" y="660.03906" x="84.3779"  strokeWidth="0"  fill="#000000">MEETING ROOM</text>
                    <text fontWeight="bold" opacity="0.5" stroke="#cccccc" transform="matrix(0.612651 0 0 0.698368 41.989 -800.432)"  fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" fontSize="27" id="svg_871" y="1329.18379" x="5.76455" strokeWidth="0"   fill="#000000">8C</text>
                    <path transform="rotate(90 125.346 93.872)" fill="none" strokeWidth="2"  d="m117.06622,57.68631l16.56062,0l0,72.37106l-16.56062,0l0,-72.37106z" id="svg_874" stroke="#b2b2b2"/>
                    <path transform="rotate(90 168.767 93.656)" fill="none" strokeWidth="2"  d="m165.46707,90.85404l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" id="svg_877" stroke="#b2b2b2"/>
                    <path transform="rotate(90 81.621 93.656)" fill="none" strokeWidth="2"  d="m78.32287,90.85403l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" id="svg_878" stroke="#b2b2b2"/>
                    <g id="svg_908">
                    <path transform="rotate(90 99.236 77.423)" fill="none" strokeWidth="2"  d="m95.93629,74.62128l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" id="svg_879" stroke="#b2b2b2"/>
                    <path transform="rotate(90 149.65 77.423)" fill="none" strokeWidth="2"  d="m146.35023,74.62131l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" id="svg_882" stroke="#b2b2b2"/>
                    <path transform="rotate(90 138.543 77.423)" fill="none" strokeWidth="2"  d="m135.24287,74.62132l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" id="svg_890" stroke="#b2b2b2"/>
                    <path transform="rotate(90 128.824 77.423)" fill="none" strokeWidth="2"  d="m125.52388,74.62127l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" id="svg_895" stroke="#b2b2b2"/>
                    <path transform="rotate(90 119.105 77.423)" fill="none" strokeWidth="2"  d="m115.80495,74.62129l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" id="svg_904" stroke="#b2b2b2"/>
                    <path transform="rotate(90 109.386 77.423)" fill="none" strokeWidth="2"  d="m106.08601,74.62131l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" id="svg_906" stroke="#b2b2b2"/>
                    </g>
                    <g id="svg_917" stroke="null">
                    <path transform="rotate(90 100.09 110.743)" fill="none" strokeWidth="2"  d="m96.79065,107.94112l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" id="svg_909" stroke="#b2b2b2"/>
                    <path transform="rotate(90 150.504 110.743)" fill="none" strokeWidth="2"  d="m147.20459,107.94115l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" id="svg_910" stroke="#b2b2b2"/>
                    <path transform="rotate(90 139.396 110.743)" fill="none" strokeWidth="2"  d="m136.09723,107.94116l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" id="svg_912" stroke="#b2b2b2"/>
                    <path transform="rotate(90 129.677 110.743)" fill="none" strokeWidth="2"  d="m126.37823,107.94112l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" id="svg_914" stroke="#b2b2b2"/>
                    <path transform="rotate(90 119.959 110.743)" fill="none" strokeWidth="2"  d="m116.6593,107.94114l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" id="svg_915" stroke="#b2b2b2"/>
                    <path transform="rotate(90 110.24 110.743)" fill="none" strokeWidth="2"  d="m106.94037,107.94115l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" id="svg_916" stroke="#b2b2b2"/>
                    </g>
                    <rect height="184.02434" width="250.61865" y="281.95099" x="275.55215" strokeWidth="1.5" fill="#cccccc" stroke="#b2b2b2" />
                    <rect stroke="#999999" height="135.03358" width="165.75458" y="1300.60555" x="18.38185" strokeWidth="1.5" fill="#999999" />
                    <rect height="160.80826" width="90.01427" y="1269.57208" x="184.44703" strokeWidth="1.5" fill="#cccccc" stroke="#cccccc" />
                    <rect height="162.6365" width="303.75479" y="1268.35789" x="275.37391" strokeWidth="1.5" fill="#f4f4f4" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="282.506" x2="333.89616" y1="282.506" x1="274.40957" fillOpacity="null" fill="none" transform="rotate(-180 304.153 282.506)" stroke="#999999" opacity="0.45" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="282.20927" x2="526.76675" y1="282.20927" x1="466.09324" fillOpacity="null" fill="none" transform="rotate(-180 496.43 282.209)" stroke="#999999" opacity="0.45" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="344.19608" x2="334.68701" y1="344.19608" x1="274.34022" fillOpacity="null" fill="none" stroke="#999999" transform="rotate(-180 304.514 344.196)" opacity="0.45" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="405.65562" x2="334.687" y1="405.65562" x1="274.34021" fillOpacity="null" fill="none" stroke="#999999" transform="rotate(-180 304.514 405.656)" opacity="0.45" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="466.58904" x2="334.68701" y1="466.58904" x1="274.34022" fillOpacity="null" fill="none" stroke="#999999" transform="rotate(-180 304.514 466.589)" opacity="0.45" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="322.94212" x2="275.09749" y1="282.2436" x1="275.09749" fillOpacity="null" fill="none" stroke="#999999" transform="rotate(-180 275.097 302.593)" opacity="0.45" />
                    <line transform="rotate(-180 430.102 351.297)" stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="351.29676" x2="526.06525" y1="351.29676" x1="334.13954" fillOpacity="null" fill="none" opacity="0.45" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="325.23067" x2="526.49626" y1="325.23067" x1="466.14947" fillOpacity="null" fill="none" stroke="#999999" transform="rotate(-180 496.323 325.231)" opacity="0.45" />
                    <line stroke="#cccccc" fill="none" strokeWidth="1.5" x1="466.01747" y1="325.06997" x2="466.01747" y2="346.25286" strokeLinejoin="null" strokeLinecap="null" />
                    <rect height="183.75973" width="192" y="495.29256" x="274.67111" fill="#cccccc" stroke="#b2b2b2" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="679.47386" x2="334.01376" y1="495.53798" x1="334.01376" fillOpacity="null" fill="none" opacity="0.45" stroke="#999999" />
                    <rect height="101.68475" width="66.48642" y="495.53905" x="400.48786" fill="#cccccc" stroke="#b2b2b2" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="523.92812" x2="334.687" y1="523.92812" x1="274.34021" fillOpacity="null" fill="none" stroke="#999999" transform="rotate(-180 304.514 523.928)" opacity="0.45" />
                    <line transform="rotate(-180 304.514 602.4)" strokeLinecap="null" strokeLinejoin="null" y2="602.40054" x2="334.68701" y1="602.40054" x1="274.34022" fillOpacity="null" fill="none" stroke="#999999" opacity="0.45" />
                    <text fontWeight="bold" fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="475.98454" y="592.10576" fontSize="10" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.835067 0 0 0.834963 -105.969 19.181)" stroke="#cccccc" opacity="0.5">A.H.U</text>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="426.318" y="936.30822" fontSize="14" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 120.836 38.0867)" stroke="#cccccc" opacity="0.5" fontWeight="bold">ELECTRIC</text>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="430.67215" y="955.88357" fontSize="14" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 125.598 37.5607)" stroke="#cccccc" opacity="0.5" fontWeight="bold">ROOM</text>
                    <rect height="41.81851" width="22.07273" y="495.47759" x="378.46508" fill="#cccccc" stroke="#b2b2b2" />
                    <rect height="48.80197" width="43.86514" y="630.5211" x="422.86234" fill="#cccccc" stroke="#b2b2b2" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="638.19033" x2="422.82679" y1="597.49181" x1="422.82679" fillOpacity="null" fill="none" stroke="#999999" transform="rotate(-180 422.827 617.841)" opacity="0.45" />
                    <text fontWeight="bold" fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="454.99039" y="840.33641" fontSize="16" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 120.836 37.5607)" stroke="#cccccc" opacity="0.5">WASHROOM</text>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="582.29808" y="692.13586" fontSize="12" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="rotate(90 -13306 274694) matrix(0.779759 0 0 0.889744 -25.8889 -87.2663)" stroke="#cccccc" opacity="0.2">SHE</text>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="478.31165" y="689.59264" fontSize="12" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="rotate(90 -16617.1 287718) matrix(0.779759 0 0 0.889744 -25.8889 -87.2663)" stroke="#cccccc" opacity="0.2">HE</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="597.4697" x2="526.27227" y1="597.4697" x1="465.92548" fillOpacity="null" fill="none" stroke="#999999" transform="rotate(-180 496.099 597.47)" opacity="0.45" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="662.04011" x2="526.66753" y1="662.04011" x1="466.32074" fillOpacity="null" fill="none" stroke="#999999" transform="rotate(-180 496.494 662.04)" opacity="0.45" />
                    <rect height="120.91117" width="59.24914" y="597.93491" x="466.78226" fill="#cccccc" stroke="#b2b2b2" />
                    <rect height="39.25437" width="132.78626" y="679.21901" x="333.84736" fill="#e5e5e5" opacity="0.45" stroke="#999999" transform="rotate(0.062299 400.24 698.846)" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="718.71819" x2="378.27161" y1="678.01967" x1="378.27161" fillOpacity="null" fill="none" transform="rotate(-180 378.272 698.369)" stroke="#999999" opacity="0.5" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="718.71819" x2="422.87946" y1="678.01967" x1="422.87946" fillOpacity="null" fill="none" stroke="#999999" transform="rotate(-180 422.879 698.369)" opacity="0.4" />
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="400.87582" y="1041.0054" fontSize="12" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.846686 0 0 0.771825 11.0049 -102.369)" stroke="#cccccc" opacity="0.4" id="WS0L1_text" data-tip="" onClick={(e) => { seatSelected(e) }}>0L1</text>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="451.24524" y="1041.0054" fontSize="12" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.846686 0 0 0.771825 11.0049 -102.369)" stroke="#cccccc" opacity="0.4" id="WS0L2_text" data-tip="" onClick={(e) => { seatSelected(e) }}>0L2</text>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="503.9305" y="1039.73517" fontSize="12" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.846686 0 0 0.771825 11.0049 -102.369)" stroke="#cccccc" opacity="0.4" id="WS0L3_text" data-tip="" onClick={(e) => { seatSelected(e) }}>0L3</text>
                    <rect height="39.25437" width="132.91318" y="790.98381" x="333.84736" fill="#e5e5e5" opacity="0.45" stroke="#999999" transform="rotate(0.062299 400.304 810.611)" />
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="400.87582" y="1185.81115" fontSize="12" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.846686 0 0 0.771825 11.0049 -102.369)" stroke="#cccccc" opacity="0.4" id="WS0L6_text" data-tip="" onClick={(e) => { seatSelected(e) }}>0L6</text>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="451.24524" y="1185.81115" fontSize="12" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.846686 0 0 0.771825 11.0049 -102.369)" stroke="#cccccc" opacity="0.4" id="WS0L5_text" data-tip="" onClick={(e) => { seatSelected(e) }}>0L5</text>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="503.9305" y="1184.54092" fontSize="12" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.846686 0 0 0.771825 11.0049 -102.369)" stroke="#cccccc" opacity="0.4" id="WS0L4_text" data-tip="" onClick={(e) => { seatSelected(e) }}>0L4</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="831.11101" x2="378.27161" y1="790.41249" x1="378.27161" fillOpacity="null" fill="none" transform="rotate(-180 378.272 810.767)" stroke="#999999" opacity="0.5" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="831.11101" x2="422.87946" y1="790.41249" x1="422.87946" fillOpacity="null" fill="none" stroke="#999999" transform="rotate(-180 422.879 810.767)" opacity="0.5" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="791.3774" x2="333.82728" y1="717.34566" x1="333.82728" fillOpacity="null" fill="none" transform="rotate(-180 333.827 754.362)" opacity="0.5" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="791.3774" x2="466.73291" y1="717.34566" x1="466.73291" fillOpacity="null" fill="none" transform="rotate(-180 466.733 754.362)" opacity="0.5" stroke="#999999" />
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="482.69386" y="1116.16054" fontSize="16" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 120.403 37.5607)" stroke="#cccccc" opacity="0.5" fontWeight="bold">LIFT</text>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="470.08188" y="1141.52822" fontSize="16" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 120.403 37.5607)" stroke="#cccccc" opacity="0.5" fontWeight="bold">LOBBBY</text>
                    <rect stroke="#b2b2b2" height="157.67876" width="192.37808" y="655.00053" x="578.0703" fill="#cccccc" />
                    <line transform="rotate(-180 673.9 740.027)" stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="740.02729" x2="769.84922" y1="740.02729" x1="577.95138" fillOpacity="null" fill="none" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="739.81504" x2="687.98789" y1="654.20102" x1="687.98789" fillOpacity="null" fill="none" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="688.84265" x2="688.0413" y1="688.84265" x1="577.95141" fillOpacity="null" fill="none" />
                    <rect height="233.90174" width="191.75853" y="830.29254" x="274.67111" strokeWidth="1.5" fill="#cccccc" stroke="#b2b2b2" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1064.82953" x2="334.01376" y1="825.53798" x1="334.01376" fillOpacity="null" fill="none" opacity="0.45" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="888.2744" x2="334.06524" y1="888.2744" x1="273.71845" fillOpacity="null" fill="none" stroke="#999999" opacity="0.45" transform="rotate(-180 303.892 888.275)" />
                    <rect height="106.09979" width="132.24914" y="830.65618" x="334.09285" fill="#cccccc" stroke="#b2b2b2" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="902.98397" x2="334.66046" y1="902.98397" x1="274.31367" fillOpacity="null" fill="none" stroke="#999999" opacity="0.45" transform="rotate(-180 304.487 902.984)" />
                    <rect height="56.68895" width="59.34092" y="1007.77525" x="466.31355" fill="#cccccc" stroke="#b2b2b2" />
                    <rect height="71.5" width="58.23392" y="935.73502" x="467.05156" fill="#e5e5e5" strokeWidth="0" stroke="#b2b2b2" />
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="642.3031" y="1572.8845" fontSize="12" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 120.836 38.1669)" stroke="#cccccc" opacity="0.5" fontWeight="bold">AHU ROOM</text>
                    <rect stroke="#000" height="5.15461" width="0" y="845.51634" x="498.97322" fillOpacity="null" strokeOpacity="null" strokeWidth="1.5" fill="#fff" />
                    <rect height="127.56964" width="132.22352" y="936.50343" x="334.30324" fill="#cccccc" stroke="#b2b2b2" />
                    <rect stroke="#b2b2b2" height="127.88016" width="65.72696" y="936.50343" x="400.7998" fill="#cccccc" />
                    <rect height="41.81851" width="22.07273" y="1022.78206" x="378.46508" fill="#cccccc" stroke="#b2b2b2" />
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="454.99039" y="1455.02681" fontSize="16" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 120.836 37.5607)" stroke="#cccccc" opacity="0.5" fontWeight="bold">WASHROOM</text>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="521.35719" y="1272.90876" fontSize="12" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="rotate(90 2.97989e+13 2.70967e+13) matrix(0.779759 0 0 0.889744 -25.8889 -87.2663)" stroke="#cccccc" opacity="0.2">H.T.</text>
                    <rect stroke="#b2b2b2" height="132.89165" width="251.17864" y="1095.58065" x="274.67111" strokeWidth="1.5" fill="#cccccc" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1227.84173" x2="333.82673" y1="1096.46022" x1="333.82673" fillOpacity="null" fill="none" opacity="0.5" stroke="#999999" transform="rotate(-180 333.827 1162.15)" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1228.73015" x2="466.73291" y1="1095.35902" x1="466.73291" fillOpacity="null" fill="none" transform="rotate(-180 466.733 1162.04)" opacity="0.5" />
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="473.50796" y="1650.57751" fontSize="12" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.835067 0 0 0.834963 -23.3848 -175.991)" stroke="#cccccc" opacity="0.5" fontWeight="bold">STAIRCASE</text>
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1173.51528" x2="467.04769" y1="1173.51528" x1="333.55803" fillOpacity="null" fill="none" transform="rotate(-180 400.303 1173.52)" opacity="0.45" />
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="488.56235" y="1568.4524" fontSize="12" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.835067 0 0 0.834963 -20.9599 -173.566)" stroke="#cccccc" opacity="0.5" fontWeight="bold">A.H.U.</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1173.51528" x2="335.04771" y1="1173.51528" x1="274.70092" fillOpacity="null" fill="none" stroke="#999999" transform="rotate(-180 304.874 1173.52)" opacity="0.45" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1188.42198" x2="333.90489" y1="1188.42198" x1="274.70096" fillOpacity="null" fill="none" opacity="0.45" transform="rotate(-180 304.303 1188.43)" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1229.27636" x2="333.82728" y1="1197.02612" x1="333.82728" fillOpacity="null" fill="none" transform="rotate(-180 333.827 1213.15)" opacity="0.5" />
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="345.61295" y="1808.9523" fontSize="12" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="rotate(90 -70493.6 262730) matrix(0.557352 0 0 0.635674 120.836 37.5607)" stroke="#cccccc" opacity="0.2">EXIT</text>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="131.40341" y="2065.35402" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 123.897 85.513)" stroke="#cccccc" opacity="0.5" fontWeight="bold">MEETING ROOM</text>
                    <rect height="52.48516" width="59.24914" y="778.31085" x="274.72509" fill="#cccccc" stroke="#b2b2b2" />
                    <rect height="54.72072" width="59.24914" y="679.34175" x="274.72509" fill="#cccccc" stroke="#b2b2b2" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1300.46603" x2="177.41669" y1="1300.46603" x1="28.03079" fillOpacity="null" fill="none" />
                    <rect height="19.92463" width="35.71396" y="999.79731" x="493.36462" strokeWidth="1.5" stroke="#999999" fill="#999999" transform="rotate(90 1.08985e+8 2.94709e+8) matrix(0.557352 0 0 0.635674 120.836 37.5607)" />
                    <rect height="19.92463" width="35.71396" y="999.71769" x="464.18262" strokeWidth="1.5" stroke="#999999" fill="#999999" transform="rotate(90 1.26040e+8 2.94763e+8) matrix(0.557352 0 0 0.635674 120.836 37.5607)" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="138.14418" x2="223.88234" y1="138.14418" x1="27.67058" fillOpacity="null" fill="none" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="215.47662" x2="223.88234" y1="215.47662" x1="27.61741" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="245.18408" x2="223.31875" y1="214.69136" x1="223.31875" fillOpacity="null" fill="none" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="292.44624" x2="223.31875" y1="261.95352" x1="223.31875" fillOpacity="null" fill="none" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="167.85164" x2="223.31875" y1="137.35892" x1="223.31875" fillOpacity="null" fill="none" />
                    <rect id="WS241" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS241.status} height="19.92463" width="30" y="143.58353" x="188.27675" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS240" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS240.status} height="19.92463" width="30" y="143.58494" x="147.07911" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS238" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS238.status} height="19.92463" width="30" y="144.20482" x="73.83933" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS237" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS237.status} height="19.92463" width="30" y="143.95947" x="33.27468" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="167.84024" x2="68.62984" y1="138.11219" x1="68.62984" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="168.22617" x2="182.73905" y1="138.49811" x1="182.73905" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-139.85544" y="187.59386" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 119.519 38.0575)" stroke="#b2b2b2" fontWeight="normal" id="WS237_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS237.status}_text cpil4_css1`}>237</text>
                    <rect id="WS239" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS239.status} height="19.92463" width="30" y="143.60241" x="110.58633" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-68.51984" y="187.59386" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 120.121 38.0575)" stroke="#b2b2b2" fontWeight="normal" id="WS238_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS238.status}_text cpil4_css1`}>238</text>
                    <text fill="#000000" strokeWidth="0" x="-60.95394" y="187.59386" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 152.651 37.4551)" stroke="#b2b2b2" fontWeight="normal" id="WS239_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS239.status}_text cpil4_css1`}>239</text>
                    <text fill="#000000" strokeWidth="0" x="-60.95394" y="187.59386" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 188.193 37.4551)" stroke="#b2b2b2" fontWeight="normal" id="WS240_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS240.status}_text cpil4_css1`}>240</text>
                    <text fill="#000000" strokeWidth="0" x="-58.98169" y="187.59386" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 229.157 37.4551)" stroke="#b2b2b2" fontWeight="normal" id="WS241_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS241.status}_text cpil4_css1`}>241</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="292.80905" x2="223.88234" y1="292.80905" x1="27.61741" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="244.35768" x2="223.31875" y1="213.86496" x1="223.31875" fillOpacity="null" fill="none" />
                    <rect id="WS231" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS231.status} height="19.92463" width="30" y="220.08957" x="188.27675" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS230" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS230.status} height="19.92463" width="30" y="220.09098" x="147.07911" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS228" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS228.status} height="19.92463" width="30" y="220.71086" x="73.83933" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS227" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS227.status} height="19.92463" width="30" y="220.46551" x="33.27468" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="244.34628" x2="68.62984" y1="214.61823" x1="68.62984" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="244.73221" x2="182.73905" y1="215.00415" x1="182.73905" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-138.7746" y="186.64619" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 118.917 115.166)" stroke="#b2b2b2" fontWeight="normal" id="WS227_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS227.status}_text cpil4_css1`}>227</text>
                    <rect id="WS229" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS229.status} height="19.92463" width="30" y="220.10845" x="110.58633" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-67.439" y="186.64619" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 119.519 115.166)" stroke="#b2b2b2" fontWeight="normal" id="WS228_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS228.status}_text cpil4_css1`}>228</text>
                    <text fill="#000000" strokeWidth="0" x="-59.8731" y="186.64619" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 152.049 114.564)" stroke="#b2b2b2" fontWeight="normal" id="WS229_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS229.status}_text cpil4_css1`}>229</text>
                    <text fill="#000000" strokeWidth="0" x="-59.8731" y="186.64619" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 187.591 114.564)" stroke="#b2b2b2" fontWeight="normal" id="WS230_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS230.status}_text cpil4_css1`}>230</text>
                    <text fill="#000000" strokeWidth="0" x="-57.90085" y="186.64619" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 228.555 114.564)" stroke="#b2b2b2" fontWeight="normal" id="WS231_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS231.status}_text cpil4_css1`}>231</text>
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="292.3393" x2="223.31876" y1="261.84658" x1="223.31876" fillOpacity="null" fill="none" />
                    <rect id="WS222" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS222.status} height="19.92463" width="30" y="268.07119" x="188.27675" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS223" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS223.status} height="19.92463" width="30" y="268.0726" x="147.07911" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS225" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS225.status} height="19.92463" width="30" y="268.69248" x="73.83934" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS226" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS226.status} height="19.92463" width="30" y="268.44713" x="33.27469" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="292.3279" x2="68.62985" y1="262.59985" x1="68.62985" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="292.71383" x2="182.73905" y1="262.98577" x1="182.73905" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-356.02391" y="189.32312" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 240.001 160.949)" stroke="#b2b2b2" fontWeight="normal" id="WS226_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS226.status}_text cpil4_css1`}>226</text>
                    <rect id="WS224" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS224.status} height="19.92463" width="30" y="268.09007" x="110.58633" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-284.68831" y="189.32312" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 240.603 160.949)" stroke="#b2b2b2" fontWeight="normal" id="WS225_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS225.status}_text cpil4_css1`}>225</text>
                    <text fill="#000000" strokeWidth="0" x="-277.12241" y="189.32312" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 273.133 160.347)" stroke="#b2b2b2" fontWeight="normal" id="WS224_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS224.status}_text cpil4_css1`}>224</text>
                    <text fill="#000000" strokeWidth="0" x="-277.12241" y="189.32312" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 308.675 160.347)" stroke="#b2b2b2" fontWeight="normal" id="WS223_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS223.status}_text cpil4_css1`}>223</text>
                    <text fill="#000000" strokeWidth="0" x="-275.15016" y="189.32312" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 349.639 160.347)" stroke="#b2b2b2" fontWeight="normal" id="WS222_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS222.status}_text cpil4_css1`}>222</text>
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="214.8396" x2="223.31876" y1="184.34688" x1="223.31876" fillOpacity="null" fill="none" />
                    <rect id="WS232" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS232.status} height="19.92463" width="30" y="190.57149" x="188.27675" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS233" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS233.status} height="19.92463" width="30" y="190.5729" x="147.07911" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS235" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS235.status} height="19.92463" width="30" y="191.19278" x="73.83934" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS236" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS236.status} height="19.92463" width="30" y="190.94743" x="33.27469" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="214.8282" x2="68.62985" y1="185.10015" x1="68.62985" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="215.21413" x2="182.73905" y1="185.48607" x1="182.73905" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-356.21334" y="190.27079" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 240.603 83.8406)" stroke="#b2b2b2" fontWeight="normal" id="WS236_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS236.status}_text cpil4_css1`}>236</text>
                    <rect id="WS234" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS234.status} height="19.92463" width="30" y="190.59037" x="110.58633" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-284.87774" y="190.27079" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 241.205 83.8406)" stroke="#b2b2b2" fontWeight="normal" id="WS235_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS235.status}_text cpil4_css1`}>235</text>
                    <text fill="#000000" strokeWidth="0" x="-277.31185" y="190.27079" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 273.735 83.2382)" stroke="#b2b2b2" fontWeight="normal" id="WS234_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS234.status}_text cpil4_css1`}>234</text>
                    <text fill="#000000" strokeWidth="0" x="-277.31185" y="190.27079" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 309.277 83.2382)" stroke="#b2b2b2" fontWeight="normal" id="WS233_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS233.status}_text cpil4_css1`}>233</text>
                    <text fill="#000000" strokeWidth="0" x="-276.231" y="191.05238" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 350.738 82.7414)" stroke="#b2b2b2" fontWeight="normal" id="WS232_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS232.status}_text cpil4_css1`}>232</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="370.81165" x2="223.88234" y1="370.81165" x1="27.61741" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="322.36028" x2="223.31875" y1="291.86756" x1="223.31875" fillOpacity="null" fill="none" />
                    <rect id="WS221" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS221.status} height="19.92463" width="30" y="298.09217" x="188.27675" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS220" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS220.status} height="19.92463" width="30" y="298.09358" x="147.07911" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS218" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS218.status} height="19.92463" width="30" y="298.71346" x="73.83933" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS217" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS217.status} height="19.92463" width="30" y="298.46811" x="33.27468" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="322.34888" x2="68.62984" y1="292.62083" x1="68.62984" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="322.73481" x2="182.73905" y1="293.00675" x1="182.73905" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-138.7746" y="189.77252" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 118.917 190.684)" stroke="#b2b2b2" fontWeight="normal" id="WS217_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS217.status}_text cpil4_css1`}>217</text>
                    <rect id="WS219" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS219.status} height="19.92463" width="30" y="298.11105" x="110.58633" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-67.439" y="189.77252" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 119.519 190.684)" stroke="#b2b2b2" fontWeight="normal" id="WS218_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS218.status}_text cpil4_css1`}>218</text>
                    <text fill="#000000" strokeWidth="0" x="-59.8731" y="189.77252" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 152.049 190.082)" stroke="#b2b2b2" fontWeight="normal" id="WS219_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS219.status}_text cpil4_css1`}>219</text>
                    <text fill="#000000" strokeWidth="0" x="-59.8731" y="189.77252" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 187.591 190.082)" stroke="#b2b2b2" fontWeight="normal" id="WS220_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS220.status}_text cpil4_css1`}>220</text>
                    <text fill="#000000" strokeWidth="0" x="-57.90085" y="189.77252" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 228.555 190.082)" stroke="#b2b2b2" fontWeight="normal" id="WS221_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS221.status}_text cpil4_css1`}>221</text>
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="370.34191" x2="223.31876" y1="339.84919" x1="223.31876" fillOpacity="null" fill="none" />
                    <rect id="WS212" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS212.status} height="19.92463" width="30" y="346.0738" x="188.27675" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS213" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS213.status} height="19.92463" width="30" y="346.07521" x="147.07911" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS215" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS215.status} height="19.92463" width="30" y="346.69509" x="73.83934" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS216" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS216.status} height="19.92463" width="30" y="346.44974" x="33.27469" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="370.33051" x2="68.62985" y1="340.60246" x1="68.62985" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="370.71644" x2="182.73905" y1="340.98838" x1="182.73905" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-356.02391" y="193.23104" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 240.001 236.964)" stroke="#b2b2b2" fontWeight="normal" id="WS216_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS216.status}_text cpil4_css1`}>216</text>
                    <rect id="WS214" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS214.status} height="19.92463" width="30" y="346.09268" x="110.58633" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-284.68831" y="193.23104" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 240.603 236.964)" stroke="#b2b2b2" fontWeight="normal" id="WS215_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS215.status}_text cpil4_css1`}>215</text>
                    <text fill="#000000" strokeWidth="0" x="-277.12241" y="193.23104" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 273.133 236.362)" stroke="#b2b2b2" fontWeight="normal" id="WS214_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS214.status}_text cpil4_css1`}>214</text>
                    <text fill="#000000" strokeWidth="0" x="-277.12241" y="193.23104" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 308.675 236.362)" stroke="#b2b2b2" fontWeight="normal" id="WS213_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS213.status}_text cpil4_css1`}>213</text>
                    <text fill="#000000" strokeWidth="0" x="-275.15016" y="193.23104" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 349.639 236.362)" stroke="#b2b2b2" fontWeight="normal" id="WS212_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS212.status}_text cpil4_css1`}>212</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="448.81426" x2="223.88234" y1="448.81426" x1="27.61741" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="400.36289" x2="223.31875" y1="369.87017" x1="223.31875" fillOpacity="null" fill="none" />
                    <rect id="WS211" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS211.status} height="19.92463" width="30" y="376.09478" x="188.27675" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS210" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS210.status} height="19.92463" width="30" y="376.09619" x="147.07911" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS208" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS208.status} height="19.92463" width="30" y="376.71607" x="73.83933" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS207" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS207.status} height="19.92463" width="30" y="376.47072" x="33.27468" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="400.35149" x2="68.62984" y1="370.62344" x1="68.62984" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="400.73742" x2="182.73905" y1="371.00936" x1="182.73905" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-133.42611" y="196.02518" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 115.936 265.209)" stroke="#b2b2b2" fontWeight="normal" id="WS207_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS207.status}_text cpil4_css1`}>207</text>
                    <rect id="WS209" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS209.status} height="19.92463" width="30" y="376.11366" x="110.58633" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-62.09051" y="196.02518" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 116.538 265.209)" stroke="#b2b2b2" fontWeight="normal" id="WS208_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS208.status}_text cpil4_css1`}>208</text>
                    <text fill="#000000" strokeWidth="0" x="-54.52461" y="196.02518" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 149.068 264.607)" stroke="#b2b2b2" fontWeight="normal" id="WS209_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS209.status}_text cpil4_css1`}>209</text>
                    <text fill="#000000" strokeWidth="0" x="-54.52461" y="196.02518" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 184.61 264.607)" stroke="#b2b2b2" fontWeight="normal" id="WS210_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS210.status}_text cpil4_css1`}>210</text>
                    <text fill="#000000" strokeWidth="0" x="-52.55236" y="196.02518" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 225.574 264.607)" stroke="#b2b2b2" fontWeight="normal" id="WS211_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS211.status}_text cpil4_css1`}>211</text>
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="448.34451" x2="223.31876" y1="417.85179" x1="223.31876" fillOpacity="null" fill="none" />
                    <rect id="WS202" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS202.status} height="19.92463" width="30" y="424.0764" x="188.27675" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS203" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS203.status} height="19.92463" width="30" y="424.07781" x="147.07911" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS205" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS205.status} height="19.92463" width="30" y="424.69769" x="73.83934" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS206" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS206.status} height="19.92463" width="30" y="424.45234" x="33.27469" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="448.33311" x2="68.62985" y1="418.60506" x1="68.62985" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="448.71904" x2="182.73905" y1="418.99098" x1="182.73905" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-350.67542" y="198.70212" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 237.02 311.489)" stroke="#b2b2b2" fontWeight="normal" id="WS206_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS206.status}_text cpil4_css1`}>206</text>
                    <rect id="WS204" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS204.status} height="19.92463" width="30" y="424.09528" x="110.58633" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-279.33982" y="198.70212" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 237.622 311.489)" stroke="#b2b2b2" fontWeight="normal" id="WS205_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS205.status}_text cpil4_css1`}>205</text>
                    <text fill="#000000" strokeWidth="0" x="-271.77392" y="198.70212" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 270.152 310.887)" stroke="#b2b2b2" fontWeight="normal" id="WS204_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS204.status}_text cpil4_css1`}>204</text>
                    <text fill="#000000" strokeWidth="0" x="-271.77392" y="198.70212" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 305.694 310.887)" stroke="#b2b2b2" fontWeight="normal" id="WS203_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS203.status}_text cpil4_css1`}>203</text>
                    <text fill="#000000" strokeWidth="0" x="-269.80167" y="198.70212" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 346.658 310.887)" stroke="#b2b2b2" fontWeight="normal" id="WS202_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS202.status}_text cpil4_css1`}>202</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="526.32003" x2="223.88234" y1="526.32003" x1="27.61741" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="477.86866" x2="223.31875" y1="447.37594" x1="223.31875" fillOpacity="null" fill="none" />
                    <rect id="WS201" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS201.status} height="19.92463" width="30" y="453.60055" x="188.27675" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS200" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS200.status} height="19.92463" width="30" y="453.60196" x="147.07911" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS198" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS198.status} height="19.92463" width="30" y="454.22184" x="73.83933" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS197" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS197.status} height="19.92463" width="30" y="453.97649" x="33.27468" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="477.85726" x2="68.62984" y1="448.12921" x1="68.62984" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="478.24319" x2="182.73905" y1="448.51513" x1="182.73905" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-133.42611" y="195.2436" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 115.936 342.715)" stroke="#b2b2b2" fontWeight="normal" id="WS197_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS197.status}_text cpil4_css1`}>197</text>
                    <rect id="WS199" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS199.status} height="19.92463" width="30" y="453.61943" x="110.58633" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-62.09051" y="195.2436" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 116.538 342.715)" stroke="#b2b2b2" fontWeight="normal" id="WS198_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS198.status}_text cpil4_css1`}>198</text>
                    <text fill="#000000" strokeWidth="0" x="-54.52461" y="195.2436" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 149.068 342.113)" stroke="#b2b2b2" fontWeight="normal" id="WS199_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS199.status}_text cpil4_css1`}>199</text>
                    <text fill="#000000" strokeWidth="0" x="-54.52461" y="195.2436" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 184.61 342.113)" stroke="#b2b2b2" fontWeight="normal" id="WS200_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS200.status}_text cpil4_css1`}>200</text>
                    <text fill="#000000" strokeWidth="0" x="-52.55236" y="195.2436" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 225.574 342.113)" stroke="#b2b2b2" fontWeight="normal" id="WS201_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS201.status}_text cpil4_css1`}>201</text>
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="525.85028" x2="223.31876" y1="495.35756" x1="223.31876" fillOpacity="null" fill="none" />
                    <rect id="WS192" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS192.status} height="19.92463" width="30" y="501.58217" x="188.27675" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS193" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS193.status} height="19.92463" width="30" y="501.58358" x="147.07911" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS195" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS195.status} height="19.92463" width="30" y="502.20346" x="73.83934" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS196" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS196.status} height="19.92463" width="30" y="501.95811" x="33.27469" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="525.83888" x2="68.62985" y1="496.11083" x1="68.62985" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="526.22481" x2="182.73905" y1="496.49675" x1="182.73905" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-350.67542" y="198.70212" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 237.02 388.995)" stroke="#b2b2b2" fontWeight="normal" id="WS196_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS196.status}_text cpil4_css1`}>196</text>
                    <rect id="WS194" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS194.status} height="19.92463" width="30" y="501.60105" x="110.58633" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-279.33982" y="198.70212" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 237.622 388.995)" stroke="#b2b2b2" fontWeight="normal" id="WS195_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS195.status}_text cpil4_css1`}>195</text>
                    <text fill="#000000" strokeWidth="0" x="-271.77392" y="198.70212" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 270.152 388.393)" stroke="#b2b2b2" fontWeight="normal" id="WS194_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS194.status}_text cpil4_css1`}>194</text>
                    <text fill="#000000" strokeWidth="0" x="-271.77392" y="198.70212" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 305.694 388.393)" stroke="#b2b2b2" fontWeight="normal" id="WS193_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS193.status}_text cpil4_css1`}>193</text>
                    <text fill="#000000" strokeWidth="0" x="-269.80167" y="198.70212" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 346.658 388.393)" stroke="#b2b2b2" fontWeight="normal" id="WS192_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS192.status}_text cpil4_css1`}>192</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="573" x2="223.88234" y1="573" x1="27.61741" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="555.87127" x2="223.31875" y1="525.37855" x1="223.31875" fillOpacity="null" fill="none" />
                    <rect id="WS191" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS191.status} height="19.92463" width="30" y="531.60316" x="188.27675" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS190" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS190.status} height="19.92463" width="30" y="531.60457" x="147.07911" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS188" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS188.status} height="19.92463" width="30" y="532.22445" x="73.83933" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS187" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS187.status} height="19.92463" width="30" y="531.9791" x="33.27468" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="555.85987" x2="68.62984" y1="526.13182" x1="68.62984" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="556.2458" x2="182.73905" y1="526.51774" x1="182.73905" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-133.42611" y="196.80677" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 115.936 420.221)" stroke="#b2b2b2" fontWeight="normal" id="WS187_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS187.status}_text cpil4_css1`}>187</text>
                    <rect id="WS189" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS189.status} height="19.92463" width="30" y="531.62204" x="110.58633" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-62.09051" y="196.80677" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 116.538 420.221)" stroke="#b2b2b2" fontWeight="normal" id="WS188_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS188.status}_text cpil4_css1`}>188</text>
                    <text fill="#000000" strokeWidth="0" x="-54.52461" y="196.80677" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 149.068 419.619)" stroke="#b2b2b2" fontWeight="normal" id="WS189_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS189.status}_text cpil4_css1`}>189</text>
                    <text fill="#000000" strokeWidth="0" x="-54.52461" y="196.80677" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 184.61 419.619)" stroke="#b2b2b2" fontWeight="normal" id="WS190_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS190.status}_text cpil4_css1`}>190</text>
                    <text fill="#000000" strokeWidth="0" x="-52.55236" y="196.80677" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 225.574 419.619)" stroke="#b2b2b2" fontWeight="normal" id="WS191_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS191.status}_text cpil4_css1`}>191</text>
                    {/* <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="603.85289" x2="223.31876" y1="573.36017" x1="223.31876" fillOpacity="null" fill="none" />
                    <rect id="WS182" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS182.status} height="19.92463" width="30" y="579.58478" x="188.27675" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS183" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS183.status} height="19.92463" width="30" y="579.58619" x="147.07911" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS185" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS185.status} height="19.92463" width="30" y="580.20607" x="73.83934" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS186" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS186.status} height="19.92463" width="30" y="579.96072" x="33.27469" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="603.84149" x2="68.62985" y1="574.11344" x1="68.62985" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="604.22742" x2="182.73905" y1="574.49936" x1="182.73905" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-350.67542" y="199.4837" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 237.02 466.501)" stroke="#b2b2b2" fontWeight="normal" id="WS186_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS186.status}_text cpil4_css1`}>186</text>
                    <rect id="WS184" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS184.status} height="19.92463" width="30" y="579.60366" x="110.58633" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-279.33982" y="199.4837" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 237.622 466.501)" stroke="#b2b2b2" fontWeight="normal" id="WS185_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS185.status}_text cpil4_css1`}>185</text>
                    <text fill="#000000" strokeWidth="0" x="-271.77392" y="199.4837" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 270.152 465.899)" stroke="#b2b2b2" fontWeight="normal" id="WS184_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS184.status}_text cpil4_css1`}>184</text>
                    <text fill="#000000" strokeWidth="0" x="-271.77392" y="199.4837" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 305.694 465.899)" stroke="#b2b2b2" fontWeight="normal" id="WS183_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS183.status}_text cpil4_css1`}>183</text>
                    <text fill="#000000" strokeWidth="0" x="-269.80167" y="199.4837" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 346.658 465.899)" stroke="#b2b2b2" fontWeight="normal" id="WS182_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS182.status}_text cpil4_css1`}>182</text> */}
                    <line strokeLinecap="null" strokeLinejoin="null" y2="754.75749" x2="224.49565" y1="754.75749" x1="28.28389" fillOpacity="null" fill="none" stroke="#999999" /> 
                    <line strokeLinecap="null" strokeLinejoin="null" y2="832.08993" x2="224.49565" y1="832.08993" x1="28.23072" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="861.79739" x2="223.93206" y1="831.30467" x1="223.93206" fillOpacity="null" fill="none" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="909.05955" x2="223.93206" y1="878.56683" x1="223.93206" fillOpacity="null" fill="none" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="784.46495" x2="223.93206" y1="753.97223" x1="223.93206" fillOpacity="null" fill="none" />
                    <rect id="WS181" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS181.status} height="19.92463" width="30" y="760.19684" x="188.89006" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS180" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS180.status} height="19.92463" width="30" y="760.19825" x="147.69242" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS178" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS178.status} height="19.92463" width="30" y="760.81813" x="74.45264" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS177" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS177.status} height="19.92463" width="30" y="760.57278" x="33.88799" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="784.45355" x2="69.24315" y1="754.7255" x1="69.24315" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="784.83948" x2="183.35236" y1="755.11142" x1="183.35236" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-147.72603" y="578.6958" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 124.519 406.057)" stroke="#b2b2b2" fontWeight="normal" id="WS177_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS177.status}_text cpil4_css1`}>177</text>
                    <rect id="WS179" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS179.status} height="19.92463" width="30" y="760.21572" x="111.19964" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-76.39043" y="578.6958" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 125.121 406.057)" stroke="#b2b2b2" fontWeight="normal" id="WS178_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS178.status}_text cpil4_css1`}>178</text>
                    <text fill="#000000" strokeWidth="0" x="-68.82453" y="578.6958" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 157.651 405.455)" stroke="#b2b2b2" fontWeight="normal" id="WS179_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS179.status}_text cpil4_css1`}>179</text>
                    <text fill="#000000" strokeWidth="0" x="-68.82453" y="578.6958" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 193.193 405.455)" stroke="#b2b2b2" fontWeight="normal" id="WS180_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS180.status}_text cpil4_css1`}>180</text>
                    <text fill="#000000" strokeWidth="0" x="-66.85228" y="578.6958" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 234.157 405.455)" stroke="#b2b2b2" fontWeight="normal" id="WS181_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS181.status}_text cpil4_css1`}>181</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="909.42236" x2="224.49565" y1="909.42236" x1="28.23072" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="860.97099" x2="223.93206" y1="830.47827" x1="223.93206" fillOpacity="null" fill="none" />
                    <rect id="WS171" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS171.status} height="19.92463" width="30" y="836.70288" x="188.89006" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <path id="WS170" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS170.status} d="m147.69241,836.70422l30,0l0,19.92463l-30,0l0,-19.92463z" strokeWidth="0" stroke="#b2b2b2" fill="#999999" />
                    <rect id="WS168" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS168.status} height="19.92463" width="30" y="837.32417" x="74.45264" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS167" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS167.status} height="19.92463" width="30" y="837.07882" x="33.88799" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="860.95959" x2="69.24315" y1="831.23154" x1="69.24315" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="861.34552" x2="183.35236" y1="831.61746" x1="183.35236" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-146.64519" y="577.74813" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 123.917 483.166)" stroke="#b2b2b2" fontWeight="normal" id="WS167_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS167.status}_text cpil4_css1`}>167</text>
                    <rect id="WS169" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS169.status} height="19.92463" width="30" y="836.72176" x="111.19964" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-75.30959" y="577.74813" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 124.519 483.166)" stroke="#b2b2b2" fontWeight="normal" id="WS168_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS168.status}_text cpil4_css1`}>168</text>
                    <text fill="#000000" strokeWidth="0" x="-67.74369" y="577.74813" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 157.049 482.564)" stroke="#b2b2b2" fontWeight="normal" id="WS169_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS169.status}_text cpil4_css1`}>169</text>
                    <text fill="#000000" strokeWidth="0" x="-67.74369" y="577.74813" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 192.591 482.564)" stroke="#b2b2b2" fontWeight="normal" id="WS170_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS170.status}_text cpil4_css1`}>170</text>
                    <text fill="#000000" strokeWidth="0" x="-65.77144" y="577.74813" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 233.555 482.564)" stroke="#b2b2b2" fontWeight="normal" id="WS171_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS171.status}_text cpil4_css1`}>171</text>
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="908.95261" x2="223.93207" y1="878.45989" x1="223.93207" fillOpacity="null" fill="none" />
                    <rect id="WS162" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS162.status} height="19.92463" width="30" y="884.6845" x="188.89006" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS163" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS163.status} height="19.92463" width="30" y="884.68591" x="147.69242" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS165" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS165.status} height="19.92463" width="30" y="885.30579" x="74.45265" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS166" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS166.status} height="19.92463" width="30" y="885.06044" x="33.888" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="908.94121" x2="69.24316" y1="879.21316" x1="69.24316" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="909.32714" x2="183.35236" y1="879.59908" x1="183.35236" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-363.8945" y="580.42506" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 245.001 528.949)" stroke="#b2b2b2" fontWeight="normal" id="WS166_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS166.status}_text cpil4_css1`}>166</text>
                    <rect id="WS164" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS164.status} height="19.92463" width="30" y="884.70338" x="111.19964" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-292.5589" y="580.42506" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 245.603 528.949)" stroke="#b2b2b2" fontWeight="normal" id="WS165_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS165.status}_text cpil4_css1`}>165</text>
                    <text fill="#000000" strokeWidth="0" x="-284.993" y="580.42506" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 278.133 528.347)" stroke="#b2b2b2" fontWeight="normal" id="WS164_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS164.status}_text cpil4_css1`}>164</text>
                    <text fill="#000000" strokeWidth="0" x="-284.993" y="580.42506" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 313.675 528.347)" stroke="#b2b2b2" fontWeight="normal" id="WS163_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS163.status}_text cpil4_css1`}>163</text>
                    <text fill="#000000" strokeWidth="0" x="-283.02075" y="580.42506" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 354.639 528.347)" stroke="#b2b2b2" fontWeight="normal" id="WS162_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS162.status}_text cpil4_css1`}>162</text>
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="831.45291" x2="223.93207" y1="800.96019" x1="223.93207" fillOpacity="null" fill="none" />
                    <rect id="WS172" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS172.status} height="19.92463" width="30" y="807.1848" x="188.89006" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS173" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS173.status} height="19.92463" width="30" y="807.18621" x="147.69242" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS175" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS175.status} height="19.92463" width="30" y="807.80609" x="74.45265" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS176" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS176.status} height="19.92463" width="30" y="807.56074" x="33.888" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="831.44151" x2="69.24316" y1="801.71346" x1="69.24316" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="831.82744" x2="183.35236" y1="802.09938" x1="183.35236" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-364.08393" y="581.37273" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 245.603 451.841)" stroke="#b2b2b2" fontWeight="normal" id="WS176_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS176.status}_text cpil4_css1`}>176</text>
                    <rect id="WS174" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS174.status} height="19.92463" width="30" y="807.20368" x="111.19964" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-292.74833" y="581.37273" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 246.205 451.841)" stroke="#b2b2b2" fontWeight="normal" id="WS175_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS175.status}_text cpil4_css1`}>175</text>
                    <text fill="#000000" strokeWidth="0" x="-285.18244" y="581.37273" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 278.735 451.238)" stroke="#b2b2b2" fontWeight="normal" id="WS174_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS174.status}_text cpil4_css1`}>174</text>
                    <text fill="#000000" strokeWidth="0" x="-285.18244" y="581.37273" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 314.277 451.238)" stroke="#b2b2b2" fontWeight="normal" id="WS173_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS173.status}_text cpil4_css1`}>173</text>
                    <text fill="#000000" strokeWidth="0" x="-284.10159" y="582.15432" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 355.738 450.741)" stroke="#b2b2b2" fontWeight="normal" id="WS172_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS172.status}_text cpil4_css1`}>172</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="987.42496" x2="224.49565" y1="987.42496" x1="28.23072" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="938.97359" x2="223.93206" y1="908.48087" x1="223.93206" fillOpacity="null" fill="none" />
                    <rect id="WS159" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS159.status} height="19.92463" width="30" y="914.70548" x="188.89006" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS158" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS158.status} height="19.92463" width="30" y="914.70689" x="147.69242" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <path id="WS156" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS156.status} d="m74.45264,915.32678l30,0l0,19.92463l-30,0l0,-19.92463z" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS155" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS155.status} height="19.92463" width="30" y="915.08142" x="33.88799" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="938.96219" x2="69.24315" y1="909.23414" x1="69.24315" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="939.34812" x2="183.35236" y1="909.62006" x1="183.35236" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-146.64519" y="580.87446" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 123.917 558.684)" stroke="#b2b2b2" fontWeight="normal" id="WS155_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS155.status}_text cpil4_css1`}>155</text>
                    <rect id="WS157" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS157.status} height="19.92463" width="30" y="914.72436" x="111.19964" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-75.30959" y="580.87446" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 124.519 558.684)" stroke="#b2b2b2" fontWeight="normal" id="WS156_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS156.status}_text cpil4_css1`}>156</text>
                    <text fill="#000000" strokeWidth="0" x="-67.74369" y="580.87446" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 157.049 558.082)" stroke="#b2b2b2" fontWeight="normal" id="WS157_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS157.status}_text cpil4_css1`}>157</text>
                    <text fill="#000000" strokeWidth="0" x="-67.74369" y="580.87446" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 192.591 558.082)" stroke="#b2b2b2" fontWeight="normal" id="WS158_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS158.status}_text cpil4_css1`}>158</text>
                    <text fill="#000000" strokeWidth="0" x="-65.77144" y="580.87446" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 233.555 558.082)" stroke="#b2b2b2" fontWeight="normal" id="WS159_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS159.status}_text cpil4_css1`}>159</text>
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="986.95522" x2="223.93207" y1="956.4625" x1="223.93207" fillOpacity="null" fill="none" />
                    <rect id="WS150" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS150.status} height="19.92463" width="30" y="962.68711" x="188.89006" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS151" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS151.status} height="19.92463" width="30" y="962.68852" x="147.69242" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS153" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS153.status} height="19.92463" width="30" y="963.3084" x="74.45265" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS154" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS154.status} height="19.92463" width="30" y="963.06305" x="33.888" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="986.94382" x2="69.24316" y1="957.21577" x1="69.24316" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="987.32975" x2="183.35236" y1="957.60169" x1="183.35236" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-363.8945" y="584.33298" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 245.001 604.964)" stroke="#b2b2b2" fontWeight="normal" id="WS154_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS154.status}_text cpil4_css1`}>154</text>
                    <rect id="WS152" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS152.status} height="19.92463" width="30" y="962.70599" x="111.19964" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-292.5589" y="584.33298" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 245.603 604.964)" stroke="#b2b2b2" fontWeight="normal" id="WS153_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS153.status}_text cpil4_css1`}>153</text>
                    <text fill="#000000" strokeWidth="0" x="-284.993" y="584.33298" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 278.133 604.362)" stroke="#b2b2b2" fontWeight="normal" id="WS152_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS152.status}_text cpil4_css1`}>152</text>
                    <text fill="#000000" strokeWidth="0" x="-284.993" y="584.33298" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 313.675 604.362)" stroke="#b2b2b2" fontWeight="normal" id="WS151_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS151.status}_text cpil4_css1`}>151</text>
                    <text fill="#000000" strokeWidth="0" x="-283.02075" y="584.33298" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 354.639 604.362)" stroke="#b2b2b2" fontWeight="normal" id="WS150_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS150.status}_text cpil4_css1`}>150</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1065.42757" x2="224.49565" y1="1065.42757" x1="28.23072" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1016.9762" x2="223.93206" y1="986.48348" x1="223.93206" fillOpacity="null" fill="none" />
                    <rect id="WS149" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS149.status} height="19.92463" width="30" y="992.70809" x="188.89006" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS148" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS148.status} height="19.92463" width="30" y="992.7095" x="147.69242" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS146" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS146.status} height="19.92463" width="30" y="993.32938" x="74.45264" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS145" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS145.status} height="19.92463" width="30" y="993.08403" x="33.88799" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1016.9648" x2="69.24315" y1="987.23675" x1="69.24315" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1017.35073" x2="183.35236" y1="987.62267" x1="183.35236" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-141.2967" y="587.12712" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 120.936 633.209)" stroke="#b2b2b2" fontWeight="normal" id="WS145_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS145.status}_text cpil4_css1`}>145</text>
                    <rect id="WS147" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS147.status} height="19.92463" width="30" y="992.72697" x="111.19964" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-69.9611" y="587.12712" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 121.538 633.209)" stroke="#b2b2b2" fontWeight="normal" id="WS146_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS146.status}_text cpil4_css1`}>146</text>
                    <text fill="#000000" strokeWidth="0" x="-62.3952" y="587.12712" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 154.068 632.607)" stroke="#b2b2b2" fontWeight="normal" id="WS147_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS147.status}_text cpil4_css1`}>147</text>
                    <text fill="#000000" strokeWidth="0" x="-62.3952" y="587.12712" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 189.61 632.607)" stroke="#b2b2b2" fontWeight="normal" id="WS148_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS148.status}_text cpil4_css1`}>148</text>
                    <text fill="#000000" strokeWidth="0" x="-60.42295" y="587.12712" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 230.574 632.607)" stroke="#b2b2b2" fontWeight="normal" id="WS149_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS149.status}_text cpil4_css1`}>149</text>
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1064.95782" x2="223.93207" y1="1034.4651" x1="223.93207" fillOpacity="null" fill="none" />
                    <rect id="WS140" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS140.status} height="19.92463" width="30" y="1040.68971" x="188.89006" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS141" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS141.status} height="19.92463" width="30" y="1040.69112" x="147.69242" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS143" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS143.status} height="19.92463" width="30" y="1041.311" x="74.45265" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS144" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS144.status} height="19.92463" width="30" y="1041.06565" x="33.888" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1064.94642" x2="69.24316" y1="1035.21837" x1="69.24316" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1065.33235" x2="183.35236" y1="1035.60429" x1="183.35236" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-358.54601" y="589.80406" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 242.02 679.489)" stroke="#b2b2b2" fontWeight="normal" id="WS144_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS144.status}_text cpil4_css1`}>144</text>
                    <rect id="WS142" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS142.status} height="19.92463" width="30" y="1040.70859" x="111.19964" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-287.21041" y="589.80406" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 242.622 679.489)" stroke="#b2b2b2" fontWeight="normal" id="WS143_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS143.status}_text cpil4_css1`}>143</text>
                    <text fill="#000000" strokeWidth="0" x="-279.64451" y="589.80406" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 275.152 678.887)" stroke="#b2b2b2" fontWeight="normal" id="WS142_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS142.status}_text cpil4_css1`}>142</text>
                    <text fill="#000000" strokeWidth="0" x="-279.64451" y="589.80406" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 310.694 678.887)" stroke="#b2b2b2" fontWeight="normal" id="WS141_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS141.status}_text cpil4_css1`}>141</text>
                    <text fill="#000000" strokeWidth="0" x="-277.67226" y="589.80406" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 351.658 678.887)" stroke="#b2b2b2" fontWeight="normal" id="WS140_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS140.status}_text cpil4_css1`}>140</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1142.93334" x2="224.49565" y1="1142.93334" x1="28.23072" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1094.48197" x2="223.93206" y1="1063.98925" x1="223.93206" fillOpacity="null" fill="none" />
                    <rect id="WS139" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS139.status} height="19.92463" width="30" y="1070.21386" x="188.89006" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS138" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS138.status} height="19.92463" width="30" y="1070.21527" x="147.69242" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS136" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS136.status} height="19.92463" width="30" y="1070.83515" x="74.45264" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS135" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS135.status} height="19.92463" width="30" y="1070.5898" x="33.88799" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1094.47057" x2="69.24315" y1="1064.74252" x1="69.24315" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1094.8565" x2="183.35236" y1="1065.12844" x1="183.35236" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-141.2967" y="586.34554" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 120.936 710.715)" stroke="#b2b2b2" fontWeight="normal" id="WS135_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS135.status}_text cpil4_css1`}>135</text>
                    <rect id="WS137" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS137.status} height="19.92463" width="30" y="1070.23274" x="111.19964" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-69.9611" y="586.34554" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 121.538 710.715)" stroke="#b2b2b2" fontWeight="normal" id="WS136_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS136.status}_text cpil4_css1`}>136</text>
                    <text fill="#000000" strokeWidth="0" x="-62.3952" y="586.34554" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 154.068 710.113)" stroke="#b2b2b2" fontWeight="normal" id="WS137_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS137.status}_text cpil4_css1`}>137</text>
                    <text fill="#000000" strokeWidth="0" x="-62.3952" y="586.34554" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 189.61 710.113)" stroke="#b2b2b2" fontWeight="normal" id="WS138_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS138.status}_text cpil4_css1`}>138</text>
                    <text fill="#000000" strokeWidth="0" x="-60.42295" y="586.34554" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 230.574 710.113)" stroke="#b2b2b2" fontWeight="normal" id="WS139_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS139.status}_text cpil4_css1`}>139</text>
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1142.46359" x2="223.93207" y1="1111.97087" x1="223.93207" fillOpacity="null" fill="none" />
                    <path id="WS130" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS130.status} d="m188.89006,1118.19556l30,0l0,19.92463l-30,0l0,-19.92463z" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS131" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS131.status} height="19.92463" width="30" y="1118.19689" x="147.69242" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS133" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS133.status} height="19.92463" width="30" y="1118.81677" x="74.45265" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS134" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS134.status} height="19.92463" width="30" y="1118.57142" x="33.888" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1142.45219" x2="69.24316" y1="1112.72414" x1="69.24316" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1142.83812" x2="183.35236" y1="1113.11006" x1="183.35236" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-358.54601" y="589.80406" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 242.02 756.995)" stroke="#b2b2b2" fontWeight="normal" id="WS134_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS134.status}_text cpil4_css1`}>134</text>
                    <rect id="WS132" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS132.status} height="19.92463" width="30" y="1118.21436" x="111.19964" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-287.21041" y="589.80406" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 242.622 756.995)" stroke="#b2b2b2" fontWeight="normal" id="WS133_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS133.status}_text cpil4_css1`}>133</text>
                    <text fill="#000000" strokeWidth="0" x="-279.64451" y="589.80406" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 275.152 756.393)" stroke="#b2b2b2" fontWeight="normal" id="WS132_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS132.status}_text cpil4_css1`}>132</text>
                    <text fill="#000000" strokeWidth="0" x="-279.64451" y="589.80406" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 310.694 756.393)" stroke="#b2b2b2" fontWeight="normal" id="WS131_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS131.status}_text cpil4_css1`}>131</text>
                    <text fill="#000000" strokeWidth="0" x="-277.67226" y="589.80406" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 351.658 756.393)" stroke="#b2b2b2" fontWeight="normal" id="WS130_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS130.status}_text cpil4_css1`}>130</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1220.93595" x2="224.49565" y1="1220.93595" x1="28.23072" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1172.48458" x2="223.93206" y1="1141.99186" x1="223.93206" fillOpacity="null" fill="none" />
                    <rect id="WS129" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS129.status} height="19.92463" width="30" y="1148.21647" x="188.89006" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS128" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS128.status} height="19.92463" width="30" y="1148.21788" x="147.69242" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS126" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS126.status} height="19.92463" width="30" y="1148.83776" x="74.45264" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS125" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS125.status} height="19.92463" width="30" y="1148.59241" x="33.88799" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1172.47318" x2="69.24315" y1="1142.74513" x1="69.24315" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1172.85911" x2="183.35236" y1="1143.13105" x1="183.35236" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-141.2967" y="587.90871" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 120.936 788.221)" stroke="#b2b2b2" fontWeight="normal" id="WS125_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS125.status}_text cpil4_css1`}>125</text>
                    <rect id="WS127" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS127.status} height="19.92463" width="30" y="1148.23535" x="111.19964" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-69.9611" y="587.90871" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 121.538 788.221)" stroke="#b2b2b2" fontWeight="normal" id="WS126_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS126.status}_text cpil4_css1`}>126</text>
                    <text fill="#000000" strokeWidth="0" x="-62.3952" y="587.90871" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 154.068 787.619)" stroke="#b2b2b2" fontWeight="normal" id="WS127_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS127.status}_text cpil4_css1`}>127</text>
                    <text fill="#000000" strokeWidth="0" x="-62.3952" y="587.90871" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 189.61 787.619)" stroke="#b2b2b2" fontWeight="normal" id="WS128_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS128.status}_text cpil4_css1`}>128</text>
                    <text fill="#000000" strokeWidth="0" x="-60.42295" y="587.90871" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 230.574 787.619)" stroke="#b2b2b2" fontWeight="normal" id="WS129_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS129.status}_text cpil4_css1`}>129</text>
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1220.4662" x2="223.93207" y1="1189.97348" x1="223.93207" fillOpacity="null" fill="none" />
                    <rect id="WS120" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS120.status} height="19.92463" width="30" y="1196.19809" x="188.89006" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS121" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS121.status} height="19.92463" width="30" y="1196.1995" x="147.69242" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS123" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS123.status} height="19.92463" width="30" y="1196.81938" x="74.45265" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS124" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS124.status} height="19.92463" width="30" y="1196.57403" x="33.888" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1220.4548" x2="69.24316" y1="1190.72675" x1="69.24316" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1220.84073" x2="183.35236" y1="1191.11267" x1="183.35236" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-358.54601" y="590.58564" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 242.02 834.501)" stroke="#b2b2b2" fontWeight="normal" id="WS124_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS124.status}_text cpil4_css1`}>124</text>
                    <rect id="WS122" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS122.status} height="19.92463" width="30" y="1196.21697" x="111.19964" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-287.21041" y="590.58564" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 242.622 834.501)" stroke="#b2b2b2" fontWeight="normal" id="WS123_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS123.status}_text cpil4_css1`}>123</text>
                    <text fill="#000000" strokeWidth="0" x="-279.64451" y="590.58564" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 275.152 833.899)" stroke="#b2b2b2" fontWeight="normal" id="WS122_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS122.status}_text cpil4_css1`}>122</text>
                    <text fill="#000000" strokeWidth="0" x="-279.64451" y="590.58564" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 310.694 833.899)" stroke="#b2b2b2" fontWeight="normal" id="WS121_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS121.status}_text cpil4_css1`}>121</text>
                    <text fill="#000000" strokeWidth="0" x="-277.67226" y="590.58564" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 351.658 833.899)" stroke="#b2b2b2" fontWeight="normal" id="WS120_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS120.status}_text cpil4_css1`}>120</text>
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1249.76171" x2="223.93206" y1="1219.26899" x1="223.93206" fillOpacity="null" fill="none" />
                    <rect id="WS119" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS119.status} height="19.92463" width="30" y="1225.4936" x="188.89006" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS118" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS118.status} height="19.92463" width="30" y="1225.49501" x="147.69242" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS116" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS116.status} height="19.92463" width="30" y="1226.11489" x="74.45264" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS115" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS115.status} height="19.92463" width="30" y="1225.86954" x="33.88799" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1249.75031" x2="69.24315" y1="1220.02226" x1="69.24315" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1250.13624" x2="183.35236" y1="1220.40818" x1="183.35236" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-142.3971" y="587.90871" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 121.549 865.498)" stroke="#b2b2b2" fontWeight="normal" id="WS115_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS115.status}_text cpil4_css1`}>115</text>
                    <rect id="WS117" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS117.status} height="19.92463" width="30" y="1225.51248" x="111.19964" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-71.0615" y="587.90871" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 122.151 865.498)" stroke="#b2b2b2" fontWeight="normal" id="WS116_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS116.status}_text cpil4_css1`}>116</text>
                    <text fill="#000000" strokeWidth="0" x="-63.4956" y="587.90871" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 154.681 864.896)" stroke="#b2b2b2" fontWeight="normal" id="WS117_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS117.status}_text cpil4_css1`}>117</text>
                    <text fill="#000000" strokeWidth="0" x="-63.4956" y="587.90871" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 190.223 864.896)" stroke="#b2b2b2" fontWeight="normal" id="WS118_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS118.status}_text cpil4_css1`}>118</text>
                    <text fill="#000000" strokeWidth="0" x="-61.52335" y="587.90871" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 231.187 864.896)" stroke="#b2b2b2" fontWeight="normal" id="WS119_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS119.status}_text cpil4_css1`}>119</text>
                    <rect id="WS111" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS111.status} height="19.92463" width="30" y="1273.47663" x="147.69242" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS113" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS113.status} height="19.92463" width="30" y="1274.09651" x="74.45265" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS114" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS114.status} height="19.92463" width="30" y="1273.85116" x="33.888" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1299.57186" x2="69.24316" y1="1269.84381" x1="69.24316" fillOpacity="null" fill="none" stroke="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-359.64641" y="590.58564" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 242.633 911.778)" stroke="#b2b2b2" fontWeight="normal" id="WS114_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS114.status}_text cpil4_css1`}>114</text>
                    <rect id="WS112" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS112.status} height="19.92463" width="30" y="1273.4941" x="111.19964" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-288.31081" y="590.58564" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 243.235 911.778)" stroke="#b2b2b2" fontWeight="normal" id="WS113_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS113.status}_text cpil4_css1`}>113</text>
                    <text fill="#000000" strokeWidth="0" x="-280.74491" y="590.58564" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 275.765 911.176)" stroke="#b2b2b2" fontWeight="normal" id="WS112_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS112.status}_text cpil4_css1`}>112</text>
                    <text fill="#000000" strokeWidth="0" x="-280.74491" y="590.58564" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 311.307 911.176)" stroke="#b2b2b2" fontWeight="normal" id="WS111_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS111.status}_text cpil4_css1`}>111</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1301.65433" x2="183.35236" y1="1269.47303" x1="183.35236" fillOpacity="null" fill="none" stroke="#999999" />
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="176.01955" y="2068.54333" fontSize="23" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.612651 0 0 0.698368 113.202 -27.8949)" stroke="#cccccc" opacity="0.5" fontWeight="bold">8A</text>
                    {/* <text fill="#000000" strokeWidth="0" x="-57.09323" y="584.02443" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 331.804 945.143)" stroke="#b2b2b2" fontWeight="normal" id="WS241_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS241.status}_text cpil4_css1`}>241</text> */}
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1430.05575" x2="282.82043" y1="1266.99466" x1="282.82043" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS106" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS106.status} height="19.92463" width="30" y="1271.50677" x="288.0195" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-279.23432" y="582.81707" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 451.658 914.146)" stroke="#b2b2b2" fontWeight="normal" id="WS106_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS106.status}_text cpil4_css1`}>106</text>
                    <rect id="WS107" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS107.status} height="19.92463" width="30" y="1296.48129" x="288.0195" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-63.08541" y="573.34265" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 331.187 945.143)" stroke="#b2b2b2" fontWeight="normal" id="WS107_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS107.status}_text cpil4_css1`}>107</text>
                    <rect id="WS108" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS108.status} height="19.92463" width="30" y="1325.82779" x="288.0195" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-280.34185" y="586.70136" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 452.275 965.998)" stroke="#b2b2b2" fontWeight="normal" id="WS108_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS108.status}_text cpil4_css1`}>108</text>
                    <rect id="WS109" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS109.status} height="19.92463" width="30" y="1350.80231" x="288.0195" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-64.19294" y="577.22693" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 331.804 996.995)" stroke="#b2b2b2" fontWeight="normal" id="WS109_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS109.status}_text cpil4_css1`}>109</text>
                    <rect id="WS110" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS110.status} height="19.92463" width="30" y="1381.38338" x="288.0195" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-279.23432" y="592.52778" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 451.658 1017.85)" stroke="#b2b2b2" fontWeight="normal" id="WS110_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS110.status}_text cpil4_css1`}>110</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1321.0945" x2="319.6294" y1="1321.0945" x1="283.30073" fillOpacity="null" fill="none" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1267.48175" x2="319.17087" y1="1267.48175" x1="283.30073" fillOpacity="null" fill="none" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1375.82934" x2="319.6294" y1="1375.82934" x1="283.30073" fillOpacity="null" fill="none" stroke="#999999" />
                    <path id="WS105" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS105.status} d="m330.64954,1271.50684l30,0l0,19.92463l-30,0l0,-19.92463z" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-291.50996" y="583.64501" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 501.13 913.62)" stroke="#b2b2b2" fontWeight="normal" id="WS105_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS105.status}_text cpil4_css1`}>105</text>
                    <rect id="WS104" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS104.status} height="19.92463" width="30" y="1296.48129" x="330.64952" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-75.36105" y="574.17058" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 380.659 944.617)" stroke="#b2b2b2" fontWeight="normal" id="WS104_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS104.status}_text cpil4_css1`}>104</text>
                    <rect id="WS103" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS103.status} height="19.92463" width="30" y="1325.82779" x="330.64952" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-292.61749" y="587.52929" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 501.747 965.472)" stroke="#b2b2b2" fontWeight="normal" id="WS103_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS103.status}_text cpil4_css1`}>103</text>
                    <rect id="WS102" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS102.status} height="19.92463" width="30" y="1350.80231" x="330.64952" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-76.46858" y="578.05487" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 381.276 996.469)" stroke="#b2b2b2" fontWeight="normal" id="WS102_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS102.status}_text cpil4_css1`}>102</text>
                    <rect id="WS101" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS101.status} height="19.92463" width="30" y="1381.38338" x="330.64952" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-291.50996" y="593.35571" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 501.13 1017.32)" stroke="#b2b2b2" fontWeight="normal" id="WS101_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS101.status}_text cpil4_css1`}>101</text>
                    <rect id="WS100" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS100.status} height="19.92463" width="30" y="1406.3579" x="330.64952" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-75.36105" y="583.88129" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 380.659 1048.32)" stroke="#b2b2b2" fontWeight="normal" id="WS100_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS100.status}_text cpil4_css1`}>100</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1321.09449" x2="366.84466" y1="1321.09449" x1="328.6819" fillOpacity="null" fill="none" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1267.48175" x2="366.84466" y1="1267.48175" x1="328.6819" fillOpacity="null" fill="none" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1375.82934" x2="366.84466" y1="1375.82934" x1="328.6819" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS095" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS095.status} height="19.92463" width="30" y="1271.50677" x="369.59546" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-299.0642" y="581.98914" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 544.286 914.673)" stroke="#b2b2b2" fontWeight="normal" id="WS095_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS095.status}_text cpil4_css1`}>095</text>
                    <rect id="WS096" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS096.status} height="19.92463" width="30" y="1296.48129" x="369.59546" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-82.9153" y="572.51471" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 423.815 945.67)" stroke="#b2b2b2" fontWeight="normal" id="WS096_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS096.status}_text cpil4_css1`}>096</text>
                    <rect id="WS097" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS097.status} height="19.92463" width="30" y="1325.82779" x="369.59546" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-300.17174" y="585.87342" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 544.903 966.525)" stroke="#b2b2b2" fontWeight="normal" id="WS097_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS097.status}_text cpil4_css1`}>097</text>
                    <rect id="WS098" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS098.status} height="19.92463" width="30" y="1350.80231" x="369.59546" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-84.02283" y="576.399" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 424.432 997.522)" stroke="#b2b2b2" fontWeight="normal" id="WS098_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS098.status}_text cpil4_css1`}>098</text>
                    <rect id="WS099" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS099.status} height="19.92463" width="30" y="1381.38338" x="369.59546" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-299.0642" y="591.69985" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 544.286 1018.37)" stroke="#b2b2b2" fontWeight="normal" id="WS099_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS099.status}_text cpil4_css1`}>099</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1321.0945" x2="401.20536" y1="1321.0945" x1="363.0426" fillOpacity="null" fill="none" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1267.48175" x2="400.74683" y1="1267.48175" x1="362.58407" fillOpacity="null" fill="none" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1375.82934" x2="400.74683" y1="1375.82934" x1="362.58407" fillOpacity="null" fill="none" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1430.05575" x2="364.92269" y1="1266.99466" x1="364.92269" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS094" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS094.status} height="19.92463" width="30" y="1271.50677" x="412.75178" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-304.72989" y="583.64501" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 590.6 913.62)" stroke="#b2b2b2" fontWeight="normal" id="WS094_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS094.status}_text cpil4_css1`}>094</text>
                    <rect id="WS093" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS093.status} height="19.92463" width="30" y="1296.48129" x="412.75178" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-88.58098" y="574.17058" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 470.129 944.617)" stroke="#b2b2b2" fontWeight="normal" id="WS093_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS093.status}_text cpil4_css1`}>093</text>
                    <rect id="WS092" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS092.status} height="19.92463" width="30" y="1325.82779" x="412.75178" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-305.83742" y="587.52929" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 591.217 965.472)" stroke="#b2b2b2" fontWeight="normal" id="WS092_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS092.status}_text cpil4_css1`}>092</text>
                    <rect id="WS091" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS091.status} height="19.92463" width="30" y="1350.80231" x="412.75178" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-89.68851" y="578.05487" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 470.746 996.469)" stroke="#b2b2b2" fontWeight="normal" id="WS091_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS091.status}_text cpil4_css1`}>091</text>
                    <rect id="WS090" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS090.status} height="19.92463" width="30" y="1381.38338" x="412.75178" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-304.72989" y="593.35571" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 590.6 1017.32)" stroke="#b2b2b2" fontWeight="normal" id="WS090_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS090.status}_text cpil4_css1`}>090</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1321.09449" x2="449.86398" y1="1321.09449" x1="411.70121" fillOpacity="null" fill="none" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1267.48175" x2="449.40545" y1="1267.48175" x1="411.24269" fillOpacity="null" fill="none" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1375.82934" x2="449.86398" y1="1375.82934" x1="411.70121" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS085" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS085.status} height="19.92463" width="30" y="1271.50677" x="451.69772" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-312.28413" y="581.98914" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 633.756 914.673)" stroke="#b2b2b2" fontWeight="normal" id="WS085_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS085.status}_text cpil4_css1`}>085</text>
                    <rect id="WS086" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS086.status} height="19.92463" width="30" y="1296.48129" x="451.69772" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-96.13522" y="572.51471" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 513.285 945.67)" stroke="#b2b2b2" fontWeight="normal" id="WS086_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS086.status}_text cpil4_css1`}>086</text>
                    <rect id="WS087" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS087.status} height="19.92463" width="30" y="1325.82779" x="451.69772" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-313.39166" y="585.87342" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 634.373 966.525)" stroke="#b2b2b2" fontWeight="normal" id="WS087_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS087.status}_text cpil4_css1`}>087</text>
                    <path id="WS088" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS088.status} d="m451.69772,1350.80237l30,0l0,19.92463l-30,0l0,-19.92463z" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-97.24275" y="576.399" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 513.902 997.522)" stroke="#b2b2b2" fontWeight="normal" id="WS088_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS088.status}_text cpil4_css1`}>088</text>
                    <path id="WS089" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS089.status} d="m451.69772,1381.38342l30,0l0,19.92463l-30,0l0,-19.92463z" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-312.28413" y="591.69985" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 633.756 1018.37)" stroke="#b2b2b2" fontWeight="normal" id="WS089_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS089.status}_text cpil4_css1`}>089</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1321.0945" x2="483.30762" y1="1321.0945" x1="445.14486" fillOpacity="null" fill="none" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1267.48175" x2="482.8491" y1="1267.48175" x1="444.68634" fillOpacity="null" fill="none" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1375.82934" x2="482.8491" y1="1375.82934" x1="444.68634" fillOpacity="null" fill="none" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1404.83689" x2="447.02496" y1="1266.99466" x1="447.02496" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS084" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS084.status} height="19.92463" width="30" y="1271.50677" x="494.32774" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-281.12288" y="579.50534" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 659.019 916.251)" stroke="#b2b2b2" fontWeight="normal" id="WS084_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS084.status}_text cpil4_css1`}>084</text>
                    <rect id="WS083" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS083.status} height="19.92463" width="30" y="1296.48129" x="494.32774" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-64.97397" y="570.03091" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 538.548 947.248)" stroke="#b2b2b2" fontWeight="normal" id="WS083_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS083.status}_text cpil4_css1`}>083</text>
                    <rect id="WS082" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS082.status} height="19.92463" width="30" y="1325.82779" x="494.32774" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-282.23041" y="583.38962" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 659.636 968.103)" stroke="#b2b2b2" fontWeight="normal" id="WS082_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS082.status}_text cpil4_css1`}>082</text>
                    <rect id="WS081" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS081.status} height="19.92463" width="30" y="1350.80231" x="494.32774" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-66.0815" y="573.91519" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 539.165 999.1)" stroke="#b2b2b2" fontWeight="normal" id="WS081_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS081.status}_text cpil4_css1`}>081</text>
                    <rect id="WS080" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS080.status} height="19.92463" width="30" y="1381.38338" x="494.32774" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-281.12288" y="589.21604" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 659.019 1019.95)" stroke="#b2b2b2" fontWeight="normal" id="WS080_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS080.status}_text cpil4_css1`}>080</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1321.09449" x2="528.23027" y1="1321.09449" x1="493.27718" fillOpacity="null" fill="none" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1267.48175" x2="529.14731" y1="1267.48175" x1="492.36013" fillOpacity="null" fill="none" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1375.82934" x2="527.77174" y1="1375.82934" x1="492.81865" fillOpacity="null" fill="none" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1405.29541" x2="528.60092" y1="1266.99466" x1="528.60092" fillOpacity="null" fill="none" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="868.59988" x2="773.88086" y1="868.59988" x1="577.61593" fillOpacity="null" fill="none" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="945.93231" x2="773.88086" y1="945.93231" x1="577.61593" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS010" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS010.status} height="19.92463" width="30" y="873.21283" x="738.27527" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS009" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS009.status} height="19.92463" width="30" y="873.21424" x="697.07763" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS007" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS007.status} height="19.92463" width="30" y="873.83412" x="623.83785" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS006" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS006.status} height="19.92463" width="30" y="873.58877" x="583.2732" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="897.46954" x2="618.62836" y1="867.74149" x1="618.62836" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="897.85547" x2="732.73757" y1="868.12741" x1="732.73757" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="468.16733" y="1032.20322" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 330.635 230.791)" stroke="#b2b2b2" fontWeight="normal" id="WS006_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS006.status}_text cpil4_css1`}>006</text>
                    <rect id="WS008" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS008.status} height="19.92463" width="30" y="873.23171" x="660.58485" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="539.50293" y="1032.20322" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 331.237 230.791)" stroke="#b2b2b2" fontWeight="normal" id="WS007_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS007.status}_text cpil4_css1`}>007</text>
                    <text fill="#000000" strokeWidth="0" x="547.06883" y="1032.20322" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 363.767 230.189)" stroke="#b2b2b2" fontWeight="normal" id="WS008_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS008.status}_text cpil4_css1`}>008</text>
                    <text fill="#000000" strokeWidth="0" x="547.06883" y="1032.20322" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 399.309 230.189)" stroke="#b2b2b2" fontWeight="normal" id="WS009_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS009.status}_text cpil4_css1`}>009</text>
                    <text fill="#000000" strokeWidth="0" x="549.04108" y="1032.20322" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 440.273 230.189)" stroke="#b2b2b2" fontWeight="normal" id="WS010_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS010.status}_text cpil4_css1`}>010</text>
                    <rect id="WS011" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS011.status} height="19.92463" width="30" y="921.19445" x="738.27527" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS012" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS012.status} height="19.92463" width="30" y="921.19586" x="697.07763" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS014" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS014.status} height="19.92463" width="30" y="921.81574" x="623.83786" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS015" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS015.status} height="19.92463" width="30" y="921.57039" x="583.27321" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="945.45116" x2="618.62837" y1="915.72311" x1="618.62837" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="945.83709" x2="732.73757" y1="916.10903" x1="732.73757" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="250.91802" y="1034.88015" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 451.719 276.574)" stroke="#b2b2b2" fontWeight="normal" id="WS015_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS015.status}_text cpil4_css1`}>015</text>
                    <rect id="WS013" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS013.status} height="19.92463" width="30" y="921.21333" x="660.58485" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="322.25362" y="1034.88015" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 452.321 276.574)" stroke="#b2b2b2" fontWeight="normal" id="WS014_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS014.status}_text cpil4_css1`}>014</text>
                    <text fill="#000000" strokeWidth="0" x="329.81952" y="1034.88015" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 484.851 275.972)" stroke="#b2b2b2" fontWeight="normal" id="WS013_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS013.status}_text cpil4_css1`}>013</text>
                    <text fill="#000000" strokeWidth="0" x="329.81952" y="1034.88015" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 520.393 275.972)" stroke="#b2b2b2" fontWeight="normal" id="WS012_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS012.status}_text cpil4_css1`}>012</text>
                    <text fill="#000000" strokeWidth="0" x="331.79177" y="1034.88015" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 561.357 275.972)" stroke="#b2b2b2" fontWeight="normal" id="WS011_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS011.status}_text cpil4_css1`}>011</text>
                    <rect id="WS001" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS001.status} height="19.92463" width="30" y="843.69475" x="738.27527" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS002" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS002.status} height="19.92463" width="30" y="843.69616" x="697.07763" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS004" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS004.status} height="19.92463" width="30" y="844.31604" x="623.83786" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS005" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS005.status} height="19.92463" width="30" y="844.07069" x="583.27321" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="867.95146" x2="618.62837" y1="838.22341" x1="618.62837" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="868.33739" x2="732.73757" y1="838.60933" x1="732.73757" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="250.72859" y="1035.82782" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 452.321 199.465)" stroke="#b2b2b2" fontWeight="normal" id="WS005_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS005.status}_text cpil4_css1`}>005</text>
                    <rect id="WS003" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS003.status} height="19.92463" width="30" y="843.71363" x="660.58485" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="322.06419" y="1035.82782" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 452.923 199.465)" stroke="#b2b2b2" fontWeight="normal" id="WS004_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS004.status}_text cpil4_css1`}>004</text>
                    <text fill="#000000" strokeWidth="0" x="329.63008" y="1035.82782" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 485.453 198.863)" stroke="#b2b2b2" fontWeight="normal" id="WS003_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS003.status}_text cpil4_css1`}>003</text>
                    <text fill="#000000" strokeWidth="0" x="329.63008" y="1035.82782" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 520.995 198.863)" stroke="#b2b2b2" fontWeight="normal" id="WS002_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS002.status}_text cpil4_css1`}>002</text>
                    <text fill="#000000" strokeWidth="0" x="330.71093" y="1036.60941" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 562.456 198.366)" stroke="#b2b2b2" fontWeight="normal" id="WS001_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS001.status}_text cpil4_css1`}>001</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1023.93491" x2="773.88086" y1="1023.93491" x1="577.61593" fillOpacity="null" fill="none" stroke="#999999" />
                    <path id="WS024" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS024.status} d="m738.27527,951.21545l30,0l0,19.92463l-30,0l0,-19.92463z" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS023" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS023.status} height="19.92463" width="30" y="951.21684" x="697.07763" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS021" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS021.status} height="19.92463" width="30" y="951.83672" x="623.83785" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS020" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS020.status} height="19.92463" width="30" y="951.59137" x="583.2732" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="975.47214" x2="618.62836" y1="945.74409" x1="618.62836" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="975.85807" x2="732.73757" y1="946.13001" x1="732.73757" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="468.16733" y="1035.32955" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 330.635 306.309)" stroke="#b2b2b2" fontWeight="normal" id="WS020_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS020.status}_text cpil4_css1`}>020</text>
                    <rect id="WS022" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS022.status} height="19.92463" width="30" y="951.23431" x="660.58485" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="539.50293" y="1035.32955" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 331.237 306.309)" stroke="#b2b2b2" fontWeight="normal" id="WS021_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS021.status}_text cpil4_css1`}>021</text>
                    <text fill="#000000" strokeWidth="0" x="547.06883" y="1035.32955" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 363.767 305.707)" stroke="#b2b2b2" fontWeight="normal" id="WS022_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS022.status}_text cpil4_css1`}>022</text>
                    <text fill="#000000" strokeWidth="0" x="547.06883" y="1035.32955" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 399.309 305.707)" stroke="#b2b2b2" fontWeight="normal" id="WS023_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS023.status}_text cpil4_css1`}>023</text>
                    <text fill="#000000" strokeWidth="0" x="549.04108" y="1035.32955" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 440.273 305.707)" stroke="#b2b2b2" fontWeight="normal" id="WS024_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS024.status}_text cpil4_css1`}>024</text>
                    <rect id="WS025" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS025.status} height="19.92463" width="30" y="999.19706" x="738.27527" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS026" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS026.status} height="19.92463" width="30" y="999.19847" x="697.07763" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS028" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS028.status} height="19.92463" width="30" y="999.81835" x="623.83786" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS029" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS029.status} height="19.92463" width="30" y="999.573" x="583.27321" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1023.45377" x2="618.62837" y1="993.72572" x1="618.62837" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1023.8397" x2="732.73757" y1="994.11164" x1="732.73757" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="250.91802" y="1038.78807" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 451.719 352.589)" stroke="#b2b2b2" fontWeight="normal" id="WS029_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS029.status}_text cpil4_css1`}>029</text>
                    <rect id="WS027" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS027.status} height="19.92463" width="30" y="999.21594" x="660.58485" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="322.25362" y="1038.78807" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 452.321 352.589)" stroke="#b2b2b2" fontWeight="normal" id="WS028_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS028.status}_text cpil4_css1`}>028</text>
                    <text fill="#000000" strokeWidth="0" x="329.81952" y="1038.78807" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 484.851 351.987)" stroke="#b2b2b2" fontWeight="normal" id="WS027_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS027.status}_text cpil4_css1`}>027</text>
                    <text fill="#000000" strokeWidth="0" x="329.81952" y="1038.78807" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 520.393 351.987)" stroke="#b2b2b2" fontWeight="normal" id="WS026_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS026.status}_text cpil4_css1`}>026</text>
                    <text fill="#000000" strokeWidth="0" x="331.79177" y="1038.78807" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 561.357 351.987)" stroke="#b2b2b2" fontWeight="normal" id="WS025_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS025.status}_text cpil4_css1`}>025</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1101.93752" x2="773.88086" y1="1101.93752" x1="577.61593" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS034" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS034.status} height="19.92463" width="30" y="1029.21804" x="738.27527" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS033" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS033.status} height="19.92463" width="30" y="1029.21945" x="697.07763" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS031" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS031.status} height="19.92463" width="30" y="1029.83933" x="623.83785" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS030" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS030.status} height="19.92463" width="30" y="1029.59398" x="583.2732" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1053.47475" x2="618.62836" y1="1023.7467" x1="618.62836" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1053.86068" x2="732.73757" y1="1024.13262" x1="732.73757" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="473.51582" y="1041.58221" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 327.654 380.834)" stroke="#b2b2b2" fontWeight="normal" id="WS030_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS030.status}_text cpil4_css1`}>030</text>
                    <rect id="WS032" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS032.status} height="19.92463" width="30" y="1029.23692" x="660.58485" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="544.85142" y="1041.58221" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 328.256 380.834)" stroke="#b2b2b2" fontWeight="normal" id="WS031_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS031.status}_text cpil4_css1`}>031</text>
                    <text fill="#000000" strokeWidth="0" x="552.41732" y="1041.58221" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 360.786 380.232)" stroke="#b2b2b2" fontWeight="normal" id="WS032_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS032.status}_text cpil4_css1`}>032</text>
                    <text fill="#000000" strokeWidth="0" x="552.41732" y="1041.58221" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 396.328 380.232)" stroke="#b2b2b2" fontWeight="normal" id="WS033_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS033.status}_text cpil4_css1`}>033</text>
                    <text fill="#000000" strokeWidth="0" x="554.38957" y="1041.58221" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 437.292 380.232)" stroke="#b2b2b2" fontWeight="normal" id="WS034_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS034.status}_text cpil4_css1`}>034</text>
                    <rect id="WS035" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS035.status} height="19.92463" width="30" y="1077.19966" x="738.27527" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS036" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS036.status} height="19.92463" width="30" y="1077.20107" x="697.07763" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS038" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS038.status} height="19.92463" width="30" y="1077.82095" x="623.83786" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS039" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS039.status} height="19.92463" width="30" y="1077.5756" x="583.27321" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1101.45637" x2="618.62837" y1="1071.72832" x1="618.62837" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1101.8423" x2="732.73757" y1="1072.11424" x1="732.73757" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="256.26651" y="1044.25915" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 448.738 427.114)" stroke="#b2b2b2" fontWeight="normal" id="WS039_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS039.status}_text cpil4_css1`}>039</text>
                    <rect id="WS037" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS037.status} height="19.92463" width="30" y="1077.21854" x="660.58485" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="327.60211" y="1044.25915" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 449.34 427.114)" stroke="#b2b2b2" fontWeight="normal" id="WS038_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS038.status}_text cpil4_css1`}>038</text>
                    <text fill="#000000" strokeWidth="0" x="335.16801" y="1044.25915" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 481.87 426.512)" stroke="#b2b2b2" fontWeight="normal" id="WS037_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS037.status}_text cpil4_css1`}>037</text>
                    <text fill="#000000" strokeWidth="0" x="335.16801" y="1044.25915" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 517.412 426.512)" stroke="#b2b2b2" fontWeight="normal" id="WS036_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS036.status}_text cpil4_css1`}>036</text>
                    <text fill="#000000" strokeWidth="0" x="337.14026" y="1044.25915" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 558.376 426.512)" stroke="#b2b2b2" fontWeight="normal" id="WS035_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS035.status}_text cpil4_css1`}>035</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1179.44329" x2="773.88086" y1="1179.44329" x1="577.61593" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS046" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS046.status} height="19.92463" width="30" y="1106.72381" x="738.27527" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS045" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS045.status} height="19.92463" width="30" y="1106.72522" x="697.07763" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS043" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS043.status} height="19.92463" width="30" y="1107.3451" x="623.83785" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS042" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS042.status} height="19.92463" width="30" y="1107.09975" x="583.2732" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1130.98052" x2="618.62836" y1="1101.25247" x1="618.62836" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1131.36645" x2="732.73757" y1="1101.63839" x1="732.73757" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="473.51582" y="1040.80063" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 327.654 458.34)" stroke="#b2b2b2" fontWeight="normal" id="WS042_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS042.status}_text cpil4_css1`}>042</text>
                    <rect id="WS044" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS044.status} height="19.92463" width="30" y="1106.74269" x="660.58485" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="544.85142" y="1040.80063" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 328.256 458.34)" stroke="#b2b2b2" fontWeight="normal" id="WS043_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS043.status}_text cpil4_css1`}>043</text>
                    <text fill="#000000" strokeWidth="0" x="552.41732" y="1040.80063" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 360.786 457.738)" stroke="#b2b2b2" fontWeight="normal" id="WS044_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS044.status}_text cpil4_css1`}>044</text>
                    <text fill="#000000" strokeWidth="0" x="552.41732" y="1040.80063" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 396.328 457.738)" stroke="#b2b2b2" fontWeight="normal" id="WS045_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS045.status}_text cpil4_css1`}>045</text>
                    <text fill="#000000" strokeWidth="0" x="554.38957" y="1040.80063" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 437.292 457.738)" stroke="#b2b2b2" fontWeight="normal" id="WS046_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS046.status}_text cpil4_css1`}>046</text>
                    <rect id="WS047" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS047.status} height="19.92463" width="30" y="1154.70543" x="738.27527" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS048" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS048.status} height="19.92463" width="30" y="1154.70684" x="697.07763" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS050" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS050.status} height="19.92463" width="30" y="1155.32672" x="623.83786" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS051" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS051.status} height="19.92463" width="30" y="1155.08137" x="583.27321" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1178.96214" x2="618.62837" y1="1149.23409" x1="618.62837" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1179.34807" x2="732.73757" y1="1149.62001" x1="732.73757" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="256.26651" y="1044.25915" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 448.738 504.62)" stroke="#b2b2b2" fontWeight="normal" id="WS051_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS051.status}_text cpil4_css1`}>051</text>
                    <rect id="WS049" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS049.status} height="19.92463" width="30" y="1154.72431" x="660.58485" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="327.60211" y="1044.25915" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 449.34 504.62)" stroke="#b2b2b2" fontWeight="normal" id="WS050_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS050.status}_text cpil4_css1`}>050</text>
                    <text fill="#000000" strokeWidth="0" x="335.16801" y="1044.25915" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 481.87 504.018)" stroke="#b2b2b2" fontWeight="normal" id="WS049_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS049.status}_text cpil4_css1`}>049</text>
                    <text fill="#000000" strokeWidth="0" x="335.16801" y="1044.25915" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 517.412 504.018)" stroke="#b2b2b2" fontWeight="normal" id="WS048_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS048.status}_text cpil4_css1`}>048</text>
                    <text fill="#000000" strokeWidth="0" x="337.14026" y="1044.25915" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 558.376 504.018)" stroke="#b2b2b2" fontWeight="normal" id="WS047_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS047.status}_text cpil4_css1`}>047</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1257.4459" x2="773.88086" y1="1257.4459" x1="577.61593" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS056" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS056.status} height="19.92463" width="30" y="1184.72642" x="738.27527" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS055" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS055.status} height="19.92463" width="30" y="1184.72783" x="697.07763" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS053" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS053.status} height="19.92463" width="30" y="1185.34771" x="623.83785" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS052" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS052.status} height="19.92463" width="30" y="1185.10236" x="583.2732" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1208.98313" x2="618.62836" y1="1179.25508" x1="618.62836" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1209.36906" x2="732.73757" y1="1179.641" x1="732.73757" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="473.51582" y="1042.3638" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 327.654 535.846)" stroke="#b2b2b2" fontWeight="normal" id="WS052_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS052.status}_text cpil4_css1`}>052</text>
                    <path id="WS054" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS054.status} d="m660.58484,1184.74524l30,0l0,19.92463l-30,0l0,-19.92463z" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="544.85142" y="1042.3638" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 328.256 535.846)" stroke="#b2b2b2" fontWeight="normal" id="WS053_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS053.status}_text cpil4_css1`}>053</text>
                    <text fill="#000000" strokeWidth="0" x="552.41732" y="1042.3638" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 360.786 535.244)" stroke="#b2b2b2" fontWeight="normal" id="WS054_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS054.status}_text cpil4_css1`}>054</text>
                    <text fill="#000000" strokeWidth="0" x="552.41732" y="1042.3638" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 396.328 535.244)" stroke="#b2b2b2" fontWeight="normal" id="WS055_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS055.status}_text cpil4_css1`}>055</text>
                    <text fill="#000000" strokeWidth="0" x="554.38957" y="1042.3638" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 437.292 535.244)" stroke="#b2b2b2" fontWeight="normal" id="WS056_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS056.status}_text cpil4_css1`}>056</text>
                    <rect id="WS057" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS057.status} height="19.92463" width="30" y="1232.70804" x="738.27527" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS058" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS058.status} height="19.92463" width="30" y="1232.70945" x="697.07763" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS060" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS060.status} height="19.92463" width="30" y="1233.32933" x="623.83786" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS061" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS061.status} height="19.92463" width="30" y="1233.08398" x="583.27321" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1256.96475" x2="618.62837" y1="1227.2367" x1="618.62837" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1257.35068" x2="732.73757" y1="1227.62262" x1="732.73757" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="256.26651" y="1045.04073" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 448.738 582.126)" stroke="#b2b2b2" fontWeight="normal" id="WS061_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS061.status}_text cpil4_css1`}>061</text>
                    <rect id="WS059" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS059.status} height="19.92463" width="30" y="1232.72692" x="660.58485" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="327.60211" y="1045.04073" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 449.34 582.126)" stroke="#b2b2b2" fontWeight="normal" id="WS060_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS060.status}_text cpil4_css1`}>060</text>
                    <text fill="#000000" strokeWidth="0" x="335.16801" y="1045.04073" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 481.87 581.524)" stroke="#b2b2b2" fontWeight="normal" id="WS059_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS059.status}_text cpil4_css1`}>059</text>
                    <text fill="#000000" strokeWidth="0" x="335.16801" y="1045.04073" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 517.412 581.524)" stroke="#b2b2b2" fontWeight="normal" id="WS058_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS058.status}_text cpil4_css1`}>058</text>
                    <text fill="#000000" strokeWidth="0" x="337.14026" y="1045.04073" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 558.376 581.524)" stroke="#b2b2b2" fontWeight="normal" id="WS057_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS057.status}_text cpil4_css1`}>057</text>
                    <g>
                        <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1102.49869" x2="576.92779" y1="1072.00597" x1="576.92779" fillOpacity="null" fill="none" />
                        <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1132.02284" x2="576.92778" y1="1101.53012" x1="576.92778" fillOpacity="null" fill="none" />
                        <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1180.00446" x2="576.92779" y1="1149.51174" x1="576.92779" fillOpacity="null" fill="none" />
                        <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1210.02545" x2="576.92778" y1="1179.53273" x1="576.92778" fillOpacity="null" fill="none" />
                        <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1258.00707" x2="576.92779" y1="1227.51435" x1="576.92779" fillOpacity="null" fill="none" />
                    </g>
                    <g>
                        <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="898.51186" x2="576.92777" y1="868.01914" x1="576.92777" fillOpacity="null" fill="none" />
                        <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="946.49348" x2="576.92778" y1="916.00076" x1="576.92778" fillOpacity="null" fill="none" />
                        <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="868.99378" x2="576.92778" y1="838.50106" x1="576.92778" fillOpacity="null" fill="none" />
                        <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="976.51446" x2="576.92777" y1="946.02174" x1="576.92777" fillOpacity="null" fill="none" />
                        <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1024.49609" x2="576.92778" y1="994.00337" x1="576.92778" fillOpacity="null" fill="none" />
                        <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1054.51707" x2="576.92777" y1="1024.02435" x1="576.92777" fillOpacity="null" fill="none" />
                    </g>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1335.11161" x2="773.88086" y1="1335.11161" x1="577.61593" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS066" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS066.status} height="19.92463" width="30" y="1262.39213" x="738.27527" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <path id="WS065" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS065.status} d="m697.07764,1262.39355l30,0l0,19.92463l-30,0l0,-19.92463z" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS063" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS063.status} height="19.92463" width="30" y="1263.01342" x="623.83785" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS062" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS062.status} height="19.92463" width="30" y="1262.76807" x="583.2732" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1286.64884" x2="618.62836" y1="1256.92079" x1="618.62836" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1287.03477" x2="732.73757" y1="1257.30671" x1="732.73757" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="470.74132" y="1188.38157" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 329.2 520.195)" stroke="#b2b2b2" fontWeight="normal" id="WS062_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS062.status}_text cpil4_css1`}>062</text>
                    <rect id="WS064" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS064.status} height="19.92463" width="30" y="1262.41101" x="660.58485" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="542.07692" y="1188.38157" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 329.802 520.195)" stroke="#b2b2b2" fontWeight="normal" id="WS063_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS063.status}_text cpil4_css1`}>063</text>
                    <text fill="#000000" strokeWidth="0" x="549.64282" y="1188.38157" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 362.332 519.593)" stroke="#b2b2b2" fontWeight="normal" id="WS064_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS064.status}_text cpil4_css1`}>064</text>
                    <text fill="#000000" strokeWidth="0" x="549.64282" y="1188.38157" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 397.874 519.593)" stroke="#b2b2b2" fontWeight="normal" id="WS065_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS065.status}_text cpil4_css1`}>065</text>
                    <text fill="#000000" strokeWidth="0" x="551.61507" y="1188.38157" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 438.838 519.593)" stroke="#b2b2b2" fontWeight="normal" id="WS066_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS066.status}_text cpil4_css1`}>066</text>
                    <rect id="WS067" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS067.status} height="19.92463" width="30" y="1310.37375" x="738.27527" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS068" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS068.status} height="19.92463" width="30" y="1310.37516" x="697.07763" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS070" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS070.status} height="19.92463" width="30" y="1310.99504" x="623.83786" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS071" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS071.status} height="19.92463" width="30" y="1310.74969" x="583.27321" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1334.63046" x2="618.62837" y1="1304.90241" x1="618.62837" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1335.01639" x2="732.73757" y1="1305.28833" x1="732.73757" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="253.49201" y="1191.84009" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 450.284 566.475)" stroke="#b2b2b2" fontWeight="normal" id="WS071_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS071.status}_text cpil4_css1`}>071</text>
                    <rect id="WS069" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS069.status} height="19.92463" width="30" y="1310.39263" x="660.58485" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="324.82761" y="1191.84009" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 450.886 566.475)" stroke="#b2b2b2" fontWeight="normal" id="WS070_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS070.status}_text cpil4_css1`}>070</text>
                    <text fill="#000000" strokeWidth="0" x="332.39351" y="1191.84009" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 483.416 565.873)" stroke="#b2b2b2" fontWeight="normal" id="WS069_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS069.status}_text cpil4_css1`}>069</text>
                    <text fill="#000000" strokeWidth="0" x="332.39351" y="1191.84009" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 518.958 565.873)" stroke="#b2b2b2" fontWeight="normal" id="WS068_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS068.status}_text cpil4_css1`}>068</text>
                    <text fill="#000000" strokeWidth="0" x="334.36576" y="1191.84009" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 559.922 565.873)" stroke="#b2b2b2" fontWeight="normal" id="WS067_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS067.status}_text cpil4_css1`}>067</text>
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1413.11422" x2="773.88086" y1="1413.11422" x1="617.78984" fillOpacity="null" fill="none" />
                    <rect id="WS076" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS076.status} height="19.92463" width="30" y="1340.39474" x="738.27527" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS075" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS075.status} height="19.92463" width="30" y="1340.39615" x="697.07763" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS073" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS073.status} height="19.92463" width="30" y="1341.01603" x="623.83785" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS072" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS072.status} height="19.92463" width="30" y="1340.77068" x="583.2732" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1364.65145" x2="618.62836" y1="1334.9234" x1="618.62836" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1365.03738" x2="732.73757" y1="1335.30932" x1="732.73757" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="470.74132" y="1189.94474" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 329.2 597.701)" stroke="#b2b2b2" fontWeight="normal" id="WS072_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS072.status}_text cpil4_css1`}>072</text>
                    <rect id="WS074" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS074.status} height="19.92463" width="30" y="1340.41362" x="660.58485" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="542.07692" y="1189.94474" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 329.802 597.701)" stroke="#b2b2b2" fontWeight="normal" id="WS073_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS073.status}_text cpil4_css1`}>073</text>
                    <text fill="#000000" strokeWidth="0" x="549.64282" y="1189.94474" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 362.332 597.099)" stroke="#b2b2b2" fontWeight="normal" id="WS074_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS074.status}_text cpil4_css1`}>074</text>
                    <text fill="#000000" strokeWidth="0" x="549.64282" y="1189.94474" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 397.874 597.099)" stroke="#b2b2b2" fontWeight="normal" id="WS075_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS075.status}_text cpil4_css1`}>075</text>
                    <text fill="#000000" strokeWidth="0" x="551.61507" y="1189.94474" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 438.838 597.099)" stroke="#b2b2b2" fontWeight="normal" id="WS076_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS076.status}_text cpil4_css1`}>076</text>
                    <rect id="WS077" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS077.status} height="19.92463" width="30" y="1388.37636" x="738.27527" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS078" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS078.status} height="19.92463" width="30" y="1388.37777" x="697.07763" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS079" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS079.status} height="19.92463" width="30" y="1388.99765" x="623.83786" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1412.63307" x2="618.62837" y1="1382.90502" x1="618.62837" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1413.019" x2="732.73757" y1="1383.29094" x1="732.73757" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="324.82761" y="1192.62167" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 450.886 643.981)" stroke="#b2b2b2" fontWeight="normal" id="WS079_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS079.status}_text cpil4_css1`}>079</text>
                    <text fill="#000000" strokeWidth="0" x="332.39351" y="1192.62167" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 518.958 643.379)" stroke="#b2b2b2" fontWeight="normal" id="WS078_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS078.status}_text cpil4_css1`}>078</text>
                    <text fill="#000000" strokeWidth="0" x="334.36576" y="1192.62167" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 559.922 643.379)" stroke="#b2b2b2" fontWeight="normal" id="WS077_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS077.status}_text cpil4_css1`}>077</text>
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1258.16701" x2="576.92779" y1="1227.67429" x1="576.92779" fillOpacity="null" fill="none" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1287.69116" x2="576.92778" y1="1257.19844" x1="576.92778" fillOpacity="null" fill="none" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1335.67278" x2="576.92779" y1="1305.18006" x1="576.92779" fillOpacity="null" fill="none" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="1365.69377" x2="576.92778" y1="1335.20105" x1="576.92778" fillOpacity="null" fill="none" />
                    <rect stroke="#999999" height="29.00218" width="41.51214" y="61.54252" x="577.31203" strokeWidth="0" fill="#cccccc" />
                    <text fontWeight="bold" fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="832.16923" y="57.99148" fontSize="12" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 120.836 37.5607)" stroke="#cccccc" opacity="0.5">UTILITY</text>
                    <text fontWeight="bold" fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="839.88422" y="71.86118" fontSize="12" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 120.836 37.5607)" stroke="#cccccc" opacity="0.5">AREA</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="371.34704" x2="774.53327" y1="371.34704" x1="578.26834" fillOpacity="null" fill="none" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="449.34964" x2="774.53327" y1="449.34964" x1="578.26834" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS333" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS333.status} height="19.92463" width="30" y="376.63016" x="738.92768" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS332" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS332.status} height="19.92463" width="30" y="376.63157" x="697.73004" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS330" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS330.status} height="19.92463" width="30" y="377.25145" x="624.49026" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS329" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS329.status} height="19.92463" width="30" y="377.0061" x="583.92561" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="400.88687" x2="619.28077" y1="371.15882" x1="619.28077" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="401.2728" x2="733.38998" y1="371.54474" x1="733.38998" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-137.6067" y="187.47259" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 668.917 270.684)" stroke="#b2b2b2" fontWeight="normal" id="WS329_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS329.status}_text cpil4_css1`}>329</text>
                    <rect id="WS331" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS331.status} height="19.92463" width="30" y="376.64904" x="661.23726" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-66.2711" y="187.47259" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 669.519 270.684)" stroke="#b2b2b2" fontWeight="normal" id="WS330_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS330.status}_text cpil4_css1`}>330</text>
                    <text fill="#000000" strokeWidth="0" x="-58.7052" y="187.47259" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 702.049 270.082)" stroke="#b2b2b2" fontWeight="normal" id="WS331_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS331.status}_text cpil4_css1`}>331</text>
                    <text fill="#000000" strokeWidth="0" x="-58.7052" y="187.47259" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 737.591 270.082)" stroke="#b2b2b2" fontWeight="normal" id="WS332_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS332.status}_text cpil4_css1`}>332</text>
                    <text fill="#000000" strokeWidth="0" x="-56.73295" y="187.47259" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 778.555 270.082)" stroke="#b2b2b2" fontWeight="normal" id="WS333_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS333.status}_text cpil4_css1`}>333</text>
                    <rect id="WS334" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS334.status} height="19.92463" width="30" y="424.61179" x="738.92768" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS335" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS335.status} height="19.92463" width="30" y="424.6132" x="697.73004" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS337" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS337.status} height="19.92463" width="30" y="425.23308" x="624.49027" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS338" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS338.status} height="19.92463" width="30" y="424.98773" x="583.92562" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="448.8685" x2="619.28078" y1="419.14045" x1="619.28078" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="449.25443" x2="733.38998" y1="419.52637" x1="733.38998" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-354.85601" y="190.93111" id="WS338_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS338.status}_text cpil4_css1`} fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 790.001 316.964)" stroke="#b2b2b2" fontWeight="normal">338</text>
                    <rect id="WS336" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS336.status} height="19.92463" width="30" y="424.63067" x="661.23726" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-283.52041" y="190.93111" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 790.603 316.964)" stroke="#b2b2b2" fontWeight="normal" id="WS337_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS337.status}_text cpil4_css1`}>337</text>
                    <text fill="#000000" strokeWidth="0" x="-275.95451" y="190.93111" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 823.133 316.362)" stroke="#b2b2b2" fontWeight="normal" id="WS336_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS336.status}_text cpil4_css1`}>336</text>
                    <text fill="#000000" strokeWidth="0" x="-275.95451" y="190.93111" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 858.675 316.362)" stroke="#b2b2b2" fontWeight="normal" id="WS335_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS335.status}_text cpil4_css1`}>335</text>
                    <text fill="#000000" strokeWidth="0" x="-273.98226" y="190.93111" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 899.639 316.362)" stroke="#b2b2b2" fontWeight="normal" id="WS334_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS334.status}_text cpil4_css1`}>334</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="527.35225" x2="774.53327" y1="527.35225" x1="578.26834" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS343" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS343.status} height="19.92463" width="30" y="454.63277" x="738.92768" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS342" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS342.status} height="19.92463" width="30" y="454.63418" x="697.73004" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS340" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS340.status} height="19.92463" width="30" y="455.25406" x="624.49026" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS339" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS339.status} height="19.92463" width="30" y="455.00871" x="583.92561" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="478.88948" x2="619.28077" y1="449.16143" x1="619.28077" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="479.27541" x2="733.38998" y1="449.54735" x1="733.38998" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-132.25821" y="193.72525" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 665.936 345.209)" stroke="#b2b2b2" fontWeight="normal" id="WS339_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS339.status}_text cpil4_css1`}>339</text>
                    <rect id="WS341" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS341.status} height="19.92463" width="30" y="454.65165" x="661.23726" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-60.92261" y="193.72525" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 666.538 345.209)" stroke="#b2b2b2" fontWeight="normal" id="WS340_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS340.status}_text cpil4_css1`}>340</text>
                    <text fill="#000000" strokeWidth="0" x="-53.35671" y="193.72525" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 699.068 344.607)" stroke="#b2b2b2" fontWeight="normal" id="WS341_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS341.status}_text cpil4_css1`}>341</text>
                    <text fill="#000000" strokeWidth="0" x="-53.35671" y="193.72525" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 734.61 344.607)" stroke="#b2b2b2" fontWeight="normal" id="WS342_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS342.status}_text cpil4_css1`}>342</text>
                    <text fill="#000000" strokeWidth="0" x="-51.38446" y="193.72525" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 775.574 344.607)" stroke="#b2b2b2" fontWeight="normal" id="WS343_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS343.status}_text cpil4_css1`}>343</text>
                    <rect id="WS344" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS344.status} height="19.92463" width="30" y="502.61439" x="738.92768" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS345" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS345.status} height="19.92463" width="30" y="502.6158" x="697.73004" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS347" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS347.status} height="19.92463" width="30" y="503.23568" x="624.49027" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS348" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS348.status} height="19.92463" width="30" y="502.99033" x="583.92562" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="526.8711" x2="619.28078" y1="497.14305" x1="619.28078" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="527.25703" x2="733.38998" y1="497.52897" x1="733.38998" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-349.50752" y="196.40219" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 787.02 391.489)" stroke="#b2b2b2" fontWeight="normal" id="WS348_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS348.status}_text cpil4_css1`}>348</text>
                    <rect id="WS346" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS346.status} height="19.92463" width="30" y="502.63327" x="661.23726" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-278.17192" y="196.40219" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 787.622 391.489)" stroke="#b2b2b2" fontWeight="normal" id="WS347_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS347.status}_text cpil4_css1`}>347</text>
                    <text fill="#000000" strokeWidth="0" x="-270.60602" y="196.40219" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 820.152 390.887)" stroke="#b2b2b2" fontWeight="normal" id="WS346_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS346.status}_text cpil4_css1`}>346</text>
                    <text fill="#000000" strokeWidth="0" x="-270.60602" y="196.40219" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 855.694 390.887)" stroke="#b2b2b2" fontWeight="normal" id="WS345_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS345.status}_text cpil4_css1`}>345</text>
                    <text fill="#000000" strokeWidth="0" x="-268.63377" y="196.40219" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 896.658 390.887)" stroke="#b2b2b2" fontWeight="normal" id="WS344_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS344.status}_text cpil4_css1`}>344</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="604.85802" x2="774.53327" y1="604.85802" x1="578.26834" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS353" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS353.status} height="19.92463" width="30" y="532.13854" x="738.92768" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS352" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS352.status} height="19.92463" width="30" y="532.13995" x="697.73004" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS350" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS350.status} height="19.92463" width="30" y="532.75983" x="624.49026" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS349" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS349.status} height="19.92463" width="30" y="532.51448" x="583.92561" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="556.39525" x2="619.28077" y1="526.6672" x1="619.28077" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="556.78118" x2="733.38998" y1="527.05312" x1="733.38998" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-132.25821" y="192.94367" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 665.936 422.715)" stroke="#b2b2b2" fontWeight="normal" id="WS349_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS349.status}_text cpil4_css1`}>349</text>
                    <rect id="WS351" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS351.status} height="19.92463" width="30" y="532.15742" x="661.23726" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-60.92261" y="192.94367" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 666.538 422.715)" stroke="#b2b2b2" fontWeight="normal" id="WS350_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS350.status}_text cpil4_css1`}>350</text>
                    <text fill="#000000" strokeWidth="0" x="-53.35671" y="192.94367" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 699.068 422.113)" stroke="#b2b2b2" fontWeight="normal" id="WS351_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS351.status}_text cpil4_css1`}>351</text>
                    <text fill="#000000" strokeWidth="0" x="-53.35671" y="192.94367" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 734.61 422.113)" stroke="#b2b2b2" fontWeight="normal" id="WS352_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS352.status}_text cpil4_css1`}>352</text>
                    <text fill="#000000" strokeWidth="0" x="-51.38446" y="192.94367" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 775.574 422.113)" stroke="#b2b2b2" fontWeight="normal" id="WS353_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS353.status}_text cpil4_css1`}>353</text>
                    <rect id="WS354" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS354.status} height="19.92463" width="30" y="580.12016" x="738.92768" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS355" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS355.status} height="19.92463" width="30" y="580.12157" x="697.73004" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS357" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS357.status} height="19.92463" width="30" y="580.74145" x="624.49027" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS358" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS358.status} height="19.92463" width="30" y="580.4961" x="583.92562" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="604.37687" x2="619.28078" y1="574.64882" x1="619.28078" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="604.7628" x2="733.38998" y1="575.03474" x1="733.38998" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-349.50752" y="196.40219" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 787.02 468.995)" stroke="#b2b2b2" fontWeight="normal" id="WS358_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS358.status}_text cpil4_css1`}>358</text>
                    <rect id="WS356" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS356.status} height="19.92463" width="30" y="580.13904" x="661.23726" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-278.17192" y="196.40219" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 787.622 468.995)" stroke="#b2b2b2" fontWeight="normal" id="WS357_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS357.status}_text cpil4_css1`}>357</text>
                    <text fill="#000000" strokeWidth="0" x="-270.60602" y="196.40219" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 820.152 468.393)" stroke="#b2b2b2" fontWeight="normal" id="WS356_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS356.status}_text cpil4_css1`}>356</text>
                    <text fill="#000000" strokeWidth="0" x="-270.60602" y="196.40219" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 855.694 468.393)" stroke="#b2b2b2" fontWeight="normal" id="WS355_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS355.status}_text cpil4_css1`}>355</text>
                    <text fill="#000000" strokeWidth="0" x="-268.63377" y="196.40219" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 896.658 468.393)" stroke="#b2b2b2" fontWeight="normal" id="WS354_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS354.status}_text cpil4_css1`}>354</text>
                    <rect id="WS363" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS363.status} height="19.92463" width="30" y="610.14115" x="738.92768" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS362" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS362.status} height="19.92463" width="30" y="610.14256" x="697.73004" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS360" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS360.status} height="19.92463" width="30" y="610.76244" x="624.49026" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS359" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS359.status} height="19.92463" width="30" y="610.51709" x="583.92561" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="634.39786" x2="619.28077" y1="604.66981" x1="619.28077" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="634.78379" x2="733.38998" y1="605.05573" x1="733.38998" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-132.25821" y="194.50684" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 665.936 500.221)" stroke="#b2b2b2" fontWeight="normal" id="WS359_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS359.status}_text cpil4_css1`}>359</text>
                    <rect id="WS361" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS361.status} height="19.92463" width="30" y="610.16003" x="661.23726" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-60.92261" y="194.50684" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 666.538 500.221)" stroke="#b2b2b2" fontWeight="normal" id="WS360_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS360.status}_text cpil4_css1`}>360</text>
                    <text fill="#000000" strokeWidth="0" x="-53.35671" y="194.50684" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 699.068 499.619)" stroke="#b2b2b2" fontWeight="normal" id="WS361_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS361.status}_text cpil4_css1`}>361</text>
                    <text fill="#000000" strokeWidth="0" x="-53.35671" y="194.50684" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 734.61 499.619)" stroke="#b2b2b2" fontWeight="normal" id="WS362_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS362.status}_text cpil4_css1`}>362</text>
                    <text fill="#000000" strokeWidth="0" x="-51.38446" y="194.50684" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 775.574 499.619)" stroke="#b2b2b2" fontWeight="normal" id="WS363_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS363.status}_text cpil4_css1`}>363</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="215.73871" x2="774.53327" y1="215.73871" x1="578.32151" fillOpacity="null" fill="none" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="293.07115" x2="774.53327" y1="293.07115" x1="578.26834" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS313" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS313.status} height="19.92463" width="30" y="221.17806" x="738.92768" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS312" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS312.status} height="19.92463" width="30" y="221.17947" x="697.73004" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS310" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS310.status} height="19.92463" width="30" y="221.79935" x="624.49026" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS309" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS309.status} height="19.92463" width="30" y="221.554" x="583.92561" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="245.43477" x2="619.28077" y1="215.70672" x1="619.28077" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="245.8207" x2="733.38998" y1="216.09264" x1="733.38998" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-138.68755" y="183.80974" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 669.519 118.057)" stroke="#b2b2b2" fontWeight="normal" id="WS309_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS309.status}_text cpil4_css1`}>309</text>
                    <rect id="WS311" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS311.status} height="19.92463" width="30" y="221.19694" x="661.23726" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-67.35195" y="183.80974" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 670.121 118.057)" stroke="#b2b2b2" fontWeight="normal" id="WS310_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS310.status}_text cpil4_css1`}>310</text>
                    <text fill="#000000" strokeWidth="0" x="-59.78605" y="183.80974" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 702.651 117.455)" stroke="#b2b2b2" fontWeight="normal" id="WS311_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS311.status}_text cpil4_css1`}>311</text>
                    <text fill="#000000" strokeWidth="0" x="-59.78605" y="183.80974" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 738.193 117.455)" stroke="#b2b2b2" fontWeight="normal" id="WS312_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS312.status}_text cpil4_css1`}>312</text>
                    <text fill="#000000" strokeWidth="0" x="-57.8138" y="183.80974" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 779.157 117.455)" stroke="#b2b2b2" fontWeight="normal" id="WS313_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS313.status}_text cpil4_css1`}>313</text>
                    <rect id="WS323" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS323.status} height="19.92463" width="30" y="297.6841" x="738.92768" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS322" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS322.status} height="19.92463" width="30" y="297.68551" x="697.73004" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS320" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS320.status} height="19.92463" width="30" y="298.30539" x="624.49026" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS319" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS319.status} height="19.92463" width="30" y="298.06004" x="583.92561" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="321.94081" x2="619.28077" y1="292.21276" x1="619.28077" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="322.32674" x2="733.38998" y1="292.59868" x1="733.38998" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-137.60671" y="182.86207" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 668.917 195.166)" stroke="#b2b2b2" fontWeight="normal" id="WS319_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS319.status}_text cpil4_css1`}>319</text>
                    <rect id="WS321" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS321.status} height="19.92463" width="30" y="297.70298" x="661.23726" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-66.27111" y="182.86207" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 669.519 195.166)" stroke="#b2b2b2" fontWeight="normal" id="WS320_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS320.status}_text cpil4_css1`}>320</text>
                    <text fill="#000000" strokeWidth="0" x="-58.70521" y="182.86207" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 702.049 194.564)" stroke="#b2b2b2" fontWeight="normal" id="WS321_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS321.status}_text cpil4_css1`}>321</text>
                    <text fill="#000000" strokeWidth="0" x="-58.70521" y="182.86207" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 737.591 194.564)" stroke="#b2b2b2" fontWeight="normal" id="WS322_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS322.status}_text cpil4_css1`}>322</text>
                    <text fill="#000000" strokeWidth="0" x="-56.73296" y="182.86207" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 778.555 194.564)" stroke="#b2b2b2" fontWeight="normal" id="WS323_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS323.status}_text cpil4_css1`}>323</text>
                    <rect id="WS324" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS324.status} height="19.92463" width="30" y="345.66572" x="738.92768" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS325" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS325.status} height="19.92463" width="30" y="345.66713" x="697.73004" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS327" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS327.status} height="19.92463" width="30" y="346.28701" x="624.49027" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS328" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS328.status} height="19.92463" width="30" y="346.04166" x="583.92562" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="369.92243" x2="619.28078" y1="340.19438" x1="619.28078" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="370.30836" x2="733.38998" y1="340.5803" x1="733.38998" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-354.85602" y="185.539" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 790.001 240.949)" stroke="#b2b2b2" fontWeight="normal" id="WS328_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS328.status}_text cpil4_css1`}>328</text>
                    <rect id="WS326" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS326.status} height="19.92463" width="30" y="345.6846" x="661.23726" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-283.52042" y="185.539" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 790.603 240.949)" stroke="#b2b2b2" fontWeight="normal" id="WS327_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS327.status}_text cpil4_css1`}>327</text>
                    <text fill="#000000" strokeWidth="0" x="-275.95452" y="185.539" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 823.133 240.347)" stroke="#b2b2b2" fontWeight="normal" id="WS326_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS326.status}_text cpil4_css1`}>326</text>
                    <text fill="#000000" strokeWidth="0" x="-275.95452" y="185.539" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 858.675 240.347)" stroke="#b2b2b2" fontWeight="normal" id="WS325_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS325.status}_text cpil4_css1`}>325</text>
                    <text fill="#000000" strokeWidth="0" x="-273.98227" y="185.539" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 899.639 240.347)" stroke="#b2b2b2" fontWeight="normal" id="WS324_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS324.status}_text cpil4_css1`}>324</text>
                    <rect id="WS314" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS314.status} height="19.92463" width="30" y="268.16602" x="738.92768" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS315" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS315.status} height="19.92463" width="30" y="268.16743" x="697.73004" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS317" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS317.status} height="19.92463" width="30" y="268.78731" x="624.49027" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS318" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS318.status} height="19.92463" width="30" y="268.54196" x="583.92562" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="292.42273" x2="619.28078" y1="262.69468" x1="619.28078" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="292.80866" x2="733.38998" y1="263.0806" x1="733.38998" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-355.04545" y="186.48667" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 790.603 163.841)" stroke="#b2b2b2" fontWeight="normal" id="WS318_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS318.status}_text cpil4_css1`}>318</text>
                    <rect id="WS316" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS316.status} height="19.92463" width="30" y="268.1849" x="661.23726" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-283.70985" y="186.48667" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 791.205 163.841)" stroke="#b2b2b2" fontWeight="normal" id="WS317_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS317.status}_text cpil4_css1`}>317</text>
                    <text fill="#000000" strokeWidth="0" x="-276.14396" y="186.48667" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 823.735 163.238)" stroke="#b2b2b2" fontWeight="normal" id="WS316_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS316.status}_text cpil4_css1`}>316</text>
                    <text fill="#000000" strokeWidth="0" x="-276.14396" y="186.48667" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 859.277 163.238)" stroke="#b2b2b2" fontWeight="normal" id="WS315_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS315.status}_text cpil4_css1`}>315</text>
                    <text fill="#000000" strokeWidth="0" x="-275.06311" y="187.26826" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 900.738 162.741)" stroke="#b2b2b2" fontWeight="normal" id="WS314_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS314.status}_text cpil4_css1`}>314</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="61.01115" x2="774.53327" y1="61.01115" x1="578.32151" fillOpacity="null" fill="none" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="138.34359" x2="774.53327" y1="138.34359" x1="578.26834" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS293" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS293.status} height="19.92463" width="30" y="66.4505" x="738.92768" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS292" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS292.status} height="19.92463" width="30" y="66.45191" x="697.73004" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS290" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS290.status} height="19.92463" width="30" y="67.07179" x="624.49026" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="90.70721" x2="619.28077" y1="60.97916" x1="619.28077" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="91.09314" x2="733.38998" y1="61.36508" x1="733.38998" fillOpacity="null" fill="none" />
                    <rect id="WS291" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS291.status} height="19.92463" width="30" y="66.46938" x="661.23726" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-67.35195" y="185.29393" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 670.121 -37.614)" stroke="#b2b2b2" fontWeight="normal" id="WS290_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS290.status}_text cpil4_css1`}>290</text>
                    <text fill="#000000" strokeWidth="0" x="-59.78605" y="185.29393" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 702.651 -38.216)" stroke="#b2b2b2" fontWeight="normal" id="WS291_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS291.status}_text cpil4_css1`}>291</text>
                    <text fill="#000000" strokeWidth="0" x="-59.78605" y="185.29393" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 738.193 -38.216)" stroke="#b2b2b2" fontWeight="normal" id="WS292_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS292.status}_text cpil4_css1`}>292</text>
                    <text fill="#000000" strokeWidth="0" x="-57.8138" y="185.29393" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 779.157 -38.216)" stroke="#b2b2b2" fontWeight="normal" id="WS293_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS293.status}_text cpil4_css1`}>293</text>
                    <rect id="WS303" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS303.status} height="19.92463" width="30" y="142.95654" x="738.92768" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS302" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS302.status} height="19.92463" width="30" y="142.95795" x="697.73004" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS300" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS300.status} height="19.92463" width="30" y="143.57783" x="624.49026" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS299" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS299.status} height="19.92463" width="30" y="143.33248" x="583.92561" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="167.21325" x2="619.28077" y1="137.4852" x1="619.28077" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="167.59918" x2="733.38998" y1="137.87112" x1="733.38998" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-137.60671" y="184.34626" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 668.917 39.495)" stroke="#b2b2b2" fontWeight="normal" id="WS299_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS299.status}_text cpil4_css1`}>299</text>
                    <rect id="WS301" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS301.status} height="19.92463" width="30" y="142.97542" x="661.23726" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-66.27111" y="184.34626" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 669.519 39.495)" stroke="#b2b2b2" fontWeight="normal" id="WS300_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS300.status}_text cpil4_css1`}>300</text>
                    <text fill="#000000" strokeWidth="0" x="-58.70521" y="184.34626" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 702.049 38.893)" stroke="#b2b2b2" fontWeight="normal" id="WS301_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS301.status}_text cpil4_css1`}>301</text>
                    <text fill="#000000" strokeWidth="0" x="-58.70521" y="184.34626" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 737.591 38.893)" stroke="#b2b2b2" fontWeight="normal" id="WS302_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS302.status}_text cpil4_css1`}>302</text>
                    <text fill="#000000" strokeWidth="0" x="-56.73296" y="184.34626" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 778.555 38.893)" stroke="#b2b2b2" fontWeight="normal" id="WS303_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS303.status}_text cpil4_css1`}>303</text>
                    <rect id="WS304" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS304.status} height="19.92463" width="30" y="190.93816" x="738.92768" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS305" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS305.status} height="19.92463" width="30" y="190.93957" x="697.73004" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS307" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS307.status} height="19.92463" width="30" y="191.55945" x="624.49027" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS308" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS308.status} height="19.92463" width="30" y="191.3141" x="583.92562" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="215.19487" x2="619.28078" y1="185.46682" x1="619.28078" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="215.5808" x2="733.38998" y1="185.85274" x1="733.38998" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-354.85602" y="187.02319" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 790.001 85.278)" stroke="#b2b2b2" fontWeight="normal" id="WS308_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS308.status}_text cpil4_css1`}>308</text>
                    <rect id="WS306" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS306.status} height="19.92463" width="30" y="190.95704" x="661.23726" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-283.52042" y="187.02319" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 790.603 85.278)" stroke="#b2b2b2" fontWeight="normal" id="WS307_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS307.status}_text cpil4_css1`}>307</text>
                    <text fill="#000000" strokeWidth="0" x="-275.95452" y="187.02319" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 823.133 84.676)" stroke="#b2b2b2" fontWeight="normal" id="WS306_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS306.status}_text cpil4_css1`}>306</text>
                    <text fill="#000000" strokeWidth="0" x="-275.95452" y="187.02319" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 858.675 84.676)" stroke="#b2b2b2" fontWeight="normal" id="WS305_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS305.status}_text cpil4_css1`}>305</text>
                    <text fill="#000000" strokeWidth="0" x="-273.98227" y="187.02319" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 899.639 84.676)" stroke="#b2b2b2" fontWeight="normal" id="WS304_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS304.status}_text cpil4_css1`}>304</text>
                    <rect id="WS294" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS294.status} height="19.92463" width="30" y="113.43846" x="738.92768" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS295" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS295.status} height="19.92463" width="30" y="113.43987" x="697.73004" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS297" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS297.status} height="19.92463" width="30" y="114.05975" x="624.49027" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WS298" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS298.status} height="19.92463" width="30" y="113.8144" x="583.92562" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="137.69517" x2="619.28078" y1="107.96712" x1="619.28078" fillOpacity="null" fill="none" stroke="#999999" />
                    <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="138.0811" x2="733.38998" y1="108.35304" x1="733.38998" fillOpacity="null" fill="none" />
                    <text fill="#000000" strokeWidth="0" x="-355.04545" y="187.97086" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 790.603 8.16998)" stroke="#b2b2b2" fontWeight="normal" id="WS298_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS298.status}_text cpil4_css1`}>298</text>
                    <path id="WS296" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS296.status} d="m661.23724,113.45734l30,0l0,19.92463l-30,0l0,-19.92463z" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-283.70985" y="187.97086" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 791.205 8.16998)" stroke="#b2b2b2" fontWeight="normal" id="WS297_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS297.status}_text cpil4_css1`}>297</text>
                    <text fill="#000000" strokeWidth="0" x="-276.14396" y="187.97086" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 823.735 7.56699)" stroke="#b2b2b2" fontWeight="normal" id="WS296_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS296.status}_text cpil4_css1`}>296</text>
                    <text fill="#000000" strokeWidth="0" x="-276.14396" y="187.97086" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 859.277 7.56699)" stroke="#b2b2b2" fontWeight="normal" id="WS295_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS295.status}_text cpil4_css1`}>295</text>
                    <text fill="#000000" strokeWidth="0" x="-275.06311" y="188.75245" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 900.738 7.06998)" stroke="#b2b2b2" fontWeight="normal" id="WS294_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS294.status}_text cpil4_css1`}>294</text>
                    <g>
                        <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="400.89827" x2="577.6896" y1="370.40555" x1="577.6896" fillOpacity="null" fill="none" />
                        <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="448.8799" x2="577.68961" y1="418.38718" x1="577.68961" fillOpacity="null" fill="none" />
                        <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="478.90088" x2="577.6896" y1="448.40816" x1="577.6896" fillOpacity="null" fill="none" />
                        <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="526.8825" x2="577.68961" y1="496.38978" x1="577.68961" fillOpacity="null" fill="none" />
                        <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="556.40665" x2="577.6896" y1="525.91393" x1="577.6896" fillOpacity="null" fill="none" />
                        <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="604.38827" x2="577.68961" y1="573.89555" x1="577.68961" fillOpacity="null" fill="none" />
                        <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="634.40926" x2="577.6896" y1="603.91654" x1="577.6896" fillOpacity="null" fill="none" />
                        <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="245.44617" x2="577.6896" y1="214.95345" x1="577.6896" fillOpacity="null" fill="none" />
                        <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="321.95221" x2="577.6896" y1="291.45949" x1="577.6896" fillOpacity="null" fill="none" />
                        <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="369.93383" x2="577.68961" y1="339.44111" x1="577.68961" fillOpacity="null" fill="none" />
                        <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="292.43413" x2="577.68961" y1="261.94141" x1="577.68961" fillOpacity="null" fill="none" />
                        <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="167.22465" x2="577.6896" y1="136.73193" x1="577.6896" fillOpacity="null" fill="none" />
                        <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="215.20627" x2="577.68961" y1="184.71355" x1="577.68961" fillOpacity="null" fill="none" />
                        <line stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="137.70657" x2="577.68961" y1="107.21385" x1="577.68961" fillOpacity="null" fill="none" />
                    </g>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="361.69209" y="-114.96047" fontSize="16" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 126.836 -170.439)" stroke="#cccccc" opacity="0.2">WASHROOM</text>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="462.3646" y="-82.53733" fontSize="12" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="rotate(90 2.16200e+13 4.55164e+14) matrix(0.779759 0 0 0.889744 -25.8889 -87.2663)" stroke="#cccccc" opacity="0.2">H.T.</text>
                    <rect id="WS270" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS270.status} height="19.92463" width="30" y="67.54348" x="426.50889" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-154.54631" y="-994.89146" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 520.652 713.091)" stroke="#b2b2b2" fontWeight="normal" id="WS270_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS270.status}_text cpil4_css1`}>270</text>
                    <rect id="WS271" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS271.status} height="19.92463" width="30" y="96.88998" x="426.50889" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-371.80275" y="-981.53275" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 641.74 733.946)" stroke="#b2b2b2" fontWeight="normal" id="WS271_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS271.status}_text cpil4_css1`}>271</text>
                    <rect id="WS272" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS272.status} height="19.92463" width="30" y="121.8645" x="426.50889" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-155.65384" y="-991.00717" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 521.269 764.943)" stroke="#b2b2b2" fontWeight="normal" id="WS272_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS272.status}_text cpil4_css1`}>272</text>
                    <rect id="WS273" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS273.status} height="19.92463" width="30" y="147.15223" x="426.50889" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-292.60629" y="-1020.1177" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 597.6 808.732)" stroke="#b2b2b2" fontWeight="normal" id="WS273_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS273.status}_text cpil4_css1`}>273</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="92.15668" x2="463.62109" y1="92.15668" x1="425.45832" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS283" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS283.status} height="19.92463" width="30" y="67.54348" x="465.45483" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-162.10055" y="-996.54733" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 563.808 714.144)" stroke="#b2b2b2" fontWeight="normal" id="WS283_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS283.status}_text cpil4_css1`}>283</text>
                    <rect id="WS282" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS282.status} height="19.92463" width="30" y="96.88998" x="465.45483" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-379.35699" y="-983.18862" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 684.896 734.999)" stroke="#b2b2b2" fontWeight="normal" id="WS282_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS282.status}_text cpil4_css1`}>282</text>
                    <rect id="WS281" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS281.status} height="19.92463" width="30" y="121.8645" x="465.45483" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-163.20808" y="-992.66304" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 564.425 765.996)" stroke="#b2b2b2" fontWeight="normal" id="WS281_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS281.status}_text cpil4_css1`}>281</text>
                    <rect id="WS280" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS280.status} height="19.92463" width="30" y="147.15223" x="465.45483" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-300.16053" y="-1021.77356" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 640.756 809.782)" stroke="#b2b2b2" fontWeight="normal" id="WS280_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS280.status}_text cpil4_css1`}>280</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="92.15669" x2="497.06473" y1="92.15669" x1="458.90197" fillOpacity="null" fill="none" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="251.18215" x2="460.78207" y1="65.1117" x1="460.78207" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS284" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS284.status} height="19.92463" width="30" y="96.88998" x="508.08485" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-348.19574" y="-985.67242" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 710.159 736.577)" stroke="#b2b2b2" fontWeight="normal" id="WS284_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS284.status}_text cpil4_css1`}>284</text>
                    <rect id="WS285" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS285.status} height="19.92463" width="30" y="121.8645" x="508.08485" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-132.04683" y="-995.14685" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 589.688 767.574)" stroke="#b2b2b2" fontWeight="normal" id="WS285_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS285.status}_text cpil4_css1`}>285</text>
                    <rect id="WS286" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS286.status} height="19.92463" width="30" y="147.15223" x="508.08485" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-268.99928" y="-1024.25737" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 666.019 811.362)" stroke="#b2b2b2" fontWeight="normal" id="WS286_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS286.status}_text cpil4_css1`}>286</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="92.15668" x2="541.98738" y1="92.15668" x1="507.03429" fillOpacity="null" fill="none" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="251.64067" x2="542.35803" y1="91.77809" x1="542.35803" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS274" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS274.status} height="19.92463" width="30" y="176.29009" x="426.50889" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-381.30005" y="-1035.19649" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 647.033 847.459)" stroke="#b2b2b2" fontWeight="normal" id="WS274_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS274.status}_text cpil4_css1`}>274</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="171.55679" x2="463.62109" y1="171.55679" x1="425.45832" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS279" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS279.status} height="19.92463" width="30" y="176.29009" x="465.45483" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-388.85429" y="-1036.85236" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 690.189 848.512)" stroke="#b2b2b2" fontWeight="normal" id="WS279_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS279.status}_text cpil4_css1`}>279</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="171.5568" x2="497.06473" y1="171.5568" x1="458.90197" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS287" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS287.status} height="19.92463" width="30" y="176.29009" x="508.08485" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-357.69304" y="-1039.33616" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 715.452 850.09)" stroke="#b2b2b2" fontWeight="normal" id="WS287_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS287.status}_text cpil4_css1`}>287</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="171.55679" x2="541.98738" y1="171.55679" x1="507.03429" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS275" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS275.status} height="19.92463" width="30" y="201.26461" x="426.50889" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-76.50966" y="-1029.86712" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 477.158 869.045)" stroke="#b2b2b2" fontWeight="normal" id="WS275_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS275.status}_text cpil4_css1`}>275</text>
                    <rect id="WS276" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS276.status} height="19.92463" width="30" y="226.55234" x="426.50889" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-291.55104" y="-1021.96818" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 597.012 889.308)" stroke="#b2b2b2" fontWeight="normal" id="WS276_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS276.status}_text cpil4_css1`}>276</text>
                    <rect id="WS278" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS278.status} height="19.92463" width="30" y="201.26461" x="465.45483" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-84.0639" y="-1031.52299" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 520.314 870.098)" stroke="#b2b2b2" fontWeight="normal" id="WS278_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS278.status}_text cpil4_css1`}>278</text>
                    <rect id="WS277" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS277.status} height="19.92463" width="30" y="226.55234" x="465.45483" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-299.10528" y="-1023.62404" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 640.168 890.358)" stroke="#b2b2b2" fontWeight="normal" id="WS277_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS277.status}_text cpil4_css1`}>277</text>
                    <rect id="WS288" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS288.status} height="19.92463" width="30" y="201.26461" x="508.08485" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-52.90265" y="-1034.0068" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 545.577 871.676)" stroke="#b2b2b2" fontWeight="normal" id="WS288_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS288.status}_text cpil4_css1`}>288</text>
                    <rect id="WS289" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS289.status} height="19.92463" width="30" y="226.55234" x="508.08485" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-267.94403" y="-1026.10785" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 665.431 891.938)" stroke="#b2b2b2" fontWeight="normal" id="WS289_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS289.status}_text cpil4_css1`}>289</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="250.9569" x2="463.62109" y1="250.9569" x1="425.45832" fillOpacity="null" fill="none" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="250.95691" x2="497.06473" y1="250.95691" x1="458.90197" fillOpacity="null" fill="none" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="250.9569" x2="541.98738" y1="250.9569" x1="507.03429" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS242" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS242.status} height="19.92463" width="30" y="67.54348" x="264.2398" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-136.37049" y="-995.8167" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 348.253 713.679)" stroke="#b2b2b2" fontWeight="normal" id="WS242_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS242.status}_text cpil4_css1`}>242</text>
                    <rect id="WS243" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS243.status} height="19.92463" width="30" y="96.88998" x="264.2398" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-353.62693" y="-982.45799" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 469.341 734.534)" stroke="#b2b2b2" fontWeight="normal" id="WS243_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS243.status}_text cpil4_css1`}>243</text>
                    <rect id="WS244" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS244.status} height="19.92463" width="30" y="121.8645" x="264.2398" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-137.47802" y="-991.93241" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 348.87 765.531)" stroke="#b2b2b2" fontWeight="normal" id="WS244_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS244.status}_text cpil4_css1`}>244</text>
                    <rect id="WS245" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS245.status} height="19.92463" width="30" y="147.15223" x="264.2398" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-274.43047" y="-1021.04294" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 425.201 809.32)" stroke="#b2b2b2" fontWeight="normal" id="WS245_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS245.status}_text cpil4_css1`}>245</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="92.15668" x2="300.43494" y1="92.15668" x1="262.27218" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS255" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS255.status} height="19.92463" width="30" y="67.54348" x="303.18574" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-143.92474" y="-997.47257" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 391.409 714.732)" stroke="#b2b2b2" fontWeight="normal" id="WS255_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS255.status}_text cpil4_css1`}>255</text>
                    <rect id="WS254" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS254.status} height="19.92463" width="30" y="96.88998" x="303.18574" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-361.18118" y="-984.11386" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 512.497 735.587)" stroke="#b2b2b2" fontWeight="normal" id="WS254_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS254.status}_text cpil4_css1`}>254</text>
                    <rect id="WS253" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS253.status} height="19.92463" width="30" y="121.8645" x="303.18574" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-145.03227" y="-993.58828" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 392.026 766.584)" stroke="#b2b2b2" fontWeight="normal" id="WS253_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS253.status}_text cpil4_css1`}>253</text>
                    <rect id="WS252" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS252.status} height="19.92463" width="30" y="147.15223" x="303.18574" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-281.98471" y="-1022.6988" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 468.357 810.37)" stroke="#b2b2b2" fontWeight="normal" id="WS252_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS252.status}_text cpil4_css1`}>252</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="92.15669" x2="334.79564" y1="92.15669" x1="296.63288" fillOpacity="null" fill="none" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="250.52245" x2="298.51297" y1="65.11171" x1="298.51297" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS246" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS246.status} height="19.92463" width="30" y="176.29009" x="264.2398" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-363.12423" y="-1036.12173" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 474.634 848.047)" stroke="#b2b2b2" fontWeight="normal" id="WS246_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS246.status}_text cpil4_css1`}>246</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="171.55679" x2="300.43494" y1="171.55679" x1="262.27218" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS251" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS251.status} height="19.92463" width="30" y="176.29009" x="303.18574" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-370.67848" y="-1037.7776" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 517.79 849.1)" stroke="#b2b2b2" fontWeight="normal" id="WS251_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS251.status}_text cpil4_css1`}>251</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="171.5568" x2="334.79564" y1="171.5568" x1="296.63288" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS247" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS247.status} height="19.92463" width="30" y="201.26461" x="264.2398" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-58.33383" y="-1030.79236" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 304.759 869.633)" stroke="#b2b2b2" fontWeight="normal" id="WS247_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS247.status}_text cpil4_css1`}>247</text>
                    <rect id="WS248" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS248.status} height="19.92463" width="30" y="226.55234" x="264.2398" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-273.37521" y="-1022.89341" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 424.613 889.896)" stroke="#b2b2b2" fontWeight="normal" id="WS248_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS248.status}_text cpil4_css1`}>248</text>
                    <rect id="WS250" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS250.status} height="19.92463" width="30" y="201.26461" x="303.18574" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-65.88808" y="-1032.44823" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 347.915 870.686)" stroke="#b2b2b2" fontWeight="normal" id="WS250_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS250.status}_text cpil4_css1`}>250</text>
                    <rect id="WS249" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS249.status} height="19.92463" width="30" y="226.55234" x="303.18574" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-280.92945" y="-1024.54927" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 467.769 890.946)" stroke="#b2b2b2" fontWeight="normal" id="WS249_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS249.status}_text cpil4_css1`}>249</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="250.9569" x2="300.43494" y1="250.9569" x1="262.27218" fillOpacity="null" fill="none" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="250.95691" x2="334.79564" y1="250.95691" x1="296.63288" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS256" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS256.status} height="19.92463" width="30" y="67.23994" x="345.43485" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-139.48156" y="-993.08895" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 431.182 712.366)" stroke="#b2b2b2" fontWeight="normal" id="WS256_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS256.status}_text cpil4_css1`}>256</text>
                    <rect id="WS257" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS257.status} height="19.92463" width="30" y="96.58644" x="345.43485" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-356.738" y="-979.73024" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 552.27 733.221)" stroke="#b2b2b2" fontWeight="normal" id="WS257_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS257.status}_text cpil4_css1`}>257</text>
                    <rect id="WS258" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS258.status} height="19.92463" width="30" y="121.56096" x="345.43485" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-140.58909" y="-989.20466" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 431.799 764.218)" stroke="#b2b2b2" fontWeight="normal" id="WS258_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS258.status}_text cpil4_css1`}>258</text>
                    <rect id="WS259" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS259.status} height="19.92463" width="30" y="146.84869" x="345.43485" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-277.54154" y="-1018.31519" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 508.13 808.007)" stroke="#b2b2b2" fontWeight="normal" id="WS259_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS259.status}_text cpil4_css1`}>259</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="91.85314" x2="381.62999" y1="91.85314" x1="343.46723" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS269" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS269.status} height="19.92463" width="30" y="67.23994" x="384.38079" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-146.51288" y="-995.40732" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 474.338 713.419)" stroke="#b2b2b2" fontWeight="normal" id="WS269_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS269.status}_text cpil4_css1`}>269</text>
                    <rect id="WS268" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS268.status} height="19.92463" width="30" y="96.58644" x="384.38079" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-363.76932" y="-982.04861" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 595.426 734.274)" stroke="#b2b2b2" fontWeight="normal" id="WS268_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS268.status}_text cpil4_css1`}>268</text>
                    <rect id="WS267" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS267.status} height="19.92463" width="30" y="121.56096" x="384.38079" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-147.62041" y="-991.52303" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 474.955 765.271)" stroke="#b2b2b2" fontWeight="normal" id="WS267_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS267.status}_text cpil4_css1`}>267</text>
                    <rect id="WS266" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS266.status} height="19.92463" width="30" y="146.84869" x="384.38079" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-285.09578" y="-1019.97105" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 551.286 809.057)" stroke="#b2b2b2" fontWeight="normal" id="WS266_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS266.status}_text cpil4_css1`}>266</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="91.85315" x2="415.99069" y1="91.85315" x1="377.82793" fillOpacity="null" fill="none" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="250.21891" x2="379.70802" y1="64.80817" x1="379.70802" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS260" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS260.status} height="19.92463" width="30" y="175.98655" x="345.43484" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-366.2353" y="-1033.39397" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 557.563 846.734)" stroke="#b2b2b2" fontWeight="normal" id="WS260_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS260.status}_text cpil4_css1`}>260</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="171.25325" x2="381.62998" y1="171.25325" x1="343.46722" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS265" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS265.status} height="19.92463" width="30" y="175.98655" x="384.38078" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-373.78955" y="-1035.04984" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 600.719 847.787)" stroke="#b2b2b2" fontWeight="normal" id="WS265_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS265.status}_text cpil4_css1`}>265</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="171.25326" x2="415.99068" y1="171.25326" x1="377.82792" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect id="WS261" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS261.status} height="19.92463" width="30" y="200.96107" x="345.43485" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-61.4449" y="-1028.0646" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 387.688 868.32)" stroke="#b2b2b2" fontWeight="normal" id="WS261_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS261.status}_text cpil4_css1`}>261</text>
                    <rect id="WS262" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS262.status} height="19.92463" width="30" y="226.2488" x="345.43485" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-276.48628" y="-1020.16566" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 507.542 888.583)" stroke="#b2b2b2" fontWeight="normal" id="WS262_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS262.status}_text cpil4_css1`}>262</text>
                    <rect id="WS264" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS264.status} height="19.92463" width="30" y="200.96107" x="384.38079" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-68.99915" y="-1029.72047" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 430.844 869.373)" stroke="#b2b2b2" fontWeight="normal" id="WS264_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS264.status}_text cpil4_css1`}>264</text>
                    <rect id="WS263" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS263.status} height="19.92463" width="30" y="226.2488" x="384.38079" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="-284.04052" y="-1021.82152" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 550.698 889.633)" stroke="#b2b2b2" fontWeight="normal" id="WS263_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS263.status}_text cpil4_css1`}>263</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="250.65336" x2="381.62998" y1="250.65336" x1="343.46722" fillOpacity="null" fill="none" stroke="#999999" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="250.65337" x2="415.99068" y1="250.65337" x1="377.82792" fillOpacity="null" fill="none" stroke="#999999" />
                    <rect stroke="#cccccc" height="180.2356" width="193.54357" y="573.48737" x="29.75363" strokeWidth="1.5" fill="#cccccc" />
                    {/* <ellipse strokeWidth="2" ry="5" rx="5.49498" cy="90.04639" cx="80.15418" stroke="#b2b2b2" fill="none" />
                    <rect transform="rotate(-45 91.5105 80.3931)" stroke="#b2b2b2" height="10" width="10.25729" y="75.39312" x="86.38182" strokeWidth="2" fill="none" />
                    <rect transform="rotate(-45 69.5306 100.175)" stroke="#b2b2b2" height="10" width="10.25729" y="95.17503" x="64.40191" strokeWidth="2" fill="none" />
                    <rect transform="rotate(-45 70.2632 79.6605)" stroke="#b2b2b2" height="10" width="10.25729" y="74.66045" x="65.13457" strokeWidth="2" fill="none" />
                    <rect transform="rotate(-45 90.7778 100.175)" stroke="#b2b2b2" height="10" width="10.25729" y="95.17503" x="85.64915" strokeWidth="2" fill="none" /> */}
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" x="316.47714" y="250.75405" fontSize="18" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.533372 0 0 0.534535 206.091 185.152)" stroke="#cccccc" opacity="0.52" fontWeight="bold">STAIRCASE</text>
                    <path stroke="#b2b2b2" d="m144.1622,626.33193l32.43674,0l0,13.73741l-32.43674,0l0,-13.73741z" strokeWidth="2" fill="none" />
                    <path stroke="#b2b2b2" d="m150.38226,616.28413l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    <path stroke="#b2b2b2" d="m163.30084,616.28413l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    <g>
                        <path stroke="#b2b2b2" d="m72.87076,626.33192l32.43674,0l0,14.21587l-32.43674,0l0,-14.21587z" strokeWidth="2" fill="none" />
                        <path stroke="#b2b2b2" d="m78.61235,616.7626l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                        <path stroke="#b2b2b2" d="m92.0094,616.7626l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    </g>
                    <g>
                        <path stroke="#b2b2b2" d="m72.39229,661.73841l32.43674,0l0,14.21588l-32.43674,0l0,-14.21588z" strokeWidth="2" fill="none" />
                        <path stroke="#b2b2b2" d="m78.13389,652.16909l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                        <path stroke="#b2b2b2" d="m91.53093,652.16909l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    </g>
                    <g>
                        <path stroke="#b2b2b2" d="m144.64066,661.25994l32.43674,0l0,14.21588l-32.43674,0l0,-14.21588z" strokeWidth="2" fill="none" />
                        <path stroke="#b2b2b2" d="m150.38226,651.69062l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                        <path stroke="#b2b2b2" d="m163.7793,651.69062l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    </g>
                    <g>
                        <path stroke="#b2b2b2" d="m72.87075,699.05876l32.43674,0l0,14.21588l-32.43674,0l0,-14.21588z" strokeWidth="2" fill="none" />
                        <path stroke="#b2b2b2" d="m78.61235,689.48944l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                        <path stroke="#b2b2b2" d="m92.00939,689.48944l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    </g>
                    <g>
                        <path stroke="#b2b2b2" d="m145.11913,698.58029l32.43674,0l0,14.21588l-32.43674,0l0,-14.21588z" strokeWidth="2" fill="none" />
                        <path stroke="#b2b2b2" d="m150.86073,689.01097l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                        <path stroke="#b2b2b2" d="m164.25777,689.01097l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    </g>
                    <path transform="rotate(45 73.0728 1419.59)" d="m71.87652,1426.0959l4.17233,-4.01914l-8.06699,0l-8.06697,0l0,-2.48804l0,-2.48804l7.97443,0c4.38593,0 7.97442,-0.12611 7.97442,-0.28024c0,-0.15413 -1.74602,-1.96274 -3.88005,-4.01914l-3.88006,-3.73891l3.58636,0l3.58635,0l5.47717,5.2682l5.47717,5.2682l-5.48767,5.25812l-5.48767,5.25812l-3.77557,0l-3.77558,0l4.17234,-4.01914l0,0l-0.00001,0.00001z" fill="#faebd7" />
                    <path transform="rotate(-45 74.0436 1389.49)" d="m72.84733,1396.00108l4.17232,-4.01913l-8.06699,0l-8.06697,0l0,-2.48804l0,-2.48804l7.97443,0c4.38593,0 7.97442,-0.12611 7.97442,-0.28024c0,-0.15412 -1.74602,-1.96273 -3.88005,-4.01913l-3.88006,-3.73891l3.58636,0l3.58635,0l5.47717,5.2682l5.47717,5.2682l-5.48768,5.25811l-5.48767,5.25812l-3.77557,0l-3.77558,0l4.17234,-4.01914l0.00001,0z" fill="#faebd7" />
                    <path transform="rotate(135 40.0656 1419.59)" d="m38.86931,1426.09589l4.17232,-4.01913l-8.06699,0l-8.06697,0l0,-2.48804l0,-2.48804l7.97443,0c4.38593,0 7.97442,-0.12611 7.97442,-0.28024c0,-0.15412 -1.74602,-1.96273 -3.88005,-4.01913l-3.88006,-3.73891l3.58636,0l3.58635,0l5.47717,5.2682l5.47717,5.2682l-5.48768,5.25811l-5.48767,5.25812l-3.77557,0l-3.77558,0l4.17234,-4.01914l0.00001,0z" fill="#faebd7" />
                    <path transform="rotate(-135 41.0364 1389.49)" d="m39.84011,1396.00108l4.17232,-4.01913l-8.06699,0l-8.06697,0l0,-2.48804l0,-2.48804l7.97443,0c4.38593,0 7.97442,-0.12611 7.97442,-0.28024c0,-0.15412 -1.74602,-1.96273 -3.88005,-4.01913l-3.88006,-3.73891l3.58636,0l3.58635,0l5.47717,5.2682l5.47717,5.2682l-5.48768,5.25811l-5.48767,5.25812l-3.77557,0l-3.77558,0l4.17234,-4.01914l0.00001,0z" fill="#faebd7" />
                    <path stroke="#b2b2b2" d="m215.70744,1290.81053l29.37595,0l0,72.37106l-29.37595,0l0,-72.37106z" strokeWidth="2" fill="none" />
                    <path stroke="#b2b2b2" d="m221.44904,1281.24142l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    <path stroke="#b2b2b2" d="m232.67192,1281.24142l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    <path stroke="#b2b2b2" d="m221.44904,1367.96369l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    <path stroke="#b2b2b2" d="m232.67192,1367.96369l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    <path stroke="#b2b2b2" d="m248.99611,1293.48456l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    <path stroke="#b2b2b2" d="m248.99611,1353.61742l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    <path stroke="#b2b2b2" d="m248.99611,1303.20351l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    <path stroke="#b2b2b2" d="m248.99611,1314.31088l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    <path stroke="#b2b2b2" d="m248.99611,1324.02983l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    <path stroke="#b2b2b2" d="m248.99611,1333.74878l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    <path stroke="#b2b2b2" d="m248.99611,1343.46773l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    <g>
                        <path stroke="#b2b2b2" d="m204.56664,1293.48456l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                        <path stroke="#b2b2b2" d="m204.56664,1353.61742l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                        <path stroke="#b2b2b2" d="m204.56664,1303.20351l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                        <path stroke="#b2b2b2" d="m204.56664,1314.31088l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                        <path stroke="#b2b2b2" d="m204.56664,1324.02983l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                        <path stroke="#b2b2b2" d="m204.56664,1333.74878l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                        <path stroke="#b2b2b2" d="m204.56664,1343.46773l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    </g>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="276.35709" y="1507.95804" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 486.353 -151.439)" stroke="#cccccc" opacity="0.5" fontWeight="bold">MEETING ROOM</text>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="296.30517" y="1732.98232" fontSize="23" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.612651 0 0 0.698368 557.989 -410.432)" stroke="#cccccc" opacity="0.5" fontWeight="bold">8F</text>
                    <path transform="rotate(90 673.346 772.872)" stroke="#b2b2b2" d="m665.06622,736.68631l16.56062,0l0,72.37106l-16.56062,0l0,-72.37106z" strokeWidth="2" fill="none" />
                    <path transform="rotate(90 716.767 772.656)" stroke="#b2b2b2" d="m713.46707,769.85404l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    <path transform="rotate(90 629.621 772.656)" stroke="#b2b2b2" d="m626.32287,769.85403l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    <g>
                        <path transform="rotate(90 647.236 756.423)" stroke="#b2b2b2" d="m643.93629,753.62128l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                        <path transform="rotate(90 697.65 756.423)" stroke="#b2b2b2" d="m694.35023,753.62131l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                        <path transform="rotate(90 686.543 756.423)" stroke="#b2b2b2" d="m683.24287,753.62132l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                        <path transform="rotate(90 676.824 756.423)" stroke="#b2b2b2" d="m673.52388,753.62127l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                        <path transform="rotate(90 667.105 756.423)" stroke="#b2b2b2" d="m663.80495,753.62129l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                        <path transform="rotate(90 657.386 756.423)" stroke="#b2b2b2" d="m654.08601,753.62131l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    </g>
                    <g >
                        <path transform="rotate(90 648.09 789.743)" stroke="#b2b2b2" d="m644.79065,786.94112l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                        <path transform="rotate(90 698.504 789.743)" stroke="#b2b2b2" d="m695.20459,786.94115l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                        <path transform="rotate(90 687.396 789.743)" stroke="#b2b2b2" d="m684.09723,786.94116l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                        <path transform="rotate(90 677.677 789.743)" stroke="#b2b2b2" d="m674.37823,786.94112l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                        <path transform="rotate(90 667.959 789.743)" stroke="#b2b2b2" d="m664.6593,786.94114l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                        <path transform="rotate(90 658.24 789.743)" stroke="#b2b2b2" d="m654.94037,786.94115l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    </g>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="310.08049" y="1506.61402" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 444.022 -242.111)" stroke="#cccccc" opacity="0.5" fontWeight="bold">PANTRY</text>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="302.41608" y="1509.30205" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 544.449 -243.709)" stroke="#cccccc" opacity="0.5" fontWeight="bold">DISPATCH</text>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="316.21202" y="1525.43022" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 544.449 -242.855)" stroke="#cccccc" opacity="0.5" fontWeight="bold">AREA</text>
                    <rect id="WSL13" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WSL13.status} height="19.92463" width="30" y="662.31629" x="640.31797" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WSL14" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WSL14.status} height="19.92463" width="30" y="662.07094" x="602.08399" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <text fill="#000000" strokeWidth="0" x="250.91802" y="1022.65862" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 470.529 24.8436)" stroke="#b2b2b2" fontWeight="normal" id="WSL14_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WSL14.status}_text cpil4_css1`}>L14</text>
                    <text fill="#000000" strokeWidth="0" x="318.07193" y="1022.65862" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 471.132 24.8436)" stroke="#b2b2b2" fontWeight="normal" id="WSL13_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WSL13.status}_text cpil4_css1`}>L13</text>
                    <rect id="WSL11" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WSL11.status} height="19.92463" width="30" y="663.17064" x="732.5883" strokeWidth="1.5" stroke="#999999" fill="#999999" />
                    <rect id="WSL12" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WSL12.status} height="19.92463" width="30" y="662.92529" x="694.35432" strokeWidth="1.5" fill="#999999" stroke="#999999" />
                    <text fill="#000000" strokeWidth="0" x="250.91802" y="1018.62658" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 562.799 28.261)" stroke="#b2b2b2" fontWeight="normal" id="WSL12_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WSL12.status}_text cpil4_css1`}>L12</text>
                    <text fill="#000000" strokeWidth="0" x="318.07193" y="1018.62658" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 563.402 28.261)" stroke="#b2b2b2" fontWeight="normal" id="WSL11_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WSL11.status}_text cpil4_css1`}>L11</text>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="303.94897" y="1175.98648" fontSize="13" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 300.104 -427.396)" stroke="#cccccc" opacity="0.5" fontWeight="bold">LOUNGE AREA</text>
                    <g transform="rotate(45 496.386 303.445)" >
                        <ellipse stroke="#b2b2b2" strokeWidth="2" ry="3.69948" rx="4.12337" cy="303.5396" cx="496.11082" fill="none" />
                        <rect stroke="#b2b2b2" transform="matrix(0.530605 -0.523185 0.530605 0.523185 75.2564 418.84)" height="10" width="10.25729" y="282.59279" x="516.49764" strokeWidth="2" fill="none" />
                        <rect stroke="#b2b2b2" transform="matrix(0.530605 -0.523185 0.530605 0.523185 59.9294 411.628)" height="10" width="10.25729" y="302.37471" x="494.51773" strokeWidth="2" fill="none" />
                        <path stroke="#b2b2b2" d="m483.31437,295.92231l5.44257,-5.36646l5.30605,5.23185l-5.44257,5.36646l-5.30605,-5.23185z" strokeWidth="2" fill="none" />
                    </g>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" x="264.35915" y="624.60556" fontSize="18" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 -52.6623 338.589)" stroke="#cccccc" opacity="0.52" fontWeight="bold">OPEN AREA</text>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="327.34688" y="1102.37528" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 296.264 -15.2642)" stroke="#cccccc" opacity="0.5" fontWeight="bold">MEETING</text>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="143.80438" y="1418.27452" fontSize="23" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.612651 0 0 0.698368 400.699 -276.818)" stroke="#cccccc" opacity="0.5" fontWeight="bold">8E</text>
                    <path stroke="#b2b2b2" d="m485.29066,623.12305l23.91637,0l0,38.63127l-23.91637,0l0,-38.63127z" strokeWidth="2" fill="none" />
                    <path stroke="#b2b2b2" d="m493.69005,666.66371l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    <path stroke="#b2b2b2" d="m493.69005,613.09338l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    <path stroke="#b2b2b2" d="m473.9534,624.61238l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    <path stroke="#b2b2b2" d="m473.9534,634.33133l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    <path stroke="#b2b2b2" d="m473.9534,644.05028l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    <path stroke="#b2b2b2" d="m513.5586,633.53887l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    <path stroke="#b2b2b2" d="m513.5586,643.25782l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    <path stroke="#b2b2b2" d="m513.5586,652.97677l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="335.60955" y="1102.37528" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 296.264 -5.39584)" stroke="#cccccc" opacity="0.5" fontWeight="bold">ROOM</text>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="247.08094" y="797.54437" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 342.316 -77.6433)" stroke="#cccccc" opacity="0.5" fontWeight="bold">MEETING</text>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="126.62302" y="1276.29011" fontSize="23" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.612651 0 0 0.698368 412.541 -433.813)" stroke="#cccccc" opacity="0.5" fontWeight="bold">8D</text>
                    <g>
                        <path stroke="#b2b2b2" d="m485.29068,366.91423l23.91637,0l0,38.63127l-23.91637,0l0,-38.63127z" strokeWidth="2" fill="none" />
                        <path stroke="#b2b2b2" d="m493.69007,410.45489l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                        <path stroke="#b2b2b2" d="m493.69007,356.88456l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                        <path stroke="#b2b2b2" d="m473.95342,373.7799l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                        <path stroke="#b2b2b2" d="m473.95342,383.49885l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                        <path stroke="#b2b2b2" d="m473.95342,393.2178l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                        <path stroke="#b2b2b2" d="m514.08462,373.12201l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                        <path stroke="#b2b2b2" d="m514.08462,382.84096l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                        <path stroke="#b2b2b2" d="m514.08462,392.55991l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    </g>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="255.34362" y="797.54437" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 342.316 -67.775)" stroke="#cccccc" opacity="0.5" fontWeight="bold">ROOM</text>
                    <line transform="rotate(90 335.036 374.323)" stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="374.32352" x2="428.03862" y1="374.32352" x1="242.03397" fillOpacity="null" fill="none" opacity="0.45" />
                    <line transform="rotate(90 465.957 303.271)" stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="303.27064" x2="488.56414" y1="303.27064" x1="443.34897" fillOpacity="null" fill="none" opacity="0.45" />
                    <line transform="rotate(90 465.957 408.863)" stroke="#999999" strokeLinecap="null" strokeLinejoin="null" y2="408.86262" x2="523.76233" y1="408.86262" x1="408.15247" fillOpacity="null" fill="none" opacity="0.45" />
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" x="341.1464" y="411.98617" fontSize="18" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.533372 0 0 0.534535 206.091 185.152)" stroke="#cccccc" opacity="0.52" fontWeight="bold">A.H.U.</text>
                    <text fontWeight="bold" fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="475.03886" y="627.10039" fontSize="10" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.835067 0 0 0.834963 -52.2688 25.4987)" stroke="#cccccc" opacity="0.5">HE</text>
                    <text fontWeight="bold" fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="475.03886" y="627.10039" fontSize="10" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.835067 0 0 0.834963 33.0197 24.709)" stroke="#cccccc" opacity="0.5">SHE</text>
                    <text fontWeight="bold" fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="474.09317" y="627.10039" fontSize="10" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.835067 0 0 0.834963 -13.5731 -2.93081)" stroke="#cccccc" opacity="0.5">H.T.</text>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" x="137.48434" y="692.03632" fontSize="18" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.533372 0 0 0.534535 206.091 185.152)" stroke="#cccccc" opacity="0.52" fontWeight="bold">DOCTOR'S</text>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" x="155.2515" y="712.71957" fontSize="18" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.533372 0 0 0.534535 206.091 185.152)" stroke="#cccccc" opacity="0.52" fontWeight="bold">ROOM</text>
                    <path d="m293.50484,578.21246l6.26378,0l0,-6.00278l6.42545,0l0,6.00278l6.26378,0l0,6.15771l-6.26378,0l0,6.00278l-6.42545,0l0,-6.00278l-6.26378,0l0,-6.15771z" strokeOpacity="null" strokeWidth="0" stroke="#cccccc" fill="#999999" />
                    <rect height="12.47824" width="59.50871" y="665.20902" x="273.34124" fill="#faebd7" stroke="#faebd7" />
                    {/* <g>
                        <ellipse stroke="#b2b2b2" strokeWidth="2" ry="5" rx="5.49498" cy="87.8484" cx="158.54919" fill="none" />
                        <rect stroke="#b2b2b2" height="10" width="10.25729" y="73.19513" x="164.77682" strokeWidth="2" fill="none" transform="rotate(-45 169.905 78.1951)" />
                        <rect stroke="#b2b2b2" height="10" width="10.25729" y="92.97704" x="142.79691" strokeWidth="2" fill="none" transform="rotate(-45 147.926 97.977)" />
                        <path d="m143.52957,72.46246l10.25729,0l0,10l-10.25729,0l0,-10z" strokeWidth="2" stroke="#b2b2b2" fill="none" transform="rotate(-45 148.658 77.4625)" />
                        <rect stroke="#b2b2b2" height="10" width="10.25729" y="92.97704" x="164.04416" strokeWidth="2" fill="none" transform="rotate(-45 169.173 97.977)" />
                    </g> */}
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="337.7282" y="1098.09155" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 96.9083 23.1225)" stroke="#cccccc" opacity="0.5" fontWeight="bold">MEETING</text>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="347.87838" y="1095.60912" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 96.9083 32.9909)" stroke="#cccccc" opacity="0.5" fontWeight="bold">ROOM</text>
                    <ellipse ry="3.26371" rx="3.50456" cy="697.18006" cx="315.16825" fill="none" stroke="#b2b2b2" />
                    <rect height="10" width="10.25729" y="695.4198" x="313.93781" strokeWidth="2" fill="none" transform="matrix(0.450975 -0.461559 0.450975 0.461559 -137.352 514.862)" stroke="#b2b2b2" />
                    <rect height="10" width="10.25729" y="715.20171" x="291.9579" strokeWidth="2" fill="none" transform="matrix(0.450975 -0.461559 0.450975 0.461559 -150.379 508.499)" stroke="#b2b2b2" />
                    <path d="m304.2922,690.46l4.62578,-4.73434l4.50975,4.61559l-4.62578,4.73434l-4.50975,-4.61559z" fill="none" stroke="#b2b2b2" strokeWidth="1.25" />
                    <rect height="10" width="10.25729" y="715.20171" x="313.20515" strokeWidth="2" fill="none" transform="matrix(0.450975 -0.461559 0.450975 0.461559 -146.41 518.306)" stroke="#b2b2b2" />
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="143.80438" y="1412.88561" fontSize="23" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.612651 0 0 0.698368 190.297 -285.257)" stroke="#cccccc" opacity="0.5" fontWeight="bold">8B</text> 
                    <path stroke="#b2b2b2" d="m513.5586,624.07078l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    <path stroke="#b2b2b2" d="m473.9534,653.72769l6.59957,0l0,5.6035l-6.59957,0l0,-5.6035z" strokeWidth="2" fill="none" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="608.72004" x2="526.7462" y1="608.72004" x1="467.53861" fillOpacity="null" fill="none" opacity="0.45" stroke="#999999" transform="rotate(-180 497.142 608.72)" />
                    <rect id="CU04" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.CU04.status} height="62.4206" width="59.50871" y="602.84344" x="274.58852" fill="#cccccc" stroke="#b2b2b2" />
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" x="160" y="887.16163" fontSize="18" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.533372 0 0 0.534535 206.091 185.152)" stroke="#cccccc" opacity="0.52" fontWeight="bold" id="CU04_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.CU04.status}_text cpil4_css1`}>CU04</text>
                    <g>
                        <path transform="rotate(90 303.393 626.184)" strokeWidth="1.25" stroke="#b2b2b2" d="m296.45313,610.54628l13.88044,0l0,31.27498l-13.88044,0l0,-31.27498z" fill="none" />
                        <path transform="rotate(90 304.532 612.861)" strokeWidth="1.25" stroke="#b2b2b2" d="m301.23183,609.61266l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" fill="none" />
                        <path transform="rotate(90 288.451 640.557)" stroke="#b2b2b2" d="m285.15076,637.30784l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" />
                        <path transform="rotate(90 298.278 640.557)" stroke="#b2b2b2" d="m294.97808,637.30784l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" />
                        <path transform="rotate(90 308.105 640.557)" stroke="#b2b2b2" d="m304.8054,637.30784l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" />
                        <path transform="rotate(90 317.932 640.557)" stroke="#b2b2b2" d="m314.63272,637.30784l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" />
                    </g>
                    <rect id="CU05" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.CU05.status} height="59.19479" width="59.50871" y="406.60695" x="275.66379" fill="#cccccc" stroke="#b2b2b2" />
                    <rect id="CU06" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.CU06.status} height="62.4206" width="59.50871" y="344.24138" x="275.66379" fill="#cccccc" stroke="#b2b2b2" />
                    <rect id="CU07" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.CU07.status} height="62.4206" width="59.50871" y="281.87581" x="275.66379" fill="#cccccc" stroke="#b2b2b2" />
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" x="160" y="284.96388" fontSize="18" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.533372 0 0 0.534535 206.091 185.152)" stroke="#cccccc" opacity="0.52" fontWeight="bold" id="CU07_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.CU07.status}_text cpil4_css1`}>CU07</text>
                    <g>
                        <path transform="rotate(90 305.268 304.288)" strokeWidth="1.25" stroke="#b2b2b2" d="m298.32813,288.65051l13.88044,0l0,31.27499l-13.88044,0l0,-31.27499z" fill="none" />
                        <path transform="rotate(90 306.407 290.965)" strokeWidth="1.25" stroke="#b2b2b2" d="m303.10683,287.71689l6.59957,0l0,6.4969l-6.59957,0l0,-6.4969z" fill="none" />
                        <path transform="rotate(90 290.326 318.661)" stroke="#b2b2b2" d="m287.02576,315.41207l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" />
                        <path transform="rotate(90 300.153 318.661)" stroke="#b2b2b2" d="m296.85308,315.41207l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" />
                        <path transform="rotate(90 309.98 318.661)" stroke="#b2b2b2" d="m306.6804,315.41207l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" />
                        <path transform="rotate(90 319.807 318.661)" stroke="#b2b2b2" d="m316.50772,315.41207l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" />
                    </g>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" x="160" y="400.27339" fontSize="18" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.533372 0 0 0.534535 206.091 185.152)" stroke="#cccccc" opacity="0.52" fontWeight="bold" id="CU06_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.CU06.status}_text cpil4_css1`}>CU06</text>
                    <g>
                        <path transform="rotate(90 304.516 365.925)" strokeWidth="1.25" stroke="#b2b2b2" d="m297.57646,350.28748l13.88044,0l0,31.27498l-13.88044,0l0,-31.27498z" fill="none" />
                        <path transform="rotate(90 305.655 352.602)" strokeWidth="1.25" stroke="#b2b2b2" d="m302.35516,349.35386l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" fill="none" />
                        <path transform="rotate(90 289.574 380.298)" stroke="#b2b2b2" d="m286.27409,377.04904l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" />
                        <path transform="rotate(90 299.401 380.298)" stroke="#b2b2b2" d="m296.10141,377.04904l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" />
                        <path transform="rotate(90 309.228 380.298)" stroke="#b2b2b2" d="m305.92873,377.04904l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" />
                        <path transform="rotate(90 319.055 380.298)" stroke="#b2b2b2" d="m315.75605,377.04904l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" />
                    </g>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" x="160" y="511.79655" fontSize="18" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.533372 0 0 0.534535 206.091 185.152)" stroke="#cccccc" opacity="0.52" fontWeight="bold" id="CU05_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.CU05.status}_text cpil4_css1`}>CU05</text>
                    <g>
                        <path strokeWidth="1.25" stroke="#b2b2b2" d="m296.97913,411.90051l13.88044,0l0,31.27498l-13.88044,0l0,-31.27498z" fill="none" transform="rotate(90 303.919 427.538)" />
                        <path strokeWidth="1.25" stroke="#b2b2b2" d="m301.75783,410.96689l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" fill="none" transform="rotate(90 305.058 414.215)" />
                        <path stroke="#b2b2b2" d="m285.67676,438.66207l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" transform="rotate(90 288.977 441.911)" />
                        <path stroke="#b2b2b2" d="m295.50408,438.66207l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" transform="rotate(90 298.804 441.911)" />
                        <path stroke="#b2b2b2" d="m305.3314,438.66207l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" transform="rotate(90 308.631 441.911)" />
                        <path stroke="#b2b2b2" d="m315.15872,438.66207l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" transform="rotate(90 318.458 441.911)" />
                    </g>
                    <rect id="CU08" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.CU08.status} height="49.51738" width="58.74925" y="495.31659" x="467.06157" fill="#cccccc" stroke="#b2b2b2" />
                    <rect id="CU09" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.CU09.status} height="53.28081" width="59.08325" y="544.77894" x="466.83974" fill="#cccccc" stroke="#b2b2b2" />
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" x="525" y="668.25913" fontSize="18" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.533372 0 0 0.534535 206.091 185.152)" stroke="#cccccc" opacity="0.52" fontWeight="bold" id="CU08_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.CU08.status}_text cpil4_css1`}>CU08</text>
                    <g>
                        <path strokeWidth="1.25" stroke="#b2b2b2" d="m488.9708,498.69126l13.88044,0l0,31.27498l-13.88044,0l0,-31.27498z" fill="none" transform="rotate(90 495.911 514.329)" />
                        <path strokeWidth="1.25" stroke="#b2b2b2" d="m493.7495,497.75764l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" fill="none" transform="rotate(90 497.05 501.006)" />
                        <path stroke="#b2b2b2" d="m477.66843,525.45282l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" transform="rotate(90 480.969 528.702)" />
                        <path stroke="#b2b2b2" d="m487.49575,525.45282l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" transform="rotate(90 490.796 528.702)" />
                        <path stroke="#b2b2b2" d="m497.32307,525.45282l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" transform="rotate(90 500.623 528.702)" />
                        <path stroke="#b2b2b2" d="m507.15039,525.45282l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" transform="rotate(90 510.45 528.702)" />
                    </g>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" x="525" y="763.71116" fontSize="18" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.533372 0 0 0.534535 206.091 185.152)" stroke="#cccccc" opacity="0.52" fontWeight="bold" id="CU09_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.CU09.status}_text cpil4_css1`}>CU09</text>
                    <g>
                        <path strokeWidth="1.25" stroke="#b2b2b2" d="m489.49681,549.71371l13.88044,0l0,31.27498l-13.88044,0l0,-31.27498z" fill="none" transform="rotate(90 496.437 565.351)" />
                        <path strokeWidth="1.25" stroke="#b2b2b2" d="m494.27551,548.78009l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" fill="none" transform="rotate(90 497.576 552.028)" />
                        <path stroke="#b2b2b2" d="m478.19444,576.47527l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" transform="rotate(90 481.495 579.724)" />
                        <path stroke="#b2b2b2" d="m488.02176,576.47527l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" transform="rotate(90 491.322 579.724)" />
                        <path stroke="#b2b2b2" d="m497.84908,576.47527l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" transform="rotate(90 501.149 579.724)" />
                        <path stroke="#b2b2b2" d="m507.6764,576.47527l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" transform="rotate(90 510.976 579.724)" />
                    </g>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="596.94794" x2="401.05465" y1="596.94794" x1="334.25239" fillOpacity="null" fill="none" opacity="0.5" stroke="#999999" transform="rotate(-180 367.654 596.948)" />
                    <rect height="71.1661" width="59.24913" y="719.06986" x="466.78226" fill="#cccccc" stroke="#b2b2b2" />
                    <path strokeWidth="1.25" d="m493.72705,760.63377l9.51861,0l0,31.27498l-9.51861,0l0,-31.27498z" fill="none" stroke="#b2b2b2" transform="rotate(90 498.486 776.271)" />
                    <path stroke="#b2b2b2" d="m483.72667,727.71684l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" transform="rotate(90 487.027 730.966)" />
                    <path stroke="#b2b2b2" d="m503.07073,727.3203l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" transform="rotate(90 506.371 730.569)" />
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="330.1927" y="1111.73222" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 289.126 47.3876)" stroke="#cccccc" opacity="0.5" fontWeight="bold">RECEPTION</text>
                    <ellipse ry="3.26371" rx="3.50456" cy="730.88516" cx="496.77928" fill="none" stroke="#b2b2b2" />
                    {/* <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="337.7282" y="1098.09155" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 97.5494 118)" stroke="#cccccc" opacity="0.5" fontWeight="bold">MEETING</text>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="347.87838" y="1095.60912" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 97.5494 127.868)" stroke="#cccccc" opacity="0.5" fontWeight="bold">ROOM</text>
                    <ellipse ry="3.26371" rx="3.50456" cy="792.05734" cx="315.80931" fill="none" stroke="#b2b2b2" />
                    <rect height="10" width="10.25729" y="798.9097" x="211.86942" strokeWidth="2" fill="none" transform="matrix(0.450975 -0.461559 0.450975 0.461559 -137.352 514.862)" stroke="#b2b2b2" />
                    <rect height="10" width="10.25729" y="818.69161" x="189.88951" strokeWidth="2" fill="none" transform="matrix(0.450975 -0.461559 0.450975 0.461559 -150.379 508.499)" stroke="#b2b2b2" />
                    <path d="m304.93326,785.33728l4.62578,-4.73434l4.50975,4.61559l-4.62578,4.73434l-4.50975,-4.61559z" fill="none" stroke="#b2b2b2" strokeWidth="1.25" />
                    <rect height="10" width="10.25729" y="818.69161" x="211.13676" strokeWidth="2" fill="none" transform="matrix(0.450975 -0.461559 0.450975 0.461559 -146.41 518.306)" stroke="#b2b2b2" /> */}
                    {/* <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="143.80438" y="1412.88561" fontSize="23" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.612651 0 0 0.698368 190.938 -190.38)" stroke="#cccccc" opacity="0.5" fontWeight="bold">8D</text> */}
                    {/* <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="337.7282" y="1098.09155" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 97.5494 172.49)" stroke="#cccccc" opacity="0.5" fontWeight="bold">MEETING</text>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="347.87838" y="1095.60912" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 97.5494 182.358)" stroke="#cccccc" opacity="0.5" fontWeight="bold">ROOM</text> */}
                    {/* <ellipse ry="3.26371" rx="3.50456" cy="846.54767" cx="315.80931" fill="none" stroke="#b2b2b2" />
                    <rect height="10" width="10.25729" y="857.93826" x="152.84085" strokeWidth="2" fill="none" transform="matrix(0.450975 -0.461559 0.450975 0.461559 -137.352 514.862)" stroke="#b2b2b2" />
                    <rect height="10" width="10.25729" y="877.72017" x="130.86094" strokeWidth="2" fill="none" transform="matrix(0.450975 -0.461559 0.450975 0.461559 -150.379 508.499)" stroke="#b2b2b2" />
                    <path d="m304.93326,839.82761l4.62578,-4.73434l4.50975,4.61559l-4.62578,4.73434l-4.50975,-4.61559z" fill="none" stroke="#b2b2b2" strokeWidth="1.25" />
                    <rect height="10" width="10.25729" y="877.72017" x="152.10819" strokeWidth="2" fill="none" transform="matrix(0.450975 -0.461559 0.450975 0.461559 -146.41 518.306)" stroke="#b2b2b2" /> */}
                    {/* <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="143.80438" y="1412.88561" fontSize="23" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.612651 0 0 0.698368 190.938 -135.89)" stroke="#cccccc" opacity="0.5" fontWeight="bold">8C</text> */}
                    <rect height="8.23469" width="59.50871" y="599.131" x="467.93792" fill="#faebd7" stroke="#faebd7" />
                    <rect height="12.47824" width="59.50871" y="889.51049" x="272.73502" fill="#faebd7" stroke="#faebd7" />
                    <rect id="CU03" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.CU03.status} stroke="#b2b2b2" height="54.44908" width="59.50871" y="1009.70787" x="274.58852" fill="#cccccc" />
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" x="160" y="1637.47164" fontSize="18" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.533372 0 0 0.534535 206.091 185.152)" stroke="#cccccc" opacity="0.52" fontWeight="bold" id="CU03_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.CU03.status}_text cpil4_css1`}>CU03</text>
                    <g>
                        <path transform="rotate(90 304.118 1030.15)" strokeWidth="1.25" stroke="#b2b2b2" d="m297.17781,1014.51198l13.88044,0l0,31.27498l-13.88044,0l0,-31.27498z" fill="none" />
                        <path transform="rotate(90 305.257 1016.82)" strokeWidth="1.25" stroke="#b2b2b2" d="m301.95651,1013.57836l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" fill="none" />
                        <path transform="rotate(90 289.176 1044.52)" stroke="#b2b2b2" d="m285.87544,1041.27354l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" />
                        <path transform="rotate(90 299.003 1044.52)" stroke="#b2b2b2" d="m295.70276,1041.27354l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" />
                        <path transform="rotate(90 308.83 1044.52)" stroke="#b2b2b2" d="m305.53008,1041.27354l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" />
                        <path transform="rotate(90 318.657 1044.52)" stroke="#b2b2b2" d="m315.3574,1041.27354l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" />
                    </g>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" x="266.9804" y="1531.33478" fontSize="18" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.533372 0 0 0.534535 206.091 185.152)" stroke="#cccccc" opacity="0.52" fontWeight="bold">HE</text>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" x="421.55535" y="1529.06656" fontSize="18" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.533372 0 0 0.534535 206.091 185.152)" stroke="#cccccc" opacity="0.52" fontWeight="bold">SHE</text>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" x="328.35574" y="1611.85642" fontSize="18" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.533372 0 0 0.534535 206.091 185.152)" stroke="#cccccc" opacity="0.52" fontWeight="bold">H.T.</text>
                    <rect id="CU02" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.CU02.status} height="62.4206" width="58.90249" y="1095.70048" x="274.58852" fill="#cccccc" stroke="#b2b2b2" />
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" x="160" y="1809.19115" fontSize="18" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.533372 0 0 0.534535 206.091 185.152)" stroke="#cccccc" opacity="0.52" fontWeight="bold" id="CU02_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.CU02.status}_text cpil4_css1`}>CU02</text>
                    <g>
                        <path strokeWidth="1.25" stroke="#b2b2b2" d="m296.45313,1103.40332l13.88044,0l0,31.27498l-13.88044,0l0,-31.27498z" fill="none" transform="rotate(90 303.393 1119.04)" />
                        <path strokeWidth="1.25" stroke="#b2b2b2" d="m301.23183,1102.4697l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" fill="none" transform="rotate(90 304.532 1105.71)" />
                        <path stroke="#b2b2b2" d="m285.15076,1130.16488l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" transform="rotate(90 288.451 1133.41)" />
                        <path stroke="#b2b2b2" d="m294.97808,1130.16488l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" transform="rotate(90 298.278 1133.41)" />
                        <path stroke="#b2b2b2" d="m304.8054,1130.16488l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" transform="rotate(90 308.105 1133.41)" />
                        <path stroke="#b2b2b2" d="m314.63272,1130.16488l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" transform="rotate(90 317.932 1133.41)" />
                    </g>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="-11.87254" y="2095.02556" fontSize="13" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 296.71 -163.302)" stroke="#cccccc" opacity="0.5" fontWeight="bold">PANTRY</text>
                    <rect height="12.47823" width="63.75225" y="1175.04017" x="268.49148" fill="#faebd7" stroke="#faebd7" />
                    <g>
                        <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="-85.44017" y="2282.23829" fontSize="13" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 326.171 -226.131)" stroke="#cccccc" opacity="0.5" fontWeight="bold">LOUNGE AREA</text>
                        <g transform="rotate(45 305.427 1207.93)">
                            <ellipse stroke="#b2b2b2" strokeWidth="2" ry="3.69948" rx="4.12337" cy="1208.02015" cx="305.15145" fill="none" />
                            <rect stroke="#b2b2b2" transform="matrix(0.530605 -0.523185 0.530605 0.523185 75.2564 418.84)" height="10" width="10.25729" y="967.04621" x="-527.84569" strokeWidth="2" fill="none" />
                            <rect stroke="#b2b2b2" transform="matrix(0.530605 -0.523185 0.530605 0.523185 59.9294 411.628)" height="10" width="10.25729" y="986.82813" x="-549.8256" strokeWidth="2" fill="none" />
                            <path stroke="#b2b2b2" d="m292.355,1200.40286l5.44257,-5.36646l5.30605,5.23185l-5.44257,5.36646l-5.30605,-5.23185z" strokeWidth="2" fill="none" />
                        </g>
                    </g>
                    <rect id="CU01" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.CU01.status} height="54.97171" width="58.90249" y="1173.47093" x="466.76033" fill="#cccccc" stroke="#b2b2b2" />
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" x="525" y="1941.81384" fontSize="18" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.533372 0 0 0.534535 206.091 185.152)" stroke="#cccccc" opacity="0.52" fontWeight="bold" id="CU01_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.CU01.status}_text cpil4_css1`}>CU01</text>
                    <g>
                        <path strokeWidth="1.25" stroke="#b2b2b2" d="m489.23116,1178.60731l13.88044,0l0,31.27498l-13.88044,0l0,-31.27498z" fill="none" transform="rotate(90 496.171 1194.24)" />
                        <path strokeWidth="1.25" stroke="#b2b2b2" d="m494.00986,1177.67369l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" fill="none" transform="rotate(90 497.31 1180.91)" />
                        <path stroke="#b2b2b2" d="m477.92879,1205.36887l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" transform="rotate(90 481.229 1208.61)" />
                        <path stroke="#b2b2b2" d="m487.75611,1205.36887l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" transform="rotate(90 491.056 1208.61)" />
                        <path stroke="#b2b2b2" d="m497.58343,1205.36887l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" transform="rotate(90 500.883 1208.61)" />
                        <path stroke="#b2b2b2" d="m507.41075,1205.36887l6.59957,0l0,6.49689l-6.59957,0l0,-6.49689z" strokeWidth="1.25" fill="none" transform="rotate(90 510.71 1208.61)" />
                    </g>
                    <rect height="77.13358" width="58.23392" y="1095.72628" x="467.4436" fill="#cccccc" strokeWidth="0" stroke="#b2b2b2" />
                    {/* <rect id="WS041" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS041.status} height="19.92463" width="30" y="1143.04953" x="492.73313" strokeWidth="1.5" stroke="#999999" fill="#999999" transform="rotate(90 507.733 1153.01)" />
                    <rect id="WS040" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS040.status} height="19.92463" width="30" y="1106.77598" x="492.97856" strokeWidth="1.5" fill="#999999" stroke="#999999" transform="rotate(90 507.979 1116.74)" /> */}
                    {/* <text fill="#000000" strokeWidth="0" x="480.54993" y="1039.56714" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="rotate(90 506.624 1117.29) matrix(0.557352 0 0 0.635674 231.994 459.516)" stroke="#b2b2b2" fontWeight="normal" id="WS040_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS040.status}_text cpil4_css1`}>040</text>
                    <text fill="#000000" strokeWidth="0" x="480.54993" y="1042.03412" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="rotate(90 506.776 1153.07) matrix(0.557352 0 0 0.635674 232.386 493.232)" stroke="#b2b2b2" fontWeight="normal" id="WS041_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS041.status}_text cpil4_css1`}>041</text> */}
                    {/* <rect id="WS019" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS019.status} height="19.92463" width="30" y="967.10337" x="494.80602" strokeWidth="1.5" stroke="#999999" fill="#999999" transform="rotate(90 509.806 977.065)" /> */}
                    {/* <rect id="WS016" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS016.status} height="19.92463" width="30" y="928.86937" x="495.05129" strokeWidth="1.5" fill="#999999" stroke="#999999" transform="rotate(90 510.051 938.831)" /> */}
                    {/* <rect id="WS018" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS018.status} height="19.92463" width="30" y="967.10335" x="467.9214" strokeWidth="1.5" stroke="#999999" fill="#999999" transform="rotate(90 482.921 977.065)" /> */}
                    {/* <rect id="WS017" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS017.status} height="19.92463" width="30" y="928.86941" x="468.16672" strokeWidth="1.5" fill="#999999" stroke="#999999" transform="rotate(90 483.167 938.831)" /> */}
                    <rect stroke="#b2b2b2" fill="#cccccc" x="466.99546" y="1095.70049" width="58.90249" height="38.93788" id="svg_919"/>
                    <g id="svg_950">
                    <text fontWeight="bold" opacity="0.52" stroke="#cccccc" transform="matrix(0.533372 0 0 0.534535 206.091 185.152)"  fontFamily="ColgateReady, Helvetica, Arial"  fontSize="17" id="svg_920" y="1768.09427" x="510.36783" strokeOpacity="null" strokeWidth="0" fill="#000000">CABIN-D</text>
                    <path stroke="#b2b2b2" fill="none"  d="m503.92008,1104.89068l0,8.97578l-14.87717,0l0,-8.97578l14.87717,0z" id="svg_921" strokeWidth="1.25"/>
                    <path stroke="#b2b2b2" fill="none"  d="m498.44406,1098.6244l0,4.26761l-4.03466,0l0,-4.26761l4.03466,0z" id="svg_922" strokeWidth="1.25"/>
                    <path stroke="#b2b2b2" fill="none" strokeWidth="1.25"  d="m494.52061,1116.5366l0,4.26761l-4.03466,0l0,-4.26761l4.03466,0z" id="svg_924"/>
                    <path stroke="#b2b2b2" fill="none" strokeWidth="1.25"  d="m502.13835,1116.53679l0,4.26761l-4.03466,0l0,-4.26761l4.03466,0z" id="svg_927"/>
                    </g>
                    <text fontWeight="bold" opacity="0.52" stroke="#cccccc" transform="matrix(0.533372 0 0 0.534535 206.091 185.152)"  fontFamily="ColgateReady, Helvetica, Arial"  fontSize="17" id="svg_955" y="1840.3681" x="510.36783" strokeOpacity="null" strokeWidth="0" fill="#000000">CABIN-E</text>
                    <path stroke="#b2b2b2" fill="none"  d="m503.92008,1143.52357l0,8.97578l-14.87717,0l0,-8.97578l14.87717,0z" id="svg_956" strokeWidth="1.25"/>
                    <path stroke="#b2b2b2" fill="none"  d="m498.44406,1137.25729l0,4.26761l-4.03467,0l0,-4.26761l4.03467,0z" id="svg_979" strokeWidth="1.25"/>
                    <path stroke="#b2b2b2" fill="none" strokeWidth="1.25"  d="m494.52061,1155.16949l0,4.26761l-4.03467,0l0,-4.26761l4.03467,0z" id="svg_981"/>
                    <path stroke="#b2b2b2" fill="none" strokeWidth="1.25"  d="m502.13835,1155.16968l0,4.26761l-4.03466,0l0,-4.26761l4.03466,0z" id="svg_982"/>
                    <rect stroke="#b2b2b2" fill="#cccccc" x="274.5712" y="903.27624" width="58.90249" height="40.45304" id="svg_984"/>
                    <rect transform="rotate(90 514.283 960.11)" fill="#999999" strokeWidth="1.5" x="504.28223" y="953.7708" width="20" height="12.67779"  id="WSL15" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WSL15.status} stroke="#999999"/>
                    <rect transform="rotate(90 514.283 986.002)" fill="#999999" strokeWidth="1.5" x="504.28223" y="979.66314" width="20" height="12.67779" id="WSL20" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WSL20.status} stroke="#999999"/>
                    <rect transform="rotate(90 495.765 960.109)" fill="#999999" strokeWidth="1.5" x="485.76371" y="953.7708" width="20" height="12.67779" id="WSL16" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WSL16.status} stroke="#999999"/>
                    <rect transform="rotate(90 495.765 986.002)" fill="#999999" strokeWidth="1.5" x="485.76371" y="979.66314" width="20" height="12.67779" id="WSL19" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WSL19.status} stroke="#999999"/>
                    <rect transform="rotate(90 477.987 960.109)" fill="#999999" strokeWidth="1.5" x="467.98593" y="953.7708" width="20" height="12.67779" id="WSL17" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WSL17.status} stroke="#999999"/>
                    <rect transform="rotate(90 477.987 986.002)" fill="#999999" strokeWidth="1.5" x="467.98593" y="979.66314" width="20" height="12.67779" id="WSL18" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WSL18.status} stroke="#999999"/>
                    <text fontWeight="normal" stroke="#ffffff" transform="rotate(90 514.083 960.232) matrix(0.557352 0 0 0.635674 241.468 356.281)" fontFamily="ColgateReady, Helvetica, Arial" strokeWidth="0" id="WSL15_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WSL15.status}_text cpil4_css1`} y="955.05135" x="476.61167"  fill="#000000">L15</text>
                    <text fontWeight="normal" stroke="#ffffff" transform="rotate(90 514.083 986.124) matrix(0.557352 0 0 0.635674 241.468 381.571)" fontFamily="ColgateReady, Helvetica, Arial"strokeWidth="0" id="WSL20_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WSL20.status}_text cpil4_css1`} y="955.9986" x="475.61167"  fill="#000000">L20</text>
                    <text fontWeight="normal" stroke="#ffffff" transform="rotate(90 495.565 959.491) matrix(0.557352 0 0 0.635674 240.727 356.281)" fontFamily="ColgateReady, Helvetica, Arial" strokeWidth="0" id="WSL16_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WSL16.status}_text cpil4_css1`} y="953.88607" x="444.71482"  fill="#000000">L16</text>
                    <text fontWeight="normal" stroke="#ffffff" transform="rotate(90 495.564 986.124) matrix(0.557352 0 0 0.635674 240.727 381.571)" fontFamily="ColgateReady, Helvetica, Arial" strokeWidth="0" id="WSL19_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WSL19.status}_text cpil4_css1`} y="955.9986" x="444.71482"  fill="#000000">L19</text>
                    <text fontWeight="normal" stroke="#ffffff" transform="rotate(90 477.787 960.232) matrix(0.557352 0 0 0.635674 222.949 355.54)" fontFamily="ColgateReady, Helvetica, Arial" strokeWidth="0" id="WSL17_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WSL17.status}_text cpil4_css1`} y="956.21663" x="444.71482"  fill="#000000">L17</text>
                    <text fontWeight="normal" stroke="#ffffff" transform="rotate(90 477.787 986.124) matrix(0.557352 0 0 0.635674 222.949 380.83)" fontFamily="ColgateReady, Helvetica, Arial" strokeWidth="0" id="WSL18_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WSL18.status}_text cpil4_css1`} y="957.16388" x="444.71482"  fill="#000000">L18</text>
                    <g id="svg_1421">
                    <text fontWeight="bold" opacity="0.52" stroke="#cccccc" transform="matrix(0.533372 0 0 0.534535 206.091 185.152)" fontFamily="ColgateReady, Helvetica, Arial"   fontSize="17" id="svg_985" y="1410.52478" x="149.5985" strokeOpacity="null" strokeWidth="0" fill="#000000">CABIN-A</text>
                    <path stroke="#b2b2b2" fill="none"  d="m311.49582,912.46642l0,8.97578l-14.87717,0l0,-8.97578l14.87717,0z" id="svg_986" strokeWidth="1.25"/>
                    <path stroke="#b2b2b2" fill="none"  d="m306.0198,906.20014l0,4.26761l-4.03467,0l0,-4.26761l4.03467,0z" id="svg_987" strokeWidth="1.25"/>
                    <path stroke="#b2b2b2" fill="none" strokeWidth="1.25"  d="m302.09635,924.11234l0,4.26761l-4.03467,0l0,-4.26761l4.03467,0z" id="svg_988"/>
                    <path stroke="#b2b2b2" fill="none" strokeWidth="1.25"  d="m309.71409,924.11253l0,4.26761l-4.03466,0l0,-4.26761l4.03466,0z" id="svg_989"/>
                    </g>
                    <text fontWeight="bold" opacity="0.52" stroke="#cccccc" transform="matrix(0.533372 0 0 0.534535 206.091 185.152)" fontFamily="ColgateReady, Helvetica, Arial"   fontSize="17" id="svg_1424" y="1294.1204" x="148.20971" strokeOpacity="null" strokeWidth="0"  fill="#000000">CABIN-B</text>
                    <path stroke="#b2b2b2" fill="none"  d="m310.75508,848.02198l0,8.97578l-14.87717,0l0,-8.97578l14.87717,0z" id="svg_1425" strokeWidth="1.25"/>
                    <path stroke="#b2b2b2" fill="none"  d="m305.27906,841.7557l0,4.26761l-4.03467,0l0,-4.26761l4.03467,0z" id="svg_1430" strokeWidth="1.25"/>
                    <path stroke="#b2b2b2" fill="none" strokeWidth="1.25"  d="m301.35561,859.6679l0,4.26761l-4.03467,0l0,-4.26761l4.03467,0z" id="svg_1431"/>
                    <path stroke="#b2b2b2" fill="none" strokeWidth="1.25"  d="m308.97335,859.66809l0,4.26761l-4.03466,0l0,-4.26761l4.03466,0z" id="svg_1432"/>
                    <text fontWeight="bold" opacity="0.52" stroke="#cccccc" transform="matrix(0.533372 0 0 0.534535 206.091 185.152)" fontFamily="ColgateReady, Helvetica, Arial"   fontSize="17" id="svg_1436" y="1188.80215" x="148.20971" strokeOpacity="null" strokeWidth="0" fill="#000000">CABIN-C</text>
                    <path stroke="#b2b2b2" fill="none"  d="m310.75508,791.72569l0,8.97578l-14.87717,0l0,-8.97578l14.87717,0z" id="svg_1437" strokeWidth="1.25"/>
                    <path stroke="#b2b2b2" fill="none"  d="m305.27906,785.45941l0,4.26761l-4.03467,0l0,-4.26761l4.03467,0z" id="svg_1438" strokeWidth="1.25"/>
                    <path stroke="#b2b2b2" fill="none" strokeWidth="1.25"  d="m301.35561,803.37161l0,4.26761l-4.03467,0l0,-4.26761l4.03467,0z" id="svg_1439"/>
                    <path stroke="#b2b2b2" fill="none" strokeWidth="1.25"  d="m308.97335,803.3718l0,4.26761l-4.03466,0l0,-4.26761l4.03466,0z" id="svg_1440"/>                    
                    <line strokeLinecap="null" strokeLinejoin="null" y2="880" x2="525.711" y1="880" x1="465.97659" fillOpacity="null" fill="none" stroke="#999999" opacity="0.5" transform="rotate(-180 495.844 908.05)" />
                    <rect stroke="#b2b2b2" height="65.36903" width="58.23392" y="943.4" x="274.9483" fill="#e5e5e5" strokeWidth="0" />
                    {/* <rect id="WS161" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS161.status} height="19.92463" width="30" y="913.57952" x="303.34008" strokeWidth="1.5" fill="#999999" stroke="#999999" transform="rotate(90 318.34 923.541)" /> */}
                    {/* <rect id="WS160" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WS160.status} height="19.92463" width="30" y="913.57956" x="276.45552" strokeWidth="1.5" fill="#999999" stroke="#999999" transform="rotate(90 291.456 923.541)" /> */}
                    {/* <text fill="#000000" strokeWidth="0" x="476.61167" y="959.49434" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="rotate(90 291.635 924.009) matrix(0.557352 0 0 0.635674 19.1957 317.128)" stroke="#b2b2b2" fontWeight="normal" id="WS160_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS160.status}_text cpil4_css1`}>160</text> */}
                    {/* <text fill="#000000" strokeWidth="0" x="476.61167" y="959.49434" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="rotate(90 317.616 924.185) matrix(0.557352 0 0 0.635674 45.0707 317.128)" stroke="#b2b2b2" fontWeight="normal" id="WS161_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS161.status}_text cpil4_css1`}>161</text> */}
                    <line strokeLinecap="null" strokeLinejoin="null" y2="1007.46904" x2="525.38452" y1="935.5" x1="525.38452" fillOpacity="null" fill="none" stroke="#999999" opacity="0.5" />
                    <line strokeLinecap="null" strokeLinejoin="null" y2="945.10076" x2="334.15285" y1="945.10076" x1="273.80606" fillOpacity="null" fill="none" stroke="#999999" opacity="0.5" transform="rotate(-180 303.979 944.101)" />
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="444.06125" y="1463.81081" fontSize="16" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 118.298 -48.7338)" stroke="#cccccc" opacity="0.5" fontWeight="bold">SERVICE ROOM</text>
                    <rect height="23.59722" width="59.24914" y="871.87459" x="466.68806" fill="#cccccc" stroke="#b2b2b2" />
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="330.1927" y="1150.06241" fontSize="13" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 288.111 156.017)" stroke="#cccccc" opacity="0.5" fontWeight="bold">STORE ROOM</text>
                    <rect stroke="#b2b2b2" height="80.30646" width="58.23392" y="791.06861" x="467.55917" fill="#e5e5e5" strokeWidth="0" />
                    <rect id="WSL03" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WSL03.status} height="19.92463" width="30" y="841.21492" x="495.31364" strokeWidth="1.5" stroke="#999999" fill="#999999" transform="rotate(90 510.314 851.177)" />
                    <rect id="WSL02" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WSL02.status} height="19.92463" width="30" y="802.98092" x="495.5589" strokeWidth="1.5" fill="#999999" stroke="#999999" transform="rotate(90 510.559 812.943)" />
                    <rect id="WSL04" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WSL04.status} height="19.92463" width="30" y="841.2149" x="468.42901" strokeWidth="1.5" stroke="#999999" fill="#999999" transform="rotate(90 483.429 851.177)" />
                    <rect id="WSL01" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WSL01.status} height="19.92463" width="30" y="802.98096" x="468.67434" strokeWidth="1.5" fill="#999999" stroke="#999999" transform="rotate(90 483.675 812.943)" />
                    {/* <text fill="#000000" strokeWidth="0" x="476.61167" y="960.65959" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="rotate(90 510.272 938.718) matrix(0.557352 0 0 0.635674 237.657 331.202)" stroke="#b2b2b2" fontWeight="normal" id="WS016_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS016.status}_text cpil4_css1`}>016</text> */}
                    {/* <text fill="#000000" strokeWidth="0" x="476.61167" y="959.49434" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="rotate(90 482.865 938.718) matrix(0.557352 0 0 0.635674 210.251 331.943)" stroke="#b2b2b2" fontWeight="normal" id="WS017_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS017.status}_text cpil4_css1`}>017</text>
                    <text fill="#000000" strokeWidth="0" x="476.61167" y="960.65959" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="rotate(90 510.272 975.753) matrix(0.557352 0 0 0.635674 237.657 368.238)" stroke="#b2b2b2" fontWeight="normal" id="WS019_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS019.status}_text cpil4_css1`}>019</text>
                    <text fill="#000000" strokeWidth="0" x="476.61167" y="959.49434" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="rotate(90 482.866 975.754) matrix(0.557352 0 0 0.635674 210.251 368.979)" stroke="#b2b2b2" fontWeight="normal" id="WS018_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WS018.status}_text cpil4_css1`}>018</text> */}
                    <text fill="#000000" strokeWidth="0" x="476.61167" y="957.16385" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="rotate(90 511.013 812.055) matrix(0.557352 0 0 0.635674 238.398 206.762)" stroke="#b2b2b2" fontWeight="normal" id="WSL02_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WSL02.status}_text cpil4_css1`}>L02</text>
                    <text fill="#000000" strokeWidth="0" x="476.61167" y="955.9986" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="rotate(90 483.657 812.055) matrix(0.557352 0 0 0.635674 210.992 207.503)" stroke="#b2b2b2" fontWeight="normal" id="WSL01_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WSL01.status}_text cpil4_css1`}>L01</text>
                    <text fill="#000000" strokeWidth="0" x="476.61167" y="960.65959" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="rotate(90 511.013 851.313) matrix(0.557352 0 0 0.635674 238.398 243.798)" stroke="#b2b2b2" fontWeight="normal" id="WSL03_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WSL03.status}_text cpil4_css1`}>L03</text>
                    <text fill="#000000" strokeWidth="0" x="476.61167" y="959.49434" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="rotate(90 483.607 851.314) matrix(0.557352 0 0 0.635674 210.992 244.539)" stroke="#b2b2b2" fontWeight="normal" id="WSL04_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WSL04.status}_text cpil4_css1`}>L04</text>
                    <line strokeLinecap="null" strokeLinejoin="null" y2="880.0659" x2="526.12524" y1="780.87603" x1="526.12524" fillOpacity="null" fill="none" stroke="#999999" opacity="0.5" />
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="320.84575" y="1102.37528" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 246.986 -46.4256)" stroke="#cccccc" opacity="0.5" fontWeight="bold">SERVICE</text>
                    <text fill="#000000" strokeWidth="0" strokeOpacity="null" fillOpacity="null" x="336.90978" y="1102.37528" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="matrix(0.557352 0 0 0.635674 246.986 -36.5572)" stroke="#cccccc" opacity="0.5" fontWeight="bold">LIFT</text>
                    <rect id="WSL10" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WSL10.status} transform="rotate(90 286.889 963.076)" stroke="#999999" height="12.67779" width="20" y="956.73714" x="276.88916" strokeWidth="1.5" fill="#999999" />
                    <rect id="WSL09" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WSL09.status} transform="rotate(90 304.653 963.073)" stroke="#999999" height="12.67779" width="20" y="956.73376" x="294.65262" strokeWidth="1.5" fill="#999999" />
                    <rect id="WSL08" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WSL08.status} transform="rotate(90 322.717 963.073)" stroke="#999999" height="12.67779" width="20" y="956.73376" x="312.71704" strokeWidth="1.5" fill="#999999" />
                    <rect id="WSL05" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WSL05.status} transform="rotate(90 286.889 988.969)" stroke="#999999" height="12.67779" width="20" y="982.62949" x="276.88916" strokeWidth="1.5" fill="#999999" />
                    <rect id="WSL06" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WSL06.status} transform="rotate(90 304.653 988.965)" stroke="#999999" height="12.67779" width="20" y="982.6261" x="294.65262" strokeWidth="1.5" fill="#999999" />
                    <rect id="WSL07" data-tip="" onClick={(e) => { seatSelected(e) }} className={floorState.WSL07.status} transform="rotate(90 322.717 988.965)" stroke="#999999" height="12.67779" width="20" y="982.6261" x="312.71704" strokeWidth="1.5" fill="#999999" />
                    <text fill="#000000" strokeWidth="0" x="475" y="955.05135" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="rotate(90 322.231 963.144) matrix(0.557352 0 0 0.635674 49.6165 359.244)" stroke="#b2b2b2" fontWeight="normal" id="WSL08_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WSL08.status}_text cpil4_css1`}>L08</text>
                    <text fill="#000000" strokeWidth="0" x="475" y="955.9986" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="rotate(90 322.231 989.036) matrix(0.557352 0 0 0.635674 49.6165 384.534)" stroke="#b2b2b2" fontWeight="normal" id="WSL07_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WSL07.status}_text cpil4_css1`}>L07</text>
                    <text fill="#000000" strokeWidth="0" x="475" y="955.05135" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="rotate(90 304.166 963.144) matrix(0.557352 0 0 0.635674 31.5521 359.244)" stroke="#b2b2b2" fontWeight="normal" id="WSL09_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WSL09.status}_text cpil4_css1`}>L09</text>
                    <text fill="#000000" strokeWidth="0" x="475" y="955.9986" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="rotate(90 304.166 989.036) matrix(0.557352 0 0 0.635674 31.5521 384.534)" stroke="#b2b2b2" fontWeight="normal" id="WSL06_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WSL06.status}_text cpil4_css1`}>L06</text>
                    <text fill="#000000" strokeWidth="0" x="476" y="955.05135" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="rotate(90 286.102 963.144) matrix(0.557352 0 0 0.635674 13.4877 359.244)" stroke="#b2b2b2" fontWeight="normal" id="WSL10_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WSL10.status}_text cpil4_css1`}>L10</text>
                    <text fill="#000000" strokeWidth="0" x="475" y="955.9986" fontSize="15" fontFamily="ColgateReady, Helvetica, Arial" textAnchor="start" transform="rotate(90 286.102 989.036) matrix(0.557352 0 0 0.635674 13.4877 384.534)" stroke="#b2b2b2" fontWeight="normal" id="WSL05_text" data-tip="" onClick={(e) => { seatSelected(e) }} className={`${floorState.WSL05.status}_text cpil4_css1`}>L05</text>

                </g>

            </svg>
            {/* </UncontrolledReactSVGPanZoom> */}
        </div>
    )
}