import React from 'react';

import AssignOrgSpace from './assign-org-space';
import '../../BlockBookings/block-bookings.css'
import './floor-div.css'

export default function Org_Division(props) {

    const onCancelBlock = () => {
        props.handleCancelBlock(props.blockBooking)
    }

    return (
        <div className='block-bookings'>
            <AssignOrgSpace
                commonState={props.commonState}
                setfloor={(floor) => props.setfloor(floor)}
                reserveBlock={(newBlock) => props.handleReseveBlock(newBlock)} 
                blockBookings={props.blockBookings}
                handleReseveBlock={(newBlock)=>props.onReseveBlock(newBlock)}
                handleCancelBlock={(Block)=>onCancelBlock(Block)}
                handleReleaseSeats={(Block)=>props.onReleaseSeats(Block)}                
                
                />

            <div className='block-bookings-content'>
            </div>
        </div>
    )
}



