import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { useHistory } from 'react-router-dom';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { confirmAlert } from 'react-confirm-alert';
// import EditUserPage from './edit-user';
import Loader from '../../Loader/loader';
import MenuItem from '@material-ui/core/MenuItem';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
// import FilterListIcon from '@material-ui/icons/FilterList';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import { firebase } from '../../../config/firebase';
// import MenuIcon from '@material-ui/icons/Menu';
// import Modal from "@material-ui/core/Modal";
import InputBase from '@material-ui/core/InputBase';
import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';
// import SaveIcon from '@material-ui/icons/Save';
import './user-table.css';



function createData(userEmail, userName, site, category, blockAdminEmail, blockAdmin, declaration_last_status,
  declaration_last_updated, isAppAdmin, isBlockAdmin, cubicleBooking, profile_created, userPhoto) {

  // console.log(userEmail, userName, site, category, blockAdminEmail, blockAdmin, declaration_last_status,
  //   declaration_last_updated, isAppAdmin, isBlockAdmin, isBlockAdmin, userPhoto);
  let temp_v = true;
  return {
    userEmail, userName, site, category, blockAdminEmail, blockAdmin, temp_v,
   isAppAdmin, isBlockAdmin, cubicleBooking, profile_created, userPhoto
  };
}


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


const headCells = [

  { id: 'userName', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'userEmail', numeric: false, disablePadding: true, label: 'Email ID' },
  { id: 'site', numeric: false, disablePadding: true, label: 'Site' },
  { id: 'category', numeric: false, disablePadding: true, label: 'Category' },
  { id: 'blockAdminEmail', numeric: false, disablePadding: true, label: 'Block Admin Email ID' },
  { id: 'blockAdmin', numeric: false, disablePadding: true, label: 'Block Admin' },
  { id: 'ws', numeric: false, disablePadding: true, label: 'Workstation' },
  { id: 'floor', numeric: false, disablePadding: true, label: 'Floor' },
  { id: 'wing', numeric: false, disablePadding: true, label: 'Wing' },
  // { id: 'declaration_last_status', numeric: false, disablePadding: true, label: 'Declaration Status' },
  // { id: 'declaration_last_updated', numeric: false, disablePadding: true, label: 'Declaration Updated On' },
  { id: 'isAppAdmin', numeric: false, disablePadding: true, label: 'Site Admin' },
  { id: 'isBlockAdmin', numeric: false, disablePadding: true, label: 'Block Admin' },
  { id: 'cubicleBooking', numeric: false, disablePadding: true, label: 'Cubicle Booking Access' },

  { id: 'profile_created', numeric: false, disablePadding: true, label: 'Created On' },
  // { id: 'userPhoto', numeric: false, disablePadding: true, label: 'User Photo' },

];



function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className='site-table-header'>
      <TableRow className='site-table-header'>
        <TableCell padding="checkbox" className='site-table-header' >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell, index) => (
          <TableCell className='site-table-header'
            key={index}
            style={{ color: '#000', fontWeight: 'bold' }}
            // className="table-head"
            // align={headCell.numeric ? 'right' : 'left'}
            align={'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};



const useToolbarStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    background: 'whitesmoke'
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 80%',
  },
}));




const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: 'auto',
    marginTop: '1%'

  },
  root3: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: '0px',
    margin: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: '90%',
    // paddingTop: '10px',
    // marginLeft: '20px',

    marginTop: '1%'
    // marginBottom: theme.spacing(2),

  },
  paper2: {
    width: '45%',
    paddingTop: '10px',
    marginLeft: '2px',
    // marginBottom: theme.spacing(2),

  },
  table: {
    // minWidth: 500,
    width: '100%',
    whiteSpace: 'nowrap',
    marginTop: '1%',

  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
    float: 'left',
  },
  inputSearch: {
    width: '80%',
    marginLeft: '20px',
  },
  paper1: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,

    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  root1: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  rowC:
  {
    display: 'flex',
    flexDirection: 'row'
  },
  table_border: {
    // border: '1px solid black',
    // borderCollapse: 'collapse',
  }

}));


export default function UserTable(props) {
  const classes = useStyles();

  const [order, setOrder] = React.useState('asc');
  // const [childorder, childsetOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  // const [childorderBy, childsetOrderBy] = React.useState('name');
  const [availableSites, setAvailableSites] = useState([{ userName: 'Click Search' }])
  // const [availableFloors, setAvailableFloors] = useState([])
  const [availableFloorsjson, setAvailableFloorsjson] = useState({});
  // const [availableSiteFloors, setAvailableSiteFloors] = useState([])
  let test_try = {};
  const [reload_counter, setreloadcounter] = useState(0);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  // const [dense, setDense] = React.useState(true);
  const dense = true;
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const [blockAdmindropDown, setblockAdmindropDown] = React.useState([]);
  // const [editUser, seteditUser] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [allSites, setAllSites] = useState([])
  const [categoryDropDown, setCategoryDropDown] = useState([]);
  const [selectedUserEmail, setselectedUserEmail] = useState('');
  const blockCategory = [{ value: '', label: '-Select-' },{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }];
  const users_ref = firebase.firestore().collection('Users');
  const [currency, setCurrency] = React.useState(props.commonState.site);
  const [block_admin_select, setBlock_admin_select] = React.useState('');
  const [block_admin_role_select, setBlock_admin_role_select] = React.useState('');
  const [site_admin_role_select, setSite_admin_role_select] = React.useState('');
  const [cubicle_role_select, setCubicle_role_select] = React.useState('');
  const [category_select, setBlock_category_select] = React.useState('');
  const [username_select, setBlock_username_select] = React.useState('');

  const categories_ref = firebase.firestore().collection('Categories/' + props.commonState.site + '/Category');
  const sites_ref = firebase.firestore().collection('Sites');
  useEffect(() => {
    sites_ref
      .get()
      .then(
        querySnapshot => {
          const tempSites = []
          querySnapshot.forEach(doc => {
            const site = doc.id;
            tempSites.push({ value: site, label: site });

          }
          );
          setAllSites([...tempSites])
        }
      )



    users_ref
      .where("site", "==", props.commonState.site)
      .where("isBlockAdmin", "==", true)
      .get()
      .then(
        querySnapshot => {
          const temp_arr = [{ value: '', label: '-Select-' }]
          querySnapshot.forEach(doc => {
            // console.log(doc.id)
            // const site = doc.id;
            const temp_data = doc.data();
            temp_arr.push({ value: temp_data.userName, label: temp_data.userName });
          });
          setblockAdmindropDown([...temp_arr])
        }
      )

    categories_ref
      .get()
      .then(
        querySnapshot => {
          const temp_arr_cat = []
          querySnapshot.forEach(doc => {
            // console.log(doc.id)
            // const site = doc.id;
            const temp_data = doc.data();
            temp_arr_cat.push({ value: temp_data.category, label: temp_data.category });
          });
          setCategoryDropDown([...temp_arr_cat])
        }
      )

    fetchResults()
  }, []);


  const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    // const { numSelected } = props;

    return (
      <Toolbar
        className={classes.root3}
      >
        <Paper component="form" className='inputSearchcss'>
          <IconButton type="submit" className='adminiconButton' aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            className='admininput'
            placeholder="Search a user"
            inputProps={{ 'aria-label': 'Search a user' }}
          />

        </Paper>


        <div style={{ display: 'flex' }} >
          <Tooltip title="Add site" className='adminaddicon'>
            <div style={{ backgroundColor: '#d77373', marginLeft: '2px', borderRadius: '2px' }}>
              <IconButton aria-label="filter list" className='adminaddicon'>
                <AddIcon />
              </IconButton>
            </div>
          </Tooltip>

          <Tooltip title="Edit site details" className='adminaddicon'>
            <div style={{ backgroundColor: '#d77373', marginLeft: '2px', borderRadius: '2px' }}>
              <IconButton aria-label="filter list" className='adminaddicon'>
                <EditIcon />
              </IconButton>
            </div>
          </Tooltip>
          <Tooltip title="Delete site" className='adminaddicon'>
            <div style={{ backgroundColor: '#d77373', marginLeft: '2px', borderRadius: '2px' }}>
              <IconButton aria-label="filter list" className='adminaddicon'>
                <DeleteIcon />
              </IconButton>
            </div>
          </Tooltip>
        </div>

      </Toolbar>
    );
  };
  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };
  // const handleOpen = () => {
  //   console.log('open');
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   console.log('close');
  //   setOpen(false);
  // };

  // const saveSiteData = () => {
  //   handleClose();
  //   alert('Data saved');
  //   // const site_temp = valueRef.current.value;
  //   // console.log(site_temp);
  // };
  // const valueRef = useRef(''); //creating a refernce for TextField Component

  // const sendValue = () => {
  //     return console.log(valueRef.current.value) //on clicking button accesing current value of TextField and outputing it to console 
  // }

  const fetchResults = () => {

    setLoading(true)
    // useEffect(() => {

    const block_admin_filter = block_admin_select && block_admin_select !== "" ? users_ref.where( "blockAdmin", "==", block_admin_select )  : users_ref;
    
    let block_admin_role_select_bool = block_admin_role_select === "Yes" ? true : false;
    const block_admin_role_filter =  block_admin_role_select !== "" ? block_admin_filter.where( "isBlockAdmin", "==", block_admin_role_select_bool )  : block_admin_filter;

    let site_admin_role_select_bool = site_admin_role_select === "Yes" ? true : false;
    const site_admin_role_filter = site_admin_role_select !== "" ? block_admin_role_filter.where( "isSiteAdmin", "==", site_admin_role_select_bool )  : block_admin_role_filter;
    
    let cubicle_role_select_bool = cubicle_role_select === "Yes" ? true : false;
    const cubicle_role_filter = cubicle_role_select !== "" ? site_admin_role_filter.where( "cubicleBookingAccess", "==", cubicle_role_select_bool )  : site_admin_role_filter;
    
    const category_filter = category_select ? cubicle_role_filter.where( "category", "==", category_select )  : cubicle_role_filter;
    const username_filter = username_select ? category_filter.where( "userName", "==", username_select )  : category_filter;

    username_filter
      .where("site", "==", props.commonState.site)
      .get()
      .then(
        querySnapshot => {
          const tempSites = []
          // const tempSitesFloor = []

          querySnapshot.forEach(doc => {
            if (doc.data()) {
              let temp_user = doc.data();
              let app_ad = '';
              let block_ad = '';
              let cubicle_b = '';
              if (temp_user.isSiteAdmin) {
                app_ad = 'Yes';
              }
              else {
                app_ad = 'No';
              }
              if (temp_user.isBlockAdmin) {
                block_ad = 'Yes';
              }
              else {
                block_ad = 'No';
              }
              if (temp_user.cubicleBookingAccess) {
                cubicle_b = 'Yes';
              }
              else {
                cubicle_b = 'No';
              }
              let date1 = temp_user.profile_created.toDate().toLocaleDateString();
              tempSites.push(createData(temp_user.userEmail, temp_user.userName, temp_user.site,
                temp_user.category, temp_user.blockAdminEmail, temp_user.blockAdmin,
                temp_user.declaration_last_status, temp_user.declaration_last_updated,
                app_ad, block_ad, cubicle_b, date1,
                temp_user.userPhoto));
            }
          }

          );
          setAvailableSites([...tempSites])
          // setAvailableFloors([...tempSitesFloor])
          setAvailableFloorsjson({ ...test_try })
          setLoading(false)
        }
      )


    // }, []);
  }
  const handleChange = (event) => {
    // console.log('Change Site');
    // console.log(event.target.value);
    setCurrency(event.target.value);
  };


 const handleChangeBlockAdmin = (event) =>{
   setBlock_admin_select(event.target.value);
 }

 const handleChangeBlockAdminRole = (event) =>{
  setBlock_admin_role_select(event.target.value);
}
const handleChangeSiteAdminRole = (event) =>{
 setSite_admin_role_select(event.target.value);
}
const handleChangeCubicleRole = (event) =>{
  setCubicle_role_select(event.target.value);
}
const handleChangeCategory = (event) =>{
  setBlock_category_select(event.target.value);
}
const handleChangeUsername = (event) =>{
  setBlock_username_select(event.target.value);
}


  const loadfilteroptions = () => {
    return (
      <div className="user-items">
        <div className="user-display-filter-item">
          <TextField
            id="standard-select-site"
            select
            value={currency}
            label="Site"
            disabled={!props.commonState.isAppAdmin}
            onChange={handleChange}
          >
            {allSites.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="user-display-filter-item">
          <TextField
            id="standard-select-currency"
            select
            label="Block Admin"
            className="user-display-filter-item"
            onChange={handleChangeBlockAdmin}
          >
            {blockAdmindropDown.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div className="user-display-filter-item">
          <TextField
            id="standard-select-currency"
            select
            label="Is Block Admin?"
            className="user-display-filter-item"
            onChange={handleChangeBlockAdminRole}
          >
            {blockCategory.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="user-display-filter-item">
          <TextField
            id="standard-select-currency"
            select
            label="Is Site Admin?"
            className="user-display-filter-item"
            onChange={handleChangeSiteAdminRole}
          >
            {blockCategory.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="user-display-filter-item">
          <TextField
            id="standard-select-currency"
            select
            label="Cubicle booking?"
            className="user-display-filter-item"
            onChange={handleChangeCubicleRole}
          >
            {blockCategory.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="user-display-filter-item">
          <TextField
            id="standard-select-currency"
            select
            label="Category"
            className="user-display-filter-item"
            onChange={handleChangeCategory}
          >
            {categoryDropDown.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div className="user-display-filter-item">
          <TextField id="site" label="User Name" value={username_select} onChange={handleChangeUsername} />
        </div>
        <div className='user-display-filter-item'>
          <button
            onClick={(e) => fetchResults(e)}
            className='field-style reserve-button'>
            Search
          </button>
        </div>
      </div>
    )
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // const childhandleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   childsetOrder(isAsc ? 'desc' : 'asc');
  //   childsetOrderBy(property);
  // };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = availableSites.map((n) => n.userEmail);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const handleClick = (event, name) => {
    // console.log('click');
    // console.log(availableSites)
    // console.log(name);
    setselectedUserEmail(name);
    // console.log(event);
    if (name === '') {
      // console.log('uncheck');

    }
    // console.log(name);
    // console.log(availableFloors);
    // console.log(availableFloors[name]);
    // console.log('clicl2');
    // console.log(availableFloorsjson);   
    // console.log('clic888');
    // console.log(availableFloorsjson[name]); 
    let temp_arr1 = [];
    let temp_floors = availableFloorsjson[name];
    let i;
    for (i in temp_floors) {
      temp_arr1.push(createData(temp_floors[i]));
    }
    if (temp_arr1.length === 0) {
      temp_arr1.push(createData('No data available'));
    }
    // console.log(test_try);
    // console.log('clic99');

    const selectedIndex = selected.indexOf(name);
    // console.log('index');
    // console.log(selectedIndex);
    let newSelected = [];

    if (selectedIndex === -1) {
      // newSelected = newSelected.concat(selected, name);
      newSelected = name
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      temp_arr1 = [];
      setselectedUserEmail()
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    // setAvailableSiteFloors([...temp_arr1]);
    setSelected(newSelected);

  };

  const EditUser = () => {
    history.push('/user_profile', {
      commonState: props.commonState,
      site: props.commonState.site,
      blockedSeatName: "Test",
      new_cat: true,
      re_select: false,
      email: 'test',
      selected_user_email: selectedUserEmail,
      userName: 'Priyam',
      question: 'Test',

    })
  }

  // const handleEditOpen = () => {
  //   console.log('open');
  //   seteditUser(true);
  // };
  // const handleEditClose = () => {
  //   console.log('Edit window closed')
  //   seteditUser(false);
  //   // setreloadcounter(reload_counter + 1);
  // }
  const deleteuser = (e) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui confirm_dialog'>
            <div className="confirm_title">Confirmation</div>
            <div className="confirm_msg">Are you sure you want to delete the user - {selectedUserEmail}</div>
            <div className="confirm_buttons">
              <button className="confirm_no" onClick={
                () => {
                  onClose()
                }
              }>No</button>

              <button className="confirm_yes"
                onClick={
                  () => {
                    // console.log(selectedQuesIndex)
                    // console.log(selectedQuesName)
                    // console.log(ques_id[selectedQuesName])
                    // const delete_id = ques_id[selectedQuesName];
                    users_ref
                      .doc(selectedUserEmail)
                      .delete()
                      .then(function () {
                        // console.log("Document successfully deleted!");
                        onClose();
                        setreloadcounter(reload_counter + 1);
                      }).catch(function (error) {
                        console.error("Error removing document: ", error);
                      });
                    setreloadcounter(reload_counter + 1);
                    fetchResults();
                    onClose()
                  }
                }
              >Yes</button>
            </div>
          </div>
        );

      }
    })
  }

  // const EditUserModal = (
  //   <EditUserPage 
  //   handleEditClose={handleEditClose}
  //   email='test'
  //   selected_user_email={selectedUserEmail}
  //   userName='Priyam' 
  //   question='Test'
  //   options={['yes', 'no']}

  //   />
  // );
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked);
  // };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, availableSites.length - page * rowsPerPage);

  return (
    <div className={classes.root}>

      {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
      <Loader loading={loading}></Loader>
      {loadfilteroptions()}
      <div className={classes.root3}>
        <Paper className={classes.paper}>

          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              // style={{border: '1px grey solid',justifyContent:'center', textAlign:'center',tableLayout: 'auto'}}
              fixedHeader={false}
              aria-label="enhanced table"
            >

              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={availableSites.length}
              />
              <TableBody>
                {stableSort(availableSites, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.userEmail);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.userEmail)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}


                        key={row.userEmail}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox" >
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="none" >
                          {row.userName}
                        </TableCell>
                        <TableCell align="left" >{row.userEmail}</TableCell>
                        <TableCell align="left" >{row.site}</TableCell>
                        <TableCell align="left" >{row.category}</TableCell>
                        <TableCell align="left" >{row.blockAdminEmail}</TableCell>
                        <TableCell align="left" >{row.blockAdmin}</TableCell>
                        <TableCell align="left" ></TableCell>
                        <TableCell align="left" ></TableCell>
                        <TableCell align="left" ></TableCell>
                        {/* <TableCell align="right" >{row.declaration_last_status}</TableCell>
                        <TableCell align="right" >{row.declaration_last_updated}</TableCell> */}
                        <TableCell align="left" >
                          {row.isAppAdmin}
                          {/* <Checkbox
                            checked={row.isAppAdmin}
                            inputProps={{ 'aria-labelledby': labelId }}
                          /> */}
                        </TableCell>
                        <TableCell align="left" >
                          {row.isBlockAdmin}
                          {/* <Checkbox
                            checked={row.isBlockAdmin}
                            inputProps={{ 'aria-labelledby': labelId }}
                          /> */}
                        </TableCell>
                        <TableCell align="left" >
                          {row.cubicleBooking}
                          {/* <Checkbox
                            checked={row.isBlockAdmin}
                            inputProps={{ 'aria-labelledby': labelId }}
                          /> */}
                        </TableCell>
                        {/* <TableCell align="right" >
                        {row.isBlockAdmin}
                        {/* <Checkbox
                            checked={row.isBlockAdmin1}
                            inputProps={{ 'aria-labelledby': labelId }}
                          /> */}
                        {/* </TableCell> */}
                        <TableCell align="left" >{row.profile_created}</TableCell>
                        {/* <TableCell align="right">{row.userPhoto}</TableCell> */}
                      </TableRow>
                    );
                  })}
                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={8} />
                  </TableRow>
                )} */}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={availableSites.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
        <div >
          {/* <FloorTable floors={availableSiteFloors}/> */}

        </div>
        <div style={{ alignSelf: 'baseline', marginLeft: '5px', marginTop: '1%' }} >
          {/* <div >
                        <Tooltip title="Add Question"  >
                            <div style={{ backgroundColor: '#d77373', margin: '2px', borderRadius: '2px' }}>
                                <IconButton aria-label="filter list"  >
                                    <AddIcon />
                                </IconButton>
                            </div>
                        </Tooltip>
                    </div> */}
          <div onClick={EditUser}>
            <Tooltip title="Edit User Details"  >
              <div style={{ backgroundColor: '#d77373', margin: '2px', borderRadius: '2px' }}>
                <IconButton aria-label="filter list"  >
                  <EditIcon />
                </IconButton>
              </div>
            </Tooltip>
          </div>
          <div onClick={(e) => { deleteuser(e) }} >
            <Tooltip title="Delete User"  >
              <div style={{ backgroundColor: '#d77373', margin: '2px', borderRadius: '2px' }}>
                <IconButton aria-label="filter list"  >
                  <DeleteIcon />
                </IconButton>
              </div>
            </Tooltip>
          </div>
          {/* <Modal
                        open={editUser}
                        onClose={handleEditClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >

                        {EditUserModal}
                    </Modal> */}
          {/* <Modal
                        open={newQues}
                        onClose={handleNewClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >

                        {AddQuesModal}
                    </Modal>
                    <Modal
                        open={editQues}
                        onClose={handleEditClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >

                        {EditQuesModal}
                    </Modal> */}
        </div>

        {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
      </div>
    </div>
  );
}
